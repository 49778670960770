import { Collapse, CollapseProps } from 'antd';
import { SvgArrowDownCollapse } from '../@svg/SvgArrowDownCollapse';
import { SvgArrowUpCollapse } from '../@svg/SvgArrowUpCollapse';
import './styledCollapse.scss';

export interface ICollapseProps<T extends object> {
  items: T[] | undefined;
  collapseProps?: CollapseProps;
  activeKey?: string | number;
}

function StyledCollapse<T extends object>(props: ICollapseProps<T>) {
  const { items, collapseProps, activeKey } = props;
  return (
    <div className="salon__styled-collapse-container">
      <Collapse
        items={items}
        defaultActiveKey={activeKey ?? ['1']}
        expandIcon={(panelProps) => <>{panelProps.isActive ? <SvgArrowUpCollapse /> : <SvgArrowDownCollapse />}</>}
        {...collapseProps}
        expandIconPosition="end"
        bordered={false}
        className={`salon__styled-collapse-main ${collapseProps?.className ?? ''}`}
      />
    </div>
  );
}
export default StyledCollapse;
