import { Form } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { IHandleSubmitCheckoutProps, IListIdTotalPrice, ITipInformation, PaymentInformation } from '../../..';
import {
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import {
  convertFormatNumberToNumber,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../../../../utils';
import KeyboardWrapper from '../../../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  onSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  paymentInformation?: PaymentInformation;
  onChangePaymentInformation: Dispatch<SetStateAction<PaymentInformation>>;
  tipInformation?: ITipInformation;
  saveTipAndTechnician: (totalTip: number) => void;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
}

interface FormData {
  customerCheck?: string;
  customerTipLeft?: string;
}

enum InputNameEnum {
  CustomerCheck = 'customerCheck',
  CustomerTipLeft = 'customerTipLeft',
}

const n = (key: keyof FormData) => key;

export const CheckPayment: React.FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    listTotal,
    onChangePaymentInformation,
    onSubmitCheckout,
    saveTipAndTechnician,
    setListTotal,
    paymentInformation,
    tipInformation,
  } = props;

  const intl = useIntl();

  const [form] = Form.useForm<FormData>();
  const customerCheck = Form.useWatch(n('customerCheck'), form);
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const customerCheckRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState<boolean>(false);
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();

  useEffect(() => {
    if (customerCheck && customerCheckRef.current) {
      const [integer] = customerCheck.replace(/,/g, '').split('.');
      customerCheckRef.current.selectionStart = customerCheckRef.current.selectionEnd =
        customerCheck.length + Math.floor(Number(integer || 0) / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(Number(integer || 0) / 3);
    }
  }, [customerCheck, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    const convertCustomerCheck = handleTruncateToTwoDecimal(convertFormatNumberToNumber(value.customerCheck || 0));
    const convertCustomerTipLeft = convertFormatNumberToNumber(value.customerTipLeft || 0);
    const balanceToPay = handleTruncateToTwoDecimal(listTotal.totalBalanceTotalPay);

    let totalTip = Number(tipInformation?.tip ?? 0) + convertCustomerTipLeft;

    if (convertCustomerCheck > balanceToPay) {
      totalTip += convertCustomerCheck - balanceToPay;
    }

    const ticketTransactions = [...(paymentInformation?.ticketTransaction ?? [])];

    const ticketTransaction: TicketTransactionDTO = {
      money: convertCustomerCheck,
      typePayment: TicketTransactionDTOTypePaymentEnum.Check,
      status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
      tipMoney: convertCustomerTipLeft,
    };

    ticketTransactions.push(ticketTransaction);

    onChangePaymentInformation((prev) => ({
      ...prev,
      ticketTransaction: ticketTransactions,
      checkPayment: {
        amount: Number(paymentInformation?.checkPayment?.amount || 0) + convertCustomerCheck,
        tip: Number(paymentInformation?.checkPayment?.tip || 0) + convertCustomerTipLeft,
      },
    }));

    if (convertCustomerCheck >= balanceToPay) {
      onSubmitCheckout({
        status: TicketDTOStatusEnum.Completed,
        ticketTransaction: ticketTransactions,
        totalTip: handleTruncateToTwoDecimal(totalTip),
        balanceToPay: 0,
      });
    }

    saveTipAndTechnician(handleTruncateToTwoDecimal(totalTip));
    setListTotal({
      ...listTotal,
      totalCash: convertCustomerCheck + listTotal.totalCash,
    });
    setOpen(false);
  };
  const onClickNoTip = (value: number) => {
    form.setFieldValue(n('customerTipLeft'), formatNumberThousandWithDecimal(value));
  };
  useEffect(() => {
    form.setFieldsValue({
      [n('customerCheck')]: formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0),
      [n('customerTipLeft')]: null,
    });
  }, []);

  useEffect(() => {
    if (open) {
      customerCheckRef.current?.focus();
      setFocused(open);
    }
  }, [open, focused]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.checkPayment` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 832,
        footer: null,
      }}
    >
      <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <p className="font-size-16 font-weight-500 m-0">
              {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
              <span className="font-weight-700">
                ${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}
              </span>
            </p>
          </ColWrap>
          <ColWrap>
            <FormInputNumber
              name={n('customerCheck')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.checkPayment.customerCheck.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                inputMode: 'none',

                onFocus: () => setFocusedInputName(InputNameEnum.CustomerCheck),
                getInputRef: customerCheckRef,
                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 width-378',
              }}
            />
          </ColWrap>
          <ColWrap>
            <FormInputNumber
              name={n('customerTipLeft')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                inputMode: 'none',

                onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
                getInputRef: customerTipLeftRef,
                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 width-354',
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
              {/* no tip */}
              <ColWrap colProps={{ span: 4 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                    onClick: () => onClickNoTip(0),
                  }}
                  content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
                />
              </ColWrap>

              {/* numeric keyboard */}
              <ColWrap colProps={{ span: 16 }}>
                <KeyboardWrapper
                  inputName={focusedInputName}
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={9}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                  direction="rtl"
                />
              </ColWrap>

              {/* ok button */}
              <ColWrap colProps={{ span: 4 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                    htmlType: 'submit',
                  }}
                  content={intl.formatMessage({ id: 'common.OK' })}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
};
