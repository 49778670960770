import { AxiosResponse } from 'axios';
import React, { FC, RefObject, useEffect, useState } from 'react';
import {
  DragDropContext,
  DragStart,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import {
  Customer,
  Promotion,
  SalonSetting,
  ServiceTurn,
  SettingCheckout,
  Ticket,
  TicketDTOStatusEnum,
  TicketDisCountDTO,
  TicketStatusEnum,
  TicketTransactionDTO,
} from '../../../../apis/client-axios';
import { SvgPointRewardIcon } from '../../../../components/@svg/SvgPointRewardIcon';
import { SvgPrintIcon } from '../../../../components/@svg/SvgPrintIcon';
import { SvgRecycleIcon } from '../../../../components/@svg/SvgRecycleIcon';
import { SvgRemoveTechnicianIcon } from '../../../../components/@svg/SvgRemoveTechnicianIcon';
import ColWrap from '../../../../components/ColWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import { TimePayment } from '../../../../components/Print/@types';
import RowWrap from '../../../../components/RowWrap';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { ButtonCirclePrimary } from '../../../../components/buttons/buttonCircle/ButtonCirclePrimary';
import {
  CHECKOUT_FULL_TIME_FORMAT,
  formatNumberThousand,
  formatNumberThousandWithDecimal,
  formatPhoneNumber,
  handleTruncateToTwoDecimal,
  toDayjsTimeZone,
} from '../../../../utils';
import BillItem from '../BillItem';
import BillItemServices from '../BillItemServices';
import BillItemTechnician from '../BillItemTechnician';
import BillStart from '../BillStart';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import {
  DesiredStatusesItem,
  IAppointmentBill,
  IDiscountWithTax,
  IGiftCard,
  IListIdDisable,
  IListIdTotalPrice,
  IOpenModalCheckOut,
  IServiceCharge,
  IServicesItem,
  ITechnicianItem,
  ListServicesItem,
  PaymentInformation,
} from '../index';
import ModalSelectTechnician from './ModalSelectTechnician';
import { log } from 'console';
import { RequirePasswordModal } from '../../../../components/RequirePasswordModal';
import { IRequirePassword } from '../ListServicesButtons';
import { defaultManagerCode } from '../../../../utils/constant';

interface IProps {
  appointmentBill: IAppointmentBill;
  openModal: IOpenModalCheckOut;
  idTechnician: number | string;
  listTotal: IListIdTotalPrice;
  taxDiscount: number;
  serviceChangePromotionAuto: number;
  serviceChangePromotionManual: number;
  serviceChangeNamePromotion: string;
  isOpenPayAndComplete: boolean;
  serviceCharge: IServiceCharge;
  discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined;
  paymentInformation: PaymentInformation;
  taxPercent: number;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  isLoading: boolean;
  listIdDisable: IListIdDisable;
  listTechnician: ITechnicianItem[];
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  setDiscountsAndRewardsApplied: (discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined) => void;
  setIdTechnician: (idTechnician: number | string) => void;
  setListSkill: (listSkill: number[]) => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
  setServiceApply: (serviceApply: number[]) => void;
  setListIdDisable: (listIdDisable: IListIdDisable) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  handleRemoveTechnician: (idDeleteTechnician: number) => void;
  handleRemoveMerchandiseDiscount: (
    idDeleteTechnician: number | undefined,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean,
    isRemoveTax: boolean
  ) => void;

  handleRemoveServices: (idDeleteServices: number, idTechnician: number) => void;
  handleRemoveServiceDiscount: (
    idDeleteServices: number,
    idTechnician: number,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean,
    isRemoveDiscountTech: boolean,
    isRemoveTax: boolean
  ) => void;
  handleDeleteTicketPending: () => void;
  handleRemoveMerchandise: (idDeleteMerchandise: number | undefined) => void;
  setTaxPercent: (taxPercent: number) => void;
  setIsOpenPayAndComplete: (isOpenPayAndComplete: boolean) => void;
  handleRemoveCustomer: () => void;
  handleRemoveGiftCard: (idGiftCard: number) => void;
  setServiceCharge: (serviceCharge: IServiceCharge) => void;
  setIsEditServicePrice: (isEditServicePrice: boolean) => void;
  setIdService: (idService: number | string) => void;
  handleSubmitCheckout: (submitParams?: {
    status?: TicketDTOStatusEnum;
    ticketTransaction?: TicketTransactionDTO[];
  }) => void;
  setServiceItem: (serviceItem: IServicesItem) => void;
  setIndexServiceEdit: (indexService: number) => void;
  resetBill: () => void;
  checkListServiceInTechnician: (idTechnician: number) => void;
  checkIsHaveServiceHold: () => boolean;
  checkIsHaveServiceCreate: () => boolean;
  handleUpdateShowModal: (item: string) => React.ReactNode;
  settingCheckout?: SettingCheckout;
  listSetting?: SalonSetting;
  lastTicketInToday?: Ticket;
  printBillComponentRef: RefObject<HTMLDivElement | null>;
  onChangeTicketToPrint: (ticket?: Ticket, timePayment?: TimePayment) => void;
  onPrintTicket: () => void;
  setRedeem: (redeem: boolean) => void;
  isLoadingPdf: boolean;
}

const technicianString: string = 'technician_';

const CheckoutBill: FC<IProps> = (props) => {
  const {
    appointmentBill,
    openModal,
    idTechnician,
    listTotal,
    taxDiscount,
    serviceCharge,
    serviceChangePromotionAuto,
    serviceChangePromotionManual,
    serviceChangeNamePromotion,
    discountsAndRewardsApplied,
    paymentInformation,
    taxPercent,
    listPromotion,
    isLoading,
    settingCheckout,
    listTechnician,
    listIdDisable,
    setServiceApply,
    handleGetTotal,
    setListIdDisable,
    setDiscountsAndRewardsApplied,
    handleUpdateShowModal,
    setOpenModal,
    setIdTechnician,
    setListSkill,
    handleRemoveTechnician,
    handleRemoveServices,
    handleRemoveServiceDiscount,
    handleRemoveMerchandise,
    handleRemoveMerchandiseDiscount,
    setTaxPercent,
    setIsOpenPayAndComplete,
    handleRemoveCustomer,
    handleRemoveGiftCard,
    setServiceCharge,
    setIsEditServicePrice,
    setIdService,
    handleSubmitCheckout,
    setServiceItem,
    resetBill,
    handleDeleteTicketPending,
    checkListServiceInTechnician,
    checkIsHaveServiceHold,
    checkIsHaveServiceCreate,
    setIndexServiceEdit,
    setAppointmentBill,
    listSetting,
    lastTicketInToday,
    printBillComponentRef,
    onChangeTicketToPrint,
    onPrintTicket,
    setRedeem,
    isLoadingPdf,
  } = props;
  const intl = useIntl();
  const [currentDateTime, setCurrentDateTime] = useState(toDayjsTimeZone().format(CHECKOUT_FULL_TIME_FORMAT));
  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [droppableId, setDroppableId] = useState<string>('');
  const [saveIdElement, setSaveIdElement] = useState<string>('');
  const [saveHeight, setSaveHeight] = useState<number>(0);
  const [idServiceDrop, setIdServiceDrop] = useState<number>(0);
  const [requirePasswordState, setRequirePasswordState] = useState<IRequirePassword>({
    open: false,
    modalType: undefined,
  });

  const handleRenderHeight = (item: ITechnicianItem) => {
    if (item.services) {
      let count = 0;
      item.services.forEach((itemService: IServicesItem) => {
        if (!!itemService.discount?.birthdayDiscount) {
          count += 1;
        }
        if (!!itemService.discount?.weeklyDiscount) {
          count += 1;
        }
        if (!!itemService.discount?.occasionDiscount) {
          count += 1;
        }
        if (!!itemService.discount?.technicianDiscount) {
          count += 1;
        }
        if (!!itemService.discount?.tax) {
          count += 1;
        }
      });
      return 50 * item?.services.length + 50 + count * 30;
    } else {
      return 50;
    }
  };
  const handleRenderHeightOndrop = (item: ITechnicianItem) => {
    if (item.services) {
      if (saveHeight > 0) {
        let count = 0;
        item.services.forEach((itemService: IServicesItem) => {
          if (!!itemService.discount?.birthdayDiscount) {
            count += 1;
          }
          if (!!itemService.discount?.weeklyDiscount) {
            count += 1;
          }
          if (!!itemService.discount?.occasionDiscount) {
            count += 1;
          }
          if (!!itemService.discount?.tax) {
            count += 1;
          }
        });
        return 50 * item?.services.length + saveHeight + 50 + count * 30;
      } else {
        return 50 * item?.services.length + 110;
      }
    } else {
      return 110;
    }
  };

  const onCancelRequirePassword = () => setRequirePasswordState({ open: false, modalType: undefined });
  const onSuccessRequirePassword = () => {
    if (requirePasswordState.modalType) {
      handleUpdateShowModal(requirePasswordState.modalType);
      setIsEditServicePrice(false);
    }
    setRequirePasswordState({
      open: false,
      modalType: undefined,
    });
  };

  const getPasswords: () => string[] = () => {
    const arrayPasswords: string[] = [];
    if (
      requirePasswordState.modalType &&
      ListServicesItem.Redeem === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureDiscountButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureDiscountButton);
      arrayPasswords.push(settingCheckout.securityCode.managerCode ?? settingCheckout.securityCode.defaultManagerCode);
    }
    return arrayPasswords;
  };

  const handleRemoveReferral = () => {
    setDiscountsAndRewardsApplied({
      ...discountsAndRewardsApplied,
      referralDiscount: undefined,
    });
  };
  const handleRemoveGeneralDiscount = () => {
    setDiscountsAndRewardsApplied({
      ...discountsAndRewardsApplied,
      generalTicketDiscount: undefined,
    });
  };
  const handleRemoveGeneralMerchandiseDiscount = () => {
    setDiscountsAndRewardsApplied({
      ...discountsAndRewardsApplied,
      generalMerchandiseDiscount: undefined,
    });
  };
  const handleRemoveServiceCharge = () => {
    setServiceCharge({
      chargeValue: -1,
      name: '',
    });
  };
  const handleRemoveTaxPercent = () => {
    setTaxPercent(-1);
  };
  const handleSetValueCheckout = (tempAppointment: IAppointmentBill) => {
    const listServicesIdDisable: number[] = [];
    tempAppointment?.technician.forEach((tech) => {
      tech?.services?.forEach((service) => {
        listServicesIdDisable.push(service.idDelete);
      });
    });
    setListIdDisable({
      ...listIdDisable,
      listIdServices: listServicesIdDisable,
    });
    setServiceApply(listServicesIdDisable);
    setAppointmentBill(tempAppointment);
    handleGetTotal(tempAppointment);
    setIdServiceDrop(0);
  };

  const onDragStart = (result: DragStart) => {
    setDroppableId(result.source.droppableId);
  };
  const onDragEnd = (result: DropResult) => {
    const { source, destination }: { source: DraggableLocation; destination?: DraggableLocation | null } = result;
    setDroppableId('');
    const sourceDroppableId = source.droppableId;
    let tempAppointment = appointmentBill;
    if (!destination) {
      if (sourceDroppableId === DesiredStatusesItem.ReferralDiscount) {
        handleRemoveReferral();
      }
      if (sourceDroppableId === DesiredStatusesItem.GeneralTicketDiscount) {
        handleRemoveGeneralDiscount();
      }
      if (sourceDroppableId === DesiredStatusesItem.GeneralMerchandiseDiscount) {
        handleRemoveGeneralMerchandiseDiscount();
      }
      if (sourceDroppableId === DesiredStatusesItem.ServiceCharge) {
        handleRemoveServiceCharge();
      }
      if (sourceDroppableId === DesiredStatusesItem.TaxPercent) {
        handleRemoveTaxPercent();
      }
      if (sourceDroppableId === DesiredStatusesItem.GiftCard) {
        const idGiftCard: number = source.index;
        if (!!tempAppointment.giftCards?.length) {
          tempAppointment = {
            ...tempAppointment,
            giftCards: tempAppointment.giftCards.filter((item) => item.id !== idGiftCard) as IGiftCard[],
          };
        }
      }
      if (sourceDroppableId === DesiredStatusesItem.Merchandise) {
        const idDeleteMerchandise: number = source.index;
        if (appointmentBill.ticketMerchandise && idDeleteMerchandise) {
          const ticketMerchandiseDelete = appointmentBill.ticketMerchandise.filter(
            (merchandise) => merchandise.idDelete !== idDeleteMerchandise
          );
          if (ticketMerchandiseDelete) {
            setListIdDisable({
              ...listIdDisable,
              listIdMerchandise: ticketMerchandiseDelete.map((item) => item.merchandiseId) as number[],
            });
            tempAppointment = {
              ...appointmentBill,
              ticketMerchandise: ticketMerchandiseDelete,
            };
          }
        }
      }
      if (sourceDroppableId.includes(technicianString)) {
        tempAppointment.technician.forEach((tech) => {
          tech.services = tech.services?.filter((service) => service.idDelete !== source.index);
        });
      }
    } else {
      const destinationDroppableId = destination.droppableId;
      if (sourceDroppableId === destinationDroppableId || source.index === destination.index) return;

      if (sourceDroppableId !== destinationDroppableId && destination.index !== source.index) {
        const idTechnicianSource: number = +source.droppableId.replace(technicianString, '') ?? 0;
        const idTechnicianDestination: number = destination?.droppableId.replace(technicianString, '')
          ? +destination?.droppableId.replace(technicianString, '')
          : 0;
        const findIndexTechnicianSrc = appointmentBill.technician.findIndex(
          (itemFilter) => itemFilter.id === idTechnicianSource
        );
        const findIndexTechnicianDestination = appointmentBill.technician.findIndex(
          (itemFilter) => itemFilter.id === idTechnicianDestination
        );

        const technicianHaveService = appointmentBill.technician.find(
          (itemFilter) => itemFilter.id === idTechnicianDestination
        );

        // const checkDuplicateService = technicianHaveService?.services?.findIndex(
        //   (item) => item.idDelete === source.index
        // );

        // const technicianHaveGeneralSV = appointmentBill.technician.find(
        //   (itemFilter) => itemFilter.id === idTechnicianDestination
        // );

        const serviceGeneral = tempAppointment.technician[findIndexTechnicianSrc].services?.find(
          (item) => item.idDelete === source.index
        );

        // if (
        //   ((checkDuplicateService !== -1 && checkDuplicateService !== undefined) ||
        //     !technicianHaveService?.skills?.includes(source.index)) &&
        //   !serviceGeneral?.isGeneralService
        // ) {

        if (!technicianHaveService?.skills?.includes(idServiceDrop) && !serviceGeneral?.isGeneralService) {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.technicianDoesntHaveSkill' }) });

          return;
        } else {
          if (findIndexTechnicianSrc >= 0 && findIndexTechnicianDestination >= 0) {
            // destination
            const serviceSrc = tempAppointment.technician[findIndexTechnicianSrc].services?.filter(
              (item) => item.idDelete === source.index
            );
            if (!!serviceSrc?.length) {
              if (!!tempAppointment.technician[findIndexTechnicianDestination].services) {
                tempAppointment.technician[findIndexTechnicianDestination].services =
                  tempAppointment.technician[findIndexTechnicianDestination].services?.concat(serviceSrc);
              } else {
                tempAppointment.technician[findIndexTechnicianDestination].services = serviceSrc;
              }
            }

            // source
            tempAppointment.technician[findIndexTechnicianSrc].services = tempAppointment.technician[
              findIndexTechnicianSrc
            ].services?.filter((item) => item.idDelete !== source.index);
          }
        }
      }
    }
    handleSetValueCheckout(tempAppointment);
  };

  const checkActiveBill: boolean =
    appointmentBill.ticketMerchandise.length === 0 &&
    appointmentBill.customerId === 0 &&
    appointmentBill.technician.length === 0 &&
    !appointmentBill.giftCards?.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(toDayjsTimeZone().format(CHECKOUT_FULL_TIME_FORMAT));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const convertValue = (value: number | string, type?: DiscountTypeEnum) => {
    return value ? (type === DiscountTypeEnum.Percent ? Number(value) : value) : 0;
  };

  const handleCheckTax = () => {
    if (!!serviceChangePromotionManual && +serviceCharge.chargeValue === serviceChangePromotionManual) {
      return true;
    } else if (!!serviceChangePromotionAuto && +serviceCharge.chargeValue === serviceChangePromotionAuto) {
      return true;
    } else if (+serviceCharge.chargeValue === 0 && serviceCharge.name !== '') {
      return true;
    } else {
      return false;
    }
  };

  const dataSource = [
    // cash payment
    paymentInformation?.cashPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.cash' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.cashPayment.amount || 0),
        }
      : undefined,
    // gift card payment
    paymentInformation?.giftCardPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.giftCard' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.giftCardPayment?.totalValue ?? 0),
        }
      : undefined,
    //check payment
    paymentInformation?.checkPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.check' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.checkPayment?.amount ?? 0),
        }
      : undefined,
    //keyin card / card number
    paymentInformation?.keyInCardPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.keyinCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.amount ?? 0),
        }
      : undefined,
    paymentInformation?.ortherPayment
      ? {
          key: <span className="font-size-14 font-weight-600">Orther</span>,
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.amount ?? 0),
        }
      : undefined,
    paymentInformation?.multipleChargeCard
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.multipleChargeCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.amount ?? 0),
        }
      : undefined,
  ];

  const handlePrintTicketBeforePayment = () => {
    const ticket: Ticket = {
      id: '............................',
      customerId: appointmentBill?.customerId || 0,
      createdOnDate: new Date(),
      serviceTax: {
        moneyDiscount: listTotal.saleTax,
        percentDiscount: taxPercent,
      },
      serviceCharge: {
        moneyDiscount: listTotal.serviceCharge,
        name: serviceCharge.name,
        percentDiscount: serviceCharge.chargeValue,
      },
      total: listTotal.total ?? 0,
      totalDiscount: listTotal.totalDiscount ?? 0,
      totalPayment: listTotal.totalPayments ?? 0,
      totalTip: 0,
      discount: {
        birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
              type: discountsAndRewardsApplied?.birthdayDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountBirthday),
            }
          : undefined,
        generalItemDiscount: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount,
              type: discountsAndRewardsApplied?.generalMerchandiseDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalItemDiscount),
            }
          : undefined,
        generalTicketDiscount: discountsAndRewardsApplied?.generalTicketDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalTicketDiscount?.amount,
              type: discountsAndRewardsApplied?.generalTicketDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalTicketDiscount),
            }
          : undefined,
        occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
              type: discountsAndRewardsApplied?.occasionDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountOccasion),
            }
          : undefined,
        referralDiscount: !!appointmentBill.customer?.tickets?.filter(
          (ticket) => ticket.status === TicketStatusEnum.Completed
        )?.length
          ? undefined
          : discountsAndRewardsApplied?.referralDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.referralDiscount?.amount,
              type: discountsAndRewardsApplied?.referralDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.referralDiscount),
              referralCode: discountsAndRewardsApplied.referralDiscount.referralCode,
            }
          : undefined,
        weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
              type: discountsAndRewardsApplied?.weeklyDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountWeekly),
            }
          : undefined,
        loyaltyReward: undefined,
        rewardBalance: discountsAndRewardsApplied?.rewardBalance?.amount
          ? {
              discountApply: discountsAndRewardsApplied.rewardBalance.amount,
              type: discountsAndRewardsApplied?.rewardBalance?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalRedeeming),
              point: discountsAndRewardsApplied.rewardBalance.points,
              isCustomAmount: !!discountsAndRewardsApplied.rewardBalance.type,
            }
          : undefined,
        // rewardUse: discountsAndRewardsApplied?.rewardBalance?.points ?? 0,
      },
      deposit: appointmentBill.deposit ?? 0,
      balanceToPay: listTotal.totalBalanceTotalPay ?? 0,
      technicianResponsibility: [],
      status: appointmentBill.status,
      rewarding: 0,
      customer: appointmentBill?.customer as Customer,
      technicianTurns: appointmentBill.technician.map((item, index) => {
        return {
          technicianId: item.id,
          id: index + 1,
          serviceTurns:
            item.services &&
            item.services.map((itemService) => {
              let totalDiscount: number = 0;
              if (itemService && itemService.discount) {
                if (itemService.discount.birthdayDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.birthdayDiscount?.moneyDiscount;
                }
                if (itemService.discount.weeklyDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.weeklyDiscount?.moneyDiscount;
                }
                if (itemService.discount.occasionDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.occasionDiscount?.moneyDiscount;
                }
              }

              const serviceTurn: ServiceTurn = {
                isGeneralService: itemService.isGeneralService,
                price: itemService.price,
                supply: itemService.supply,
                serviceId: itemService.id,
                discount: itemService.discount as IDiscountWithTax,
                totalDiscount: totalDiscount,
                technicianTurnId: index + 1,
                service: itemService,
              } as any;

              return serviceTurn;
            }),
        } as any;
      }),
      ticketTransaction: [],
      ticketMerchandise:
        appointmentBill.ticketMerchandise &&
        appointmentBill.ticketMerchandise.map((item, index) => {
          let totalDiscount: number = 0;
          if (item && item.discount) {
            if (item.discount.birthdayDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.birthdayDiscount?.moneyDiscount;
            }
            if (item.discount.weeklyDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.weeklyDiscount?.moneyDiscount;
            }
            if (item.discount.occasionDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.occasionDiscount?.moneyDiscount;
            }
          }
          return {
            merchandisePrice: item.merchandisePrice,
            merchandiseId: item?.merchandiseId || 0,
            discount: item.discount,
            totalDiscount: totalDiscount,
            id: index + 1,
            merchandise: {
              name: item.name,
            },
          } as any;
        }),
    } as any;

    onChangeTicketToPrint(ticket, TimePayment.BEFORE);
    onPrintTicket();
  };

  return (
    <React.Fragment>
      <Droppable droppableId={DesiredStatusesItem.CheckOutBIll} key={DesiredStatusesItem.CheckOutBIll}>
        {(providedDropZone: DroppableProvided) => (
          <>
            {checkActiveBill ? (
              <></>
            ) : (
              <div className="salon__checkout-container-customer">
                <div className="w-100">
                  <RowWrap
                    isAutoFillRow={false}
                    isGutter={true}
                    gutter={24}
                    isWrap={false}
                    styleFill="between"
                    rowClassName="align-item-center"
                  >
                    {!!appointmentBill.customer ? (
                      <ColWrap
                        colProps={{
                          span: 1,
                          className: 'p-0',
                        }}
                      >
                        <div className="p-l-12 m-t-15">
                          <ButtonIcon
                            icon={<SvgRemoveTechnicianIcon />}
                            className="m-r-20"
                            buttonProps={{
                              onClick: () => {
                                handleRemoveCustomer();
                                // setDiscountsAndRewardsApplied(undefined);
                              },
                            }}
                          />
                        </div>
                      </ColWrap>
                    ) : null}
                    <ColWrap
                      colProps={{
                        span: 12,
                      }}
                    >
                      <div className={`salon__checkout-customer ${!!appointmentBill?.customer ? '' : 'gap-6'}`}>
                        <p
                          className={`salon__checkout-customer-title m-b-0 ${
                            !!appointmentBill?.customer ? 'font-size-14' : 'font-size-16'
                          }`}
                        >
                          {!!appointmentBill?.customer
                            ? appointmentBill?.customer?.name
                            : intl.formatMessage({ id: 'checkout.customerAccount' })}
                        </p>
                        {!!appointmentBill?.customer?.phoneNumber && (
                          <span className="salon__checkout-customer-phone">
                            {formatPhoneNumber(appointmentBill.customer?.phoneNumber, ' - ')}
                          </span>
                        )}

                        <span
                          className={`salon__checkout-customer-subtitle cursor-pointer ${
                            !appointmentBill?.customer && 'd-flex gap-4'
                          }`}
                          onClick={() => {
                            !!appointmentBill?.customer
                              ? setOpenModal({
                                  ...openModal,
                                  openDiscountsAndRewards: true,
                                })
                              : setOpenModal({
                                  ...openModal,
                                  openListCustomer: true,
                                });
                          }}
                        >
                          {!appointmentBill?.customer ? (
                            <span className="font-size-12">
                              {intl.formatMessage({ id: 'checkout.pickRecentCheckin' })}
                            </span>
                          ) : (
                            <>
                              {formatNumberThousand(
                                appointmentBill.customer?.customerReward?.reduce(
                                  (currentValue, item) => currentValue + item.reward,
                                  0
                                ) || 0
                              )}{' '}
                              pts <SvgPointRewardIcon />
                            </>
                          )}
                        </span>
                      </div>
                    </ColWrap>
                    <ColWrap
                      colProps={{
                        span: !!appointmentBill.customer ? 11 : 12,
                      }}
                    >
                      <div className="d-flex align-items-end justify-content-end flex-column gap-4">
                        <span className="font-size-12 font-weight-600 color-292F33">{currentDateTime}</span>
                        <div className="d-flex align-items-center justify-content-between gap-8">
                          <ButtonCirclePrimary
                            content={<SvgPrintIcon />}
                            buttonProps={{
                              className: 'width-28 height-28',
                              onClick: handlePrintTicketBeforePayment,
                            }}
                          />
                          <ButtonCirclePrimary
                            content={<SvgRecycleIcon />}
                            buttonProps={{
                              className: 'width-28 height-28',
                              onClick: () => {
                                if (listTotal.totalCash > 0 || !!paymentInformation.giftCardPayment?.totalValue) {
                                  setOpenModal({
                                    ...openModal,
                                    openDeleteTicket: true,
                                  });
                                } else {
                                  if (appointmentBill.isDetail) {
                                    handleDeleteTicketPending();
                                  } else {
                                    resetBill();
                                  }
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                    </ColWrap>
                  </RowWrap>
                </div>
              </div>
            )}
            <div
              className={`salon__checkout-col-container ${
                checkActiveBill ? '' : 'salon__checkout-col-container-mid'
              } w-100`}
              {...providedDropZone.droppableProps}
              ref={providedDropZone.innerRef}
            >
              {checkActiveBill ? (
                <BillStart
                  settingCheckout={settingCheckout}
                  listSetting={listSetting}
                  lastTicketInToday={lastTicketInToday}
                  printBillComponentRef={printBillComponentRef}
                  onChangeTicketToPrint={onChangeTicketToPrint}
                  onPrintTicket={onPrintTicket}
                  listTechnician={listTechnician}
                  isLoadingPdf={isLoadingPdf}
                />
              ) : (
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}
                  onBeforeDragStart={() => {
                    const eventElement = document.getElementById(saveIdElement);
                    setSaveHeight(eventElement?.clientHeight ?? 0);
                  }}
                >
                  <div className="p-t-12 gap-8 salon__checkout-technician-dropzone w-100">
                    {appointmentBill?.technician.map((item: ITechnicianItem, index: number) => (
                      <Droppable
                        droppableId={`technician_${item.id}`}
                        key={DesiredStatusesItem.CheckOutBIll}
                        isDropDisabled={!droppableId.includes('technician')}
                      >
                        {(providedDropTechnicianZone: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
                          <div
                            {...providedDropTechnicianZone.droppableProps}
                            ref={providedDropTechnicianZone.innerRef}
                            className="salon__checkout-bill-service-dropzone"
                            style={{
                              height:
                                snapshotDrop.isDraggingOver && droppableId !== `technician_${item.id}`
                                  ? handleRenderHeightOndrop(item)
                                  : handleRenderHeight(item),
                            }}
                          >
                            <div className="w-100">
                              <BillItemTechnician
                                name={item.name}
                                defaultAvatar={item.defaultAvatar ?? ''}
                                active={item.id === idTechnician}
                                onClick={() => handleRemoveTechnician(+item.id)}
                                onClickActiveTechnician={() => {
                                  setIdTechnician(item.id);
                                  setListSkill(item.skills ?? []);
                                  checkListServiceInTechnician(item.id as number);
                                }}
                                onClickAvatar={() => {
                                  setOpenModalSelect(true);
                                }}
                              />

                              {item?.services?.map((itemService: IServicesItem, indexService: number) => (
                                <div
                                  id={`technicianDrop_${item.id}_${itemService.idDelete}`}
                                  className="salon__checkout-service-container"
                                  onMouseDown={() => {
                                    setIdServiceDrop(itemService.id);
                                    setSaveIdElement(`technicianDrop_${item.id}_${itemService.idDelete}`);
                                  }}
                                  onMouseUp={() => {
                                    setSaveIdElement('');
                                    setSaveHeight(0);
                                  }}
                                >
                                  <Draggable
                                    draggableId={`serviceBill_${item.id}_${itemService.idDelete}`}
                                    index={itemService.idDelete}
                                    key={itemService.idDelete}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div className="w-100">
                                        <div
                                          ref={providedDrag?.innerRef}
                                          {...providedDrag?.draggableProps}
                                          {...providedDrag?.dragHandleProps}
                                          {...snapshotDrag}
                                        >
                                          {/* {!snapshotDrag.isDropAnimating && ( */}
                                          <React.Fragment key={indexService}>
                                            <BillItemServices
                                              name={itemService.name}
                                              price={itemService.price}
                                              supply={itemService.supply ?? 0}
                                              onClick={() => {
                                                handleRemoveServices(itemService.idDelete, +item.id);
                                              }}
                                              onClickEdit={() => {
                                                setIsEditServicePrice(true);
                                                setIdTechnician(item.id);
                                                setListSkill(item.skills ?? []);
                                                setIdService(itemService.id);
                                                setOpenModal({
                                                  ...openModal,
                                                  openGeneralService: true,
                                                });
                                                setServiceItem(itemService);
                                                setIndexServiceEdit(indexService);
                                              }}
                                            />

                                            {itemService.discount?.weeklyDiscount &&
                                            discountsAndRewardsApplied?.weeklyDiscount?.amount ? (
                                              <div
                                                key={index}
                                                className="p-l-67 p-r-20 w-100 p-b-8 salon__checkout-service-container"
                                              >
                                                <BillItem
                                                  name={`${intl.formatMessage(
                                                    { id: 'checkout.discount.weekly' },
                                                    {
                                                      index: convertValue(
                                                        discountsAndRewardsApplied?.weeklyDiscount?.amount,
                                                        discountsAndRewardsApplied?.weeklyDiscount?.type
                                                      ),
                                                      symbol:
                                                        discountsAndRewardsApplied?.weeklyDiscount?.type ===
                                                        DiscountTypeEnum.Percent
                                                          ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                                          : '',
                                                      symbolDollar:
                                                        discountsAndRewardsApplied?.weeklyDiscount?.type ===
                                                        DiscountTypeEnum.Dollar
                                                          ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                                          : '',
                                                    }
                                                  )}`}
                                                  price={itemService.discount.weeklyDiscount.moneyDiscount}
                                                  onClick={() => {
                                                    handleRemoveServiceDiscount(
                                                      itemService.id,
                                                      +item.id,
                                                      true,
                                                      false,
                                                      false,
                                                      false,
                                                      false
                                                    );
                                                    const checkItemIds = (
                                                      discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                                        (itemFil) => itemFil !== itemService.id
                                                      ) as number[]
                                                    ).length;
                                                    if (!!checkItemIds) {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        weeklyDiscount: {
                                                          amount:
                                                            discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0,
                                                          serviceItemIds:
                                                            discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                                              (itemFil) => itemFil !== itemService.id
                                                            ) as number[],
                                                          day: discountsAndRewardsApplied.weeklyDiscount?.day ?? [],
                                                          type:
                                                            discountsAndRewardsApplied.weeklyDiscount?.type ??
                                                            DiscountTypeEnum.Dollar,
                                                        },
                                                      });
                                                    } else {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        weeklyDiscount: undefined,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {itemService.discount?.birthdayDiscount &&
                                            discountsAndRewardsApplied?.birthdayDiscount?.amount ? (
                                              <div className="p-l-67 p-r-20 w-100 p-b-8 salon__checkout-service-container">
                                                <BillItem
                                                  name={`${intl.formatMessage(
                                                    { id: 'checkout.discount.birthday' },
                                                    {
                                                      index: convertValue(
                                                        discountsAndRewardsApplied?.birthdayDiscount?.amount,
                                                        discountsAndRewardsApplied?.birthdayDiscount?.type
                                                      ),
                                                      symbol:
                                                        discountsAndRewardsApplied?.birthdayDiscount?.type ===
                                                        DiscountTypeEnum.Percent
                                                          ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                                          : '',
                                                      symbolDollar:
                                                        discountsAndRewardsApplied?.birthdayDiscount?.type ===
                                                        DiscountTypeEnum.Dollar
                                                          ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                                          : '',
                                                    }
                                                  )}`}
                                                  price={itemService.discount?.birthdayDiscount.moneyDiscount}
                                                  onClick={() => {
                                                    handleRemoveServiceDiscount(
                                                      itemService.id,
                                                      +item.id,
                                                      false,
                                                      true,
                                                      false,
                                                      false,
                                                      false
                                                    );
                                                    const checkItemIds = (
                                                      discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                                        (itemFil) => itemFil !== itemService.id
                                                      ) as number[]
                                                    ).length;
                                                    if (!!checkItemIds) {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        birthdayDiscount: {
                                                          amount:
                                                            discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0,
                                                          serviceItemIds:
                                                            discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                                              (itemFil) => itemFil !== itemService.id
                                                            ) as number[],
                                                          type:
                                                            discountsAndRewardsApplied.birthdayDiscount?.type ??
                                                            DiscountTypeEnum.Dollar,
                                                        },
                                                      });
                                                    } else {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        birthdayDiscount: undefined,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {itemService.discount?.occasionDiscount &&
                                            discountsAndRewardsApplied?.occasionDiscount?.amount ? (
                                              <div className="p-l-67 p-r-20 w-100 p-b-8 salon__checkout-service-container">
                                                <BillItem
                                                  name={`${intl.formatMessage(
                                                    { id: 'checkout.discount.occasion' },
                                                    {
                                                      index: convertValue(
                                                        discountsAndRewardsApplied?.occasionDiscount?.amount,
                                                        discountsAndRewardsApplied?.occasionDiscount?.type
                                                      ),
                                                      symbol:
                                                        discountsAndRewardsApplied?.occasionDiscount?.type ===
                                                        DiscountTypeEnum.Percent
                                                          ? discountsAndRewardsApplied?.occasionDiscount?.type
                                                          : '',
                                                      symbolDollar:
                                                        discountsAndRewardsApplied?.occasionDiscount?.type ===
                                                        DiscountTypeEnum.Dollar
                                                          ? discountsAndRewardsApplied?.occasionDiscount?.type
                                                          : '',
                                                    }
                                                  )}`}
                                                  price={itemService.discount?.occasionDiscount.moneyDiscount}
                                                  onClick={() => {
                                                    handleRemoveServiceDiscount(
                                                      itemService.id,
                                                      +item.id,
                                                      false,
                                                      false,
                                                      true,
                                                      false,
                                                      false
                                                    );
                                                    const checkItemIds = (
                                                      discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                                        (itemFil) => itemFil !== itemService.id
                                                      ) as number[]
                                                    ).length;
                                                    const filterId =
                                                      discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                                        (itemFil) => itemFil !== itemService.id
                                                      ) as number[];

                                                    if (!!checkItemIds) {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        occasionDiscount: {
                                                          amount:
                                                            discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0,
                                                          serviceItemIds: filterId,
                                                          name:
                                                            discountsAndRewardsApplied?.occasionDiscount?.name ?? '',
                                                          type:
                                                            discountsAndRewardsApplied.occasionDiscount?.type ??
                                                            DiscountTypeEnum.Dollar,
                                                        },
                                                      });
                                                    } else {
                                                      setDiscountsAndRewardsApplied({
                                                        ...discountsAndRewardsApplied,
                                                        occasionDiscount: undefined,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            ) : null}
                                            {itemService.discount && itemService.discount.technicianDiscount ? (
                                              <div className="p-l-67 p-r-20 w-100 p-b-8 salon__checkout-service-container">
                                                <BillItem
                                                  name={`${intl.formatMessage(
                                                    { id: 'checkout.discount.discountTechnician' },
                                                    {
                                                      index: convertValue(
                                                        itemService.discount.technicianDiscount.discountApply ?? 0,
                                                        itemService.discount.technicianDiscount.type ===
                                                          DiscountTypeEnum.Dollar
                                                          ? DiscountTypeEnum.Dollar
                                                          : DiscountTypeEnum.Percent
                                                      ),
                                                      symbol:
                                                        itemService.discount.technicianDiscount.type ===
                                                        DiscountTypeEnum.Percent
                                                          ? DiscountTypeEnum.Percent
                                                          : '',
                                                      symbolDollar:
                                                        itemService.discount.technicianDiscount.type ===
                                                        DiscountTypeEnum.Dollar
                                                          ? DiscountTypeEnum.Dollar
                                                          : '',
                                                    }
                                                  )}`}
                                                  price={itemService.discount.technicianDiscount.moneyDiscount}
                                                  onClick={() => {
                                                    handleRemoveServiceDiscount(
                                                      itemService.id,
                                                      +item.id,
                                                      false,
                                                      false,
                                                      false,
                                                      true,
                                                      false
                                                    );
                                                  }}
                                                />
                                              </div>
                                            ) : null}
                                            {itemService.discount &&
                                            itemService.discount.tax &&
                                            itemService.discount.tax.moneyDiscount &&
                                            taxPercent > 0 ? (
                                              <div className="p-l-67 p-r-20 w-100 p-b-8 salon__checkout-service-container">
                                                <BillItem
                                                  name={`${intl.formatMessage(
                                                    { id: 'checkout.tax' },
                                                    {
                                                      index: convertValue(taxPercent, DiscountTypeEnum.Percent),
                                                      symbol: DiscountTypeEnum.Percent,
                                                    }
                                                  )}`}
                                                  price={itemService.discount.tax.moneyDiscount}
                                                  onClick={() => {
                                                    handleRemoveServiceDiscount(
                                                      itemService.id,
                                                      +item.id,
                                                      false,
                                                      false,
                                                      false,
                                                      false,
                                                      true
                                                    );
                                                  }}
                                                />
                                              </div>
                                            ) : null}
                                          </React.Fragment>
                                          {/*  )} */}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                  {!!appointmentBill.ticketMerchandise?.length || !!appointmentBill.giftCards?.length ? (
                    <div className="salon__checkout-block">
                      <div className="d-flex flex-column">
                        <Droppable
                          droppableId={DesiredStatusesItem.GiftCard}
                          key={DesiredStatusesItem.GiftCard}
                          isDropDisabled={true}
                        >
                          {(providedDrop: DroppableProvided) => (
                            <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                              {!!appointmentBill.giftCards?.length ? (
                                appointmentBill.giftCards.map((item: IGiftCard, index: number) => (
                                  <Draggable
                                    draggableId={`${DesiredStatusesItem.GiftCard}_${item.id ? item.id : 0}`}
                                    index={item.id ? item.id : 0}
                                    key={item.id ? item.id : 0}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div
                                        ref={providedDrag?.innerRef}
                                        {...providedDrag?.draggableProps}
                                        {...providedDrag?.dragHandleProps}
                                        {...snapshotDrag}
                                        className="bg-white"
                                      >
                                        <BillItem
                                          key={index}
                                          name={intl.formatMessage({ id: 'checkout.giftCard' })}
                                          price={item.giftCardValue}
                                          onClick={() => {
                                            handleRemoveGiftCard(item?.id ?? 0);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </Droppable>
                        <Droppable
                          droppableId={DesiredStatusesItem.Merchandise}
                          key={DesiredStatusesItem.Merchandise}
                          isDropDisabled={true}
                        >
                          {(providedDrop: DroppableProvided) => (
                            <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                              {!!appointmentBill.ticketMerchandise?.length &&
                                appointmentBill.ticketMerchandise.map((item, index) => (
                                  <Draggable
                                    draggableId={`${DesiredStatusesItem.Merchandise}_${
                                      item.idDelete ? item.idDelete : 0
                                    }`}
                                    index={item.idDelete ? item.idDelete : 0}
                                    key={item.idDelete ? item.idDelete : 0}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div
                                        ref={providedDrag?.innerRef}
                                        {...providedDrag?.draggableProps}
                                        {...providedDrag?.dragHandleProps}
                                        {...snapshotDrag}
                                        className="bg-white"
                                      >
                                        <BillItem
                                          name={item.name}
                                          price={item.merchandisePrice}
                                          onClick={() => {
                                            handleRemoveMerchandise(item.idDelete);
                                          }}
                                        />
                                        {item.discount?.occasionDiscount &&
                                        discountsAndRewardsApplied?.occasionDiscount?.amount ? (
                                          <div className="p-l-20 m-t-8">
                                            <BillItem
                                              name={`${intl.formatMessage(
                                                { id: 'checkout.discount.occasion' },
                                                {
                                                  index: convertValue(
                                                    discountsAndRewardsApplied?.occasionDiscount?.amount,
                                                    discountsAndRewardsApplied?.occasionDiscount?.type
                                                  ),
                                                  symbol:
                                                    discountsAndRewardsApplied?.occasionDiscount?.type ===
                                                    DiscountTypeEnum.Percent
                                                      ? DiscountTypeEnum.Percent
                                                      : '',
                                                  symbolDollar:
                                                    discountsAndRewardsApplied?.occasionDiscount?.type ===
                                                    DiscountTypeEnum.Dollar
                                                      ? DiscountTypeEnum.Dollar
                                                      : '',
                                                }
                                              )}`}
                                              price={item.discount?.occasionDiscount.moneyDiscount}
                                              onClick={() => {
                                                handleRemoveMerchandiseDiscount(
                                                  item.merchandiseId,
                                                  false,
                                                  false,
                                                  true,
                                                  false
                                                );

                                                const checkItemIds = (
                                                  discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                                    (itemFil) => itemFil !== item.merchandiseId
                                                  ) as number[]
                                                ).length;
                                                if (!!checkItemIds) {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    occasionDiscount: {
                                                      name: discountsAndRewardsApplied?.occasionDiscount?.name ?? '',
                                                      amount: discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0,
                                                      serviceItemIds:
                                                        discountsAndRewardsApplied.occasionDiscount?.serviceItemIds.filter(
                                                          (itemFil) => itemFil !== item.merchandiseId
                                                        ) as number[],
                                                      type:
                                                        discountsAndRewardsApplied.occasionDiscount?.type ??
                                                        DiscountTypeEnum.Dollar,
                                                    },
                                                  });
                                                } else {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    occasionDiscount: undefined,
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                        {item.discount?.birthdayDiscount &&
                                        discountsAndRewardsApplied?.birthdayDiscount?.amount ? (
                                          <div className="p-l-20 m-t-8">
                                            <BillItem
                                              name={`${intl.formatMessage(
                                                { id: 'checkout.discount.birthday' },
                                                {
                                                  index: convertValue(
                                                    discountsAndRewardsApplied?.birthdayDiscount?.amount,
                                                    discountsAndRewardsApplied?.birthdayDiscount?.type
                                                  ),
                                                  symbol:
                                                    discountsAndRewardsApplied?.birthdayDiscount?.type ===
                                                    DiscountTypeEnum.Percent
                                                      ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                                      : '',
                                                  symbolDollar:
                                                    discountsAndRewardsApplied?.birthdayDiscount?.type ===
                                                    DiscountTypeEnum.Dollar
                                                      ? discountsAndRewardsApplied?.birthdayDiscount?.type
                                                      : '',
                                                }
                                              )}`}
                                              price={item.discount.birthdayDiscount.moneyDiscount}
                                              onClick={() => {
                                                handleRemoveMerchandiseDiscount(
                                                  item.merchandiseId,
                                                  false,
                                                  true,
                                                  false,
                                                  false
                                                );
                                                const checkItemIds = (
                                                  discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                                    (itemFil) => itemFil !== item.merchandiseId
                                                  ) as number[]
                                                ).length;
                                                if (!!checkItemIds) {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    birthdayDiscount: {
                                                      amount: discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0,
                                                      serviceItemIds:
                                                        discountsAndRewardsApplied.birthdayDiscount?.serviceItemIds.filter(
                                                          (itemFil) => itemFil !== item.merchandiseId
                                                        ) as number[],
                                                      type:
                                                        discountsAndRewardsApplied.birthdayDiscount?.type ??
                                                        DiscountTypeEnum.Dollar,
                                                    },
                                                  });
                                                } else {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    birthdayDiscount: undefined,
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {item.discount?.weeklyDiscount &&
                                        discountsAndRewardsApplied?.weeklyDiscount?.amount ? (
                                          <div className="p-l-20 m-t-8">
                                            <BillItem
                                              name={`${intl.formatMessage(
                                                { id: 'checkout.discount.weekly' },
                                                {
                                                  index: convertValue(
                                                    discountsAndRewardsApplied?.weeklyDiscount?.amount,
                                                    discountsAndRewardsApplied?.weeklyDiscount?.type
                                                  ),
                                                  symbol:
                                                    discountsAndRewardsApplied?.weeklyDiscount?.type ===
                                                    DiscountTypeEnum.Percent
                                                      ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                                      : '',
                                                  symbolDollar:
                                                    discountsAndRewardsApplied?.weeklyDiscount?.type ===
                                                    DiscountTypeEnum.Dollar
                                                      ? discountsAndRewardsApplied?.weeklyDiscount?.type
                                                      : '',
                                                }
                                              )}`}
                                              price={item.discount?.weeklyDiscount.moneyDiscount}
                                              onClick={() => {
                                                handleRemoveMerchandiseDiscount(
                                                  item.merchandiseId,
                                                  true,
                                                  false,
                                                  false,
                                                  false
                                                );
                                                const checkItemIds = (
                                                  discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                                    (itemFil) => itemFil !== item.merchandiseId
                                                  ) as number[]
                                                ).length;
                                                if (!!checkItemIds) {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    weeklyDiscount: {
                                                      amount: discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0,
                                                      serviceItemIds:
                                                        discountsAndRewardsApplied.weeklyDiscount?.serviceItemIds.filter(
                                                          (itemFil) => itemFil !== item.merchandiseId
                                                        ) as number[],
                                                      day: discountsAndRewardsApplied.weeklyDiscount?.day ?? [],
                                                      type:
                                                        discountsAndRewardsApplied.weeklyDiscount?.type ??
                                                        DiscountTypeEnum.Dollar,
                                                    },
                                                  });
                                                } else {
                                                  setDiscountsAndRewardsApplied({
                                                    ...discountsAndRewardsApplied,
                                                    weeklyDiscount: undefined,
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {item.discount &&
                                        item.discount.tax &&
                                        item.discount.tax.moneyDiscount &&
                                        taxPercent > 0 ? (
                                          <div className="p-l-20 m-t-8">
                                            <BillItem
                                              name={`${intl.formatMessage(
                                                { id: 'checkout.tax' },
                                                {
                                                  index: convertValue(taxPercent, DiscountTypeEnum.Percent),
                                                  symbol: DiscountTypeEnum.Percent,
                                                }
                                              )}`}
                                              price={item.discount.tax.moneyDiscount}
                                              onClick={() => {
                                                handleRemoveMerchandiseDiscount(
                                                  item.merchandiseId,
                                                  false,
                                                  false,
                                                  false,
                                                  true
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <div className="salon__checkout-block">
                      {discountsAndRewardsApplied &&
                      (discountsAndRewardsApplied.generalTicketDiscount?.amount ||
                        discountsAndRewardsApplied.generalMerchandiseDiscount?.amount ||
                        discountsAndRewardsApplied.referralDiscount?.amount) ? (
                        <div
                          className={
                            listTotal.referralDiscount ||
                            listTotal.totalDiscountGeneralMerchandise ||
                            listTotal.totalDiscountGeneralTicket
                              ? 'salon__checkout-bill-general-discount'
                              : ''
                          }
                        >
                          {listTotal.referralDiscount > 0 ? (
                            <Droppable
                              droppableId={DesiredStatusesItem.ReferralDiscount}
                              key={DesiredStatusesItem.ReferralDiscount}
                              isDropDisabled={true}
                            >
                              {(providedDrop: DroppableProvided) => (
                                <div
                                  className={discountsAndRewardsApplied.generalTicketDiscount?.amount ? 'm-b-8' : ''}
                                  {...providedDrop.droppableProps}
                                  ref={providedDrop.innerRef}
                                >
                                  <Draggable
                                    draggableId={DesiredStatusesItem.ReferralDiscount}
                                    index={0}
                                    key={DesiredStatusesItem.ReferralDiscount}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div
                                        className="w-100"
                                        ref={providedDrag?.innerRef}
                                        {...providedDrag?.draggableProps}
                                        {...providedDrag?.dragHandleProps}
                                        {...snapshotDrag}
                                      >
                                        <BillItem
                                          name={intl.formatMessage({ id: 'checkout.referralCode' })}
                                          price={listTotal.referralDiscount}
                                          onClick={() => {
                                            handleRemoveReferral();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              )}
                            </Droppable>
                          ) : (
                            <></>
                          )}
                          {listTotal.totalDiscountGeneralTicket > 0 &&
                          discountsAndRewardsApplied.generalTicketDiscount?.amount ? (
                            <Droppable
                              droppableId={DesiredStatusesItem.GeneralTicketDiscount}
                              key={DesiredStatusesItem.GeneralTicketDiscount}
                              isDropDisabled={true}
                            >
                              {(providedDrop: DroppableProvided) => (
                                <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                                  <Draggable
                                    draggableId={DesiredStatusesItem.GeneralTicketDiscount}
                                    index={0}
                                    key={DesiredStatusesItem.GeneralTicketDiscount}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div
                                        ref={providedDrag?.innerRef}
                                        {...providedDrag?.draggableProps}
                                        {...providedDrag?.dragHandleProps}
                                        {...snapshotDrag}
                                      >
                                        <BillItem
                                          name={`${intl.formatMessage(
                                            { id: 'checkout.general.discount.ticket' },
                                            {
                                              index: convertValue(
                                                discountsAndRewardsApplied.generalTicketDiscount?.amount ?? 0
                                              ),
                                              symbol: discountsAndRewardsApplied.generalTicketDiscount?.type,
                                            }
                                          )}`}
                                          price={listTotal.totalDiscountGeneralTicket}
                                          onClick={() => {
                                            handleRemoveGeneralDiscount();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              )}
                            </Droppable>
                          ) : (
                            <></>
                          )}
                          {listTotal.totalDiscountGeneralMerchandise > 0 &&
                          discountsAndRewardsApplied.generalMerchandiseDiscount?.amount ? (
                            <Droppable
                              droppableId={DesiredStatusesItem.GeneralMerchandiseDiscount}
                              key={DesiredStatusesItem.GeneralMerchandiseDiscount}
                              isDropDisabled={true}
                            >
                              {(providedDrop: DroppableProvided) => (
                                <div
                                  className={discountsAndRewardsApplied.generalTicketDiscount?.amount ? 'm-t-8' : ''}
                                  {...providedDrop.droppableProps}
                                  ref={providedDrop.innerRef}
                                >
                                  <Draggable
                                    draggableId={DesiredStatusesItem.GeneralMerchandiseDiscount}
                                    index={0}
                                    key={DesiredStatusesItem.GeneralMerchandiseDiscount}
                                  >
                                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                      <div
                                        className="w-100"
                                        ref={providedDrag?.innerRef}
                                        {...providedDrag?.draggableProps}
                                        {...providedDrag?.dragHandleProps}
                                        {...snapshotDrag}
                                      >
                                        <BillItem
                                          name={`${intl.formatMessage(
                                            { id: 'checkout.general.discount.merchandise' },
                                            {
                                              index: convertValue(
                                                discountsAndRewardsApplied.generalMerchandiseDiscount?.amount ?? 0
                                              ),
                                              symbol: discountsAndRewardsApplied.generalMerchandiseDiscount?.type,
                                            }
                                          )}`}
                                          price={listTotal.totalDiscountGeneralMerchandise}
                                          onClick={() => {
                                            handleRemoveGeneralMerchandiseDiscount();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              )}
                            </Droppable>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex gap-8 flex-column">
                        {listTotal.total > 0 ? (
                          <BillItem name={intl.formatMessage({ id: 'checkout.total' })} price={listTotal.total} />
                        ) : (
                          <></>
                        )}
                        {listTotal.totalDiscount > 0 ? (
                          <BillItem
                            name={intl.formatMessage({ id: 'checkout.totalDiscount' })}
                            price={listTotal.totalDiscount}
                          />
                        ) : (
                          <></>
                        )}
                        {listTotal.totalRedeeming ? (
                          <BillItem
                            name={intl.formatMessage({ id: 'checkout.redeeming' })}
                            price={listTotal.totalRedeeming}
                            ptsReward={
                              <>
                                {`${discountsAndRewardsApplied?.rewardBalance?.points} ${intl.formatMessage({
                                  id: 'checkout.discountAndRewards.rewards.points',
                                })} / $${formatNumberThousandWithDecimal(listTotal.totalRedeeming ?? 0)}`}
                              </>
                            }
                          />
                        ) : (
                          <></>
                        )}

                        {listTotal.saleTax >= 0 && taxPercent > 0 ? (
                          <Droppable
                            droppableId={DesiredStatusesItem.TaxPercent}
                            key={DesiredStatusesItem.TaxPercent}
                            isDropDisabled={true}
                          >
                            {(providedDrop: DroppableProvided) => (
                              <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                                <Draggable
                                  draggableId={DesiredStatusesItem.TaxPercent}
                                  index={0}
                                  key={DesiredStatusesItem.TaxPercent}
                                >
                                  {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                    <div
                                      ref={providedDrag?.innerRef}
                                      {...providedDrag?.draggableProps}
                                      {...providedDrag?.dragHandleProps}
                                      {...snapshotDrag}
                                    >
                                      <BillItem
                                        name={`${intl.formatMessage(
                                          { id: 'checkout.saleTex.title' },
                                          { index: convertValue(taxPercent, DiscountTypeEnum.Percent) }
                                        )}`}
                                        price={taxDiscount}
                                        onClick={() => {
                                          handleRemoveTaxPercent();
                                        }}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </div>
                            )}
                          </Droppable>
                        ) : (
                          <></>
                        )}
                        {+serviceCharge.chargeValue !== -1 && handleCheckTax() ? (
                          <Droppable
                            droppableId={DesiredStatusesItem.ServiceCharge}
                            key={DesiredStatusesItem.ServiceCharge}
                          >
                            {(providedDrop: DroppableProvided) => (
                              <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                                <Draggable
                                  draggableId={DesiredStatusesItem.ServiceCharge}
                                  index={0}
                                  key={DesiredStatusesItem.ServiceCharge}
                                >
                                  {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                                    <div
                                      ref={providedDrag?.innerRef}
                                      {...providedDrag?.draggableProps}
                                      {...providedDrag?.dragHandleProps}
                                      {...snapshotDrag}
                                    >
                                      <BillItem
                                        name={`${serviceCharge.name ?? serviceChangeNamePromotion} (${convertValue(
                                          serviceCharge.chargeValue ??
                                            serviceChangePromotionAuto ??
                                            serviceChangePromotionManual,
                                          DiscountTypeEnum.Percent
                                        )}%)`}
                                        price={+listTotal.serviceCharge}
                                        onClick={() => {
                                          handleRemoveServiceCharge();
                                        }}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </div>
                            )}
                          </Droppable>
                        ) : (
                          <></>
                        )}
                        <BillItem
                          name={intl.formatMessage({ id: 'checkout.totalPayment' })}
                          price={listTotal.totalPayments}
                          isBoldName={true}
                        />
                      </div>
                    </div>

                    <div className="salon__checkout-block">
                      <div className="d-flex gap-8 flex-column">
                        <BillItem
                          name={intl.formatMessage({ id: 'checkout.payment' })}
                          isBoldName={true}
                          isHidePrice={true}
                        />
                        {listTotal.totalDeposit ? (
                          <BillItem
                            name={intl.formatMessage({ id: 'checkout.deposit' })}
                            price={listTotal.totalDeposit}
                          />
                        ) : (
                          <></>
                        )}
                        {dataSource
                          ?.filter((data) => data)
                          .map((item) => {
                            if (item && item.amount) {
                              return <BillItem name={item?.key} price={parseFloat(item?.amount.replace(/,/g, ''))} />;
                            }
                            return <></>;
                          })}
                        <BillItem
                          name={intl.formatMessage({ id: 'checkout.balanceToPay' })}
                          price={listTotal.totalBalanceTotalPay}
                          isWarning={true}
                        />
                      </div>
                    </div>
                  </DragDropContext>
                  {listPromotion &&
                  listPromotion.data &&
                  listPromotion.data.loyaltyReward &&
                  listPromotion.data.loyaltyReward.reward &&
                  listPromotion.data.loyaltyReward.loyaltyReward &&
                  listTotal.totalPayments > +listPromotion.data.loyaltyReward.saleTicket ? (
                    <div className="salon__checkout-block">
                      <div className="d-flex gap-8 flex-column">
                        <BillItem
                          name={intl.formatMessage({ id: 'checkout.rewarding' })}
                          price={listTotal.totalRedeeming}
                          ptsReward={
                            <>
                              {`${
                                listPromotion &&
                                listPromotion.data &&
                                listPromotion.data.loyaltyReward &&
                                listPromotion.data.loyaltyReward.reward &&
                                formatNumberThousand(
                                  listPromotion.data.loyaltyReward.reward * Math.floor(listTotal.totalPayments)
                                )
                              } ${intl.formatMessage({
                                id: 'checkout.discountAndRewards.rewards.points',
                              })} / $${formatNumberThousandWithDecimal(listTotal.totalPayments ?? 0)}`}
                            </>
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex flex-column align-items-center justify-content-center gap-8 m-t-20 m-b-20">
                    <ButtonStyled
                      content={intl.formatMessage({ id: 'checkout.payAndComplete' })}
                      isPrimary={true}
                      heightButton={76}
                      buttonProps={{
                        className: 'width-266',
                        // disabled: isLoading || appointmentBill.customerId === 0 || !checkIsHaveServiceCreate(),
                        disabled: isLoading || !checkIsHaveServiceCreate(),
                        onClick: () => {
                          if (
                            !!appointmentBill.customer?.tickets?.filter(
                              (ticket) => ticket.status === TicketStatusEnum.Completed
                            )?.length &&
                            discountsAndRewardsApplied?.referralDiscount?.amount
                          ) {
                            NotificationError({
                              contentNoti: intl.formatMessage({
                                id: 'checkout.notification.notAllowedToUseReferralCode',
                              }),
                            });
                          } else {
                            setIsOpenPayAndComplete(true);
                          }
                        },
                        loading: isLoading,
                      }}
                    />
                    <ButtonStyled
                      content={intl.formatMessage({ id: 'checkout.holdThisTicket' })}
                      isPrimary={false}
                      buttonProps={{
                        className: 'width-266',
                        // disabled: isLoading || appointmentBill.customerId === 0 || !checkIsHaveServiceCreate(),
                        disabled: isLoading || !checkIsHaveServiceHold(),
                        onClick: () => {
                          handleSubmitCheckout({
                            status: TicketDTOStatusEnum.Hold,
                          });
                        },
                        loading: isLoading,
                      }}
                    />
                    <ButtonStyled
                      content={intl.formatMessage({ id: 'checkout.redeem' })}
                      isPrimary={false}
                      buttonProps={{
                        className: 'width-266',
                        disabled:
                          isLoading ||
                          appointmentBill.customer?.customerReward?.reduce(
                            (currentValue, item) => currentValue + item.reward,
                            0
                          ) === 0 ||
                          !appointmentBill?.customer,

                        // disabled: isLoading || !checkIsHaveServiceHold(),
                        onClick: () => {
                          if (listSetting?.settingCheckout.securityCode.secureDiscountButton) {
                            setRequirePasswordState({
                              open: true,
                              modalType: ListServicesItem.Redeem,
                            });
                          } else {
                            setOpenModal({
                              ...openModal,
                              openDiscountsAndRewards: true,
                            });
                            setRedeem(true);
                          }
                        },
                        loading: isLoading,
                      }}
                    />
                  </div>
                </DragDropContext>
              )}
            </div>
          </>
        )}
      </Droppable>

      <ModalSelectTechnician
        openModalSelect={openModalSelect}
        setOpenModalSelect={setOpenModalSelect}
        setIdTechnician={setIdTechnician}
        setAppointmentBill={setAppointmentBill}
        setListIdDisable={setListIdDisable}
        listIdDisable={listIdDisable}
        idTechnician={idTechnician}
        listTechnician={listTechnician}
        appointmentBill={appointmentBill}
      />
      {requirePasswordState.open && (
        <RequirePasswordModal
          open={requirePasswordState.open}
          onCancel={onCancelRequirePassword}
          onClickOK={onSuccessRequirePassword}
          passwords={getPasswords()}
          inputProps={{
            inputLabel: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.label`,
            }),
            inputPlaceholder: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.placeholder`,
            }),
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CheckoutBill;
