import { Form, FormItemProps, Switch, SwitchProps } from 'antd';
import React, { ReactNode } from 'react';
import './formSwitch.scss';
type Props = {
  name: string;
  label?: string;
  formItemProps?: FormItemProps;
  switchProps?: SwitchProps;
  content: ReactNode | string;
  className?: string;
};

export const FormSwitch: React.FC<Props> = ({ switchProps, formItemProps, className, ...rest }) => {
  return (
    <div className={'salon__form-switch-box ' + (className || '')}>
      <Form.Item
        {...rest}
        valuePropName="checked"
        {...formItemProps}
        className={`salon__form-switch ${formItemProps?.className ?? ''}`}
      >
        <Switch {...switchProps} />
      </Form.Item>
      <span className="salon__form-switch-title">{rest.content}</span>
    </div>
  );
};
