import React from 'react';

export const SvgRecycleIcon = () => (
  <span>
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33203 0.666667H8.9987C9.36537 0.666667 9.66537 0.966667 9.66537 1.33333C9.66537 1.7 9.36537 2 8.9987 2H0.998698C0.632031 2 0.332031 1.7 0.332031 1.33333C0.332031 0.966667 0.632031 0.666667 0.998698 0.666667H2.66536L3.1387 0.193333C3.2587 0.0733333 3.43203 0 3.60536 0H6.39203C6.56536 0 6.7387 0.0733333 6.8587 0.193333L7.33203 0.666667ZM2.33203 12C1.5987 12 0.998698 11.4 0.998698 10.6667V4C0.998698 3.26667 1.5987 2.66667 2.33203 2.66667H7.66536C8.3987 2.66667 8.9987 3.26667 8.9987 4V10.6667C8.9987 11.4 8.3987 12 7.66536 12H2.33203Z"
        fill="white"
      />
    </svg>
  </span>
);
