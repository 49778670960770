import React from 'react';

export const SvgErrorNotiIcon = () => (
  <span>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM13.5355 6.46447C13.1466 6.07556 12.5102 6.07556 12.1213 6.46447L10 8.58579L7.87868 6.46447C7.48977 6.07556 6.85337 6.07556 6.46447 6.46447C6.07556 6.85337 6.07556 7.48977 6.46447 7.87868L8.58579 10L6.46447 12.1213C6.07556 12.5102 6.07556 13.1466 6.46447 13.5355C6.85337 13.9244 7.48977 13.9244 7.87868 13.5355L10 11.4142L12.1213 13.5355C12.5102 13.9244 13.1466 13.9244 13.5355 13.5355C13.9244 13.1466 13.9244 12.5102 13.5355 12.1213L11.4142 10L13.5355 7.87868C13.9244 7.48977 13.9244 6.85337 13.5355 6.46447ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
        fill="white"
      />
    </svg>
  </span>
);
