import { Avatar, Checkbox } from 'antd';
import { FC, Fragment, MouseEvent } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { SettingCheckin } from '../../../../../../apis/client-axios';
import avatarDefaultSrc from '../../../../../../assets/images/appointment/avatar-default.png';
import { SvgCloseIcon } from '../../../../../../components/@svg/SvgCloseIcon';
import { ServiceItem } from '../../../../../../components/ServiceItem';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { TIME_FORMAT_AM_PM, formatDateByFormatString, formatNumberThousandWithDecimal } from '../../../../../../utils';
import { Booking } from '../../index';
import { StepTwoCommonProps } from '../index';
import _ from 'lodash';

interface BookingInformationProps extends StepTwoCommonProps {}

const renderTechnician = (booking: Booking, intl: IntlShape) => {
  if (booking.isAnyTechnician) {
    return (
      <div className="salon__booking-online-checkbox-any">
        <Checkbox checked={booking.isAnyTechnician}>
          <span className="salon__step-two-left-bottom-checkbox-text">
            {intl.formatMessage({ id: 'bookingOnline.checkboxAnyTechnician' })}
          </span>
        </Checkbox>
      </div>
    );
  } else if (booking.technician) {
    return (
      <div className="d-flex gap-10 align-items-center">
        <Avatar
          src={
            booking.technician &&
            (booking.technician?.defaultAvatar ||
              (booking.technician?.avatar?.preview || booking.technician?.avatar?.source
                ? `${process.env.REACT_APP_API_URL}/static/${
                    booking.technician?.avatar?.preview || booking.technician?.avatar?.source
                  }`
                : avatarDefaultSrc))
          }
          className=""
        />
        <p className=" salon__custom-card-des text-max-1-line m-0">{booking.technician?.name || '--'}</p>
      </div>
    );
  } else {
    return '----';
  }
};

const renderServices = (booking: Booking, settingCheckin: SettingCheckin, intl: IntlShape) => {
  if (booking.service) {
    return (
      <div className="position-relative width-min-content">
        <ServiceItem
          isMerchandise={!!booking?.service?.isMerchandise}
          name={booking.service.name}
          price={
            settingCheckin?.showServicePriceInCheckin ? formatNumberThousandWithDecimal(booking.service.price) : ''
          }
          isCheckinOnline={!settingCheckin?.showServicePriceInCheckin}
          time={booking.service.time?.toString()}
          backgroundColor={booking.service.backgroundColor}
          onClick={() => {}}
        />
      </div>
    );
  } else if (Array.isArray(booking.appointmentServices) && booking.appointmentServices.length > 0) {
    return (
      <div className="position-relative width-min-content d-flex align-items-center gap-10 salon__step-two-custom-booking-service">
        {booking.appointmentServices.map((service) => (
          <Fragment key={service.id}>
            <ServiceItem
              isMerchandise={!!service?.isMerchandise}
              name={service.name}
              price={settingCheckin?.showServicePriceInCheckin ? formatNumberThousandWithDecimal(service.price) : ''}
              isCheckinOnline={!settingCheckin?.showServicePriceInCheckin}
              time={service.time?.toString()}
              backgroundColor={service.backgroundColor}
              onClick={() => {}}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <div className="w-100 text-center">
        <span className="salon__booking-online-pick-service">
          {intl.formatMessage({
            id: 'bookingOnline.pleasePickAService',
          })}
        </span>
      </div>
    );
  }
};

const BookingInformation: FC<BookingInformationProps> = (props) => {
  const { settingCheckin, bookings, setBookings, setNaturalBookings, naturalBookings } = props;

  const intl = useIntl();

  const handleFocusBooking = (focusBooking: Booking) => {
    const cloneBooking = [...bookings].map((booking) => ({ ...booking, isFocus: false }));
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.id === focusBooking.id);

    if (findIndexBookingFocused > -1) {
      cloneBooking[findIndexBookingFocused].isFocus = true;
    }

    setBookings(cloneBooking);
  };

  const handleDeleteBooking = (e: MouseEvent, deleteBooking: Booking) => {
    e.stopPropagation();

    const cloneBooking = _.cloneDeep(bookings);

    const filterBookings = cloneBooking.filter((booking) => booking.id !== deleteBooking.id);
    const filterNaturalBookings = naturalBookings.filter((booking) => booking.id !== deleteBooking.id);

    setNaturalBookings(filterNaturalBookings);
    setBookings(filterBookings);
  };

  return (
    <>
      {settingCheckin && (
        <div className="salon__step-two-right cursor-pointer">
          <span className="salon__step-two-title">{intl.formatMessage({ id: 'bookingOnline.service.selected' })} </span>

          <div className="salon__step-two-right-main">
            {bookings.length > 0 ? (
              <div
                className={`d-flex gap-24 m-t-7 salon__step-two-right-main-item p-b-24 p-t-10 ${
                  bookings.length > 4 ? 'max-height-650' : 'max-height-520'
                }`}
              >
                {bookings.map((booking) => (
                  <div
                    className={`position-relative salon__information-appointment-online-container max-width-262 ${
                      booking.isFocus ? 'border border-color-0090FF' : ''
                    }`}
                    key={booking.id}
                    onClick={() => handleFocusBooking(booking)}
                  >
                    <div className="d-flex gap-18 salon__information-appointment-container">
                      {renderServices(booking, settingCheckin, intl)}
                    </div>
                    <div>{renderTechnician(booking, intl)}</div>
                    <div className="d-flex justify-content-between align-items-center m-t-10">
                      <span>
                        {intl.formatMessage(
                          {
                            id: 'bookingOnline.appointmentTime',
                          },
                          {
                            time: booking.availableTime
                              ? formatDateByFormatString(TIME_FORMAT_AM_PM, booking.availableTime)
                              : '----',
                          }
                        )}
                      </span>
                    </div>
                    <CustomButton
                      buttonProps={{
                        icon: <SvgCloseIcon />,
                        className:
                          'salon__technician-item-preview__deactive-button salon__service-item-preview__deactive-button',
                        onClick: (e) => handleDeleteBooking(e, booking),
                      }}
                      type="primary"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className=" salon__step-two-right-main-no-data">
                <img src="/assets/qr/create.svg" alt="" />
                <p className=" salon__step-two-right-main-des">
                  {intl.formatMessage({ id: 'bookingOnline.service.no.selected' })}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BookingInformation;
