import { useMutation } from '@tanstack/react-query';
import { Form, Spin } from 'antd';
import _ from 'lodash';
import { FC, Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { appointmentApi } from '../../../../../../apis';
import { CalculateAvailableTimeDto, Service, ServiceItems } from '../../../../../../apis/client-axios';
import { FormSelect } from '../../../../../../components/Form/FormSelect';
import { ServiceItem } from '../../../../../../components/ServiceItem';
import { StyledPopup } from '../../../../../../components/StyledPopup';
import {
  DATE_FORMAT_FULL_DATE_3,
  TIME_FORMAT_AM_PM,
  formatDateByFormatString,
  formatNumberThousandWithDecimal,
  toDayjsByTimeZoneServer,
  toDayjsTimeZone,
} from '../../../../../../utils';
import { Booking } from '../../index';
import { Notification, StepTwoCommonProps, n } from '../index';
import NotificationError from '../../../../../../components/HandleShowNotiError';

interface PickServicesProps extends StepTwoCommonProps {
  services: Service[];
  isLoadingService: boolean;
}

const PickServices: FC<PickServicesProps> = (props) => {
  const {
    settingCheckin,
    services,
    form,
    bookings,
    setBookings,
    isLoadingService,
    setNaturalBookings,
    naturalBookings,
  } = props;

  const intl = useIntl();

  const categoryId = Form.useWatch(n('categoryId'), form);

  const [notification, setNotification] = useState<Notification>();

  const filterServices = useMemo(() => {
    return services.filter(
      (service) =>
        service.serviceItems &&
        service.serviceItems.length > 0 &&
        service.serviceItems.filter((service) => !service.isMerchandise && service.isCheckinStands).length > 0
    );
  }, [services]);

  const serviceItems = useMemo(() => {
    let cloneServices = _.cloneDeep(filterServices);

    if (categoryId) {
      cloneServices = cloneServices.filter((service) => service.id === categoryId);
    }

    const findIndexBookingFocused = bookings.findIndex((booking) => booking.isFocus);
    const booking = bookings[findIndexBookingFocused];

    return cloneServices
      .flatMap((service) => service.serviceItems)
      .filter((serviceItem) => {
        if (booking && booking.technician) {
          return (
            serviceItem.isCheckinStands && booking.technician?.skills?.find((skill) => skill.id === serviceItem.id)
          );
        } else return serviceItem.isCheckinStands;
      });
  }, [filterServices, categoryId, bookings]);

  const calculateAvailableTimeMutation = useMutation((payload: CalculateAvailableTimeDto) =>
    appointmentApi.appointmentControllerCalculateAvailableTime({ ...payload, estimate: payload.estimate ?? 15 })
  );

  const onCreateNewBooking = (cloneBooking: Booking[], serviceItem?: ServiceItems) => {
    const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
    const newBookingId = findMaxId ? findMaxId + 1 : 1;

    const newBooking = {
      service: serviceItem,
      isFocus: true,
      isAnyTechnician: true,
      isAppointment: false,
      availableTime: toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3),
      id: newBookingId,
    };

    cloneBooking.push(newBooking);

    // unfocus others booking
    cloneBooking = cloneBooking.map((booking) => {
      if (booking.id === newBookingId) return booking;
      else return { ...booking, isFocus: false };
    });

    const cloneNatureBooking = _.cloneDeep(naturalBookings);
    cloneNatureBooking.push(newBooking);

    setNaturalBookings(cloneNatureBooking);

    return cloneBooking;
  };

  const handleChooseService = async (serviceItem: ServiceItems) => {
    let cloneBooking = _.cloneDeep(bookings);
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);
    if (findIndexBookingFocused > -1) {
      const bookingFocused = cloneBooking[findIndexBookingFocused];

      if (bookingFocused.isAppointment) {
        if (
          !Array.isArray(bookingFocused.appointmentServices) ||
          (Array.isArray(bookingFocused.appointmentServices) && bookingFocused.appointmentServices.length === 0)
        ) {
          bookingFocused.appointmentServices = [serviceItem];
        } else {
          cloneBooking = onCreateNewBooking(cloneBooking, serviceItem);
        }
      } else {
        if (!bookingFocused.service) {
          bookingFocused.service = serviceItem;

          if (!bookingFocused.technician) {
            bookingFocused.availableTime = toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3);
          } else {
            const findBooking = naturalBookings.find((booking) => booking.id === bookingFocused.id);

            if (findBooking && Number(serviceItem.time) <= Number(findBooking.service?.time)) {
              bookingFocused.availableTime = findBooking.availableTime;
            } else {
              let technicianBooking: Booking | undefined = undefined;
              const filterBookingByTechnician = cloneBooking.filter(
                (booking) =>
                  booking.technician &&
                  booking?.technician?.id === bookingFocused?.technician?.id &&
                  booking.id !== bookingFocused.id
              );

              if (filterBookingByTechnician.length > 0) {
                technicianBooking = _.maxBy(filterBookingByTechnician, 'availableTime');
              }

              let estimateTime = 15;

              if (technicianBooking?.service) {
                estimateTime = technicianBooking?.service?.time;
              } else if (technicianBooking?.appointmentServices) {
                estimateTime = technicianBooking?.appointmentServices.reduce(
                  (total, service) => total + Number(service?.time || 0),
                  0
                );
              }
              const technicianTimeBusy = toDayjsByTimeZoneServer(
                technicianBooking?.availableTime ?? new Date(),
                DATE_FORMAT_FULL_DATE_3
              ).add(estimateTime, 'minute');
              try {
                const response = await calculateAvailableTimeMutation.mutateAsync({
                  technicianId: bookingFocused.technician.id,
                  estimate: Number(serviceItem.time ?? 15),
                  technicianTimeBusy: technicianBooking ? technicianTimeBusy.toISOString() : undefined,
                });
                const data = response.data;

                if (!findBooking) {
                  const cloneNatureBooking = _.cloneDeep(naturalBookings);
                  cloneNatureBooking.push(bookingFocused);

                  setNaturalBookings(cloneNatureBooking);
                }

                if (data && data?.isBusy) {
                  setNotification({
                    isBusy: data.isBusy,
                    technician: bookingFocused.technician,
                    availableTime: data.availableTime,
                    service: serviceItem,
                  });
                  return false;
                } else {
                  bookingFocused.availableTime =
                    data.availableTime || toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3);
                }
              } catch (error: any) {
                return NotificationError({ contentNoti: error?.response?.data?.message });
              }
            }
          }
        } else if (bookingFocused.service.id === serviceItem.id) {
          bookingFocused.service = undefined;
        } else {
          cloneBooking = onCreateNewBooking(cloneBooking, serviceItem);
        }
      }
    } else {
      cloneBooking = onCreateNewBooking(cloneBooking, serviceItem);
    }

    setBookings(cloneBooking);
  };

  return (
    <div
      className={`${
        settingCheckin?.allowSelectingServices ? 'salon__step-two-left' : 'salon__step-two-left-unselect'
      } p-r-0`}
    >
      {settingCheckin?.allowSelectingServices && (
        <div className="salon__step-two-left-top">
          <span className="salon__step-two-title p-r-30">
            {intl.formatMessage({ id: 'bookingOnline.service' })} ({`${serviceItems.length ?? 0}`})
          </span>
          <div className="salon__step-two-left-wrap m-t-17">
            {isLoadingService ? (
              <Spin />
            ) : (
              <>
                <FormSelect
                  name={n('categoryId')}
                  selectProps={{
                    placeholder: intl.formatMessage({ id: 'bookingOnline.step2.allCategory' }),
                    options: filterServices.map((item) => ({ label: item.name, value: item.id })),
                  }}
                  formItemProps={{
                    className: 'salon__step-two-custom p-r-30',
                  }}
                />
                <div className="salon__step-two-custom-service-list p-r-30">
                  {(serviceItems as ServiceItems[]).map((serviceItem) => (
                    <Fragment key={serviceItem.id}>
                      <ServiceItem
                        key={serviceItem.id}
                        isMerchandise={false}
                        name={serviceItem.name}
                        price={
                          settingCheckin?.showServicePriceInCheckin
                            ? formatNumberThousandWithDecimal(serviceItem.price)
                            : ''
                        }
                        isCheckinOnline={!settingCheckin?.showServicePriceInCheckin}
                        time={serviceItem.time?.toString()}
                        className={`${
                          bookings
                            .filter((booking) => booking.isFocus)
                            .findIndex((booking) => {
                              if (
                                Array.isArray(booking.appointmentServices) &&
                                booking.appointmentServices.length > 0
                              ) {
                                return (
                                  booking.appointmentServices.findIndex((service) => service.id === serviceItem.id) > -1
                                );
                              }
                              return booking.service?.id === serviceItem.id;
                            }) > -1
                            ? 'active'
                            : ''
                        }`}
                        backgroundColor={serviceItem.backgroundColor}
                        onClick={() => handleChooseService(serviceItem)}
                      />
                    </Fragment>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <StyledPopup
        isOpen={!!notification?.isBusy}
        content={
          <div>
            {intl.formatMessage(
              { id: 'bookingOnline.technician.available' },
              { time: formatDateByFormatString(TIME_FORMAT_AM_PM, notification?.availableTime) }
            )}
          </div>
        }
        onCancel={() => {
          setNotification(undefined);
        }}
        onOk={() => {
          let cloneBooking = _.cloneDeep(bookings);
          const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

          if (findIndexBookingFocused > -1) {
            const booking = cloneBooking[findIndexBookingFocused];
            booking.service = notification?.service;
            booking.availableTime = notification?.availableTime;
          } else {
            cloneBooking = onCreateNewBooking(cloneBooking, notification?.service);
          }

          setBookings(cloneBooking);
          setNotification(undefined);
        }}
        buttonPropsOK={{
          content: intl.formatMessage({ id: 'common.ok' }),
        }}
        buttonPropsCancel={{
          content: intl.formatMessage({ id: 'common.cancel' }),
        }}
      />
    </div>
  );
};

export default PickServices;
