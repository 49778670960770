import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Salon } from '../apis/client-axios';

export interface DataQueryState {
  listSalon: Salon[];
}

export const dataQuerySlice = createSlice({
  name: 'auth',
  initialState: (): DataQueryState => {
    let initState: DataQueryState = {
      listSalon: [],
    };

    return initState;
  },
  reducers: {
    saveListSalons: (state, action: PayloadAction<Salon[]>) => {
      state.listSalon = action.payload;
    },
  },
});

export const { saveListSalons } = dataQuerySlice.actions;
export default dataQuerySlice.reducer;
