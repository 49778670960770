import React from 'react';

export const SvgBookMarkIconLarge = () => (
  <span>
    <svg width="88" height="20" viewBox="0 0 88 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H86.0917C86.8983 0 87.373 0.905873 86.914 1.56911L81.8665 8.86177C81.3925 9.54658 81.3925 10.4534 81.8665 11.1382L86.914 18.4309C87.373 19.0941 86.8983 20 86.0917 20H0V0Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);
