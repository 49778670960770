import { Form } from 'antd';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DeleteBlockTimeDTOTypeEnum } from '../../../../../apis/client-axios/api';
import { FormRadio } from '../../../../../components/Form/FormRadio';
import FormWrap from '../../../../../components/FormWrap';

interface DeleteBlockTimeContentProps {
  repeat: boolean;
  onChangeBlockTime: (type: DeleteBlockTimeDTOTypeEnum) => void;
}

export interface IFormData {
  type: DeleteBlockTimeDTOTypeEnum;
}

const n = (key: keyof IFormData) => key;

const DeleteBlockTimeContent: FC<DeleteBlockTimeContentProps> = ({ repeat, onChangeBlockTime }) => {
  const intl = useIntl();
  const [form] = Form.useForm<IFormData>();

  useEffect(() => {
    form.setFieldsValue({
      [n('type')]: DeleteBlockTimeDTOTypeEnum.Only,
    });
  }, []);

  const options = [
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.event.blocktime.delete.option.first' })}
        </span>
      ),
      value: DeleteBlockTimeDTOTypeEnum.Only,
    },
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.event.blocktime.delete.option.second' })}
        </span>
      ),
      value: DeleteBlockTimeDTOTypeEnum.All,
    },
  ];

  const onFieldsChange = () => {
    const { type } = form.getFieldsValue();
    onChangeBlockTime(type);
  };

  return (
    <div>
      {intl.formatMessage({ id: 'appointment.event.blocktime.doYouWantTo' })}
      &nbsp;
      <span className="font-weight-700 text-transform-lowercase">{intl.formatMessage({ id: 'common.delete' })}</span>
      &nbsp;
      {intl.formatMessage({ id: 'appointment.event.blocktime.thisBlockTime' })}?
      {repeat && (
        <FormWrap name="deleteBlockTime" form={form} onFieldsChange={onFieldsChange}>
          <FormRadio
            name="type"
            options={options}
            formItemProps={{
              className: 'salon__appointment-calendar-blocktime-delete',
            }}
          />
        </FormWrap>
      )}
    </div>
  );
};

export default DeleteBlockTimeContent;
