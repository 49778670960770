import antdEN from 'antd/locale/en_US';
import common from './common_en.json';
import signin from './signin.json';
import sideBar from './sidebar_en.json';
import generalInfo from './generalInfo_en.json';
import bookingOnline from './bookingOnline_en.json';
import employee from './employee_en.json';
import setting from './setting_en.json';
import appointment from './appointment.json';
import customer from './customer_en.json';
import checkout from './checkout_en.json';
import promotions from './promotions_en.json';

import checkIn from './checkin_en.json';
import onlineAppointment from './onlineAppointment_en.json';
import onlineAppointmentNew from './onlineAppointmentNew_en.json';
import timesheet from './timesheet_en.json';
import compensation from './compensation_en.json';
import report from './report_en.json';
import print from './print_en.json';
import saas from './saas_en.json';

const EnLang = {
  messages: {
    ...common,
    ...signin,
    ...sideBar,
    ...generalInfo,
    ...employee,
    ...setting,
    ...appointment,
    ...customer,
    ...checkout,
    ...bookingOnline,
    ...checkIn,
    ...onlineAppointment,
    ...onlineAppointmentNew,
    ...promotions,
    ...timesheet,
    ...compensation,
    ...report,
    ...print,
    ...saas,
  },
  antd: antdEN,
  locale: 'en-US',
};
export default EnLang;
