import React from 'react';
import { useIntl } from 'react-intl';
import { Promotion, ServiceItems } from '../../../../../../apis/client-axios';
import ColWrap from '../../../../../../components/ColWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../../checkoutConstants';

interface IProps {
  promotion?: Promotion;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean
  ) => void;
  onCancelModal: () => void;
  listServiceItems: ServiceItems[];
}

export const OccasionDiscount: React.FC<IProps> = (props) => {
  const {
    promotion,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    confirmAddDiscountsAndRewards,
    onCancelModal,
    listServiceItems,
  } = props;

  const intl = useIntl();

  const onClickApplyOccasion = () => {
    if (discountsAndRewardsApplied?.occasionDiscount) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        occasionDiscount: undefined,
      }));
    } else if (promotion?.occasionReward?.occasionReward) {
      const handleSetDiscountsAndRewards = () => {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          occasionDiscount: {
            amount: promotion.occasionReward.discountAmount || promotion.occasionReward.discountPercent,
            serviceItemIds: promotion.occasionReward.allService
              ? listServiceItems.map((e) => e.id)
              : promotion.occasionReward.discountServices ?? [],
            type: promotion.occasionReward.discountAmount ? DiscountTypeEnum.Dollar : DiscountTypeEnum.Percent,
            name: promotion.occasionReward.occasionPromotion,
          },
        }));
        onCancelModal();
      };
      confirmAddDiscountsAndRewards(handleSetDiscountsAndRewards);
    }
  };

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={[20, 12]}
      isWrap={true}
      styleFill="between"
      className="border-bottom p-t-b-20"
    >
      <ColWrap colProps={{ span: 24 }}>
        <span className="salon__checkout-discount-title">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.4' })}
          &nbsp;
          {promotion?.occasionReward?.occasionPromotion && `[${promotion?.occasionReward?.occasionPromotion}]`}
          {(!promotion?.occasionReward?.occasionReward ||
            (!promotion?.occasionReward.discountServices?.length && !promotion.occasionReward.allService)) && (
            <span className="salon__checkout-discount-not-available m-l-17">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
            </span>
          )}
        </span>
        {promotion?.occasionReward?.occasionReward && (
          <>
            <br />
            <span className="salon__checkout-discount-title-sub">
              {promotion?.occasionReward?.discountPercent || promotion?.occasionReward?.discountAmount
                ? promotion?.occasionReward?.discountPercent
                  ? `${Number(promotion?.occasionReward?.discountPercent)}%`
                  : `$${promotion?.occasionReward?.discountAmount}`
                : `0%`}
              &nbsp;
              {intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.off' })}
              <br />
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.applicableForServices' })}&nbsp;
              {promotion?.occasionReward?.allService
                ? intl.formatMessage({ id: 'checkout.discountAndRewards.allServicesAndMerchandise' })
                : !!promotion?.occasionReward?.discountServices?.length && !!listServiceItems.length
                ? promotion?.occasionReward.discountServices
                    .map((serviceItem) => listServiceItems.find((si) => si.id === serviceItem)?.name)
                    .join(', ')
                : '...'}
            </span>
          </>
        )}
      </ColWrap>

      <ColWrap colProps={{ span: 8 }}>
        <ButtonStyled
          content={
            <span className="salon__checkout-merchandise-modal-keyboard-button-text">
              {!discountsAndRewardsApplied?.occasionDiscount
                ? intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })
                : intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.applied' })}
            </span>
          }
          buttonProps={{
            className: `width-124 height-38 salon__checkout-discount-rewards-button-3${
              discountsAndRewardsApplied?.occasionDiscount ? ' salon__checkout-discount-rewards-applied-btn' : ''
            }`,
            onClick: onClickApplyOccasion,
            disabled:
              !promotion?.occasionReward?.occasionReward ||
              (!promotion?.occasionReward.discountServices?.length && !promotion.occasionReward.allService),
            // || !!discountsAndRewardsApplied?.occasionDiscount
          }}
          isPrimary={true}
        />
      </ColWrap>
    </RowWrap>
  );
};
