import React from 'react';

export const SvgNextIcon = () => (
  <span>
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.501719 4.93746L2.44172 2.99746L0.501719 1.05746C0.306719 0.862458 0.306719 0.547458 0.501719 0.352458C0.696719 0.157458 1.01172 0.157458 1.20672 0.352458L3.50172 2.64746C3.69672 2.84246 3.69672 3.15746 3.50172 3.35246L1.20672 5.64746C1.1133 5.74108 0.986478 5.7937 0.854219 5.7937C0.72196 5.7937 0.595134 5.74108 0.501719 5.64746C0.311719 5.45246 0.306719 5.13246 0.501719 4.93746Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
