import { useMutation, useQuery } from '@tanstack/react-query';
import { Image } from 'antd';
import { toPng } from 'html-to-image';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { employeeApi, fetchApiPrint, promotionApi, settingApi } from '../../../../../../apis';
import {
  Employee,
  RewardBalanceDiscountDTO,
  ServiceTurn,
  SettingCheckout,
  SettingPrinter,
  SettingPrinterCloverPrinterEnum,
  SettingTip,
  TechnicianTurn,
  TechnicianTurnDTO,
  Ticket,
  TicketDisCountDTO,
  TicketMerchandise,
  TicketTransactionTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import avatarDefaultSrc from '../../../../../../assets/images/appointment/avatar-default.png';
import { SvgPrintIcon } from '../../../../../../components/@svg/SvgPrintIcon';
import ColWrap from '../../../../../../components/ColWrap';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import PrintBill from '../../../../../../components/Print/PrintBill';
import RowWrap from '../../../../../../components/RowWrap';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { RootState, useAppDispatch } from '../../../../../../store';
import { setIsLoading, setPrintResponse } from '../../../../../../store/cloverSlice';
import { formatNumberThousandWithDecimal, getStorageByName } from '../../../../../../utils';
import { QUERY_PROMOTION_CHECKOUT, QUERY_SETTING } from '../../../../../../utils/constant';
import { useClover } from '../../../../setting/terminalDeviceSetting/Clover/connection/hooks';
import SvgReloadTechnician from '../../../../../../components/@svg/SvgReloadTechnician';
import { ChangeTip } from './ChangeTip';
import { ITechnicianItem } from '../../..';
import ChangeTechnician from './ChangeTechnician';
import { Permission } from '../../../../../../utils/permission';
import { DiscountTypeEnum } from '../../../checkoutConstants';
import BillItem from '../../../BillItem';
import jsPDF from 'jspdf';
import { SettingPrinterReceiptForEnum } from '../../../../setting/printerSetting';

interface IProps {
  ticket: Ticket;
  className?: string;
  isHiddenCustomer?: boolean;
  settingTip?: SettingTip;
}

interface IOpenModalSaleTicket {
  openChangeTechnician: boolean;
  openChangeTip: boolean;
}

const technicianDiscountConstant: keyof TicketDisCountDTO = 'technicianDiscount';

enum TicketDisCountDTOKeyEnum {
  ReferralDiscount = 'referralDiscount',
  BirthdayDiscount = 'birthdayDiscount',
  WeeklyDiscount = 'weeklyDiscount',
  OccasionDiscount = 'occasionDiscount',
  GeneralTicketDiscount = 'generalTicketDiscount',
  GeneralItemDiscount = 'generalItemDiscount',
  LoyaltyReward = 'loyaltyReward',
  RewardBalance = 'rewardBalance',
  TechnicianDiscount = 'technicianDiscount',
}

export interface ITipInformation {
  technicians?: ITechnicianItem[];
  tip?: number;
  ticketId?: number;
  technicianTurns?: Array<TechnicianTurn>;
}

export interface ITicketDisCountDTO extends TicketDisCountDTO {
  tax: {
    moneyDiscount: number;
    type: '%';
  };
}

export const SaleTicket: React.FC<IProps> = (props) => {
  const { ticket, className, isHiddenCustomer, settingTip } = props;
  const intl = useIntl();

  const [listTechnicianTurn, setListTechnicianTurn] = useState<TechnicianTurn[]>([]);
  const [serviceTurn, setServiceTurn] = useState<ServiceTurn[]>();
  const [openModal, setOpenModal] = useState<IOpenModalSaleTicket>({
    openChangeTechnician: false,
    openChangeTip: false,
  });
  const [technicianTurn, setTechnicianTurn] = useState<TechnicianTurn>();
  const printBillComponentRef = useRef<HTMLDivElement>(null);
  const [listTicketMerchandise, setListTicketMerchandise] = useState<TicketMerchandise[]>([]);
  const [tipInformation, setTipInformation] = useState<ITipInformation>();
  const [settingPrinter, setSettingPrinter] = useState<SettingPrinter>();
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);
  const isHavingPermission = salonPermission?.includes(Permission.CheckOut.CheckOut);
  const salonId = getStorageByName('salonId') as string;
  const { cloverDevice, onConnect } = useClover();

  const dispatch = useAppDispatch();
  const printResponse = useSelector((state: RootState) => state.clover.printResponse);
  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);
  const isLoading = useSelector((state: RootState) => state.clover.isLoading);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  const { data: listSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });

  const { data: listPromotion, refetch: handleRefetchPromotion } = useQuery({
    queryKey: [QUERY_PROMOTION_CHECKOUT],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () => promotionApi.promotionControllerGet(salonId),
  });

  const getDiscountName = (value: keyof TicketDisCountDTO) => {
    switch (value) {
      case TicketDisCountDTOKeyEnum.ReferralDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.1' });
      case TicketDisCountDTOKeyEnum.BirthdayDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.2' });
      case TicketDisCountDTOKeyEnum.WeeklyDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.3' });
      case TicketDisCountDTOKeyEnum.OccasionDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.4' });
      case TicketDisCountDTOKeyEnum.GeneralTicketDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.5' });
      case TicketDisCountDTOKeyEnum.GeneralItemDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.6' });
      case TicketDisCountDTOKeyEnum.LoyaltyReward:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.title.1' });
      case TicketDisCountDTOKeyEnum.RewardBalance:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.title' });
      case TicketDisCountDTOKeyEnum.TechnicianDiscount:
        return intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.technicianDiscount' });
      default:
        return intl.formatMessage({ id: 'checkout.discount.title' });
    }
  };

  const getTypePaymentName = (value: TicketTransactionTypePaymentEnum) => {
    switch (value) {
      case TicketTransactionTypePaymentEnum.CardNumber:
        return intl.formatMessage({ id: 'checkout.payment.cardnumber' });
      case TicketTransactionTypePaymentEnum.Cash:
        return intl.formatMessage({ id: 'checkout.payment.cash' });
      case TicketTransactionTypePaymentEnum.ChargeCard:
        return intl.formatMessage({ id: 'checkout.payment.chargecard' });
      case TicketTransactionTypePaymentEnum.Check:
        return intl.formatMessage({ id: 'checkout.payment.check' });
      case TicketTransactionTypePaymentEnum.GiftCard:
        return intl.formatMessage({ id: 'checkout.giftCard' });
      case TicketTransactionTypePaymentEnum.Orther:
        return 'Orther';
      case TicketTransactionTypePaymentEnum.MultipleChargeCard:
        return intl.formatMessage({ id: 'checkout.multipleChargeCard' });
      default:
        return '...';
    }
  };

  useEffect(() => {
    if (ticket) {
      setTipInformation((prevState) => ({
        ...prevState,
        ticketId: ticket?.id,
        technicianTurns: ticket?.technicianTurns,
      }));
      setListTechnicianTurn(ticket?.technicianTurns || []);
      setListTicketMerchandise(ticket?.ticketMerchandise || []);
    }
  }, [ticket]);

  useEffect(() => {
    if (printResponse) {
      dispatch(setPrintResponse(undefined));

      if (printResponse.getReason() === 'DONE') {
        if (!printResponse.getSuccess()) {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.printLastTicket.failed' }) });
        }
      }
    }
  }, [printResponse, intl, dispatch]);

  const printJobMutation = useMutation((payload: FormData) => fetchApiPrint(payload), {
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: ({ response }) => {
      setIsLoading(false);
      NotificationError({ contentNoti: 'Please connect printer!!' });
    },
  });

  useEffect(() => {
    if (!!listSetting?.data?.settingPrinter?.length) {
      const printerCompensation = listSetting?.data.settingPrinter.find((e) =>
        e.receiptFor.includes(SettingPrinterReceiptForEnum.CHECK_OUT)
      );

      setSettingPrinter(printerCompensation);
    }
  }, [listSetting]);

  const onPrintPdf = async () => {
    setIsLoadingPdf(true);
    const cloneRef = printBillComponentRef.current ? printBillComponentRef.current : printBillComponentRef.current;
    if (!cloneRef) return false;
    cloneRef.style.marginLeft = '10px';
    cloneRef.style.marginRight = '10px';
    const componentWidth = cloneRef.offsetWidth + 20;
    const componentHeight = cloneRef.clientHeight;

    const orientation = componentWidth >= componentHeight ? 'l' : 'p';

    // const convertComponentToPng = await toPng(techDailySummaryRef.current);
    const scale = 153 / componentWidth;

    const pdf = new jsPDF({
      orientation,
      unit: 'px',
      format: [153, 153 * 3.7],
    });

    // pdf.internal.pageSize.width = 153;

    // pdf.internal.pageSize.height = componentHeight * scale;

    await pdf.html(cloneRef, { html2canvas: { scale: scale }, x: 0, y: 0, autoPaging: 'text' });

    const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

    let newUploadFile = new FormData();
    newUploadFile.append('file', blob);

    printJobMutation.mutate(newUploadFile);
  };

  const handlePrintTicket = () => {
    if (!settingPrinter) return false;

    if (settingPrinter?.cloverPrinter === SettingPrinterCloverPrinterEnum.PrinterConnectedViaUsb) {
      onPrintPdf();
      return true;
    }

    onConnect();

    setTimeout(async () => {
      try {
        if (printBillComponentRef.current) {
          const convertComponentToPng = await toPng(printBillComponentRef.current);

          if (convertComponentToPng) {
            cloverDevice.performPrint({
              base64Image: convertComponentToPng,
            });

            setTimeout(() => {
              cloverDevice.performPrint({
                base64Image: convertComponentToPng,
              });
            }, 300);
          }
        }
      } catch (error) {
        throw error;
      }
    }, 900);
  };

  return (
    <div
      className={'salon__transactions-collapse__sale-ticket-collapse__container' + (className ? ' ' + className : '')}
    >
      {!isHiddenCustomer ? (
        <div className="salon__transactions-collapse__sale-ticket-collapse__header background-color-0090FF1A p-l-r-40 p-t-b-8">
          <div className="d-flex gap-8 align-items-center width-350">
            <Image
              src={
                ticket?.customer?.avatar?.preview || ticket?.customer?.avatar?.source
                  ? `${process.env.REACT_APP_API_URL}/static/${
                      ticket?.customer?.avatar?.preview || ticket?.customer?.avatar?.source
                    }`
                  : avatarDefaultSrc
              }
              preview={false}
              className="border-radius-8px width-32 height-32"
            />
            <p className="salon__transactions-collapse__sale-ticket-collapse__content-customer-name">
              {ticket?.customer?.name ?? ''}
            </p>
          </div>
          <CustomButton
            type="primary"
            buttonProps={{
              icon: <SvgPrintIcon />,
              className: 'm-l-auto m-y-auto border-radius-8px width-28 height-28',
              disabled: isLoading || isLoadingPdf,
              onClick: handlePrintTicket,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div className={`${listTicketMerchandise && listTicketMerchandise?.length > 0 ? 'border-bottom-c8cacc' : ''}`}>
        {listTechnicianTurn && listTechnicianTurn?.length > 0 ? (
          listTechnicianTurn?.map((item) => {
            return (
              <div>
                <div className="bg-light d-flex gap-20 justify-content-end w-100 p-l-r-40  p-t-b-8 align-items-center ">
                  <p
                    className="color-0090FF cursor-pointer m-0"
                    onClick={() => {
                      setOpenModal({
                        ...openModal,
                        openChangeTechnician: true,
                      });
                      setTechnicianTurn(item);
                      setServiceTurn(item?.serviceTurns);
                    }}
                  >
                    <SvgReloadTechnician />
                    {intl.formatMessage({ id: 'checkout.transactions.changeTechnician' })}
                  </p>
                  <p className="salon__transactions-collapse__sale-ticket-collapse__content-customer-name p-3">
                    {item?.technician?.name ?? ''}
                  </p>
                  <Image
                    src={
                      item?.technician?.avatar?.preview || item?.technician?.avatar?.source
                        ? `${process.env.REACT_APP_API_URL}/static/${
                            item?.technician?.avatar?.preview || item?.technician?.avatar?.source
                          }`
                        : avatarDefaultSrc
                    }
                    preview={false}
                    className="border-radius-8px width-32 height-32"
                  />
                </div>
                <div className="font-weight-500 p-l-r-40 p-t-b-8">
                  {item?.serviceTurns?.map((serTurn) => {
                    const serviceTax = serTurn.discount as ITicketDisCountDTO;
                    return (
                      <div>
                        <div
                          className="salon__transactions-collapse__sale-ticket-collapse__content-row"
                          key={serTurn.id}
                        >
                          <div className="">
                            {serTurn?.isGeneralService
                              ? intl.formatMessage({ id: 'checkout.generalService' })
                              : serTurn?.service?.name ?? '...'}
                          </div>
                          <div className="text-align-right">
                            ${formatNumberThousandWithDecimal(serTurn.price ?? serTurn.service?.price ?? 0)}
                          </div>
                        </div>
                        {serTurn?.service?.isTaxable && serviceTax?.tax?.moneyDiscount ? (
                          <div className="p-l-10 m-t-5">
                            <BillItem
                              name={`${intl.formatMessage(
                                { id: 'checkout.tax' },
                                {
                                  index: ticket.serviceTax.percentDiscount,
                                  symbol: DiscountTypeEnum.Percent,
                                }
                              )}`}
                              price={formatNumberThousandWithDecimal(serviceTax?.tax?.moneyDiscount ?? 0)}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <div className="salon__transactions-collapse__sale-ticket-collapse__content">
        {/* Services and merchandises */}
        <div className="p-l-r-20 p-t-b-8 d-flex flex-column gap-8">
          <div className="font-weight-500 p-l-r-20">
            {listTicketMerchandise?.map((merchandise) => {
              const merchandiseTax = merchandise.discount as ITicketDisCountDTO;
              return (
                <div>
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={merchandise.id}>
                    <div>{merchandise?.merchandise?.name ?? intl.formatMessage({ id: 'checkout.merchandise' })}</div>
                    <div className="text-align-right">
                      $
                      {formatNumberThousandWithDecimal(
                        merchandise?.merchandisePrice ?? merchandise?.merchandise?.price ?? 0
                      )}
                    </div>
                  </div>
                  {merchandise?.merchandise?.isTaxable ? (
                    <div className="p-l-10 m-t-5">
                      <BillItem
                        name={`${intl.formatMessage(
                          { id: 'checkout.tax' },
                          {
                            index: ticket.serviceTax.percentDiscount,
                            symbol: DiscountTypeEnum.Percent,
                          }
                        )}`}
                        price={formatNumberThousandWithDecimal(merchandiseTax?.tax?.moneyDiscount ?? 0)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}

            {!!ticket?.ticketGiftCard?.length ? (
              <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={'ticketGiftCard'}>
                <div className="">{intl.formatMessage({ id: 'checkout.giftCard' })}</div>
                <div className="text-align-right">
                  $
                  {formatNumberThousandWithDecimal(
                    ticket.ticketGiftCard
                      ?.map((giftCard) => giftCard.giftCardValue)
                      .reduce((prev, cur) => Number(prev) + Number(cur), 0)
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* general discount */}
        <div className="p-20 d-flex flex-column gap-20">
          <div className="p-l-r-20 d-flex flex-column gap-8">
            {(ticket?.discount && !!Object.keys(ticket?.discount).length) ||
            ticket?.technicianTurns
              ?.flatMap((techTurn) => techTurn.serviceTurns)
              ?.find((serTurn) => serTurn.discount?.technicianDiscount) ? (
              <div className="d-flex flex-column gap-8 border-bottom-1px-DBDDDF p-b-8">
                {/* Technician discount */}
                {!!ticket?.technicianTurns
                  ?.flatMap((techTurn) => techTurn.serviceTurns)
                  ?.find((serTurn) => serTurn.discount?.technicianDiscount) && (
                  <div
                    className="salon__transactions-collapse__sale-ticket-collapse__content-row"
                    key={technicianDiscountConstant}
                  >
                    <div className="">{getDiscountName(technicianDiscountConstant)}</div>
                    <div className="text-align-right">
                      $
                      {formatNumberThousandWithDecimal(
                        ticket?.technicianTurns
                          ?.flatMap((techTurn) => techTurn.serviceTurns)
                          ?.reduce(
                            (prev, cur) => prev + (Number(cur.discount?.technicianDiscount?.moneyDiscount) || 0),
                            0
                          ) || 0
                      )}
                    </div>
                  </div>
                )}
                {/* Other discounts */}
                {Object.keys(ticket.discount).map((discount) => (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={discount}>
                    <div className="">
                      {getDiscountName(discount as keyof TicketDisCountDTO)}&nbsp;
                      {ticket.discount[discount as keyof TicketDisCountDTO]?.type === '%'
                        ? `${Number(ticket.discount[discount as keyof TicketDisCountDTO]?.discountApply) ?? 0}%`
                        : ''}
                    </div>
                    <div className="text-align-right">
                      {(discount as keyof TicketDisCountDTO) === 'rewardBalance'
                        ? `${
                            (ticket.discount[discount as keyof TicketDisCountDTO] as RewardBalanceDiscountDTO).point
                          } ${intl.formatMessage({ id: 'checkout.discountAndRewards.rewards.points' })} / `
                        : ''}
                      $
                      {formatNumberThousandWithDecimal(
                        ticket.discount[discount as keyof TicketDisCountDTO]?.moneyDiscount ?? 0
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}

            {Number(ticket.serviceTax?.moneyDiscount) || Number(ticket.serviceCharge?.moneyDiscount) ? (
              <div className="d-flex flex-column gap-8 border-bottom-1px-DBDDDF p-b-8">
                {Number(ticket.serviceTax?.moneyDiscount) ? (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
                    <div className="text-transform-capitalize">
                      {intl.formatMessage({ id: 'checkout.transactions.tax' })}&nbsp;
                      {ticket.serviceTax?.percentDiscount ? `(${Number(ticket.serviceTax?.percentDiscount)}%)` : ''}
                    </div>
                    <div className="text-align-right">${ticket.serviceTax?.moneyDiscount?.toFixed(2)}</div>
                  </div>
                ) : (
                  ''
                )}
                {Number(ticket.serviceCharge?.moneyDiscount) ? (
                  <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
                    <div className="text-transform-capitalize">
                      {ticket.serviceCharge?.name ?? intl.formatMessage({ id: 'checkout.transactions.charge' })}
                      &nbsp;
                      {ticket.serviceCharge?.percentDiscount
                        ? `(${Number(ticket.serviceCharge?.percentDiscount)}%)`
                        : ''}
                    </div>
                    <div className="text-align-right">${ticket.serviceCharge?.moneyDiscount?.toFixed(2)}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">{intl.formatMessage({ id: 'checkout.total' })}</div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.total ?? 0)}
              </div>
            </div>

            {/* total tip */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">
                {intl.formatMessage({ id: 'checkout.total' })}{' '}
                <span className="text-transform-lowercase">
                  {intl.formatMessage({ id: 'checkout.transactions.item.tip' })}
                </span>
                {'  '}
                <span className="cursor-pointer">
                  <SvgReloadTechnician />
                </span>
                <span
                  className=" color-0090FF cursor-pointer"
                  onClick={() =>
                    setOpenModal({
                      ...openModal,
                      openChangeTip: true,
                    })
                  }
                >
                  {intl.formatMessage({ id: 'checkout.transactions.changeTip' })}
                </span>
              </div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.totalTip ?? 0)}
              </div>
            </div>

            {/* total discount */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">{intl.formatMessage({ id: 'checkout.totalDiscount' })} </div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket.totalDiscount ?? 0)}
              </div>
            </div>

            {/* total payment */}
            <div className="salon__transactions-collapse__sale-ticket-collapse__content-row">
              <div className="">
                {intl.formatMessage({ id: 'checkout.total' })}{' '}
                <span className="text-transform-uppercase">
                  {intl.formatMessage({ id: 'checkout.transactions.payments' })}
                </span>
              </div>
              <div className="text-align-right font-weight-600">
                ${formatNumberThousandWithDecimal(ticket?.totalPayment ?? 0)}
              </div>
            </div>
            {Number(ticket.deposit) ? (
              <>
                <div
                  className="salon__transactions-collapse__sale-ticket-collapse__content-row border-bottom-1px-DBDDDF p-b-8"
                  key={'deposit'}
                >
                  <div className="">{intl.formatMessage({ id: 'checkout.deposit' })}</div>
                  <div className="text-align-right font-weight-600">
                    ${formatNumberThousandWithDecimal(ticket.deposit)}
                  </div>
                </div>
                <div className="salon__transactions-collapse__sale-ticket-collapse__content-row" key={'deposit'}>
                  <div className=""></div>
                  <div className="text-align-right font-weight-600">
                    $
                    {formatNumberThousandWithDecimal(
                      (ticket?.totalPayment ?? 0) -
                        (ticket?.discount?.rewardBalance?.moneyDiscount ?? 0) -
                        (ticket.deposit ?? 0)
                    )}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="p-l-r-20 p-t-b-12 m-t-20 salon__transactions-collapse__sale-ticket-collapse__table">
            <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between">
              <ColWrap colProps={{ span: 6, className: 'font-weight-500 ' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.payments' })}</span>
              </ColWrap>
              {/* <ColWrap colProps={{ span: 6, className: 'font-weight-500 text-center' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.cashDiscount' })}</span>
              </ColWrap> */}
              <ColWrap colProps={{ span: 9, className: 'font-weight-500 text-align-right' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.amount' })}</span>
              </ColWrap>
              <ColWrap colProps={{ span: 9, className: 'font-weight-500 text-align-right' }}>
                <span>{intl.formatMessage({ id: 'checkout.transactions.item.tip' })}</span>
              </ColWrap>
            </RowWrap>

            {ticket?.ticketTransaction
              ?.filter(
                (trans) =>
                  trans.typePayment &&
                  trans.typePayment !== TicketTransactionTypePaymentEnum.TipCash &&
                  trans.typePayment !== TicketTransactionTypePaymentEnum.Tip
              )
              ?.map((trans) => (
                <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between" key={trans.id}>
                  <ColWrap colProps={{ span: 6, className: 'font-weight-400 ' }}>
                    <span className="text-transform-capitalize">{getTypePaymentName(trans.typePayment)}</span>
                  </ColWrap>
                  {/* <ColWrap colProps={{ span: 6, className: 'font-weight-400 text-center' }}>
                    <span>{''}</span>
                  </ColWrap> */}
                  <ColWrap colProps={{ span: 9, className: 'font-weight-400 text-align-right' }}>
                    <span>${formatNumberThousandWithDecimal(trans.money ?? 0)}</span>
                  </ColWrap>
                  <ColWrap colProps={{ span: 9, className: 'font-weight-400 text-align-right' }}>
                    <span>
                      {Number(trans.tipMoney) ? `$${formatNumberThousandWithDecimal(trans.tipMoney ?? 0)}` : ''}
                    </span>
                  </ColWrap>
                </RowWrap>
              ))}
          </div>
        </div>
      </div>

      {ticket &&
        createPortal(
          <div className="width-500 d-flex justify-content-center align-items-center position-absolute top-0 left-500-n">
            <PrintBill
              ticket={ticket}
              salon={{
                name: salonInformation?.name || '',
                address: salonInformation?.address,
                phoneNumber: listSetting?.data?.settingBusiness?.phoneNumber,
              }}
              customer={{
                isHideCustomerPhoneNumber: !!listSetting?.data?.settingBusiness?.hideCustomerIncheckinAndCheckOut,
              }}
              ref={printBillComponentRef}
              isHideSupplyAmount={!!!listSetting?.data?.settingCheckout?.printSupplyAmountTicketSummary}
              listPromotion={listPromotion?.data}
            />
          </div>,
          document.body
        )}
      <ChangeTip
        open={openModal.openChangeTip}
        setOpenModal={() =>
          setOpenModal({
            ...openModal,
            openChangeTip: false,
          })
        }
        tipInformation={tipInformation}
        setTipInformation={setTipInformation}
        idTicket={ticket?.id}
        settingTip={settingTip}
      />
      <ChangeTechnician
        open={openModal.openChangeTechnician}
        setOpenModal={() =>
          setOpenModal({
            ...openModal,
            openChangeTechnician: false,
          })
        }
        technicianTurn={technicianTurn}
        serviceTurn={serviceTurn}
        idTicket={ticket?.id}
        listTechnicianTurn={listTechnicianTurn}
        setListTechnicianTurn={setListTechnicianTurn}
        setTipInformation={setTipInformation}
      />
    </div>
  );
};
