import { Image } from 'antd';
import { useIntl } from 'react-intl';
import AvatarDefault from '../../../../assets/images/appointment/avatar-default.png';
import { SvgRemoveTechnicianIcon } from '../../../../components/@svg/SvgRemoveTechnicianIcon';
import ColWrap from '../../../../components/ColWrap';
import RowWrap from '../../../../components/RowWrap';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';

interface IProps {
  onClick?: () => void;
  onClickActiveTechnician: () => void;
  onClickAvatar: () => void;
  name: string | undefined;
  defaultAvatar: string;
  active?: boolean;
}

function BillItemTechnician(props: IProps) {
  const { onClick, onClickActiveTechnician, onClickAvatar, name, defaultAvatar, active } = props;
  const intl = useIntl();
  return (
    <div
      className={`${
        active ? 'salon__checkout-technician-container-active' : 'salon__checkout-technician-container'
      } height-50`}
    >
      <RowWrap
        isAutoFillRow={true}
        isGutter={false}
        gutter={0}
        isWrap={false}
        styleFill="between"
        rowClassName="align-items-center"
      >
        <ColWrap>
          <ButtonIcon
            icon={<SvgRemoveTechnicianIcon />}
            className="m-r-10"
            buttonProps={{
              onClick: onClick,
            }}
          />
        </ColWrap>

        <ColWrap
          colProps={{
            span: 23,
          }}
        >
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              onClickActiveTechnician();
            }}
          >
            <RowWrap
              isAutoFillRow={true}
              isGutter={true}
              gutter={4}
              isWrap={false}
              styleFill="end"
              rowClassName="align-items-center"
            >
              <ColWrap>
                <p
                  className="salon__checkout-technician-name"
                  onClick={(e) => {
                    e.preventDefault();
                    onClickAvatar();
                  }}
                >
                  {name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
                </p>
              </ColWrap>
              <ColWrap>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    onClickAvatar();
                  }}
                >
                  <Image
                    alt={name}
                    className="salon__checkout-technician-avatar"
                    src={
                      defaultAvatar
                        ? defaultAvatar || `${process.env.REACT_APP_API_URL}/static${defaultAvatar}`
                        : AvatarDefault
                    }
                    preview={false}
                  />
                </div>
              </ColWrap>
            </RowWrap>
          </div>
        </ColWrap>
      </RowWrap>
    </div>
  );
}

export default BillItemTechnician;
