import React from 'react';
import { useIntl } from 'react-intl';
import { GeneralDiscountEnum, ListCustomDiscountStatusEnum } from '..';
import ColWrap from '../../../../../../components/ColWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../../checkoutConstants';

interface IProps {
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  onOpenCustomDiscount: (
    status: ListCustomDiscountStatusEnum,
    discountsAndRewardsAppliedKey: GeneralDiscountEnum,
    type: DiscountTypeEnum,
    isMerchandise?: boolean
  ) => void;
  onClickQuickValue: (
    value: number,
    type: ListCustomDiscountStatusEnum,
    discountsAndRewardsAppliedKey: GeneralDiscountEnum,
    isMerchandise?: boolean
  ) => void;
}

export const GeneralMerchandise: React.FC<IProps> = (props) => {
  const { discountsAndRewardsApplied, onOpenCustomDiscount, onClickQuickValue } = props;

  const intl = useIntl();

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={[20, 12]}
      isWrap={true}
      styleFill="between"
      className="p-t-b-20"
    >
      <ColWrap colProps={{ span: 24 }}>
        <span className="salon__checkout-discount-title">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.6' })}
        </span>
        <br />
        <span className="salon__checkout-discount-title-sub">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.sub.6' })}
        </span>
      </ColWrap>

      <ColWrap colProps={{ span: 6 }}>
        <ButtonStyled
          content={intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.custom.price' })}
          buttonProps={{
            className: `width-124 height-38 salon__checkout-discount-rewards-button-3${
              discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar
                ? ' salon__checkout-discount-rewards-applied-btn'
                : ''
            }`,
            onClick: () => {
              onOpenCustomDiscount(
                ListCustomDiscountStatusEnum.ItemDiscount,
                GeneralDiscountEnum.generalMerchandiseDiscount,
                DiscountTypeEnum.Dollar,
                true
              );
            },
            // disabled:
            //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
            //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar,
          }}
          isPrimary={true}
        />
      </ColWrap>
      <ColWrap colProps={{ span: 18 }}>
        <div className="d-flex gap-8">
          <CustomButton
            content={
              <span className="salon__checkout-discount-rewards-button-text">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.10' })}
              </span>
            }
            buttonProps={{
              className: `width-72 height-38 salon__checkout-discount-rewards-button-4 salon__checkout-discount-rewards-button-3${
                !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 10
                  ? ' salon__checkout-discount-rewards-applied-btn'
                  : ''
              }`,
              onClick: () =>
                onClickQuickValue(
                  10,
                  ListCustomDiscountStatusEnum.ItemDiscountPercentage,
                  GeneralDiscountEnum.generalMerchandiseDiscount,
                  true
                ),
              // disabled:
              //   !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 10,
            }}
          />
          <CustomButton
            content={
              <span className="salon__checkout-discount-rewards-button-text">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.15' })}
              </span>
            }
            buttonProps={{
              className: `width-72 height-38 salon__checkout-discount-rewards-button-4 salon__checkout-discount-rewards-button-3${
                !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 15
                  ? ' salon__checkout-discount-rewards-applied-btn'
                  : ''
              }`,
              onClick: () =>
                onClickQuickValue(
                  15,
                  ListCustomDiscountStatusEnum.ItemDiscountPercentage,
                  GeneralDiscountEnum.generalMerchandiseDiscount,
                  true
                ),
              // disabled:
              //   !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 15,
            }}
          />
          <CustomButton
            content={
              <span className="salon__checkout-discount-rewards-button-text">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.20' })}
              </span>
            }
            buttonProps={{
              className: `width-72 height-38 salon__checkout-discount-rewards-button-4 salon__checkout-discount-rewards-button-3${
                !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 20
                  ? ' salon__checkout-discount-rewards-applied-btn'
                  : ''
              }`,
              onClick: () =>
                onClickQuickValue(
                  20,
                  ListCustomDiscountStatusEnum.ItemDiscountPercentage,
                  GeneralDiscountEnum.generalMerchandiseDiscount,
                  true
                ),
              // disabled:
              //   !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 20,
            }}
          />
          <CustomButton
            content={
              <span className="salon__checkout-discount-rewards-button-text">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.25' })}
              </span>
            }
            buttonProps={{
              className: `width-72 height-38 salon__checkout-discount-rewards-button-4 salon__checkout-discount-rewards-button-3${
                !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 25
                  ? ' salon__checkout-discount-rewards-applied-btn'
                  : ''
              }`,
              onClick: () =>
                onClickQuickValue(
                  25,
                  ListCustomDiscountStatusEnum.ItemDiscountPercentage,
                  GeneralDiscountEnum.generalMerchandiseDiscount,
                  true
                ),
              // disabled:
              //   !discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount === 25,
            }}
          />
          <CustomButton
            content={
              <span className="font-size-16 font-weight-700">
                {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.custom' })}
              </span>
            }
            buttonProps={{
              className: `width-127 height-38 salon__checkout-discount-rewards-button-4 salon__checkout-discount-rewards-button-3${
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
                discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Percent
                  ? ' salon__checkout-discount-rewards-applied-btn'
                  : ''
              }`,
              onClick: () => {
                onOpenCustomDiscount(
                  ListCustomDiscountStatusEnum.ItemDiscountPercentage,
                  GeneralDiscountEnum.generalMerchandiseDiscount,
                  DiscountTypeEnum.Percent,
                  true
                );
              },
              // disabled:
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.isCustomAmount &&
              //   discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Percent,
            }}
          />
        </div>
      </ColWrap>
    </RowWrap>
  );
};
