import { ReactNode } from 'react';
import './styledCheckBox.scss';
import { Checkbox, CheckboxProps } from 'antd';

export interface IStyledCheckBoxProps {
  content: ReactNode | string;
  checkboxProps?: CheckboxProps;
}

function StyledCheckBox(props: IStyledCheckBoxProps) {
  const { content, checkboxProps } = props;
  return (
    <div className="salon__styled-checkbox-container">
      <Checkbox className={`salon__styled-checkbox ${checkboxProps?.className ?? ''}`} {...checkboxProps}>
        <span className="salon__styled-checkbox-content">{content}</span>
      </Checkbox>
    </div>
  );
}
export default StyledCheckBox;
