import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../../../../../utils';
import './timeItem.scss';
import { BusyTime } from '../../../../../../apis/client-axios';
import { TimeActive } from '..';

interface listTime {
  timeStart: string;
  timeEnd: string;
}
export interface listTimeDisable {
  timeStart: string;
  timeEnd: string;
}
export interface ITimeItemProps {
  textTime: string;
  listTime: listTime[];
  timeActive?: TimeActive;
  setTimeActive: (time: listTime | undefined) => void;
  resetTimeActive: () => void;
  listTimeDisable?: BusyTime[];
}

export function TimeItem(props: ITimeItemProps) {
  const { textTime, listTime, timeActive, setTimeActive, listTimeDisable } = props;

  const onClickTime = (item: listTime) => {
    setTimeActive(item === timeActive ? undefined : item);
  };
  const checkHoursCompare = (
    checkTimeStart: string,
    checkTimeEnd: string,
    compareTimeStart?: string,
    compareTimeEnd?: string
  ): boolean => {
    return (
      (dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) > dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) < dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT)) ||
      (dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) > dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) < dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT)) ||
      (dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) <= dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) >= dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT))
    );
  };

  return (
    <div className="salon__time-item">
      <span className="salon__time-item-text">{textTime ?? ''}</span>

      {listTime &&
        listTime.length > 0 &&
        listTime.map((item, index) => {
          const isDisabled = listTimeDisable?.some((timeDisable) => {
            return checkHoursCompare(item.timeStart, item.timeEnd, timeDisable.timeStart, timeDisable.timeEnd);
          });
          const isActive = timeActive === item;
          const itemClasses = `salon__time-item-date ${isActive && !isDisabled ? 'salon__time-item-date-active' : ''} ${
            isDisabled ? 'salon__time-item-date-disabled' : ''
          }`;

          return (
            <div
              className="salon__time-item-main"
              onClick={() => {
                if (!isDisabled) {
                  onClickTime(item);
                }
              }}
              key={index}
            >
              <div className={itemClasses}>
                <span className="salon__time-item-date-text">
                  {item?.timeStart} - {item?.timeEnd}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}
