import React from 'react';

export const SvgAppointmentTimePickerIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.25488 9.51143L9.41321 11.6698C9.73821 11.9948 10.2632 11.9948 10.5882 11.6698L12.7465 9.51143C13.2715 8.98643 12.8965 8.08643 12.1549 8.08643H7.83821C7.09654 8.08643 6.72988 8.98643 7.25488 9.51143Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
