import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IListIdTotalPrice } from '../../..';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { RootState } from '../../../../../../store';
import PaymentChargeCard from './PaymentChargeCard';
import { PrintChargeCard } from './PrintChargeCard';

export interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  onCloverClose: () => void;
  onCloverPayment: () => void;
}

export enum ChargeCardEnum {
  Payment = 'payment',
  Print = 'print',
  CreditCardPayment = 'creditCardPayment',
  CardPayment = 'cardPayment',
  Tips = 'tips',
}

const ChargeCard: FC<Props> = (props) => {
  const { open, setOpen, onCloverClose, onCloverPayment } = props;

  const intl = useIntl();
  const [currentScreen, setCurrentScreen] = useState<ChargeCardEnum>(ChargeCardEnum.Payment);

  const deviceEvent = useSelector((state: RootState) => state.clover.deviceEvent);
  const isDeviceReady = useSelector((state: RootState) => state.clover.isDeviceReady);
  const isConnect = useSelector((state: RootState) => state.clover.isConnect);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (isDeviceReady && isConnect) {
      if (deviceEvent?.start) {
        clearTimeout(timeout);
      } else {
        timeout = setTimeout(() => {
          onCloverPayment();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isDeviceReady, deviceEvent?.start, isConnect]);

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      case ChargeCardEnum.Payment:
        return <PaymentChargeCard />;
      case ChargeCardEnum.Print:
        return <PrintChargeCard />;
      default:
        return;
    }
  }, [currentScreen]);

  const onClickButtonPrint = (isPrint: boolean) => {
    setCurrentScreen(ChargeCardEnum.Tips);
  };

  const onCloseModal = () => {
    setOpen(false);

    onCloverClose();
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCloseModal}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.${currentScreen}` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        footer:
          currentScreen === ChargeCardEnum.Print ? (
            <div className="d-flex justify-content-end p-b-20 p-l-r-40">
              <CustomButton
                buttonProps={{
                  className: 'p-b-t-8 p-l-r-24 height-38 font-weight-700 salon__checkout__primary-outlined-btn',
                  onClick: () => onClickButtonPrint(false),
                }}
                type="cancel"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.print.noReceipt' })}
              />
              <CustomButton
                buttonProps={{
                  className: 'p-b-t-8 p-l-r-24 height-38 font-weight-700',
                  onClick: () => onClickButtonPrint(true),
                }}
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.title.print' })}
              />
            </div>
          ) : null,
      }}
    >
      {renderScreen()}
    </StyledModal>
  );
};

export default ChargeCard;
