import React from 'react';
export const SvgAddMoreDataIconExSmall = () => (
  <span>
    <svg width="78" height="80" viewBox="0 0 78 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65.666 5.02553e-05H12.3327C7.7488 -0.015731 4.0199 3.68724 4.00396 8.2713C4.0026 8.65441 4.02781 9.03715 4.07943 9.41677C4.19927 10.3294 5.0363 10.9721 5.94896 10.8522C6.17035 10.8232 6.38364 10.7499 6.57615 10.6368C7.31181 10.2101 8.14906 9.99014 8.99943 10H17.5327C18.5776 10.0024 19.5957 10.3306 20.4453 10.9388C21.2949 11.547 21.9337 12.405 22.2727 13.3933L22.556 14.3033C23.7018 17.6994 26.8819 19.9897 30.466 20H68.9993C69.8733 20.0008 70.7318 20.2307 71.4893 20.6668C72.2832 21.1325 73.3044 20.8665 73.7702 20.0726C73.9196 19.8181 73.9986 19.5285 73.9993 19.2335V8.33349C73.9994 3.73099 70.2683 5.02553e-05 65.666 5.02553e-05Z"
        fill="#C8CACC"
      />
      <path
        d="M73.1781 17.7931C71.9102 17.0544 70.4688 16.6655 69.0014 16.6664H30.4681C29.4233 16.664 28.4051 16.3358 27.5555 15.7276C26.706 15.1194 26.0672 14.2614 25.7281 13.2731L25.4448 12.3631C24.2991 8.96698 21.1189 6.67667 17.5348 6.66636H9.00155C7.56859 6.65307 6.15836 7.02493 4.91827 7.74308C4.26551 8.11284 3.66659 8.57044 3.13827 9.10308C2.35065 9.86893 1.72586 10.786 1.30139 11.7993C0.876921 12.8125 0.661521 13.9011 0.668116 14.9996V54.9996C0.668116 59.602 4.39905 63.3329 9.0014 63.3329H17.4181C17.3748 62.7796 17.3348 62.2296 17.3348 61.6662C17.3348 49.7001 27.0353 39.9995 39.0016 39.9995C50.9678 39.9995 60.6683 49.7 60.6683 61.6662C60.6683 62.2295 60.625 62.7795 60.585 63.3329H69.0017C73.6041 63.3329 77.335 59.602 77.335 54.9996V24.9996C77.3396 23.5385 76.9574 22.1021 76.2273 20.8364C75.4972 19.5707 74.4452 18.5207 73.1781 17.7931Z"
        fill="#DBDDDF"
      />
      <path
        d="M39.0013 80.0005C49.1264 80.0005 57.3345 71.7925 57.3345 61.6673C57.3345 51.5421 49.1264 43.334 39.0013 43.334C28.8761 43.334 20.668 51.5421 20.668 61.6673C20.668 71.7925 28.8761 80.0005 39.0013 80.0005Z"
        fill="#C8CACC"
      />
      <path
        d="M45.668 63.3334H32.3347C31.4142 63.3334 30.668 62.5872 30.668 61.6667C30.668 60.7462 31.4142 60 32.3347 60H45.668C46.5884 60 47.3347 60.7462 47.3347 61.6667C47.3347 62.5872 46.5884 63.3334 45.668 63.3334Z"
        fill="#FAFAFA"
      />
      <path
        d="M39.0027 70.0007C38.0822 70.0007 37.3359 69.2545 37.3359 68.334V55.0007C37.3359 54.0802 38.0822 53.334 39.0027 53.334C39.9231 53.334 40.6694 54.0802 40.6694 55.0007V68.334C40.6692 69.2546 39.9231 70.0007 39.0027 70.0007Z"
        fill="#FAFAFA"
      />
    </svg>
  </span>
);
