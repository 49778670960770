import { useIntl } from 'react-intl';
import {
  AppointmentSettingDTO,
  LineUpTechniciansByDTOOrderEnum,
  LineUpTechniciansByDTOValueEnum,
  SuggestNextTechniciansByDTOOrderEnum,
  SuggestNextTechniciansByDTOValueEnum,
  TurnsSettingDTO,
} from '../../../../../../apis/client-axios/api';
import { IFormData } from './index';

const n = (key: keyof IFormData | keyof AppointmentSettingDTO | keyof TurnsSettingDTO) => key;

export const useDisplaySettingOptions = () => {
  const intl = useIntl();

  const appointmentOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Past' })}
        </span>
      ),
      value: n('pastAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage(
            { id: 'appointment.setting.appointment.checkbox' },
            { title: 'Waiting For Confirmation' }
          )}
        </span>
      ),
      value: n('waitingForConfirmationAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Confirmed' })}
        </span>
      ),
      value: n('confirmedAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Checked In' })}
        </span>
      ),
      value: n('checkedInAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Being Served' })}
        </span>
      ),
      value: n('beingServedAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Completed' })}
        </span>
      ),
      value: n('completedAppointment'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.appointment.checkbox' }, { title: 'Canceled' })}
        </span>
      ),
      value: n('canceledAppointment'),
    },
  ];

  const turnsOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.turns.checkbox' }, { title: 'Served' })}
        </span>
      ),
      value: n('servedTurns'),
    },
    {
      label: (
        <span className="font-size-16 font-weight-400 salon__appointment-setting-form-group-content">
          {intl.formatMessage({ id: 'appointment.setting.turns.checkbox' }, { title: 'Stack' })}
        </span>
      ),
      value: n('stackTurns'),
    },
  ];

  const suggestOrderOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.select' }, { title: 'Most' })}
        </span>
      ),
      value: SuggestNextTechniciansByDTOOrderEnum.MostFirst,
    },
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.select' }, { title: 'Least' })}
        </span>
      ),
      value: SuggestNextTechniciansByDTOOrderEnum.LeastFirst,
    },
  ];

  const lineUpOrderOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.select' }, { title: 'Recent' })}
        </span>
      ),
      value: LineUpTechniciansByDTOOrderEnum.RecentFirst,
    },
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.select' }, { title: 'Early' })}
        </span>
      ),
      value: LineUpTechniciansByDTOOrderEnum.EarlyFirst,
    },
  ];

  const suggestValueOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.suggest.value.turnCount' }, { index: '1' })}
        </span>
      ),
      value: SuggestNextTechniciansByDTOValueEnum.TurnCount,
    },
  ];

  const lineUpValueOptions = [
    {
      label: (
        <span className="font-size-16 font-weight-400">
          {intl.formatMessage({ id: 'appointment.setting.lineUp.value.clockInTime' }, { index: '5' })}
        </span>
      ),
      value: LineUpTechniciansByDTOValueEnum.ClockInTime,
    },
  ];

  return {
    appointmentOptions,
    turnsOptions,
    suggestOrderOptions,
    lineUpOrderOptions,
    suggestValueOptions,
    lineUpValueOptions,
  };
};
