import React from 'react';

export const SvgBookMarkIcon = () => (
  <span>
    <svg width="62" height="20" viewBox="0 0 62 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H60.0833C60.8915 0 61.3659 0.909158 60.9035 1.57209L55.8231 8.85583C55.3436 9.54326 55.3436 10.4567 55.8231 11.1442L60.9035 18.4279C61.3659 19.0908 60.8915 20 60.0833 20H0V0Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);
