import { Form, FormInstance } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { Employee, Service, ServiceItems, SettingCheckin } from '../../../../../apis/client-axios';
import FormWrap from '../../../../../components/FormWrap';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { Booking } from '../index';
import BookingInformation from './BookingInformation';
import PickServices from './Services';
import PickTechnicians from './Technicians';

interface StepTwoProps {
  settingCheckin?: SettingCheckin;
  services: Service[];
  technicians: Employee[];
  isLoadingService: boolean;
  bookings: Booking[];
  setBookings: Dispatch<SetStateAction<Booking[]>>;
  setStep: Dispatch<SetStateAction<number>>;
}

export interface FormData {
  categoryId?: number;
  anyTechnician?: boolean;
}

export interface Notification {
  availableTime?: string | Date;
  isBusy: boolean;
  technician: Employee;
  service?: ServiceItems;
}

export interface StepTwoCommonProps {
  settingCheckin?: SettingCheckin;
  form: FormInstance<FormData>;
  bookings: Booking[];
  naturalBookings: Booking[];
  setBookings: Dispatch<SetStateAction<Booking[]>>;
  setNaturalBookings: Dispatch<SetStateAction<Booking[]>>;
}

export const n = (key: keyof FormData) => key;

const StepTwo: FC<StepTwoProps> = (props) => {
  const { settingCheckin, services, technicians, isLoadingService, bookings, setBookings, setStep } = props;

  const intl = useIntl();

  const [form] = Form.useForm<FormData>();

  const [naturalBookings, setNaturalBookings] = useState<Booking[]>([]);

  return (
    <div className="salon__step-two">
      <FormWrap name="stepTwo" form={form}>
        <div className="salon__step-two-top">
          <PickServices
            settingCheckin={settingCheckin}
            form={form}
            bookings={bookings}
            services={services}
            setBookings={setBookings}
            isLoadingService={isLoadingService}
            setNaturalBookings={setNaturalBookings}
            naturalBookings={naturalBookings}
          />
          <PickTechnicians
            settingCheckin={settingCheckin}
            form={form}
            bookings={bookings}
            technicians={technicians}
            setBookings={setBookings}
            setNaturalBookings={setNaturalBookings}
            naturalBookings={naturalBookings}
          />
          <BookingInformation
            settingCheckin={settingCheckin}
            form={form}
            bookings={bookings}
            setBookings={setBookings}
            setNaturalBookings={setNaturalBookings}
            naturalBookings={naturalBookings}
          />
        </div>
      </FormWrap>

      <div className=" salon__step-two-bottom">
        {settingCheckin?.asksOptionReceivingTextMessage && (
          <ButtonStyled
            content={
              <span className=" salon__step-two-bottom-left-text">
                {intl.formatMessage({ id: 'bookingOnline.skip' })}
              </span>
            }
            isLarge={true}
            buttonProps={{
              className: 'width-184 salon__step-two-bottom-left',
              onClick: () => setStep((prevStep) => prevStep + 1),
            }}
            isPrimary={false}
            isButtonCancel={true}
          />
        )}

        <ButtonStyled
          content={
            <span className=" salon__step-two-bottom-right-text">
              {intl.formatMessage({
                id: 'bookingOnline.next',
              })}
            </span>
          }
          isLarge={true}
          buttonProps={{
            className: 'width-184',
            onClick: () => setStep((prevStep) => prevStep + 1),
          }}
          isPrimary={true}
          isButtonCancel={false}
        />
      </div>
    </div>
  );
};

export default StepTwo;
