import { Header } from 'antd/lib/layout/layout';
import { ReactNode } from 'react';
import './styledHeader.scss';
import PageContainer from '../PageContainer';

export interface IHeaderProps {
  content: ReactNode;
  extraButton?: ReactNode;
}

function StyledHeader(props: IHeaderProps) {
  const { content, extraButton } = props;
  return (
    <div className="salon__styled-header-container">
      <PageContainer paddingPage={20} marginTop={0}>
        <Header className="salon__styled-header">
          <h1 className="salon__styled-header-title">{content}</h1>
          {extraButton}
        </Header>
      </PageContainer>
    </div>
  );
}
export default StyledHeader;
