import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateObject } from 'react-multi-date-picker';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_2 = 'YYYY-MM-DD';
export const DATE_FORMAT_3 = 'MM-DD-YYYY';
export const DATE_FORMAT_FULL_DATE = 'MM/DD/YYYY HH:mm:ss';
export const DATE_FORMAT_FULL_DATE_2 = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_FULL_DATE_3 = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_WO_TIME = 'MM/DD/YYYY HH:mm';
export const DATE_FORMAT_UTC = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DATE_ONLY_FORMAT_UTC = 'YYYY-MM-DDTHH:mm';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_1 = 'HH:mm A';
export const TIME_FORMAT_2 = 'hh:mm A';
export const APPOINTMENT_TIME_FORMAT = 'hh:mma';
export const APPOINTMENT_FULL_TIME_FORMAT = 'h:mmA MMM D, YYYY';
export const CHECKOUT_FULL_TIME_FORMAT = 'hh:mm A, MM/DD/YYYY';
export const PROMOTION_FULL_TIME_FORMAT = 'h:mm A, MM/DD/YYYY';
export const DATE_TIME_WITH_MERIDIEM_FORMAT = 'MM/DD/YYYY, hh:mm A';
export const TIME_FORMAT_AM_PM = 'hh:mm a';
export const TIME_FORMAT_AM_PM_EXPORT = 'hh-mm a';

export const DATE_TIME_WITH_AT_ON = 'hh:mm A, on MM/DD/YYYY';
export const DATE_FORMAT_TEXT_MONTH = 'MMM D, YYYY';
export const DATE_FORMAT_TEXT_MONTH_2 = 'MMM DD';
export const DATE_FORMAT_TEXT_MONTH_REVERSED = 'D MMM, YYYY';

//eg: June 23rd 2017, 03:11 PM
export const DATE_TIME_FORMAT_IN_BILL = 'MMMM Do YYYY, hh:mm A';

//eg: Monday, July 24, 2023
export const DATE_FORMAT_4 = 'dddd, MMMM DD, YYYY';

//eg: Sep 28, 2023, 7:36:45 PM
export const DATE_TIME_FORMAT_IN_REVENUE_TICKET = 'MMM DD, YYYY, hh:mm:ss A';

export const MONTH_YEAR_FORMAT = 'MMM YYYY';

export const HHMMA_TIME_FORMAT = (splitter?: string) => 'hh:mm' + (splitter ?? '') + 'a';

export const timeZoneSalon = (getStorageByName('timeZone') ?? ('America/New_York' as string)) as string;
export const toDayjs = (date: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null): Dayjs | DateObject => {
  if (dayjs.isDayjs(date)) {
    return date;
  } else if (date instanceof Date) {
    return dayjs(date);
  } else if (typeof date === 'string') {
    return dayjs(new Date(date));
  } else if (date instanceof DateObject) {
    return date;
  } else {
    return dayjs();
  }
};

// if you pass time into this it will return america time
// for example In america is 10h, global is 15h and VN is 22h and server placed in VN
// you get new Date() => 22h 1/1
// toDayjsTimeZone(22h 1/1) => 10h 1/1
export const toDayjsTimeZone = (
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | number | null
): Dayjs => {
  const time: string = getStorageByName('timeZone') ?? ('America/New_York' as string);
  if (dayjs.isDayjs(date)) {
    return date.tz(time);
  } else if (date instanceof Date) {
    return dayjs(date).tz(time);
  } else if (typeof date === 'string') {
    return dayjs(new Date(date)).tz(time);
  } else if (date instanceof DateObject) {
    return dayjs(date.toString()).tz(time);
  } else {
    return dayjs().tz(time);
  }
};

export const toDayjsTimeZoneServer = (
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | number | null
): Dayjs => {
  const time: string = process.env.REACT_APP_TIMEZONE_SERVER ?? ('America/New_York' as string);
  if (dayjs.isDayjs(date)) {
    return date.tz(time);
  } else if (date instanceof Date) {
    return dayjs(date).tz(time);
  } else if (typeof date === 'string') {
    return dayjs(new Date(date)).tz(time);
  } else if (date instanceof DateObject) {
    return dayjs(date.toString()).tz(time);
  } else {
    return dayjs().tz(time);
  }
};

export const toStartOfDaysByTimeZoneServer = (date: dayjs.Dayjs | Date | string, format?: string): string | Date => {
  const dateDayjs = format ? dayjs(date, format) : dayjs(date);
  const timeZone: string = process.env.REACT_APP_TIMEZONE_SERVER ?? ('America/New_York' as string);
  return dayjs.tz(dateDayjs.format(DATE_FORMAT), DATE_FORMAT, timeZone).startOf('day').toISOString();
};

export const toDaysByTimeZoneServer = (date: dayjs.Dayjs | Date | string, format: string): string => {
  const timeZone: string = getStorageByName('timeZone') ?? ('America/New_York' as string);
  return dayjs.tz(date, format, timeZone).toISOString();
};

export const toDayjsByTimeZoneServer = (date: dayjs.Dayjs | Date | string, format: string): Dayjs => {
  const timeZone: string = getStorageByName('timeZone') ?? ('America/New_York' as string);
  return dayjs.tz(date, format, timeZone);
};

export const toStartOfDaysByTimeZone = (date: dayjs.Dayjs | Date | string, format?: string): string | Date => {
  const dateDayjs = format ? dayjs(date, format) : dayjs(date);
  return dayjs.tz(dateDayjs.format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon).startOf('day').toISOString();
};

// this function for add timezone for your time
// with example above
// toTimeZone(22h 1/1) => 22h 1/1 in America.
// so if you convert it to global it will be 3h 1/2
export const toTimeZone = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null): Dayjs => {
  const time: string = getStorageByName('timeZone') ?? ('America/New_York' as string);
  const format = formatDateByFormatString(DATE_FORMAT_FULL_DATE_2, date);
  return dayjs.tz(format, time);
};

export const formatUtc = (format?: string, date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  if (format) {
    return date ? toDayjsTimeZone(date).utc().format(format) : toDayjsTimeZone(null).utc().format(format);
  }
  return date ? toDayjsTimeZone(date).utc().toISOString() : toDayjsTimeZone(null).utc().toISOString();
};

export const formatLocal = (format?: string, date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  if (format) {
    return date ? toDayjsTimeZone(date).format(format) : toDayjsTimeZone(null).format(format);
  }
  return date ? toDayjsTimeZone(date).toISOString() : toDayjsTimeZone(null).toISOString();
};

export const formatDateByFormatString = (
  format: string,
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null
) => {
  return date ? toDayjs(date).format(format) : dayjs().format(format);
};

export const formatDateTimeZoneByFormatString = (
  format: string,
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null
) => {
  return date ? toDayjsTimeZone(date).format(format) : toDayjsTimeZone(null).format(format);
};

export const formatTimeZoneToDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  const value = date
    ? toDayjsTimeZone(date).format(DATE_FORMAT_FULL_DATE)
    : dayjs()
        .tz(timeZoneSalon as string)
        .format(DATE_FORMAT_FULL_DATE);

  return new Date(value);
};

export const formatToDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  const value = date ? toDayjs(date).format('YYYY-MM-DDTHH:mm:ss') : dayjs().format('YYYY-MM-DDTHH:mm:ss');

  return new Date(`${value}Z`);
};

export const formatTimeZoneOnlyDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjsTimeZone(date).format(DATE_FORMAT) : toDayjsTimeZone(null).format(DATE_FORMAT);

export const formatDateQuery = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT) : dayjs().format(DATE_FORMAT);

export const formatDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_WO_TIME) : '';

export const formatDateWithMer = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_TIME_WITH_MERIDIEM_FORMAT) : '';

export const formatDateWithAtOn = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_TIME_WITH_AT_ON) : '';

export const formatOnlyDate = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT) : '';
export const formatOnlyDateType3 = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_3) : '';

export const formatDateTextMonth = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_TEXT_MONTH) : '';

export const formatDateTextMonthReversed = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_TEXT_MONTH_REVERSED) : '';

export const formatTime = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(FULL_TIME_FORMAT) : '';

export const formatTimeHHMM = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(TIME_FORMAT) : '';

export const formatTimeHHMMAmPm = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(TIME_FORMAT_AM_PM) : '';

export const formatTimeHHMMa = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(APPOINTMENT_TIME_FORMAT) : '';

export const formatTimeHHMMaWithSplitter = (
  date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null,
  splitter?: string
) => (date ? toDayjs(date).format(HHMMA_TIME_FORMAT(splitter)) : '');

export const formatTimeHHMMaFulltime = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(APPOINTMENT_FULL_TIME_FORMAT).replace(/AM/g, 'am').replace(/PM/g, 'pm') : '';

export const formatFulltimeHmaMMDDYYYY = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(PROMOTION_FULL_TIME_FORMAT).replace(/AM/g, 'am').replace(/PM/g, 'pm') : '';

export const formatDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? toDayjs(date).format(DATE_FORMAT_UTC) : '';

export const formatDateTimeJsonUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) =>
  date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()) : '';

export const _fmUtcToLocalFullDateUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT_FULL_DATE) : '';
};

export const _fmUtcToLocalOnlyDateUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT) : '';
};

export const _fmUtcToLocalFullDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format(DATE_FORMAT_FULL_DATE) : '';
};

export const _fmFullDateTimeUTC = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return dayjs(new Date(`${date}Z`.replace('ZZ', 'Z')).toString()).format();
};

export const formatDateTimeWithMeridiem = (date?: dayjs.Dayjs | Date | string | DateObject | DateObject[] | null) => {
  return date ? toDayjsTimeZone(date).format(DATE_TIME_WITH_MERIDIEM_FORMAT) : '';
};

export const cloverDeviceAddressChecker = (address: string) => {
  const regex =
    /^wss:\/\/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}:(102[4-9]|10[3-9]\d|1[1-9]\d{2}|[2-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])\/remote_pay$/;

  return regex.test(address);
};

export const emailChecker = (email: string) => {
  const regex =
    /^(([^<>()[\]\\~!#$%^&()<>.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
};

export const phoneChecker = (phone: string) => {
  // const regex =
  //   /^(0)[1-9]((\s|\s?\-\s?)?[0-9])([0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]?[0-9]?[0-9]$/;
  const regex = /^\d{10,12}$/;
  return regex.test(phone);
};

export const passwordChecker = (password: string) => {
  const regex = /^((?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,32})$/;
  return regex.test(password);
};

export const noSpecialCharactersChecker = (phone: string) => {
  const regex = /^[a-zA-Z0-9]$/;
  return regex.test(phone);
};

export const isValidUrl = (urlString: string) => {
  const re = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(urlString);
};

export function setStorageByName<T>(key: string, value: T) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getStorageByName<T>(key: string): T | null {
  const item: string | null = window.localStorage.getItem(key);
  if (item === null) {
    return null;
  }
  return JSON.parse(item) as T;
}
export function getSessionStorageByName<T>(key: string): T | null {
  const item: string | null = window.sessionStorage.getItem(key);
  if (item === null) {
    return null;
  }
  return JSON.parse(item) as T;
}

export const removeStorageByName = (key: string) => {
  window.localStorage.removeItem(key);
};
export const removeSessionStorageByName = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const clearAllStorage = () => {
  window.localStorage.clear();
};
export const clearAllSessionStorage = () => {
  window.sessionStorage.clear();
};

export const formatPhoneNumber = (phoneNumber?: string, spliter?: string) => {
  const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !!x?.length
    ? !x[2]
      ? x[1]
      : `${x[1]} ${spliter || '-'} ${x[2]}${x[3] ? ` ${spliter || '-'} ${x[3]}` : ''}`
    : '';
};
export const formatPhoneNumber2 = (phoneNumber?: string, spliter?: string) => {
  const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !!x?.length ? (!x[2] ? x[1] : `${x[1]}${spliter || '-'}${x[2]}${x[3] ? `${spliter || '-'}${x[3]}` : ''}`) : '';
};
export const regexPasswordMin8LettersMin1Number = (password: string) => {
  const regex = /^(?=.*\d).{8,}$/;
  return regex.test(password);
};

export const regexCheckUrl = (url: string) => {
  const regexUrl = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;
  return regexUrl.test(url);
};

export const formatNumberThousand = (numberThousand: string | number, spliter?: string) => {
  return numberThousand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spliter || ',');
};

export const formatNumberThousandWithDecimal = (number: string | number) => {
  return Number(number)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberCashID = (number: string | number) => {
  return Number(number)
    .toString()
    .replace(/\B(?=(\d{4})+(?!\d))/g, ' ') !== '0'
    ? Number(number)
        .toString()
        .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
    : '';
};

export const formatNumberThousandWithDecimalZero = (number: string | number) => {
  return Number(Number(number).toFixed(2))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertFormatNumberToNumber = (number: string | number) => {
  return Number(Number(number?.toString().replace(/,/g, '').replace(/ /g, '') || '').toFixed(2));
};

export const sortDayJS = (days: dayjs.Dayjs[] | Date[] | string[] | DateObject[]) =>
  days.sort((a, b) => {
    const dayA = dayjs(a.toString());
    const dayB = dayjs(b.toString());
    if (dayjs(dayA).isAfter(dayjs(dayB))) return 1;
    else if (dayjs(dayA).isBefore(dayjs(dayB))) return -1;
    else return 0;
  });

export const handleTruncateToTwoDecimal = (value: number): number => {
  if (value > 0) {
    const valueStr = value.toFixed(3);
    const truncatedStr = valueStr.slice(0, -1);
    return parseFloat(truncatedStr);
  }
  return 0;
};

export const reverseArray = (arr: Array<any>) => {
  var reversed = [];
  for (var i = arr.length - 1; i >= 0; i--) {
    reversed.push(arr[i]);
  }
  return reversed;
};

export const regexCheckIpV4 = (ip: string) => {
  const regexUrl = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
  return regexUrl.test(ip);
};

export const regexCheckSupplyCost = (ip: string) => {
  var regex =
    /^(>=|>|=|<|<=)\s*\d+(\.\d*)?\s*,\s*\d+(\.\d*)?(\s*(\|\s*(>=|>|=|<|<=)\s*\d+(\.\d*)?\s*,\s*\d+(\.\d*)?\s*)*)?$/;

  return regex.test(ip);
};

export const convertTimeFormatToDayJS = (time: string | undefined) => {
  if (time && time.split(':').length === 2) {
    return dayjs(time, 'HH:mm');
  } else {
    return undefined;
  }
};

export const convertDayJSToTimeFormat = (date: Date | Dayjs | undefined) => {
  if (date) {
    return dayjs(date).format('HH:mm');
  } else {
    return '';
  }
};
export const formatNumberReport = (number: number) => {
  return number < 10 && number !== 0 ? `0${number}` : `${number}`;
};
export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};
