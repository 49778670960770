import React from 'react';
export const SvgAddMoreDataIconSmall = () => (
  <span>
    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2318_72160)">
        <path
          d="M83.8335 6.28191e-05H17.1669C11.437 -0.0196637 6.77585 4.60905 6.75592 10.3391C6.75422 10.818 6.78574 11.2964 6.85026 11.771C7.00007 12.9118 8.04635 13.7151 9.18717 13.5653C9.46391 13.529 9.73052 13.4374 9.97116 13.296C10.8907 12.7627 11.9373 12.4877 13.0003 12.5001H23.6669C24.9729 12.503 26.2456 12.9132 27.3076 13.6735C28.3696 14.4338 29.1681 15.5063 29.5919 16.7417L29.946 17.8792C31.3782 22.1243 35.3534 24.9872 39.8335 25.0001H88.0001C89.0926 25.001 90.1657 25.2884 91.1126 25.8335C92.1049 26.4157 93.3815 26.0831 93.9637 25.0907C94.1504 24.7726 94.2492 24.4107 94.2501 24.0419V10.4169C94.2502 4.66373 89.5864 6.28191e-05 83.8335 6.28191e-05Z"
          fill="#C8CACC"
        />
        <path
          d="M93.2217 22.2418C91.6367 21.3184 89.8351 20.8324 88.0008 20.8334H39.8342C38.5281 20.8305 37.2555 20.4203 36.1935 19.66C35.1315 18.8997 34.333 17.8272 33.9092 16.5918L33.5551 15.4543C32.1228 11.2092 28.1476 8.34633 23.6676 8.33344H13.001C11.2098 8.31683 9.44698 8.78166 7.89686 9.67934C7.08091 10.1415 6.33226 10.7135 5.67186 11.3793C4.68734 12.3366 3.90635 13.483 3.37576 14.7496C2.84517 16.0161 2.57592 17.3768 2.58417 18.75V68.75C2.58417 74.503 7.24784 79.1666 13.0008 79.1666H23.5217C23.4676 78.475 23.4176 77.7875 23.4176 77.0832C23.4176 62.1256 35.5432 49.9998 50.501 49.9998C65.4588 49.9998 77.5844 62.1254 77.5844 77.0832C77.5844 77.7873 77.5303 78.4748 77.4803 79.1666H88.0012C93.7541 79.1666 98.4178 74.503 98.4178 68.75V31.25C98.4235 29.4236 97.9458 27.6281 97.0332 26.046C96.1206 24.4639 94.8056 23.1514 93.2217 22.2418Z"
          fill="#DBDDDF"
        />
        <path
          d="M50.5006 100C63.1571 100 73.4172 89.7401 73.4172 77.0836C73.4172 64.4271 63.1571 54.167 50.5006 54.167C37.8441 54.167 27.584 64.4271 27.584 77.0836C27.584 89.7401 37.8441 100 50.5006 100Z"
          fill="#C8CACC"
        />
        <path
          d="M58.834 79.1668H42.1674C41.0168 79.1668 40.084 78.234 40.084 77.0834C40.084 75.9328 41.0168 75 42.1674 75H58.834C59.9846 75 60.9174 75.9328 60.9174 77.0834C60.9174 78.234 59.9846 79.1668 58.834 79.1668Z"
          fill="#FAFAFA"
        />
        <path
          d="M50.5014 87.5004C49.3508 87.5004 48.418 86.5676 48.418 85.417V68.7504C48.418 67.5998 49.3508 66.667 50.5014 66.667C51.652 66.667 52.5848 67.5998 52.5848 68.7504V85.417C52.5846 86.5678 51.652 87.5004 50.5014 87.5004Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2318_72160">
          <rect width="100" height="100" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </span>
);
