import { Carousel } from 'antd';
import { Asset } from '../../../../apis/client-axios';
import SvgNextSlice from '../../../../components/@svg/SvgNextSlice';
import SvgPrevSlice from '../../../../components/@svg/SvgPrevSlice';

interface IScreenSaverProps {
  status: boolean;
  screenSaver?: Asset[] | undefined;
  setStatus(status: boolean): void;
  salonHome: boolean;
}

const ScreenSaver: React.FC<IScreenSaverProps> = ({ ...props }) => {
  const { status, screenSaver, setStatus, salonHome } = props;

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
        <SvgNextSlice />
      </div>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block', left: '-50px' }} onClick={onClick}>
        <SvgPrevSlice />
      </div>
    );
  };
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      {salonHome ? (
        <section className="salon__home">
          {screenSaver?.length && screenSaver.length > 0 ? (
            <Carousel autoplay autoplaySpeed={3000} dots={true} arrows={false}>
              {screenSaver?.map((item) => (
                <div onClick={() => setStatus(!status)} key={item.id}>
                  <img className="w-100" src={`${process.env.REACT_APP_API_URL}/static/${item.source}`} alt="" />
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel autoplay autoplaySpeed={3000} dots={true} arrows={false}>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide1.png" alt="" />
              </div>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide2.png" alt="" />
              </div>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide3.png" alt="" />
              </div>
            </Carousel>
          )}
        </section>
      ) : (
        <section className="salon__slider">
          {screenSaver?.length && screenSaver.length > 0 ? (
            <Carousel autoplay autoplaySpeed={3000} dots={true} arrows={true} {...settings}>
              {screenSaver?.map((item) => (
                <div onClick={() => setStatus(!status)} key={item.id}>
                  <img className="w-100" src={`${process.env.REACT_APP_API_URL}/static/${item.source}`} alt="" />
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel autoplay autoplaySpeed={3000} dots={true} arrows={true} {...settings}>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide1.png" alt="" />
              </div>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide2.png" alt="" />
              </div>
              <div onClick={() => setStatus(!status)}>
                <img className="w-100" src="/assets/qr/slide3.png" alt="" />
              </div>
            </Carousel>
          )}
        </section>
      )}
    </>
  );
};

export default ScreenSaver;
