import React from 'react';

export const SvgRingVolume = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97308 5.02852H7.95308C7.59308 5.02852 7.29974 4.73519 7.29974 4.36852V2.34852C7.29974 1.98852 7.59308 1.69519 7.95974 1.69519H7.97974C8.33974 1.69519 8.63308 1.98852 8.63308 2.34852V4.37519C8.63308 4.73519 8.33974 5.02852 7.97308 5.02852ZM12.1664 6.43519C11.9064 6.69519 11.4931 6.69519 11.2331 6.43519L11.2264 6.42186C10.9664 6.16852 10.9664 5.74852 11.2264 5.49519L12.6731 4.06186C12.9264 3.80852 13.3464 3.80852 13.5997 4.06186C13.8597 4.31519 13.8597 4.73519 13.5997 4.98852C13.1797 5.40852 12.5797 6.01519 12.1664 6.43519ZM3.77308 6.42852C4.02641 6.68852 4.44641 6.68852 4.70641 6.42852C4.95974 6.17519 4.95974 5.75519 4.70641 5.50186L3.27308 4.05519C3.01974 3.79519 2.59974 3.79519 2.34641 4.05519L2.33308 4.06186C2.07308 4.32186 2.07308 4.73519 2.33308 4.98852C2.75308 5.40186 3.35974 6.00852 3.77308 6.42852ZM0.50641 11.1819C4.77974 7.40852 11.2197 7.40852 15.4931 11.1819C16.0597 11.6819 16.0597 12.5685 15.5331 13.1019L14.7197 13.9152C14.2397 14.3952 13.4797 14.4352 12.9464 14.0152L11.6197 12.9619C11.2997 12.7085 11.1131 12.3219 11.1131 11.9152V10.1752C9.09308 9.51519 6.91308 9.52186 4.89974 10.1752V11.9085C4.89974 12.3152 4.71308 12.7019 4.39308 12.9552L3.05974 14.0152C2.52641 14.4352 1.76641 14.3952 1.28641 13.9152L0.473076 13.1019C-0.0602569 12.5685 -0.0602569 11.6819 0.50641 11.1819Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);

export default SvgRingVolume;
