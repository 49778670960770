import React from 'react';

export const SvgAppointmentGroup = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.334 1.83325H5.33398C4.60065 1.83325 4.00065 2.43325 4.00065 3.16659V11.1666C4.00065 11.8999 4.60065 12.4999 5.33398 12.4999H13.334C14.0673 12.4999 14.6673 11.8999 14.6673 11.1666V3.16659C14.6673 2.43325 14.0673 1.83325 13.334 1.83325ZM2.00065 4.49992C1.63398 4.49992 1.33398 4.79992 1.33398 5.16659V13.8333C1.33398 14.5666 1.93398 15.1666 2.66732 15.1666H11.334C11.7007 15.1666 12.0007 14.8666 12.0007 14.4999C12.0007 14.1333 11.7007 13.8333 11.334 13.8333H3.33398C2.96732 13.8333 2.66732 13.5333 2.66732 13.1666V5.16659C2.66732 4.79992 2.36732 4.49992 2.00065 4.49992ZM10.0007 7.83325H12.0007C12.3673 7.83325 12.6673 7.53325 12.6673 7.16659C12.6673 6.79992 12.3673 6.49992 12.0007 6.49992H10.0007V4.49992C10.0007 4.13325 9.70065 3.83325 9.33398 3.83325C8.96732 3.83325 8.66732 4.13325 8.66732 4.49992V6.49992H6.66732C6.30065 6.49992 6.00065 6.79992 6.00065 7.16659C6.00065 7.53325 6.30065 7.83325 6.66732 7.83325H8.66732V9.83325C8.66732 10.1999 8.96732 10.4999 9.33398 10.4999C9.70065 10.4999 10.0007 10.1999 10.0007 9.83325V7.83325Z"
        fill="#56595C"
      />
    </svg>
  </span>
);
