import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { FormRadio } from '../../../../../components/Form/FormRadio';
import { FormSwitch } from '../../../../../components/Form/FormSwitch';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { IFilterTechnician } from '../../index';
import '../style.scss';
import { useEffect } from 'react';

export interface Props {
  open: boolean;
  onCancelModal: () => void;
  setFilterTechnician: (filterTechnician: IFilterTechnician) => void;
  filterTechnician: IFilterTechnician;
}

export interface IFormData {
  all: boolean;
  today: boolean;
  clocked: boolean;
  order: number;
}

const n = (key: keyof IFormData) => key;

function ModalTechnicianList<T extends object>(props: Props) {
  const { open, onCancelModal, setFilterTechnician, filterTechnician } = props;
  const intl = useIntl();
  const [form] = Form.useForm<IFormData>();

  useEffect(() => {
    form.setFieldsValue({
      [n('all')]: filterTechnician.isAll,
      [n('today')]: filterTechnician.isAll ? true : filterTechnician.filterBy === 'WORK_TODAY' ? true : false,
      [n('clocked')]: filterTechnician.isAll ? true : filterTechnician.filterBy === 'CLOCKED_IN' ? true : false,
      [n('order')]: filterTechnician.orderBy === 'NAME' ? 1 : filterTechnician.orderBy === 'CLOCK_TIME' ? 2 : undefined,
    });
  }, [filterTechnician]);

  const onSubmit = () => {
    const data = form.getFieldsValue();
    const dataFilter: IFilterTechnician = {
      isAll: data.all,
      filterBy:
        (!data.today && !data.clocked) || data.all
          ? undefined
          : data.today
          ? 'WORK_TODAY'
          : data.clocked
          ? 'CLOCKED_IN'
          : undefined,
      orderBy: !data.order ? undefined : data.order === 1 ? 'NAME' : data.order === 2 ? 'CLOCK_TIME' : undefined,
    };
    setFilterTechnician(dataFilter);
    onCancelModal();
  };
  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-option-modal-heading">
            {intl.formatMessage({ id: 'checkout.option.technician' })}
          </div>
        ),
        className: 'salon__checkout-option-modal',
        width: 700,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.option.technician.cancel' })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: onCancelModal,
                }}
                isPrimary={false}
                isButtonCancel={true}
              />
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.option.technician.save' })}
                buttonProps={{
                  className: 'width-100 height-38 m-0',
                  onClick: () => {
                    onSubmit();
                  },
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap
        form={form}
        name="searchTechnicianCheckout"
        layout="vertical"
        className="w-100 salon__checkout-option-modal-form"
      >
        <div className="salon__checkout-option-technician">
          <div className="salon__checkout-option-technician-top">
            <span className="salon__checkout-option-technician-title">
              {intl.formatMessage({ id: 'checkout.option.technician.title.1' })}
            </span>
            <FormSwitch
              name={n('all')}
              content={
                <span className="salon__checkout-option-technician-des">
                  {intl.formatMessage({ id: 'checkout.option.technician.switch.1' })}
                </span>
              }
              formItemProps={{
                className: 'm-b-0',
              }}
              switchProps={{
                onChange: (e) => {
                  if (e) {
                    form.setFieldValue('today', true);
                    form.setFieldValue('clocked', true);
                  } else {
                    form.setFieldValue('today', false);
                    form.setFieldValue('clocked', false);
                  }
                },
              }}
            />
            <FormSwitch
              name={n('today')}
              content={
                <div>
                  <span className="salon__checkout-option-technician-des">
                    {intl.formatMessage({ id: 'checkout.option.technician.switch.2' })}
                  </span>
                  <br />
                  <span className="salon__checkout-option-technician-des-sub">
                    {intl.formatMessage({ id: 'checkout.option.technician.sub.switch.2' })}
                  </span>
                </div>
              }
              switchProps={{
                onChange: (e) => {
                  if (!e) {
                    form.setFieldValue('all', false);
                  }
                  const clockedValue = form.getFieldValue('clocked');
                  if (clockedValue && e) {
                    form.setFieldValue('all', true);
                  }
                },
              }}
            />
            <FormSwitch
              name={n('clocked')}
              content={
                <div>
                  <span className="salon__checkout-option-technician-des">
                    {intl.formatMessage({ id: 'checkout.option.technician.switch.3' })}
                  </span>
                  <br />
                  <span className="salon__checkout-option-technician-des-sub">
                    {intl.formatMessage({ id: 'checkout.option.technician.sub.switch.3' })}
                  </span>
                </div>
              }
              switchProps={{
                onChange: (e) => {
                  if (!e) {
                    form.setFieldValue('all', false);
                  }
                  const todayValue = form.getFieldValue('today');
                  if (todayValue && e) {
                    form.setFieldValue('all', true);
                  }
                },
              }}
            />
          </div>
          <div className="salon__checkout-option-technician-bottom">
            <span className="salon__checkout-option-technician-title">
              {intl.formatMessage({ id: 'checkout.option.technician.title.2' })}
            </span>
            <FormRadio
              name={n('order')}
              formRadioGroupProps={{
                onChange(e) {
                  const value = e.target.value;
                },
              }}
              formItemProps={{
                className: 'salon__checkout-option-technician-radio',
              }}
              options={[
                {
                  value: 1,
                  label: (
                    <span className="salon__checkout-option-technician-des">
                      {intl.formatMessage({ id: 'checkout.option.technician.radio.1' })}
                    </span>
                  ),
                },
                {
                  value: 2,
                  label: (
                    <span className="salon__checkout-option-technician-des">
                      {intl.formatMessage({ id: 'checkout.option.technician.radio.2' })}
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </FormWrap>
    </StyledModal>
  );
}

export default ModalTechnicianList;
