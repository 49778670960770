import { useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../../apis';
import { Customer, CustomerRecentCheckin, Promotion } from '../../../../../apis/client-axios';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import TableWrap from '../../../../../components/TableWrap';
import { formatPhoneNumber } from '../../../../../utils';
import { QUERY_CUSTOMER } from '../../../../../utils/constant';
import { IAppointmentBill } from '../../index';
import '../style.scss';
import { AxiosResponse } from 'axios';
import { IRedeemOptionLoyaltyReward, RedeemOption } from '../../../promotions/LoyaltyReward';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../checkoutConstants';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import KeyboardWrapper from '../../../../../components/KeyboardWrapper';

export interface Props<T extends object> {
  open: boolean;
  appointmentBill: IAppointmentBill;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
}

interface IFilter {
  fullTextSearch: string;
}

const initialFilters: IFilter = {
  fullTextSearch: '',
};
function ModalCustomer<T extends object>(props: Props<T>) {
  const { open, appointmentBill, onCancelModal, setAppointmentBill, listPromotion, setDiscountsAndRewardsApplied } =
    props;
  const [form] = Form.useForm();
  const fullSearchTextValue = Form.useWatch('fullTextSearch', form);

  const intl = useIntl();
  const [filters, setFilters] = useState(initialFilters);
  const [inputName, setInputName] = useState<string | undefined>('fullTextSearch');
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const timeout: any = useRef(null);

  const { data: listDataCustomer, isLoading: isLoading } = useQuery({
    queryKey: [QUERY_CUSTOMER, filters],
    queryFn: () => customerApi.customerControllerGetRecentCheckins(1, undefined, '', filters.fullTextSearch),
    enabled: true,
    staleTime: 1000,
  });
  const onFinishSearch = ({ fullTextSearch }: { fullTextSearch: string }) => {
    setFilters({ ...filters, fullTextSearch: fullTextSearch });
  };
  const handleSaveValue = (record: Customer) => {
    setAppointmentBill({
      ...appointmentBill,
      customer: record,
      customerId: record.id,
    });
    // auto select the highest redeem option in rewards
    if (
      !!listPromotion?.data?.loyaltyReward?.redeemOptions?.length &&
      listPromotion?.data?.loyaltyReward?.autoRewardPoint &&
      !!record?.customerReward?.length
    ) {
      const redeemOptions = listPromotion?.data?.loyaltyReward?.redeemOptions as IRedeemOptionLoyaltyReward[];
      const customerRewardPoints =
        record?.customerReward?.reduce((currentValue, item) => currentValue + item.reward, 0) || 0;
      const maxRedeemOption: IRedeemOptionLoyaltyReward | undefined = !customerRewardPoints
        ? undefined
        : redeemOptions.reduce((prev, cur) => {
            return cur
              ? customerRewardPoints >= (cur.redeem || 0) && cur.redeem > (prev?.redeem ?? 0)
                ? cur
                : prev
              : prev;
          }, undefined as IRedeemOptionLoyaltyReward | undefined);

      if (maxRedeemOption) {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          rewardBalance: {
            amount: maxRedeemOption.for?.amount,
            points: maxRedeemOption.redeem,
            isCustomAmount: false,
            type: maxRedeemOption.for?.type ? (maxRedeemOption.for?.type as DiscountTypeEnum) : DiscountTypeEnum.Dollar,
          },
          referralDiscount: undefined,
        }));
      } else {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          referralDiscount: undefined,
        }));
      }
    } else {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        referralDiscount: undefined,
      }));
    }
    onCancelModal();
  };

  const columnsCustomer: ColumnType<Customer>[] = [
    {
      key: 0,
      title: intl.formatMessage({ id: 'checkout.option.phoneNumber' }),
      width: 170,
      align: 'left',
      render: (text, item: Customer) => (
        <span className="font-size-16 font-weight-400">
          {item.phoneNumber ? formatPhoneNumber(item.phoneNumber, ' - ') : '--'}
        </span>
      ),
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.name' }),
      width: 272,
      align: 'left',
      render: (text, item: Customer) => <span className="font-size-16 font-weight-400">{item.name}</span>,
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.paymentCard' }),
      width: 142,
      align: 'left',
      render: (text, item: CustomerRecentCheckin) => (
        <span>{item.cardInformation ? `**** **** ${item.cardInformation.cardLast4}` : '--'}</span>
      ),
    },
    {
      key: 1,
      title: intl.formatMessage({ id: 'checkout.option.referralcode' }),
      width: 136,
      align: 'left',
      render: (text, item: Customer) => <span>{item.phoneNumber ? `R${item.phoneNumber}` : '--'}</span>,
    },
  ];

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        setFilters((prev) => ({ ...prev, fullTextSearch: value ?? '' }));
      }, 500);
    },
    [timeout]
  );

  useEffect(() => {
    debounceSearch(fullSearchTextValue);
  }, [fullSearchTextValue]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-option-modal-heading">
            {intl.formatMessage({ id: 'checkout.option.customer' })}
          </div>
        ),
        className: 'salon__checkout-option-modal salon__checkout-option-modal-list-customer',
        width: 1000,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        onFinish={onFinishSearch}
        name="searchTechnicianCustomerCheckout"
        layout="vertical"
        className="w-100 salon__checkout-option-modal-form"
      >
        <FormInputSearch
          name={'fullTextSearch'}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'customer.placeholder.search' }),
            className: 'width-920 salon__checkout-option-modal-form-search',
            onFocus: () => setInputName('fullTextSearch'),
            inputMode: 'none',
            onKeyDown: (event) => {
              const value = event.key;
              if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
              if (value === 'Enter') {
                form.submit();
              }
              if (value !== 'Backspace' && value !== 'Enter' && event?.key?.length < 2)
                keyboardRef.current?.handleButtonClicked(value);

              return event.preventDefault();
            },
          }}
          formItemProps={{
            rules: [
              {
                transform: (value) => {
                  return value?.trim();
                },
              },
            ],
            className: 'm-0',
          }}
        />

        <div className="salon__checkout-table-customer m-t-12">
          <TableWrap
            data={listDataCustomer?.data.content ?? []}
            columns={columnsCustomer}
            total={0}
            size={0}
            page={1}
            setPage={() => {}}
            setSize={() => {}}
            onRowClick={(record) => {
              handleSaveValue(record);
            }}
            isLoading={isLoading}
            showSizeChanger
            isHidePagination={true}
            isScroll={true}
            scrollValue={{ y: 216 }}
            isPointer={true}
          />
        </div>
        <div className="salon__information min-height-50 p-t-0 bg-white">
          <div className="salon__step-one">
            <div className="salon__form-keyboard">
              <KeyboardWrapper
                onNext={() => {}}
                onFinish={() => {
                  form.submit();
                }}
                form={form}
                maxLength={255}
                inputName={inputName}
                keyboardRef={keyboardRef}
                keyboardProps={{
                  inputPattern: undefined,
                }}
              />
            </div>
          </div>
        </div>
      </FormWrap>
    </StyledModal>
  );
}

export default ModalCustomer;
