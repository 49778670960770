import { useMutation } from '@tanstack/react-query';
import { Avatar, Card } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import avatarDefaultSrc from '../../../../../assets/images/appointment/avatar-default.png';
import { useIntl } from 'react-intl';
import { CalculateAvailableTimeDto, Employee, Salon } from '../../../../../apis/client-axios';
import { DATE_FORMAT } from '../../../../../utils';
import { StepTwoCommonProps, n } from '../index';
import { appointmentApi } from '../../../../../apis';
import { FormCheckbox } from '../../../../../components/Form/FormCheckbox';
import PickATime, { TimeActive } from '../PickATime';
import { DateObject } from 'react-multi-date-picker';

dayjs.extend(isSameOrBefore);

interface PickTechniciansProps extends StepTwoCommonProps {
  technicians: Employee[];
  statusAnyTechnician?: boolean;
  setStatusAnyTechnician: (statusAnyTechnician: boolean) => void;
  dataSalon?: Salon;
}

const PickTechnicians: FC<PickTechniciansProps> = (props) => {
  const { technicians, form, bookings, setBookings, statusAnyTechnician, setStatusAnyTechnician, dataSalon } = props;
  const [openPickATime, setOpenPickATime] = useState<boolean>(false);
  const [technician, setTechnician] = useState<Employee>();
  const intl = useIntl();
  const filterTechnicians = useMemo(() => {
    const findIndexBookingFocused = bookings.findIndex((booking) => booking.isFocus);
    const booking = bookings[findIndexBookingFocused];

    return technicians.filter((technician) => {
      if (booking && booking.service)
        return technician.skills.findIndex((skill) => skill.id === booking.service?.id) > -1;
      else return technician;
    });
  }, [bookings, technicians]);

  const calculateAvailableTimeMutation = useMutation((payload: CalculateAvailableTimeDto) =>
    appointmentApi.appointmentControllerCalculateAvailableTime({ ...payload, estimate: payload.estimate ?? 15 })
  );

  useEffect(() => {
    let isAnyTechnician = false;

    if (bookings.length > 0) {
      isAnyTechnician = !!bookings.find((booking) => booking.isFocus)?.isAnyTechnician;
    }

    form.setFieldsValue({
      [n('anyTechnician')]: isAnyTechnician,
    });
  }, [bookings]);

  const handleChooseTechnician = async (
    technician?: Employee,
    timeActive?: TimeActive,
    selectedDate?: DateObject | DateObject[] | null | Date
  ) => {
    const cloneBooking = [...bookings];
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

    const formattedDate = selectedDate ? dayjs(selectedDate.toString()).format(DATE_FORMAT) : null;

    const availableTime = `${timeActive?.timeStart}${' '}${formattedDate}`;

    if (findIndexBookingFocused > -1) {
      const booking = cloneBooking[findIndexBookingFocused];
      if (statusAnyTechnician) {
        if (technician) {
          if (!booking.technician) {
            booking.isAnyTechnician = false;
            booking.technician = technician;
            booking.availableTime = availableTime;
            booking.isFocus = true;
          } else {
            const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
            booking.isFocus = false;
            cloneBooking.push({
              isFocus: true,
              isAnyTechnician: false,
              isAppointment: false,
              technician,
              availableTime: availableTime,
              id: findMaxId ? findMaxId + 1 : 1,
            });
          }
        } else {
          booking.isAnyTechnician = true;
          booking.availableTime = availableTime;
          booking.isFocus = true;
        }
      } else {
        booking.isAnyTechnician = true;
        booking.availableTime = availableTime;
        booking.isFocus = true;
        booking.technician = undefined;
        setStatusAnyTechnician(true);
      }
    } else {
      const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
      cloneBooking.push({
        isFocus: true,
        isAnyTechnician: false,
        isAppointment: false,
        technician,
        availableTime: availableTime,
        id: findMaxId ? findMaxId + 1 : 1,
      });
    }
    setBookings(cloneBooking);
    setOpenPickATime(false);
  };
  const handleChooseService = () => {};

  const handleOpenPickATime = async (technician: Employee) => {
    let cloneBooking = _.cloneDeep(bookings);
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);
    if (findIndexBookingFocused > -1) {
      const bookingFocused = cloneBooking[findIndexBookingFocused];
      if (!bookingFocused.technician && bookingFocused.service) {
        setTechnician(technician);
        setOpenPickATime(true);
        setStatusAnyTechnician(!!technician);
      } else {
        if (!bookingFocused.isAnyTechnician && bookingFocused.technician) {
          bookingFocused.isFocus = false;
          const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
          cloneBooking.push({
            isFocus: true,
            isAnyTechnician: false,
            isAppointment: false,
            technician,
            availableTime: undefined,
            id: findMaxId ? findMaxId + 1 : 1,
          });
          setBookings(cloneBooking);
        } else {
          bookingFocused.technician = technician;
          bookingFocused.isAnyTechnician = false;
          setBookings(cloneBooking);
        }
      }
    } else {
      const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
      cloneBooking.push({
        isFocus: true,
        isAnyTechnician: false,
        isAppointment: false,
        technician,
        availableTime: undefined,
        id: findMaxId ? findMaxId + 1 : 1,
      });
      setBookings(cloneBooking);
    }
  };
  return (
    <>
      <div className={`salon__step-two-left-bottom `}>
        <span className="salon__step-two-title">
          {intl.formatMessage({ id: 'bookingOnline.technician' })} ({`${filterTechnicians?.length ?? 0}`})
        </span>
        <FormCheckbox
          name={n('anyTechnician')}
          content={
            <span className="salon__step-two-left-bottom-checkbox-text">
              {intl.formatMessage({ id: 'bookingOnline.checkbox' })}
            </span>
          }
          formItemProps={{
            className: 'salon__step-two-custom salon__step-two-custom-select',
          }}
          checkboxProps={{
            className: 'salon__step-two-custom-checkbox',
            onChange: (e: CheckboxChangeEvent) => {
              const checked = e.target.checked;
              const cloneBooking = [...bookings];
              const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

              if (findIndexBookingFocused > -1) {
                const booking = cloneBooking[findIndexBookingFocused];
                if (booking.service) {
                  setBookings(cloneBooking);
                  setOpenPickATime(true);
                  setStatusAnyTechnician(!!booking.isAnyTechnician);
                  setTechnician(undefined);
                } else {
                  if (booking.technician && !booking.isAnyTechnician) {
                    booking.isAnyTechnician = true;
                    setBookings(cloneBooking);
                  } else {
                    booking.isAnyTechnician = checked;
                    setBookings(cloneBooking);
                  }
                }
              } else {
                const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
                cloneBooking.push({
                  isFocus: true,
                  isAnyTechnician: true,
                  isAppointment: false,
                  availableTime: undefined,
                  id: findMaxId ? findMaxId + 1 : 1,
                });
                setBookings(cloneBooking);
              }
            },
          }}
        />
        <div className="salon__step-two-left-bottom-wrap">
          <div className="salon__step-two-left-bottom-card">
            {filterTechnicians.map((technician) => (
              <Card
                key={technician.id}
                className={`salon__custom-card salon__technician-item-preview ${
                  bookings.find((booking) => booking.isFocus)?.technician?.id === technician.id ? 'active' : ''
                } ${calculateAvailableTimeMutation.isLoading ? 'disable' : ''}`}
                onClick={() => handleOpenPickATime(technician)}
              >
                <Avatar
                  src={
                    technician &&
                    (technician.defaultAvatar ||
                      (technician.avatar?.preview || technician.avatar?.source
                        ? `${process.env.REACT_APP_API_URL}/static/${
                            technician.avatar?.preview || technician.avatar?.source
                          }`
                        : avatarDefaultSrc))
                  }
                />
                <p className="salon__custom-card-des ">{technician.name || '--'}</p>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <PickATime
        open={openPickATime}
        setOpen={() => {
          setOpenPickATime(false);
        }}
        handleChooseService={handleChooseService}
        handleChooseTechnician={handleChooseTechnician}
        bookings={bookings}
        technician={technician}
        statusAnyTechnician={statusAnyTechnician}
        dataSalon={dataSalon}
      />
    </>
  );
};

export default PickTechnicians;
