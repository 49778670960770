import { Divider, Image } from 'antd';
import { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { TechnicianReceiptResponse, Ticket } from '../../../apis/client-axios';
import avatarDefaultSrc from '../../../assets/images/appointment/avatar-default.png';
import { CHECKOUT_FULL_TIME_FORMAT, formatDateTimeZoneByFormatString } from '../../../utils';
import BillItem from '../BillItem';
import '../bill.scss';

interface TechnicianReceiptProps {
  receipts: TechnicianReceiptResponse[];
  ticket?: Ticket;
}

const TechnicianReceipt = (props: TechnicianReceiptProps, ref: ForwardedRef<HTMLDivElement | null>) => {
  const { receipts, ticket } = props;

  const intl = useIntl();

  return (
    <div ref={ref} className="w-full bg-white">
      <div className="d-flex align-items-center justify-content-between p-20 w-full">
        <span className="font-weight-600 font-size-30">
          {intl.formatMessage({ id: 'print.technicianReceipt.title' })}
        </span>
        <span className="font-weight-600 font-size-30">
          {formatDateTimeZoneByFormatString(CHECKOUT_FULL_TIME_FORMAT, ticket?.createdOnDate).replace(/,/g, '')}
        </span>
      </div>

      <Divider className="m-b-32 m-t-0" />

      {receipts.map((receipt, index) => {
        const technician = receipt.technician;

        return (
          <div key={index} className="d-flex flex-column">
            <div className="d-flex gap-8 align-items-center height-56 m-b-18 p-l-r-20">
              <Image
                alt={technician?.name}
                src={
                  technician &&
                  (technician?.defaultAvatar ||
                    (technician?.avatar?.preview || technician?.avatar?.source
                      ? `${process.env.REACT_APP_API_URL}/static/${
                          technician?.avatar?.preview || technician?.avatar?.source
                        }`
                      : avatarDefaultSrc))
                }
                className="border-radius-8px"
                preview={false}
                width={32}
                height={32}
              />

              <span className="font-weight-600 font-size-30">{technician?.name}</span>
            </div>

            <div className="d-flex flex-column gap-8 p-l-r-40">
              {Array(receipt.services) &&
                Array(receipt.services).length > 0 &&
                receipt.services.map((serviceTurn) => (
                  <div key={serviceTurn.id} className="m-b-4-n">
                    <BillItem
                      title={
                        !serviceTurn?.service
                          ? `${intl.formatMessage({ id: 'print.bill.generalServices' })}`
                          : serviceTurn.service.name
                      }
                      value={Number(serviceTurn?.price)}
                    />
                  </div>
                ))}
            </div>

            <Divider className="m-t-b-20" />

            <div className="d-flex flex-column gap-8 p-l-r-40">
              <BillItem
                title={intl.formatMessage({ id: 'print.technicianReceipt.subTotal' })}
                value={Number(receipt.subTotal)}
              />

              <BillItem
                title={intl.formatMessage({ id: 'print.technicianReceipt.commission' })}
                value={Number(receipt.commission)}
              />

              <BillItem title={intl.formatMessage({ id: 'print.technicianReceipt.tip' })} value={Number(receipt.tip)} />
            </div>

            <Divider className="m-t-b-20" />

            <div className="d-flex flex-column gap-8 p-l-r-40 m-b-25">
              <BillItem
                title={intl.formatMessage({ id: 'print.technicianReceipt.payment' })}
                value={Number(receipt.payment)}
              />

              <BillItem
                title={intl.formatMessage({ id: 'print.technicianReceipt.techEarning' })}
                value={Number(receipt.techEarning)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef<HTMLDivElement | null, TechnicianReceiptProps>(TechnicianReceipt);
