import { IntlShape, useIntl } from 'react-intl';
import { SvgArrowBackIcon } from '../../../../components/@svg/SvgArrowBackIcon';
import './deposit.scss';
import { Avatar, Form } from 'antd';
import FormWrap from '../../../../components/FormWrap';
import PageContainer from '../../../../components/PageContainer';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';
import { Fragment, useEffect, useState } from 'react';
import { QUERY_PAYMENT_CARDS } from '../../../../utils/constant';
import { stripeApi } from '../../../../apis';
import { useQuery } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddCardPayment from './AddCardPayment';
import { ServiceItem } from '../../../../components/ServiceItem';
import avatarDefaultSrc from '../../../../assets/images/appointment/avatar-default.png';
import { CustomerCheckInDto, Salon } from '../../../../apis/client-axios';
import { formatNumberThousandWithDecimal } from '../../../../utils';
import { Booking } from '..';

interface Iprops {
  checkShowDeposit: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  bookings: Booking[];
  onFinish: () => void;
  dataCustomer?: CustomerCheckInDto;
  dataSalon?: Salon;
}
const renderTechnician = (booking: Booking, intl: IntlShape) => {
  if (booking.isAnyTechnician) {
    return (
      <div className="salon__booking-online-checkbox-any">
        <span className="salon__step-two-left-bottom-checkbox-text">
          {intl.formatMessage({ id: 'bookingOnline.checkboxAnyTechnician' })}
        </span>
      </div>
    );
  } else if (booking.technician) {
    return (
      <div className="d-flex gap-10 align-items-center">
        <Avatar
          src={
            booking.technician &&
            (booking.technician?.defaultAvatar ||
              (booking.technician?.avatar?.preview || booking.technician?.avatar?.source
                ? `${process.env.REACT_APP_API_URL}/static/${
                    booking.technician?.avatar?.preview || booking.technician?.avatar?.source
                  }`
                : avatarDefaultSrc))
          }
          className=""
        />
        <p className=" salon__custom-card-des text-max-1-line m-0">{booking.technician?.name || '--'}</p>
      </div>
    );
  } else {
    return '----';
  }
};

const renderServices = (booking: Booking, intl: IntlShape) => {
  if (booking.service) {
    return (
      <div className="position-relative width-min-content">
        <ServiceItem
          isMerchandise={!!booking?.service?.isMerchandise}
          name={booking.service.name}
          price={formatNumberThousandWithDecimal(booking.service.price)}
          isCheckinOnline={!true}
          time={booking.service.time?.toString()}
          backgroundColor={booking.service.backgroundColor}
        />
      </div>
    );
  } else if (Array.isArray(booking.appointmentServices) && booking.appointmentServices.length > 0) {
    return (
      <div className="position-relative width-min-content d-flex align-items-center gap-10 salon__step-two-custom-booking-service">
        {booking.appointmentServices.map((service) => (
          <Fragment key={service.id}>
            <ServiceItem
              isMerchandise={!!service?.isMerchandise}
              name={service.name}
              price={formatNumberThousandWithDecimal(service.price)}
              isCheckinOnline={!true}
              time={service.time?.toString()}
              backgroundColor={service.backgroundColor}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
};
const Deposit: React.FC<Iprops> = (props) => {
  const { checkShowDeposit, setStep, onFinish, bookings, dataCustomer, dataSalon } = props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: listPaymentMethod, refetch: onRefetchListPaymentMethod } = useQuery({
    queryKey: [QUERY_PAYMENT_CARDS],
    queryFn: () => stripeApi.stripeControllerGetPaymentMethod(),
  });
  const onClickBack = () => {
    setStep((prev) => prev - 1);
  };
  const onNext = () => {
    if (checkShowDeposit) {
      setOpenModal(true);
    } else {
      onFinish();
    }
  };
  return (
    <>
      <div className="salon__online-appointment__deposit p-l-r-60">
        <div className="position-relative m-t-40">
          <div className="salon__online-appointment-new-back" onClick={onClickBack}>
            <SvgArrowBackIcon />
            <span className="salon__online-appointment-back-title text-transform-capitalize">
              {intl.formatMessage({ id: 'common.back' })}
            </span>
          </div>
          <h1 className="salon__online-appointment-new__deposit-title">Schedule Your Appointment</h1>
        </div>
        <FormWrap
          name="deposit"
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          {/* Service and technician */}
          <PageContainer paddingPage={0} marginTop={40} className="">
            <div className="salon__online-appointment__deposit-header d-flex justify-content-between p-t-b-16 p-l-r-20 border-bottom">
              <div className="d-flex justify-content-center align-items-center salon__online-appointment__deposit-text">
                <span className="salon__custom-text">Service & technician</span>
                <div className="d-flex font-weight-500 font-size-16">
                  <p className="m-b-0">Total price:</p>
                  <span>
                    $
                    {formatNumberThousandWithDecimal(
                      bookings?.reduce((prev, cur) => prev + Number(cur?.service?.price ?? 0), 0)
                    )}
                  </span>
                </div>
                <div className="d-flex font-weight-500 font-size-16">
                  <p className="m-b-0">Estimate:</p>
                  <span>
                    {bookings?.reduce((prev, cur) => prev + Number(cur?.service?.time?.toString() ?? 0), 0)} min
                  </span>
                </div>
              </div>
              <ButtonStyled
                content={intl.formatMessage({ id: 'common.edit' })}
                buttonProps={{
                  className: 'width-101',
                  onClick: () => {
                    setStep((prev) => prev - 2);
                  },
                }}
                isPrimary={true}
              />
            </div>
            <div className="salon__online-appointment-info">
              <div className=" d-flex justify-content gap-20 salon__online-appointment-info-scroll">
                {/** */}
                <div className="salon__step-two-right-main-deposit p-l-r-30">
                  <div className={`d-flex gap-24 m-t-7 salon__step-two-right-main-item-deposit p-b-24 p-t-10 `}>
                    {bookings.map((booking) => (
                      <div
                        className={`position-relative salon__information-appointment-online-container max-width-262 min-width-240`}
                      >
                        <div className="d-flex gap-18 salon__information-appointment-container">
                          {renderServices(booking, intl)}
                        </div>
                        <div>{renderTechnician(booking, intl)}</div>
                        <div className="d-flex justify-content-between align-items-center m-t-10">
                          <span>{booking?.availableTime as any}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </PageContainer>
          {/* Your information */}
          <PageContainer paddingPage={0} marginTop={40} className="">
            <div className="salon__online-appointment__deposit-header d-flex align-items-center justify-content-between p-t-b-16 p-l-r-20 border-bottom">
              <p className="m-b-0 salon__custom-text">Your information</p>
              <ButtonStyled
                content={intl.formatMessage({ id: 'common.edit' })}
                buttonProps={{
                  className: 'width-101',
                  onClick: () => {
                    setStep((prev) => prev - 1);
                  },
                }}
                isPrimary={true}
              />
            </div>
            {!!dataCustomer && (
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 0]} isWrap={true} styleFill="between">
                <ColWrap colProps={{ span: 24 }}>
                  <div className="salon__online-appointment-info d-flex p-20">
                    <span className="salon__online-appointment-info-title">
                      {dataCustomer?.customerName ?? ''}, {dataCustomer?.customerPhone ?? ''}
                      {dataCustomer?.customerEmail ? ', ' + dataCustomer?.customerEmail : ''}
                    </span>
                  </div>
                </ColWrap>
              </RowWrap>
            )}
          </PageContainer>
          <div
            className={`salon__custom-m-t salon__button d-flex ${
              checkShowDeposit ? 'justify-content-between salon__custom-d-f' : 'justify-content-end'
            } m-t-40 p-t-7`}
          >
            {checkShowDeposit && (
              <div>
                <p className="salon__custom-note font-weight-400">
                  <span className="font-weight-700">
                    {/* $50.00 */}$
                    {formatNumberThousandWithDecimal(
                      bookings.reduce(
                        (prev, cur) =>
                          prev +
                          (cur.service?.price
                            ? Number((Number(cur.service.price) * Number(dataSalon?.deposit ?? 0)) / 100)
                            : Number(dataSalon?.depositNotService)),
                        0
                      )
                    )}
                  </span>{' '}
                  {intl.formatMessage({ id: 'onlineAppointmentNew.deposit.messageNote' })}
                </p>
              </div>
            )}
            <ButtonStyled
              content={
                checkShowDeposit
                  ? intl.formatMessage({ id: 'onlineAppointmentNew.deposit.button.payAndSubmit' })
                  : intl.formatMessage({ id: 'onlineAppointmentNew.deposit.button.submit' })
              }
              buttonProps={{
                className:
                  'width-203 height-55 border-radius-12px font-weight-700 font-size-24 line-height-29 text-transform-capitalize',
                onClick: onNext,
              }}
              isPrimary
            />
          </div>

          {/* Modal */}
          {openModal && (
            <Elements stripe={stripePromise}>
              <AddCardPayment
                open={openModal}
                onCancelModal={() => setOpenModal(false)}
                onRefetchListPaymentMethod={onRefetchListPaymentMethod}
                onFinish={onFinish}
                dataCustomer={dataCustomer}
                bookings={bookings}
                dataSalon={dataSalon}
              />
            </Elements>
          )}
        </FormWrap>
      </div>
    </>
  );
};
export default Deposit;
