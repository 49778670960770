import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { customerApi } from '../../../../apis';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { QUERY_CUSTOMER_REWARD_BALANCE } from '../../../../utils/constant';
import { MAIN_ROUTES } from '../../routes';
import './checkInConfirm.scss';

const TIME_INTERVAL = 5000;

const CheckInConfirm = () => {
  const intl = useIntl();

  const location = useLocation();
  const navigate = useNavigate();

  const isClickBackToCheckInRef = useRef<boolean>(false);

  const { data: customerRewardBalanceResponse } = useQuery({
    queryKey: [QUERY_CUSTOMER_REWARD_BALANCE, location?.state],
    queryFn: () => customerApi.customerControllerGetRewardBalance(location?.state?.customerId?.toString() || ''),
    enabled: !!location?.state?.customerId && !!!location?.state?.rewardBalance,
  });

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (!isClickBackToCheckInRef.current) {
        navigate(`/${MAIN_ROUTES.BOOKING_ONLINE}`);
      }
    }, TIME_INTERVAL);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  return (
    <div className="salon__home">
      <section className="salon_confirm_qr">
        <div className="salon_confirm_qr-img top">
          <picture>
            <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-top-sp.png" />
            <img src="/assets/qr/img-qr-top.png" alt="qr" />
          </picture>
        </div>
        <div className="salon_confirm_qr-main">
          <div className="salon_confirm_qr-main__image">
            <img src="/assets/qr/confirm.png" alt="" />
            <p className="salon_confirm_qr-main__image-des">
              {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.des' })}
            </p>
          </div>
          <h3 className="salon_confirm_qr-main-title">
            {intl.formatMessage(
              { id: 'bookingOnline.checkin.confirm.title' },
              {
                rewardBalance:
                  Number(location?.state?.rewardBalance || customerRewardBalanceResponse?.data?.rewardBalance) || 0,
              }
            )}
          </h3>
          <ButtonStyled
            content={
              <span className="color-FFFFFF font-size-24 font-weight-700">
                {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.button' })}
              </span>
            }
            isPrimary={false}
            buttonProps={{
              className:
                'height-64 salon__booking-online-btn-back-checkin border-radius-12px background-color-0090FF p-t-b-12 p-l-r-28',
              onClick: () => navigate(`/${MAIN_ROUTES.BOOKING_ONLINE}`),
            }}
          />
        </div>
        <div className="salon_confirm_qr-img bottom">
          <picture>
            <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-bottom-sp.png" />
            <img src="/assets/qr/qr-bottom-confirm.png" alt="qr" />
          </picture>
        </div>
      </section>
    </div>
  );
};

export default CheckInConfirm;
