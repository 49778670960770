import React from 'react';

export const SvgAppointmentDropDownIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M6.30312 7.60924L8.02979 9.33591C8.28979 9.59591 8.70979 9.59591 8.96979 9.33591L10.6965 7.60924C11.1165 7.18924 10.8165 6.46924 10.2231 6.46924H6.76979C6.17645 6.46924 5.88312 7.18924 6.30312 7.60924Z"
        fill="white"
      />
    </svg>
  </span>
);
