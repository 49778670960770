import React from 'react';

export const SvgMultiUserIcon = () => (
  <span>
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15703 2.6665C9.15703 4.04984 8.0487 5.1665 6.66536 5.1665C5.28203 5.1665 4.16536 4.04984 4.16536 2.6665C4.16536 1.28317 5.28203 0.166504 6.66536 0.166504C8.0487 0.166504 9.15703 1.28317 9.15703 2.6665ZM15.8237 2.6665C15.8237 4.04984 14.7154 5.1665 13.332 5.1665C11.9487 5.1665 10.832 4.04984 10.832 2.6665C10.832 1.28317 11.9487 0.166504 13.332 0.166504C14.7154 0.166504 15.8237 1.28317 15.8237 2.6665ZM6.66536 6.83317C4.7237 6.83317 0.832031 7.80817 0.832031 9.74984V10.9998C0.832031 11.4582 1.20703 11.8332 1.66536 11.8332H11.6654C12.1237 11.8332 12.4987 11.4582 12.4987 10.9998V9.74984C12.4987 7.80817 8.60703 6.83317 6.66536 6.83317ZM12.5237 6.87484C12.8154 6.84984 13.0904 6.83317 13.332 6.83317C15.2737 6.83317 19.1654 7.80817 19.1654 9.74984V10.9998C19.1654 11.4582 18.7904 11.8332 18.332 11.8332H14.0154C14.107 11.5748 14.1654 11.2915 14.1654 10.9998V9.74984C14.1654 8.52484 13.507 7.59984 12.557 6.90817C12.5545 6.90565 12.552 6.90236 12.5492 6.89877C12.5429 6.89051 12.5353 6.88065 12.5237 6.87484Z"
        fill="white"
      />
    </svg>
  </span>
);

export default SvgMultiUserIcon;
