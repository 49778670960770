import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toDayjsTimeZone } from '../../utils';

export interface ICheckInCardProps<T extends object> {
  startTime: dayjs.Dayjs | Date | string | null;
  endTime: dayjs.Dayjs | Date | string | null | undefined;
  estimate: number;
  lastModifiedOnDate: dayjs.Dayjs | Date | string | null | undefined;
}

function CountdownInMinutes<T extends object>(props: ICheckInCardProps<T>) {
  const { startTime, endTime, estimate, lastModifiedOnDate } = props;
  const intl = useIntl();

  // const initialDifference = estimate - dayjs(endTime).diff(startTime, 'minute');

  const initialDifference = estimate - toDayjsTimeZone().diff(toDayjsTimeZone(lastModifiedOnDate), 'minute');

  const [minutesLeft, setMinutesLeft] = useState(initialDifference);

  useEffect(() => {
    if (minutesLeft <= 0) return;
    const intervalId = setInterval(() => {
      setMinutesLeft((prevMinutesLeft) => prevMinutesLeft - 1);
    }, 60000);
    return () => clearInterval(intervalId);
  }, [minutesLeft]);

  return (
    <div>
      <p className="font-size-12 font-weight-700 color-292F33 m-0">
        {minutesLeft > 0 ? minutesLeft : 0} {intl.formatMessage({ id: 'common.minutesLeft' })}
      </p>
    </div>
  );
}
export default CountdownInMinutes;
