import { Button, ButtonProps } from 'antd';
import React, { ReactNode } from 'react';
import './buttonCircle.scss';
type Props = {
  label?: string;
  buttonProps?: ButtonProps;
  content: ReactNode | string;
};
export const ButtonCirclePrimary: React.FC<Props> = ({ buttonProps, ...rest }) => {
  return (
    <Button
      type="primary"
      {...buttonProps}
      className={`salon__form-button-circle salon__form-button-circle-primary ${buttonProps?.className}`}
    >
      {rest.content}
    </Button>
  );
};
