import React from 'react';

export const SvgPrevPageDisableIcon = () => (
  <span>
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00002 0.704595C6.81319 0.517342 6.55954 0.412109 6.29502 0.412109C6.0305 0.412109 5.77685 0.517342 5.59002 0.704595L1.00002 5.29459C0.61002 5.68459 0.61002 6.3146 1.00002 6.7046L5.59002 11.2946C5.98002 11.6846 6.61002 11.6846 7.00002 11.2946C7.39002 10.9046 7.39002 10.2746 7.00002 9.88459L3.12002 5.9946L7.00002 2.11459C7.39002 1.72459 7.38002 1.08459 7.00002 0.704595Z"
        fill="#A0A2A3"
      />
    </svg>
  </span>
);
