import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { Promotion, ServiceItems } from '../../../../../../apis/client-axios';
import ColWrap from '../../../../../../components/ColWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../../checkoutConstants';
import { toDayjsTimeZone } from '../../../../../../utils';

interface IProps {
  promotion?: Promotion;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean
  ) => void;
  onCancelModal: () => void;
  onClickAddNewCustomer: () => void;
  listServiceItems: ServiceItems[];
}

const daysOfWeekDayJsArray = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const WeeklyDiscount: React.FC<IProps> = (props) => {
  const {
    promotion,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    confirmAddDiscountsAndRewards,
    onCancelModal,
    onClickAddNewCustomer,
    listServiceItems,
  } = props;

  const intl = useIntl();

  const onClickApplyWeekly = () => {
    if (discountsAndRewardsApplied?.weeklyDiscount) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        weeklyDiscount: undefined,
      }));
    } else if (promotion?.weeklyReward?.weeklyReward) {
      const handleSetDiscountsAndRewards = () => {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          weeklyDiscount: {
            amount: promotion.weeklyReward.discountAmount || promotion.weeklyReward.discountPercent,
            day: promotion.weeklyReward.dayOfWeek || [],
            serviceItemIds: promotion.weeklyReward.allService
              ? listServiceItems.map((e) => e.id)
              : promotion.weeklyReward.discountServices ?? [],
            type: promotion.weeklyReward.discountAmount ? DiscountTypeEnum.Dollar : DiscountTypeEnum.Percent,
          },
        }));
        onCancelModal();
      };
      confirmAddDiscountsAndRewards(handleSetDiscountsAndRewards);
    }
  };

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={[20, 12]}
      isWrap={true}
      styleFill="between"
      className="border-bottom p-t-b-20"
    >
      <ColWrap colProps={{ span: 24 }}>
        <span className="salon__checkout-discount-title d-flex">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.3' })}
          {(!promotion?.weeklyReward?.weeklyReward ||
            (!promotion?.weeklyReward.discountServices?.length && !promotion.weeklyReward.allService)) && (
            <span className="salon__checkout-discount-not-available m-l-17">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
            </span>
          )}
        </span>
        {promotion?.weeklyReward?.weeklyReward && (
          <>
            <span className="salon__checkout-discount-title-sub">
              {/* {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.sub.1' })} */}
              {promotion?.weeklyReward?.discountPercent || promotion?.weeklyReward?.discountAmount
                ? promotion?.weeklyReward?.discountPercent
                  ? `${Number(promotion?.weeklyReward?.discountPercent)}%`
                  : `$${promotion?.weeklyReward?.discountAmount}`
                : `0%`}
              &nbsp;
              {intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.off' })}
              <br />
              {/* for services/merchandise */}
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.applicableForServices' })}&nbsp;
              {promotion?.weeklyReward?.allService
                ? intl.formatMessage({ id: 'checkout.discountAndRewards.allServicesAndMerchandise' })
                : !!promotion?.weeklyReward?.discountServices?.length && !!listServiceItems.length
                ? promotion?.weeklyReward.discountServices
                    .map((serviceItem) => listServiceItems.find((si) => si.id === serviceItem)?.name)
                    .join(', ')
                : '...'}
              {/* on days */}
              {!!promotion?.weeklyReward?.dayOfWeek?.length && (
                <>
                  &nbsp;
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.weekly.on' })}
                  &nbsp;
                  {promotion.weeklyReward.dayOfWeek.length === 7 ? (
                    intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.allDaysOfWeek' })
                  ) : (
                    <span className="text-transform-first-letter-capitalize text-transform-capitalize d-inline">
                      {promotion.weeklyReward.dayOfWeek.map((day) => day).join(', ')}
                    </span>
                  )}
                </>
              )}
            </span>
          </>
        )}
      </ColWrap>
      <ColWrap colProps={{ span: 24 }}>
        <ButtonStyled
          content={
            !discountsAndRewardsApplied?.weeklyDiscount
              ? intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })
              : intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.applied' })
          }
          buttonProps={{
            className: `width-124 height-38 salon__checkout-discount-rewards-button-3${
              discountsAndRewardsApplied?.weeklyDiscount ? ' salon__checkout-discount-rewards-applied-btn' : ''
            }`,
            disabled:
              !promotion?.weeklyReward?.weeklyReward ||
              !(
                promotion?.weeklyReward?.weeklyReward &&
                !!promotion?.weeklyReward?.dayOfWeek?.length &&
                promotion.weeklyReward.dayOfWeek.includes(daysOfWeekDayJsArray[toDayjsTimeZone().day()])
              ) ||
              (!promotion?.weeklyReward.discountServices?.length && !promotion.weeklyReward.allService),
            // ||
            // !!discountsAndRewardsApplied?.weeklyDiscount
            onClick: onClickApplyWeekly,
          }}
          isPrimary={true}
          isCancelPopup={false}
          isButtonCancel={false}
        />
      </ColWrap>
    </RowWrap>
  );
};
