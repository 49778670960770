import { Form } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { IFormDataPassword, nPasswordForm } from '../../routes/tenant/checkOut/checkoutConstants';
import { FormInput } from '../Form/FormInput';
import FormWrap from '../FormWrap';
import { StyledModal } from '../StyledModal';
import KeyboardRequirePassword from './KeyboardRequirePassword';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface inputProps {
  inputLabel: string;
  inputPlaceholder: string;
}

interface IProps {
  open: boolean;
  onCancel: () => void;
  inputProps?: inputProps;
  onClickOK: () => void;
  passwords: string[];
}

export const RequirePasswordModal: React.FC<IProps> = (props) => {
  const { open, onCancel, inputProps, onClickOK, passwords } = props;
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const inputsRef = useRef<HTMLInputElement>(null);

  const intl = useIntl();
  const [form] = Form.useForm<IFormDataPassword>();

  const onFinish = (values: IFormDataPassword) => {
    const { password } = values;
    if (!passwords.length || (!!passwords.length && passwords.includes(password))) {
      onClickOK();
    } else {
      form.setFields([
        {
          name: nPasswordForm('password'),
          errors: [''],
        },
      ]);
    }
  };

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  useEffect(() => {
    inputsRef.current?.focus();
  }, [open]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancel}
      modalProps={{
        title: (
          <div className="font-size-20 font-weight-600 line-height-24 text-transform-capitalize">
            {intl.formatMessage({ id: 'checkout.payAndComplete.requirePassword' })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal salon__checkout__pay-n-complete-modal__require-password',
        width: 700,
        onOk: () => form.submit(),
      }}
    >
      <FormWrap
        form={form}
        onFinish={onFinish}
        className="salon__checkout__pay-n-complete-modal__cash-payment"
        layout="vertical"
      >
        <FormInput
          name={nPasswordForm('password')}
          label={inputProps?.inputLabel ?? undefined}
          inputProps={{
            ref: inputsRef,
            placeholder: inputProps?.inputPlaceholder ?? undefined,
            type: 'password',
            inputMode: 'none',
            onChange: () => {
              form.setFields([
                {
                  name: nPasswordForm('password'),
                  errors: undefined,
                },
              ]);
            },
          }}
          formItemProps={{
            className: 'm-b-20 w-full',
          }}
        />
        <KeyboardRequirePassword form={form} inputName={nPasswordForm('password')} keyboardRef={keyboardRef} />
      </FormWrap>
    </StyledModal>
  );
};
