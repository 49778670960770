export const Permission = {
  Appointment: {
    Display: 'DisplayAppointment',
    Online: 'OnlineAppointment',
    CRUCAppointment: 'CRUCAppointment',
    CRUDBlocktime: 'CRUDBlocktimeAppointment',
    Turn: 'TurnAppointment',
  },
  CheckIn: {
    CheckIn: 'CheckInCheckIn',
  },
  CheckOut: {
    CheckOut: 'CheckOutCheckOut',
  },
  Compensation: {
    List: 'ListCompensation',
    Adjust: 'AdjustCompensation',
    Pay: 'PayCompensation',
    VTimesheet: 'VTimesheetCompensation',
    ETimesheet: 'ETimesheetCompensation',
  },

  Customers: {
    List: 'ListCustomer',
    Details: 'DetailsCustomer',
    EInformation: 'EInformationCustomer',
    IE: 'IECustomer',
  },

  Employee: {
    List: 'ListEmployee',
    Details: 'DetailsEmployee',
    CU: 'CUEmployee',
    TR: 'TREmployee',
    IE: 'IEEmployee',
  },

  Promotions: {
    Fee: 'FeePromotions',
    Checkin: 'CheckinPromotions',
    Loyalty: 'LoyaltyPromotions',
    Birthday: 'BirthdayPromotions',
    Occasion: 'OccasionPromotions',
    Referral: 'ReferralPromotions',
    Weekly: 'WeeklyPromotions',
    General: 'GeneralPromotions',
    Announcements: 'AnnouncementsPromotions',
  },

  Report: {
    Dashboard: 'DashboardReport',
    Revenue: 'RevenueReport',
    ValuedCustomer: 'ValuedCustomerReport',
    ValuedEmployee: 'ValuedEmployeeReport',
    Transactions: 'TransactionsReport',
    SalesByCategory: 'SalesByCategoryReport',
    RecentSales: 'RecentSalesReport',
    GiftCards: 'GiftCardsReport',
    Expenses: 'ExpensesReport',
    SentMessage: 'SentMessageReport',
  },
  Settings: {
    BillingAndPayment: 'BillingAndPaymentSettings',
    Business: 'BusinessSettings',
    ServiceAndMerchandise: 'ServiceAndMerchandiseSettings',
    CheckIn: 'CheckInSettings',
    CheckOut: 'CheckOutSettings',
    Tip: 'TipSettings',
    TextMessage: 'TextMessageSettings',
    Printer: 'PrinterSettings',
    Data: 'DataSettings',
    CustomerRate: 'CustomerRateSettings',
    TerminalDevice: 'TerminalDeviceSettings',
    Authorization: 'AuthorizationSettings',
    Expenses: 'ExpensesSettings',
  },
};
export enum ETenantModuleName {
  Silver = 'Silver',
  Gold = 'Gold',
  Diamond = 'Diamond',
}
export const TenantModuleData = {
  Employee: 'Employee',
  Setting: 'Setting',
  Checkout: 'Checkout',
  Report: 'Report',
  Appointment: 'Appointment',
  CheckIn: 'Check in',
  Customer: 'Customer',
  Compensation: 'Compensation',
  Sass: 'Sass',
  Promotions: 'Promotions',
};
export const Roles = {
  SuperAdmin: 'superadmin',
  Owner: 'owner',
  Manager: 'manager',
  Technician: 'technician',
  CheckinStand: 'checkin-stand',
  Tenant: 'tenant',
};
