import { Button, ButtonProps } from 'antd';
import { useIntl } from 'react-intl';
import './styledButtonTab.scss';

export interface IButtonTabProps {
  buttonProps?: ButtonProps;
  setValueActive: (valueActive: string) => void;
  onExtraClick?: (value?: string) => void;
  valueActive: string;
  dataArr: string[];
  listNumberTotal?: string[] | number[];
  className?: string;
}

function StyledButtonTab(props: IButtonTabProps) {
  const { buttonProps, valueActive, dataArr, listNumberTotal, className, setValueActive, onExtraClick } = props;
  const intl = useIntl();
  return (
    <div className={'salon__button-tab' + (className ? ` ${className}` : '')}>
      {dataArr.map((item, index) => (
        <Button
          {...buttonProps}
          className={`salon__button-tab-btn ${valueActive === item ? 'active' : ''}`}
          type="text"
          onClick={() => {
            setValueActive(item);
            if (onExtraClick) {
              onExtraClick(item);
            }
          }}
          key={item}
        >
          <span className={` ${valueActive === item ? 'active-content' : ''}`}>
            {item ? intl.formatMessage({ id: item.toString() }) : ''}
            {listNumberTotal && listNumberTotal.length > 0
              ? ` ${listNumberTotal[index] ? `(${listNumberTotal[index]})` : ''}`
              : ''}
          </span>
        </Button>
      ))}
    </div>
  );
}
export default StyledButtonTab;
