import { useMutation, useQuery } from '@tanstack/react-query';
import { Card } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AvatarDefault from '../../../../../../../assets/images/appointment/avatar-default.png';
import './style.scss';
import { StyledModal } from '../../../../../../../components/StyledModal';
import { Employee, ServiceTurn, TechnicianTurn, UpdateTechniciansDTO } from '../../../../../../../apis/client-axios';
import { employeeApi, ticketApi } from '../../../../../../../apis';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import { QUERY_LIST_TECHNICIAN_CHECKOUT } from '../../../../../../../utils/constant';
import { ITipInformation } from '..';

interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  technicianTurn?: TechnicianTurn;
  serviceTurn?: ServiceTurn[];
  idTicket: number;
  listTechnicianTurn: TechnicianTurn[];
  setListTechnicianTurn: (technicianTurn: TechnicianTurn[]) => void;
  setTipInformation: (tipInformation: ITipInformation | undefined) => void;
}

const ChangeTechnician: FC<Props> = ({ ...props }) => {
  const {
    open,
    setOpenModal,
    technicianTurn,
    idTicket,
    serviceTurn,
    setListTechnicianTurn,
    listTechnicianTurn,
    setTipInformation,
  } = props;
  const intl = useIntl();
  const [listTechnicianBySKill, setListTechnicianBySKill] = useState<any>([]);
  const [technicianNew, setTechnicianNew] = useState<Employee>();

  const { data: listTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN_CHECKOUT],
    staleTime: 1000,
    queryFn: () => employeeApi.employeeControllerTechnician(undefined, undefined),
  });
  useEffect(() => {
    if (serviceTurn) {
      const listServiceIds: any[] = [];

      const listTechnician = listTechnicians?.data?.map((item) => item);
      serviceTurn.forEach((item) => {
        if (!item.isGeneralService) {
          listServiceIds.push(item.serviceId);
        }
      });
      const filteredElements = listTechnician?.filter((item: Employee) => {
        const skillsTech = item.skills.map((e) => e.id);

        return listServiceIds?.every((skill: any) => skillsTech.includes(skill));
      });
      setListTechnicianBySKill(filteredElements);
    }
  }, [listTechnicians, serviceTurn, open]);

  const saveTechnicianMutation = useMutation(
    (payload: UpdateTechniciansDTO) => ticketApi.ticketControllerUpdateTechnician(idTicket, payload),
    {
      onSuccess: () => {
        const updatedListTechnicianTurns: TechnicianTurn[] = listTechnicianTurn.map((item) => {
          if (item.id && item.id === technicianTurn?.id) {
            return {
              ...item,
              technicianId: Number(technicianNew?.id),
              technician: {
                ...item.technician,
                name: technicianNew?.name || '',
              },
            };
          } else {
            return item;
          }
        });
        setListTechnicianTurn(updatedListTechnicianTurns);
        setTipInformation({
          ticketId: idTicket,
          technicianTurns: updatedListTechnicianTurns,
        });
        setOpenModal(false);
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response.data.message });
      },
    }
  );
  const onClickTechnician = (item: Employee) => {
    const payload: UpdateTechniciansDTO = {
      technicianIdOld: Number(technicianTurn?.id),
      technicianIdNew: Number(item?.id),
    };
    setTechnicianNew(item);
    saveTechnicianMutation.mutate(payload);
  };
  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpenModal(false)}
      modalProps={{
        title: <div className="salon__promotions-modal-heading">{intl.formatMessage({ id: 'checkout.select' })}</div>,
        className: 'salon__promotions-modal',
        width: 700,
        footer: false,
        centered: true,
      }}
    >
      <div className="salon__promotions-select-technician-modal-card">
        {listTechnicians?.data && listTechnicians?.data?.length > 0 ? (
          listTechnicianBySKill?.map((item: any) => (
            <Card
              key={item.id}
              className={`salon__promotions-select-technician-modal-card-main w-176 `}
              onClick={() => onClickTechnician(item)}
            >
              <img
                width={20}
                height={20}
                src={
                  item?.defaultAvatar || item?.avatar?.preview || item?.avatar?.source
                    ? item.defaultAvatar ||
                      `${process.env.REACT_APP_API_URL}/static/${item?.avatar?.preview || item?.avatar?.source}`
                    : AvatarDefault
                }
                alt=""
              />
              <p className="salon__promotions-select-technician-modal-card-name">{item?.name}</p>
            </Card>
          ))
        ) : (
          <></>
        )}
      </div>
    </StyledModal>
  );
};

export default ChangeTechnician;
