import { FC } from 'react';

interface Props {
  wrapperClassName?: string;
}

export const SvgAppointmentStarIcon: FC<Props> = ({ wrapperClassName }) => (
  <span className={wrapperClassName}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M8.00151 12.1798L10.7682 13.8531C11.2748 14.1598 11.8948 13.7064 11.7615 13.1331L11.0282 9.98643L13.4748 7.86643C13.9215 7.47976 13.6815 6.74643 13.0948 6.69976L9.87485 6.42643L8.61485 3.4531C8.38818 2.9131 7.61485 2.9131 7.38818 3.4531L6.12818 6.41976L2.90818 6.6931C2.32151 6.73976 2.08151 7.4731 2.52818 7.85976L4.97485 9.97976L4.24151 13.1264C4.10818 13.6998 4.72818 14.1531 5.23485 13.8464L8.00151 12.1798Z"
        fill="#E0B005"
      />
    </svg>
  </span>
);
