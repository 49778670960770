import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../../../../../components/StyledModal';
import FormWrap from '../../../../../components/FormWrap';
import RowWrap from '../../../../../components/RowWrap';
import ColWrap from '../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../components/@svg/SvgDollarIcon';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import KeyboardWrapper, { KeyboardDirection } from '../../keyboard';
import { SvgPercentIcon } from '../../../../../components/@svg/SvgPercentIcon';
import { ListCustomDiscountStatusEnum } from '../Discounts';
import { KeyboardReactInterface } from 'react-simple-keyboard';

export interface Props {
  open: boolean;
  onCancelModal: () => void;
  statusCustomDiscount: ListCustomDiscountStatusEnum;
  onOK: (value: number) => void;
}

interface FormData {
  ticketDiscount?: number | string;
  itemDiscount?: number | string;
  ticketDiscountPercentage?: number | string;
  itemDiscountPercentage?: number | string;
}

function CustomDiscount<T extends object>(props: Props) {
  const { open, onCancelModal, statusCustomDiscount, onOK } = props;

  const intl = useIntl();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [focusedInputName, setFocusedInputName] = useState<ListCustomDiscountStatusEnum>(statusCustomDiscount);
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const ticketDiscount = Form.useWatch(n('ticketDiscount'), form);
  const itemDiscount = Form.useWatch(n('itemDiscount'), form);
  const ticketDiscountPercentage = Form.useWatch(n('ticketDiscountPercentage'), form);
  const itemDiscountPercentage = Form.useWatch(n('itemDiscountPercentage'), form);

  useEffect(() => {
    if ((ticketDiscount || itemDiscount || ticketDiscountPercentage || itemDiscountPercentage) && inputRef.current) {
      const value = ticketDiscount || itemDiscount || ticketDiscountPercentage || itemDiscountPercentage;

      const [integer] = value.replace(/,/g, '').split('.');
      inputRef.current.selectionStart = inputRef.current.selectionEnd = value.length + Math.floor(integer / 3);
    }
  }, [ticketDiscount, itemDiscount, ticketDiscountPercentage, itemDiscountPercentage]);

  const onFinish = (data: FormData) => {
    if (focusedInputName && data[focusedInputName]) {
      const value = Number(data[focusedInputName]?.toString().replace(/[,\s]/g, ''));

      onOK(value);
    }
  };

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [open, focusedInputName]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-custom-discout-modal-heading">
            {intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount' })}
          </div>
        ),
        className: 'salon__checkout-custom-discout-modal salon__checkout-discount-rewards-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="customDiscount"
        layout="vertical"
        className="w-100 salon__checkout-custom-discount-modal-form p-t-20 p-b-40"
        onFinish={onFinish}
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            {statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscount ||
            statusCustomDiscount === ListCustomDiscountStatusEnum.ItemDiscount ? (
              <FormInputNumber
                name={
                  statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscount
                    ? n('ticketDiscount')
                    : n('itemDiscount')
                }
                label={
                  statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscount
                    ? intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.title.1' })
                    : intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.title.2' })
                }
                prefix={<SvgDollarIcon />}
                numericFormatProps={{
                  className: 'm-b-0',
                  inputMode: 'none',
                  placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.placeholder' }),
                  onFocus: () => setFocusedInputName(statusCustomDiscount),
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                  getInputRef: inputRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            ) : (
              <FormInputNumber
                name={
                  statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscountPercentage
                    ? n('ticketDiscountPercentage')
                    : n('itemDiscountPercentage')
                }
                label={
                  <div className="d-flex align-items-center gap-6">
                    {statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscountPercentage
                      ? intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.title.3' })
                      : intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.title.4' })}
                  </div>
                }
                suffix={<SvgPercentIcon />}
                numericFormatProps={{
                  className: 'm-b-0',
                  max: 100,
                  inputMode: 'none',
                  placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.customDiscount.placeholder.1' }),
                  onFocus: () =>
                    setFocusedInputName(
                      statusCustomDiscount === ListCustomDiscountStatusEnum.TicketDiscountPercentage
                        ? ListCustomDiscountStatusEnum.TicketDiscountPercentage
                        : ListCustomDiscountStatusEnum.ItemDiscountPercentage
                    ),

                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                  getInputRef: inputRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            )}
          </ColWrap>
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-custom-discout-modal-keyboard-button-text">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                htmlType: 'submit',
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default CustomDiscount;
