import { Button, Form, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DepositTotalDTOActionEnum } from '../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../components/@svg/SvgDollarIcon';
import { FormCheckbox } from '../../../../../components/Form/FormCheckbox';
import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../components/FormWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { IFromState, IFromStateInput } from '../index';
import { StyledPopup } from '../../../../../components/StyledPopup';
import { error } from 'console';
import NotificationError from '../../../../../components/HandleShowNotiError';

export interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  activeTab: number;
  tabData: IFromState[];
  setTabData: (data: IFromStateInput) => void;
  type: DepositModalType;
}

export enum DepositModalTypeEnum {
  CREATE = 'create',
  EDIT = 'edit',
}
export interface DepositModalType {
  type: DepositModalTypeEnum;
  index?: number;
}

const DepositModal: FC<Props> = ({ open, activeTab, tabData, setOpenModal, setTabData, type }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [pick, setPick] = useState(false);
  const formFieldAmount = 'amount';
  const [action, setAction] = useState<DepositTotalDTOActionEnum>(DepositTotalDTOActionEnum.Others);

  useEffect(() => {
    if (type.type === DepositModalTypeEnum.EDIT) {
      setAction(
        tabData.find((item) => item.tabId == activeTab)?.deposit?.[type.index || 0]?.action ||
          DepositTotalDTOActionEnum.Others
      );
      form.setFieldValue(
        formFieldAmount,
        tabData.find((item) => item.tabId == activeTab)?.deposit?.[type.index || 0]?.money
      );
    } else {
      form.setFieldValue(formFieldAmount, undefined);
      setAction(DepositTotalDTOActionEnum.Others);
    }
  }, [open]);

  const actionTypes = [DepositTotalDTOActionEnum.Card, DepositTotalDTOActionEnum.Cash, DepositTotalDTOActionEnum.Check];

  const selectedAction = (action: DepositTotalDTOActionEnum) => {
    if (pick) return '';
    if (type.index !== undefined) {
      return tabData.find((item) => item.tabId == activeTab)?.deposit?.[type.index]?.action === action
        ? 'background-color-E9F5FD'
        : '';
    }
    return '';
  };

  const handlePick = (input: DepositTotalDTOActionEnum) => {
    setPick(true);
    setAction(input);
    if (input === DepositTotalDTOActionEnum.Card) {
      // setCardModalOpen(true);
      setConnectModalOpen(true);
      return;
    }
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((value) => {
        if (action === DepositTotalDTOActionEnum.Others) throw Error();
        const date = new Date();
        const deposit = tabData.find((item) => item.tabId == activeTab)?.deposit || [];

        if (type.type === DepositModalTypeEnum.CREATE) {
          deposit?.push({
            action: action,
            money: value?.amount?.replace(/[,\s]/g, ''),
            time: date.toISOString(),
          });
        }

        if (type.type === DepositModalTypeEnum.EDIT && type.index !== undefined && deposit && deposit[type.index]) {
          deposit[type.index].action = action;
          deposit[type.index].money = value?.amount?.replace(/[,\s]/g, '');
          deposit[type.index].time = date.toISOString();
        }

        setTabData({ deposit: deposit });
        setOpenModal(false);
      })
      .catch((err) => NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) }));
  };

  const handleDelete = () => {
    const deposit = tabData.find((item) => item.tabId == activeTab)?.deposit || [];

    setTabData({ deposit: deposit.filter((item, index) => index !== type.index) });
    setOpenModal(false);
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={() => setOpenModal(false)}
        modalProps={{
          title: (
            <div className="salon__appointment-modal-heading m-l-r-24-n">
              {intl.formatMessage(
                { id: 'appointment.modal.deposit.modal.heading' },
                { title: type.type === DepositModalTypeEnum.CREATE ? 'Add' : 'Edit' }
              )}
            </div>
          ),
          className: 'salon__appointment-modal-create salon__appointment-modal-create-deposit',
          width: 400,
          footer: (
            <div className="d-flex justify-content-end align-items-center m-l-r-24-n">
              {type.index !== undefined && (
                <ButtonStyled
                  content={intl.formatMessage({ id: 'common.delete' })}
                  buttonProps={{
                    className: 'width-100 height-38 ',
                    onClick: () => handleDelete(),
                  }}
                  isPrimary={false}
                />
              )}
              <ButtonStyled
                content={intl.formatMessage({ id: 'common.save' })}
                buttonProps={{
                  className: 'width-100 height-38 m-l-10',
                  onClick: () => handleSave(),
                }}
                isPrimary={true}
              />
            </div>
          ),
          centered: true,
        }}
      >
        <FormWrap
          form={form}
          name="appointmentModified"
          layout="vertical"
          className="salon__appointment-deposit-modal m-l-r-24-n p-b-16"
        >
          <FormInputNumber
            name={formFieldAmount}
            label={intl.formatMessage({ id: 'appointment.modal.form.title.amount' })}
            prefix={<SvgDollarIcon />}
            numericFormatProps={{
              required: true,
              min: 0,
              max: 999_999_999,
              placeholder: intl.formatMessage({ id: 'appointment.modal.form.placeholder.amount' }),
            }}
            formItemProps={{
              className: 'm-b-0',
              rules: [
                {
                  transform(value) {
                    return value?.trim().replace(/,/g, '');
                  },
                  validator: (_, value) => {
                    const v = value;
                    if (!v) {
                      return Promise.reject('');
                    }
                    return Promise.resolve();
                  },
                },
              ],
            }}
          />

          <Space direction="vertical" size={10} className="d-flex p-t-40">
            {actionTypes.map((actionType) => (
              <Button
                key={actionType}
                className={`salon__appointment-deposit-button w-100 ${selectedAction(actionType)} ${
                  action === actionType ? 'active' : ''
                }`}
                onClick={() => handlePick(actionType)}
              >
                <span className="font-size-14 font-weight-600">
                  {intl.formatMessage({ id: `appointment.modal.deposit.modal.${actionType.toLowerCase()}` })}
                </span>
              </Button>
            ))}
          </Space>
        </FormWrap>
      </StyledModal>

      {/* <StyledModal
        isOpen={cardModalOpen}
        onCancel={() => setCardModalOpen(false)}
        modalProps={{
          title: intl.formatMessage({ id: 'appointment.modal.cardPayment.heading' }),
          className:
            'salon__appointment-modal-create salon__appointment-modal-create-deposit salon__appointment-modal-create-deposit__card-payment',
          width: 496,
          footer: (
            <div className="d-flex align-items-center justify-content-between">
              <p className="color-78797A font-weight-400 font-size-12 m-b-0">
                {intl.formatMessage(
                  { id: 'appointment.modal.cardPayment.device' },
                  { ip: 'wss://192.168.8.204:12345/remote_pay' }
                )}
              </p>
              <ButtonStyled
                content={intl.formatMessage({ id: 'appointment.modal.cardPayment.connect' })}
                buttonProps={{
                  className: 'width-100 m-0',
                  onClick: () => setConnectModalOpen(true),
                }}
                isPrimary={true}
              />
            </div>
          ),
          centered: true,
        }}
      >
        <div className="text-align-left font-weight-400 font-size-16 m-b-0">
          <p className="color-EB4C3B">{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect' })}</p>
          <ol className="m-b-0">
            <li>{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step1' })}</li>
            <li>
              {intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step2.1' })}
              <strong>{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step2.2' })}</strong>
            </li>
          </ol>
        </div>
      </StyledModal> */}

      {/* <StyledModal
        isOpen={connectModalOpen}
        onCancel={() => setConnectModalOpen(false)}
        modalProps={{
          title: intl.formatMessage({ id: 'appointment.modal.cardPayment.heading' }),
          className:
            'salon__appointment-modal-create salon__appointment-modal-create-deposit salon__appointment-modal-create-deposit__card-payment',
          width: 496,
          footer: false,
          centered: true,
        }}
      >
        <div className="align-items-center justify-content-between font-weight-400 font-size-16 m-b-0">
          <p className="color-EB4C3B">{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect' })}</p>
        </div>
      </StyledModal> */}
    </>
  );
};

export default DepositModal;
