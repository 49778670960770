import React from 'react';

export const SvgDatePickerIcon = () => (
  <span>
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8337 2.49992H15.667C16.5837 2.49992 17.3337 3.24992 17.3337 4.16658V17.4999C17.3337 18.4166 16.5837 19.1666 15.667 19.1666H2.33366C1.41699 19.1666 0.666992 18.4166 0.666992 17.4999V4.16658C0.666992 3.24992 1.41699 2.49992 2.33366 2.49992H3.16699V1.66659C3.16699 1.20825 3.54199 0.833252 4.00033 0.833252C4.45866 0.833252 4.83366 1.20825 4.83366 1.66659V2.49992H13.167V1.66659C13.167 1.20825 13.542 0.833252 14.0003 0.833252C14.4587 0.833252 14.8337 1.20825 14.8337 1.66659V2.49992ZM3.16699 17.4999H14.8337C15.292 17.4999 15.667 17.1249 15.667 16.6666V6.66658H2.33366V16.6666C2.33366 17.1249 2.70866 17.4999 3.16699 17.4999Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
