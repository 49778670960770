import { Form, Tooltip } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FormWrap from '../../../../../../components/FormWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import '../style.scss';
import RowWrap from '../../../../../../components/RowWrap';
import ColWrap from '../../../../../../components/ColWrap';
import { FormSelect } from '../../../../../../components/Form/FormSelect';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import { formatNumberThousandWithDecimal, getStorageByName } from '../../../../../../utils';
import { DefaultOptionType } from 'antd/es/select';
import { SvgToolTipIcon } from '../../../../../../components/@svg/SvgToolTipIcon';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { FormCheckbox } from '../../../../../../components/Form/FormCheckbox';
import StyledCollapse from '../../../../../../components/StyledCollapse';
import { useWatch } from 'antd/es/form/Form';
import { QUERY_SETTING } from '../../../../../../utils/constant';
import { useQuery, useMutation } from '@tanstack/react-query';
import { employeeApi, appointmentApi, settingApi } from '../../../../../../apis';
import {
  CreateAppointmentsDTO,
  TurnSettingSuggesttingModeEnum,
  UpdateTurnSettingDto,
} from '../../../../../../apis/client-axios';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../../components/HandleShowNotiSuccess';
import { AppointmentContext } from '../../..';

interface DisplaySettingsModalProps {
  open: boolean;
  onCancel: () => void;
  onRefetch: () => void;
}

enum SuggestingModeEnum {
  SimpleTurn = 'simpleTurn',
  TraditionalTurn = 'traditionalTurn',
  EnhancedTurn = 'enhancedTurn',
  AverageTurn = 'averageTurn',
  ServiceTurn = 'serviceTurn',
  TimeTurn = 'timeTurn',
}

export interface IFormData {
  suggesttingMode: TurnSettingSuggesttingModeEnum;
  turnSize: number;
  assessFillingTurnEarly: boolean;
  suggestOnlyAvailiableTechnician: boolean;
  enableTurnManagement: boolean;
  showTurnInfoCounts: boolean;
  showMoneyAmounts: boolean;
}

const checkboxOptions: (keyof IFormData)[] = [
  'assessFillingTurnEarly',
  'suggestOnlyAvailiableTechnician',
  'enableTurnManagement',
  'showTurnInfoCounts',
  'showMoneyAmounts',
];

const n = (key: keyof IFormData) => key;

const TurnSettingsModal: FC<DisplaySettingsModalProps> = ({ open, onCancel, onRefetch }) => {
  const intl = useIntl();
  const [form] = Form.useForm<IFormData>();
  const [isOpenHow, setIsOpenHow] = useState(false);
  const mode = Form.useWatch(n('suggesttingMode'), form);
  const management = Form.useWatch(n('enableTurnManagement'), form);

  const { setting, onRefetchSetting } = useContext(AppointmentContext);

  const updateSettingMutation = useMutation(
    (payload: UpdateTurnSettingDto) => settingApi.settingControllerUpdateTurn(payload),
    {
      onSuccess: ({ data }) => {
        onRefetchSetting();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.setting.turnSettings.saved' }) });
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );

  useEffect(() => {
    const formValues = setting;
    form.setFieldsValue({
      assessFillingTurnEarly: true,
      suggestOnlyAvailiableTechnician: true,
      enableTurnManagement: true,
      showTurnInfoCounts: true,
      showMoneyAmounts: true,
    });
    if (formValues) {
      form.setFieldsValue({
        suggesttingMode: formValues.suggesttingMode,
        turnSize: formValues.turnSize,
        assessFillingTurnEarly: formValues.assessFillingTurnEarly,
        suggestOnlyAvailiableTechnician: formValues.suggestOnlyAvailiableTechnician,
        enableTurnManagement: formValues.enableTurnManagement,
        showTurnInfoCounts: formValues.showTurnInfoCounts,
        showMoneyAmounts: formValues.showMoneyAmounts,
      });
    }
  }, [setting]);

  const onSave = () => {
    form.validateFields().then((formValues) => {
      const updateData: UpdateTurnSettingDto = {
        suggesttingMode: formValues.suggesttingMode,
        turnSize: formValues.turnSize,
        assessFillingTurnEarly: formValues.assessFillingTurnEarly,
        suggestOnlyAvailiableTechnician: formValues.suggestOnlyAvailiableTechnician,
        enableTurnManagement: formValues.enableTurnManagement,
        showTurnInfoCounts: formValues.showTurnInfoCounts,
        showMoneyAmounts: formValues.showMoneyAmounts,
      };

      updateSettingMutation.mutate(updateData);
      onCancel();
    });
  };

  const suggestingModeOptions: DefaultOptionType[] = Object.values(SuggestingModeEnum).map((value) => ({
    label: intl.formatMessage({ id: `appointment.setting.turnSettings.suggestingMode.${value}` }),
    value,
  }));

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={onCancel}
        modalProps={{
          title: (
            <span className="font-size-20 font-weight-600 salon__appointment-setting-heading text-transform-capitalize">
              {intl.formatMessage({ id: 'appointment.setting.turnSettings' })}
            </span>
          ),
          rootClassName: 'salon__appointment-setting salon__appointment-setting__turn-settings',
          width: 700,
          centered: true,
          footer: (
            <div className="d-flex justify-content-between align-items-center p-b-20 p-l-r-40">
              <CustomButton
                type="link"
                content={intl.formatMessage({ id: 'appointment.setting.turnSettings.clearTechnicianCheckInLine' })}
                buttonProps={{
                  className: 'p-0 salon__appointment-setting__turn-settings-link',
                }}
              />
              <div className="d-flex align-items-center gap-12">
                <CustomButton
                  type="cancel"
                  content={intl.formatMessage({ id: 'common.reset' })}
                  buttonProps={{
                    className: 'text-transform-capitalize width-100 height-38 font-weight-700 font-size-16',
                  }}
                />
                <CustomButton
                  type="primary"
                  content={intl.formatMessage({ id: 'common.save' })}
                  buttonProps={{
                    className: 'text-transform-capitalize width-100 height-38 font-weight-700 font-size-16',
                    onClick: () => onSave(),
                  }}
                />
              </div>
            </div>
          ),
        }}
      >
        <FormWrap form={form} name="turnSettings" className="salon__appointment-setting-form m-b-21" layout="vertical">
          <RowWrap isGutter={true} gutter={20} isAutoFillRow={true} styleFill="between" isWrap={true}>
            <ColWrap colProps={{ span: 12 }}>
              <FormSelect
                name={n('suggesttingMode')}
                label={
                  <span className="text-transform-capitalize">
                    {intl.formatMessage({ id: 'appointment.setting.turnSettings.suggestingMode' })}
                  </span>
                }
                formItemProps={{
                  className: 'm-b-0 w-full',
                  initialValue: SuggestingModeEnum.AverageTurn,
                }}
                selectProps={{
                  options: suggestingModeOptions,
                  rootClassName: 'salon__appointment-setting__turn-settings-select',
                  allowClear: false,
                }}
              />
            </ColWrap>

            {mode === SuggestingModeEnum.AverageTurn && (
              <ColWrap colProps={{ span: 12 }}>
                <FormInputNumber
                  name={n('turnSize')}
                  label={
                    <span className="text-transform-capitalize">
                      {intl.formatMessage({ id: 'appointment.setting.turnSettings.turnSize' })}
                      <Tooltip
                        trigger="click"
                        title={intl.formatMessage({ id: 'appointment.setting.turnSettings.turnSize.tooltip' })}
                      >
                        <SvgToolTipIcon className="m-l-6" />
                        &nbsp;
                      </Tooltip>
                    </span>
                  }
                  prefix={<SvgDollarIcon />}
                  numericFormatProps={{
                    min: 0,
                    max: 999_999_999,
                    className: 'm-b-0',
                    placeholder: formatNumberThousandWithDecimal(0),
                  }}
                  formItemProps={{
                    className: 'm-b-0 w-full',
                  }}
                  classNameFormInputNumber="m-b-0"
                />
              </ColWrap>
            )}

            <ColWrap colProps={{ span: 24 }} className="p-t-b-3 m-t-8 ">
              <CustomButton
                type="text"
                content={intl.formatMessage({ id: 'appointment.setting.howThisWorks' })}
                buttonProps={{
                  className: 'd-flex color-0090FF p-0 text-transform-first-letter-capitalize height-20',
                  onClick: () => setIsOpenHow(true),
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 24 }} className="m-t-16">
              {checkboxOptions.map((option, index) => (
                <FormCheckbox
                  name={n(option)}
                  content={
                    <div>
                      <p className="m-b-3 font-size-16 color-292F33 font-weight-400">
                        {intl.formatMessage({ id: `appointment.setting.turnSettings.${option}` })}
                      </p>
                      <p className="m-b-0 font-size-14 color-78797A font-weight-400">
                        {intl.formatMessage({ id: `appointment.setting.turnSettings.${option}.subTitle` })}
                      </p>
                    </div>
                  }
                  formItemProps={{
                    className: 'm-b-8',
                    initialValue: false,
                  }}
                  checkboxProps={
                    option === 'enableTurnManagement'
                      ? {
                          onChange: (event) => {
                            const checked = event.target.checked;
                            form.setFieldsValue({
                              showMoneyAmounts: checked,
                              showTurnInfoCounts: checked,
                            });
                          },
                        }
                      : {}
                  }
                />
              ))}
            </ColWrap>
          </RowWrap>
        </FormWrap>
      </StyledModal>

      <StyledModal
        isOpen={isOpenHow}
        onCancel={() => setIsOpenHow(false)}
        modalProps={{
          title: (
            <span className="text-transform-first-letter-capitalize color-0090FF font-size-16">
              {intl.formatMessage({ id: 'appointment.setting.howThisWorks.header' })}
            </span>
          ),
          className: 'salon__appointment-setting-turn-collapse-modal',
          width: 800,
          footer: false,
        }}
      >
        <div className="text-align-left">
          <p className="font-weight-500 font-size-12 font-style-italic">
            {intl.formatMessage({ id: 'appointment.setting.howThisWorks.subTitle.1' })}
            <br />
            <br />
            {intl.formatMessage({ id: 'appointment.setting.howThisWorks.subTitle.2' })}
          </p>

          <StyledCollapse
            collapseProps={{
              className: 'salon__appointment-setting-turn-collapse',
              ghost: true,
            }}
            activeKey={''}
            items={[
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple.3' })}</li>
                      {/* <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple.2' })}</li> */}
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple.4' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.simple.5' })}</li>
                    </ul>
                  </p>
                ),
              },
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional.2' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional.3' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional.4' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.traditional.5' })}</li>
                    </ul>
                  </p>
                ),
              },
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.enhanced' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.enhanced.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.enhanced.2' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.enhanced.3' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.enhanced.4' })}</li>
                    </ul>
                  </p>
                ),
              },
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.2' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.3' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.4' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.5' })}</li>
                      {/* <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.6' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.7' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.average.8' })}</li> */}
                    </ul>
                  </p>
                ),
              },
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.2' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.3' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.4' })}</li>
                      {/* <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.5' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.service.6' })}</li> */}
                    </ul>
                  </p>
                ),
              },
              {
                label: (
                  <span className="font-weight-500 font-size-16 text-transform-first-letter-capitalize color-0090FF">
                    {intl.formatMessage({ id: 'appointment.setting.howThisWorks.time' })}
                  </span>
                ),
                children: (
                  <p className="font-weight-500 font-size-12 p-t-20">
                    <ul>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.time.1' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.time.2' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.time.3' })}</li>
                      <li> {intl.formatMessage({ id: 'appointment.setting.howThisWorks.time.4' })}</li>
                    </ul>
                  </p>
                ),
              },
            ]}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default TurnSettingsModal;
