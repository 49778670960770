import React from 'react';

export const SvgAppointmentInputDropDownIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.70663 11.4139L11.2966 14.0039C11.6866 14.3939 12.3166 14.3939 12.7066 14.0039L15.2966 11.4139C15.9266 10.7839 15.4766 9.70386 14.5866 9.70386H9.40663C8.51663 9.70386 8.07663 10.7839 8.70663 11.4139Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
