import { useIntl } from 'react-intl';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IListIdTotalPrice, PaymentInformation } from '../index';
import './style.scss';
import { formatNumberThousandWithDecimal } from '../../../../utils';
import React, { useEffect, useState } from 'react';

interface Props {
  open: boolean;
  listTotal: IListIdTotalPrice;
  price: number;
  paymentInformation: PaymentInformation;

  onCancelModal: () => void;
  onDeleteTicket: () => void;
}

function DeleteTicket(props: Props) {
  const { open, price, paymentInformation, onCancelModal, onDeleteTicket } = props;
  const intl = useIntl();
  const [totalCash, setTotalCash] = useState<number>(0);

  const dataSource = [
    // cash payment
    paymentInformation?.cashPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.cash' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.cashPayment.amount || 0),
        }
      : undefined,
    // gift card payment
    paymentInformation?.giftCardPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.giftCard' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.giftCardPayment?.totalValue ?? 0),
        }
      : undefined,
    //check payment
    paymentInformation?.checkPayment
      ? {
          key: intl.formatMessage({ id: 'checkout.payAndComplete.title.check' }),
          amount: formatNumberThousandWithDecimal(paymentInformation?.checkPayment?.amount ?? 0),
        }
      : undefined,
    //keyin card / card number
    paymentInformation?.keyInCardPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.keyinCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.amount ?? 0),
        }
      : undefined,
    paymentInformation?.ortherPayment
      ? {
          key: <span className="font-size-14 font-weight-600">Orther</span>,
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.amount ?? 0),
        }
      : undefined,
    paymentInformation?.multipleChargeCard
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.multipleChargeCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.amount ?? 0),
        }
      : undefined,
  ];

  useEffect(() => {
    if (price > 0) {
      let totalCashTemp = 0;
      dataSource
        ?.filter((data) => data)
        .forEach((item) => {
          if (item && item.amount) {
            totalCashTemp = totalCashTemp + +item.amount;
          }
        });
      setTotalCash(totalCashTemp);
    }
  }, [price]);

  return (
    <StyledPopup
      isOpen={open}
      content={
        <div className="text-align-left line-height-19">
          <div className="salon__checkout-delete-ticket-modal-popup-title">
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete' })}
          </div>
          <p className="salon__checkout-delete-ticket-modal-popup-content">
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete.content' })}
            <span className="salon__checkout-delete-ticket-modal-popup-content-strong">{`$${formatNumberThousandWithDecimal(
              totalCash
            )}`}</span>
            {intl.formatMessage({ id: 'checkout.tax.ticket.delete.content.last' })}
          </p>
        </div>
      }
      onCancel={() => {
        onCancelModal();
      }}
      onOk={() => {
        onCancelModal();
        onDeleteTicket();
      }}
      isHiddenCancel={false}
      customFooter={
        <div className="d-flex gap-12 justify-content-center">
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.no' })}
            buttonProps={{
              className: 'width-100',
              onClick: () => {
                onCancelModal();
              },
            }}
            isPrimary={true}
          />
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.yes' })}
            buttonProps={{
              className: 'width-100 m-0',
              onClick: async () => {
                onCancelModal();
                onDeleteTicket();
              },
            }}
            isButtonCancel={true}
            isCancelPopup={false}
            isPrimary={false}
          />
        </div>
      }
    />
  );
}

export default DeleteTicket;
