import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ListServicesItem } from '../index';
import { SettingCheckout } from '../../../../apis/client-axios';
import { RequirePasswordModal } from '../../../../components/RequirePasswordModal';
import { defaultManagerCode } from '../../../../utils/constant';

interface IProps {
  listServices: ListServicesItem[];
  settingCheckout: SettingCheckout | undefined;
  handleUpdateShowModal: (item: string) => React.ReactNode;
  setIsEditServicePrice: (value: React.SetStateAction<boolean>) => void;
}

export interface IRequirePassword {
  open: boolean;
  modalType: ListServicesItem | undefined;
}

const ListServicesButtons: React.FC<IProps> = (props) => {
  const { listServices, settingCheckout, handleUpdateShowModal, setIsEditServicePrice } = props;
  const intl = useIntl();
  const [requirePasswordState, setRequirePasswordState] = useState<IRequirePassword>({
    open: false,
    modalType: undefined,
  });

  const getPasswords: () => string[] = () => {
    const arrayPasswords: string[] = [];
    if (settingCheckout?.securityCode?.managerCode) {
      arrayPasswords.push(settingCheckout.securityCode.managerCode);
    } else {
      arrayPasswords.push(settingCheckout?.securityCode?.defaultManagerCode || defaultManagerCode);
    }
    if (
      requirePasswordState.modalType &&
      ListServicesItem.GiftCard === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureGiftCardSaleButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureGiftCardSaleButton);
    }
    if (
      requirePasswordState.modalType &&
      ListServicesItem.DiscountAndRewards === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureDiscountButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureDiscountButton);
    }
    return arrayPasswords;
  };

  const onCancelRequirePassword = () => setRequirePasswordState({ open: false, modalType: undefined });

  const onSuccessRequirePassword = () => {
    if (requirePasswordState.modalType) {
      handleUpdateShowModal(requirePasswordState.modalType);
      setIsEditServicePrice(false);
    }
    setRequirePasswordState({
      open: false,
      modalType: undefined,
    });
  };

  const onClickServiceItemButton = (item: ListServicesItem) => {
    if (item === ListServicesItem.GiftCard && settingCheckout?.securityCode?.secureGiftCardSaleButton) {
      setRequirePasswordState({
        open: true,
        modalType: ListServicesItem.GiftCard,
      });
    } else if (item === ListServicesItem.DiscountAndRewards && settingCheckout?.securityCode?.secureDiscountButton) {
      setRequirePasswordState({
        open: true,
        modalType: ListServicesItem.DiscountAndRewards,
      });
    } else {
      handleUpdateShowModal(item);
      setIsEditServicePrice(false);
    }
  };

  return (
    <>
      <div className="salon__checkout-list-services">
        {listServices.map((item, index) => (
          <div
            className="salon__checkout-service-item"
            key={index}
            onClick={() => {
              onClickServiceItemButton(item);
            }}
          >
            <span className="salon__checkout-service-item-title">{intl.formatMessage({ id: `checkout.${item}` })}</span>
          </div>
        ))}
      </div>

      {requirePasswordState.open && (
        <RequirePasswordModal
          open={requirePasswordState.open}
          onCancel={onCancelRequirePassword}
          onClickOK={onSuccessRequirePassword}
          passwords={getPasswords()}
          inputProps={{
            inputLabel: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.label`,
            }),
            inputPlaceholder: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.placeholder`,
            }),
          }}
        />
      )}
    </>
  );
};

export default ListServicesButtons;
