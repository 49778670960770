import React from 'react';

export const SvgRemoveServiceItemIcon = () => (
  <span>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79333 2.20529C7.95604 2.36801 7.95604 2.63183 7.79333 2.79455L2.79333 7.79455C2.63061 7.95726 2.36679 7.95726 2.20407 7.79455C2.04135 7.63183 2.04135 7.36801 2.20407 7.20529L7.20407 2.20529C7.36679 2.04257 7.63061 2.04257 7.79333 2.20529Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20407 2.20529C2.36679 2.04257 2.63061 2.04257 2.79333 2.20529L7.79333 7.20529C7.95604 7.36801 7.95604 7.63183 7.79333 7.79455C7.63061 7.95726 7.36679 7.95726 7.20407 7.79455L2.20407 2.79455C2.04135 2.63183 2.04135 2.36801 2.20407 2.20529Z"
        fill="white"
      />
    </svg>
  </span>
);
