import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { authApi, axiosInstance, salonApi } from '../../apis';
import { Administrator, Customer, Employee } from '../../apis/client-axios';
import { SuspenseWrapper } from '../../components/LoadingComponent';
import { RootState, useAppDispatch } from '../../store';
import { logout, updateMe, updatePermission, updateSalon } from '../../store/authSlice';
import { removeSessionStorageByName, setStorageByName } from '../../utils';
import { QUERY_EMPLOYEE_ME, QUERY_SALONS_ALL, QUERY_SALONS_DETAIL } from '../../utils/constant';
import { TenantModuleData } from '../../utils/permission';
import { BOOKING_ONLINE_ROUTES } from './bookingOnline/bookingOnlineConstants';
import BookingOnline from './bookingOnline/routes';
import CheckInTenant from './checkIn';
import CheckOutTenant from './checkOut';
import { RoleCode } from './employee/employeeConstants';
import PromotionsTenant from './promotions/promotionsRoutes';
import { MAIN_ROUTES } from './routes';
import SettingRoutes from './setting/settingRoutes';
import CheckInByQR from './bookingOnline/checkInByQR';
import MobileOnlineAppointment from './onlineAppointment/mobile';
import OnlineAppointmentNew from './onlineAppointmentNew';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const Tenant = React.lazy(() => import('.'));
const Appointment = React.lazy(() => import('./appointment'));
const SignInTenant = React.lazy(() => import('./auth/SignInTenant'));
const EmployeeComponent = React.lazy(() => import('./employee/index'));
const CustomerComponent = React.lazy(() => import('./customers/index'));
const TimesheetComponent = React.lazy(() => import('./timesheet/index'));
const CompensationComponent = React.lazy(() => import('./compensation/index'));
const ReportComponent = React.lazy(() => import('./report/reportRoutes'));
const SaasComponent = React.lazy(() => import('./saas/index'));

const OnlineAppointment = React.lazy(() => import('./onlineAppointment/index'));
const Information = React.lazy(() => import('./bookingOnline/information'));
const CheckInConfirm = React.lazy(() => import('./bookingOnline/checkInConfirm'));
const ForgotPasswordTenant = React.lazy(() => import('./auth/ForgotPassword/index'));
const VerificationCodeTenant = React.lazy(() => import('./auth/VerificationCode/index'));
const PrivacyTenant = React.lazy(() => import('./auth/Privacy/index'));
const TermOfUserTenant = React.lazy(() => import('./auth/TermOfUser/index'));
const PrintTenant = React.lazy(() => import('./print'));

export const TenantRoutes = () => {
  const accessToken = sessionStorage.getItem('token') || localStorage.getItem('token');

  const authUserString = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');

  const authUser: Administrator | Customer | Employee = authUserString ? JSON.parse(authUserString as string) : null;

  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);

  const moduleTenant = useSelector((state: RootState) => state.auth.moduleTenant);

  let timer: NodeJS.Timeout | undefined;

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const { data, isLoading: loadingMe } = useQuery({
    queryKey: [QUERY_EMPLOYEE_ME],
    enabled: !!localStorage.getItem('token'),
    queryFn: () => authApi.authControllerEmployeeMe(),

    onSuccess: (data: AxiosResponse<Employee, any>) => {
      localStorage.setItem('authUser', JSON.stringify(data.data));
      removeSessionStorageByName('appointmentBill');
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      const path = window.location.pathname.split('/');
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        if (path[1] === 'admin') window.location.href = '/admin/signin';
        else window.location.href = '/signin';
      }
    },
  });

  const { data: salonDetail, refetch: refetchSalon } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(),
    enabled: !!localStorage.getItem('salonId'),
  });

  const { data: salonData } = useQuery({
    queryKey: [QUERY_SALONS_ALL],
    queryFn: () => salonApi.salonControllerGet(1),
    enabled:
      !!data?.data?.user?.roles?.some((e) => e.code === RoleCode.Owner || e.code === RoleCode.Tenant) &&
      !localStorage.getItem('salonId'),
  });

  useEffect(() => {
    if (data) {
      dispatch(updateMe(data.data));
      if (
        !data.data.user?.roles.some(
          (e) => e.code === RoleCode.Owner || e.code === RoleCode.SuperAdmin || e.code === RoleCode.Tenant
        )
      ) {
        localStorage.setItem('salonId', data.data?.salons[0]?.id?.toString());

        axiosInstance.defaults.headers.salonId = data.data?.salons[0]?.id?.toString();
        refetchSalon();
      }
    }
  }, [data, dispatch, queryClient]);

  useEffect(() => {
    if (salonDetail) {
      dispatch(updateSalon(salonDetail.data));
      setStorageByName('timeZone', salonDetail?.data?.timeZone ?? 'America/New_York');
    }
  }, [salonDetail, dispatch, queryClient]);

  useEffect(() => {
    if (!!data?.data?.user?.roles?.length && !!salonDetail?.data?.salonSetting?.settingAuthorization?.length) {
      const role = data.data.user.roles[0];

      const permission =
        salonDetail?.data?.salonSetting?.settingAuthorization?.find((e) => e.roleId === role.id)?.permissions ?? [];
      dispatch(updatePermission(permission));
    }
  }, [data, salonDetail, dispatch]);

  useEffect(() => {
    if (!localStorage.getItem('salonId') && !!salonData?.data?.content?.length) {
      // dispatch(saveListSalons(salonData?.data?.content ?? []));
      const salonId = salonData.data.content[Math.floor(Math.random() * salonData.data.content.length)].id.toString();
      localStorage.setItem('salonId', salonId);
      axiosInstance.defaults.headers.salonId = salonId;
      refetchSalon();
    }
  }, [salonData, dispatch]);

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      dispatch(logout());
    }, 1800000);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (!localStorage.getItem('isRemember')) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (loadingMe && accessToken) ||
    (accessToken &&
      (!moduleTenant?.length || !salonPermission?.length) &&
      (!authUser || !authUser?.user?.roles?.some((e) => e.code === RoleCode.SuperAdmin || e.code === RoleCode.Tenant)))
  ) {
    return (
      <div className="min-height-600 d-flex justify-content-center align-items-center">
        <Spin />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="min-height-600 d-flex justify-content-center align-items-center">
          <Spin />
        </div>
      }
    >
      <Routes>
        {accessToken ? (
          <>
            {authUser?.user?.roles?.some((e) => e.code === RoleCode.CheckinStand) ? (
              <>
                <Route
                  path={`${MAIN_ROUTES.BOOKING_ONLINE}/*`}
                  element={<SuspenseWrapper component={<BookingOnline />} />}
                />

                <Route path="/*" element={<Navigate to={`${MAIN_ROUTES.BOOKING_ONLINE}`} />} />
              </>
            ) : authUser?.user?.roles?.some((e) => e.code === RoleCode.SuperAdmin) ? (
              <Route path="" element={<Tenant moduleTenant={[TenantModuleData.Sass]} />}>
                <Route path={``} element={<SuspenseWrapper component={<Navigate to={`${MAIN_ROUTES.SAAS}`} />} />} />
                <Route path={`${MAIN_ROUTES.SAAS}/*`} element={<SuspenseWrapper component={<SaasComponent />} />} />
                <Route path="/*" element={<Navigate to={`${MAIN_ROUTES.SAAS}/`} />} />
              </Route>
            ) : (
              <>
                <Route path="" element={<Tenant moduleTenant={moduleTenant} />}>
                  <Route
                    path=""
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Appointment) >= 0 ? (
                            <Appointment />
                          ) : (
                            <Navigate to={`/employees`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path="appointment"
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Appointment) >= 0 ? (
                            <Appointment />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.CHECK_IN}`}
                    element={
                      !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.CheckIn) >= 0 ? (
                        <CheckInTenant />
                      ) : (
                        <Navigate to={`/`} />
                      )
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.CHECK_OUT}`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Checkout) >= 0 ? (
                            <CheckOutTenant />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.CUSTOMERS}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Customer) >= 0 ? (
                            <CustomerComponent />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.EMPLOYEES}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Employee) >= 0 ? (
                            <EmployeeComponent />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.COMPENSATION}`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Compensation) >= 0 ? (
                            <CompensationComponent />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.TIME_SHEET}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Compensation) >= 0 ? (
                            <TimesheetComponent />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />

                  <Route
                    path={`${MAIN_ROUTES.PROMOTIONS}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Promotions) >= 0 ? (
                            <PromotionsTenant />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />
                  <Route
                    path={`${MAIN_ROUTES.REPORT}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Report) >= 0 ? (
                            <ReportComponent />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />
                  <Route
                    path={`${MAIN_ROUTES.SETTING}/*`}
                    element={
                      <SuspenseWrapper
                        component={
                          !!moduleTenant?.length && moduleTenant.indexOf(TenantModuleData.Setting) >= 0 ? (
                            <SettingRoutes />
                          ) : (
                            <Navigate to={`/`} />
                          )
                        }
                      />
                    }
                  />
                </Route>
                <Route path="/*" element={<Navigate to={`/`} />} />
              </>
            )}
          </>
        ) : (
          <>
            <Route path={`${MAIN_ROUTES.SIGN_IN}`} element={<SuspenseWrapper component={<SignInTenant />} />} />
            <Route
              path={MAIN_ROUTES.FORGOT_PASSWORD}
              element={<SuspenseWrapper component={<ForgotPasswordTenant />} />}
            />
            <Route
              path={MAIN_ROUTES.VERIFICATION_CODE}
              element={<SuspenseWrapper component={<VerificationCodeTenant />} />}
            />
            <Route path="/*" element={<Navigate to={`${MAIN_ROUTES.SIGN_IN}`} />} />
          </>
        )}
        <Route path={`${MAIN_ROUTES.ONLINE_APPOINTMENT}/:id`} element={<OnlineAppointmentNew />} />
        <Route
          path={`${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.INFORMATION}/:id`}
          element={<Information />}
        ></Route>
        <Route
          path={`${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.CHECKINBYQRCODE}/:id`}
          element={<CheckInByQR />}
        ></Route>
        <Route
          path={`${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.CONFIRM}/:id`}
          element={<CheckInConfirm />}
        ></Route>
        <Route path={MAIN_ROUTES.PRIVACY} element={<SuspenseWrapper component={<PrivacyTenant />} />} />
        <Route path={MAIN_ROUTES.TERM_OF_USER} element={<SuspenseWrapper component={<TermOfUserTenant />} />} />
        <Route path={'print'} element={<SuspenseWrapper component={<PrintTenant />} />} />
      </Routes>
    </Suspense>
  );
};
