import { handleTruncateToTwoDecimal } from '../../../../../../utils';

export interface ConnectPaxDevice {
  command: string;
  version: string;
}

export interface ConnectPaxResponse {
  status: number | string;
  command: string;
  version: string;
  responseCode: number | string;
  responseMessage: string;
  sn: number | string;
  modelName: string;
  osVersion: number | string;
  macAddress: string;
  numberOfLinesPerscreen: number | string;
  numberOfCharsPerline: number | string;
  additionalInformation: string;
}

export interface PaxPerformSale {
  amount: number;
  endPoint: string;
  onSaleResponse: (response: any) => void;
}

export const paxDevice = () => {
  const PAX = {
    mStx: {
      hex: 0x02,
      code: '02',
    },
    mFS: {
      hex: 0x1c,
      code: '1c',
    },
    mEtx: {
      hex: 0x03,
      code: '03',
    },
    mUS: {
      hex: 0x1f,
      code: '1F',
    },
  };

  const hexToBase64 = (str: string) => {
    return window.btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, '')
          .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
          .replace(/ +$/, '')
          .split(' ') as any
      )
    );
  };

  const base64ToHex = (str: string) => {
    const bin = window.atob(str);
    const hex = [];

    for (let i = 0; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = `0${tmp}`;
      hex[hex.length] = tmp;
    }

    return hex.join(' ');
  };

  const stringToHex = (response: string) => {
    let responseHex = '';

    for (let i = 0; i < response.length; i++) {
      if (!responseHex) {
        responseHex =
          response.charCodeAt(i).toString(16).length < 2
            ? `0${response.charCodeAt(i).toString(16)}`
            : response.charCodeAt(i).toString(16);
      } else {
        responseHex +=
          response.charCodeAt(i).toString(16).length < 2
            ? ` 0${response.charCodeAt(i).toString(16)}`
            : ` ${response.charCodeAt(i).toString(16)}`;
      }
    }

    return responseHex;
  };

  const hexToString = (response: any) => {
    let responseHex = '';
    const arr = response.split(' ');

    for (let i = 0; i < arr.length; i++) {
      if (!arr[i]) continue;

      responseHex += String.fromCharCode(parseInt(arr[i], 16));
    }

    return responseHex;
  };

  const getLRC = (params: any) => {
    let lrc = 0;

    for (let i = 1; i < params.length; i++) {
      const paramTypeOf = typeof params[i];

      if (paramTypeOf === 'string') {
        const element = params[i].split('');

        for (let j = 0; j < element.length; j++) {
          lrc ^= element[j].charCodeAt(0);
        }
      } else {
        lrc ^= params[i];
      }
    }

    return lrc > 0 ? String.fromCharCode(lrc) : 0;
  };

  const httpCommunication = (
    commandType: string,
    url: string,
    callback: (response: ConnectPaxResponse | any) => void
  ) => {
    let xhr: XMLHttpRequest | null = null;

    if (window.XMLHttpRequest) {
      xhr = new XMLHttpRequest();
    }

    if (xhr) {
      xhr.open('GET', url, true);

      xhr.onreadystatechange = () => {
        if (xhr && xhr.readyState === 4) {
          if (xhr.status === 200) {
            const response = xhr.responseText;
            const checkParams = stringToHex(response).split(' ').pop();
            const redundancyCheck = stringToHex(response).split(' ').pop()?.substring(1);
            const check = getLRC(checkParams);

            if (check === redundancyCheck) {
              const packetInfo = [];
              const len = stringToHex(response).indexOf('03');
              const hex = stringToHex(response).slice(0, len).split(/02|1c/);

              let subHex = [];
              let subPacketInfo = [];

              if (commandType === 'DoCredit') {
                for (let i = 0; i < hex.length; i++) {
                  if (hex[i]) {
                    if (hex[i].indexOf('1f') > 0) {
                      subHex = hex[i].split('1f');
                      subPacketInfo = [];

                      for (let j = 0; j < subHex.length; j++) {
                        if (subHex[j]) {
                          subPacketInfo.push(hexToString(subHex[j]));
                        }
                      }

                      packetInfo.push(subPacketInfo);
                    } else {
                      packetInfo[i] = hexToString(hex[i]);
                    }
                  }
                }
              } else {
                for (let i = 0; i < hex.length; i++) {
                  if (hex[i]) {
                    packetInfo[i] = hexToString(hex[i]);
                  }
                }
              }

              if (commandType !== 'DoCredit') {
                callback({
                  status: packetInfo[1] as string,
                  command: packetInfo[2] as string,
                  version: packetInfo[3] as string,
                  responseCode: packetInfo[4] as string,
                  responseMessage: packetInfo[5] as string,
                  sn: packetInfo[6] as string,
                  modelName: packetInfo[7] as string,
                  osVersion: packetInfo[8] as string,
                  macAddress: packetInfo[9] as string,
                  numberOfLinesPerscreen: packetInfo[10] as string,
                  numberOfCharsPerline: packetInfo[11] as string,
                  additionalInformation: packetInfo[12] as string,
                });
              } else {
                callback(packetInfo);
              }
            }
          } else {
            console.error('Failed to do credit', xhr);
          }
        }
      };

      xhr.send(null);
    }
  };

  const pushParams = (params: any, type: string, objectInfo: any) => {
    let empty = 0;
    let arr = [...params];

    for (const name in objectInfo) {
      if (!objectInfo[name] && type !== 'additionalInformation') {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type === 'additionalInformation') {
        if (!objectInfo[name]) {
          continue;
        }

        empty++;
        arr.push(`${name}=${objectInfo[name].toString()}`);
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }

    arr.pop();

    if (empty === 0 && type !== 'additionalInformation') {
      arr = params;
    }

    if (empty === 0 && type === 'additionalInformation') {
      arr.push(PAX.mFS.hex);
    }

    return arr;
  };

  const addBase64 = (elements: any, type: string, objectInfo: any) => {
    let empty = 0;
    let arr = [...elements];

    for (const name in objectInfo) {
      if (!objectInfo[name] && type !== 'additionalInformation') {
        arr.push(PAX.mUS.code);
        continue;
      }

      if (type === 'additionalInformation') {
        if (!objectInfo[name]) continue;

        empty++;
        arr.push(base64ToHex(window.btoa(name + '=' + objectInfo[name].toString())));
      } else {
        empty++;
        arr.push(base64ToHex(window.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }

    arr.pop();

    if (empty === 0 && type !== 'additionalInformation') {
      arr = elements;
    }

    if (empty === 0 && type === 'additionalInformation') {
      arr.push(PAX.mFS.code);
    }

    return arr;
  };

  const connect = (
    initialInfo: ConnectPaxDevice,
    callback: (response: ConnectPaxResponse) => void,
    endpoint: string
  ) => {
    const params = [PAX.mStx.hex, initialInfo.command, PAX.mFS.hex, initialInfo.version, PAX.mEtx.hex];
    const lrc = getLRC(params);
    const commandHex = base64ToHex(window.btoa(initialInfo.command));
    const versionHex = base64ToHex(window.btoa(initialInfo.version));

    const elements = [
      PAX.mStx.code,
      commandHex,
      PAX.mFS.code,
      versionHex,
      PAX.mEtx.code,
      base64ToHex(window.btoa(lrc || '')),
    ];

    const finalString = elements.join(' ');
    const finalB64 = hexToBase64(finalString);
    const url = `${endpoint}?${finalB64}`;

    httpCommunication('Initialize', url, callback);
  };

  const performSale = (props: PaxPerformSale) => {
    const { amount, endPoint, onSaleResponse } = props;

    const command = 'T00';
    const version = '1.28';
    const transactionType = '01';

    const config = {
      amountInformation: {
        TransactionAmount: Math.round(handleTruncateToTwoDecimal(amount * 100)),
        TipAmount: '',
        CashBackAmount: '',
        MerchantFee: '',
        TaxAmount: '',
        FuelAmount: '',
      },
      accountInformation: {
        Account: '',
        EXPD: '',
        CVVCode: '',
        EBTtype: '',
        VoucherNumber: '',
        Force: '',
        FirstName: '',
        LastName: '',
        CountryCode: '',
        State_ProvinceCode: '',
        CityName: '',
        EmailAddress: '',
      },
      traceInformation: {
        ReferenceNumber: '1',
        InvoiceNumber: '',
        AuthCode: '',
        TransactionNumber: '',
        TimeStamp: '',
        ECRTransID: '',
      },
      avsInformation: {
        ZipCode: '',
        Address: '',
        Address2: '',
      },
      cashierInformation: {
        ClerkID: '',
        ShiftID: '',
      },
      commercialInformation: {
        PONumber: '',
        CustomerCode: '',
        TaxExempt: '',
        TaxExemptID: '',
        MerchantTaxID: '',
        DestinationZipCode: '',
        ProductDescription: '',
      },
      motoEcommerce: {
        MOTO_E_CommerceMode: '',
        TransactionType: '',
        SecureType: '',
        OrderNumber: '',
        Installments: '',
        CurrentInstallment: '',
      },
      additionalInformation: {
        TABLE: '',
        GUEST: '',
        SIGN: '',
        TICKET: '',
        HREF: '',
        TIPREQ: '',
        SIGNUPLOAD: '',
        REPORTSTATUS: '',
        TOKENREQUEST: '',
        TOKEN: '',
        CARDTYPE: '',
        CARDTYPEBITMAP: '',
        PASSTHRUDATA: '',
        RETURNREASON: '',
        ORIGTRANSDATE: '',
        ORIGPAN: '',
        ORIGEXPIRYDATE: '',
        ORIGTRANSTIME: '',
        DISPROGPROMPTS: '',
        GATEWAYID: '',
        GETSIGN: '',
        ENTRYMODEBITMAP: '',
        RECEIPTPRINT: '',
        CPMODE: '',
        ODOMETER: '',
        VEHICLENO: '',
        JOBNO: '',
        DRIVERID: '',
        EMPLOYEENO: '',
        LICENSENO: '',
        JOBID: '',
        DEPARTMENTNO: '',
        CUSTOMERDATA: '',
        USERID: '',
        VEHICLEID: '',
      },
    };

    let params = [PAX.mStx.hex, command, PAX.mFS.hex, version, PAX.mFS.hex, transactionType, PAX.mFS.hex];

    params = pushParams(params, 'amountInformation', config.amountInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'accountInformation', config.accountInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'traceInformation', config.traceInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'avsInformation', config.avsInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'cashierInformation', config.cashierInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'commercialInformation', config.commercialInformation);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'motoEcommerce', config.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = pushParams(params, 'additionalInformation', config.additionalInformation);

    params.push(PAX.mEtx.hex);

    const lrc = getLRC(params);
    const commandHex = base64ToHex(window.btoa(command));
    const versionHex = base64ToHex(window.btoa(version));
    const transactionTypeHex = base64ToHex(window.btoa(transactionType));

    let elements = [
      PAX.mStx.code,
      commandHex,
      PAX.mFS.code,
      versionHex,
      PAX.mFS.code,
      transactionTypeHex,
      PAX.mFS.code,
    ];

    elements = addBase64(elements, 'amountInformation', config.amountInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'accountInformation', config.accountInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'traceInformation', config.traceInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'avsInformation', config.avsInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'cashierInformation', config.cashierInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'commercialInformation', config.commercialInformation);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'motoEcommerce', config.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = addBase64(elements, 'additionalInformation', config.additionalInformation);

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(window.btoa(lrc.toString())));
    const finalString = elements.join(' ');
    const finalB64 = hexToBase64(finalString);

    const url = `${endPoint}?${finalB64}`;

    httpCommunication('DoCredit', url, onSaleResponse);
  };

  return { connect, performSale };
};
