import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { Employee, Salon, Service, ServiceItems, SettingCheckin } from '../../../../apis/client-axios';
import FormWrap from '../../../../components/FormWrap';
import PickServices from './Services';
import { FormInstance } from 'antd/lib';
import { Form } from 'antd';
import BookingInformation from './BookingInformation';
import PickTechnicians from './Technicians';
import { Booking } from '..';
import { toDayjsTimeZone } from '../../../../utils';
import NotificationError from '../../../../components/HandleShowNotiError';

interface StepTwoProps {
  settingCheckin?: SettingCheckin;
  services: Service[];
  technicians: Employee[];
  bookings: Booking[];
  setBookings: Dispatch<SetStateAction<Booking[]>>;
  setStep: Dispatch<SetStateAction<number>>;
  dataSalon?: Salon;
}

export interface FormData {
  categoryId?: number;
  anyTechnician?: boolean;
}

export interface Notification {
  availableTime?: string | Date;
  isBusy: boolean;
  technician: Employee;
  service?: ServiceItems;
}

export interface StepTwoCommonProps {
  form: FormInstance<FormData>;
  bookings: Booking[];
  naturalBookings: Booking[];
  setBookings: Dispatch<SetStateAction<Booking[]>>;
  setNaturalBookings: Dispatch<SetStateAction<Booking[]>>;
}
export const n = (key: keyof FormData) => key;

const PickAServiceAndTechnician: FC<StepTwoProps> = (props) => {
  const intl = useIntl();
  const { services, technicians, bookings, setBookings, setStep, dataSalon } = props;
  const [statusAnyTechnician, setStatusAnyTechnician] = useState<boolean>(false);
  const [form] = Form.useForm<FormData>();
  const [naturalBookings, setNaturalBookings] = useState<Booking[]>([]);
  const onNext = () => {
    if (
      !bookings ||
      !bookings.length ||
      bookings.some((booking) => !booking.service || (!booking.technician && !booking.isAnyTechnician))
    ) {
      const errorBookings = bookings.filter(
        (booking) => !booking.service || (!booking.technician && !booking.isAnyTechnician)
      );

      errorBookings.forEach((booking) => {
        const element = document.getElementById(`${booking.id}`);
        if (element) {
          element.classList.remove('border-color-0090FF');
          element.classList.remove('border');
          element.classList.add('salon__booking-error');
        }
      });
      NotificationError({
        contentNoti: intl.formatMessage({ id: 'onlineAppointmentNew.error' }),
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="salon__step-two">
        <FormWrap name="stepTwo" form={form}>
          <div className="salon__step-two-top">
            <PickServices
              form={form}
              bookings={bookings}
              services={services}
              setBookings={setBookings}
              setNaturalBookings={setNaturalBookings}
              naturalBookings={naturalBookings}
              statusAnyTechnician={statusAnyTechnician}
              setStatusAnyTechnician={setStatusAnyTechnician}
              dataSalon={dataSalon}
            />
            <PickTechnicians
              form={form}
              bookings={bookings}
              technicians={technicians}
              setBookings={setBookings}
              setNaturalBookings={setNaturalBookings}
              naturalBookings={naturalBookings}
              statusAnyTechnician={statusAnyTechnician}
              setStatusAnyTechnician={setStatusAnyTechnician}
              dataSalon={dataSalon}
            />
            <BookingInformation
              form={form}
              bookings={bookings}
              setBookings={setBookings}
              setNaturalBookings={setNaturalBookings}
              naturalBookings={naturalBookings}
            />
          </div>
        </FormWrap>

        <div className=" salon__step-two-bottom">
          <ButtonStyled
            content={
              <span className=" salon__step-two-bottom-right-text">
                {intl.formatMessage({
                  id: 'bookingOnline.next',
                })}
              </span>
            }
            isLarge={true}
            buttonProps={{
              className: 'width-184',
              onClick: onNext,
            }}
            isPrimary={true}
            isButtonCancel={false}
          />
        </div>
      </div>
    </>
  );
};
export default PickAServiceAndTechnician;
