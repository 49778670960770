import React from 'react';

export const SvgAppointmentCanceledIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 2.66659H12V1.99992C12 1.63325 11.7 1.33325 11.3333 1.33325C10.9667 1.33325 10.6667 1.63325 10.6667 1.99992V2.66659H5.33333V1.99992C5.33333 1.63325 5.03333 1.33325 4.66667 1.33325C4.3 1.33325 4 1.63325 4 1.99992V2.66659H3.33333C2.59333 2.66659 2.00667 3.26659 2.00667 3.99992L2 13.3333C2 14.0666 2.59333 14.6666 3.33333 14.6666H12.6667C13.4 14.6666 14 14.0666 14 13.3333V3.99992C14 3.26659 13.4 2.66659 12.6667 2.66659ZM6.56 11.6466L7.83333 10.3733L9.10667 11.6466C9.3 11.8399 9.62 11.8399 9.81333 11.6466C10.0067 11.4533 10.0067 11.1333 9.81333 10.9399L8.54 9.66658L9.81333 8.39325C10.0067 8.19992 10.0067 7.87992 9.81333 7.68658C9.62 7.49325 9.3 7.49325 9.10667 7.68658L7.83333 8.95992L6.56 7.68658C6.36667 7.49325 6.04667 7.49325 5.85333 7.68658C5.66 7.87992 5.66 8.19992 5.85333 8.39325L7.12667 9.66658L5.85333 10.9399C5.66 11.1333 5.66 11.4533 5.85333 11.6466C6.04667 11.8399 6.36667 11.8399 6.56 11.6466ZM4 13.3333H12C12.3667 13.3333 12.6667 13.0333 12.6667 12.6666V5.99992H3.33333V12.6666C3.33333 13.0333 3.63333 13.3333 4 13.3333Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
