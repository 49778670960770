import { Image } from 'antd';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT_FULL_DATE_2, formatNumberThousandWithDecimal, toDayjsTimeZone } from '../../../../../utils';
import { AppointmentResource, FilterProps, RESOURCE_UNASIGNED_KEY } from '../../models';
import { useMutation, useQuery } from '@tanstack/react-query';
import { employeeApi, settingApi } from '../../../../../apis';
import { QUERY_SETTING } from '../../../../../utils/constant';
import { TurnSetting } from '../../../../../apis/client-axios';
import { AppointmentContext } from '../..';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Roles } from '../../../../../utils/permission';
import { SvgReloadIcon } from '../../../../../components/@svg/SvgReloadIcon';
import NotificationSuccess from '../../../../../components/HandleShowNotiSuccess';
import NotificationError from '../../../../../components/HandleShowNotiError';

interface TechnicianProps extends FilterProps {
  technician: AppointmentResource;
  onOpenDetailDay?: (technician: AppointmentResource) => void;
  position?: number;
}

const Technician: FC<TechnicianProps> = (props) => {
  const { technician, onOpenDetailDay } = props;
  const { setting, onRefetchSortTechnician } = useContext(AppointmentContext);
  const [detailDay, SetDetailDay] = useState<boolean>(false);
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userRoles = authUser?.user?.roles?.map((item) => item.code);
  const isOwner =
    userRoles?.includes(Roles.Owner) || userRoles?.includes(Roles.Tenant) || userRoles?.includes(Roles.SuperAdmin);
  const intl = useIntl();
  const divRef = useRef<HTMLDivElement>(null);
  const [, forceRender] = useState({});
  useEffect(() => {
    forceRender({});
  }, []);
  const checkinAppointment = useMutation(
    () =>
      employeeApi.employeeControllerCheckinAppointment(technician?.id, {
        checkinTime: toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_2),
      }),
    {
      onSuccess: () => {
        onRefetchSortTechnician();
      },
    }
  );
  const handleOpenDetailDay = () => {
    if (!onOpenDetailDay || technician.resourceId === RESOURCE_UNASIGNED_KEY) return;

    onOpenDetailDay(technician);
  };
  const handleReload = () => {
    SetDetailDay(!detailDay);
  };
  const handleCheckinAndCheckout = async (technicianResource: any) => {
    const data = await checkinAppointment.mutateAsync();
    if (data) {
      if (technicianResource?.isCheckin) {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.success.checkOut' }) });
      } else {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.success.checkIn' }) });
      }
    } else {
      NotificationError({ contentNoti: 'ERROR' });
    }
  };
  return (
    <div className="salon__appointment-filter-technician-item" ref={divRef}>
      <div className="salon__appointment-filter-technician-item-left">
        {!detailDay ? (
          <div className="salon__appointment-filter-technician-item-detail">
            {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (
              <div className="salon__appointment-filter-technician-avatar position-relative">
                <div
                  className={`salon__appointment-filter-technician-position cursor-pointer ${
                    (technician.resource as any)?.isCheckout ? 'bg-danger' : ''
                  } `}
                  onClick={() => handleCheckinAndCheckout(technician.resource)}
                >
                  {technician.index !== undefined &&
                  (technician.resource as any)?.isCheckin &&
                  !(technician.resource as any)?.isCheckout
                    ? (technician.index as number) + 1
                    : ''}
                </div>

                <Image alt={technician.resourceTitle} src={technician.avatar} preview={false} width={48} height={48} />
              </div>
            )}
            <div
              onClick={handleOpenDetailDay}
              className={`salon__appointment-filter-technician-infor ${
                technician.resourceId === RESOURCE_UNASIGNED_KEY
                  ? 'salon__appointment-filter-technician-infor-unasigned'
                  : ''
              }`}
            >
              <p
                className={`font-weight-600 salon__text-ellipsis salon__appointment-filter-technician-name  max-width-${
                  divRef.current?.clientWidth ? Number(divRef.current?.clientWidth) - 80 : 'unset'
                }`}
              >
                {technician.resourceTitle}
              </p>

              <div className="salon__appointment-filter-technician-detail">
                {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (setting?.showMoneyAmounts || isOwner) && (
                  <p className="font-weight-500 salon__appointment-filter-technician-revenue m-t-6">
                    $
                    {formatNumberThousandWithDecimal(technician.revenue).length > 4 &&
                    divRef.current?.clientWidth === 176
                      ? formatNumberThousandWithDecimal(technician.revenue).substring(0, 3) + '...'
                      : formatNumberThousandWithDecimal(technician.revenue)}
                  </p>
                )}
                {setting?.showTurnInfoCounts ||
                  (isOwner && (
                    <div className="font-weight-400 salon__appointment-filter-technician-tasks-wrapper">
                      <p className="font-weight-400 m-b-0 m-t-6 salon__appointment-filter-technician-tasks">
                        {technician.tasks > 9 ? technician.tasks : `0${technician.tasks}`}|
                        {formatNumberThousandWithDecimal(technician.hours).length > 4 &&
                        technician.resourceId !== RESOURCE_UNASIGNED_KEY &&
                        divRef.current?.clientWidth === 176
                          ? formatNumberThousandWithDecimal(technician.hours).substring(0, 3) + '...'
                          : formatNumberThousandWithDecimal(technician.hours)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="salon__appointment-filter-technician-item-display">
            <span className="font-size-16 font-weight-600 salon__appointment-filter-technician-item-display-tasks">
              {technician.tasks > 9 ? technician.tasks : `0${technician.tasks}`}
              <span>{intl.formatMessage({ id: 'appointment.technician.tasks' })}</span>
            </span>
            <span className="font-size-14 font-weight-400 salon__appointment-filter-technician-item-display-hours">
              {formatNumberThousandWithDecimal(technician.hours)}
              <span>{intl.formatMessage({ id: 'appointment.technician.hours' })}</span>
            </span>
          </div>
        )}
      </div>
      <div className="salon__appointment-filter-technician-item-reload" onClick={handleReload}>
        <SvgReloadIcon />
      </div>
    </div>
  );
};

export default Technician;
