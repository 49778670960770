import { SvgRemoveTechnicianIcon } from '../../../../components/@svg/SvgRemoveTechnicianIcon';
import ColWrap from '../../../../components/ColWrap';
import RowWrap from '../../../../components/RowWrap';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { formatNumberThousandWithDecimal } from '../../../../utils';

interface IProps {
  onClick?: () => void;
  onClickEdit?: () => void;
  name: string | undefined;
  price: string | number;
  supply: string | number;
}

function BillItemServices(props: IProps) {
  const { onClick, onClickEdit, name, price, supply } = props;

  return (
    <div className="salon__checkout-technician-service-container">
      <RowWrap
        isAutoFillRow={true}
        isGutter={true}
        gutter={8}
        isWrap={false}
        styleFill="between"
        rowClassName="align-items-center w-100 m-0"
      >
        <ColWrap>
          <ButtonIcon
            icon={<SvgRemoveTechnicianIcon />}
            className="m-r-10"
            buttonProps={{
              onClick: onClick,
            }}
          />
        </ColWrap>
        <ColWrap
          colProps={{
            className: 'w-100 m-0 p-0',
          }}
        >
          <RowWrap
            isAutoFillRow={true}
            isGutter={false}
            gutter={0}
            isWrap={true}
            styleFill="start"
            rowClassName="align-items-center w-100"
          >
            <ColWrap
              colProps={{
                span: 24,
              }}
            >
              <span className="salon__checkout-technician-service-name">{name}</span>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 24,
                className: 'm-0 p-0',
              }}
            >
              <RowWrap
                isAutoFillRow={true}
                isGutter={false}
                gutter={0}
                isWrap={true}
                styleFill="between"
                rowClassName="align-items-center"
              >
                <ColWrap>
                  <span
                    className="salon__checkout-technician-service-price cursor-pointer"
                    onClick={onClickEdit}
                  >{`[$${formatNumberThousandWithDecimal(supply)}]`}</span>
                </ColWrap>
                <ColWrap>
                  <span
                    className="salon__checkout-technician-service-price cursor-pointer"
                    onClick={onClickEdit}
                  >{`[$${formatNumberThousandWithDecimal(price)}]`}</span>
                </ColWrap>
              </RowWrap>
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </div>
  );
}

export default BillItemServices;
