import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../../apis';
import { CashDrawerDTO, CashDrawerDTOActionEnum } from '../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';
import { FormTextArea } from '../../../../../components/Form/FormTextArea';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import '../style.scss';
import CashDrawerKeyBoard from '../CashDrawerKeyBoard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

export interface Props {
  openModal: boolean;
  onCancelModal: () => void;
  refetchCashDrawer: () => void;
  totalBalance: number;
  onOpenCashDrawer: (reason: string) => void;
}

interface FormData {
  cashAmount?: string;
  notes?: string;
}

const n = (key: keyof FormData) => key;

const CashOut: FC<Props> = (props) => {
  const { openModal, onCancelModal, refetchCashDrawer, onOpenCashDrawer, totalBalance } = props;

  const intl = useIntl();

  const [form] = Form.useForm<FormData>();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const notes = Form.useWatch(n('notes'), form) as string | undefined;
  const cashAmount = Form.useWatch(n('cashAmount'), form) as any;
  const [inputName, setInputName] = useState<string>(n('cashAmount'));
  const cashAmountRef = useRef<HTMLInputElement>(null);

  const createCashDrawer = useMutation((data: CashDrawerDTO) => ticketApi.ticketControllerCreateCashDrawer(data), {
    onSuccess: () => {
      onOpenCashDrawer(notes || CashDrawerDTOActionEnum.CashOut);
      onCancelModal();
      form.resetFields();
      refetchCashDrawer();
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      NotificationError({ contentNoti: error.response.data.message });
    },
  });

  const onSubmit = () => {
    const { cashAmount, notes } = form.getFieldsValue();
    if (!!cashAmount) {
      const dataSubmit: CashDrawerDTO = {
        cashAmount: Number(cashAmount.replace(/[,\s]/g, '')),
        notes,
        action: CashDrawerDTOActionEnum.CashOut,
      };
      createCashDrawer.mutate(dataSubmit);
    }
  };

  useEffect(() => {
    if (cashAmount && cashAmountRef.current) {
      const [integer] = cashAmount.replace(/,/g, '').split('.');
      cashAmountRef.current.selectionStart = cashAmountRef.current.selectionEnd =
        cashAmount.length + Math.floor(integer / 3);
    }
  }, [cashAmount]);

  useEffect(() => {
    if (openModal) {
      cashAmountRef.current?.focus();
    }
  }, [openModal]);

  return (
    <>
      <StyledModal
        isOpen={openModal}
        onCancel={onCancelModal}
        modalProps={{
          title: intl.formatMessage({ id: 'checkout.cashDrawer.cashOut' }),
          className: 'salon__checkout-cash-drawer-modal',
          width: 'auto',
          footer: (
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex gap-12 align-items-center">
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.cashDrawer.ok' })}
                  buttonProps={{
                    className: 'width-100 height-38',
                    onClick: onSubmit,
                    loading: createCashDrawer.isLoading,
                    disabled: Number(cashAmount) > totalBalance,
                  }}
                  isPrimary={true}
                />
              </div>
            </div>
          ),
        }}
      >
        <FormWrap form={form} name="cashIn" layout="vertical" className="w-100  p-t-20">
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[10, 20]} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <FormInputNumber
                name={n('cashAmount')}
                label={intl.formatMessage({ id: 'checkout.cashDrawer.cashOut.title' })}
                prefix={<SvgDollarIcon />}
                numericFormatProps={{
                  min: 0,
                  max: 999_999_999,
                  className: 'm-b-0',
                  inputMode: 'none',
                  placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.cashInAndCashOut.placeholder' }),
                  onFocus: () => {
                    setInputName(n('cashAmount'));
                  },
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                  getInputRef: cashAmountRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                  rules: [
                    {
                      validator: (_, value) => {
                        if (value && Number(value) > totalBalance) {
                          return Promise.reject('');
                        }

                        return Promise.resolve();
                      },
                    },
                  ],
                }}
                classNameFormInputNumber="m-b-0"
              />
            </ColWrap>
            <ColWrap colProps={{ span: 24 }}>
              <FormTextArea
                name={n('notes')}
                inputProps={{
                  className: 'salon__checkout-cash-drawer-modal-custom-input',
                  placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.others.placeholder' }),
                  maxLength: 1000,
                  inputMode: 'none',
                  onFocus: () => {
                    setInputName(n('notes'));
                  },
                }}
              />
            </ColWrap>
          </RowWrap>
          <CashDrawerKeyBoard form={form} inputName={inputName} keyboardRef={keyboardRef} />
        </FormWrap>
      </StyledModal>
    </>
  );
};

export default CashOut;
