import { Popover, PopoverProps } from 'antd';
import { FC, ReactNode } from 'react';

interface StyledPopoverProps {
  open: boolean;
  children: ReactNode;
  popoverProps?: PopoverProps;
}

const StyledPopover: FC<StyledPopoverProps> = (props) => {
  const { open, children, popoverProps } = props;

  return (
    <Popover open={open} {...popoverProps}>
      {children}
    </Popover>
  );
};

export default StyledPopover;
