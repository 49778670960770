import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { settingApi, stripeApi } from '../../../../../apis';
import { CreateChargeDto, CreatePaymentMethodDto, CustomerCheckInDto, Salon } from '../../../../../apis/client-axios';
import ColWrap from '../../../../../components/ColWrap';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import './addCardPayment.scss';
import { StripeElementStyle } from '@stripe/stripe-js';
import { handleTruncateToTwoDecimal } from '../../../../../utils';
import { Booking } from '../..';
import { QUERY_SETTING } from '../../../../../utils/constant';

interface FormData {
  creditOrDebitCard: string;
  day: string;
  cvc: string;
}

interface AddCardPaymentProps {
  open: boolean;
  onCancelModal: () => void;
  onRefetchListPaymentMethod: () => void;
  onFinish: () => void;
  dataCustomer?: CustomerCheckInDto;
  bookings: Booking[];
  dataSalon?: Salon;
}

const n = (key: keyof FormData) => key;

const style: StripeElementStyle = {
  base: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#292f33',
    lineHeight: '20px',
    '::placeholder': {
      color: '#B4B6B8',
    },
  },
  invalid: {
    color: '#292f33',
  },
};

const AddCardPayment: FC<AddCardPaymentProps> = (props) => {
  const { open, onCancelModal, onRefetchListPaymentMethod, onFinish, dataCustomer, bookings, dataSalon } = props;

  const stripe = useStripe();
  const elements = useElements();

  const intl = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: setting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(dataSalon?.id),
    staleTime: 1000,
  });

  const onSave = () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberElement = elements.getElement('cardNumber');

    if (!cardNumberElement) return;
    setLoading(true);
    stripe.createToken(cardNumberElement, { currency: 'usd' }).then((payload) => {
      if (payload.error) {
        setLoading(false);
        return NotificationError({
          contentNoti: intl.formatMessage({ id: 'onlineAppointment.paymentInformation.error' }),
        });
      }
      if (payload.token) {
        const convertAmount = Number(
          bookings.reduce(
            (prev, cur) =>
              prev +
              (cur.service?.price
                ? Number((Number(cur.service.price) * Number(dataSalon?.deposit ?? 0)) / 100)
                : Number(dataSalon?.depositNotService)),
            0
          )
        );
        const feeCheckout = Number(setting?.data?.settingCheckout?.feeChargedPerPaymentPercentage || 0);

        const amount = convertAmount + (convertAmount * feeCheckout) / 100;

        createAppointmentMutation.mutate({
          amount: Number(handleTruncateToTwoDecimal(amount)),
          cardToken: payload.token.id,
          customerName: dataCustomer?.customerName,
          customerPhoneNumber: dataCustomer?.customerPhone,
        });
      }
    });
  };
  const createAppointmentMutation = useMutation(
    (data: CreateChargeDto) => stripeApi.stripeControllerCreateCharge(data),
    {
      onSuccess: ({ data }) => {
        onCancelModal();
        onRefetchListPaymentMethod();
        setLoading(false);
        onFinish();
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        setLoading(false);
        if (error.response.data.message === `The salon hasn't had Stripe Account yet!`) {
          NotificationError({ contentNoti: error.response.data.message });
        } else {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'onlineAppointment.paymentInformation.error' }) });
        }
      },
    }
  );

  return (
    <>
      <FormWrap
        name="purgeData"
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        form={form}
        onFinish={onSave}
      >
        <StyledModal
          isOpen={open}
          onCancel={() => {
            onCancelModal();
            form.resetFields();
          }}
          modalProps={{
            title: (
              <span className="">{intl.formatMessage({ id: 'onlineAppointmentNew.deposit.paymentInformation' })}</span>
            ),
            className: 'salon__appointment-deposit-modal',
            width: 700,
            footer: (
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex">
                  <ButtonStyled
                    content={intl.formatMessage({
                      id: 'setting.services.cancel',
                    })}
                    buttonProps={{
                      className: 'width-100 height-38',
                      onClick: () => {
                        onCancelModal();
                        form.resetFields();
                      },
                    }}
                    isPrimary={false}
                    isCancelPopup
                  />
                  <ButtonStyled
                    content={intl.formatMessage({
                      id: 'setting.services.save',
                    })}
                    buttonProps={{
                      className: 'width-100 height-38 m-l-10',
                      onClick: () => form.submit(),
                      loading: loading || createAppointmentMutation.isLoading,
                    }}
                    isPrimary
                  />
                </div>
              </div>
            ),
            destroyOnClose: true,
          }}
        >
          <RowWrap isAutoFillRow isGutter gutter={[20, 0]} isWrap styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <div className={`salon__settings-container `}>
                <div className="height-35">
                  <label htmlFor={n('creditOrDebitCard')} className="salon__settings-container-label">
                    {
                      <span className="font-size-16 font-weight-500">
                        {intl.formatMessage({ id: 'onlineAppointmentNew.deposit.creditOrDebitCard' })}
                      </span>
                    }
                  </label>
                </div>
                <CardNumberElement
                  options={{
                    showIcon: true,
                    style,
                    placeholder: `${intl.formatMessage({
                      id: 'setting.services.billingAndPayments.placeholder.creditOrDebitCard',
                    })}`,
                  }}
                />
              </div>
            </ColWrap>
            <ColWrap colProps={{ span: 12 }}>
              <div className={`salon__settings-container `}>
                <div className="height-35">
                  <label htmlFor={n('creditOrDebitCard')} className="salon__settings-container-label">
                    {
                      <span className="font-size-16 font-weight-500">
                        {intl.formatMessage({ id: 'setting.services.billingAndPayments.day' })}
                      </span>
                    }
                  </label>
                </div>
                <CardExpiryElement
                  options={{
                    style,
                  }}
                />
              </div>
            </ColWrap>
            <ColWrap colProps={{ span: 12 }}>
              <div className={`salon__settings-container `}>
                <div className="height-35">
                  <label htmlFor={n('creditOrDebitCard')} className="salon__settings-container-label">
                    {
                      <span className="font-size-16 font-weight-500">
                        {intl.formatMessage({ id: 'setting.services.billingAndPayments.placeholder.cvc' })}
                      </span>
                    }
                  </label>
                </div>
                <CardCvcElement
                  options={{
                    style,
                  }}
                />
              </div>
            </ColWrap>
          </RowWrap>
        </StyledModal>
      </FormWrap>
    </>
  );
};
export default AddCardPayment;
