import { Button, Form, FormItemProps, InputProps } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { PasswordProps } from 'antd/es/input';
import { FC } from 'react';
import { IFromState, IFromStateInput } from '../index';
import { useIntl } from 'react-intl';
import { AppointmentStatusEnum } from '../../../../../apis/client-axios';

type Props = {
  name: string;
  label?: React.ReactNode | string;
  formItemProps?: FormItemProps;
  inputProps?: InputProps | PasswordProps;
  activeTab: number;
  tabData: IFromState[];
  setTabData: (data: IFromStateInput) => void;
};

export const FormGroupButton: FC<Props> = ({ activeTab, tabData, setTabData, inputProps, formItemProps, ...rest }) => {
  const intl = useIntl();
  const currentStatus = tabData.find((item) => item.tabId == activeTab)?.status;

  const activateButtons = (...indices: number[]) => {
    switch (currentStatus) {
      case AppointmentStatusEnum.Confirmed:
        return indices.length < 2;
      case AppointmentStatusEnum.CheckedIn:
        return indices.length < 3;
      case AppointmentStatusEnum.BeingServed:
        return indices.length < 4;
      case AppointmentStatusEnum.Completed:
        return indices.length < 5;
      default:
        return false;
    }
  };

  return (
    <Form.Item
      {...rest}
      {...formItemProps}
      className={`salon__appointment-form w-100 ${formItemProps?.className ?? ''}`}
    >
      <ButtonGroup className="salon__appointment-button-group">
        <Button
          className={`salon__appointment-button-group-confirmed ${activateButtons(0) ? 'active' : ''}`}
          onClick={() => {
            if (currentStatus === AppointmentStatusEnum.Confirmed)
              setTabData({ status: AppointmentStatusEnum.WaitingConfirm });
            else setTabData({ status: AppointmentStatusEnum.Confirmed });
          }}
        >
          {intl.formatMessage({ id: 'appointment.modal.status.comfirmed' })}
        </Button>
        <Button
          className={`salon__appointment-button-group-checkedIn ${activateButtons(0, 1) ? 'active' : ''}`}
          onClick={() => setTabData({ status: AppointmentStatusEnum.CheckedIn })}
        >
          {intl.formatMessage({ id: 'appointment.modal.status.checkedIn' })}
        </Button>
        <Button
          className={`salon__appointment-button-group-served ${activateButtons(0, 1, 2) ? 'active' : ''}`}
          onClick={() => setTabData({ status: AppointmentStatusEnum.BeingServed })}
        >
          {intl.formatMessage({ id: 'appointment.modal.status.beingServed' })}
        </Button>
        <Button className={`salon__appointment-button-group-completed ${activateButtons(0, 1, 2, 3) ? 'active' : ''}`}>
          {intl.formatMessage({ id: 'appointment.modal.status.completed' })}
        </Button>
      </ButtonGroup>
    </Form.Item>
  );
};
