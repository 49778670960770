import React from 'react';

export const SvgMiniStarIconActive = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00151 11.6795L10.7682 13.3529C11.2748 13.6595 11.8948 13.2062 11.7615 12.6329L11.0282 9.48619L13.4748 7.36619C13.9215 6.97952 13.6815 6.24619 13.0948 6.19952L9.87485 5.92619L8.61485 2.95285C8.38818 2.41285 7.61485 2.41285 7.38818 2.95285L6.12818 5.91952L2.90818 6.19285C2.32151 6.23952 2.08151 6.97285 2.52818 7.35952L4.97485 9.47952L4.24151 12.6262C4.10818 13.1995 4.72818 13.6529 5.23485 13.3462L8.00151 11.6795Z"
        fill="#E0B005"
      />
    </svg>
  </span>
);
