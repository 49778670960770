import React from 'react';

export const SvgAppointmentCheckedInIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63216 8.47582C9.10549 8.47582 10.2988 7.28249 10.2988 5.80915C10.2988 4.33582 9.10549 3.14249 7.63216 3.14249C6.15883 3.14249 4.9655 4.33582 4.9655 5.80915C4.9655 7.28249 6.15883 8.47582 7.63216 8.47582ZM8.29883 13.8092L7.7255 13.2358C6.93883 12.4492 6.94549 11.1692 7.73883 10.3958L8.29883 9.84915C8.03883 9.82249 7.84549 9.80915 7.63216 9.80915C5.85216 9.80915 2.29883 10.7025 2.29883 12.4758V13.8092H8.29883ZM8.75883 12.2692L10.1388 13.6625C10.3988 13.9225 10.8255 13.9225 11.0855 13.6625L14.5122 10.2092C14.7655 9.95582 14.7655 9.54249 14.5122 9.28249L14.5055 9.27582C14.2455 9.01582 13.8255 9.01582 13.5655 9.27582L10.6122 12.2492L9.69883 11.3358C9.44549 11.0758 9.02549 11.0758 8.76549 11.3358L8.75883 11.3425C8.50549 11.5958 8.50549 12.0092 8.75883 12.2692Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
