import { ButtonProps, Modal } from 'antd';
import { ModalProps } from 'antd/lib';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import NotificationSuccess from '../HandleShowNotiSuccess';
import { ButtonStyled } from '../buttons/ButtonStyled';
import './styledPopup.scss';
type Props = {
  content?: ReactNode | string;
  content2?: ReactNode | string;
  contentStrong?: ReactNode | string;
  contentNoti?: ReactNode | string;
  isOpen: boolean;
  modalProps?: ModalProps;
  onCancel: () => void;
  onOk: () => void;
  buttonPropsOK?: ButtonProps;
  buttonPropsCancel?: ButtonProps;
  title?: ReactNode | string;
  customFooter?: ReactNode | string;
  width?: number;
  isHiddenFooter?: boolean;
  isHiddenCancel?: boolean;
  isLoading?: boolean;
  isTextLeft?: boolean;
  classNameContent?: string;
  wrapClassNameEx?: string;
  onCancelButton?: () => void;
};
export const StyledPopup: React.FC<Props> = ({
  content,
  content2,
  contentStrong,
  contentNoti,
  isOpen,
  modalProps,
  onCancel,
  onOk,
  buttonPropsCancel,
  buttonPropsOK,
  title,
  customFooter,
  width,
  isHiddenFooter,
  isLoading,
  onCancelButton,
  isHiddenCancel,
  isTextLeft,
  classNameContent,
  wrapClassNameEx,
}) => {
  const intl = useIntl();
  return (
    <>
      <Modal
        width={width || 480}
        title={title}
        open={isOpen}
        maskClosable={false}
        keyboard={false}
        closable={false}
        destroyOnClose={true}
        onCancel={onCancel}
        onOk={onOk}
        closeIcon={null}
        wrapClassName={`salon__styled-popup ${wrapClassNameEx ?? ''}`}
        {...modalProps}
        centered={true}
        footer={
          isHiddenFooter
            ? false
            : customFooter || (
                <div className="d-flex gap-12 align-items-center justify-content-center">
                  {!isHiddenCancel && (
                    <ButtonStyled
                      content={
                        buttonPropsCancel?.content
                          ? buttonPropsCancel?.content
                          : intl.formatMessage({ id: 'common.no' })
                      }
                      buttonProps={{
                        className: 'width-100',
                        onClick: () => {
                          onCancel();
                          if (onCancelButton) {
                            onCancelButton();
                          }
                        },
                        ...buttonPropsCancel,
                      }}
                      isButtonCancel={true}
                      isCancelPopup={true}
                      isPrimary={false}
                    />
                  )}
                  <ButtonStyled
                    content={buttonPropsOK?.content ? buttonPropsOK?.content : intl.formatMessage({ id: 'common.yes' })}
                    buttonProps={{
                      className: 'width-100 m-0',
                      onClick: async () => {
                        await onOk();
                        if (contentNoti) {
                          NotificationSuccess({ contentNoti: contentNoti });
                        }
                      },
                      ...buttonPropsOK,
                      loading: isLoading,
                      disabled: isLoading,
                    }}
                    isPrimary={true}
                  />
                </div>
              )
        }
        className="salon__popup-styled"
      >
        <p className={`salon__popup-styled-content ${!isTextLeft ? 'text-center' : ''}${classNameContent ?? ''}`}>
          {content}
          {contentStrong && <span className="salon__popup-styled-content-strong">{contentStrong}</span>}
          {content2}
        </p>
      </Modal>
    </>
  );
};
