import React from 'react';

interface IProps {
  className?: string;
}

export const SvgArrowUpCollapse: React.FC<IProps> = (props) => {
  return (
    <span {...props}>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.40814 7.08893C0.733577 7.41437 1.26121 7.41437 1.58665 7.08893L5.9974 2.67819L10.4081 7.08893C10.7336 7.41437 11.2612 7.41437 11.5867 7.08893C11.9121 6.76349 11.9121 6.23586 11.5867 5.91042L6.58665 0.910419C6.26121 0.584982 5.73358 0.584982 5.40814 0.910419L0.40814 5.91042C0.0827033 6.23586 0.0827033 6.76349 0.40814 7.08893Z"
          fill="#292F33"
        />
      </svg>
    </span>
  );
};
