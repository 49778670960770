import { Form, FormInstance, Tooltip } from 'antd';
import PageContainer from '../../../../components/PageContainer';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';
import { FormSelect } from '../../../../components/Form/FormSelect';
import { useIntl } from 'react-intl';
import { SettingPrinterCloverPrinterEnum } from '../../../../apis/client-axios';
import { SvgToolTipIcon } from '../../../../components/@svg/SvgToolTipIcon';
import { FormInput } from '../../../../components/Form/FormInput';
import { regexCheckIpV4 } from '../../../../utils';
import { FormInputNumberFormat } from '../../../../components/Form/FormInputNumberFormat';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import { FormCheckboxGroup } from '../../../../components/Form/FormCheckboxGroup';
import { SettingPrinterReceiptForEnum } from '.';

interface IProps {
  form: FormInstance;
  index: number;
  printerActive: number;
  setPrinterActive: (value: number) => void;
}

interface FormData {
  cloverPrinter: string;
  printerIPAddress: string;
  printerDeviceIP: string;
  charactersPerLine: string;
  lineSpacing: string;
  printerName: string;
  paperWidth: number;
  receiptFor: string;
}

export const PrinterChild: React.FC<IProps> = (props) => {
  const { form, index, printerActive, setPrinterActive } = props;

  const intl = useIntl();

  const n = (key: keyof FormData) => key;

  const cloverPrinter = Form.useWatch(n('cloverPrinter') + index, form);

  const ReceiptForOption = [
    {
      label: 'Check in',
      value: SettingPrinterReceiptForEnum.CHECK_IN,
    },
    {
      label: 'Check out',
      value: SettingPrinterReceiptForEnum.CHECK_OUT,
    },
    {
      label: 'Compensation',
      value: SettingPrinterReceiptForEnum.COMPENSATION,
    },
  ];

  return (
    <PageContainer
      paddingPage={20}
      marginTop={20}
      className=" p-b-0"
      style={printerActive === index ? { borderColor: 'blue', borderStyle: 'solid' } : {}}
      onClick={() => setPrinterActive(index)}
    >
      {cloverPrinter === SettingPrinterCloverPrinterEnum.PrinterConnectedViaUsb ? (
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 0]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormSelect
              name={n('cloverPrinter') + index}
              selectProps={{
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.cloverPrinter',
                }),
                options: Object.values(SettingPrinterCloverPrinterEnum).map((value) => ({
                  label: value,
                  value: value,
                })),
                allowClear: false,
              }}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.label.select.cloverPrinter' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.cloverPrinter',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="topLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              formItemProps={{
                className: 'salon__setting-printer-modal-form-input m-b-20',
              }}
            />
          </ColWrap>

          {/* <ColWrap colProps={{ span: 12, className: 'salon__printer-settings__ip-address' }}>
            <FormInput
              name={n('printerName') + index}
              label={
                <span>
                  Printer Name
                  <span className="m-l-8">
                    <Tooltip
                      title={'Name Of Printer'}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              inputProps={{
                placeholder: 'Printer Name',
              }}
              formItemProps={{
                className: 'm-b-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (!v) {
                        return Promise.reject('');
                      }

                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 12, className: 'salon__printer-settings__line-space' }}>
            <FormInputNumber
              name={n('paperWidth') + index}
              label={
                <span>
                  Width Paper (inch)
                  <span className="m-l-8">
                    <Tooltip
                      title={'Default Width: 2.83'}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              numericFormatProps={{
                defaultValue: 2.83,
                placeholder: 'Width Paper (inch)',
              }}
              formItemProps={{
                className: 'm-b-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.toString()?.trim();

                      if (/^\s*$/.test(v)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap> */}

          <ColWrap colProps={{ span: 24, className: 'm-t-20' }}>
            <FormCheckboxGroup
              label={'Print receipt for'}
              name={n('receiptFor') + index}
              formItemProps={{
                className: 'm-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string[]) => {
                      const v = value;

                      if (v?.length < 1) {
                        return Promise.reject('');
                      }

                      return Promise.resolve();
                    },
                  },
                ],
              }}
              checkboxGroupProps={{
                options: ReceiptForOption,
                className: 'gap-17 align-items-center',
              }}
            />
          </ColWrap>
        </RowWrap>
      ) : (
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 0]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormSelect
              name={n('cloverPrinter') + index}
              selectProps={{
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.cloverPrinter',
                }),
                options: Object.values(SettingPrinterCloverPrinterEnum).map((value) => ({
                  label: value,
                  value: value,
                })),
                allowClear: false,
              }}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.label.select.cloverPrinter' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.cloverPrinter',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="topLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              formItemProps={{
                className: 'salon__setting-printer-modal-form-input m-b-20',
              }}
            />
          </ColWrap>

          {/* <ColWrap colProps={{ span: 12, className: 'salon__printer-settings__ip-address' }}>
            <FormInput
              name={n('printerIPAddress') + index}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.input.printerIPAddress' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.printerIPAddress',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.printerIPAddress',
                }),
              }}
              formItemProps={{
                className: 'm-b-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (/^\s*$/.test(v)) {
                        return Promise.reject('');
                      }
                      if (regexCheckIpV4(v) === false) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 12 }}>
            <FormInput
              name={n('printerDeviceIP') + index}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.input.printerDeviceIP' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.printerDeviceIP',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              inputProps={{
                maxLength: 255,
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.printerDeviceIP',
                }),
              }}
              formItemProps={{
                className: 'm-b-20',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (/^\s*$/.test(v)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 12, className: 'salon__printer-settings__characters-line' }}>
            <FormInputNumberFormat
              name={n('charactersPerLine') + index}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.input.charactersPerLine' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.charactersPerLine',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              patternFormat={{
                format: '##',
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.charactersPerLine',
                }),
                className: 'm-b-0 ',
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                rules: [
                  {
                    validator: (_, value: string | number) => {
                      if (value && value.toString().trim().length < 2) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 12, className: 'salon__printer-settings__line-space' }}>
            <FormInputNumber
              name={n('lineSpacing') + index}
              label={
                <span>
                  {intl.formatMessage({ id: 'setting.services.printerSettings.input.lineSpacing' })}
                  <span className="m-l-8">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'setting.services.printerSettings.tooltip.lineSpacing',
                      })}
                      trigger="hover"
                      overlayClassName="max-width-442 font-size-16"
                      placement="bottomLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              numericFormatProps={{
                defaultValue: 30,
                placeholder: intl.formatMessage({
                  id: 'setting.services.printerSettings.placeholder.lineSpacing',
                }),
              }}
              formItemProps={{
                className: 'm-b-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (/^\s*$/.test(v)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap> */}

          <ColWrap colProps={{ span: 24, className: 'm-t-20' }}>
            <FormCheckboxGroup
              label={'Print receipt for'}
              name={n('receiptFor') + index}
              formItemProps={{
                className: 'm-0',
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value;

                      if (v?.length < 1) {
                        return Promise.reject('');
                      }

                      return Promise.resolve();
                    },
                  },
                ],
              }}
              checkboxGroupProps={{
                options: ReceiptForOption,
                className: 'gap-17 align-items-center',
              }}
            />
          </ColWrap>
        </RowWrap>
      )}
    </PageContainer>
  );
};
