import { Button, ButtonProps } from 'antd';
import React, { ReactNode } from 'react';
import './buttonStyled.scss';

type Props = {
  label?: string;
  buttonProps?: ButtonProps;
  content: ReactNode | string;
  isPrimary: boolean;
  isButtonCancel?: boolean;
  isCancelPopup?: boolean;
  isLarge?: boolean;
  isWarning?: boolean;
  heightButton?: number;
};
export const ButtonStyled: React.FC<Props> = ({ ...props }) => {
  const {
    buttonProps,
    isPrimary,
    isButtonCancel,
    isCancelPopup,
    isLarge,
    isWarning,
    content,
    heightButton
  } = props;
  return (
    <>
      {isButtonCancel ? (
        <>
          {isCancelPopup ? (
            <Button
              type="default"
              {...buttonProps}
              className={`salon__button-cancel-popup ${isLarge ? `height-${heightButton ?? 64} font-size-32` : `height-${heightButton ?? 38} font-size-16`} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          ) : (
            <Button
              type="default"
              {...buttonProps}
              className={`${isWarning ? 'salon__button-warning' : 'salon__button-cancel'} ${
                isLarge ? `height-${heightButton ?? 64} font-size-32` : `height-${heightButton ?? 38} font-size-16`
              } ${buttonProps?.className ?? ''}`}
            >
              {content}
            </Button>
          )}
        </>
      ) : (
        <>
          {isPrimary ? (
            <Button
              type="primary"
              {...buttonProps}
              className={`salon__button-primary ${isLarge ? `height-${heightButton ?? 64} font-size-32` : `height-${heightButton ?? 38} font-size-16`} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          ) : (
            <Button
              type="default"
              {...buttonProps}
              className={`salon__button-secondary ${isLarge ? `height-${heightButton ?? 64} font-size-32` : `height-${heightButton ?? 38} font-size-16`} ${
                buttonProps?.className ?? ''
              }`}
            >
              {content}
            </Button>
          )}
        </>
      )}
    </>
  );
};
