import { Modal, ModalProps } from 'antd';
import React, { ReactNode } from 'react';
import './modalStyled.scss';
import { SvgCloseIcon } from '../@svg/SvgCloseIcon';
type Props = {
  label?: string;
  modalProps?: ModalProps;
  children: ReactNode | string;
  isOpen: boolean;
  onCancel: () => void;
};
export const StyledModal: React.FC<Props> = ({ modalProps, children, isOpen, onCancel, ...rest }) => {
  return (
    <>
      <Modal
        open={isOpen}
        maskClosable={false}
        keyboard={false}
        closable={true}
        destroyOnClose={true}
        onCancel={onCancel}
        closeIcon={<SvgCloseIcon />}
        footer={modalProps?.footer}
        centered={true}
        {...modalProps}
        className={`salon__modal-styled ${modalProps?.className ?? ''}`}
      >
        {children}
      </Modal>
    </>
  );
};
