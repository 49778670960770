import { Form } from 'antd';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  SalonSetting,
  TicketTransactionDTOTypePaymentEnum,
  UpdateTerminalDeviceSettingDtoDeviceConnectedEnum,
} from '../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { RootState } from '../../../../../../store';
import {
  convertFormatNumberToNumber,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../../../../utils';
import KeyboardWrapper, { KeyboardDirection } from '../../../keyboard';
import './style.scss';
import { useClover } from '../../../../setting/terminalDeviceSetting/Clover/connection/hooks';

interface MultipleCreditCardsModalProps {
  open: boolean;
  onCancel: () => void;
  balanceToPay: number;
  onCloverPayment: () => void;
  ticketTransactionPaymentRef: MutableRefObject<{
    type: TicketTransactionDTOTypePaymentEnum;
    amount: number;
  }>;
  onPaxConnect: () => void;
  settings?: SalonSetting;
}

interface FormData {
  card1?: number;
  card2?: number;
  card3?: number;
}

const n = (key: keyof FormData) => key;

const creditCardInformation = [
  {
    name: n('card1'),
    titleId: 'checkout.multipleCreditCards.card1',
  },
  {
    name: n('card2'),
    titleId: 'checkout.multipleCreditCards.card2',
  },
  {
    name: n('card3'),
    titleId: 'checkout.multipleCreditCards.card3',
  },
];

const initialLoading: Record<keyof FormData, boolean> = {
  [n('card1')]: false,
  [n('card2')]: false,
  [n('card3')]: false,
} as Record<keyof FormData, boolean>;

const MultipleCreditCardsModal: FC<MultipleCreditCardsModalProps> = (props) => {
  const { open, onCancel, balanceToPay, onCloverPayment, ticketTransactionPaymentRef, onPaxConnect, settings } = props;

  const intl = useIntl();
  const { onConnect } = useClover();

  const keyboardRef = useRef<any>(null);
  const inputRef = useRef<Array<{ name: keyof FormData; input: HTMLInputElement }>>([]);

  const [focusedInputName, setFocusedInputName] = useState<keyof FormData>(n('card1'));
  const [isLoading, setIsLoading] = useState<Record<keyof FormData, boolean>>(initialLoading);

  const [form] = Form.useForm<FormData>();
  const card = Form.useWatch(focusedInputName, form) as string | undefined;

  const saleResponse = useSelector((state: RootState) => state.clover.saleResponse);
  const isDisconnected = useSelector((state: RootState) => state.clover.isDisconnected);

  useEffect(() => {
    if (saleResponse || isDisconnected) {
      setIsLoading(initialLoading);
      form.resetFields();
    }
  }, [saleResponse, isDisconnected]);

  useEffect(() => {
    if (inputRef.current.length > 0) {
      const input = inputRef.current.find((ref) => ref.name === n('card1'))?.input;

      if (input) {
        input?.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (inputRef && inputRef.current.length > 0 && card) {
      const input = inputRef.current.find((ref) => ref.name === focusedInputName)?.input;

      if (input) {
        const [integer] = card.replace(/,/g, '').split('.');
        input.selectionStart = input.selectionEnd = card.length + Math.floor(Number(integer) / 3);
      }
    }
  }, [focusedInputName, card]);

  const handleChargeCard = (name: keyof FormData) => {
    setIsLoading((prev) => ({ ...prev, [name]: true }));

    const balanceToPay = handleTruncateToTwoDecimal(convertFormatNumberToNumber(card || 0));
    ticketTransactionPaymentRef.current = {
      type: TicketTransactionDTOTypePaymentEnum.MultipleChargeCard,
      amount: balanceToPay,
    };

    if (settings?.settingTerminalDevice?.deviceConnected === UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Pax) {
      onPaxConnect();
    } else {
      onConnect();
      onCloverPayment();
    }
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancel}
      modalProps={{
        footer: null,
        title: (
          <div className="m-l-r-24-n">
            <span className="font-size-20 font-weight-600 salon__appointment-calendar-blocktime-heading">
              {intl.formatMessage({ id: 'checkout.multipleCreditCards.header' })}
            </span>
          </div>
        ),
        destroyOnClose: true,
        centered: true,
        rootClassName: 'salon__multiple-card',
        width: 700,
        zIndex: 100000,
      }}
    >
      <RowWrap isGutter gutter={[16, 16]} isColumn isWrap isAutoFillRow styleFill="start">
        <ColWrap colProps={{ span: 24 }}>
          <span className="font-size-16 font-weight-400a">
            {intl.formatMessage({ id: 'checkout.multipleCreditCards.balanceToPay' })}&nbsp;
            <span className="font-weight-600">${formatNumberThousandWithDecimal(balanceToPay)}</span>
          </span>
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <FormWrap form={form} name="multipleCardsForm" layout="inline">
            <RowWrap isGutter gutter={[26, 26]} isColumn isWrap isAutoFillRow styleFill="start">
              {creditCardInformation.map((card) => (
                <ColWrap key={card.name} colProps={{ span: 24 }}>
                  <RowWrap isGutter gutter={[35, 35]} isColumn={false} isWrap isAutoFillRow styleFill="start">
                    <ColWrap>
                      <FormInputNumber
                        name={card.name}
                        label={intl.formatMessage({ id: card.titleId })}
                        prefix={<SvgDollarIcon />}
                        numericFormatProps={{
                          min: 0,
                          max: 999_999_999,
                          className: 'm-b-0',
                          inputMode: 'none',
                          placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.cashInAndCashOut.placeholder' }),
                          onFocus: () => {
                            setFocusedInputName(card.name);
                          },
                          onKeyDown: (event) => {
                            const value = event?.key;
                            if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                            else if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                            return event.preventDefault();
                          },
                          getInputRef: (el: HTMLInputElement) => inputRef.current.push({ name: card.name, input: el }),
                        }}
                        formItemProps={{
                          rules: [
                            { required: true, message: '' },
                            { whitespace: true, message: '' },
                          ],
                        }}
                        classNameFormInputNumber="m-b-0"
                        rootClassNameFormInputNumber="d-flex align-items-center gap-26 salon__multiple-card-form-input"
                      />
                    </ColWrap>
                    <ColWrap>
                      <ButtonStyled
                        content={
                          <span className="font-size-16 font-weight-700">
                            {intl.formatMessage({ id: 'checkout.multipleCreditCards.chargeCard' })}
                          </span>
                        }
                        isPrimary
                        heightButton={42}
                        buttonProps={{
                          className: 'width-167',
                          onClick: () => handleChargeCard(card.name),
                          loading: isLoading[card.name],
                          disabled:
                            Object.values(isLoading).some((item) => item) &&
                            Object.keys(isLoading).find((key) => isLoading[key as keyof FormData]) !== card.name,
                        }}
                      />
                    </ColWrap>
                  </RowWrap>
                </ColWrap>
              ))}
            </RowWrap>
          </FormWrap>
        </ColWrap>

        <ColWrap colProps={{ span: 24 }} className="m-t-12">
          <RowWrap isAutoFillRow isGutter gutter={[8, 8]} isWrap styleFill="between">
            <ColWrap colProps={{ span: 19 }}>
              <KeyboardWrapper
                inputName={focusedInputName}
                form={form}
                keyboardRef={keyboardRef}
                maxLength={9}
                keyboardProps={{
                  inputPattern: /^\d+$/,
                }}
                direction={KeyboardDirection.rtl}
              />
            </ColWrap>
            <ColWrap colProps={{ span: 5 }}>
              <ButtonStyled
                content={
                  <span className="salon__checkout-merchandise-modal-keyboard-button-text">
                    {intl.formatMessage({ id: 'checkout.ok' })}
                  </span>
                }
                buttonProps={{
                  className: 'width-122 height-328',
                  onClick: () => {},
                }}
                isPrimary
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </StyledModal>
  );
};

export default MultipleCreditCardsModal;
