import React from 'react';

export const SvgAppointmentArrowBackIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.0014 2.98493C16.5114 2.49493 15.7214 2.49493 15.2314 2.98493L6.92141 11.2949C6.53141 11.6849 6.53141 12.3149 6.92141 12.7049L15.2314 21.0149C15.7214 21.5049 16.5114 21.5049 17.0014 21.0149C17.4914 20.5249 17.4914 19.7349 17.0014 19.2449L9.76141 11.9949L17.0114 4.74493C17.4914 4.26493 17.4914 3.46493 17.0014 2.98493Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  </span>
);
