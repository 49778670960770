import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as clover from 'remote-pay-cloud';

interface CloverDeviceEvent {
  start?: clover.remotepay.CloverDeviceEvent;
  end?: clover.remotepay.CloverDeviceEvent;
}

interface CloverResponse {
  isLoading: boolean;
  saleResponse?: clover.remotepay.SaleResponse;
  refundResponse?: clover.remotepay.ManualRefundResponse;
  printResponse?: clover.remotepay.PrintJobStatusResponse;
  pairingCode?: string;
  isDisconnected: boolean;
  isDeviceReady: boolean;
  isPairingCodeSuccess: boolean;
  deviceError?: clover.remotepay.CloverDeviceErrorEvent;
  resetDeviceResponse?: clover.remotepay.ResetDeviceResponse;
  deviceEvent?: CloverDeviceEvent;
  cloverConnector?: clover.remotepay.ICloverConnector | null;
  isConnect: boolean;
}

const initialState: CloverResponse = {
  isLoading: false,
  saleResponse: undefined,
  refundResponse: undefined,
  printResponse: undefined,
  pairingCode: undefined,
  isDisconnected: false,
  isDeviceReady: false,
  deviceError: undefined,
  deviceEvent: undefined,
  resetDeviceResponse: undefined,
  cloverConnector: undefined,
  isConnect: false,
  isPairingCodeSuccess: false,
};

export const cloverSlice = createSlice({
  name: 'clover',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSaleResponse: (state, action: PayloadAction<clover.remotepay.SaleResponse | undefined>) => {
      state.saleResponse = action.payload;
      state.isDisconnected = false;
      state.isLoading = false;
      state.deviceError = undefined;
      state.deviceEvent = undefined;
      state.isPairingCodeSuccess = false;
      state.isConnect = false;
    },
    setRefundResponse: (state, action: PayloadAction<clover.remotepay.ManualRefundResponse | undefined>) => {
      state.refundResponse = action.payload;
      state.isDisconnected = false;
      state.isLoading = false;
      state.deviceError = undefined;
      state.deviceEvent = undefined;
      state.isPairingCodeSuccess = false;
      state.isConnect = false;
    },
    setPrintResponse: (state, action: PayloadAction<clover.remotepay.PrintJobStatusResponse | undefined>) => {
      state.printResponse = action.payload;
      state.isDisconnected = false;
      state.isLoading = false;
      state.deviceError = undefined;
      state.deviceEvent = undefined;
      state.isPairingCodeSuccess = false;
      state.isConnect = false;
    },
    setPairingCode: (state, action: PayloadAction<string | undefined>) => {
      state.pairingCode = action.payload;

      if (action.payload) {
        state.isLoading = true;
        state.isDisconnected = false;
      }
    },
    setIsDisconnected: (state, action: PayloadAction<boolean>) => {
      state.isDisconnected = action.payload;
    },
    setIsDeviceReady: (state, action: PayloadAction<boolean>) => {
      state.isDeviceReady = action.payload;
      state.isPairingCodeSuccess = false;
    },
    setDeviceError: (state, action: PayloadAction<clover.remotepay.CloverDeviceErrorEvent | undefined>) => {
      state.deviceError = action.payload;
      state.isLoading = false;

      // if (action.payload) {
      //   state.isDeviceReady = false;
      //   state.cloverConnector = null;
      // }
    },
    setCloverConnector: (state, action: PayloadAction<clover.remotepay.ICloverConnector | undefined | null>) => {
      state.cloverConnector = action.payload;
    },
    setIsConnect: (state, action: PayloadAction<boolean>) => {
      state.isConnect = action.payload;
    },
    setResetDeviceResponse: (state, action: PayloadAction<clover.remotepay.ResetDeviceResponse>) => {
      state.resetDeviceResponse = action.payload;
      state.isPairingCodeSuccess = false;
    },
    setDeviceActivity: (state, action: PayloadAction<CloverDeviceEvent>) => {
      state.deviceEvent = action.payload;
    },
    setIsPairingCodeSuccess: (state, action: PayloadAction<boolean>) => {
      state.isPairingCodeSuccess = action.payload;
      state.pairingCode = undefined;

      if (action.payload) {
        state.isLoading = true;
        state.isDisconnected = false;
      }
    },
    resetDefault: (state) => {
      state.isLoading = false;
      state.saleResponse = undefined;
      state.refundResponse = undefined;
      state.printResponse = undefined;
      state.pairingCode = undefined;
      state.isDisconnected = false;
      state.isDeviceReady = false;
      state.deviceError = undefined;
      state.deviceEvent = undefined;
      state.resetDeviceResponse = undefined;
      state.cloverConnector = undefined;
      state.isConnect = false;
      state.isPairingCodeSuccess = false;
    },
  },
});

export const {
  setIsLoading,
  setSaleResponse,
  setRefundResponse,
  setPrintResponse,
  setPairingCode,
  setIsDisconnected,
  setDeviceError,
  setCloverConnector,
  setIsDeviceReady,
  setIsConnect,
  setResetDeviceResponse,
  setDeviceActivity,
  setIsPairingCodeSuccess,
  resetDefault,
} = cloverSlice.actions;

export default cloverSlice.reducer;
