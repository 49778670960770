import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import { CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Calendar,
  CalendarProps,
  EventProps,
  ResourceHeaderProps,
  SlotInfo,
  SlotPropGetter,
  Views,
  dayjsLocalizer,
} from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { employeeApi, settingApi } from '../../../../../../apis';
import { Administrator, Customer, DeleteBlockTimeDTOTypeEnum, Employee } from '../../../../../../apis/client-axios';
import { SvgAppointmentDropDownIcon } from '../../../../../../components/@svg/SvgAppointmentDropDownIcon';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../../components/HandleShowNotiSuccess';
import StyledPopover from '../../../../../../components/StyledPopover';
import { StyledPopup } from '../../../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { RootState } from '../../../../../../store';
import {
  DATE_FORMAT_2,
  TIME_FORMAT,
  _fmFullDateTimeUTC,
  formatDateByFormatString,
  formatTimeHHMM,
  formatTimeZoneToDate,
  toDayjsTimeZone,
  toStartOfDaysByTimeZone,
} from '../../../../../../utils';
import { RoleCode, WorkingHours } from '../../../../employee/employeeConstants';
import Schedule from '../../../AppointmentCalendar/Schedule';
import Technician from '../../../AppointmentCalendar/Technician';
import TechniciansFilter from '../../../AppointmentCalendar/Technician/filter';
import { STATUSES } from '../../../index';
import {
  AppointmentBlockTime,
  AppointmentEvent,
  AppointmentResource,
  FILTER_TECHNICIAN,
  FilterProps,
  OpenModalTechnician,
  RESOURCE_UNASIGNED_KEY,
} from '../../../models';
import { IFormData } from '../../BlockTime';
import DeleteBlockTimeContent from '../../BlockTime/deleteContent';
import { IFromState, IFromStateInput } from '../../index';
import './style.scss';
import { QUERY_SETTING } from '../../../../../../utils/constant';
export interface Props extends FilterProps {
  events: AppointmentEvent[];
  resources: AppointmentResource[];
  activeTab: number;
  tabData: IFromState[];
  setTabData: (data: IFromStateInput) => void;
  setSlotInfor: (slot: SlotInfo) => void;
  onRefetch: () => void;
}
dayjs.extend(customParseFormat);
// const DnDCalendar = withDragAndDrop<AppointmentEvent, AppointmentResource>(Calendar);

const AppointmentCalendar: FC<Props> = (props) => {
  const { filter, onChangeFilter, setSlotInfor, events, resources, onRefetch } = props;
  const intl = useIntl();
  const filterDate = new Date(filter?.date || new Date());

  const authUserString = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
  const authUser: Administrator | Customer | Employee = authUserString ? JSON.parse(authUserString as string) : null;

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [openModalTechnician, setOpenModalTechnician] = useState<OpenModalTechnician>({
    filter: false,
  });
  const [draggedEvent, setDraggedEvent] = useState<AppointmentEvent>();
  const [blockTime, setBlockTime] = useState<AppointmentBlockTime>({
    technicians: [],
  });

  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);
  const [form] = Form.useForm<IFormData>();

  const deleteBlockTimeMutation = useMutation(
    (payload: { id: number; type: DeleteBlockTimeDTOTypeEnum }) =>
      employeeApi.employeeControllerDeleteBlockTime(payload.id, {
        type: payload.type,
        timeDeleted: _fmFullDateTimeUTC(filter.date),
      }),
    {
      onSuccess: () => {
        onRefetch();
        form.resetFields();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.event.blocktime.delete.success' }) });
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
      onSettled: () => {
        setBlockTime({ technicians: [] });
      },
    }
  );

  const filterResources = useMemo(() => {
    if (!filter.technician || filter.technician === FILTER_TECHNICIAN.ALL) return resources;
    else if (filter.technician === FILTER_TECHNICIAN.AT_LEAST_ONE) {
      const resourceIdMap = _.uniq(events.filter((event) => !event.isBlockTime).map((event) => event.resourceId));

      return resources.filter(
        (resource) => resourceIdMap.includes(resource.resourceId) || resource.resourceId === RESOURCE_UNASIGNED_KEY
      );
    } else {
      return resources.filter(
        (resource) => resource.resourceId === filter.technician || resource.resourceId === RESOURCE_UNASIGNED_KEY
      );
    }
  }, [resources, filter.technician, events]);

  // useEffect(() => {
  //   if (
  //     openModalTechnician.filter &&
  //     (blockTime.technicians.length > 0 || blockTime.blockTimeDeleted || openModifiedModal)
  //   ) {
  //     setOpenModalTechnician((prev) => ({ ...prev, filter: false }));
  //   }
  // }, [openModalTechnician.filter, blockTime.technicians, blockTime.blockTimeDeleted, openModifiedModal]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleEnlargeScrollbar = (e: MouseEvent) => {
    const element = document.querySelector('.rbc-time-content');

    if (element) {
      const distanceX = (element as HTMLElement).offsetLeft + (element as HTMLElement).offsetWidth - e.pageX;
      distanceX < -105 && distanceX > -120
        ? element.classList.add('more-width')
        : element.classList.remove('more-width');

      const distanceY = (element as HTMLElement).offsetTop + (element as HTMLElement).offsetHeight - e.pageY;
      distanceY < 20 && distanceY > -15
        ? element.classList.add('more-height')
        : element.classList.remove('more-height');
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleEnlargeScrollbar);
    return () => {
      document.removeEventListener('mousemove', handleEnlargeScrollbar);
    };
  }, []);

  const { formats, view, localizer, timeslots, step } = useMemo<CalendarProps<AppointmentEvent, AppointmentResource>>(
    () => ({
      localizer: dayjsLocalizer(dayjs),
      formats: {
        timeGutterFormat: (date: Date, culture?: string) => dayjsLocalizer(dayjs).format(date, 'hha', culture),
        eventTimeRangeFormat: () => '',
      },
      view: Views.DAY,
      timeslots: 4,
      step: 15,
    }),
    []
  );

  const handleCheckBlockTime = (dateToCheck: Date, resources: AppointmentResource[], resourceId?: string | number) => {
    const day = dayjs(filter?.date).day();
    const dayIndex = day > 0 ? day - 1 : 6;

    const findTechnician = resources.find((resource) => resource.id === resourceId);

    if (findTechnician || resourceId === RESOURCE_UNASIGNED_KEY) {
      let workingTime: WorkingHours | undefined = findTechnician?.resource?.employeeWorkingHours?.isSameSalon
        ? (salonInformation?.workingTime as WorkingHours | undefined)
        : (findTechnician?.resource?.employeeWorkingHours?.workingTime as WorkingHours | undefined);

      if (resourceId === RESOURCE_UNASIGNED_KEY) {
        workingTime = salonInformation?.workingTime as WorkingHours | undefined;
      }

      if (workingTime) {
        const findWorkingTime = workingTime?.[Object.keys(workingTime)?.[dayIndex] as keyof WorkingHours];

        const dateYMD = formatDateByFormatString(DATE_FORMAT_2, filter.date ?? null);

        if (!findWorkingTime.from && !findWorkingTime.to) return true;

        const from = findWorkingTime.from;
        const to = dayjs(findWorkingTime.to, TIME_FORMAT).subtract(15, 'minutes').format(TIME_FORMAT);

        const formatDate = formatTimeHHMM(dateToCheck);

        return Boolean(
          dayjs(`${dateYMD} ${formatDate}`).isBefore(`${dateYMD} ${from}`) ||
            dayjs(`${dateYMD} ${formatDate}`).isAfter(`${dateYMD} ${to}`)
        );
      }

      return false;
    }

    return false;
  };

  const renderResourceHeader = (props: ResourceHeaderProps<AppointmentResource>) => {
    return (
      props.resource && (
        <Technician
          filter={filter}
          onChangeFilter={onChangeFilter}
          technician={props.resource}
          onOpenDetailDay={(technician) => setOpenModalTechnician({ filter: false, detailDay: technician })}
        />
      )
    );
  };

  const renderEvent = (props: EventProps<AppointmentEvent>) => <Schedule eventProps={props} />;

  const renderEventPropGetter = (event: AppointmentEvent): { className?: string; style?: CSSProperties } => {
    const diff = dayjs(event?.end).diff(event?.start, 'minutes');

    const filteredEvents = events.filter((e) => e.resourceId === event.resourceId);

    const isTimeOverlap = filteredEvents.some(
      (element, index) =>
        element.id !== event.id &&
        ((dayjs(event.start) <= dayjs(element.end) && dayjs(event.start) >= dayjs(element.start)) ||
          (dayjs(event.end) <= dayjs(element.end) && dayjs(event.end) >= dayjs(element.start)) ||
          (dayjs(event.start) <= dayjs(element.start) && dayjs(event.end) >= dayjs(element.end))) &&
        index < filteredEvents.findIndex((e) => e.id === event.id)
    );

    const borderColor = !!isTimeOverlap
      ? '#00297A'
      : !event.isBlockTime
      ? STATUSES.find((status) => status.key === event.resource?.status)?.color
      : '#A0A2A3';

    return {
      style: {
        backgroundColor: !event.isBlockTime
          ? STATUSES.find((status) => status.key === event.resource?.status)?.color
          : '#A0A2A3',
        border: `1px solid ${borderColor}`,
        padding: diff < 10 ? '0 11px' : '8px 11px',
        minHeight: diff < 10 ? 20 : 32,
        zIndex: draggedEvent?.id === event.id ? 6 : 4,
      },
    };
  };

  const renderSlotPropGetter = useCallback<SlotPropGetter>(
    (dateSlot: Date, resourceId?: string | number): HTMLAttributes<HTMLDivElement> => {
      let className = '';

      const convertDate = toDayjsTimeZone(currentDate);

      if (dateSlot.getHours() === convertDate.hour() && convertDate.minute() === 0) {
        className = `${className} rbc-time-slot-custom`;
      }

      const isBlockTime = handleCheckBlockTime(dateSlot, resources, resourceId);

      if (isBlockTime) {
        className = `${className} rbc-time-slot-blocktime`;
      }

      return {
        className,
      };
    },
    [filter?.date, resources, currentDate, salonInformation?.workingTime]
  );

  return (
    <div className="salon__appointment-calendar ">
      <Calendar
        localizer={localizer}
        defaultView={view}
        formats={formats}
        toolbar={false}
        date={new Date(toStartOfDaysByTimeZone(filter.date ?? dayjs(), DATE_FORMAT_2))}
        min={new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate(), 8)}
        scrollToTime={new Date(toStartOfDaysByTimeZone(filter.date ?? dayjs(), DATE_FORMAT_2))}
        resources={filterResources}
        resourceIdAccessor={(resource: AppointmentResource) => resource.resourceId}
        resourceTitleAccessor={(resource: AppointmentResource) => resource.resourceTitle}
        events={events}
        timeslots={timeslots}
        step={step}
        className={currentDate.getMinutes() < 10 ? 'rbc-calendar-custom-indicator' : ''}
        eventPropGetter={renderEventPropGetter}
        slotPropGetter={renderSlotPropGetter}
        components={{
          resourceHeader: renderResourceHeader,
          event: renderEvent,
        }}
        selectable
        onSelectSlot={(e) => setSlotInfor(e)}
        getNow={() => formatTimeZoneToDate()}
      />
      {!(authUser?.user?.roles?.[0]?.code === RoleCode.Technician) && (
        <StyledPopover
          open={openModalTechnician.filter}
          popoverProps={{
            content: (
              <div className="m-b-79">
                <TechniciansFilter
                  filter={filter}
                  onChangeFilter={onChangeFilter}
                  events={events}
                  onCloseFilter={() => setOpenModalTechnician((prev) => ({ ...prev, filter: false }))}
                />
              </div>
            ),
            placement: 'topLeft',
            trigger: 'click',
            arrow: false,
            overlayClassName: 'salon__appointment-calendar-filter-popover-sub',
          }}
        >
          <ButtonStyled
            isPrimary
            content={
              <p className="font-size-16 font-weight-600 salon__appointment-calendar-filter-button-text">
                {intl.formatMessage({ id: 'appointment.button.all' })}
                <SvgAppointmentDropDownIcon />
              </p>
            }
            buttonProps={{
              onClick: () => setOpenModalTechnician((prev) => ({ ...prev, filter: !prev.filter })),
              className: 'salon__appointment-calendar-filter-button-sub height-67',
            }}
          />

          {blockTime.blockTimeDeleted && (
            <StyledPopup
              isOpen={Boolean(blockTime.blockTimeDeleted)}
              content={
                <DeleteBlockTimeContent
                  repeat={form.getFieldValue('repeat')}
                  onChangeBlockTime={(type) =>
                    setBlockTime((prev) => ({ ...prev, blockTimeDeleted: { ...prev.blockTimeDeleted, type } }))
                  }
                />
              }
              onCancel={() => setBlockTime((prev) => ({ ...prev, blockTimeDeleted: undefined }))}
              onOk={() =>
                deleteBlockTimeMutation.mutate({
                  id: blockTime.blockTimeDeleted?.id || 0,
                  type: blockTime.blockTimeDeleted?.type || DeleteBlockTimeDTOTypeEnum.Only,
                })
              }
              buttonPropsOK={{
                loading: deleteBlockTimeMutation.isLoading,
              }}
            />
          )}

          {/* <TechnicianDetailDay
            open={Boolean(openModalTechnician.detailDay)}
            technician={openModalTechnician.detailDay}
            onCancel={() => setOpenModalTechnician((prev) => ({ ...prev, detailDay: undefined }))}
            onOpenBlockTime={handleOpenBlockTime}
          /> */}
        </StyledPopover>
      )}
    </div>
  );
};

export default AppointmentCalendar;
