import React from 'react';

export const SvgAppointmentCallerIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9873 3.01318H19.9873C20.5373 3.01318 20.9873 3.46318 20.9873 4.01318V9.01318C20.9873 9.56318 20.5373 10.0132 19.9873 10.0132H14.9873L11.9873 13.0132V4.01318C11.9873 3.46318 12.4373 3.01318 12.9873 3.01318ZM16.6773 14.9832L19.2173 15.2732C20.2273 15.3932 20.9873 16.2432 20.9773 17.2532V18.9832C20.9773 20.1132 20.0373 21.0532 18.9073 20.9832C10.3773 20.4532 3.54735 13.6332 3.01735 5.09318C2.94735 3.96318 3.88735 3.02318 5.01735 3.02318H6.74735C7.76735 3.02318 8.61735 3.78318 8.73735 4.79318L9.02735 7.31318C9.09735 7.92318 8.88735 8.52318 8.45735 8.95318L6.60735 10.8032C8.04735 13.6432 10.3673 15.9532 13.1973 17.3932L15.0373 15.5532C15.4673 15.1232 16.0673 14.9132 16.6773 14.9832Z"
        fill="#00297A"
      />
    </svg>
  </span>
);
