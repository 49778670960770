import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { ReactNode, useEffect } from 'react';
import { SvgAddMoreDataIconSmall } from '../@svg/SvgAddMoreDataIconSmall';
import { StyledPagination } from '../StyledPagination';
import './tableWrap.scss';
export interface IScrollTable {
  x?: number | string;
  y?: number | string;
}
export interface ITableWrapProps<T> {
  data?: T[];
  isLoading?: boolean;
  page?: number;
  size?: number;
  total?: number;
  children?: any;
  rowKey?: string;
  isHidePagination?: boolean;
  isScroll?: boolean;
  isPointer?: boolean;
  scrollValue?: IScrollTable;
  setSize: (size: number) => void;
  setPage?: (page: number) => void;
  columns?: ColumnType<T>[];
  showSizeChanger?: boolean;
  onRowClick?: (record: T) => void;
  isRowAvatar?: boolean;
  rootClassName?: string;
  rowClassName?: string | ((record: T, index?: number, indent?: number) => string);
  noDataContent?: ReactNode;
}

function TableWrap<T extends object>(props: ITableWrapProps<T>) {
  const handleEnlargeScrollbar = (e: MouseEvent) => {
    const element = document.querySelector('.ant-table-body');
    const elementHeight = document.querySelector('.ant-table-content');

    if (element) {
      const distanceX = (element as HTMLElement).offsetLeft + (element as HTMLElement).offsetWidth - e.pageX;
      distanceX < -120 && distanceX > -150
        ? element.classList.add('more-width')
        : element.classList.remove('more-width');
    }
    if (elementHeight) {
      const distanceY =
        (elementHeight as HTMLElement).offsetTop + (elementHeight as HTMLElement).offsetHeight - e.pageY;
      distanceY < 290 && distanceY > 230
        ? elementHeight.classList.add('more-height')
        : elementHeight.classList.remove('more-height');
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleEnlargeScrollbar);
    return () => {
      document.removeEventListener('mousemove', handleEnlargeScrollbar);
    };
  }, []);
  return (
    <div className="salon__custom-table">
      <Table
        columns={props.columns}
        dataSource={props.data}
        loading={props.isLoading}
        pagination={false}
        rowKey={props.rowKey || 'id'}
        scroll={props.isScroll ? props.scrollValue : {}}
        className={`${props.isPointer ? 'salon__custom-table-pointer' : ''} ${
          props.isRowAvatar ? 'salon__custom-table-avatar' : ''
        }`}
        onRow={(record, index) => {
          return {
            onClick: () => {
              if (props.onRowClick) {
                props.onRowClick(record);
              }
            },
          };
        }}
        rootClassName={props?.rootClassName}
        rowClassName={props?.rowClassName}
        locale={{
          emptyText: (
            <div className="d-flex flex-column align-items-center justify-content-center m-t-40">
              <SvgAddMoreDataIconSmall />
              <span className="salon__setting-add-more-content">{props.noDataContent}</span>
            </div>
          ),
        }}
      >
        {props.children}
      </Table>
      {!props.isHidePagination && (
        <div className="salon__table-warp-pagination">
          <StyledPagination
            page={props.page ?? 1}
            size={props.size ?? 10}
            totalItem={props.total || 10}
            setPerPage={props.setSize}
            paginationProps={{ onChange: (page) => props.setPage && props.setPage(page) }}
          />
        </div>
      )}
    </div>
  );
}

export default TableWrap;
