import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../../../../apis';
import {
  SalonSetting,
  SettingTipSplitTipByEnum,
  TipForTechnicianDTO,
  TipForTechniciansDTO,
} from '../../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInput } from '../../../../../../../components/Form/FormInput';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../../components/FormWrap';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../../../../components/RowWrap';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import { formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { ITechnicianItem, ITipInformation } from '../../../../index';
import KeyboardWrapper, { KeyboardDirection } from '../../../../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface IProps {
  setOpenModal: (state: boolean) => void;
  tipInformation: ITipInformation | undefined;
  settings?: SalonSetting;
  open: boolean;
}

interface TechnicianTip {
  id: number;
  tip?: number;
}

interface FormData {
  technicianTips?: Array<ITechnicianItem & { tip: string }>;
  customerTipLeft?: number | string;
}

enum InputNameEnum {
  TechnicianTips = 'technicianTips',
  CustomerTipLeft = 'customerTipLeft',
}

const n = (key: keyof FormData) => key;
const nTip = (key: keyof TechnicianTip) => key;

export const TipsChargeCard: React.FC<IProps> = (props) => {
  const { setOpenModal, tipInformation, settings, open } = props;
  const intl = useIntl();
  const [form] = Form.useForm<FormData>();
  const technicianTips: Array<ITechnicianItem & { tip: string }> | undefined = Form.useWatch(
    n('technicianTips'),
    form
  ) as Array<ITechnicianItem & { tip: string }> | undefined;

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const customerTipLeft: number | string = Form.useWatch(n('customerTipLeft'), form) as number | string;

  const [focusedInputName, setFocusedInputName] = useState<string | NamePath<any>>();
  const saveTiveForTechnicianMutation = useMutation(
    (payload: TipForTechniciansDTO) => ticketApi.ticketControllerTipForTechnician(payload),
    {
      onSuccess: () => {
        NotificationSuccess({
          contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.tipsChargeCard.success' }),
        });
        setOpenModal(false);
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response.data.message });
      },
    }
  );

  const onClickOK = (value: FormData) => {
    if (!value.technicianTips) {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
      return;
    }

    const totalTechnicianTips = value.technicianTips.reduce((prev, curr) => (prev += Number(curr?.tip || 0)), 0);

    if (Number(totalTechnicianTips) > Number(customerTipLeft)) {
      NotificationError({
        contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.tipsChargeCard.error.totalTip' }),
      });
      return;
    }

    const technicianTurns: TipForTechnicianDTO[] = value.technicianTips.map((technician) => ({
      technicianId: Number(technician.id),
      ticketId: Number(tipInformation?.ticketId),
      tips: Number(technician.tip),
    }));

    saveTiveForTechnicianMutation.mutate({ technicianTurns });
  };

  // useEffect(() => {
  //   if (open && inputsRef.current.length > 0) {
  //     inputsRef?.current[0]?.focus();
  //   }
  // }, [open, technicianTips]);

  useEffect(() => {
    const customerTipLeft = Number(tipInformation?.tip);
    const technicianTips = (tipInformation?.technicians ?? []).map((technician) => ({ ...technician, tip: undefined }));
    form.setFieldValue(n('customerTipLeft'), formatNumberThousandWithDecimal(customerTipLeft));
    form.setFieldValue(n('technicianTips'), technicianTips);

    handleFillTip();
  }, [tipInformation, open]);

  useEffect(() => {
    if (Array.isArray(focusedInputName)) {
      const [index] = focusedInputName;
      const findInput = inputsRef.current?.[index];
      const tips = technicianTips?.[index]?.tip;

      if (findInput && tips) {
        const [integer] = tips.replace(/,/g, '').split('.');
        findInput.selectionStart = findInput.selectionEnd = tips.length + Math.floor(Number(integer) / 3);
      }
    }
  }, [focusedInputName, technicianTips]);

  const handleFillTip = () => {
    if (Array.isArray(technicianTips)) {
      if (technicianTips.length > 1 && technicianTips.every((technician) => !technician.tip)) {
        const tipSetting = settings?.settingTip;
        const copyTechnicianTips = [...technicianTips];

        if (tipSetting?.splitTipBy === SettingTipSplitTipByEnum.AmountOfMoney) {
          const totalServiceAmounts = tipInformation?.technicianTurns
            ?.flatMap((technician) => technician.serviceTurns)
            .reduce((prev, curr) => {
              return prev + Number(curr.price);
            }, 0);

          copyTechnicianTips.forEach((technician) => {
            const findTechnicianTurn = tipInformation?.technicianTurns?.find(
              (technicianTurn) => technicianTurn.technicianId === technician.id
            );

            const serviceAmount = findTechnicianTurn?.serviceTurns.reduce((prev, curr) => {
              return prev + Number(curr.price);
            }, 0);

            technician.tip = formatNumberThousandWithDecimal(
              (Number(serviceAmount) / Number(totalServiceAmounts)) * Number(customerTipLeft)
            );
          });
        } else if (tipSetting?.splitTipBy === SettingTipSplitTipByEnum.Equal) {
          copyTechnicianTips.forEach((technician) => {
            technician.tip = formatNumberThousandWithDecimal(Number(customerTipLeft) / copyTechnicianTips.length);
          });
        }

        form.setFieldValue(n('technicianTips'), copyTechnicianTips);
      } else {
        const isHaveInputNoTips = technicianTips.filter((technician) => !technician?.tip);

        if (isHaveInputNoTips.length === 1) {
          const totalTips =
            technicianTips.length > 1
              ? technicianTips.reduce((prev, curr) => {
                  return prev + Number(curr?.tip || 0);
                }, 0)
              : 0;

          const custormerTips = Number(customerTipLeft.toString().replace(/,/g, ''));

          if (totalTips <= custormerTips) {
            const copyTechnicianTips = [...technicianTips];
            const filterTechnicianHaveNoTip = copyTechnicianTips.filter((technician) => !technician.tip);

            if (filterTechnicianHaveNoTip.length === 1) {
              const findIndex = copyTechnicianTips.findIndex((technician) => !technician.tip);

              if (findIndex > -1) {
                copyTechnicianTips[findIndex].tip = formatNumberThousandWithDecimal(custormerTips - totalTips);
              }
            }

            form.setFieldValue(n('technicianTips'), copyTechnicianTips);
          }
        }
      }
    }
  };

  return (
    <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <p className="font-size-16 font-weight-500 m-0">
            {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
            <span className="font-weight-700">
              $0
              {/* {formatNumberThousandWithDecimal(
                paymentInformation?.ticketTransaction?.find((ticket) => ticket.money && ticket.tipMoney)?.money || 0
              )} */}
            </span>
          </p>
          <p className="font-size-16 font-weight-500 m-b-0 m-t-21">
            {intl.formatMessage({ id: 'checkout.payAndComplete.tips.subTitle' })}
          </p>
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <FormInputNumber
            name={n('customerTipLeft')}
            prefix={<SvgDollarIcon />}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
            numericFormatProps={{
              placeholder: '0.00',
              className: 'm-b-0',
              onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
              disabled: true,
              onKeyDown: (event) => {
                const value = event?.key;
                if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                if (value === 'Enter') {
                  form.submit();
                }
                if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                return event.preventDefault();
              },
            }}
            formItemProps={{
              className: 'm-b-0 w-full',
            }}
          />
        </ColWrap>

        {/* tips for technicians */}
        <ColWrap colProps={{ span: 24 }}>
          <Form.List name={n('technicianTips')}>
            {(technicianTips) => (
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
                {technicianTips.map((technician, index) => (
                  <ColWrap colProps={{ span: technicianTips.length === 1 ? 24 : 12 }} key={technician.key}>
                    <FormInput
                      name={[technician.name, nTip('tip')]}
                      formItemProps={{
                        className: 'd-none',
                        rules: [{ required: true, message: '' }],
                      }}
                    />
                    <FormInputNumber
                      name={[technician.name, nTip('tip')]}
                      prefix={<SvgDollarIcon />}
                      label={intl.formatMessage(
                        { id: 'checkout.payAndComplete.title.tipFor' },
                        { name: tipInformation?.technicians?.[index]?.name || '' }
                      )}
                      numericFormatProps={{
                        inputMode: 'none',
                        placeholder: '0.00',
                        className: 'm-b-0',
                        max: 999_999_999,
                        onFocus: () => {
                          setFocusedInputName([technician.name, nTip('tip')]);
                        },
                        onChange: (e) => {
                          const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
                          form.setFieldValue([technician.name, nTip('tip')], currentValue);
                        },
                        disabled:
                          !customerTipLeft ||
                          customerTipLeft === '0.00' ||
                          technicianTips?.length === 1 ||
                          !settings?.settingTip?.allowManuallySplittingTips,
                        onKeyDown: (event) => {
                          const value = event?.key;
                          if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                          if (value === 'Enter') {
                            form.submit();
                          }
                          if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                          return event.preventDefault();
                        },
                        getInputRef: (el: HTMLInputElement) => (inputsRef.current[technician.name] = el),
                        onBlur: handleFillTip,
                      }}
                      formItemProps={{
                        className: 'm-b-0 w-full',
                        rules: [{ required: true, message: '' }],
                      }}
                    />
                  </ColWrap>
                ))}
              </RowWrap>
            )}
          </Form.List>
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
            {/* numeric keyboard */}
            <ColWrap colProps={{ span: 18 }}>
              <KeyboardWrapper
                inputName={focusedInputName}
                form={form}
                keyboardRef={keyboardRef}
                maxLength={9}
                keyboardProps={{
                  inputPattern: /^\d+$/,
                }}
                direction={KeyboardDirection.rtl}
                disabledKeyboard={technicianTips?.length === 1}
              />
            </ColWrap>

            {/* ok button */}
            <ColWrap colProps={{ span: 6 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                  htmlType: 'submit',
                }}
                content={intl.formatMessage({ id: 'common.OK' })}
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </FormWrap>
  );
};
