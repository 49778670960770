import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Dispatch, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../apis';
import { SettingCheckout, SettingTip, Ticket } from '../../../../apis/client-axios';
import ColWrap from '../../../../components/ColWrap';
import RowWrap from '../../../../components/RowWrap';
import { StyledDatePicker } from '../../../../components/StyledDatePicker';
import { StyledModal } from '../../../../components/StyledModal';
import { DATE_FORMAT, DATE_FORMAT_2, formatDateTimeZoneByFormatString } from '../../../../utils';
import { QUERY_TRANSACTIONS_CHECKOUT } from '../../../../utils/constant';
import { IOpenModalCheckOut, PrintType } from '../BillStart';
import PrintReceipt from '../BillStart/PrintReceipt';
import TransactionsCollapse from './components/TransactionsCollapse';
import './transactionsStyle.scss';
import { ITechnicianItem } from '..';
import { FormSelect } from '../../../../components/Form/FormSelect';
import { Checkbox, Form, Select } from 'antd';
import { toNumber } from 'lodash';
import { SvgArrowDownSelect } from '../../../../components/@svg/SvgArrowDownSelect';
import { BaseOptionType } from 'antd/es/select';
import FormWrap from '../../../../components/FormWrap';

interface Props {
  open: boolean;
  onCancelModal: () => void;
  settingCheckout?: SettingCheckout;
  onPrintTicket: (type: PrintType, transactionTicket?: Ticket, isNotPrintBill?: boolean) => void;
  isLoading: Record<PrintType, boolean>;
  openModal: IOpenModalCheckOut;
  onChangeOpenModal: Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  settingTip?: SettingTip;
  listTechnician: ITechnicianItem[];
  setIsLoading: (value: Record<PrintType, boolean>) => void;
}

const Transactions: FC<Props> = (props) => {
  const {
    settingCheckout,
    open,
    onCancelModal,
    onPrintTicket,
    isLoading,
    openModal,
    onChangeOpenModal,
    settingTip,
    listTechnician,
    setIsLoading,
  } = props;

  const intl = useIntl();
  const [formTechnician] = Form.useForm();

  const [dateSearch, setDateSearch] = useState<string>(formatDateTimeZoneByFormatString(DATE_FORMAT_2));
  const [ticketToPrint, setTicketToPrint] = useState<Ticket>();

  const technicianIds: number[] = Form.useWatch(['technicianIds'], formTechnician) ?? [];

  const { data: dataTicket } = useQuery({
    queryKey: [QUERY_TRANSACTIONS_CHECKOUT, dateSearch, technicianIds],
    enabled: true,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetTicketCompleted({ date: dateSearch, technicianIds: technicianIds }),
  });

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            <p className="m-b-4">{intl.formatMessage({ id: 'checkout.transactions' })}</p>
            <p className="font-size-14 font-weight-400 color-78797A m-0">
              {intl.formatMessage({ id: 'checkout.transactions.subTitle' })}
            </p>
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal salon__checkout__transactions',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        name="checkoutTransactions"
        layout="vertical"
        form={formTechnician}
        preserve={false}
        className="w-100 "
        autoComplete="off"
      >
        <RowWrap isAutoFillRow={false} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 12 }}>
            <StyledDatePicker
              name="date"
              label={intl.formatMessage({ id: 'checkout.transactions.calendar' })}
              datePickerProps={{
                format: DATE_FORMAT,
                onChange: (date) => {
                  const formattedDate = dayjs(date).format(DATE_FORMAT_2);
                  setDateSearch(formattedDate);
                },
                value: dateSearch ? dayjs(dateSearch, DATE_FORMAT_2) : undefined,
                allowClear: false,
                showToday: false,
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 12 }}>
            {/* <Select
          allowClear
          suffixIcon={<SvgArrowDownSelect />}
          placeholder= {intl.formatMessage({ id: 'setting.services.placeholder.salon' })}
          filterOption={filterOption}
          showSearch={false}
          mode={'multiple'}
          maxTagCount={'responsive'}
          value: technicianIds,
          popupClassName={`salon__form-select-popup-select`}
        /> */}
            <FormSelect
              name={'technicianIds'}
              label={'Technician'}
              selectProps={{
                placeholder: 'Select technician',
                showSearch: false,
                mode: 'multiple',
                maxTagCount: 'responsive',
                className: 'salon__setting-select-create',
                value: [...(technicianIds ?? [])],
                options: [
                  {
                    value: -1,
                    label: (
                      <div className="d-flex gap-10">
                        <Checkbox checked={technicianIds?.length === listTechnician.length} />
                        {intl.formatMessage({ id: 'setting.services.selectAll' })}
                      </div>
                    ),
                  },
                  ...listTechnician?.map((item) => ({
                    value: toNumber(item.id),
                    label: (
                      <div className="d-flex gap-10">
                        <Checkbox checked={technicianIds?.includes(toNumber(item.id))} />
                        {item.name}
                      </div>
                    ),
                  })),
                ],
                onSelect: (value) => {
                  if (value === -1) {
                    if (technicianIds?.length === listTechnician.length) {
                      formTechnician.setFieldValue(['technicianIds'], []);
                    } else {
                      formTechnician.setFieldValue(['technicianIds'], [...listTechnician?.map((item) => item.id)]);
                    }
                  } else if (technicianIds && technicianIds.length > 0 && technicianIds[0] === -1) {
                    formTechnician.setFieldValue(['technicianIds'], [value]);
                  }
                },
              }}
              formItemProps={{
                className: 'slelect-technician',
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={false} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="between">
              {dataTicket?.data?.map((ticket) => (
                <ColWrap colProps={{ span: 24 }} key={ticket.transactionOrder}>
                  <TransactionsCollapse
                    ticket={ticket}
                    onCancelModal={onCancelModal}
                    settingCheckout={settingCheckout}
                    isLoading={isLoading}
                    orderNumber={ticket.transactionOrder}
                    onChangeOpenModal={onChangeOpenModal}
                    onChangeTicketToPrint={(ticket) => setTicketToPrint(ticket)}
                    settingTip={settingTip}
                  />
                </ColWrap>
              ))}
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>

      {openModal.openPrintReceipt && (
        <PrintReceipt
          open={openModal.openPrintReceipt}
          onCancel={() => onChangeOpenModal((prev) => ({ ...prev, openPrintReceipt: false }))}
          isLoading={isLoading}
          onPrintTicket={onPrintTicket}
          ticket={ticketToPrint}
          setIsLoading={setIsLoading}
        />
      )}
    </StyledModal>
  );
};

export default Transactions;
