import { Form } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Customer,
  Employee,
  Promotion,
  ServiceItems,
  ServiceTurnDTO,
  TechnicianTurnDTO,
  Ticket,
  TicketDTO,
  TicketGiftCard,
  TicketTransactionDTO,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../apis/client-axios';
import AvatarDefault from '../../../../assets/images/appointment/avatar-default.png';
import ColWrap from '../../../../components/ColWrap';
import { FormInputSearch } from '../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import {
  IAppointmentBill,
  IGiftCard,
  IListIdDisable,
  IListIdTotalPrice,
  IMerchandise,
  IMerchandiseCombine,
  IServiceCharge,
  IServicesItem,
  ITechnicianItem,
  PaymentInformation,
} from '../index';
import './style.scss';
import TicketCard from './ticket';
import KeyboardWrapper from '../../../../components/KeyboardWrapper';
import { KeyboardReactInterface } from 'react-simple-keyboard';

export interface Props {
  open: boolean;
  listTicketPending: Ticket[] | undefined;
  appointmentBill: IAppointmentBill;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  listPromotion: Promotion | undefined;
  listTotal: IListIdTotalPrice;
  listDataServices: ServiceItems[];
  setSearchPending: (searchPending: string) => void;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
  setServiceCharge: (serviceCharge: IServiceCharge) => void;
  setTaxPercent: (taxPercent: number) => void;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  setListIdDisable: (listIdDisable: IListIdDisable) => void;
  setServiceApply: (serviceApply: number[]) => void;
  setIdTechnician: (idTechnician: number | string) => void;
  setListSkill: (listSkill: number[]) => void;
  setPaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
}

export interface ITicketDTO extends TicketDTO {
  id: number;
  customer?: Customer;
  serviceTurns?: ServiceTurnDTO[];
  technician?: IEmployeeData;
  ticketGiftCard?: TicketGiftCard[];
}

export interface IEmployeeData extends Employee {
  serviceTurns?: IServicesItem[];
}

export interface ITechnicianTurnData extends TechnicianTurnDTO {
  technician?: IEmployeeData;
}

export interface IServiceTurnDTO extends ServiceTurnDTO {
  service?: IServicesItem;
  id?: number;
}

export interface ITicketTransactionDTO extends TicketTransactionDTO {
  id?: number;
}

export interface SearchPendingDTO {
  fullTextSearch: string;
}

function PendingTicket(props: Props) {
  const {
    open,
    listTicketPending,
    appointmentBill,
    listPromotion,
    discountsAndRewardsApplied,
    listTotal,
    listDataServices,
    setSearchPending,
    onCancelModal,
    setAppointmentBill,
    handleGetTotal,
    setServiceCharge,
    setTaxPercent,
    setDiscountsAndRewardsApplied,
    setListIdDisable,
    setIdTechnician,
    setListSkill,
    setPaymentInformation,
    setListTotal,
    setServiceApply,
  } = props;

  const intl = useIntl();
  const [form] = Form.useForm();
  const fullSearchTextValue = Form.useWatch('fullTextSearch', form);
  const timeout: any = useRef(null);

  const [listCombine, setListCombine] = useState<ITicketDTO[]>([]);
  const [inputName, setInputName] = useState<string | undefined>('fullTextSearch');
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const handleCheckIdItems = (listIdServices: number[], listIdServiceDiscount: number[]) => {
    for (let item of listIdServices) {
      if (listIdServiceDiscount && listIdServiceDiscount.includes(item)) {
        return true;
      }
    }
    return false;
  };
  const n = (key: keyof SearchPendingDTO) => {
    return key;
  };
  const onSubmit = (isDoubleClick?: boolean, dataDoubleClick?: ITicketDTO[]) => {
    const dataValidate = dataDoubleClick ?? listCombine;
    if (dataValidate.length === 1 && !isDoubleClick) {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.pending.ticket.error' }) });
    } else {
      if (!!dataValidate.length) {
        const groupedTechs: any = {};
        const serviceIds: number[] = [];
        const merchandiseIds: number[] = [];
        const serviceDisableIds: number[] = [];
        const ticketGiftCards: IGiftCard[] = [];
        const listTicketMerchandise: IMerchandise[] = [];
        const appointmentIds: number[] = [];
        let totalDeposit: number = 0;

        // merchandise
        let isDefaultDiscountMerchandiseBirthday: boolean = false;
        let isDefaultDiscountMerchandiseWeekly: boolean = false;
        let isDefaultDiscountMerchandiseOccasion: boolean = false;
        // service
        let isDefaultDiscountServiceBirthday: boolean = false;
        let isDefaultDiscountServiceWeekly: boolean = false;
        let isDefaultDiscountServiceOccasion: boolean = false;
        const saleTax =
          (dataValidate &&
            dataValidate[0] &&
            dataValidate[0].serviceTax &&
            dataValidate[0].serviceTax.percentDiscount) ??
          0;

        dataValidate.forEach((ticket) => {
          ticket.technicianTurns.forEach((technicianTurn) => {
            const techId = technicianTurn.technicianId;
            if (!groupedTechs[techId]) {
              groupedTechs[techId] = { ...technicianTurn, ...ticket };
            } else {
              groupedTechs[techId].serviceTurns = [
                ...groupedTechs[techId].serviceTurns,
                ...technicianTurn.serviceTurns,
              ];
            }
          });
        });
        const mergedTechnicianTurns: ITicketDTO[] = Object.values(groupedTechs);
        const dataTechnician: ITechnicianItem[] = [];
        mergedTechnicianTurns.map((item) => {
          return dataTechnician.push({
            defaultAvatar: item?.technician?.defaultAvatar ?? AvatarDefault,
            id: item?.technician?.id ?? parseFloat(Math.random().toFixed(3)) * 10,
            name: item?.technician?.name,
            phoneNumber: item?.technician?.phoneNumber,
            title: item?.technician?.title,
            skills: item?.technician?.skills.map((item) => item.id),
            services:
              item.serviceTurns?.map((itemServicesTurn: IServiceTurnDTO) => {
                const totalDiscount = itemServicesTurn.totalDiscount ? +itemServicesTurn.totalDiscount : 0;
                const tax =
                  itemServicesTurn.service?.isTaxable && saleTax > 0
                    ? {
                        type: DiscountTypeEnum.Percent,
                        moneyDiscount: ((+itemServicesTurn.price - totalDiscount) * saleTax) / 100,
                      }
                    : undefined;
                return {
                  description: itemServicesTurn.service?.description ?? '',
                  id: itemServicesTurn.serviceId ?? parseFloat(Math.random().toFixed(3)) * 10,
                  idDelete: parseFloat(Math.random().toFixed(3)) * 10,
                  name: itemServicesTurn.isGeneralService
                    ? intl.formatMessage({ id: 'checkout.generalService' })
                    : itemServicesTurn.service?.name ?? '',
                  price: itemServicesTurn.price ?? 0,
                  supply: itemServicesTurn.supply ?? 0,
                  isMerchandise: itemServicesTurn.service?.isMerchandise ?? false,
                  isGeneralService: itemServicesTurn.isGeneralService,
                  totalDiscount: totalDiscount,
                  discount: {
                    ...dataValidate[0].discount,
                    technicianDiscount: itemServicesTurn.discount?.technicianDiscount,
                    tax,
                  },
                  isTaxable: itemServicesTurn.isGeneralService ? true : itemServicesTurn.service?.isTaxable ?? false,
                };
              }) ?? [],
          });
        });
        dataValidate.map((item) =>
          item.ticketGiftCard?.map((itemTicket) => {
            return ticketGiftCards.push({
              giftCardCode: itemTicket.giftCardCode,
              giftCardValue: +itemTicket.giftCardValue,
              id: itemTicket.id,
            });
          })
        );
        dataValidate.forEach((item) =>
          item.ticketMerchandise?.forEach((ticket: IMerchandiseCombine) => {
            const totalDiscount = ticket.totalDiscount ? +ticket.totalDiscount : 0;
            const tax =
              ticket?.merchandise?.isTaxable && saleTax > 0
                ? {
                    type: DiscountTypeEnum.Percent,
                    moneyDiscount: ((+ticket.merchandisePrice - totalDiscount) * saleTax) / 100,
                  }
                : undefined;
            listTicketMerchandise.push({
              idDelete: parseFloat(Math.random().toFixed(3)) * 10,
              merchandiseId: ticket.merchandiseId,
              name: (ticket as any)?.merchandise?.name ?? intl.formatMessage({ id: 'checkout.merchandise' }),
              merchandisePrice: +ticket.merchandisePrice,
              discount: { ...dataValidate[0].discount, tax },
              totalDiscount: totalDiscount,
              isTaxable: ticket?.merchandise?.isTaxable ?? false,
            });
          })
        );

        dataValidate.map((item) => (totalDeposit += +item.deposit));
        dataValidate.map((item) => appointmentIds.concat(item.appointmentId as number[]) as number[]);

        let totalAmountPayment: number = 0;

        let totalTipMoneyPayment: number = 0;

        // cash
        let totalCashPayment: number = 0;
        let totalTipCashPayment: number = 0;
        let checkIsHaveCash: boolean = false;

        // gift card
        let giftCardNumber: number = 0;
        let totalGiftCardPayment: number = 0;
        let checkIsHaveGiftCard: boolean = false;

        // check
        let totalAmountCheckPayment: number = 0;
        let totalTipCheckPayment: number = 0;
        let checkIsHaveCheck: boolean = false;

        // cardNumber / keyin
        let totalAmountCardNumberPayment: number = 0;
        let totalTipCardNumberPayment: number = 0;
        let checkIsHaveCardNumber: boolean = false;
        const oldTransaction: Array<TicketTransactionDTO> | any = [];

        // orther
        let totalAmountOrtherPayment: number = 0;
        let totalTipOrtherPayment: number = 0;
        let checkIsHaveOrther: boolean = false;

        // multiple cards
        let totalAmountMultipleCardsPayment: number = 0;
        let totalTipMultipleCardsPayment: number = 0;
        let checkIsHaveMultipleCards: boolean = false;

        dataValidate.forEach((item) => {
          if (!!item.ticketTransaction?.length) {
            oldTransaction.push(item.ticketTransaction);
          }
          item.ticketTransaction?.forEach((itemTicketTrans) => {
            if (itemTicketTrans.money) {
              totalAmountPayment += +itemTicketTrans.money;
            }
            if (itemTicketTrans.tipMoney) {
              totalTipMoneyPayment += +itemTicketTrans.tipMoney;
            }
            // cash
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.Cash) {
              totalCashPayment += +itemTicketTrans.money;
              if (itemTicketTrans.tipMoney) {
                totalTipCashPayment += +itemTicketTrans.tipMoney;
                totalTipMoneyPayment += +itemTicketTrans.tipMoney;
              }
              checkIsHaveCash = true;
            }
            // gift card
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.GiftCard) {
              totalGiftCardPayment += +itemTicketTrans.money;
              if (itemTicketTrans.giftCardNumber) {
                giftCardNumber = itemTicketTrans.giftCardNumber;
              }
              checkIsHaveGiftCard = true;
            }

            // check
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.Check) {
              totalAmountCheckPayment += +itemTicketTrans.money;
              if (itemTicketTrans.tipMoney) {
                totalTipMoneyPayment += +itemTicketTrans.tipMoney;
                totalTipCheckPayment += +itemTicketTrans.tipMoney;
              }
              checkIsHaveCheck = true;
            }

            // Orther
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.Orther) {
              totalAmountOrtherPayment += +itemTicketTrans.money;
              if (itemTicketTrans.tipMoney) {
                totalTipMoneyPayment += +itemTicketTrans.tipMoney;
                totalTipOrtherPayment += +itemTicketTrans.tipMoney;
              }
              checkIsHaveOrther = true;
            }

            // Mutiple cards
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.MultipleChargeCard) {
              totalAmountMultipleCardsPayment += +itemTicketTrans.money;
              if (itemTicketTrans.tipMoney) {
                totalTipMoneyPayment += +itemTicketTrans.tipMoney;
                totalTipMultipleCardsPayment += +itemTicketTrans.tipMoney;
              }
              checkIsHaveMultipleCards = true;
            }

            // card number / keyin
            if (itemTicketTrans.typePayment === TicketTransactionDTOTypePaymentEnum.CardNumber) {
              totalAmountCardNumberPayment += +itemTicketTrans.money;
              if (itemTicketTrans.tipMoney) {
                totalTipMoneyPayment += +itemTicketTrans.tipMoney;
                totalTipCardNumberPayment += +itemTicketTrans.tipMoney;
              }
              checkIsHaveCardNumber = true;
            }
          });
        });
        const flatOldTransaction = oldTransaction.flat();
        const grpOldTransaction = flatOldTransaction.reduce((acc: any, item: ITicketTransactionDTO) => {
          let key;
          if (item.typePayment === 'Cash' || item.typePayment === 'Check') {
            key = `${item.typePayment}-${item.giftCardNumber}-${item.id}`;
          } else {
            key = `Unique-${item.id}`;
          }
          if (!acc[key]) {
            acc[key] = {
              giftCardNumber: item.giftCardNumber ? item.giftCardNumber.toString() : null,
              status: item.status,
              typePayment: item.typePayment,
              money: 0,
              tipMoney: item.tipMoney,
            };
          }
          acc[key].money += Number(item.money);
          return acc;
        }, {});

        const result: any = Object.values(grpOldTransaction);

        const dataPaymentInformation = {
          amount: totalAmountPayment,
          cashDiscount: 0,
          ticketTransaction: result,
          cashPayment: checkIsHaveCash
            ? {
                amount: totalCashPayment,
                tip: totalTipCashPayment,
              }
            : undefined,
          giftCardPayment: checkIsHaveGiftCard
            ? {
                totalValue: totalGiftCardPayment,
                giftCards:
                  giftCardNumber > 0
                    ? [
                        {
                          giftCardNumber: giftCardNumber,
                          giftCardValueUse: totalGiftCardPayment,
                        },
                      ]
                    : undefined,
              }
            : undefined,
          checkPayment: checkIsHaveCheck
            ? {
                amount: totalAmountCheckPayment,
                tip: totalTipCheckPayment,
              }
            : undefined,
          keyInCardPayment: checkIsHaveCardNumber
            ? {
                amount: totalAmountCardNumberPayment,
                tip: totalTipCardNumberPayment,
              }
            : undefined,
          ortherPayment: checkIsHaveOrther
            ? {
                amount: totalAmountOrtherPayment,
                tip: totalTipOrtherPayment,
              }
            : undefined,
          multipleChargeCard: checkIsHaveMultipleCards
            ? {
                amount: totalAmountMultipleCardsPayment,
                tip: totalTipMultipleCardsPayment,
              }
            : undefined,
          tip: totalTipMoneyPayment,
        };

        // setPaymentInformation(totalAmountPayment > 0 ? dataPaymentInformation : initialPromotion);
        setPaymentInformation(dataPaymentInformation);

        const data: IAppointmentBill = {
          ...appointmentBill,
          isDetail: isDoubleClick,
          id: isDoubleClick ? dataValidate[0]?.id : parseFloat(Math.random().toFixed(3)) * 10,
          customer: dataValidate[0]?.customer,
          customerId: dataValidate[0]?.customerId,
          serviceTax: dataValidate[0]?.serviceTax,
          serviceCharge: dataValidate[0]?.serviceCharge,
          giftCards: ticketGiftCards,
          status: dataValidate[0]?.status,
          deposit: totalDeposit,
          appointmentId: appointmentIds,
          technician: dataTechnician,
          ticketMerchandise: listTicketMerchandise,
          combineId: dataValidate.map((item) => item.id) as number[],
        };

        dataValidate.map((item) =>
          item.technicianTurns?.map((itemTechnician) =>
            itemTechnician.serviceTurns.map((itemServicesTurn: IServiceTurnDTO) => {
              if (itemServicesTurn.serviceId) {
                return serviceIds.push(itemServicesTurn.serviceId);
              }
              return itemServicesTurn;
            })
          )
        );
        dataValidate.map((item) =>
          item.ticketMerchandise?.map((itemMerchandise) => {
            if (itemMerchandise.merchandiseId) {
              return merchandiseIds.push(itemMerchandise.merchandiseId);
            }
            return item;
          })
        );
        const listIdServices: number[] = serviceIds.concat(merchandiseIds) ?? [];

        const isActiveDiscountOccasionReward: boolean = handleCheckIdItems(
          listIdServices,
          listPromotion?.occasionReward?.discountServices ?? []
        );
        const isActiveDiscountBirthdayReward: boolean = handleCheckIdItems(
          listIdServices,
          listPromotion?.birthdayReward?.discountServices ?? []
        );

        const isActiveDiscountWeeklyReward: boolean = handleCheckIdItems(
          listIdServices,
          listPromotion?.weeklyReward?.discountServices ?? []
        );

        listTicketMerchandise.forEach((item) => {
          if (!!item.discount?.occasionDiscount) {
            isDefaultDiscountMerchandiseOccasion = true;
          }
          if (!!item.discount?.birthdayDiscount) {
            isDefaultDiscountMerchandiseBirthday = true;
          }
          if (!!item.discount?.weeklyDiscount) {
            isDefaultDiscountMerchandiseWeekly = true;
          }
        });

        dataTechnician.forEach((item) =>
          item.services?.forEach((itemService) => {
            if (!!itemService.discount?.birthdayDiscount) {
              isDefaultDiscountServiceBirthday = true;
            }
            if (!!itemService.discount?.weeklyDiscount) {
              isDefaultDiscountServiceWeekly = true;
            }
            if (!!itemService.discount?.occasionDiscount) {
              isDefaultDiscountServiceOccasion = true;
            }
          })
        );

        setDiscountsAndRewardsApplied({
          ...discountsAndRewardsApplied,
          weeklyDiscount:
            !!listPromotion?.weeklyReward?.discountServices?.length &&
            isActiveDiscountWeeklyReward &&
            (isDefaultDiscountServiceWeekly || isDefaultDiscountMerchandiseWeekly)
              ? {
                  amount: listPromotion?.weeklyReward.discountAmount
                    ? +listPromotion?.weeklyReward.discountAmount
                    : +listPromotion?.weeklyReward.discountPercent,
                  day: listPromotion.weeklyReward.dayOfWeek,
                  serviceItemIds: listPromotion?.weeklyReward?.allService
                    ? listDataServices.map((e) => e.id)
                    : listPromotion?.weeklyReward?.discountServices ?? [],
                  type: !!listPromotion?.weeklyReward.weeklyReward ? DiscountTypeEnum.Dollar : DiscountTypeEnum.Percent,
                }
              : undefined,
          birthdayDiscount:
            !!listPromotion?.birthdayReward?.discountServices?.length &&
            isActiveDiscountBirthdayReward &&
            (isDefaultDiscountServiceBirthday || isDefaultDiscountMerchandiseBirthday)
              ? {
                  amount: listPromotion?.birthdayReward.discountAmount
                    ? +listPromotion?.birthdayReward.discountAmount
                    : +listPromotion?.birthdayReward.discountPrecent,
                  serviceItemIds: listPromotion?.birthdayReward?.allService
                    ? listDataServices.map((e) => e.id)
                    : listPromotion?.birthdayReward?.discountServices ?? [],
                  type: !!listPromotion?.birthdayReward.discountAmount
                    ? DiscountTypeEnum.Dollar
                    : DiscountTypeEnum.Percent,
                }
              : undefined,
          occasionDiscount:
            !!listPromotion?.occasionReward?.discountServices?.length &&
            isActiveDiscountOccasionReward &&
            (isDefaultDiscountServiceOccasion || isDefaultDiscountMerchandiseOccasion)
              ? {
                  amount: listPromotion?.occasionReward.discountAmount
                    ? +listPromotion?.occasionReward.discountAmount
                    : +listPromotion?.occasionReward.discountPercent,
                  name: '',
                  serviceItemIds: listPromotion?.occasionReward?.allService
                    ? listDataServices.map((e) => e.id)
                    : listPromotion?.occasionReward?.discountServices ?? [],
                  type: !!listPromotion?.occasionReward.discountAmount
                    ? DiscountTypeEnum.Dollar
                    : DiscountTypeEnum.Percent,
                }
              : undefined,
          generalTicketDiscount: !!dataValidate[0].discount.generalTicketDiscount?.discountApply
            ? {
                amount: dataValidate[0].discount.generalTicketDiscount.discountApply
                  ? +dataValidate[0].discount.generalTicketDiscount.discountApply
                  : 0,
                isCustomAmount: true,
                type:
                  dataValidate[0].discount.generalTicketDiscount.type === DiscountTypeEnum.Dollar
                    ? DiscountTypeEnum.Dollar
                    : DiscountTypeEnum.Percent,
              }
            : undefined,
          generalMerchandiseDiscount: !!dataValidate[0].discount.generalItemDiscount?.discountApply
            ? {
                amount: dataValidate[0].discount.generalItemDiscount.discountApply
                  ? +dataValidate[0].discount.generalItemDiscount.discountApply
                  : 0,
                isCustomAmount: true,
                type:
                  dataValidate[0].discount.generalItemDiscount.type === DiscountTypeEnum.Dollar
                    ? DiscountTypeEnum.Dollar
                    : DiscountTypeEnum.Percent,
              }
            : undefined,
          referralDiscount: dataValidate[0].discount.referralDiscount?.discountApply
            ? {
                amount: dataValidate[0].discount.referralDiscount?.discountApply
                  ? +dataValidate[0].discount.referralDiscount?.discountApply
                  : 0,
                referralCode: dataValidate[0].discount.referralDiscount?.referralCode ?? '',
                type: DiscountTypeEnum.Dollar,
              }
            : undefined,
          rewardBalance: dataValidate[0].discount.rewardBalance?.discountApply
            ? {
                amount: dataValidate[0].discount.rewardBalance?.discountApply,
                type:
                  dataValidate[0].discount.rewardBalance.type === DiscountTypeEnum.Dollar
                    ? DiscountTypeEnum.Dollar
                    : DiscountTypeEnum.Percent,
                isCustomAmount: !!dataValidate[0].discount.rewardBalance.isCustomAmount,
                points: dataValidate[0].discount.rewardBalance.point ?? 0,
              }
            : undefined,
        });

        setTaxPercent(dataValidate[0].serviceTax?.percentDiscount ?? 0);
        setServiceCharge({
          chargeValue: dataValidate[0].serviceCharge?.percentDiscount ?? 0,
          name: dataValidate[0].serviceCharge?.name ?? '',
        });

        data.technician.map((item) =>
          item.services?.map((itemServiceMap) => {
            if (itemServiceMap.id) {
              return serviceDisableIds.push(itemServiceMap.id as number);
            }
            return itemServiceMap;
          })
        );
        setListIdDisable({
          listIdMerchandise: !!data.ticketMerchandise.length
            ? (data.ticketMerchandise.map((item) => item.merchandiseId as number) as number[])
            : [],
          listIdServices: serviceDisableIds,
          listIdTechnician: !!data.technician.length
            ? (data.technician.map((item) => item.id as number) as number[])
            : [],
        });
        setServiceApply(serviceDisableIds);
        setIdTechnician(data.technician[0]?.id as number);
        setListSkill((data.technician[0]?.skills as number[]) ?? []);

        // final logic
        const giftCardValue = dataPaymentInformation.giftCardPayment?.totalValue
          ? +dataPaymentInformation.giftCardPayment?.totalValue
          : 0;
        setAppointmentBill(data);
        if (dataPaymentInformation.amount === 0) {
          handleGetTotal(data);
        } else {
          setListTotal({
            ...listTotal,
            totalCash: dataPaymentInformation.amount - giftCardValue,
          });
        }
        onCancelModal();
      }
    }
  };

  const onFinish = (values: SearchPendingDTO) => {
    setSearchPending(values.fullTextSearch);
    setListCombine([]);
  };

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        setSearchPending(value ?? '');
      }, 500);
    },
    [timeout]
  );

  useEffect(() => {
    debounceSearch(fullSearchTextValue);
  }, [fullSearchTextValue]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-pending-ticket-modal-heading">
            {intl.formatMessage({ id: 'checkout.pending.ticket' })}
          </div>
        ),
        className: 'salon__checkout-pending-ticket-modal ',
        width: 1000,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.pending.ticket.combine' })}
                buttonProps={{
                  disabled: listCombine.length === 0,
                  className: 'width-100 height-38',
                  onClick: () => {
                    onSubmit();
                  },
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap
        form={form}
        onFinish={onFinish}
        name="pendingTicket"
        layout="vertical"
        className="w-100 salon__checkout-pending-ticket-modal-form"
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 12]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormInputSearch
              name={n('fullTextSearch')}
              inputProps={{
                placeholder: intl.formatMessage({ id: 'customer.placeholder.search' }),
                className: 'width-920 ',
                onFocus: () => setInputName('fullTextSearch'),
                onKeyDown: (event) => {
                  const value = event.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (value !== 'Backspace' && value !== 'Enter' && event?.key?.length < 2)
                    keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
              }}
              formItemProps={{
                rules: [
                  {
                    transform: (value) => {
                      return value?.trim();
                    },
                  },
                ],
                className: 'm-0',
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <div className="salon__checkout-pending-ticket-modal-card">
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="start">
                {listTicketPending?.map((item, index) => (
                  <ColWrap colProps={{ span: 5 }} key={index}>
                    <div
                      onDoubleClick={() => {
                        onSubmit(true, [item]);
                      }}
                      className="cursor-pointer "
                    >
                      <TicketCard ticketData={item} listCombine={listCombine} setListCombine={setListCombine} />
                    </div>
                  </ColWrap>
                ))}
              </RowWrap>
            </div>
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <div className="salon__information min-height-50 p-t-0 bg-white">
              <div className="salon__step-one">
                <div className="salon__form-keyboard">
                  <KeyboardWrapper
                    onNext={() => {}}
                    onFinish={() => {
                      form.submit();
                    }}
                    form={form}
                    maxLength={255}
                    inputName={inputName}
                    keyboardRef={keyboardRef}
                    keyboardProps={{
                      inputPattern: undefined,
                    }}
                  />
                </div>
              </div>
            </div>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default PendingTicket;
