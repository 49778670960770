import { CollapseProps } from 'antd';
import { Dispatch, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CompletedResponse,
  SettingCheckout,
  SettingTip,
  Ticket,
  TicketTransactionTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { SvgArrowDownCollapse } from '../../../../../../components/@svg/SvgArrowDownCollapse';
import { SvgArrowUpCollapse } from '../../../../../../components/@svg/SvgArrowUpCollapse';
import ColWrap from '../../../../../../components/ColWrap';
import RowWrap from '../../../../../../components/RowWrap';
import StyledCollapse from '../../../../../../components/StyledCollapse';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import {
  DATE_FORMAT,
  HHMMA_TIME_FORMAT,
  formatDateTimeZoneByFormatString,
  formatNumberThousandWithDecimal,
} from '../../../../../../utils';
import { IOpenModalCheckOut, PrintType } from '../../../BillStart';
import RefundMethod from '../RefundMethod';
import { SaleTicket } from '../SaleTicket';
import './transactionsCollapseStyle.scss';

interface IProps {
  collapseProps?: CollapseProps;
  ticket: CompletedResponse;
  onCancelModal: () => void;
  settingCheckout?: SettingCheckout;
  isLoading: Record<PrintType, boolean>;
  orderNumber: number;
  onChangeOpenModal: Dispatch<React.SetStateAction<IOpenModalCheckOut>>;
  onChangeTicketToPrint: (ticket: Ticket) => void;
  settingTip?: SettingTip;
}

const saleTicketKey = 'saleTicketKey';
const DEFAULT_ORDER_NUMBER_VALUE = '0000';

const TransactionsCollapse: FC<IProps> = (props) => {
  const {
    settingCheckout,
    settingTip,
    ticket,
    onCancelModal,
    onChangeOpenModal,
    isLoading,
    onChangeTicketToPrint,
    orderNumber,
  } = props;

  const intl = useIntl();

  const [saleTicketCollapseKey, setSaleTicketCollapseKey] = useState<string>('');
  const [isOpenRefundMethod, setIsOpenRefundMethod] = useState<boolean>(false);

  const onClickRefund = () => {
    setIsOpenRefundMethod(true);
  };

  const getTypePaymentName = (value: TicketTransactionTypePaymentEnum) => {
    switch (value) {
      case TicketTransactionTypePaymentEnum.CardNumber:
        return intl.formatMessage({ id: 'checkout.payment.cardnumber' });
      case TicketTransactionTypePaymentEnum.Cash:
        return intl.formatMessage({ id: 'checkout.payment.cash' });
      case TicketTransactionTypePaymentEnum.ChargeCard:
        return intl.formatMessage({ id: 'checkout.payment.chargecard' });
      case TicketTransactionTypePaymentEnum.Check:
        return intl.formatMessage({ id: 'checkout.payment.check' });
      case TicketTransactionTypePaymentEnum.GiftCard:
        return intl.formatMessage({ id: 'checkout.giftCard' });
      case TicketTransactionTypePaymentEnum.Orther:
        return 'Orther';
      case TicketTransactionTypePaymentEnum.MultipleChargeCard:
        return intl.formatMessage({ id: 'checkout.multipleChargeCard' });
      default:
        return '...';
    }
  };

  const getTypePaymentNameCardOnly = (value: TicketTransactionTypePaymentEnum) => {
    switch (value) {
      case TicketTransactionTypePaymentEnum.CardNumber:
        return intl.formatMessage({ id: 'checkout.payment.cardnumber' });
      case TicketTransactionTypePaymentEnum.ChargeCard:
        return intl.formatMessage({ id: 'checkout.payment.chargecard' });
      case TicketTransactionTypePaymentEnum.MultipleChargeCard:
        return intl.formatMessage({ id: 'checkout.multipleChargeCard' });
      default:
        return intl.formatMessage({ id: 'checkout.transactions.noneCard' });
    }
  };

  const handlePrintReceipt = () => {
    onChangeTicketToPrint(ticket);
    onChangeOpenModal((prev) => ({ ...prev, openPrintReceipt: true }));
  };

  const handleRenderOrderNumber = () => {
    const orderNumberLength = orderNumber.toString().length;

    return orderNumberLength < 5
      ? `${DEFAULT_ORDER_NUMBER_VALUE.slice(0, -orderNumberLength)}${orderNumber}`
      : orderNumber;
  };

  return (
    <>
      <StyledCollapse
        collapseProps={{
          className: 'salon__transactions-collapse',
          expandIcon: (panelProps) => <>{panelProps.isActive ? <SvgArrowDownCollapse /> : <SvgArrowUpCollapse />}</>,
        }}
        items={[
          {
            label: (
              <div className="salon__transactions-collapse__ticket d-flex justify-content-between align-items-center">
                <div className="d-flex gap-40 align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="font-size-20 font-weight-400 color-78797A line-height-28 width-53">
                      {handleRenderOrderNumber()}
                      {/* {ticket.id} */}
                    </div>
                    <div className="d-flex flex-column gap-4 width-92">
                      <div className="font-size-20 font-weight-400 color-292F33 line-height-28">
                        {formatDateTimeZoneByFormatString(HHMMA_TIME_FORMAT(' '), ticket.createdOnDate)}
                      </div>
                      <div className="font-size-16 font-weight-400 color-78797A line-height-22">
                        {formatDateTimeZoneByFormatString(DATE_FORMAT, ticket.createdOnDate)}
                      </div>
                    </div>
                  </div>
                  <div className="font-size-20 font-weight-600 color-292F33 text-transform-capitalize">
                    {Array.from(
                      new Set(
                        ticket.ticketTransaction
                          ?.filter(
                            (trans) =>
                              trans.typePayment &&
                              trans.typePayment !== TicketTransactionTypePaymentEnum.TipCash &&
                              trans.typePayment !== TicketTransactionTypePaymentEnum.Tip
                          )
                          ?.map((trans) =>
                            settingCheckout?.hideNoneCardItemsInTransactions
                              ? getTypePaymentNameCardOnly(trans.typePayment)
                              : getTypePaymentName(trans.typePayment)
                          )
                      )
                    )?.join('/ ')}
                  </div>
                </div>
                <div className="salon__transactions-collapse__ticket-total font-size-20 font-weight-600 color-292F33">
                  {/* ${formatNumberThousandWithDecimal(Number((ticket?.totalPayment ?? 0) - (ticket?.deposit ?? 0)))} */}
                  $
                  {formatNumberThousandWithDecimal(
                    Number(ticket.ticketTransaction.map((e) => Number(e.money)).reduce((a, b) => a + b, 0))
                  )}
                  {!!ticket?.transactionRefunds?.length ? (
                    <span className="salon__transactions-collapse__ticket-total__refunded">
                      {intl.formatMessage({ id: 'checkout.transactions.refunded' })}&nbsp;
                      {`$${formatNumberThousandWithDecimal(
                        ticket.transactionRefunds.map((e) => Number(e.money)).reduce((a, b) => a + b, 0)
                      )}`}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ),
            children: (
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 12]} isWrap={true} styleFill="between">
                <ColWrap colProps={{ span: 10 }}>
                  <RowWrap
                    isAutoFillRow={true}
                    isGutter={true}
                    gutter={[0, 0]}
                    isWrap={true}
                    styleFill="between"
                    className="font-weight-600 line-height-22"
                  >
                    {Array.from(new Set(ticket.ticketTransaction?.map((trans) => trans.typePayment))).length > 1 ? (
                      <ColWrap colProps={{ span: 24 }} className="salon__transactions-collapse__detail__payment">
                        {Array.from(new Set(ticket.ticketTransaction?.map((trans) => trans.typePayment))).map(
                          (typePayment) => (
                            <p className="m-b-12 font-weight-400">
                              <span className="width-120 d-inline-block">{getTypePaymentName(typePayment)}:</span>
                              <span>
                                $
                                {formatNumberThousandWithDecimal(
                                  ticket?.ticketTransaction
                                    ?.filter((trans) => trans.typePayment && trans.typePayment === typePayment)
                                    ?.map((trans) => trans.money)
                                    ?.reduce((prev, cur) => Number(prev) + Number(cur), 0) ?? 0
                                )}
                              </span>
                            </p>
                          )
                        )}
                      </ColWrap>
                    ) : (
                      ''
                    )}
                    <ColWrap colProps={{ span: 24 }}>
                      <p className="m-b-8">
                        <span className="width-120 d-inline-block">
                          {intl.formatMessage({ id: 'checkout.transactions.item.totalPayment' })}:
                        </span>
                        <span>${formatNumberThousandWithDecimal(ticket?.totalPayment ?? 0)}</span>
                      </p>
                    </ColWrap>
                    <ColWrap colProps={{ span: 24 }}>
                      <p className="m-b-0">
                        <span className="width-120 d-inline-block">
                          {intl.formatMessage({ id: 'checkout.transactions.item.tip' })}:
                        </span>
                        <span>${formatNumberThousandWithDecimal(ticket?.totalTip)}</span>
                      </p>
                    </ColWrap>
                    {!!ticket?.transactionRefunds?.length && (
                      <ColWrap colProps={{ span: 24 }}>
                        <p className="m-b-0 m-t-8">
                          <span className="width-120 d-inline-block">
                            {intl.formatMessage({ id: 'checkout.transactions.refunded' })}:
                          </span>
                          <span className="color-EB4C3B">
                            {`($${formatNumberThousandWithDecimal(
                              ticket.transactionRefunds.map((e) => Number(e.money)).reduce((a, b) => a + b, 0)
                            )})`}
                          </span>
                        </p>
                      </ColWrap>
                    )}
                  </RowWrap>
                </ColWrap>
                <ColWrap colProps={{ span: 14 }}>
                  <div className="d-flex justify-content-end gap-12">
                    <div>
                      {!ticket?.transactionRefunds?.length && (
                        <CustomButton
                          type="cancel"
                          content={intl.formatMessage({ id: 'checkout.transactions.item.refund' })}
                          buttonProps={{
                            className:
                              'salon__checkout__primary-outlined-btn font-weight-700 font-size-16 height-38 width-150',
                            onClick: onClickRefund,
                          }}
                        />
                      )}
                    </div>

                    <div>
                      <CustomButton
                        type="cancel"
                        content={intl.formatMessage({ id: 'checkout.transactions.item.printReceipt' })}
                        buttonProps={{
                          className:
                            'salon__checkout__primary-outlined-btn font-weight-700 font-size-16 height-38 width-150',
                          onClick: handlePrintReceipt,
                          disabled: isLoading.TRANSACTION,
                        }}
                      />
                    </div>
                  </div>
                </ColWrap>
                <ColWrap colProps={{ span: 24 }}>
                  <StyledCollapse
                    collapseProps={{
                      activeKey: saleTicketCollapseKey,
                      ghost: true,
                      className: 'salon__transactions-collapse__sale-ticket-collapse',
                    }}
                    items={[
                      {
                        key: saleTicketKey,
                        showArrow: false,
                        headerClass: 'p-0',
                        label: (
                          <CustomButton
                            type="link"
                            content={
                              <span className="d-flex">
                                {intl.formatMessage({ id: 'checkout.transactions.item.saleTicket' })}
                                {saleTicketCollapseKey ? (
                                  <p className="m-b-0 m-t-1-n">
                                    <SvgArrowDownCollapse className="salon__transactions-collapse__sale-ticket-icon" />
                                  </p>
                                ) : (
                                  <p className="m-b-0 m-t-1-n">
                                    <SvgArrowUpCollapse className="salon__transactions-collapse__sale-ticket-icon" />
                                  </p>
                                )}
                              </span>
                            }
                            buttonProps={{
                              className: 'font-weight-700 color-0090FF m-b-0 p-0',
                              onClick: () =>
                                saleTicketCollapseKey
                                  ? setSaleTicketCollapseKey('')
                                  : setSaleTicketCollapseKey(saleTicketKey),
                            }}
                          />
                        ),
                        children: <SaleTicket ticket={ticket} settingTip={settingTip} />,
                      },
                    ]}
                  />
                </ColWrap>
              </RowWrap>
            ),
          },
        ]}
      />
      {isOpenRefundMethod && (
        <RefundMethod
          ticketId={ticket.id}
          // totalPayment={ticket.totalPayment}
          totalPayment={
            (ticket?.totalPayment ?? 0) -
              (ticket?.discount?.rewardBalance?.moneyDiscount ?? 0) -
              (ticket?.deposit ?? 0) >=
            0
              ? (ticket?.totalPayment ?? 0) -
                (ticket?.discount?.rewardBalance?.moneyDiscount ?? 0) -
                (ticket?.deposit ?? 0)
              : 0
          }
          open={isOpenRefundMethod}
          setOpen={setIsOpenRefundMethod}
          onCancelModal={onCancelModal}
          ticket={ticket}
        />
      )}
    </>
  );
};

export default TransactionsCollapse;
