import { Form, FormItemProps } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';
import React, { ReactNode, useEffect, useRef } from 'react';
import '../FormInput/formInput.scss';
import './formTextArea.scss';

type Props = {
  name: string;
  label?: string | ReactNode;
  formItemProps?: FormItemProps;
  inputProps?: TextAreaProps;
  resizable?: boolean;
  isFocus?: boolean;
};

export const FormTextArea: React.FC<Props> = ({ inputProps, formItemProps, resizable = true, isFocus, ...rest }) => {
  const textAreaRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isFocus) {
      textAreaRef?.current?.focus();
    }
  }, [isFocus]);

  return (
    <Form.Item
      {...rest}
      {...formItemProps}
      className={
        'salon__form-input salon__form-input-textarea w-100' +
        (formItemProps?.className ? ' ' + formItemProps.className : '') +
        (!resizable ? ' ' + 'no-resize' : '')
      }
    >
      <TextArea autoComplete="off" {...inputProps} ref={textAreaRef} />
    </Form.Item>
  );
};
