import { Divider } from 'antd';
import { ForwardedRef, Fragment, forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TicketTransactionDTOTypePaymentEnum } from '../../../apis/client-axios';
import { DiscountTypeEnum } from '../../../routes/tenant/checkOut/checkoutConstants';
import {
  DATE_TIME_FORMAT_IN_BILL,
  formatDateTimeZoneByFormatString,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../utils';
import ColWrap from '../../ColWrap';
import RowWrap from '../../RowWrap';
import { PrintBillProps, TimePayment } from '../@types';
import BillItem from '../BillItem';
import HeaderBill from '../HeaderBill';
import '../bill.scss';

const PrintBill = (props: PrintBillProps, ref: ForwardedRef<HTMLDivElement | null>) => {
  const { salon, ticket, customer, isHideSupplyAmount, timePayment = TimePayment.AFTER, listPromotion } = props;

  const intl = useIntl();

  const convertValue = (value: number | string, type?: DiscountTypeEnum | string) => {
    return value ? (type === DiscountTypeEnum.Percent ? Number(value) : value) : 0;
  };

  const paymentInformation = ticket?.ticketTransaction.find(
    (transaction) => transaction.typePayment === TicketTransactionDTOTypePaymentEnum.ChargeCard
  )?.paymentInformation;

  return (
    <div ref={ref} className="salon__print-bill-container">
      <HeaderBill
        salon={salon}
        customer={{
          isHideCustomerPhoneNumber: !!customer?.isHideCustomerPhoneNumber,
          name: ticket?.customer?.name ?? '',
          phoneNumber: ticket?.customer?.phoneNumber,
        }}
      />

      <div className="m-t-8 m-b-10">
        <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
          <p className="salon__print-bill-content">#{ticket?.id}</p>
          <p className="salon__print-bill-content">
            {formatDateTimeZoneByFormatString(DATE_TIME_FORMAT_IN_BILL, ticket?.createdOnDate)}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="salon__print-bill-title-services">{intl.formatMessage({ id: 'print.bill.services' })}</p>
          <p className="salon__print-bill-title-services">{intl.formatMessage({ id: 'print.bill.amount' })}</p>
        </div>
      </div>
      <Divider dashed className="salon__print-bill-strike m-b-4" />
      <Divider dashed className="salon__print-bill-strike m-t-4" />

      {ticket?.technicianTurns
        ?.flatMap((technicianTurn) => technicianTurn.serviceTurns)
        ?.map((serviceTurn) => {
          const findTechnician = ticket.technicianTurns.find(
            (technicianTurn) => technicianTurn.id === serviceTurn.technicianTurnId
          )?.technician;

          return (
            <Fragment key={serviceTurn.id}>
              {/* service, merchandise */}
              <BillItem
                title={`${
                  serviceTurn?.service?.name ??
                  `${intl.formatMessage({ id: 'print.bill.generalServices' })}`.toUpperCase()
                }${findTechnician ? ` (${findTechnician?.name})` : ''}`}
                value={Number(serviceTurn?.price)}
              />

              {serviceTurn?.supply && !isHideSupplyAmount ? (
                <div className="m-l-8">
                  <BillItem title={`${intl.formatMessage({ id: 'print.bill.supply' })}`} value={serviceTurn.supply} />
                </div>
              ) : (
                <></>
              )}

              {serviceTurn?.discount?.referralDiscount ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage({ id: 'print.bill.referralDiscount' })}`.toUpperCase()}
                    value={serviceTurn.supply}
                  />
                </div>
              ) : (
                <></>
              )}

              {serviceTurn?.discount?.weeklyDiscount ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage(
                      { id: 'print.bill.weekly' },
                      {
                        index: convertValue(
                          Number(serviceTurn?.discount?.weeklyDiscount?.discountApply),
                          serviceTurn?.discount?.weeklyDiscount.type
                        ),
                        symbol:
                          serviceTurn?.discount?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                            ? DiscountTypeEnum.Percent
                            : '',
                        symbolDollar:
                          serviceTurn?.discount?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                            ? DiscountTypeEnum.Dollar
                            : '',
                      }
                    )}`.toUpperCase()}
                    value={serviceTurn?.discount?.weeklyDiscount?.moneyDiscount ?? 0}
                  />
                </div>
              ) : (
                <></>
              )}

              {serviceTurn?.discount?.birthdayDiscount ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage(
                      { id: 'print.bill.birthday' },
                      {
                        index: convertValue(
                          Number(serviceTurn?.discount?.birthdayDiscount?.discountApply),
                          serviceTurn?.discount?.birthdayDiscount.type
                        ),
                        symbol:
                          serviceTurn?.discount?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                            ? DiscountTypeEnum.Percent
                            : '',
                        symbolDollar:
                          serviceTurn?.discount?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                            ? DiscountTypeEnum.Dollar
                            : '',
                      }
                    )}`.toUpperCase()}
                    value={serviceTurn?.discount?.birthdayDiscount?.moneyDiscount ?? 0}
                  />
                </div>
              ) : (
                <></>
              )}

              {serviceTurn?.discount?.technicianDiscount ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage(
                      { id: 'print.bill.technician' },
                      {
                        index: convertValue(
                          Number(serviceTurn?.discount?.technicianDiscount?.discountApply),
                          serviceTurn?.discount?.technicianDiscount.type
                        ),
                        symbol:
                          serviceTurn?.discount?.technicianDiscount?.type === DiscountTypeEnum.Percent
                            ? DiscountTypeEnum.Percent
                            : '',
                        symbolDollar:
                          serviceTurn?.discount?.technicianDiscount?.type === DiscountTypeEnum.Dollar
                            ? DiscountTypeEnum.Dollar
                            : '',
                      }
                    )}`.toUpperCase()}
                    value={serviceTurn?.discount?.technicianDiscount?.moneyDiscount ?? 0}
                  />
                </div>
              ) : (
                <></>
              )}

              {serviceTurn?.discount?.occasionDiscount ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage(
                      { id: 'print.bill.occasion' },
                      {
                        index: convertValue(
                          Number(serviceTurn?.discount?.occasionDiscount?.discountApply),
                          serviceTurn?.discount?.occasionDiscount.type
                        ),
                        symbol:
                          serviceTurn?.discount?.occasionDiscount?.type === DiscountTypeEnum.Percent
                            ? DiscountTypeEnum.Percent
                            : '',
                        symbolDollar:
                          serviceTurn?.discount?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                            ? DiscountTypeEnum.Dollar
                            : '',
                      }
                    )}`.toUpperCase()}
                    value={serviceTurn?.discount?.occasionDiscount?.moneyDiscount ?? 0}
                  />
                </div>
              ) : (
                <></>
              )}

              {ticket.serviceTax?.percentDiscount && ticket.serviceTax.percentDiscount > 0 ? (
                <div className="m-l-8">
                  <BillItem
                    title={`${intl.formatMessage(
                      { id: 'print.bill.tax' },
                      { index: convertValue(ticket?.serviceTax?.percentDiscount ?? 0, DiscountTypeEnum.Percent) }
                    )}`}
                    value={handleTruncateToTwoDecimal((serviceTurn.discount as any)?.tax?.moneyDiscount || 0)}
                  />
                </div>
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}

      {/* gift card, general merchandise */}
      <Divider dashed className="salon__print-bill-strike" />
      {ticket?.ticketGiftCard?.map((item) => (
        <BillItem title={intl.formatMessage({ id: 'print.bill.giftCard' })} value={item.giftCardValue} />
      ))}

      {ticket?.ticketMerchandise?.map((item) => {
        return (
          <Fragment key={item.id}>
            <BillItem
              title={item?.merchandise?.name || intl.formatMessage({ id: 'checkout.merchandise' })}
              value={item.merchandisePrice}
            />

            {(item.discount as any)?.tax ? (
              <BillItem
                title={`${intl.formatMessage(
                  { id: 'print.bill.tax' },
                  { index: convertValue(ticket?.serviceTax?.percentDiscount ?? 0, DiscountTypeEnum.Percent) }
                )}`}
                value={(item.discount as any)?.tax?.moneyDiscount}
              />
            ) : (
              <></>
            )}

            {item?.discount?.referralDiscount ? (
              <div className="m-l-8">
                <BillItem
                  title={`${intl.formatMessage(
                    { id: 'print.bill.referral' },
                    {
                      index: convertValue(
                        Number(item?.discount?.referralDiscount?.discountApply),
                        item?.discount?.referralDiscount.type
                      ),
                      symbol:
                        item?.discount?.referralDiscount?.type === DiscountTypeEnum.Percent
                          ? DiscountTypeEnum.Percent
                          : '',
                      symbolDollar:
                        item?.discount?.referralDiscount?.type === DiscountTypeEnum.Dollar
                          ? DiscountTypeEnum.Dollar
                          : '',
                    }
                  )}`.toUpperCase()}
                  value={item?.discount?.referralDiscount?.moneyDiscount ?? 0}
                />
              </div>
            ) : (
              <></>
            )}

            {item?.discount?.weeklyDiscount ? (
              <div className="m-l-8">
                <BillItem
                  title={`${intl.formatMessage(
                    { id: 'print.bill.weekly' },
                    {
                      index: convertValue(
                        Number(item?.discount?.weeklyDiscount?.discountApply),
                        item?.discount?.weeklyDiscount.type
                      ),
                      symbol:
                        item?.discount?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                          ? DiscountTypeEnum.Percent
                          : '',
                      symbolDollar:
                        item?.discount?.weeklyDiscount?.type === DiscountTypeEnum.Dollar ? DiscountTypeEnum.Dollar : '',
                    }
                  )}`.toUpperCase()}
                  value={item?.discount?.weeklyDiscount?.moneyDiscount ?? 0}
                />
              </div>
            ) : (
              <></>
            )}

            {item?.discount?.birthdayDiscount ? (
              <div className="m-l-8">
                <BillItem
                  title={`${intl.formatMessage(
                    { id: 'print.bill.birthday' },
                    {
                      index: convertValue(
                        Number(item?.discount?.birthdayDiscount?.discountApply),
                        item?.discount?.birthdayDiscount.type
                      ),
                      symbol:
                        item?.discount?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                          ? DiscountTypeEnum.Percent
                          : '',
                      symbolDollar:
                        item?.discount?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                          ? DiscountTypeEnum.Dollar
                          : '',
                    }
                  )}`.toUpperCase()}
                  value={item?.discount?.birthdayDiscount?.moneyDiscount}
                />
              </div>
            ) : (
              <></>
            )}

            {item?.discount?.occasionDiscount ? (
              <div className="m-l-8">
                <BillItem
                  title={`${intl.formatMessage(
                    { id: 'print.bill.occasion' },
                    {
                      index: convertValue(
                        Number(item?.discount?.occasionDiscount?.discountApply),
                        item?.discount?.occasionDiscount.type
                      ),
                      symbol:
                        item?.discount?.occasionDiscount?.type === DiscountTypeEnum.Percent
                          ? DiscountTypeEnum.Percent
                          : '',
                      symbolDollar:
                        item?.discount?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                          ? DiscountTypeEnum.Dollar
                          : '',
                    }
                  )}`.toUpperCase()}
                  value={item?.discount?.occasionDiscount?.moneyDiscount}
                />
              </div>
            ) : (
              <></>
            )}

            {item?.discount?.technicianDiscount ? (
              <div className="m-l-8">
                <BillItem
                  title={`${intl.formatMessage(
                    { id: 'print.bill.technician' },
                    {
                      index: convertValue(
                        Number(item?.discount?.technicianDiscount?.discountApply),
                        item?.discount?.technicianDiscount.type
                      ),
                      symbol:
                        item?.discount?.technicianDiscount?.type === DiscountTypeEnum.Percent
                          ? DiscountTypeEnum.Percent
                          : '',
                      symbolDollar:
                        item?.discount?.technicianDiscount?.type === DiscountTypeEnum.Dollar
                          ? DiscountTypeEnum.Dollar
                          : '',
                    }
                  )}`.toUpperCase()}
                  value={item?.discount?.technicianDiscount?.moneyDiscount}
                />
              </div>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}

      {/* general discount */}
      {ticket?.discount?.generalItemDiscount || ticket?.discount?.generalTicketDiscount ? (
        <Divider dashed className="salon__print-bill-strike" />
      ) : (
        <></>
      )}

      {ticket?.discount?.generalItemDiscount ? (
        <BillItem
          title={`${intl.formatMessage(
            { id: 'print.bill.generalMerchandiseDiscount' },
            {
              index: convertValue(
                Number(ticket?.discount?.generalItemDiscount.discountApply),
                ticket?.discount?.generalItemDiscount?.type
              ),
              symbol:
                ticket?.discount?.generalItemDiscount?.type === DiscountTypeEnum.Percent
                  ? DiscountTypeEnum.Percent
                  : '',
              symbolDollar:
                ticket?.discount?.generalItemDiscount?.type === DiscountTypeEnum.Dollar ? DiscountTypeEnum.Dollar : '',
            }
          )}`.toUpperCase()}
          value={ticket?.discount?.generalItemDiscount?.moneyDiscount}
        />
      ) : (
        <></>
      )}

      {ticket?.discount?.generalTicketDiscount ? (
        <BillItem
          title={`${intl.formatMessage(
            { id: 'print.bill.generalTicketDiscount' },
            {
              index: convertValue(
                Number(ticket?.discount?.generalTicketDiscount?.discountApply),
                ticket?.discount?.generalTicketDiscount?.type
              ),
              symbol:
                ticket?.discount?.generalTicketDiscount?.type === DiscountTypeEnum.Percent
                  ? DiscountTypeEnum.Percent
                  : '',
              symbolDollar:
                ticket?.discount?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
                  ? DiscountTypeEnum.Dollar
                  : '',
            }
          )}`}
          value={ticket?.discount?.generalTicketDiscount?.moneyDiscount}
        />
      ) : (
        <></>
      )}

      <Divider dashed className="salon__print-bill-strike m-b-4" />
      <Divider dashed className="salon__print-bill-strike m-t-4" />

      {/* total */}

      <BillItem title={intl.formatMessage({ id: 'print.bill.total' })} value={ticket?.total} />
      {ticket?.totalDiscount && Number(ticket?.totalDiscount) > 0 ? (
        <BillItem title={intl.formatMessage({ id: 'print.bill.totalDiscount' })} value={ticket?.totalDiscount} />
      ) : (
        <></>
      )}

      {/* redeeming */}
      {ticket?.discount?.rewardBalance ? (
        <BillItem
          title={`${intl.formatMessage({ id: 'print.bill.redeeming' })}`}
          ptsReward={intl.formatMessage(
            { id: 'print.bill.pointRewardValue' },
            {
              points: ticket?.discount?.rewardBalance?.point ?? 0,
              price: ticket?.discount?.rewardBalance?.moneyDiscount ?? 0,
            }
          )}
        />
      ) : (
        <></>
      )}

      {ticket?.serviceTax?.moneyDiscount && Number(ticket?.serviceTax?.moneyDiscount) > 0 ? (
        <BillItem
          title={`${intl.formatMessage(
            { id: 'print.bill.saleTax' },
            { index: convertValue(ticket?.serviceTax?.percentDiscount ?? 0, DiscountTypeEnum.Percent) }
          )}`}
          value={ticket?.serviceTax?.moneyDiscount}
        />
      ) : (
        <></>
      )}

      {ticket?.serviceCharge && Number(ticket?.serviceCharge?.percentDiscount) > 0 ? (
        <BillItem
          title={`${ticket?.serviceCharge?.name} (${convertValue(
            ticket?.serviceCharge?.percentDiscount ?? 0,
            DiscountTypeEnum.Percent
          )}%)`}
          value={ticket?.serviceCharge?.moneyDiscount}
        />
      ) : (
        <></>
      )}
      <Divider dashed className="salon__print-bill-strike" />

      {/* total payment, deposit, redeeming */}
      <BillItem title={`${intl.formatMessage({ id: 'print.bill.totalPayment' })}`} value={ticket?.totalPayment} />
      {ticket?.deposit && ticket?.deposit > 0 ? (
        <BillItem title={`${intl.formatMessage({ id: 'print.bill.deposit' })}`} value={ticket?.deposit} />
      ) : (
        <></>
      )}

      {timePayment === TimePayment.BEFORE ? (
        <>
          <BillItem
            title={`${intl.formatMessage({ id: 'print.bill.tip' })}`}
            isSelfFill={timePayment === TimePayment.BEFORE}
          />
        </>
      ) : (
        <></>
      )}

      {/* Balance to pay */}
      <Divider dashed className="salon__print-bill-strike" />
      <BillItem
        title={`${intl.formatMessage({ id: 'print.bill.balanceToPay' })}`}
        value={Number(ticket?.totalPayment || 0) < 0 ? 0 : Number(ticket?.totalPayment || 0)}
        isSelfFill={timePayment === TimePayment.BEFORE}
      />

      {/* rewarding */}
      {timePayment === TimePayment.AFTER &&
      ticket?.customer &&
      listPromotion &&
      listPromotion.loyaltyReward &&
      listPromotion.loyaltyReward.reward &&
      listPromotion.loyaltyReward.loyaltyReward &&
      ticket?.totalPayment > +listPromotion.loyaltyReward.saleTicket ? (
        <>
          <Divider dashed className="salon__print-bill-strike" />
          <BillItem
            title={`${intl.formatMessage({ id: 'print.bill.rewarding' })}`}
            ptsReward={intl.formatMessage(
              { id: 'print.bill.pointRewardValue' },
              { points: ticket?.rewarding, price: formatNumberThousandWithDecimal(ticket?.totalPayment || 0) }
            )}
          />
        </>
      ) : (
        <></>
      )}

      {/* visa card */}
      {timePayment === TimePayment.AFTER ? (
        <>
          <Divider dashed className="salon__print-bill-strike" />
          {ticket?.ticketTransaction?.map((item) => (
            <BillItem key={item.id} title={item.typePayment.replace(/_/g, ' ').trim()} value={+item.money} />
          ))}
        </>
      ) : (
        <></>
      )}

      {/*  */}
      {timePayment === TimePayment.AFTER ? <Divider dashed className="salon__print-bill-strike m-b-4" /> : <></>}
      <Divider dashed className="salon__print-bill-strike m-t-4" />
      <div className="m-t-8 height-400">
        <p className="salon__print-bill-privacy">{intl.formatMessage({ id: 'print.bill.privacy' })}</p>
        <p className="salon__print-bill-sign-here">{intl.formatMessage({ id: 'print.bill.sign' })}</p>
      </div>

      {paymentInformation && timePayment === TimePayment.AFTER ? (
        <>
          <Divider dashed className="salon__print-bill-strike m-b-4" />
          <Divider dashed className="salon__print-bill-strike m-t-4" />

          <RowWrap isAutoFillRow={true} isGutter gutter={[0, 4]} isWrap styleFill="between">
            {paymentInformation ? (
              <ColWrap
                className="p-r-0"
                colProps={{
                  span: 24,
                }}
              >
                <p className="salon__print-bill-detail">
                  {paymentInformation?.cardTransaction?.cardType}: {paymentInformation?.cardTransaction?.last4}
                </p>
              </ColWrap>
            ) : (
              <></>
            )}
            <ColWrap
              colProps={{
                span: 24,
              }}
              className="p-0"
            >
              <p className="salon__print-bill-detail">
                {intl.formatMessage(
                  { id: 'print.bill.entry' },
                  { entry: paymentInformation?.cardTransaction.entryType }
                )}
              </p>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 24,
              }}
              className="p-l-0"
            >
              <p className="salon__print-bill-detail">
                {intl.formatMessage(
                  { id: 'print.bill.transId' },
                  { id: paymentInformation?.cardTransaction.transactionNo }
                )}
              </p>
            </ColWrap>
          </RowWrap>
          <RowWrap isAutoFillRow={true} isGutter={true} isWrap={false} styleFill="start">
            <ColWrap
              className="p-r-0"
              colProps={{
                span: 12,
              }}
            >
              <p className="salon__print-bill-detail">
                {intl.formatMessage({ id: 'print.bill.auth' }, { code: paymentInformation?.cardTransaction.authCode })}
              </p>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
              className="p-0"
            >
              <p className="salon__print-bill-detail">
                {intl.formatMessage({ id: 'print.bill.batch' }, { batch: '0' })}
              </p>
            </ColWrap>
          </RowWrap>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default forwardRef<HTMLDivElement | null, PrintBillProps>(PrintBill);
