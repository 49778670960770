import React from 'react';

export const SvgDropIcon = () => (
  <span>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.27082 7.49998L10.5042 10.7333L13.7375 7.49998C14.0625 7.17498 14.5875 7.17498 14.9125 7.49998C15.2375 7.82498 15.2375 8.34998 14.9125 8.67498L11.0875 12.5C10.7625 12.825 10.2375 12.825 9.91249 12.5L6.08749 8.67498C5.93144 8.51928 5.84375 8.30791 5.84375 8.08748C5.84375 7.86704 5.93144 7.65567 6.08749 7.49998C6.41249 7.18331 6.94582 7.17498 7.27082 7.49998Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
