import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementStyle } from '@stripe/stripe-js';
import { Form, FormInstance } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CardNumberEnum } from '..';
import { IAppointmentBill, IListIdTotalPrice } from '../../../..';
import { CreateChargeDto, SettingCheckout } from '../../../../../../../apis/client-axios';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInput } from '../../../../../../../components/Form/FormInput';
import { FormSelect } from '../../../../../../../components/Form/FormSelect';
import FormWrap from '../../../../../../../components/FormWrap';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../../../components/RowWrap';
import { emailChecker, formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { US_States_and_Cities } from '../../../../../../../utils/USStatesAndCities';

interface IProps {
  setCurrentScreen: React.Dispatch<React.SetStateAction<CardNumberEnum>>;
  listTotal: IListIdTotalPrice;
  settingCheckout?: SettingCheckout;
  form: FormInstance<CardNumberFormData>;
  appointmentBill: IAppointmentBill;
  onChangeKeyInCard: (values: CreateChargeDto) => void;
  onChangeLoading: (value: boolean) => void;
}

export interface CardNumberFormData {
  email?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  address?: string;
}

const n = (key: keyof CardNumberFormData) => key;

const style: StripeElementStyle = {
  base: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#292f33',
    lineHeight: '20px',
    '::placeholder': {
      color: '#B4B6B8',
    },
  },
  invalid: {
    color: '#292f33',
  },
};

export const SecurityCodeCardNumber: React.FC<IProps> = (props) => {
  const { setCurrentScreen, listTotal, settingCheckout, form, appointmentBill, onChangeKeyInCard, onChangeLoading } =
    props;

  const intl = useIntl();
  const state = Form.useWatch(n('state'), form);

  const stripe = useStripe();
  const elements = useElements();

  const cityOptions = useMemo(() => {
    if (state) {
      return US_States_and_Cities.find((item) => item.state === state)?.city.map((city) => ({
        label: city.name,
        value: city.name,
      }));
    }

    return US_States_and_Cities.flatMap((state) => state.city).map((city) => ({
      label: city.name,
      value: city.name,
    }));
  }, [state]);

  const onFinish = (value: CardNumberFormData) => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement('cardNumber');

    if (!cardNumberElement) return;
    stripe
      .createToken(cardNumberElement, { currency: 'usd' })
      .then((payload) => {
        onChangeLoading(true);

        if (payload.error) {
          return NotificationError({
            contentNoti: intl.formatMessage({ id: 'onlineAppointment.paymentInformation.error' }),
          });
        }

        if (payload.token) {
          const { customer } = appointmentBill;
          const { address, city, email, state, zipCode } = value;

          onChangeKeyInCard({
            cardToken: payload.token.id,
            amount: 0,
            customerName: customer?.name || '',
            customerPhoneNumber: customer?.phoneNumber || '',
            address,
            city,
            state,
            zipCode,
            email,
          });

          setCurrentScreen(CardNumberEnum.CardPayment);
        }
      })
      .finally(() => {
        onChangeLoading(false);
      });
  };

  const onFinishFailed = () => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement('cardNumber');

    if (!cardNumberElement) return;

    stripe.createToken(cardNumberElement, { currency: 'usd' });

    NotificationError({
      contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.noti.incorrectCardInformation' }),
    });
  };

  return (
    <FormWrap
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="salon__checkout__pay-n-complete-modal__cash-payment"
      layout="vertical"
    >
      <RowWrap isAutoFillRow isGutter gutter={[0, 20]} isWrap styleFill="between" className="salon__form-stripe-card">
        <ColWrap colProps={{ span: 24 }}>
          <p className="font-size-16 font-weight-500 m-0">
            {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
            <span className="font-weight-700">
              ${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}
            </span>
          </p>
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <div className="height-35">
            <label className="salon__form-stripe-card-label">
              {
                <span className="font-size-16 font-weight-500 salon__label-required">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.cashID.label' })}
                </span>
              }
            </label>
          </div>
          <CardNumberElement
            options={{
              showIcon: true,
              style,
              placeholder: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.cashID.placeHolder' }),
            }}
          />
        </ColWrap>

        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow isGutter gutter={[20, 0]} isWrap styleFill="between">
            <ColWrap colProps={{ span: 12 }}>
              <div className="height-35">
                <label className="salon__form-stripe-card-label">
                  {
                    <span className="font-size-16 font-weight-500 salon__label-required">
                      {intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.expirationYear.label' })}
                    </span>
                  }
                </label>
              </div>
              <CardExpiryElement
                options={{
                  style,
                  placeholder: intl.formatMessage({
                    id: 'checkout.payAndComplete.securityCode.expirationYear.placeHolder',
                  }),
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 12 }}>
              <div className="height-35">
                <label className="salon__form-stripe-card-label">
                  {
                    <span className="font-size-16 font-weight-500 salon__label-required">
                      {intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.securityCode.label' })}
                    </span>
                  }
                </label>
              </div>
              <CardCvcElement
                options={{
                  style,
                  placeholder: intl.formatMessage({
                    id: 'checkout.payAndComplete.securityCode.securityCode.placeHolder',
                  }),
                }}
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>

        {settingCheckout?.askForEmailAddress && (
          <ColWrap colProps={{ span: 24 }}>
            <FormInput
              name={n('email')}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.email.label' })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'checkout.payAndComplete.securityCode.email.placeHolder',
                }),
                className: 'm-b-0',
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                rules: [
                  { required: true, message: '' },
                  { whitespace: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();

                      if (v && !emailChecker(v)) {
                        return Promise.reject('');
                      }

                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>
        )}

        <ColWrap colProps={{ span: 24 }}>
          {settingCheckout?.askForBillingAddress && (
            <RowWrap
              isAutoFillRow={true}
              isGutter={true}
              gutter={[20, 20]}
              isWrap={true}
              styleFill="between"
              className="m-b-20"
            >
              <ColWrap colProps={{ span: 8 }}>
                <FormSelect
                  name={n('city')}
                  selectProps={{
                    options: cityOptions,
                    placeholder: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.city.placeHolder' }),
                    allowClear: false,
                  }}
                  label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.city.label' })}
                  formItemProps={{
                    className: 'salon__setting-business-modal-form-input m-b-0',
                    rules: [{ required: true, message: '' }],
                  }}
                />
              </ColWrap>

              <ColWrap colProps={{ span: 8 }}>
                <FormSelect
                  name={n('state')}
                  label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.state.label' })}
                  selectProps={{
                    options: US_States_and_Cities.map((item) => {
                      return {
                        value: item.state,
                        label: item.state,
                      };
                    }),
                    placeholder: intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.state.placeHolder' }),
                    className: 'm-b-0',
                    onSelect: () => {
                      form.setFieldValue(n('city'), null);
                    },
                  }}
                  formItemProps={{
                    className: 'm-b-0 w-full',
                    rules: [{ required: true, message: '' }],
                  }}
                />
              </ColWrap>

              <ColWrap colProps={{ span: 8 }}>
                <FormInput
                  name={n('zipCode')}
                  label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.zipCode.label' })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: 'checkout.payAndComplete.securityCode.zipCode.placeHolder',
                    }),
                    className: 'm-b-0',
                    maxLength: 5,
                  }}
                  formItemProps={{
                    className: 'm-b-0 w-full',
                    rules: [
                      { required: true, message: '' },
                      { whitespace: true, message: '' },
                      { len: 5, message: '' },
                    ],
                  }}
                />
              </ColWrap>

              <ColWrap colProps={{ span: 24 }}>
                <FormInput
                  name={n('address')}
                  label={intl.formatMessage({ id: 'checkout.payAndComplete.securityCode.address.label' })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: 'checkout.payAndComplete.securityCode.address.placeHolder',
                    }),
                    className: 'm-b-0',
                  }}
                  formItemProps={{
                    className: 'm-b-0 w-full',
                    rules: [
                      { required: true, message: '' },
                      { whitespace: true, message: '' },
                    ],
                  }}
                />
              </ColWrap>
            </RowWrap>
          )}
        </ColWrap>
      </RowWrap>
    </FormWrap>
  );
};
