import React from 'react';
import RowWrap from '../../../../../../components/RowWrap';
import ColWrap from '../../../../../../components/ColWrap';
import { useIntl } from 'react-intl';
import { Customer, Promotion, ServiceItems } from '../../../../../../apis/client-axios';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../../../checkoutConstants';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import dayjs from 'dayjs';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { toDayjsTimeZone } from '../../../../../../utils';

interface IProps {
  promotion?: Promotion;
  customer?: Customer;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  setDiscountsAndRewardsApplied: React.Dispatch<React.SetStateAction<IDiscountsAndRewardsApplied | undefined>>;
  confirmAddDiscountsAndRewards: (
    onOKButton?: () => void,
    onCancelButton?: () => void,
    isMerchandise?: boolean
  ) => void;
  onCancelModal: () => void;
  onClickAddNewCustomer: () => void;
  listServiceItems: ServiceItems[];
}

export const BirthdayDiscount: React.FC<IProps> = (props) => {
  const {
    promotion,
    customer,
    discountsAndRewardsApplied,
    setDiscountsAndRewardsApplied,
    confirmAddDiscountsAndRewards,
    onCancelModal,
    onClickAddNewCustomer,
    listServiceItems,
  } = props;
  const intl = useIntl();

  const isWithinBirthdayReward = () => {
    const dayjsCurrent = toDayjsTimeZone();
    const currentYear = toDayjsTimeZone().get('year');
    const dayjsCustomerBirthday = customer?.birthDay
      ? toDayjsTimeZone(customer?.birthDay).endOf('day').set('year', currentYear)
      : undefined;
    // console.log('promotion', {
    //   birthday: promotion?.birthdayReward?.eligiblePeriod,
    //   dayjsCustomerBirthday: dayjsCustomerBirthday,
    //   dayjsCustomerBirthdayFormat: dayjsCustomerBirthday?.format('DD/MM/YYYY HH:mm'),
    //   dayjsCurrent: dayjsCurrent,
    //   dayjsCurrentFormat: dayjsCurrent.format('DD/MM/YYYY HH:mm'),
    //   dayjsSubtractFormat: dayjsCustomerBirthday
    //     ?.subtract(promotion?.birthdayReward?.eligiblePeriod || 0, 'day')
    //     .startOf('day')
    //     .format('DD/MM/YYYY HH:mm'),
    //   isBetween: dayjsCurrent.isBetween(
    //     dayjsCustomerBirthday?.subtract(promotion?.birthdayReward?.eligiblePeriod || 0, 'day').startOf('day'),
    //     dayjsCustomerBirthday,
    //     'day',
    //     '[]'
    //   ),
    // });
    return !promotion?.birthdayReward?.eligiblePeriod
      ? true
      : dayjsCustomerBirthday
      ? !!dayjsCurrent.isBetween(
          dayjsCustomerBirthday.subtract(promotion?.birthdayReward?.eligiblePeriod || 0, 'day').startOf('day'),
          dayjsCustomerBirthday,
          'day',
          '[]'
        )
      : false;
  };

  const onClickApplyBirthday = async () => {
    if (discountsAndRewardsApplied?.birthdayDiscount) {
      setDiscountsAndRewardsApplied((prev) => ({
        ...prev,
        birthdayDiscount: undefined,
      }));
    } else if (customer && promotion?.birthdayReward?.birthdayReward) {
      const handleSetDiscountsAndRewards = () => {
        setDiscountsAndRewardsApplied((prev) => ({
          ...prev,
          birthdayDiscount: {
            amount: promotion.birthdayReward.discountPrecent || promotion.birthdayReward.discountAmount,
            serviceItemIds: promotion.birthdayReward.allService
              ? listServiceItems.map((e) => e.id)
              : promotion.birthdayReward.discountServices,
            type: promotion.birthdayReward.discountPrecent ? DiscountTypeEnum.Percent : DiscountTypeEnum.Dollar,
          },
        }));
        onCancelModal();
      };
      confirmAddDiscountsAndRewards(handleSetDiscountsAndRewards);
    }
  };

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={[20, 12]}
      isWrap={true}
      styleFill="between"
      className="border-bottom p-t-b-20"
    >
      <ColWrap colProps={{ span: 24 }}>
        <span className="salon__checkout-discount-title">
          {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.2' })}
          {(!promotion?.birthdayReward?.birthdayReward ||
            (!promotion?.birthdayReward.discountServices?.length && !promotion?.birthdayReward.allService)) && (
            <span className="salon__checkout-discount-not-available m-l-17">
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.available' })}
            </span>
          )}
        </span>
        {promotion?.birthdayReward?.birthdayReward && (
          <>
            <br />
            <span className="salon__checkout-discount-title-sub">
              {/* {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.title.sub.1' })} */}
              {promotion?.birthdayReward?.discountPrecent || promotion?.birthdayReward?.discountAmount
                ? promotion?.birthdayReward?.discountPrecent
                  ? `${Number(promotion?.birthdayReward?.discountPrecent)}%`
                  : `${promotion?.birthdayReward?.discountAmount}$`
                : `0%`}
              &nbsp;
              {promotion?.birthdayReward?.eligiblePeriod ? (
                <>
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.birthday.10%OffWithin' })}
                  &nbsp;{promotion?.birthdayReward?.eligiblePeriod}&nbsp;
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.birthday.daysBeforeBirthday' })}
                </>
              ) : (
                intl.formatMessage({ id: 'promotion.checkinReward.redeemOptions.off' })
              )}
              <br />
              {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.applicableForServices' })}&nbsp;
              {promotion?.birthdayReward?.allService
                ? intl.formatMessage({ id: 'checkout.discountAndRewards.allServicesAndMerchandise' })
                : !!promotion?.birthdayReward?.discountServices?.length && !!listServiceItems.length
                ? promotion?.birthdayReward.discountServices
                    .map((serviceItem) => listServiceItems.find((si) => si.id === serviceItem)?.name)
                    .join(', ')
                : '...'}
            </span>
          </>
        )}
      </ColWrap>
      {promotion?.birthdayReward?.birthdayReward ? (
        !customer ? (
          <>
            <ColWrap colProps={{ span: 24 }}>
              <div className="d-flex gap-20 align-items-center">
                <CustomButton
                  type="link"
                  buttonProps={{
                    className: 'salon__checkout-discount-button p-0 m-0 height-unset border-none',
                    onClick: onClickAddNewCustomer,
                  }}
                  content={intl.formatMessage({ id: 'checkout.discountAndRewards.add.text' })}
                />
                <span className="salon__checkout-discount-des">
                  {' '}
                  {intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.des.2' })}
                </span>
              </div>
            </ColWrap>
          </>
        ) : (
          <ColWrap colProps={{ span: 24 }}>
            {!isWithinBirthdayReward() && !!promotion?.birthdayReward.discountServices?.length && (
              <p className="color-EB4C3B font-style-italic font-size-14 font-weight-400 line-height-20 m-b-12">
                {intl.formatMessage({
                  id: 'checkout.discountAndRewards.discounts.birthday.customerBirthdayDoesNotFall',
                })}
              </p>
            )}
            <ButtonStyled
              content={
                !discountsAndRewardsApplied?.birthdayDiscount
                  ? isWithinBirthdayReward()
                    ? intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })
                    : intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.applyAnyway' })
                  : intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.applied' })
              }
              buttonProps={{
                className: `${
                  isWithinBirthdayReward() ? 'width-124' : 'width-158'
                } height-38 salon__checkout-discount-rewards-button-3${
                  discountsAndRewardsApplied?.birthdayDiscount ? ' salon__checkout-discount-rewards-applied-btn' : ''
                }`,
                disabled:
                  !promotion?.birthdayReward?.birthdayReward ||
                  (!promotion?.birthdayReward.discountServices?.length && !promotion?.birthdayReward.allService),
                onClick: onClickApplyBirthday,
              }}
              isPrimary={true}
            />
          </ColWrap>
        )
      ) : (
        <ColWrap colProps={{ span: 24 }}>
          <ButtonStyled
            content={intl.formatMessage({ id: 'checkout.discountAndRewards.discounts.button.apply' })}
            buttonProps={{
              className: 'width-124 height-38 salon__checkout-discount-rewards-button-3',
              disabled: true,
            }}
            isPrimary={true}
          />
        </ColWrap>
      )}
    </RowWrap>
  );
};
