import * as clover from 'remote-pay-cloud';
import {
  CloverBuildConnection,
  CloverConnect,
  CloverOpenCashDrawer,
  CloverPerformPrint,
  CloverPerformRefund,
  CloverPerformSale,
} from './@types';

export const cloverConnection = (connector?: clover.remotepay.ICloverConnector | null) => {
  let cloverConnector: clover.remotepay.ICloverConnector | undefined | null = connector;
  let saleRequest: clover.remotepay.SaleRequest | null = null;
  // const displayItems: clover.order.DisplayLineItem[] = [];

  // const tipSuggestion1 = new clover.merchant.TipSuggestion();
  // tipSuggestion1.setIsEnabled(true);
  // tipSuggestion1.setName('Good');
  // tipSuggestion1.setPercentage(5);

  // const tipSuggestion2 = new clover.merchant.TipSuggestion();
  // tipSuggestion2.setIsEnabled(true);
  // tipSuggestion2.setName('Great');
  // tipSuggestion2.setPercentage(10);

  // const tipSuggestion3 = new clover.merchant.TipSuggestion();
  // tipSuggestion3.setIsEnabled(true);
  // tipSuggestion3.setName('Wow!');
  // tipSuggestion3.setPercentage(15);

  // const tipSuggestion4 = new clover.merchant.TipSuggestion();
  // tipSuggestion4.setIsEnabled(true);
  // tipSuggestion4.setName('Best Service Ever!');
  // tipSuggestion4.setPercentage(20);

  const cleanUpConnector = () => {
    if (cloverConnector) cloverConnector.dispose();
  };

  const getDeviceConfiguration = (props: CloverConnect) => {
    const { connectionConfiguration, onPairingCode: onPaireCode, onPairingCodeSuccess } = props;

    const onPairingCode = (pairingCode: string) => {
      if (onPaireCode) onPaireCode(pairingCode);
    };

    const onPairingSuccess = (authTokenFromPairing: string) => {
      if (onPairingCodeSuccess) onPairingCodeSuccess(authTokenFromPairing);
    };

    const configBuilder: clover.WebSocketPairedCloverDeviceConfigurationBuilder =
      new clover.WebSocketPairedCloverDeviceConfigurationBuilder(
        connectionConfiguration.applicationId,
        connectionConfiguration.endpoint,
        connectionConfiguration.posName,
        connectionConfiguration.serialNumber,
        connectionConfiguration.authToken,
        onPairingCode,
        onPairingSuccess
      );

    // return configBuilder.setHeartbeatInterval(1000).setReconnectDelay(3000).build();
    return configBuilder.build();
  };

  const buildCloverConnectionListener = (props?: CloverBuildConnection) => {
    return Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {
      //Payment
      onSaleResponse: (response: clover.remotepay.SaleResponse) => {
        const requestAmount = saleRequest?.getAmount();
        saleRequest = null;

        if (props?.onSaleResponse) props?.onSaleResponse(response);

        if (response.getSuccess()) {
          const payment = response.getPayment();
          if (payment && payment.getAmount() < Number(requestAmount)) {
            const voidPaymentRequest = new clover.remotepay.VoidPaymentRequest();
            voidPaymentRequest.setPaymentId(payment.getId());
            voidPaymentRequest.setVoidReason(clover.order.VoidReason.REJECT_PARTIAL_AUTH);

            cloverConnector?.voidPayment(voidPaymentRequest);
          } else {
            if (!response.getIsSale()) return;
          }
        }
      },

      onConfirmPaymentRequest: (request: clover.remotepay.ConfirmPaymentRequest) => {
        cloverConnector?.acceptPayment(request.getPayment());
      },

      //Signature
      onVerifySignatureRequest: (request: clover.remotepay.VerifySignatureRequest) => {
        cloverConnector?.acceptSignature(request);
      },

      //Communication
      onDeviceReady: (merchantInfo: clover.remotepay.MerchantInfo) => {
        if (props?.setCloverConnector) props.setCloverConnector(cloverConnector);
        if (props?.onDeviceReady) props.onDeviceReady(merchantInfo);
      },

      onDeviceError: (error: clover.remotepay.CloverDeviceErrorEvent) => {
        if (props?.onDeviceError) props?.onDeviceError(error);
      },

      onDeviceDisconnected: () => {
        if (props?.onDeviceDisconnected) props?.onDeviceDisconnected();
      },

      onDeviceActivityStart: (event: clover.remotepay.CloverDeviceEvent) => {
        if (props?.onDeviceActivityStart) props?.onDeviceActivityStart(event);
      },

      onDeviceActivityEnd: (event: clover.remotepay.CloverDeviceEvent) => {
        if (props?.onDeviceActivityEnd) props?.onDeviceActivityEnd(event);
      },

      //Refund
      onManualRefundResponse: (response: clover.remotepay.ManualRefundResponse) => {
        if (props?.onManualRefundResponse) props?.onManualRefundResponse(response);
      },

      //Print
      onPrintJobStatusResponse: (response: clover.remotepay.PrintJobStatusResponse) => {
        // the response contains the print job identifier and status
        if (props?.onPrintJobStatusResponse) props.onPrintJobStatusResponse(response);
      },

      //Reset Device
      onResetDeviceResponse: (response: clover.remotepay.ResetDeviceResponse) => {
        if (props?.onResetDeviceResponse) props.onResetDeviceResponse(response);
      },
    });
  };

  // const addService = (props: CloverDisplayService) => {
  //   const { id, price, title } = props;

  //   const lineItem = new clover.order.DisplayLineItem();
  //   lineItem.setId(id.toString());
  //   lineItem.setName(title);
  //   lineItem.setPrice(`$${price}`);
  //   lineItem.setQuantity('1');

  //   displayItems.push(lineItem);
  // };

  // const displayService = (props: CloverDisplayService) => {
  //   const { total, tax, subTotal } = props;

  //   addService(props);

  //   const displayOrder = new clover.order.DisplayOrder();
  //   displayOrder.setLineItems(displayItems);
  //   displayOrder.setTotal(`$${handleTruncateToTwoDecimal(total)}`);
  //   displayOrder.setSubtotal(`$${handleTruncateToTwoDecimal(subTotal)}`);
  //   displayOrder.setTax(`$${handleTruncateToTwoDecimal(tax || 0)}`);

  //   cloverConnector?.showDisplayOrder(displayOrder);
  // };

  const connect = (props: CloverConnect) => {
    const {
      onDeviceDisconnected,
      onDeviceError,
      onDeviceReady,
      onDeviceActivityStart,
      onDeviceActivityEnd,
      onSaleResponse,
      onManualRefundResponse,
      onPrintJobStatusResponse,
      onResetDeviceResponse,
      setCloverConnector,
    } = props;

    if (cloverConnector) return;

    cleanUpConnector();

    const cloverDeviceConnectionConfiguration = getDeviceConfiguration(props);

    const builderConfiguration = {
      [clover.CloverConnectorFactoryBuilder.FACTORY_VERSION]: clover.CloverConnectorFactoryBuilder.VERSION_12,
    };

    const cloverConnectorFactory =
      clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration);

    cloverConnector = cloverConnectorFactory.createICloverConnector(cloverDeviceConnectionConfiguration);
    cloverConnector.addCloverConnectorListener(
      buildCloverConnectionListener({
        onDeviceDisconnected,
        onDeviceError,
        onDeviceReady,
        onDeviceActivityStart,
        onDeviceActivityEnd,
        onSaleResponse,
        onManualRefundResponse,
        onPrintJobStatusResponse,
        onResetDeviceResponse,
        setCloverConnector,
      })
    );
    cloverConnector.initializeConnection();
  };

  const performSale = (props: CloverPerformSale) => {
    const { amount, tipMode, isDisablePrint, isDisableDeviceReceiptOptionsScreen, signatureMode, tippableAmount } =
      props;

    if (!saleRequest) {
      saleRequest = new clover.remotepay.SaleRequest();
      //id
      saleRequest.setExternalId(clover.CloverID.getNewId());

      //balance to pay
      saleRequest.setAmount(amount * 100);
      // saleRequest.setTipAmount(tip * 100);

      if (tippableAmount > 0) {
        saleRequest.setTippableAmount(tippableAmount * 100);
      }

      //others option
      saleRequest.setAutoAcceptPaymentConfirmations(true);
      saleRequest.setApproveOfflinePaymentWithoutPrompt(false);
      saleRequest.setDisableDuplicateChecking(true);

      //payment method
      saleRequest.setCardEntryMethods(clover.CardEntryMethods.ALL);

      //signature
      saleRequest.setAutoAcceptSignature(true);
      saleRequest.setSignatureEntryLocation(signatureMode);
      saleRequest.setSignatureThreshold(0);

      //print receipt after paying successful
      saleRequest.setDisablePrinting(isDisablePrint);
      saleRequest.setDisableReceiptSelection(isDisableDeviceReceiptOptionsScreen);

      //tip mode
      saleRequest.setTipMode(tipMode);

      // if (tipSuggestions) {
      //   const suggestions = tipSuggestions.map((suggest) => {
      //     const tipSuggestion = new clover.merchant.TipSuggestion();
      //     tipSuggestion.setIsEnabled(suggest.enabled);
      //     tipSuggestion.setName(suggest.name);
      //     tipSuggestion.setPercentage(suggest.percent);
      //     return tipSuggestion;
      //   });

      //   saleRequest?.setTipSuggestions(suggestions);
      // }

      if (cloverConnector) {
        cloverConnector.sale(saleRequest);
      }
    }
  };

  const performRefund = (props: CloverPerformRefund) => {
    const { amount } = props;

    const request = new clover.remotepay.ManualRefundRequest();
    //id
    request.setExternalId(clover.CloverID.getNewId());

    //amount
    request.setAmount(amount * 100);

    //refund method
    request.setCardEntryMethods(clover.CardEntryMethods.ALL);

    if (cloverConnector) {
      cloverConnector.manualRefund(request);
    }
  };

  const performOpenCashDrawer = (props: CloverOpenCashDrawer) => {
    const { reason } = props;

    const ocdr = new clover.remotepay.OpenCashDrawerRequest();
    ocdr.setReason(reason);

    if (cloverConnector) {
      cloverConnector.openCashDrawer(ocdr);
    }
  };

  const performPrint = (props: CloverPerformPrint) => {
    const { base64Image } = props;

    const printRequest = new clover.remotepay.PrintRequest();

    const printImage: any[] = [];

    if (Array.isArray(base64Image)) {
      base64Image.forEach((imgString) => {
        const image = new Image();
        image.src = imgString;

        printImage.push(image);
      });
    } else {
      const image = new Image();
      image.src = base64Image;
      printImage.push(image);
    }

    printRequest.setImages(printImage);

    if (cloverConnector) {
      cloverConnector.print(printRequest);
    }
  };

  const performPrintText = (printText: string) => {
    const printRequest = new clover.remotepay.PrintRequest();

    printRequest.setText([printText]);

    if (cloverConnector) {
      cloverConnector.print(printRequest);
    }
  };

  const performResetDevice = () => {
    if (cloverConnector) cloverConnector.resetDevice();
  };

  const performShowWelcomScreen = () => {
    if (cloverConnector) cloverConnector.showWelcomeScreen();
  };

  const performShowThankYouScreen = () => {
    if (cloverConnector) cloverConnector.showThankYouScreen();
  };

  const performCloseout = () => {
    const closeoutRequest = new clover.remotepay.CloseoutRequest();

    closeoutRequest.setAllowOpenTabs(false);
    closeoutRequest.setBatchId(null as any);

    if (cloverConnector) {
      cloverConnector.closeout(closeoutRequest);
    }
  };

  return {
    connect,
    performSale,
    performRefund,
    performOpenCashDrawer,
    performPrint,
    performResetDevice,
    performShowWelcomScreen,
    performShowThankYouScreen,
    performPrintText,
    performCloseout,
  };
};
