import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgPercentIcon } from '../../../../components/@svg/SvgPercentIcon';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IServiceCharge } from '../index';
import './style.scss';
import { SettingCheckout } from '../../../../apis/client-axios';
import { formatNumberThousandWithDecimal } from '../../../../utils';
import KeyboardWrapper, { KeyboardDirection } from '../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';

interface Props {
  open: boolean;
  feeName: string;
  serviceChargePercent: number;
  serviceCharge: IServiceCharge;
  autoOption: number;
  setTaxPercent: (taxPercent: number) => void;
  setServiceCharge: (serviceCharge: IServiceCharge) => void;
  onCancelModal: () => void;
  settingCheckout?: SettingCheckout;
}

interface FormData {
  supply?: number;
}
enum InputNameEnum {
  supply = 'supply',
}
const n = (key: keyof FormData) => key;

function TaxAndDeleteTicket(props: Props) {
  const {
    open,
    feeName,
    serviceChargePercent,
    serviceCharge,
    autoOption,
    onCancelModal,
    setTaxPercent,
    setServiceCharge,
    settingCheckout,
  } = props;
  const [saveServiceCharge, setSaveServiceCharge] = useState<IServiceCharge>({
    chargeValue: 0,
    name: '',
  });
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [flagSaveValue, setFlagSaveValue] = useState<boolean>(false);
  const intl = useIntl();

  const [form] = Form.useForm();
  const supplyRef = useRef<HTMLInputElement>(null);
  const supply = Form.useWatch(n('supply'), form);

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum | undefined>(InputNameEnum.supply);

  useEffect(() => {
    if (supply && supplyRef.current) {
      const [integer] = supply.replace(/,/g, '').split('.');
      supplyRef.current.selectionStart = supplyRef.current.selectionEnd = supply.length + Math.floor(integer / 3);
    }
  }, [supply]);

  const onFinish = () => {
    if (!flagSaveValue) {
      const tax = form.getFieldValue('supply') ?? 0;
      setTaxPercent(tax);
      form.resetFields();
    } else {
      setServiceCharge({
        chargeValue: saveServiceCharge.chargeValue,
        name: saveServiceCharge.name,
      });
    }
    onCancelModal();
  };
  useEffect(() => {
    if (
      (+serviceCharge.chargeValue === 0 || +serviceCharge.chargeValue > 0) &&
      +autoOption &&
      +serviceCharge.chargeValue !== +autoOption
    ) {
      setIsDisableButton(true);
    } else {
      if (+serviceCharge.chargeValue === +serviceChargePercent) {
        setIsDisableButton(true);
      } else {
        setIsDisableButton(false);
      }
    }
  }, [serviceCharge]);

  useEffect(() => {
    if (settingCheckout?.inputingServiceSupply && settingCheckout?.supplyCost) {
      form.setFieldValue(n('supply'), formatNumberThousandWithDecimal(settingCheckout.saleTax));
    }
  }, [settingCheckout]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-tax-ticket-modal-heading">
            {intl.formatMessage({ id: 'checkout.tax.ticket' })}
          </div>
        ),
        className: 'salon__checkout-tax-ticket-modal',
        width: 700,
        footer: null,
        // (
        //   <div className="d-flex justify-content-end align-items-center">
        //     <div className="d-flex gap-12 align-items-center">
        //       <ButtonStyled
        //         content={intl.formatMessage({ id: 'checkout.tax.ticket.add' })}
        //         buttonProps={{
        //           className: 'width-100 height-38',
        //           onClick: onFinish,
        //         }}
        //         isPrimary={true}
        //       />
        //     </div>
        //   </div>
        // ),
      }}
    >
      <FormWrap
        form={form}
        name="taxCheckout"
        layout="vertical"
        className="w-100 salon__checkout-tax-ticket-modal-form p-t-10"
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('supply')}
              label={
                <span className="salon__checkout-tax-ticket-modal-title">
                  {intl.formatMessage({ id: 'checkout.tax.ticket.title' })}
                </span>
              }
              suffix={<SvgPercentIcon />}
              numericFormatProps={{
                inputMode: 'none',
                allowNegative: false,
                min: 0,
                max: 100,
                placeholder: intl.formatMessage({ id: 'checkout.tax.ticket.placeholder' }),
                className: 'salon__checkout-tax-ticket-modal-form-input',
                onChange: (e) => {
                  const currentValue = Number(e.target.value.replace(/,/g, ''));
                  if (currentValue <= 0) return;
                  form.setFieldValue('supply', currentValue);
                },
                onBlur: () => {
                  setFlagSaveValue(false);
                },
                onFocus: () => setFocusedInputName(InputNameEnum.supply),

                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    onFinish();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
                getInputRef: supplyRef,
              }}
              formItemProps={{
                className: 'm-b-0',
                rules: [
                  {
                    transform(value) {
                      return value?.trim();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          <div>
            <span className="salon__checkout-tax-ticket-modal-text">{feeName}</span>
          </div>
          <ColWrap colProps={{ span: 24 }}>
            <ButtonStyled
              content={<span className="salon__checkout-tax-ticket-modal-text">{+serviceChargePercent}%</span>}
              buttonProps={{
                className: `width-80 height-38 m-t-b-12 ${
                  isDisableButton ? 'salon__checkout-tax-ticket-modal-disable-btn' : ''
                }`,
                onClick: () => {
                  if (serviceCharge.chargeValue === serviceChargePercent) {
                    setIsDisableButton(false);
                    setServiceCharge({
                      chargeValue: -1,
                      name: '',
                      // chargeValue: autoOption as number, enable if want to return auto option value
                      // name: feeName,
                    });
                    onCancelModal();
                  } else {
                    setIsDisableButton(true);
                    setSaveServiceCharge({
                      chargeValue: serviceChargePercent,
                      name: feeName,
                    });
                  }
                  setFlagSaveValue(true);
                },
              }}
              isLarge={false}
              isPrimary={true}
              isButtonCancel={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-merchandise-modal-keyboard-button-text">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                onClick: () => {
                  onFinish();
                },
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default TaxAndDeleteTicket;
