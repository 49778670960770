import React from 'react';

export const SvgDollarPreviewIconSmall = () => (
  <span>
    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.12 3.51111C1.212 3.24889 0.92 2.97778 0.92 2.55556C0.92 2.07111 1.324 1.73333 2 1.73333C2.568 1.73333 2.852 1.97333 2.956 2.35556C3.004 2.53333 3.136 2.66667 3.304 2.66667H3.424C3.688 2.66667 3.876 2.37778 3.784 2.10222C3.616 1.57778 3.224 1.14222 2.6 0.973333V0.666667C2.6 0.297778 2.332 0 2 0C1.668 0 1.4 0.297778 1.4 0.666667V0.96C0.624 1.14667 0 1.70667 0 2.56444C0 3.59111 0.764 4.10222 1.88 4.4C2.88 4.66667 3.08 5.05778 3.08 5.47111C3.08 5.77778 2.884 6.26667 2 6.26667C1.34 6.26667 1 6.00444 0.868 5.63111C0.808 5.45778 0.672 5.33333 0.508 5.33333H0.396C0.128 5.33333 -0.06 5.63556 0.04 5.91111C0.268 6.52889 0.8 6.89333 1.4 7.03556V7.33333C1.4 7.70222 1.668 8 2 8C2.332 8 2.6 7.70222 2.6 7.33333V7.04444C3.38 6.88 4 6.37778 4 5.46667C4 4.20444 3.028 3.77333 2.12 3.51111Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
