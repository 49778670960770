import { useIntl } from 'react-intl';
import ColWrap from '../../../../components/ColWrap';
import { FormInput } from '../../../../components/Form/FormInput';
import FormWrap from '../../../../components/FormWrap';
import RowWrap from '../../../../components/RowWrap';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { DATE_FORMAT, emailChecker, toDayjsTimeZone, toStartOfDaysByTimeZone } from '../../../../utils';
import { FormDatePicker } from '../../../../components/Form/FormDatePicker';
import dayjs from 'dayjs';
import { SvgDatePickerIcon } from '../../../../components/@svg/SvgDatePickerIcon';
import './yourInformation.scss';
import { FormInputNumberFormat } from '../../../../components/Form/FormInputNumberFormat';
import { SvgArrowBackIcon } from '../../../../components/@svg/SvgArrowBackIcon';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import { Form } from 'antd';
import { CustomerCheckInDto, Salon } from '../../../../apis/client-axios';
import { useEffect, useState } from 'react';
import { DataCustomer } from '..';
import { customerApi } from '../../../../apis';
import { useMutation } from '@tanstack/react-query';
import NotificationError from '../../../../components/HandleShowNotiError';

interface Iprops {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  dataCustomer?: CustomerCheckInDto;
  setDataCustomer: React.Dispatch<React.SetStateAction<DataCustomer>>;
  dataSalon?: Salon;
}
interface IFormData {
  customerName: string;
  customerPhone: string;
  customerBirthday: string;
  customerEmail: string;
  customerNote: string;
  acceptAutomatedTextMessage: boolean;
}

const YourInformation: React.FC<Iprops> = (props) => {
  const { setStep, dataCustomer, setDataCustomer, dataSalon } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const n = (key: keyof IFormData) => key;
  const dataEmail = Form.useWatch('customerEmail', form);
  const dataPhone = Form.useWatch('customerPhone', form);
  const [emailExisted, setEmailExisted] = useState<string>();

  const onClickBack = () => {
    const data = form.getFieldsValue();
    setDataCustomer((prev) => ({
      ...prev,
      customerName: data.customerName,
      customerEmail: data.customerEmail,
      customerPhone: data?.customerPhone?.replace(/[-\s]/g, ''),
      birthDay: data?.customerBirthday ? (toStartOfDaysByTimeZone(data.customerBirthday) as string) : '',
      customerNote: data?.customerNote,
      acceptAutomatedTextMessage: data?.acceptAutomatedTextMessage,
    }));
    setStep((prev) => prev - 1);
  };

  const checkEmail = useMutation(() => customerApi.customerControllerGetCustomerByEmail(dataEmail ?? ''), {
    onSuccess: ({ data }) => {
      if (data) {
        setEmailExisted(data.emailAddress);
        form.setFields([
          {
            name: 'customerEmail',
            errors: [intl.formatMessage({ id: 'common.emailAddressExisted' })],
          },
        ]);
      }
    },
  });

  const getCustomerByPhoneNumber = useMutation(
    () => customerApi.customerControllerGetCustomerByPhoneNumber(dataPhone ?? ''),
    {
      onSuccess: (data) => {
        if (data?.data) {
          const customer = data?.data;

          const dataCustomer: DataCustomer = {
            customerName: customer?.name,
            customerPhone: customer?.phoneNumber,
            birthDay: customer?.birthDay,
            customerEmail: customer.emailAddress,
            customerNote: customer.notes,
            receiveMessagesByPhone: customer.receiveMessagesByPhone,
            receivePromotionViaEmail: customer.receivePromotionViaEmail,
            acceptAutomatedTextMessage: customer.receiveMessagesByPhone && customer.receivePromotionViaEmail,
          };

          form.setFieldsValue({
            ...dataCustomer,
            customerBirthday: dataCustomer?.birthDay
              ? dayjs(toDayjsTimeZone(dataCustomer.birthDay).format(DATE_FORMAT))
              : null,
          });
        } else {
          form.setFieldsValue({
            customerName: '',
            customerBirthday: null,
            customerEmail: '',
            customerNote: '',
            acceptAutomatedTextMessage: false,
          });
        }
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );
  const getCustomerByPhoneNumber2 = useMutation((dataPhoneNumberForm: string) =>
    customerApi.customerControllerGetCustomerByPhoneNumber(dataPhoneNumberForm)
  );
  const checkEmailCall = async (): Promise<boolean> => {
    if (dataEmail) {
      const dataPhoneNumberForm = form.getFieldValue('customerPhone');
      const dataEmailForm = form.getFieldValue('customerEmail');
      const dataPhoneNumber = await getCustomerByPhoneNumber2.mutateAsync(dataPhoneNumberForm);

      if (
        !dataPhoneNumber.data ||
        (dataPhoneNumber.data &&
          (dataPhoneNumberForm !== dataPhoneNumber.data.phoneNumber ||
            dataEmailForm !== dataPhoneNumber.data.emailAddress))
      ) {
        const { data } = await checkEmail.mutateAsync();
        if (data) {
          setEmailExisted(data?.emailAddress ?? '');
          form.setFields([
            {
              name: 'customerEmail',
              errors: [intl.formatMessage({ id: 'common.emailAddressExisted' })],
            },
          ]);
          return false;
        }
      }
    }
    return true;
  };

  const onNext = async (value: IFormData) => {
    const emailCheckPassed = await checkEmailCall();
    if (emailCheckPassed) {
      setDataCustomer((prev) => ({
        ...prev,
        customerName: value.customerName,
        customerEmail: value.customerEmail,
        customerPhone: value?.customerPhone?.replace(/[-\s]/g, ''),
        birthDay: value?.customerBirthday ? (toStartOfDaysByTimeZone(value.customerBirthday) as string) : '',
        customerNote: value?.customerNote,
        acceptAutomatedTextMessage: value?.acceptAutomatedTextMessage,
      }));
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...dataCustomer,
      customerBirthday: dataCustomer?.birthDay
        ? dayjs(toDayjsTimeZone(dataCustomer.birthDay).format(DATE_FORMAT))
        : null,
    });
  }, [dataCustomer]);

  return (
    <div className="salon__online-appointment__information p-l-r-60 m-t-40">
      <div className="position-relative align-items-center">
        <div className="salon__online-appointment-new-back  m-b-10" onClick={onClickBack}>
          <SvgArrowBackIcon />
          <span className="salon__online-appointment-back-title text-transform-capitalize">
            {intl.formatMessage({ id: 'common.back' })}
          </span>
        </div>
        <h1 className="salon__online-appointment-new__title">
          {intl.formatMessage({ id: 'onlineAppointmentNew.yourInformation' })}
        </h1>
      </div>
      <FormWrap layout="vertical" autoComplete="off" form={form} onFinish={onNext}>
        <RowWrap
          className="salon__online-appointment-new-container-information"
          isGutter
          isWrap
          gutter={[20, 5]}
          isAutoFillRow
          styleFill="between"
        >
          <ColWrap colProps={{ span: 24, md: 12 }}>
            <FormInputNumberFormat
              name={n('customerPhone')}
              formItemProps={{
                required: true,
                rules: [
                  {
                    validator: (_, value) => {
                      const v = value;
                      if (!v) {
                        return Promise.reject('');
                      }
                      if (v && v.replace(/[-\s]/g, '').length < 10) {
                        return Promise.reject(intl.formatMessage({ id: 'onlineAppointment.input.invalidPhoneNumber' }));
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
              patternFormat={{
                format: '### - ### - ####',
                className: 'font-weight-500 ',
                placeholder: intl.formatMessage({ id: 'onlineAppointmentNew.placeholder.enterYourPhoneNumber' }),
                onBlur: (event) => {
                  const v = event.target.value;
                  if (!v) return;

                  getCustomerByPhoneNumber.mutate();
                },
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ sm: 24, md: 12 }}>
            <FormInput
              name={n('customerEmail')}
              inputProps={{
                maxLength: 255,
                placeholder: intl.formatMessage({ id: 'onlineAppointment.input.enterYourEmail' }),
                onBlur: (event) => {
                  const v = event.target.value;
                  if (!v) return;
                  if (v && !emailChecker(v)) {
                    return;
                  }
                  checkEmailCall();
                },
              }}
              formItemProps={{
                rules: [
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (v && !emailChecker(v)) {
                        return Promise.reject('');
                      }
                      if (v && v === emailExisted) {
                        return Promise.reject(intl.formatMessage({ id: 'common.emailAddressExisted' }));
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24, md: 12 }}>
            <FormInput
              name={n('customerName')}
              inputProps={{
                placeholder: intl.formatMessage({ id: 'onlineAppointmentNew.input.enterYourName' }),
                // disabled: !!(customerPhone?.toString().length < 10),
              }}
              formItemProps={{
                rules: [
                  { required: true, message: '' },
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();
                      if (/^\s*$/.test(v)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ sm: 24, md: 12 }}>
            <FormDatePicker
              name={n('customerBirthday')}
              datePickerProps={{
                placeholder: intl.formatMessage({ id: 'onlineAppointment.input.selectYourBirthday' }),
                // disabled: !!(customerPhone?.toString().length < 10),
                //format: DATE_FORMAT,
                suffixIcon: <SvgDatePickerIcon />,
                disabledDate: (date) => {
                  return dayjs(date).format('YYYY-MM-DD') > toDayjsTimeZone().format('YYYY-MM-DD');
                },
                // inputReadOnly: true,
                allowClear: false,
                showToday: false,
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <FormInput
              name={n('customerNote')}
              inputProps={{
                placeholder: intl.formatMessage({ id: 'onlineAppointmentNew.placeholder.enterYourNote' }),
                // disabled: !!(customerPhone?.toString().length < 10),
              }}
              // formItemProps={{
              //   rules: [
              //     { required: false, message: '' },
              //     {
              //       validator: (_, value: string) => {
              //         const v = value?.trim();
              //         if (/^\s*$/.test(v)) {
              //           return Promise.reject('');
              //         }
              //         return Promise.resolve();
              //       },
              //     },
              //   ],
              // }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={40} isWrap={false} styleFill="between">
              <ColWrap colProps={{ span: 24 }} className="height-44">
                <div className="d-flex">
                  <FormCheckbox
                    name={n('acceptAutomatedTextMessage')}
                    formItemProps={{
                      initialValue: false,
                      className: 'm-b-0 height-44 m-t-7 salon__online-appointment-new-checkbox',
                    }}
                    checkboxProps={{
                      className: 'font-size-20',
                    }}
                    //content={intl.formatMessage({ id: 'onlineAppointmentNew.detail.consentToReceiveAutomatedTextMessage' })}
                  />
                  <div className="w-1108 d-flex align-items-center">
                    <span className="salon__online-appointment-new__note">
                      {intl.formatMessage(
                        { id: 'onlineAppointmentNew.detail.consentToReceiveAutomatedTextMessage' },
                        { name: dataSalon?.name }
                      )}
                    </span>
                  </div>
                </div>
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>

        <div className="salon__custom-m-t salon__appointment-button d-flex justify-content-end m-t-180 p-t-7">
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.next' })}
            buttonProps={{
              className:
                'width-184 height-64 border-radius-12px font-weight-700 font-size-24 line-height-29 text-transform-capitalize',
              htmlType: 'submit',
            }}
            isPrimary
          />
        </div>
      </FormWrap>
    </div>
  );
};
export default YourInformation;
