import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { formatNumberThousand } from '../../utils';
import { SvgBookMarkIconSmall } from '../@svg/SvgBookMarkIconSmall';
import { SvgClockIconSmall } from '../@svg/SvgClockIconSmall';
import { SvgDollarPreviewIconSmall } from '../@svg/SvgDollarPreviewIconSmall';
import { SvgRemoveServiceItemIcon } from '../@svg/SvgRemoveServiceItemIcon';
import './serviceItem.scss';
interface IServiceItem {
  isMerchandise?: boolean;
  isServiceCreate?: boolean;
  name?: string;
  price?: string;
  time?: string;
  backgroundColor?: string;
  className?: string;
  onClick?: () => void;
  onClickDeleteItem?: () => void;
}
export function ServiceItemSmall(props: IServiceItem) {
  const { isMerchandise, name, price, time, backgroundColor, isServiceCreate, className, onClick, onClickDeleteItem } =
    props;
  const intl = useIntl();
  return (
    <div
      className={'salon__service-small-item-preview position-relative' + (className ? ' ' + className : '')}
      style={{
        background: backgroundColor,
        border: isServiceCreate ? '1px solid transparent' : '1px solid #000000',
      }}
      onClick={onClick}
    >
      {onClickDeleteItem ? (
        <Button onClick={onClickDeleteItem} className="salon__service-small-item-remove">
          <SvgRemoveServiceItemIcon />
        </Button>
      ) : (
        ''
      )}

      <div className="salon__service-small-item-preview-book-mark">
        <SvgBookMarkIconSmall />
        <span className="salon__service-small-item-preview-services">{isMerchandise ? 'M' : 'S'}</span>
      </div>
      <div className="salon__service-small-item-preview-title">{name}</div>
      <div className="salon__service-small-item-preview-detail">
        <div className="d-flex justify-content-between align-items-center color-292F33">
          {price ? (
            <p className="d-flex align-items-center gap-1 salon__service-small-box-value font-size-10 font-weight-300">
              <p className="m-b-0 m-t-2-n">
                <SvgDollarPreviewIconSmall />
              </p>
              <p className="salon__service-small-box-value-item">{formatNumberThousand(price)}</p>
            </p>
          ) : (
            <></>
          )}
          {isMerchandise ? (
            <></>
          ) : (
            <>
              {time ? (
                <p className="d-flex align-items-center gap-1 salon__service-small-box-value font-size-10 font-weight-300">
                  <p className="m-b-0 m-t-2-n">
                    <SvgClockIconSmall />
                  </p>
                  <p className="salon__service-small-box-value-item">{formatNumberThousand(time)}</p>
                  {intl.formatMessage({ id: 'setting.services.minute' })}
                </p>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
