import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Result, Spin, Tooltip } from 'antd';
import { CollapseProps } from 'antd/lib';
import { toPng } from 'html-to-image';
import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useScanDetection from 'use-scan-detection';
import {
  appointmentApi,
  employeeApi,
  fetchApiPrint,
  promotionApi,
  servicesApi,
  settingApi,
  ticketApi,
} from '../../../apis';
import {
  Appointment,
  AppointmentStatusEnum,
  ChangeStatusAppointments,
  Customer,
  ServiceChargeDTO,
  ServiceItems,
  ServiceTaxDTO,
  TechnicianTurnDTO,
  Ticket,
  TicketDTO,
  TicketDTOStatusEnum,
  TicketDisCountDTO,
  TicketMerchandiseDTO,
  TicketStatusEnum,
  TicketTransactionDTO,
  DiscountDTOTypeEnum,
  DiscountDTO,
  SettingPrinter,
  SettingPrinterCloverPrinterEnum,
} from '../../../apis/client-axios';
import AvatarDefault from '../../../assets/images/appointment/avatar-default.png';
import { SvgAddMoreDataIconSmall } from '../../../components/@svg/SvgAddMoreDataIconSmall';
import { SvgDoubleCheckIcon } from '../../../components/@svg/SvgDoubleCheckIcon';
import SvgMultiUserIcon from '../../../components/@svg/SvgMultiUserIcon';
import { SvgNextIcon } from '../../../components/@svg/SvgNextIcon';
import { SvgPrevIcon } from '../../../components/@svg/SvgPrevIcon';
import { SvgToolTipIcon } from '../../../components/@svg/SvgToolTipIcon';
import CheckInCard from '../../../components/CheckinCard';
import { FormInputSearch } from '../../../components/Form/FormInputSearch';
import FormWrap from '../../../components/FormWrap';
import NotificationError from '../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../components/HandleShowNotiSuccess';
import PageContainer from '../../../components/PageContainer';
import { TimePayment } from '../../../components/Print/@types';
import PrintBill from '../../../components/Print/PrintBill';
import { ServiceItemSmall } from '../../../components/ServiceItemSmall';
import StyledCollapse from '../../../components/StyledCollapse';
import StyledHeader from '../../../components/StyledHeader';
import { StyledPopup } from '../../../components/StyledPopup';
import TechnicianItem from '../../../components/TechnicianItem';
import { RootState, useAppDispatch } from '../../../store';
import { setPairingCode, setPrintResponse, setSaleResponse } from '../../../store/cloverSlice';
import {
  DATE_FORMAT,
  formatDateTimeZoneByFormatString,
  getSessionStorageByName,
  getStorageByName,
  handleTruncateToTwoDecimal,
  removeSessionStorageByName,
} from '../../../utils';
import {
  QUERY_APPOINTMENTS_CHECKOUT,
  QUERY_LAST_TICKET_TODAY_CHECKOUT,
  QUERY_LIST_TECHNICIAN_CHECKOUT,
  QUERY_PROMOTION_CHECKOUT,
  QUERY_SERVICES_CHECKOUT,
  QUERY_SETTING,
  QUERY_TICKET_PENDING_CHECKOUT,
} from '../../../utils/constant';
import { Permission } from '../../../utils/permission';
import { APPOINTMENT_SORT, CustomAppointment } from '../appointment/models';
import { useClover } from '../setting/terminalDeviceSetting/Clover/connection/hooks';
import CheckoutBill from './CheckoutBill';
import DeleteTicket from './DeleteTicket';
import DiscountsAndRewards from './DiscountsAndRewards';
import GeneralService from './GeneralService';
import GiftCard from './Giftcard';
import ListServicesButtons from './ListServicesButtons';
import Merchandise from './Merchandise';
import ModalCheckIn from './OptionTechnicianAndSearch/checkIn';
import ModalCustomer from './OptionTechnicianAndSearch/customer';
import ModalTechnicianList from './OptionTechnicianAndSearch/technicianList';
import PayAndComplete, { PayAndCompleteEnum } from './PayAndComplete';
import PendingTicket from './PendingTicket';
import TaxAndDeleteTicket from './TaxAndDeleteTicket';
import './checkOut.scss';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from './checkoutConstants';
import jsPDF from 'jspdf';
import { SettingPrinterReceiptForEnum } from '../setting/printerSetting';

interface Destination {
  droppableId: AppointmentStatusEnum;
  index: number;
}

export interface ITechnicianItem {
  id: number | string;
  defaultAvatar: string | undefined;
  name: string | undefined;
  phoneNumber: string | number | undefined;
  title: string | undefined;
  services?: IServicesItem[];
  skills?: number[];
}

export interface TaxDTO {
  type?: DiscountDTOTypeEnum;
  moneyDiscount?: number;
}

export interface IDiscountWithTax extends TicketDisCountDTO {
  tax?: TaxDTO;
}

export interface IServicesItem {
  description: string;
  id: number;
  idDelete: number;
  name: string;
  price: number;
  supply: number;
  isMerchandise: boolean;
  isGeneralService: boolean;
  discount?: IDiscountWithTax;
  totalDiscount?: number;
  isTaxable: boolean;
}

export interface IMerchandise extends TicketMerchandiseDTO {
  name?: string;
  idDelete?: number;
  isTaxable?: boolean;
  discount?: IDiscountWithTax;
}

export interface IMerchandiseCombine extends IMerchandise {
  merchandise?: ServiceItems;
}

export interface IGiftCard {
  id?: number;
  giftCardValue: number;
  giftCardCode: number;
}

export interface IAppointmentBill {
  id?: string | number;
  customer?: Customer;
  technician: ITechnicianItem[];
  appointmentId: number[];
  customerId?: number;
  giftCards?: IGiftCard[];
  serviceTax?: ServiceTaxDTO;
  serviceCharge?: ServiceChargeDTO;
  deposit?: number;
  ticketMerchandise: IMerchandise[];
  status: TicketDTOStatusEnum;
  combineId?: number[];
  isDetail?: boolean;
}

export interface IFilter {
  page: number;
  sort: string;
  fullTextSearch: string;
  salonId: number | string;
  date?: Date;
}

export interface IServiceCharge {
  name: string;
  chargeValue: number;
}

export interface IFilterTechnician {
  isAll?: boolean;
  filterBy: 'WORK_TODAY' | 'CLOCKED_IN' | undefined;
  orderBy: 'NAME' | 'CLOCK_TIME' | undefined;
}

export interface IOpenModalCheckOut {
  openListTechnician: boolean;
  openListCustomer: boolean;
  openGeneralService: boolean;
  openGiftCard: boolean;
  openMerchandise: boolean;
  openTaxAndDeleteTicket: boolean;
  openDiscountsAndRewards: boolean;
  openListCheckIn: boolean;
  openPendingTicket: boolean;
  openDeleteTicket: boolean;
}

export interface IListIdDisable {
  listIdServices: number[] | string[];
  listIdMerchandise: number[] | string[];
  listIdTechnician: number[] | string[];
}

export interface IListIdTotalPrice {
  total: number;
  totalTip: number;
  totalDiscount: number;
  totalDiscountGeneralTicket: number;
  totalDiscountGeneralMerchandise: number;
  totalPayments: number;
  totalBalanceTotalPay: number;
  saleTax: number;
  serviceCharge: number;
  giftCard: number;
  //
  totalDiscountBirthday: number;
  totalDiscountWeekly: number;
  totalDiscountOccasion: number;
  totalDiscountTechnician: number;
  generalTicketDiscount: number;
  generalItemDiscount: number;
  loyaltyReward: number;
  referralDiscount: number;
  //
  // totalDiscountMerchandise: number;
  // totalDiscountService: number;

  totalRedeeming: number;
  totalDeposit: number;

  // user are paying
  totalCash: number;
}

export interface IHandleSubmitCheckoutProps {
  status?: TicketDTOStatusEnum;
  ticketTransaction?: TicketTransactionDTO[];
  totalTip?: number;
  balanceToPay?: number;
}

export interface IGiftCardPaymentInformation {
  giftCardNumber: number;
  giftCardValueUse: number;
}

export interface IGiftCardPayment {
  totalValue: number;
  giftCards?: IGiftCardPaymentInformation[];
}

export interface ICheckPayment {
  amount: number;
  tip?: number;
}

export interface IOrtherPayment {
  amount: number;
  tip?: number;
}

export interface IKeyInPayment {
  amount: number;
  tip?: number;
}

export interface ICashPayment {
  amount: number;
  tip?: number;
}

export interface IMultipleChargeCard {
  amount: number;
  tip?: number;
}

export interface IDiscountTechnician {
  technicianId: number;
  serviceId: number;
  type: DiscountTypeEnum;
  amount: number;
  money: number;
}

export interface PaymentInformation {
  cashDiscount?: number;
  // amount?: number;
  // tip?: number;
  ticketTransaction?: TicketTransactionDTO[];
  giftCardPayment?: IGiftCardPayment;
  checkPayment?: ICheckPayment;
  keyInCardPayment?: IKeyInPayment;
  cashPayment?: ICashPayment;
  ortherPayment?: IOrtherPayment;
  multipleChargeCard?: IMultipleChargeCard;
}

export interface ITipInformation {
  technicians?: ITechnicianItem[];
  tip?: number;
  ticketId?: number;
  technicianTurns?: Array<TechnicianTurnDTO>;
}

export const initialBill: IAppointmentBill = {
  customer: undefined,
  technician: [],
  appointmentId: [0],
  customerId: 0,
  giftCards: [],
  serviceTax: undefined,
  serviceCharge: undefined,
  deposit: 0,
  status: TicketDTOStatusEnum.Completed,
  ticketMerchandise: [],
};

const initialListTotal: IListIdTotalPrice = {
  total: 0,
  totalTip: 0,
  totalBalanceTotalPay: 0,
  totalDiscount: 0,
  totalPayments: 0,
  saleTax: 0,
  serviceCharge: 0,
  giftCard: 0,
  totalDiscountGeneralMerchandise: 0,
  totalDiscountGeneralTicket: 0,
  totalDiscountBirthday: 0,
  totalDiscountOccasion: 0,
  totalDiscountWeekly: 0,
  generalItemDiscount: 0,
  generalTicketDiscount: 0,
  loyaltyReward: 0,
  referralDiscount: 0,
  totalDiscountTechnician: 0,
  // totalDiscountMerchandise: 0,
  // totalDiscountService: 0,
  totalRedeeming: 0,
  totalDeposit: 0,
  totalCash: 0,
};
export const initialPromotion: PaymentInformation = {
  cashDiscount: 0,
  // amount: 0,
  // tip: 0,
  ticketTransaction: undefined,
  giftCardPayment: undefined,
};

export const DesiredStatusesItem = {
  CheckIn: 'Checked_in',
  BeingServed: 'Being_served',
  CheckOutBIll: 'checkout_bill',
  ListTechnician: 'list_technician',
  ListServices: 'list_services',
  ListCheckin: 'list_checkin',
  ReferralDiscount: 'ReferralDiscount',
  GeneralTicketDiscount: 'GeneralTicketDiscount',
  GeneralMerchandiseDiscount: 'GeneralMerchandiseDiscount',
  ServiceCharge: 'ServiceCharge',
  TaxPercent: 'TaxPercent',
  GiftCard: 'GiftCard',
  Merchandise: 'Merchandise',
};

export enum ListServicesItem {
  GeneralService = 'generalService',
  GiftCard = 'giftCard',
  Merchandise = 'merchandise',
  DiscountAndRewards = 'discountAndRewards',
  TaxAndServiceCharge = 'taxAndServiceCharge',
  Redeem = 'redeem',
}

export const desiredStatuses = [DesiredStatusesItem.CheckIn, DesiredStatusesItem.BeingServed];
const listServices = [
  ListServicesItem.GeneralService,
  ListServicesItem.GiftCard,
  ListServicesItem.Merchandise,
  ListServicesItem.DiscountAndRewards,
  ListServicesItem.TaxAndServiceCharge,
];

const CheckOutTenant = () => {
  const intl = useIntl();
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);
  const isHavingPermission = salonPermission?.includes(Permission.CheckOut.CheckOut);
  const salonId = getStorageByName('salonId') as string;
  const dataInitialAppointmentBill: IAppointmentBill | null = getSessionStorageByName('appointmentBill');
  const listServicesIdDisable: number[] = [];
  // dataInitialAppointmentBill?.technician.forEach((tech) => {
  //   tech?.services?.forEach((service) => {
  //     listServicesIdDisable.push(service.id);
  //   });
  // });
  const [isCollapse, setIsCollapse] = useState(false);
  const [openModal, setOpenModal] = useState<IOpenModalCheckOut>({
    openListTechnician: false,
    openListCustomer: false,
    openGeneralService: false,
    openGiftCard: false,
    openMerchandise: false,
    openTaxAndDeleteTicket: false,
    openDiscountsAndRewards: false,
    openListCheckIn: false,
    openPendingTicket: false,
    openDeleteTicket: false,
  });
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [searchPending, setSearchPending] = useState<string>('');
  const [listTechnician, setListTechnician] = useState<ITechnicianItem[]>([]);
  const [isOpenPayAndComplete, setIsOpenPayAndComplete] = useState<boolean>(false);
  const [idTechnician, setIdTechnician] = useState<number | string>(
    dataInitialAppointmentBill && dataInitialAppointmentBill?.technician?.length > 0
      ? (dataInitialAppointmentBill?.technician[0].id as number)
      : 0
  );
  const [listSkill, setListSkill] = useState<number[]>(
    dataInitialAppointmentBill && dataInitialAppointmentBill?.technician?.length > 0
      ? (dataInitialAppointmentBill?.technician[0].skills as number[])
      : []
  );
  const [indexServiceEdit, setIndexServiceEdit] = useState<number>(0);
  const [serviceApply, setServiceApply] = useState<number[]>(listServicesIdDisable);
  const [idService, setIdService] = useState<number | string>(0);
  const [serviceItem, setServiceItem] = useState<IServicesItem>();
  const [taxPercent, setTaxPercent] = useState<number>(0);
  const [taxDiscount, setTaxDiscount] = useState<number>(0);
  const [filterTechnician, setFilterTechnician] = useState<IFilterTechnician>({
    isAll: true,
    filterBy: undefined,
    orderBy: 'NAME',
  });
  const [isEditServicePrice, setIsEditServicePrice] = useState<boolean>(false);
  const [serviceCharge, setServiceCharge] = useState<IServiceCharge>({
    chargeValue: 0,
    name: '',
  });
  const [idAppointment, setIdAppointment] = useState<number[]>(dataInitialAppointmentBill?.appointmentId ?? []);
  const [discountsAndRewardsApplied, setDiscountsAndRewardsApplied] = useState<IDiscountsAndRewardsApplied>();
  const [settingPrinter, setSettingPrinter] = useState<SettingPrinter>();
  const [paymentInformation, setPaymentInformation] = useState<PaymentInformation>(initialPromotion);
  const [idTechnicianDeposit, setIdTechnicianDeposit] = useState<number>(0);
  const [ticketToPrint, setTicketToPrint] = useState<Ticket>();
  const [redeem, setRedeem] = useState<boolean>(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  const [timePaymentPrintTicket, setTimePaymentPrintTicket] = useState<TimePayment>(TimePayment.AFTER);

  const printBillComponentRef = useRef<HTMLDivElement>(null);

  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);

  const [listIdDisable, setListIdDisable] = useState<IListIdDisable>({
    listIdServices: listServicesIdDisable,
    listIdTechnician: (dataInitialAppointmentBill?.technician.map((item) => item.id) as number[]) ?? [],
    listIdMerchandise:
      (dataInitialAppointmentBill?.ticketMerchandise.map((item) => item.merchandiseId) as number[]) ?? [],
  });

  const [listTotal, setListTotal] = useState<IListIdTotalPrice>(initialListTotal);
  //for column bill
  const [appointmentBill, setAppointmentBill] = useState<IAppointmentBill>(dataInitialAppointmentBill ?? initialBill);

  // state for tips and technicians after pay and complete
  const [tipInformation, setTipInformation] = useState<ITipInformation>();

  //Custom hook for clover connection
  const { cloverDevice, onConnect } = useClover();

  const dispatch = useAppDispatch();
  const printResponse = useSelector((state: RootState) => state.clover.printResponse);
  const saleResponse = useSelector((state: RootState) => state.clover.saleResponse);
  const pairingCode = useSelector((state: RootState) => state.clover.pairingCode);
  const { data: listTechnicians, isLoading: loaddingTechnician } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN_CHECKOUT, filterTechnician],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () =>
      employeeApi.employeeControllerTechnician(
        undefined,
        undefined,
        filterTechnician.filterBy,
        filterTechnician.orderBy
      ),
  });

  const { data: listPromotion, refetch: handleRefetchPromotion } = useQuery({
    queryKey: [QUERY_PROMOTION_CHECKOUT],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () => promotionApi.promotionControllerGet(salonId),
  });

  const { data: lastTicketInTodayResponse, refetch: handleRefetchLastTicket } = useQuery({
    queryKey: [QUERY_LAST_TICKET_TODAY_CHECKOUT],
    queryFn: () => ticketApi.ticketControllerGetLastTicketInToday(),
    enabled: isHavingPermission,
    staleTime: 1000,
  });
  const {
    data: listAppointments,
    isLoading: loadingAppointment,
    refetch: handleRefetchAppointment,
  } = useQuery({
    queryKey: [QUERY_APPOINTMENTS_CHECKOUT],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () =>
      appointmentApi.appointmentControllerGet(
        1,
        formatDateTimeZoneByFormatString(DATE_FORMAT),
        0,
        APPOINTMENT_SORT,
        ''
      ),
  });

  const { data: listDataServices, isLoading: loadingService } = useQuery({
    queryKey: [QUERY_SERVICES_CHECKOUT],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () =>
      servicesApi.serviceControllerGetBySalon(0, undefined, undefined, undefined, localStorage.getItem('salonId')),
  });

  const { data: listTicketPending, refetch: handleRefetchTicketPending } = useQuery({
    queryKey: [QUERY_TICKET_PENDING_CHECKOUT, searchPending],
    enabled: isHavingPermission,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetTicketPending(1, undefined, undefined, searchPending),
  });

  const { data: listSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
    enabled: isHavingPermission,
  });

  useEffect(() => {
    if (!!listSetting?.data?.settingPrinter?.length) {
      const printerCompensation = listSetting?.data.settingPrinter.find((e) =>
        e.receiptFor.includes(SettingPrinterReceiptForEnum.CHECK_OUT)
      );

      setSettingPrinter(printerCompensation);
    }
  }, [listSetting]);

  const handleUpdateShowModal = (item: string) => {
    if (item === ListServicesItem.GeneralService) {
      if (appointmentBill.technician.length === 0) {
        return NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.error.serviceDrag' }) });
      } else {
        setOpenModal({
          ...openModal,
          openGeneralService: true,
        });
        setIdService(0);
        setServiceItem(undefined);
      }
    } else if (item === ListServicesItem.GiftCard) {
      setOpenModal({
        ...openModal,
        openGiftCard: true,
      });
    } else if (item === ListServicesItem.Merchandise) {
      setOpenModal({
        ...openModal,
        openMerchandise: true,
      });
    } else if (item === ListServicesItem.DiscountAndRewards) {
      setOpenModal({
        ...openModal,
        openDiscountsAndRewards: true,
      });
    } else if (item === ListServicesItem.Redeem) {
      setRedeem(true);
      setOpenModal({
        ...openModal,
        openDiscountsAndRewards: true,
      });
    } else {
      setOpenModal({
        ...openModal,
        openTaxAndDeleteTicket: true,
      });
    }
  };

  const mutationChangeStatusAppoiment = useMutation(
    (input: { dropAppoiment: Appointment; destination: Destination }) =>
      appointmentApi.appointmentControllerChangeStatusAppointment(
        String(input.dropAppoiment.id),
        { status: input.destination.droppableId },
        input.dropAppoiment.salonId,
        undefined
      ),
    {
      onSuccess: () => {
        // handleRefetchlistAppointments();
      },
      onError: (error) => {},
    }
  );
  const mutationDeleteCheckoutPending = useMutation((id: number) => ticketApi.ticketControllerDeleteTicketPending(id), {
    onSuccess: () => {
      resetBill();
      handleRefetchTicketPending();
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (appointmentBill.customerId === 0 && appointmentBill.technician.length === 0) {
      setIdAppointment([]);
    }
  }, [appointmentBill]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (printResponse) {
      setTicketToPrint(undefined);
      dispatch(setPrintResponse(undefined));
      setTimePaymentPrintTicket(TimePayment.AFTER);

      if (printResponse.getReason() === 'DONE') {
        if (!printResponse.getSuccess()) {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.printLastTicket.failed' }) });
        }
      }
    }
  }, [printResponse, intl, dispatch]);

  const handleConvertPrice = (price: string) => {
    return price ? Number(price.toString().replace(/,/g, '')) : 0;
  };
  const handleDeleteTicketPending = () => {
    if (appointmentBill && appointmentBill.id) {
      mutationDeleteCheckoutPending.mutate(Number(appointmentBill.id));
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination }: { source: DraggableLocation; destination?: DraggableLocation | null } = result;

    if (!destination) return;
    const sourceDroppableId: AppointmentStatusEnum = source.droppableId as AppointmentStatusEnum;
    const destinationDroppableId: AppointmentStatusEnum = destination.droppableId as AppointmentStatusEnum;
    if (sourceDroppableId !== destinationDroppableId) {
      const dropAppoiment = appointments.filter((appointment) => appointment.status === source.droppableId)[
        source.index
      ];
      let dropTechnician: ITechnicianItem[] = [];
      const dropServicesItems: IServicesItem[] = [];
      // filter list technician
      if (source.droppableId === DesiredStatusesItem.ListTechnician) {
        dropTechnician = listTechnician.filter((appointment) => appointment.id === source.index);
      }

      // filter list service item
      if (
        source.droppableId !== DesiredStatusesItem.CheckIn &&
        source.droppableId !== DesiredStatusesItem.BeingServed &&
        source.droppableId !== DesiredStatusesItem.ListTechnician
      ) {
        listDataServices?.data?.content?.some((item) => {
          const found = item.serviceItems.find((serviceItem) => serviceItem.id === source.index);
          if (found) {
            dropServicesItems.push({
              ...found,
              isGeneralService: false,
              idDelete: parseFloat(Math.random().toFixed(3)) * 10,
              occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(found.id)
                ? handleTruncateToTwoDecimal((found.price * discountsAndRewardsApplied.occasionDiscount?.amount) / 100)
                : undefined,
              discountBirthday: discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(found.id)
                ? discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Percent
                  ? handleTruncateToTwoDecimal(
                      (found.price * discountsAndRewardsApplied.birthdayDiscount?.amount) / 100
                    )
                  : discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                  : undefined
                : undefined,
              discountWeekly: discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(found.id)
                ? discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(found.id)
                  ? discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Percent
                    ? handleTruncateToTwoDecimal(
                        (found.price * discountsAndRewardsApplied.weeklyDiscount?.amount) / 100
                      )
                    : discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                    : undefined
                  : undefined
                : undefined,
            } as IServicesItem);
            return true;
          }
          return false;
        });
      }
      const correctDestination: Destination = {
        droppableId: destinationDroppableId,
        index: destination.index,
      };
      const copyAppointment = [...appointments];
      const findIndex = copyAppointment.findIndex(
        (appointment) => appointment.id === (dropAppoiment && dropAppoiment.id)
      );
      //check dropzone is being served in col right
      if (destination.droppableId === DesiredStatusesItem.BeingServed) {
        if (
          (source.droppableId === DesiredStatusesItem.CheckIn &&
            destination.droppableId !== DesiredStatusesItem.BeingServed) ||
          source.droppableId === DesiredStatusesItem.BeingServed ||
          (destination.droppableId === DesiredStatusesItem.BeingServed &&
            (!dropAppoiment.services.length || !dropAppoiment.technician))
        ) {
          return;
        }

        if (findIndex > -1) {
          copyAppointment[findIndex] = { ...dropAppoiment, status: destination.droppableId as AppointmentStatusEnum };
          setAppointments(copyAppointment);
          mutationChangeStatusAppoiment.mutate({ dropAppoiment, destination: correctDestination }); // Fixed typo here
        }
      }

      // check dropzone is col mid
      if (destination.droppableId === DesiredStatusesItem.CheckOutBIll) {
        handleCreateBill(dropAppoiment, dropServicesItems, dropTechnician);
      }
    }
  };
  const checkListServiceInTechnician = (idTechnician: number, isRemoveService?: boolean, idService?: number) => {
    const tempTechnician = appointmentBill.technician.find((item) => item.id === idTechnician);
    if (isRemoveService) {
      setServiceApply(
        (tempTechnician?.services?.map((item) => item.id).filter((item) => item !== idService) as number[]) ?? []
      );
    } else {
      setServiceApply((tempTechnician?.services?.map((item) => item.id) as number[]) ?? []);
    }
  };

  const handleCreateBill = (
    dropAppoiment: Appointment,
    dropServicesItems: IServicesItem[],
    dropTechnician: ITechnicianItem[]
  ) => {
    // appointment drop into bill
    if (dropAppoiment) {
      let totalDeposit: number = 0;
      const findTechnician = listTechnicians?.data?.find((item) => item.id === dropAppoiment.technicianId);
      if (dropAppoiment.appointmentDeposits) {
        setIdTechnicianDeposit(dropAppoiment.technicianId);
        dropAppoiment.appointmentDeposits.map((item) => (totalDeposit += +item.money));
      }
      const idTechnicianUnassign: number = parseFloat(Math.random().toFixed(3)) * 10;
      checkListServiceInTechnician(dropAppoiment.id as number);
      const dataBill: IAppointmentBill = {
        customerId: dropAppoiment.customer.id,
        status: TicketDTOStatusEnum.Completed,
        appointmentId: [dropAppoiment.id],
        customer: dropAppoiment.customer,
        technician: [
          {
            id: dropAppoiment.technicianId ?? idTechnicianUnassign,
            name: dropAppoiment.technician?.name,
            defaultAvatar: dropAppoiment.technician?.defaultAvatar,
            phoneNumber: dropAppoiment.technician?.phoneNumber,
            title: dropAppoiment.technician?.title,
            services: dropAppoiment.services
              .filter((item) => !item.isMerchandise)
              .map((item) => {
                const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(item.id)
                  ? {
                      discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                      type: discountsAndRewardsApplied?.occasionDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.occasionDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(item.id)
                  ? {
                      discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                      type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(item.id)
                  ? {
                      discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                      type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const generalDiscountTicket =
                  discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (handleConvertPrice(item.price.toString()) *
                          (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                          100
                      );
                const totalDiscount: number =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                    (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                    (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                    (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

                const totalDiscountWithPromotion: number =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                  (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                  (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0);

                const finalDiscount =
                  totalDiscountWithPromotion >= +item.price ? +item.price : totalDiscountWithPromotion;
                const tax =
                  item.isTaxable && +taxPercent > 0
                    ? {
                        type: DiscountTypeEnum.Percent,
                        moneyDiscount: handleTruncateToTwoDecimal(((item.price - finalDiscount) * +taxPercent) / 100),
                      }
                    : undefined;
                return {
                  id: item.id,
                  idDelete: parseFloat(Math.random().toFixed(3)) * 10,
                  name: item.name,
                  price: item.price,
                  description: item.description,
                  supply: item.supply,
                  isTaxable: item.isTaxable,
                  totalDiscount: +totalDiscount >= +item.price ? +item.price : +totalDiscount,
                  discount: {
                    occasionDiscount: occasionDiscount,
                    weeklyDiscount: weeklyDiscount,
                    birthdayDiscount: birthdayDiscount,
                    tax: tax,
                  },
                };
              }) as IServicesItem[],
            skills: findTechnician?.skills.map((item) => item.id) as number[],
          },
        ],
        ticketMerchandise: dropAppoiment.services
          .filter((item) => item.isMerchandise)
          .map((item) => {
            const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
              item.id as number
            )
              ? {
                  discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                  type: discountsAndRewardsApplied?.occasionDiscount?.type,
                  moneyDiscount:
                    discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                      ? discountsAndRewardsApplied?.occasionDiscount?.amount
                      : handleTruncateToTwoDecimal(
                          (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                        ),
                }
              : undefined;
            const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(
              item.id as number
            )
              ? {
                  discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                  type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                  moneyDiscount:
                    discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                      ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                      : handleTruncateToTwoDecimal(
                          (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                        ),
                }
              : undefined;
            const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
              item.id as number
            )
              ? {
                  discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                  type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                  moneyDiscount:
                    discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                      ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                      : handleTruncateToTwoDecimal(
                          (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                        ),
                }
              : undefined;
            const generalDiscountMerchandise =
              discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar
                ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
                : handleTruncateToTwoDecimal(
                    (handleConvertPrice(item.price.toString()) *
                      (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount ?? 0)) /
                      100
                  );
            const generalDiscountTicket =
              discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
                ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
                : handleTruncateToTwoDecimal(
                    (handleConvertPrice(item.price.toString()) *
                      (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                      100
                  );
            const totalDiscount: number =
              (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                (!!generalDiscountMerchandise ? +generalDiscountMerchandise : 0) +
                (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

            const totalDiscountWithPromotion: number =
              (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
              (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
              (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0);

            const finalDiscount = totalDiscountWithPromotion >= +item.price ? +item.price : totalDiscountWithPromotion;
            const tax = item.isTaxable
              ? {
                  type: DiscountTypeEnum.Percent,
                  moneyDiscount: handleTruncateToTwoDecimal(((item.price - finalDiscount) * +taxPercent) / 100),
                }
              : undefined;
            return {
              id: item.id,
              idDelete: parseFloat(Math.random().toFixed(3)) * 10,
              name: item.name,
              merchandiseId: item.id,
              merchandisePrice: item.price,
              isTaxable: item.isTaxable,
              totalDiscount: totalDiscount >= +item.price ? +item.price : totalDiscount,
              discount: {
                occasionDiscount: occasionDiscount,
                weeklyDiscount: weeklyDiscount,
                birthdayDiscount: birthdayDiscount,
                tax: tax,
                rewardBalance: discountsAndRewardsApplied?.rewardBalance?.amount
                  ? {
                      discountApply: discountsAndRewardsApplied?.rewardBalance?.amount,
                      type: discountsAndRewardsApplied?.rewardBalance?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.rewardBalance?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.rewardBalance?.amount
                          : 0,
                      point: discountsAndRewardsApplied?.rewardBalance?.points,
                    }
                  : undefined,
              },
            };
          }),
        deposit: totalDeposit,
      };
      if (listPromotion?.data.feeDiscount?.autoOption) {
        setServiceCharge({
          chargeValue: +listPromotion?.data.feeDiscount?.autoOption,
          name: listPromotion?.data.feeDiscount?.feeName,
        });
      }
      setIdTechnician(dropAppoiment.technicianId ?? idTechnicianUnassign);
      setIdAppointment([dropAppoiment.id]);
      setListSkill((findTechnician?.skills.map((item) => item.id) as number[]) ?? []);
      setServiceApply(
        (dropAppoiment.services.filter((item) => !item.isMerchandise).map((item) => item.id) as number[]) ?? []
      );
      setListIdDisable({
        listIdServices: dropAppoiment.services.map((item) => item.id),
        listIdTechnician: [dropAppoiment.technicianId],
        listIdMerchandise: dropAppoiment.services.filter((item) => item.isMerchandise).map((itemMap) => itemMap.id),
      });
      handleGetTotal(dataBill);
      setAppointmentBill(dataBill);
    }
    // technician drop into bill
    if (!!dropTechnician.length && appointmentBill) {
      setIdTechnician(dropTechnician[0].id);
      setListSkill(dropTechnician[0].skills ?? []);
      setServiceApply([]);
      setListIdDisable({
        ...listIdDisable,
        listIdTechnician: [...(listIdDisable.listIdTechnician as number[]), dropTechnician[0].id as number],
      });
      const dataGrpDropTechnician = appointmentBill.technician.concat(dropTechnician);
      setAppointmentBill({
        ...appointmentBill,
        technician: dataGrpDropTechnician,
      });
      handleGetTotal({
        ...appointmentBill,
        technician: dataGrpDropTechnician,
      });
    }

    if (!!dropServicesItems.length) {
      if (idTechnician === 0 && !dropServicesItems[0].isMerchandise) {
        return NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.error.serviceDrag' }) });
      }
      setServiceApply((prev) => prev.concat(dropServicesItems.map((item) => item.id)));
      const dataMapDropServicesItems = dropServicesItems
        .filter((item) => item.isMerchandise)
        .map((item) => {
          const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(item.id)
            ? {
                discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                type: discountsAndRewardsApplied?.occasionDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.occasionDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(item.id)
            ? {
                discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(item.id)
            ? {
                discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const generalDiscountMerchandise =
            discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar
              ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
              : handleTruncateToTwoDecimal(
                  (handleConvertPrice(item.price.toString()) *
                    (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount ?? 0)) /
                    100
                );
          const generalDiscountTicket =
            discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
              ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
              : handleTruncateToTwoDecimal(
                  (handleConvertPrice(item.price.toString()) *
                    (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                    100
                );
          const totalDiscount: number =
            (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
              (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
              (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
              (!!generalDiscountMerchandise ? +generalDiscountMerchandise : 0) +
              (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

          const totalDiscountWithPromotion: number =
            (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
            (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
            (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0);
          const finalDiscount = totalDiscountWithPromotion >= +item.price ? +item.price : totalDiscountWithPromotion;
          const tax =
            item.isTaxable && +taxPercent > 0
              ? {
                  type: DiscountTypeEnum.Percent,
                  moneyDiscount: handleTruncateToTwoDecimal(((item.price - finalDiscount) * +taxPercent) / 100),
                }
              : undefined;
          return {
            name: item.name,
            id: item.id,
            idDelete: parseFloat(Math.random().toFixed(3)) * 10,
            merchandisePrice: item.price,
            merchandiseId: item.id,
            isTaxable: item.isTaxable,
            totalDiscount: finalDiscount,
            discount: {
              occasionDiscount: occasionDiscount,
              weeklyDiscount: weeklyDiscount,
              birthdayDiscount: birthdayDiscount,
              tax: tax,
            },
          };
        });
      const dataMerchandise = appointmentBill.ticketMerchandise
        ? appointmentBill.ticketMerchandise.concat(dataMapDropServicesItems)
        : dataMapDropServicesItems;
      if (appointmentBill && appointmentBill.technician.length !== 0) {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise:
            dropServicesItems.filter((item) => item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdMerchandise as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdMerchandise,
          listIdServices:
            dropServicesItems.filter((item) => !item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdServices as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdServices,
        });
        const dataMapTechnician = appointmentBill.technician.map((item) => {
          const dataTechnicianWithDiscount = dropServicesItems
            .filter((item) => !item.isMerchandise)
            .map((itemServiceMap) => {
              const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                itemServiceMap.id
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                    type: discountsAndRewardsApplied?.occasionDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.occasionDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (itemServiceMap.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                          ),
                  }
                : undefined;
              const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(
                itemServiceMap.id
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                    type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (itemServiceMap.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                          ),
                  }
                : undefined;
              const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                itemServiceMap.id
              )
                ? {
                    discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                    type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                    moneyDiscount:
                      discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                        ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                        : handleTruncateToTwoDecimal(
                            (itemServiceMap.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                          ),
                  }
                : undefined;
              const generalDiscountTicket =
                discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
                  : handleTruncateToTwoDecimal(
                      (handleConvertPrice(itemServiceMap.price.toString()) *
                        (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                        100
                    );
              const totalDiscount: number =
                (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                  (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                  (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                  (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

              const totalDiscountWithPromotion: number =
                (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0);

              const finalDiscount =
                totalDiscountWithPromotion >= +itemServiceMap.price
                  ? +itemServiceMap.price
                  : totalDiscountWithPromotion;
              const tax =
                itemServiceMap.isTaxable && +taxPercent > 0
                  ? {
                      type: DiscountTypeEnum.Percent,
                      moneyDiscount: handleTruncateToTwoDecimal(
                        ((itemServiceMap.price - finalDiscount) * +taxPercent) / 100
                      ),
                    }
                  : undefined;
              return {
                ...itemServiceMap,
                idDelete: itemServiceMap.idDelete,
                totalDiscount: totalDiscount >= +itemServiceMap.price ? +itemServiceMap.price : totalDiscount,
                discount: {
                  occasionDiscount: occasionDiscount,
                  weeklyDiscount: weeklyDiscount,
                  birthdayDiscount: birthdayDiscount,
                  tax: tax,
                },
              };
            });
          return {
            ...item,
            services:
              idTechnician === item.id
                ? item.services && item.services.length > 0
                  ? item.services.concat(dataTechnicianWithDiscount)
                  : dataTechnicianWithDiscount
                : item.services,
          };
        });
        const dataUpdateAppointment = {
          ...appointmentBill,
          technician: dataMapTechnician,
          ticketMerchandise: dataMerchandise,
        };
        const serviceId = dropServicesItems[0].idDelete;
        dataUpdateAppointment.technician.forEach((item) => {
          item.services?.forEach((itemServiceMap, indexService) => {
            const servicePrice = itemServiceMap.price ? Number(itemServiceMap.price) : 0;
            if (servicePrice === 0 && serviceId === itemServiceMap.idDelete) {
              setOpenModal({
                ...openModal,
                openGeneralService: true,
              });
              setIsEditServicePrice(true);
              setIdService(itemServiceMap.id);
              setServiceItem(itemServiceMap);
              setIndexServiceEdit(indexService);
            }
          });
        });
        setAppointmentBill(dataUpdateAppointment);
        handleGetTotal(dataUpdateAppointment);
      } else {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise:
            dropServicesItems.filter((item) => item.isMerchandise).length > 0
              ? [...(listIdDisable.listIdMerchandise as number[]), dropServicesItems[0].id as number]
              : listIdDisable.listIdMerchandise,
        });
        const dataUpdateAppointmentMerchandise = {
          ...appointmentBill,
          ticketMerchandise: dataMerchandise,
        };
        setAppointmentBill(dataUpdateAppointmentMerchandise);
        handleGetTotal(dataUpdateAppointmentMerchandise);
      }
    }
  };

  const handleGetTotal = (dataMap: IAppointmentBill) => {
    let totalService: number = 0;
    let totalServiceWithTax: number = 0;
    let giftCardValue: number = 0;
    let totalMerchandise: number = 0;
    let totalMerchandiseWithTax: number = 0;
    let totalBalanceTotalPay: number = 0;
    let totalDiscountGeneralMerchandise: number = 0;
    let totalDiscountGeneralTicket: number = 0;
    let totalDiscountBirthdayService: number = 0;
    let totalDiscountWeeklyService: number = 0;
    let totalDiscountOccasionService: number = 0;
    let totalDiscountBirthdayMerchandise: number = 0;
    let totalDiscountWeeklyMerchandise: number = 0;
    let totalDiscountOccasionMerchandise: number = 0;
    let totalDiscountTechnician: number = 0;
    let totalDiscountReferral: number = 0;

    let technicianDiscount: number = 0;

    let taxServiceApply: number = 0;
    let taxMerchandiseApply: number = 0;

    let totalRedeeming: number = 0;
    let totalDeposit: number = 0;

    let totalDiscountAllMerchandise: number = 0;
    let totalDiscountAllServices: number = 0;
    // let totalDiscountGiftCard: number = 0;

    let totalBuyGiftCard: number = 0;

    let checkTaxMerchandise: boolean = false;
    let checkTaxService: boolean = false;

    // giftCard
    dataMap.giftCards?.forEach((item) => {
      if (item.giftCardValue) {
        totalBuyGiftCard = totalBuyGiftCard + (+item.giftCardValue ?? 0);
      }
    });

    // services
    dataMap.technician.map((item) =>
      item.services?.forEach((itemService) => {
        totalService += +itemService.price;
      })
    );

    dataMap.technician.forEach((item) =>
      item.services?.forEach((itemService) => {
        if (itemService.discount?.birthdayDiscount?.moneyDiscount) {
          totalDiscountBirthdayService += +itemService.discount?.birthdayDiscount.moneyDiscount;
        }
      })
    );
    dataMap.technician.forEach((item) =>
      item.services?.forEach((itemService) => {
        if (itemService.discount?.weeklyDiscount?.moneyDiscount) {
          totalDiscountWeeklyService += +itemService.discount?.weeklyDiscount.moneyDiscount;
        }
      })
    );
    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.discount?.occasionDiscount?.moneyDiscount) {
          totalDiscountOccasionService += +itemService.discount?.occasionDiscount.moneyDiscount;
        }
      });
    });

    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.discount?.technicianDiscount?.moneyDiscount) {
          technicianDiscount += +itemService.discount?.technicianDiscount.moneyDiscount;
        }
      });
    });

    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.isTaxable) {
          checkTaxService = true;
          if (itemService.discount?.tax?.moneyDiscount) {
            taxServiceApply += +itemService.discount?.tax?.moneyDiscount;
          }
        }
      });
    });

    totalDiscountAllServices =
      totalDiscountOccasionService + totalDiscountWeeklyService + totalDiscountBirthdayService + technicianDiscount;
    // dataMap.technician.forEach((item) => {
    //   item.services?.forEach((itemService) => {
    //     if (!!itemService.totalDiscount) {
    //       totalDiscountAllServices += +itemService.totalDiscount;
    //     }
    //   });
    // });
    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (!!itemService.discount?.technicianDiscount?.moneyDiscount) {
          totalDiscountTechnician += +itemService.discount?.technicianDiscount?.moneyDiscount;
        }
      });
    });

    // merchandise
    dataMap.ticketMerchandise?.forEach((item) => {
      totalMerchandise += +item.merchandisePrice;
    });

    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.birthdayDiscount?.moneyDiscount) {
        totalDiscountBirthdayMerchandise += +item.discount?.birthdayDiscount?.moneyDiscount;
      }
    });
    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.weeklyDiscount?.moneyDiscount) {
        totalDiscountWeeklyMerchandise += +item.discount?.weeklyDiscount?.moneyDiscount;
      }
    });
    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.discount?.occasionDiscount?.moneyDiscount) {
        totalDiscountOccasionMerchandise += +item.discount?.occasionDiscount?.moneyDiscount;
      }
    });

    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.isTaxable) {
        checkTaxMerchandise = true;
        if (item.discount?.tax?.moneyDiscount) {
          taxMerchandiseApply += +item.discount?.tax?.moneyDiscount;
        }
      }
    });
    totalDiscountAllMerchandise =
      totalDiscountOccasionMerchandise + totalDiscountWeeklyMerchandise + totalDiscountBirthdayMerchandise;
    // dataMap.ticketMerchandise?.forEach((item) => {
    //   if (!!item.totalDiscount) {
    //     totalDiscountAllMerchandise += +item.totalDiscount;
    //   }
    // });

    if (dataMap && dataMap.giftCards) {
      dataMap.giftCards.forEach((item) => {
        giftCardValue += item.giftCardValue;
      });
    }

    if (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount) {
      totalDiscountGeneralMerchandise =
        discountsAndRewardsApplied?.generalMerchandiseDiscount.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal(
              (totalMerchandise * discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount) / 100
            )
          : discountsAndRewardsApplied?.generalMerchandiseDiscount.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          : 0;
    }

    dataMap.ticketMerchandise?.forEach((item) => {
      if (item.isTaxable) {
        const totalDiscountOcc = item.discount?.occasionDiscount?.moneyDiscount
          ? +item.discount?.occasionDiscount?.moneyDiscount
          : 0;
        const totalDiscountBirth = item.discount?.birthdayDiscount?.moneyDiscount
          ? +item.discount?.birthdayDiscount?.moneyDiscount
          : 0;
        const totalDiscountWeekly = item.discount?.weeklyDiscount?.moneyDiscount
          ? +item.discount?.weeklyDiscount?.moneyDiscount
          : 0;
        const totalDiscount =
          totalDiscountOcc + totalDiscountBirth + totalDiscountWeekly + totalDiscountGeneralMerchandise;
        totalMerchandiseWithTax += +item.merchandisePrice - totalDiscount;
      }
    });

    if (discountsAndRewardsApplied?.generalTicketDiscount?.amount) {
      totalDiscountGeneralTicket =
        discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal(
              ((totalService + totalMerchandise + giftCardValue) *
                discountsAndRewardsApplied?.generalTicketDiscount?.amount) /
                100
            )
          : discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
          : 0;
      // if (dataMap.giftCards && dataMap.giftCards.length) {
      //   totalDiscountGiftCard =
      //     discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Percent
      //       ? handleTruncateToTwoDecimal(
      //           (giftCardValue * discountsAndRewardsApplied?.generalTicketDiscount?.amount) / 100
      //         )
      //       : discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Dollar
      //       ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
      //       : 0;
      // }
    }

    dataMap.technician.forEach((item) => {
      item.services?.forEach((itemService) => {
        if (itemService.isTaxable) {
          let totalDCGeneralService = 0;
          const totalDiscountOcc = itemService.discount?.occasionDiscount?.moneyDiscount
            ? +itemService.discount?.occasionDiscount?.moneyDiscount
            : 0;
          const totalDiscountBirth = itemService.discount?.birthdayDiscount?.moneyDiscount
            ? +itemService.discount?.birthdayDiscount?.moneyDiscount
            : 0;
          const totalDiscountWeekly = itemService.discount?.weeklyDiscount?.moneyDiscount
            ? +itemService.discount?.weeklyDiscount?.moneyDiscount
            : 0;
          if (discountsAndRewardsApplied?.generalTicketDiscount?.amount) {
            totalDCGeneralService =
              discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Percent
                ? handleTruncateToTwoDecimal(
                    (+itemService.price * discountsAndRewardsApplied?.generalTicketDiscount?.amount) / 100
                  )
                : discountsAndRewardsApplied?.generalTicketDiscount.type === DiscountTypeEnum.Dollar
                ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
                : 0;
          }
          const totalDiscount = totalDiscountOcc + totalDiscountBirth + totalDiscountWeekly + totalDCGeneralService;
          totalServiceWithTax += +itemService.price - totalDiscount;
        }
      });
    });

    if (discountsAndRewardsApplied?.referralDiscount?.amount) {
      totalDiscountReferral = +discountsAndRewardsApplied?.referralDiscount?.amount;
    }

    const totalDiscount: number =
      totalDiscountGeneralMerchandise +
        totalDiscountGeneralTicket +
        totalDiscountReferral +
        totalDiscountAllServices +
        totalDiscountAllMerchandise ?? 0;

    const total: number =
      totalService +
      totalMerchandise +
      // (totalDiscountAllServices + totalDiscountAllMerchandise) +
      (taxMerchandiseApply + taxServiceApply) +
      totalBuyGiftCard;

    if (discountsAndRewardsApplied?.rewardBalance?.amount) {
      totalRedeeming =
        discountsAndRewardsApplied?.rewardBalance.type === DiscountTypeEnum.Percent
          ? handleTruncateToTwoDecimal(
              ((total - totalDiscount) * discountsAndRewardsApplied?.rewardBalance?.amount) / 100
            )
          : discountsAndRewardsApplied?.rewardBalance.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.rewardBalance?.amount
          : 0;
    }

    const saleTax =
      checkTaxService || checkTaxMerchandise
        ? 0
        : +taxPercent > 0
        ? handleTruncateToTwoDecimal(((total - totalDiscount - totalRedeeming) * +taxPercent) / 100)
        : 0;

    const totalServiceCharge =
      +serviceCharge.chargeValue > 0
        ? handleTruncateToTwoDecimal(
            ((total - totalDiscount - totalRedeeming + saleTax) * +serviceCharge.chargeValue) / 100
          )
        : 0;

    const totalPayment =
      total + saleTax + totalServiceCharge - totalDiscount - totalRedeeming > 0
        ? total + saleTax + totalServiceCharge - totalDiscount - totalRedeeming
        : 0;
    if (dataMap.deposit) {
      totalDeposit = dataMap.deposit;
    }

    const giftCardPaymentValue: number = paymentInformation.giftCardPayment?.totalValue ?? 0;

    if (totalPayment) {
      const tempBalanceTotal: number = totalPayment - totalDeposit - listTotal.totalCash - giftCardPaymentValue;
      totalBalanceTotalPay = tempBalanceTotal > 0 ? tempBalanceTotal : 0;
    } else {
      totalBalanceTotalPay = 0;
    }

    setListTotal({
      ...listTotal,
      total: total,
      totalPayments: totalPayment,
      totalBalanceTotalPay: totalBalanceTotalPay,
      saleTax: saleTax,
      serviceCharge: totalServiceCharge,
      totalDiscountGeneralMerchandise: totalDiscountGeneralMerchandise,
      totalDiscountGeneralTicket: totalDiscountGeneralTicket,
      totalDiscount: totalDiscount,
      totalDiscountBirthday: totalDiscountBirthdayMerchandise + totalDiscountBirthdayService,
      totalDiscountWeekly: totalDiscountWeeklyMerchandise + totalDiscountWeeklyService,
      totalDiscountOccasion: totalDiscountOccasionMerchandise + totalDiscountOccasionService,
      totalDiscountTechnician: totalDiscountTechnician,
      generalItemDiscount: totalDiscountGeneralMerchandise,
      generalTicketDiscount: totalDiscountGeneralTicket,
      referralDiscount: totalDiscountReferral,
      totalRedeeming: totalRedeeming,
      totalDeposit: totalDeposit,
    });

    setTaxDiscount(saleTax);
  };

  const handleRemoveTechnician = (idDeleteTechnician: number) => {
    const technicianRemove = appointmentBill.technician.filter((item) => item.id !== idDeleteTechnician);
    if (technicianRemove) {
      appointmentBill.technician.some((item) => {
        if (item.id === idDeleteTechnician) {
          const updateTechnician = {
            ...appointmentBill,
            technician: technicianRemove,
          };
          setAppointmentBill({
            ...updateTechnician,
            deposit: idTechnicianDeposit !== idDeleteTechnician ? appointmentBill.deposit : 0,
          });
          handleGetTotal({
            ...updateTechnician,
            deposit: idTechnicianDeposit !== idDeleteTechnician ? appointmentBill.deposit : 0,
          });
          if (updateTechnician.technician.length > 0) {
            setIdTechnician(updateTechnician.technician[0].id);
            setListSkill(updateTechnician.technician[0].skills ?? []);
          } else {
            setIdTechnician(0);
            setListSkill([]);
          }

          setListIdDisable({
            ...listIdDisable,
            listIdServices: handleSortListServices(
              listIdDisable.listIdServices as number[],
              item.services?.map((item) => item.id) as number[]
            ),
            listIdTechnician: technicianRemove.map((item) => item.id) as number[],
          });
          setServiceApply([]);
        }
        return false;
      });
      appointments.filter((item) => {
        if (item.technicianId === idDeleteTechnician) {
          return setIdAppointment((preState) => preState.filter((itemApp: number) => itemApp !== item.id));
        }
        return item;
      });
      setIdTechnicianDeposit(0);
    }
  };

  const printJobMutation = useMutation((payload: FormData) => fetchApiPrint(payload), {
    onSuccess: () => {
      setIsLoadingPdf(false);
    },
    onError: ({ response }) => {
      setIsLoadingPdf(false);
      NotificationError({ contentNoti: 'Please connect printer!!' });
    },
  });

  const handleSortListServices = (arrBase: number[], arrDelete: number[]) => {
    const arrValue: boolean[] = [];
    const arrDiff: number[] = [];
    for (let i = 0; i < arrBase.length; i++) {
      arrValue[arrBase[i]] = true;
    }
    if (arrDelete && !!arrDelete.length) {
      for (let i = 0; i < arrDelete.length; i++) {
        if (arrValue[arrDelete[i]]) {
          delete arrValue[arrDelete[i]];
        } else {
          arrValue[arrDelete[i]] = true;
        }
      }
    }
    for (let value in arrValue) {
      arrDiff.push(+value);
    }
    return arrDiff;
  };
  const handleRemoveServices = (idDeleteServices: number, idTechnician: number) => {
    setIdTechnician(idTechnician);
    appointmentBill.technician.some((item) => {
      if (item.id === idTechnician) {
        const found = item.services?.filter((serviceItem) => serviceItem.idDelete !== idDeleteServices);

        if (found) {
          const updateService = {
            ...appointmentBill,
            technician: appointmentBill.technician.map((item) => {
              return {
                ...item,
                services: idTechnician === item.id ? found : item.services,
              };
            }),
          };
          setListIdDisable({
            ...listIdDisable,
            listIdServices: found.map((item) => item.id),
          });
          checkListServiceInTechnician(idTechnician as number, true, idDeleteServices);
          setAppointmentBill(updateService);
          handleGetTotal(updateService);
          return true;
        }
      }
      return false;
    });
  };
  const handleRemoveServiceDiscount = (
    idDeleteServices: number,
    idTechnician: number,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean,
    isRemoveDiscountTech: boolean,
    isRemoveTax: boolean
  ) => {
    appointmentBill.technician.some((item) => {
      if (item.id === idTechnician) {
        const updateService = {
          ...appointmentBill,
          technician: appointmentBill.technician.map((item) => {
            return {
              ...item,
              services:
                idTechnician === item.id
                  ? item.services?.map((itemFound) => {
                      return {
                        ...itemFound,
                        discount: {
                          ...itemFound.discount,
                          weeklyDiscount: isRemoveWeekly
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.weeklyDiscount
                            : itemFound.discount?.weeklyDiscount,
                          birthdayDiscount: isRemoveBirthday
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.birthdayDiscount
                            : itemFound.discount?.birthdayDiscount,
                          occasionDiscount: isRemoveOccasion
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.occasionDiscount
                            : itemFound.discount?.occasionDiscount,
                          technicianDiscount: isRemoveDiscountTech
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.technicianDiscount
                            : itemFound.discount?.technicianDiscount,
                          tax: isRemoveTax
                            ? idDeleteServices === itemFound.id
                              ? undefined
                              : itemFound.discount?.tax
                            : itemFound.discount?.tax,
                        },
                      };
                    })
                  : item.services,
            };
          }),
        };
        const updateTaxService = updateService.technician.map((item) => {
          return {
            ...item,
            services: item.services?.map((itemService) => {
              const discountBirthday = itemService.discount?.birthdayDiscount?.moneyDiscount ?? 0;
              const discountWeekly = itemService.discount?.weeklyDiscount?.moneyDiscount ?? 0;
              const discountOccasion = itemService.discount?.occasionDiscount?.moneyDiscount ?? 0;
              const discountTech = itemService.discount?.technicianDiscount?.moneyDiscount ?? 0;

              const totalDiscount = discountBirthday + discountWeekly + discountOccasion + discountTech;
              const tax =
                itemService.isTaxable && +taxPercent > 0
                  ? {
                      type: DiscountTypeEnum.Percent,
                      moneyDiscount: handleTruncateToTwoDecimal(
                        ((itemService.price - totalDiscount) * +taxPercent) / 100
                      ),
                    }
                  : undefined;
              return {
                ...itemService,
                totalDiscount: totalDiscount,
                discount: {
                  ...itemService.discount,
                  tax: tax,
                },
              };
            }),
          };
        });
        setAppointmentBill({
          ...updateService,
          technician: updateTaxService,
        });
        handleGetTotal({
          ...updateService,
          technician: updateTaxService,
        });
        return true;
      }
      return false;
    });
  };

  const handleRemoveMerchandiseDiscount = (
    idDeleteMerchandise: number | undefined,
    isRemoveWeekly: boolean,
    isRemoveBirthday: boolean,
    isRemoveOccasion: boolean,
    isRemoveTax: boolean
  ) => {
    if (appointmentBill.ticketMerchandise && idDeleteMerchandise) {
      const ticketMerchandiseDelete = appointmentBill.ticketMerchandise.map((item) => {
        if (item.merchandiseId === idDeleteMerchandise) {
          return {
            ...item,
            discount: {
              ...item.discount,
              weeklyDiscount: isRemoveWeekly
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.weeklyDiscount
                : item.discount?.weeklyDiscount,
              birthdayDiscount: isRemoveBirthday
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.birthdayDiscount
                : item.discount?.birthdayDiscount,
              occasionDiscount: isRemoveOccasion
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.occasionDiscount
                : item.discount?.occasionDiscount,
              tax: isRemoveTax
                ? item.merchandiseId === idDeleteMerchandise
                  ? undefined
                  : item.discount?.tax
                : item.discount?.tax,
            },
          };
        }
        return item;
      });
      const updateTaxMerchandise = ticketMerchandiseDelete.map((item) => {
        const discountBirthday = item.discount?.birthdayDiscount?.moneyDiscount ?? 0;
        const discountWeekly = item.discount?.weeklyDiscount?.moneyDiscount ?? 0;
        const discountOccasion = item.discount?.occasionDiscount?.moneyDiscount ?? 0;

        const totalDiscount = discountBirthday + discountWeekly + discountOccasion;
        const tax =
          item.isTaxable && +taxPercent > 0
            ? {
                type: DiscountTypeEnum.Percent,
                moneyDiscount: handleTruncateToTwoDecimal(
                  ((item.merchandisePrice - totalDiscount) * +taxPercent) / 100
                ),
              }
            : undefined;
        return {
          ...item,
          totalDiscount: totalDiscount,
          discount: {
            ...item.discount,
            tax: tax,
          },
        };
      });
      const updateMerchandise = {
        ...appointmentBill,
        ticketMerchandise: updateTaxMerchandise,
      };
      setAppointmentBill(updateMerchandise);
      handleGetTotal(updateMerchandise);
    }
  };

  const handleRemoveMerchandise = (idDeleteMerchandise: number | undefined) => {
    if (appointmentBill.ticketMerchandise && idDeleteMerchandise) {
      const ticketMerchandiseDelete = appointmentBill.ticketMerchandise.filter(
        (merchandise) => merchandise.idDelete !== idDeleteMerchandise
      );
      if (ticketMerchandiseDelete) {
        setListIdDisable({
          ...listIdDisable,
          listIdMerchandise: ticketMerchandiseDelete.map((item) => item.merchandiseId) as number[],
        });
        const updateMerchandise = {
          ...appointmentBill,
          ticketMerchandise: ticketMerchandiseDelete,
        };
        setAppointmentBill(updateMerchandise);
        handleGetTotal(updateMerchandise);
      }
    }
  };
  const handleRemoveCustomer = () => {
    if (appointmentBill.customer) {
      const ticketTechnician = appointmentBill.technician.map((item) => {
        return {
          ...item,
          services: item.services?.map((itemService) => {
            return {
              ...itemService,
              discount: {
                ...itemService.discount,
                birthdayDiscount: undefined,
                referralDiscount: undefined,
                rewardBalance: undefined,
              },
            };
          }),
        };
      });
      const ticketMerchandise = appointmentBill.ticketMerchandise.map((item) => {
        return {
          ...item,
          discount: {
            ...item.discount,
            birthdayDiscount: undefined,
            referralDiscount: undefined,
            rewardBalance: undefined,
          },
        };
      });
      const updateCustomer = {
        ...appointmentBill,
        technician: ticketTechnician,
        ticketMerchandise: ticketMerchandise,
        customer: undefined,
        customerId: 0,
      };
      setAppointmentBill(updateCustomer);
      handleGetTotal(updateCustomer);
      setDiscountsAndRewardsApplied({
        ...discountsAndRewardsApplied,
        birthdayDiscount: undefined,
        referralDiscount: undefined,
        rewardBalance: undefined,
      });
    }
  };
  const handleRemoveGiftCard = (idGiftCard: number) => {
    if (!!appointmentBill.giftCards?.length) {
      const updateCustomer = {
        ...appointmentBill,
        giftCards: appointmentBill.giftCards.filter((item) => item.id !== idGiftCard) as IGiftCard[],
      };
      setAppointmentBill(updateCustomer);
      handleGetTotal(updateCustomer);
    }
  };

  const resetBill = () => {
    removeSessionStorageByName('appointmentBill');
    setListIdDisable({
      listIdServices: [],
      listIdTechnician: [],
      listIdMerchandise: [],
    });
    setAppointmentBill(initialBill);
    setDiscountsAndRewardsApplied(undefined);
    setIdTechnician(0);
    setListSkill([]);
    setIdAppointment([]);
    setIdTechnicianDeposit(0);
    setIsEditServicePrice(false);
    setTaxPercent(0);
    setTaxDiscount(0);
    setServiceCharge({
      chargeValue: 0,
      name: '',
    });
    setServiceApply([]);
    setListTotal(initialListTotal);
    setPaymentInformation(initialPromotion);
    handleRefetchPromotion();
  };

  const onPrintPdf = async () => {
    setIsLoadingPdf(true);
    const cloneRef = printBillComponentRef.current;

    if (!cloneRef) return false;
    cloneRef.style.marginLeft = '10px';
    cloneRef.style.marginRight = '10px';
    const componentWidth = cloneRef.offsetWidth + 20;
    const componentHeight = cloneRef.clientHeight;

    const orientation = componentWidth >= componentHeight ? 'l' : 'p';

    // const convertComponentToPng = await toPng(techDailySummaryRef.current);
    const scale = 153 / componentWidth;

    const pdf = new jsPDF({
      orientation,
      unit: 'px',
      format: [153, 153 * 3.7],
    });

    // pdf.internal.pageSize.width = 153;

    // pdf.internal.pageSize.height = componentHeight * scale;

    await pdf.html(cloneRef, { html2canvas: { scale: scale }, x: 0, y: 0, autoPaging: 'text' });

    const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

    let newUploadFile = new FormData();
    newUploadFile.append('file', blob);

    printJobMutation.mutate(newUploadFile);
  };

  const onPrintTicket = () => {
    if (!settingPrinter) return false;

    if (settingPrinter?.cloverPrinter === SettingPrinterCloverPrinterEnum.PrinterConnectedViaUsb) {
      setTimeout(async () => {
        onPrintPdf();
      }, 900);
      return true;
    }

    onConnect();

    setTimeout(async () => {
      try {
        if (printBillComponentRef.current) {
          const convertComponentToPng = await toPng(printBillComponentRef.current);

          if (convertComponentToPng) {
            cloverDevice.performPrint({
              base64Image: convertComponentToPng,
            });

            setTimeout(() => {
              cloverDevice.performPrint({
                base64Image: convertComponentToPng,
              });
            }, 300);
          }
        }
      } catch (error) {
        throw error;
      }
    }, 900);
  };

  const createCheckoutMutation = useMutation((ticketDto: TicketDTO) => ticketApi.ticketControllerCreate(ticketDto), {
    onSettled: () => {
      if (saleResponse) {
        dispatch(setSaleResponse(undefined));
      }
    },
    onSuccess: ({ data }) => {
      const settingCheckout = listSetting?.data?.settingCheckout;
      const settingTerminalDevice = listSetting?.data?.settingTerminalDevice;

      if (!tipInformation?.tip || !data.totalTip || !listSetting?.data?.settingTip?.askEnteringTipWhenCheckout) {
        resetBill();
        if (data.status !== TicketDTOStatusEnum.Hold) {
          setIsOpenPayAndComplete(false);
        }
      }
      if (data.status === TicketDTOStatusEnum.Hold) {
        resetBill();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.pending' }) });
      } else {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'checkout.payAndComplete.noti.saleCompleted' }) });

        if (settingCheckout && settingCheckout.autoPrintTicketSummaryCheckoutCompletion && settingTerminalDevice) {
          setTicketToPrint(data);
          onPrintTicket();
        }
      }
      setTipInformation((prevState) => ({ ...prevState, ticketId: data.id, technicianTurns: data.technicianTurns }));
      handleRefetchAppointment();
      handleRefetchTicketPending();
      handleRefetchLastTicket();
      removeSessionStorageByName('appointmentBill');
    },
    onError: (error) => {
      resetBill();
    },
  });

  const handleChangeOpenPayAndComplete = (type: PayAndCompleteEnum, isOpen: boolean) => {
    setIsOpenPayAndComplete(isOpen);

    if (type === PayAndCompleteEnum.Tips && !isOpen) {
      resetBill();
    }
  };

  const handleSubmitCheckout = (submitParams?: IHandleSubmitCheckoutProps) => {
    const rewarding: number =
      (listPromotion &&
        listPromotion.data &&
        listPromotion.data.loyaltyReward &&
        listPromotion.data.loyaltyReward.reward &&
        listPromotion.data.loyaltyReward.reward * Math.floor(listTotal.totalPayments)) ??
      0;
    const data: TicketDTO = {
      appointmentId: appointmentBill.appointmentId,
      combineId: appointmentBill.combineId,
      customerId: appointmentBill.customerId || undefined,
      giftCards: appointmentBill.giftCards,
      serviceTax: {
        moneyDiscount: listTotal.saleTax,
        percentDiscount: +taxPercent,
      },
      serviceCharge: {
        moneyDiscount: listTotal.serviceCharge,
        name: serviceCharge.name,
        percentDiscount: serviceCharge.chargeValue,
      },
      total: listTotal.total ?? 0,
      totalDiscount: listTotal.totalDiscount ?? 0,
      totalPayment: listTotal.totalPayments ?? 0,
      discount: {
        birthdayDiscount: discountsAndRewardsApplied?.birthdayDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
              type: discountsAndRewardsApplied?.birthdayDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountBirthday),
            }
          : undefined,
        generalItemDiscount: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount,
              type: discountsAndRewardsApplied?.generalMerchandiseDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalItemDiscount),
            }
          : undefined,
        generalTicketDiscount: discountsAndRewardsApplied?.generalTicketDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.generalTicketDiscount?.amount,
              type: discountsAndRewardsApplied?.generalTicketDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.generalTicketDiscount),
            }
          : undefined,
        occasionDiscount: discountsAndRewardsApplied?.occasionDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
              type: discountsAndRewardsApplied?.occasionDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountOccasion),
            }
          : undefined,
        referralDiscount: !!appointmentBill.customer?.tickets?.filter(
          (ticket) => ticket.status === TicketStatusEnum.Completed
        )?.length
          ? undefined
          : discountsAndRewardsApplied?.referralDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.referralDiscount?.amount,
              type: discountsAndRewardsApplied?.referralDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.referralDiscount),
              referralCode: discountsAndRewardsApplied.referralDiscount.referralCode,
            }
          : undefined,
        weeklyDiscount: discountsAndRewardsApplied?.weeklyDiscount?.amount
          ? {
              discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
              type: discountsAndRewardsApplied?.weeklyDiscount?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalDiscountWeekly),
            }
          : undefined,
        loyaltyReward: undefined,
        rewardBalance: discountsAndRewardsApplied?.rewardBalance?.amount
          ? {
              discountApply: discountsAndRewardsApplied.rewardBalance.amount,
              type: discountsAndRewardsApplied?.rewardBalance?.type,
              moneyDiscount: handleTruncateToTwoDecimal(listTotal.totalRedeeming),
              point: discountsAndRewardsApplied.rewardBalance.points,
              isCustomAmount: discountsAndRewardsApplied.rewardBalance.isCustomAmount,
            }
          : undefined,
        // rewardUse: discountsAndRewardsApplied?.rewardBalance?.points ?? 0,
      },
      deposit: appointmentBill.deposit ?? 0,
      balanceToPay: submitParams?.balanceToPay ?? listTotal.totalBalanceTotalPay ?? 0,
      status: submitParams?.status || appointmentBill.status,
      technicianTurns: appointmentBill.technician.map((item: ITechnicianItem) => {
        return {
          technicianId: item.id,
          serviceTurns:
            item.services &&
            item.services.map((itemService: IServicesItem) => {
              let totalDiscount: number = 0;
              if (itemService && itemService.discount) {
                if (itemService.discount.birthdayDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.birthdayDiscount?.moneyDiscount;
                }
                if (itemService.discount.weeklyDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.weeklyDiscount?.moneyDiscount;
                }
                if (itemService.discount.occasionDiscount?.moneyDiscount) {
                  totalDiscount += +itemService.discount.occasionDiscount?.moneyDiscount;
                }
              }
              return {
                isGeneralService: itemService.isGeneralService,
                price: itemService.price,
                supply: itemService.supply,
                serviceId: itemService.id,
                discount: itemService.discount,
                totalDiscount: totalDiscount,
              };
            }),
        };
      }) as TechnicianTurnDTO[],
      ticketMerchandise:
        appointmentBill.ticketMerchandise &&
        appointmentBill.ticketMerchandise.map((item) => {
          let totalDiscount: number = 0;
          if (item && item.discount) {
            if (item.discount.birthdayDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.birthdayDiscount?.moneyDiscount;
            }
            if (item.discount.weeklyDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.weeklyDiscount?.moneyDiscount;
            }
            if (item.discount.occasionDiscount?.moneyDiscount) {
              totalDiscount += +item.discount.occasionDiscount?.moneyDiscount;
            }
          }
          return {
            merchandisePrice: item.merchandisePrice,
            merchandiseId: item.merchandiseId,
            discount: item.discount,
            totalDiscount: totalDiscount,
          };
        }),
      ticketTransaction: submitParams?.ticketTransaction || paymentInformation.ticketTransaction || [],
      totalTip: submitParams?.totalTip || tipInformation?.tip || 0,
      rewarding: rewarding,
    };

    createCheckoutMutation.mutate(data);
  };

  const handleClickOption = (
    id: string | number,
    sourceDroppableId: typeof DesiredStatusesItem[keyof typeof DesiredStatusesItem] | string
  ) => {
    // if (
    //   ((sourceDroppableId === DesiredStatusesItem.ListTechnician &&
    //     !!(listIdDisable.listIdTechnician as number[]).includes(+id)) ||
    //     (idAppointment.includes(+id) &&
    //       (sourceDroppableId === DesiredStatusesItem.CheckIn ||
    //         sourceDroppableId === DesiredStatusesItem.BeingServed)) ||
    //     !!(listIdDisable.listIdMerchandise as number[]).includes(+id)) &&
    //   !!(serviceApply as number[]).includes(+id)
    // )
    //   return;
    onDragEnd({
      combine: null,
      destination: {
        droppableId: DesiredStatusesItem.CheckOutBIll,
        index: 0,
      },
      reason: 'DROP',
      mode: 'FLUID',
      draggableId: id.toString(),
      type: 'DEFAULT',
      source: {
        droppableId: sourceDroppableId,
        index: Number(id),
      },
    });
  };

  const checkDisableService = (item: ServiceItems) => {
    // if (item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)) {
    if (item.isMerchandise) {
      return false;
    } else {
      const valueCheck: boolean =
        // (serviceApply as number[]).includes(+item.id) ||
        !listSkill?.includes(+item.id) || (listIdDisable.listIdMerchandise as number[])?.includes(+item.id);
      if (!Number.isInteger(idTechnician)) {
        return valueCheck;
      } else {
        if (
          !!dataInitialAppointmentBill &&
          dataInitialAppointmentBill.technician.length === appointmentBill.technician.length
        ) {
          return valueCheck;
        } else {
          return !listSkill.includes(+item.id) && !!listIdDisable.listIdTechnician.length;
        }
      }
    }
  };

  const technicianItems: CollapseProps['items'] = [
    {
      key: 1,
      label: (
        <span className="font-size-16 font-weight-600 color-292F33">
          {intl.formatMessage({ id: 'checkout.technician' })}
          {' (' + listTechnician.length + ')'}
        </span>
      ),
      children: (
        <Droppable
          droppableId={DesiredStatusesItem.ListTechnician}
          key={DesiredStatusesItem.ListTechnician}
          direction="horizontal"
          isDropDisabled={true}
        >
          {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
            <React.Fragment>
              <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} className="d-none" />
              <div className="d-flex align-items-center flex-wrap gap-8">
                <div
                  className="salon__checkout-edit-technician"
                  onClick={() => {
                    setOpenModal({
                      ...openModal,
                      openListTechnician: true,
                    });
                  }}
                >
                  <SvgMultiUserIcon />
                  <span className="salon__checkout-edit-technician-title">
                    {intl.formatMessage({ id: 'checkout.options' })}
                  </span>
                </div>
                {listTechnician.map((item, index) => (
                  <Draggable
                    draggableId={`tech_${item.id}`}
                    index={+item.id}
                    key={item.id}
                    isDragDisabled={!!(listIdDisable.listIdTechnician as number[]).includes(+item.id)}
                  >
                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                      <React.Fragment>
                        <div
                          ref={providedDrag?.innerRef}
                          {...providedDrag?.draggableProps}
                          {...providedDrag?.dragHandleProps}
                          {...snapshotDrag}
                          className={
                            !!(listIdDisable.listIdTechnician as number[]).includes(+item.id)
                              ? 'salon__checkout-disable-draggable'
                              : ''
                          }
                          onClick={() =>
                            !!(listIdDisable.listIdTechnician as number[]).includes(+item.id)
                              ? null
                              : handleClickOption(item.id, DesiredStatusesItem.ListTechnician)
                          }
                        >
                          {!snapshotDrag.isDropAnimating && (
                            <TechnicianItem
                              imgSrc={item.defaultAvatar}
                              imgSrcTitle={item.name}
                              technicianName={item.name}
                              onClick={() => {}}
                            />
                          )}
                        </div>
                        {snapshotDrag.isDragging && (
                          <TechnicianItem
                            imgSrc={item.defaultAvatar}
                            imgSrcTitle={item.name}
                            technicianName={item.name}
                            onClick={() => {}}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      ),
    },
  ];
  const serviceItems: CollapseProps['items'] = listDataServices?.data?.content?.map((service, index) => {
    return {
      key: index + 1,
      label: (
        <span className="font-size-16 font-weight-600 color-292F33">
          {service.name}
          {' (' + service.serviceItems.length + ')'}
        </span>
      ),
      children: (
        <Droppable droppableId={service.name} key={service.name} direction="horizontal" isDropDisabled={true}>
          {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
            <React.Fragment>
              <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} />
              <div className="d-flex align-items-center flex-wrap gap-8">
                {service.serviceItems.map((item) => (
                  <Draggable
                    draggableId={`service_${item.id}`}
                    index={item.id}
                    key={item.id}
                    isDragDisabled={checkDisableService(item)}
                  >
                    {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
                      <React.Fragment>
                        <div
                          ref={providedDrag?.innerRef}
                          {...providedDrag?.draggableProps}
                          {...providedDrag?.dragHandleProps}
                          {...snapshotDrag}
                          className={`${
                            item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)
                              ? false
                              : checkDisableService(item)
                              ? 'salon__checkout-disable-draggable'
                              : ''
                          }`}
                          onClick={() =>
                            item.isMerchandise && !(listIdDisable.listIdMerchandise as number[]).includes(+item.id)
                              ? handleClickOption(item.id, service.name)
                              : checkDisableService(item)
                              ? null
                              : handleClickOption(item.id, service.name)
                          }
                        >
                          {!snapshotDrag.isDropAnimating && (
                            <ServiceItemSmall
                              isMerchandise={item.isMerchandise}
                              name={item.name}
                              price={item?.price?.toString()}
                              time={item?.time?.toString()}
                              key={item.id}
                              backgroundColor={item.backgroundColor}
                              isServiceCreate={false}
                            />
                          )}
                        </div>
                        {snapshotDrag.isDragging && (
                          <ServiceItemSmall
                            isMerchandise={item.isMerchandise}
                            name={item.name}
                            price={item?.price?.toString()}
                            time={item?.time?.toString()}
                            key={item.id}
                            backgroundColor={item.backgroundColor}
                            isServiceCreate={false}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      ),
    };
  });

  const updateAppointmentMutation = useMutation(
    (changeStatusAppointments: ChangeStatusAppointments) =>
      appointmentApi.appointmentControllerChangeStatusAppointments(changeStatusAppointments, undefined, undefined),
    {
      onSuccess: ({ data }) => {
        handleRefetchAppointment();
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        handleRefetchAppointment();
      },
    }
  );

  const handleUpdateAppoint = () => {
    const filterAppointment = appointments.filter((appointment) => appointment.status === DesiredStatusesItem.CheckIn);
    updateAppointmentMutation.mutate({
      status: 'Being_served',
      appointmentIds: filterAppointment.map((item) => item.id),
    });
  };

  useScanDetection({
    onComplete: (code: String) => {
      const filterAppointments = appointments.filter(
        (appointment) => appointment.status === AppointmentStatusEnum.CheckedIn
      );

      const findSourceIndex = filterAppointments.findIndex(
        (appointment) => appointment.orderNumberCheckinToday === Number(code)
      );

      if (findSourceIndex > -1) {
        handleClickOption(findSourceIndex, DesiredStatusesItem.CheckIn);
      }
    },
  });

  useEffect(() => {
    if (listAppointments?.data?.content && listAppointments?.data?.content?.length > 0) {
      setAppointments(listAppointments?.data?.content);
    }
  }, [listAppointments]);

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      const dataTechnician: ITechnicianItem[] = [];
      listTechnicians.data.forEach((technician) => {
        const resource: ITechnicianItem = {
          defaultAvatar:
            technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
              ? technician.defaultAvatar ||
                `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
              : AvatarDefault,
          name: technician.name,
          id: technician.id,
          phoneNumber: technician.phoneNumber,
          title: technician.title,
          skills:
            (
              technician.skills.map((item) => {
                if (!item.isMerchandise) {
                  return item.id;
                }

                return undefined;
              }) as number[]
            ).filter((item) => item) ?? [],
        };
        dataTechnician.push(resource);
      });
      setListTechnician(!filterTechnician.isAll && !filterTechnician.filterBy ? [] : dataTechnician);
    }
  }, [listTechnicians?.data, filterTechnician, idTechnician]);

  useEffect(() => {
    if (+taxPercent > 0 || +taxPercent === -1) {
      const dataAppointment = appointmentBill.ticketMerchandise.map((item) => {
        const finalDiscount = item.totalDiscount ?? 0;
        const tax = item.isTaxable
          ? {
              type: DiscountTypeEnum.Percent,
              moneyDiscount: handleTruncateToTwoDecimal(((item.merchandisePrice - finalDiscount) * +taxPercent) / 100),
            }
          : undefined;
        return {
          ...item,
          discount: {
            ...item.discount,
            tax: tax,
          },
        };
      });
      const dataAppointmentTechnician = appointmentBill.technician.map((item) => {
        return {
          ...item,
          services: item.services?.map((itemService) => {
            const finalDiscount = itemService.totalDiscount ?? 0;
            const tax = itemService.isTaxable
              ? {
                  type: DiscountTypeEnum.Percent,
                  moneyDiscount: handleTruncateToTwoDecimal(((itemService.price - finalDiscount) * +taxPercent) / 100),
                }
              : undefined;
            return {
              ...itemService,
              discount: {
                ...itemService.discount,
                tax: tax,
              },
            };
          }),
        };
      });
      const dataUpdateAppointment = {
        ...appointmentBill,
        ticketMerchandise: dataAppointment,
        technician: dataAppointmentTechnician,
      };
      handleGetTotal(dataUpdateAppointment);
      setAppointmentBill(dataUpdateAppointment);
    }
  }, [taxPercent]);

  useEffect(() => {
    if (listPromotion?.data.feeDiscount?.autoOption && serviceCharge.chargeValue !== -1) {
      handleGetTotal(appointmentBill);
      setServiceCharge({
        chargeValue: +listPromotion?.data.feeDiscount?.autoOption,
        name: listPromotion?.data.feeDiscount?.feeName,
      });
    }
  }, [listPromotion]);

  useEffect(() => {
    if (serviceCharge.chargeValue > 0 || serviceCharge.chargeValue === -1 || serviceCharge.chargeValue === 0) {
      handleGetTotal(appointmentBill);
    }
  }, [serviceCharge]);

  useEffect(() => {
    if (
      paymentInformation &&
      paymentInformation.giftCardPayment?.totalValue &&
      paymentInformation.giftCardPayment?.totalValue > 0
    ) {
      handleGetTotal(appointmentBill);
    }
  }, [paymentInformation]);

  useEffect(() => {
    if (listTotal.totalCash > 0) {
      handleGetTotal(appointmentBill);
    }
  }, [listTotal.totalCash]);

  useEffect(() => {
    if (
      !!discountsAndRewardsApplied?.occasionDiscount ||
      !!discountsAndRewardsApplied?.weeklyDiscount ||
      !!discountsAndRewardsApplied?.birthdayDiscount ||
      !!discountsAndRewardsApplied?.rewardBalance ||
      !!discountsAndRewardsApplied?.generalMerchandiseDiscount ||
      !!discountsAndRewardsApplied?.generalTicketDiscount
    ) {
      const dataUpdateBill = {
        ...appointmentBill,
        technician: appointmentBill.technician.map((item: ITechnicianItem) => {
          return {
            ...item,
            services:
              item.services &&
              item.services.map((itemService: IServicesItem) => {
                let totalDiscount = itemService.totalDiscount ?? 0;
                let occasionDiscount = undefined;
                let weeklyDiscount = undefined;
                let birthdayDiscount = undefined;
                let technicianDiscount = undefined;

                const occasionDiscountValue = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                  itemService.id
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                      type: discountsAndRewardsApplied?.occasionDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.occasionDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (itemService.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const weeklyDiscountValue = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(
                  itemService.id
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                      type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (itemService.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const birthdayDiscountValue = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                  itemService.id
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                      type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (itemService.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;

                const technicianDiscountValue = {
                  discountApply: itemService.discount?.technicianDiscount?.discountApply,
                  type: itemService.discount?.technicianDiscount?.type,
                  moneyDiscount: itemService.discount?.technicianDiscount?.moneyDiscount,
                };

                const generalDiscountTicket =
                  discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (handleConvertPrice(itemService.price.toString()) *
                          (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                          100
                      );

                const priceAfterDiscount: number = itemService.price - totalDiscount;

                const weeklyDiscountPrice = weeklyDiscountValue?.moneyDiscount ?? 0;
                weeklyDiscount =
                  weeklyDiscountPrice <= priceAfterDiscount
                    ? weeklyDiscountValue
                    : itemService.discount?.weeklyDiscount;

                const birthdayDiscountPrice = birthdayDiscountValue?.moneyDiscount ?? 0;
                birthdayDiscount =
                  birthdayDiscountPrice <= priceAfterDiscount
                    ? birthdayDiscountValue
                    : itemService.discount?.birthdayDiscount;

                const occasionDiscountPrice = occasionDiscountValue?.moneyDiscount ?? 0;
                occasionDiscount =
                  occasionDiscountPrice <= priceAfterDiscount
                    ? occasionDiscountValue
                    : itemService.discount?.occasionDiscount;

                const technicianDiscountPrice = technicianDiscountValue?.moneyDiscount ?? 0;
                technicianDiscount =
                  technicianDiscountPrice <= priceAfterDiscount
                    ? technicianDiscountValue
                    : itemService.discount?.occasionDiscount;

                const totalDiscountService: number =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                    (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                    (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                    (!!technicianDiscount?.moneyDiscount ? +technicianDiscount?.moneyDiscount : 0) +
                    (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

                const totalDiscountWithPromotion: number =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                  (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                  (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                  (!!technicianDiscount?.moneyDiscount ? +technicianDiscount?.moneyDiscount : 0);
                const finalDiscount =
                  totalDiscountWithPromotion >= +itemService.price ? +itemService.price : totalDiscountWithPromotion;
                const tax =
                  itemService.isTaxable && +taxPercent > 0
                    ? {
                        type: DiscountTypeEnum.Percent,
                        moneyDiscount: handleTruncateToTwoDecimal(
                          ((itemService.price - finalDiscount) * +taxPercent) / 100
                        ),
                      }
                    : undefined;
                return {
                  ...itemService,
                  totalDiscount: totalDiscountService >= +itemService.price ? +itemService.price : totalDiscountService,
                  discount: {
                    ...itemService.discount,
                    occasionDiscount: occasionDiscount,
                    weeklyDiscount: weeklyDiscount,
                    birthdayDiscount: birthdayDiscount,
                    technicianDiscount: technicianDiscountPrice ? technicianDiscount : undefined,
                    tax: tax,
                  },
                };
              }),
          };
        }),
        ticketMerchandise: appointmentBill.ticketMerchandise.map((item) => {
          let totalDiscount = item.totalDiscount ?? 0;
          let occasionDiscount = undefined;
          let weeklyDiscount = undefined;
          let birthdayDiscount = undefined;

          const occasionDiscountValue = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
            item.merchandiseId as number
          )
            ? {
                discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                type: discountsAndRewardsApplied?.occasionDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.occasionDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.merchandisePrice * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const weeklyDiscountValue = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(
            item.merchandiseId as number
          )
            ? {
                discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.merchandisePrice * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const birthdayDiscountValue = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
            item.merchandiseId as number
          )
            ? {
                discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                moneyDiscount:
                  discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                    ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                    : handleTruncateToTwoDecimal(
                        (item.merchandisePrice * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                      ),
              }
            : undefined;
          const generalDiscountMerchandise =
            discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar
              ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
              : handleTruncateToTwoDecimal(
                  (handleConvertPrice(item.merchandisePrice.toString()) *
                    (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount ?? 0)) /
                    100
                );
          const generalDiscountTicket =
            discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
              ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
              : handleTruncateToTwoDecimal(
                  (handleConvertPrice(item.merchandisePrice.toString()) *
                    (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                    100
                );

          const priceAfterDiscount: number = item.merchandisePrice - totalDiscount;

          const weeklyDiscountPrice = weeklyDiscountValue?.moneyDiscount ?? 0;
          weeklyDiscount =
            weeklyDiscountPrice <= priceAfterDiscount ? weeklyDiscountValue : item.discount?.weeklyDiscount;

          const birthdayDiscountPrice = birthdayDiscountValue?.moneyDiscount ?? 0;
          birthdayDiscount =
            birthdayDiscountPrice <= priceAfterDiscount ? birthdayDiscountValue : item.discount?.birthdayDiscount;

          const occasionDiscountPrice = occasionDiscountValue?.moneyDiscount ?? 0;
          occasionDiscount =
            occasionDiscountPrice <= priceAfterDiscount ? occasionDiscountValue : item.discount?.occasionDiscount;

          const totalDiscountService: number =
            (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
              (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
              (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
              (!!generalDiscountMerchandise ? +generalDiscountMerchandise : 0) +
              (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;
          const totalDiscountWithPromotion: number =
            (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
            (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
            (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0);
          const finalDiscount =
            totalDiscountWithPromotion >= +item.merchandisePrice ? +item.merchandisePrice : totalDiscountWithPromotion;
          const tax =
            item.isTaxable && +taxPercent > 0
              ? {
                  type: DiscountTypeEnum.Percent,
                  moneyDiscount: handleTruncateToTwoDecimal(
                    ((item.merchandisePrice - finalDiscount) * +taxPercent) / 100
                  ),
                }
              : undefined;
          return {
            id: item.merchandiseId,
            idDelete: parseFloat(Math.random().toFixed(3)) * 10,
            name: item.name,
            merchandiseId: item.merchandiseId,
            merchandisePrice: item.merchandisePrice,
            isTaxable: item.isTaxable,
            totalDiscount:
              totalDiscountService >= +item.merchandisePrice ? +item.merchandisePrice : totalDiscountService,
            discount: {
              ...item.discount,
              occasionDiscount: occasionDiscount,
              weeklyDiscount: weeklyDiscount,
              birthdayDiscount: birthdayDiscount,
              tax: tax,
            },
          };
        }),
      };
      setAppointmentBill(dataUpdateBill);
      handleGetTotal(dataUpdateBill);
    } else {
      handleGetTotal(appointmentBill);
    }
  }, [discountsAndRewardsApplied]);

  const checkIsHaveServiceHold = (): boolean => {
    const merchandise = !!appointmentBill.ticketMerchandise.length;
    const giftCard = !!appointmentBill.giftCards;
    const technicianService = appointmentBill.technician.filter((item) => item.name);
    if (!!technicianService.every((item) => !!item?.services?.length) || merchandise || giftCard) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsHaveServiceCreate = (): boolean => {
    const technicianNull = appointmentBill.technician.find((item) => !item.name);
    const technicianService = appointmentBill.technician.filter((item) => item.name);
    const merchandise = !!appointmentBill.ticketMerchandise.length;
    const giftCard = !!appointmentBill.giftCards;
    const isHaveService = !!technicianService.every((item) => !!item?.services?.length);

    if (technicianNull) {
      return false;
    } else if (!!technicianService.length && isHaveService) {
      return true;
    } else if (merchandise || giftCard) {
      if (technicianService) {
        return isHaveService;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="salon__checkout-container-page">
      <StyledHeader
        content={intl.formatMessage({ id: 'checkout.checkOut' })}
        extraButton={
          isHavingPermission && !!listTicketPending?.data?.total ? (
            <span
              className="cursor-pointer font-size-20 font-weight-600 color-0090FF line-height-45"
              onClick={() => {
                setOpenModal({
                  ...openModal,
                  openPendingTicket: true,
                });
              }}
            >
              {listTicketPending?.data.total} {intl.formatMessage({ id: 'checkout.pending.ticket' })}
            </span>
          ) : null
        }
      />
      <div className="salon__checkout-container">
        {isHavingPermission ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              className={`salon__checkout-col-container salon__checkout-col-container-col-left ${
                isCollapse ? 'salon__checkout-expand-width-left' : ''
              }`}
            >
              <div id={DesiredStatusesItem.ListTechnician}>
                {loaddingTechnician ? (
                  <div className="min-height-110 d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <StyledCollapse items={technicianItems} />
                )}
              </div>
              {/* list services */}
              {/* <div className="salon__checkout-list-services">
          {listServices.map((item, index) => (
            <div
              className="salon__checkout-service-item"
              key={index}
              onClick={() => {
                handleUpdateShowModal(item);
                setIsEditServicePrice(false);
              }}
            >
              <span className="salon__checkout-service-item-title">
                {intl.formatMessage({ id: `checkout.${item}` })}
              </span>
            </div>
          ))}
        </div> */}

              {/* General | Service Gift Card | Merchandise | Discounts & Rewards | Tax & Service Charge */}

              <ListServicesButtons
                listServices={listServices}
                handleUpdateShowModal={handleUpdateShowModal}
                setIsEditServicePrice={setIsEditServicePrice}
                settingCheckout={listSetting?.data?.settingCheckout}
              />

              <div id={DesiredStatusesItem.ListServices}>
                {loadingService ? (
                  <div className="min-height-600 d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <StyledCollapse
                    items={serviceItems}
                    collapseProps={{
                      defaultActiveKey: listDataServices?.data?.content?.map((item, index: number) =>
                        (index + 1).toString()
                      ),
                    }}
                  />
                )}
              </div>
            </div>

            <div className="salon__checkout-col-container-col-mid">
              <CheckoutBill
                appointmentBill={appointmentBill}
                idTechnician={idTechnician}
                isOpenPayAndComplete={isOpenPayAndComplete}
                listTotal={listTotal}
                openModal={openModal}
                taxDiscount={taxDiscount}
                serviceCharge={serviceCharge}
                serviceChangePromotionAuto={
                  listPromotion?.data.feeDiscount?.autoOption ? +listPromotion?.data.feeDiscount?.autoOption : 0
                }
                serviceChangePromotionManual={
                  listPromotion?.data.feeDiscount?.manualOption ? +listPromotion?.data.feeDiscount?.manualOption : 0
                }
                serviceChangeNamePromotion={listPromotion?.data.feeDiscount?.feeName ?? ''}
                discountsAndRewardsApplied={discountsAndRewardsApplied}
                paymentInformation={paymentInformation}
                taxPercent={taxPercent}
                listPromotion={listPromotion}
                isLoading={createCheckoutMutation.isLoading}
                listIdDisable={listIdDisable}
                handleDeleteTicketPending={handleDeleteTicketPending}
                setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
                handleRemoveMerchandise={handleRemoveMerchandise}
                handleRemoveServices={handleRemoveServices}
                handleRemoveServiceDiscount={handleRemoveServiceDiscount}
                handleRemoveTechnician={handleRemoveTechnician}
                handleRemoveMerchandiseDiscount={handleRemoveMerchandiseDiscount}
                setIdTechnician={setIdTechnician}
                setListSkill={setListSkill}
                handleUpdateShowModal={handleUpdateShowModal}
                setListIdDisable={setListIdDisable}
                handleGetTotal={handleGetTotal}
                setServiceApply={setServiceApply}
                setIsOpenPayAndComplete={setIsOpenPayAndComplete}
                setOpenModal={setOpenModal}
                setTaxPercent={setTaxPercent}
                handleRemoveCustomer={handleRemoveCustomer}
                handleRemoveGiftCard={handleRemoveGiftCard}
                setServiceCharge={setServiceCharge}
                setIsEditServicePrice={setIsEditServicePrice}
                setIdService={setIdService}
                handleSubmitCheckout={handleSubmitCheckout}
                setServiceItem={setServiceItem}
                resetBill={resetBill}
                checkListServiceInTechnician={checkListServiceInTechnician}
                checkIsHaveServiceHold={checkIsHaveServiceHold}
                checkIsHaveServiceCreate={checkIsHaveServiceCreate}
                setIndexServiceEdit={setIndexServiceEdit}
                settingCheckout={listSetting?.data?.settingCheckout}
                listSetting={listSetting?.data}
                listTechnician={listTechnician}
                setAppointmentBill={setAppointmentBill}
                lastTicketInToday={lastTicketInTodayResponse?.data}
                printBillComponentRef={printBillComponentRef}
                onChangeTicketToPrint={(ticket, timePayment) => {
                  setTicketToPrint(ticket);

                  if (timePayment) {
                    setTimePaymentPrintTicket(timePayment);
                  } else {
                    setTimePaymentPrintTicket(TimePayment.AFTER);
                  }
                }}
                onPrintTicket={onPrintTicket}
                setRedeem={setRedeem}
                isLoadingPdf={isLoadingPdf}
              />
            </div>

            <div
              className={`d-flex flex-column salon__checkout-col-container-col-right salon__checkout-container-btn-collapse ${
                isCollapse ? 'salon__checkout-expand-width-right' : ''
              }`}
            >
              <Button
                className="salon__checkout-btn-collapse z-index-100"
                onClick={() => {
                  setIsCollapse(!isCollapse);
                }}
              >
                {isCollapse ? <SvgNextIcon /> : <SvgPrevIcon />}
              </Button>
              <FormWrap
                name="formCreateServices"
                layout="vertical"
                preserve={false}
                autoComplete="off"
                className="salon__checkout-form-search "
              >
                <FormInputSearch
                  name={'fullTextSearch'}
                  inputProps={{
                    inputMode: 'none',
                    readOnly: true,
                    placeholder: 'Search',
                    onClick: () => {
                      setOpenModal({
                        ...openModal,
                        openListCheckIn: true,
                      });
                    },
                    onKeyDown: (e) => {
                      e.preventDefault();
                    },
                    className: 'salon__checkout-form-search-input',
                  }}
                  formItemProps={{
                    rules: [
                      {
                        transform: (value) => {
                          return value?.trim();
                        },
                      },
                    ],
                    className: 'm-0',
                  }}
                />
                <div className="width-38 height-38">
                  <Tooltip
                    trigger={'hover'}
                    placement="bottomRight"
                    title={
                      <span className="font-size-16 font-weight-500">
                        {intl.formatMessage({ id: 'checkout.tooltip.markAll' })}
                      </span>
                    }
                    overlayClassName="salon__checkout-tooltip "
                  >
                    <Button
                      className="salon__checkout-btn-update-service-checkin"
                      onClick={() => handleUpdateAppoint()}
                      loading={updateAppointmentMutation.isLoading}
                      disabled={updateAppointmentMutation.isLoading}
                    >
                      <SvgDoubleCheckIcon />
                    </Button>
                  </Tooltip>
                </div>
              </FormWrap>
              <div className="salon__checkout-col-container w-100" id={DesiredStatusesItem.ListCheckin}>
                {desiredStatuses.map((status, index) => (
                  <Droppable droppableId={status} key={status} isDropDisabled={status === DesiredStatusesItem.CheckIn}>
                    {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
                      <React.Fragment>
                        {/* <div ref={providedDrop.innerRef} {...providedDrop.droppableProps} {...snapshotDrop} /> */}
                        <div
                          ref={providedDrop.innerRef}
                          {...providedDrop.droppableProps}
                          {...snapshotDrop}
                          className="salon__checkout-drop-zone"
                        >
                          <StyledCollapse
                            collapseProps={{
                              defaultActiveKey: ['1', '2'],
                              className: 'p-l-20',
                            }}
                            items={[
                              {
                                key: (index + 1).toString(),
                                label: (
                                  <>
                                    <span className="font-size-14 font-weight-700 color-292F33 d-flex gap-4">
                                      {intl.formatMessage({ id: `checkout.${status}` })}
                                      {' (' +
                                        appointments.filter((appointment) => appointment.status === status).length +
                                        ')'}
                                      <Tooltip
                                        trigger="hover"
                                        overlayClassName="salon__checkout-tooltip"
                                        title={
                                          status === DesiredStatusesItem.CheckIn ? (
                                            <ul className="m-b-0 ">
                                              <li>
                                                <span className="font-size-16 font-weight-500">
                                                  {intl.formatMessage(
                                                    { id: 'checkout.tooltip.checkin1' },
                                                    {
                                                      service: `"${intl.formatMessage({
                                                        id: `checkout.${DesiredStatusesItem.BeingServed}`,
                                                      })}"`,
                                                    }
                                                  )}
                                                </span>
                                              </li>
                                              <li>
                                                <span className="font-size-16 font-weight-500">
                                                  {intl.formatMessage({ id: 'checkout.tooltip.checkin2' })}
                                                </span>
                                              </li>
                                            </ul>
                                          ) : (
                                            <span className="font-size-16">
                                              {intl.formatMessage({ id: 'checkout.tooltip.beingServed' })}
                                            </span>
                                          )
                                        }
                                      >
                                        <p className="m-b-0 m-t-1-n">
                                          <SvgToolTipIcon />
                                        </p>
                                      </Tooltip>
                                    </span>
                                  </>
                                ),
                                children: (
                                  <div className="d-flex align-items-center flex-wrap gap-8 p-t-b-6 w-100">
                                    {loadingAppointment ? (
                                      <div className="min-height-110 d-flex justify-content-center align-items-center w-100">
                                        <Spin />
                                      </div>
                                    ) : appointments.filter((appointment) => appointment.status === status).length >
                                      0 ? (
                                      appointments
                                        .filter((appointment) => appointment.status === status)
                                        .map((data: CustomAppointment, indexAppointment: number) => (
                                          <Draggable
                                            draggableId={`appointment_${data.id}`}
                                            index={indexAppointment}
                                            key={data.id}
                                            isDragDisabled={idAppointment.includes(data.id)}
                                          >
                                            {(
                                              providedDrag: DraggableProvided,
                                              snapshotDrag: DraggableStateSnapshot
                                            ) => (
                                              <>
                                                <div
                                                  ref={providedDrag?.innerRef}
                                                  {...providedDrag?.draggableProps}
                                                  {...providedDrag?.dragHandleProps}
                                                  {...snapshotDrag}
                                                  className={
                                                    idAppointment.includes(data.id)
                                                      ? 'salon__checkout-disable-draggable'
                                                      : ''
                                                  }
                                                  onClick={() =>
                                                    idAppointment.includes(data.id)
                                                      ? null
                                                      : handleClickOption(
                                                          indexAppointment,
                                                          data.status === AppointmentStatusEnum.CheckedIn
                                                            ? DesiredStatusesItem.CheckIn
                                                            : DesiredStatusesItem.BeingServed
                                                        )
                                                  }
                                                >
                                                  {!snapshotDrag.isDropAnimating && (
                                                    <CheckInCard
                                                      customerName={data.customer.name}
                                                      estimate={data.estimate}
                                                      rate={data.customer?.customerRating || 0}
                                                      serviceName={data.services.map((item) => item.name).join(', ')}
                                                      status={data.status}
                                                      timeStart={data?.timeStart}
                                                      technicianName={data.technician?.name}
                                                      containerClassName="salon__checkout-card"
                                                      isCountDownMinutes={
                                                        status === DesiredStatusesItem.BeingServed
                                                          ? listSetting?.data?.settingCheckout?.trackingServingTime
                                                          : false
                                                      }
                                                      data={data}
                                                    />
                                                  )}
                                                </div>
                                                {snapshotDrag.isDragging && (
                                                  <CheckInCard
                                                    customerName={data.customer.name}
                                                    estimate={data.estimate}
                                                    rate={data.customer?.customerRating || 0}
                                                    serviceName={data.services.map((item) => item.name).join(', ')}
                                                    status={data.status}
                                                    timeStart={data?.timeStart}
                                                    technicianName={data.technician?.name}
                                                    containerClassName="salon__checkout-card"
                                                    isCountDownMinutes={
                                                      status === DesiredStatusesItem.BeingServed
                                                        ? listSetting?.data?.settingCheckout?.trackingServingTime
                                                        : false
                                                    }
                                                    data={data}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </Draggable>
                                        ))
                                    ) : (
                                      <div className="d-flex align-items-center justify-content-center w-100">
                                        <SvgAddMoreDataIconSmall />
                                      </div>
                                    )}
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </Droppable>
                ))}
              </div>
            </div>
          </DragDropContext>
        ) : (
          <PageContainer className="p-t-20 p-b-20 border-radius-0px w-100" marginTop={39}>
            <Result status="error" title={intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' })} />
          </PageContainer>
        )}
      </div>
      {openModal.openListTechnician && (
        <ModalTechnicianList
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListTechnician: false,
            });
          }}
          open={openModal.openListTechnician}
          setFilterTechnician={setFilterTechnician}
          filterTechnician={filterTechnician}
        />
      )}
      {openModal.openListCustomer && (
        <ModalCustomer
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListCustomer: false,
            });
          }}
          open={openModal.openListCustomer}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          listPromotion={listPromotion}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
        />
      )}
      {openModal.openGeneralService && (
        <GeneralService
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openGeneralService: false,
            });

            if (serviceItem) setServiceItem(undefined);
          }}
          taxPercent={taxPercent}
          listTechnician={listTechnician}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          idTechnician={idTechnician}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          handleGetTotal={handleGetTotal}
          setIsEditServicePrice={setIsEditServicePrice}
          listIdDisable={listIdDisable}
          setListIdDisable={setListIdDisable}
          open={openModal.openGeneralService}
          isEdit={isEditServicePrice}
          idService={idService}
          serviceItem={serviceItem}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          indexServiceEdit={indexServiceEdit}
          settingCheckout={listSetting?.data?.settingCheckout}
        />
      )}
      {openModal.openGiftCard && (
        <GiftCard
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openGiftCard: false,
            });
          }}
          open={openModal.openGiftCard}
          appointmentBill={appointmentBill}
          setAppointmentBill={setAppointmentBill}
          handleGetTotal={handleGetTotal}
          settingCheckout={listSetting?.data?.settingCheckout}
        />
      )}

      {openModal.openMerchandise && (
        <Merchandise
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openMerchandise: false,
            });
          }}
          taxPercent={taxPercent}
          open={openModal.openMerchandise}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          setAppointmentBill={setAppointmentBill}
          appointmentBill={appointmentBill}
          handleGetTotal={handleGetTotal}
          listIdDisable={listIdDisable}
          setListIdDisable={setListIdDisable}
        />
      )}

      {openModal.openDiscountsAndRewards && (
        <DiscountsAndRewards
          open={openModal}
          customer={appointmentBill.customer}
          setOpenModal={setOpenModal}
          listIdDisable={listIdDisable}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          handleRemoveCustomer={handleRemoveCustomer}
          listDataServices={listDataServices}
          promotionData={listPromotion}
          settingCheckout={listSetting?.data?.settingCheckout}
          redeem={redeem}
          setRedeem={setRedeem}
        />
      )}
      {openModal.openTaxAndDeleteTicket && (
        <TaxAndDeleteTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openTaxAndDeleteTicket: false,
            });
          }}
          open={openModal.openTaxAndDeleteTicket}
          setTaxPercent={setTaxPercent}
          feeName={listPromotion?.data.feeDiscount?.feeName ?? ''}
          serviceCharge={serviceCharge}
          autoOption={listPromotion?.data.feeDiscount?.autoOption ?? 0}
          serviceChargePercent={listPromotion?.data.feeDiscount?.manualOption ?? 0}
          setServiceCharge={setServiceCharge}
          settingCheckout={listSetting?.data?.settingCheckout}
        />
      )}
      {openModal.openDeleteTicket && (
        <DeleteTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openDeleteTicket: false,
            });
          }}
          price={+listTotal.totalCash}
          open={openModal.openDeleteTicket}
          listTotal={listTotal}
          paymentInformation={paymentInformation}
          onDeleteTicket={() => {
            resetBill();
          }}
        />
      )}
      {openModal.openPendingTicket && (
        <PendingTicket
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openPendingTicket: false,
            });
          }}
          listDataServices={
            !!listDataServices?.data?.content?.length
              ? listDataServices.data.content.reduce(
                  (accumulator, currentValue) => [...accumulator, ...currentValue.serviceItems],
                  [] as ServiceItems[]
                )
              : []
          }
          open={openModal.openPendingTicket}
          listTicketPending={listTicketPending?.data.content}
          appointmentBill={appointmentBill}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          listPromotion={listPromotion?.data}
          listTotal={listTotal}
          setSearchPending={setSearchPending}
          handleGetTotal={handleGetTotal}
          setAppointmentBill={setAppointmentBill}
          setServiceCharge={setServiceCharge}
          setTaxPercent={setTaxPercent}
          setDiscountsAndRewardsApplied={setDiscountsAndRewardsApplied}
          setListIdDisable={setListIdDisable}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          setPaymentInformation={setPaymentInformation}
          setListTotal={setListTotal}
          setServiceApply={setServiceApply}
        />
      )}
      {openModal.openListCheckIn && (
        <ModalCheckIn
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openListCheckIn: false,
            });
          }}
          open={openModal.openListCheckIn}
          listIdDisable={listIdDisable}
          appointmentBill={appointmentBill}
          discountsAndRewardsApplied={discountsAndRewardsApplied}
          taxPercent={taxPercent}
          setAppointmentBill={setAppointmentBill}
          setIdTechnician={setIdTechnician}
          setListSkill={setListSkill}
          setIdAppointment={setIdAppointment}
          setListIdDisable={setListIdDisable}
          setServiceApply={setServiceApply}
          handleGetTotal={handleGetTotal}
        />
      )}
      {isOpenPayAndComplete && (
        <PayAndComplete
          open={isOpenPayAndComplete}
          onChangeOpenPayAndComplete={handleChangeOpenPayAndComplete}
          handleSubmitCheckout={handleSubmitCheckout}
          listTotal={listTotal}
          paymentInformation={paymentInformation}
          onChangePaymentInformation={setPaymentInformation}
          technicians={listTechnician.filter(
            (technician) => appointmentBill.technician.findIndex((item) => item.id === technician.id) > -1
          )}
          setListTotal={setListTotal}
          listPromotion={listPromotion}
          resetBill={resetBill}
          tipInformation={tipInformation}
          setTipInformation={setTipInformation}
          isLoading={createCheckoutMutation.isLoading}
          settingCheckout={listSetting?.data?.settingCheckout}
          appointmentBill={appointmentBill}
          settings={listSetting?.data}
        />
      )}

      {ticketToPrint &&
        createPortal(
          <div className="width-500 d-flex justify-content-center align-items-center position-absolute top-0 left-500-n">
            <PrintBill
              ticket={ticketToPrint}
              salon={{
                name: salonInformation?.name || '',
                address: salonInformation?.address,
                phoneNumber: listSetting?.data?.settingBusiness?.phoneNumber,
              }}
              customer={{
                isHideCustomerPhoneNumber: !!listSetting?.data?.settingBusiness?.hideCustomerIncheckinAndCheckOut,
              }}
              ref={printBillComponentRef}
              isHideSupplyAmount={!!!listSetting?.data?.settingCheckout?.printSupplyAmountTicketSummary}
              timePayment={timePaymentPrintTicket}
              listPromotion={listPromotion?.data}
            />
          </div>,
          document.body
        )}

      {pairingCode && (
        <StyledPopup
          isOpen={!!pairingCode}
          content={
            <div>
              {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.pleaseEnterPairingCode' })}
              &nbsp;
              <span className="font-weight-700 text-transform-lowercase">{pairingCode}</span>
              &nbsp;
              {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.onCloverDevice' })}?
            </div>
          }
          isHiddenCancel
          onCancel={() => dispatch(setPairingCode(undefined))}
          onOk={() => dispatch(setPairingCode(undefined))}
          modalProps={{
            zIndex: 100000,
          }}
        />
      )}
    </div>
  );
};

export default CheckOutTenant;
