import { Button, ButtonProps } from 'antd';
import './buttonIcon.scss';
type Props = {
  icon: React.ReactNode;
  buttonProps?: ButtonProps;
  className?: string;
};

export const ButtonIcon: React.FC<Props> = ({ buttonProps, icon, className }) => {
  return (
    <Button {...buttonProps} className={`salon__button-icon ${className ? className : ''}`} type="text">
      {icon}
    </Button>
  );
};
