import React from 'react';

export const SvgPrintIcon = () => (
  <span>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66536 0H10.332C10.6987 0 10.9987 0.3 10.9987 0.666667V2C10.9987 2.36667 10.6987 2.66667 10.332 2.66667H3.66536C3.2987 2.66667 2.9987 2.36667 2.9987 2V0.666667C2.9987 0.3 3.2987 0 3.66536 0ZM2.33203 3.33333H11.6654C12.772 3.33333 13.6654 4.22667 13.6654 5.33333V8C13.6654 8.73333 13.0654 9.33333 12.332 9.33333H10.9987V10.6667C10.9987 11.4 10.3987 12 9.66537 12H4.33203C3.5987 12 2.9987 11.4 2.9987 10.6667V9.33333H1.66536C0.932031 9.33333 0.332031 8.73333 0.332031 8V5.33333C0.332031 4.22667 1.22536 3.33333 2.33203 3.33333ZM4.9987 10.6667H8.9987C9.36537 10.6667 9.66537 10.3667 9.66537 10V7.33333H4.33203V10C4.33203 10.3667 4.63203 10.6667 4.9987 10.6667ZM11.6654 6C11.2987 6 10.9987 5.7 10.9987 5.33333C10.9987 4.96667 11.2987 4.66667 11.6654 4.66667C12.032 4.66667 12.332 4.96667 12.332 5.33333C12.332 5.7 12.032 6 11.6654 6Z"
        fill="white"
      />
    </svg>
  </span>
);
