import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Employee, SettingCheckout } from '../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../components/@svg/SvgDollarIcon';
import SvgReloadTechnician from '../../../../components/@svg/SvgReloadTechnician';
import ColWrap from '../../../../components/ColWrap';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { formatNumberThousandWithDecimal, handleTruncateToTwoDecimal } from '../../../../utils';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import { IAppointmentBill, IListIdDisable, IServicesItem, ITechnicianItem } from '../index';
import KeyboardWrapper, { KeyboardDirection } from '../keyboard';
import ModalSelectTechnician from './ModalSelectTechnician';
import './style.scss';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import { FormSelect } from '../../../../components/Form/FormSelect';
import { SvgArrowSelectIcon } from '../../../../components/@svg/SvgArrowSelectIcon';
import { SvgPercentIcon } from '../../../../components/@svg/SvgPercentIcon';

export interface Props {
  open: boolean;
  appointmentBill: IAppointmentBill;
  idTechnician: string | number;
  isEdit: boolean;
  idService: string | number;
  serviceItem?: IServicesItem;
  listIdDisable: IListIdDisable;
  discountsAndRewardsApplied: IDiscountsAndRewardsApplied | undefined;
  setIsEditServicePrice: (isEditServicePrice: boolean) => void;
  setIdTechnician: (idTechnician: string | number) => void;
  setListSkill: (listSkill: number[]) => void;
  onCancelModal: () => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
  setListIdDisable: ({}: IListIdDisable) => void;
  indexServiceEdit: number;
  settingCheckout?: SettingCheckout;
  listTechnician: ITechnicianItem[];
  taxPercent: number;
}

enum InputNameEnum {
  price = 'price',
  supply = 'supply',
  type = 'type',
  forOff = 'forOff',
}

interface FormData {
  price?: number;
  supply?: number;
  type: DiscountTypeEnum;
  forOff?: number;
}

interface SupplyCostRule {
  operatorSetting: string;
  priceSetting: number;
  supplySetting: number;
}

const operators = {
  '=': function (a: number | string, b: number | string) {
    return a == b;
  },
  '<=': function (a: number | string, b: number | string) {
    return a <= b;
  },
  '>=': function (a: number | string, b: number | string) {
    return a >= b;
  },
  '<': function (a: number | string, b: number | string) {
    return a < b;
  },
  '>': function (a: number | string, b: number | string) {
    return a > b;
  },
};

function GeneralService<T extends object>(props: Props) {
  const {
    open,
    idTechnician,
    idService,
    appointmentBill,
    isEdit,
    serviceItem,
    listIdDisable,
    discountsAndRewardsApplied,
    setIdTechnician,
    setListSkill,
    onCancelModal,
    setAppointmentBill,
    handleGetTotal,
    setIsEditServicePrice,
    setListIdDisable,
    listTechnician,
    indexServiceEdit,
    settingCheckout,
    taxPercent,
  } = props;
  const intl = useIntl();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [technicianItem, setTechnicianItem] = useState<ITechnicianItem>();
  const [technician, setTechnician] = useState<Employee | any>();
  const [oldTechnicianId, setOldTechnicianId] = useState<string | number>();
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>(InputNameEnum.price);
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();
  const priceRef = useRef<HTMLInputElement>(null);
  const supplyRef = useRef<HTMLInputElement>(null);
  const forOffRef = useRef<HTMLInputElement>(null);
  const price = Form.useWatch(n('price'), form);
  const supply = Form.useWatch(n('supply'), form);
  const type = Form.useWatch(n('type'), form);
  const forOff = Form.useWatch(n('forOff'), form);
  useEffect(() => {
    setOldTechnicianId(idTechnician);
  }, []);

  useEffect(() => {
    if (price !== undefined && priceRef.current && focusedInputName === InputNameEnum.price) {
      const [integer] = price.replace(/,/g, '').split('.');
      priceRef.current.selectionStart = priceRef.current.selectionEnd = price.length + Math.floor(integer / 3);
    }

    if (supply !== undefined && supplyRef.current && focusedInputName === InputNameEnum.supply) {
      const [integer] = supply?.replace(/,/g, '').split('.');
      supplyRef.current.selectionStart = supplyRef.current.selectionEnd = supply.length + Math.floor(integer / 3);
    }

    if (forOff !== undefined && forOffRef.current && focusedInputName === InputNameEnum.forOff) {
      const [integer] = forOff?.replace(/,/g, '').split('.');
      forOffRef.current.selectionStart = forOffRef.current.selectionEnd = forOff.length + Math.floor(integer / 3);
    }
  }, [price, supply, forOff, focusedInputName]);

  useEffect(() => {
    if (isEdit) {
      appointmentBill.technician.some((item) => {
        if (item.id === idTechnician && serviceItem) {
          const found = item.services?.find(
            (serviceItemFound) =>
              serviceItemFound.id === idService && serviceItemFound.idDelete === serviceItem.idDelete
          );
          if (found) {
            if (found.price) {
              form.setFieldValue(n('price'), Number.parseFloat(found.price.toString()).toFixed(2));
            }
            if (found.supply) {
              form.setFieldValue(n('supply'), Number.parseFloat(found.supply.toString()).toFixed(2));
            }
            if (found.discount?.technicianDiscount && found.discount?.technicianDiscount.discountApply) {
              form.setFieldValue(n('type'), found.discount?.technicianDiscount.type);
              form.setFieldValue(
                n('forOff'),
                Number.parseFloat(found.discount?.technicianDiscount.discountApply.toString()).toFixed(2)
              );
            }
          }
        }
        return false;
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (open) {
      priceRef?.current?.focus();
    }
  }, [open]);

  const onSubmit = () => {
    const data = form.getFieldsValue();

    if (data?.price === undefined || data?.price.trim() === '') {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.generalService.validate.price' }) });
    } else if (Number(data?.price) < Number(data?.supply)) {
      NotificationError({
        contentNoti: intl.formatMessage({ id: 'checkout.generalService.validate.price.sub' }),
      });
    } else {
      const updateService = appointmentBill;
      if (isEdit && serviceItem) {
        const generalDiscountTicket =
          discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
            ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
            : handleTruncateToTwoDecimal(
                (handleConvertPrice(data.price) * (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                  100
              );
        const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(serviceItem?.id)
          ? {
              discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
              type: discountsAndRewardsApplied?.occasionDiscount?.type,
              moneyDiscount:
                discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.occasionDiscount?.amount
                  : handleTruncateToTwoDecimal(
                      (data.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                    ),
            }
          : undefined;
        const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(serviceItem.id)
          ? {
              discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
              type: discountsAndRewardsApplied?.weeklyDiscount?.type,
              moneyDiscount:
                discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                  : handleTruncateToTwoDecimal(
                      (data.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                    ),
            }
          : undefined;
        const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(serviceItem.id)
          ? {
              discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
              type: discountsAndRewardsApplied?.birthdayDiscount?.type,
              moneyDiscount:
                discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                  ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                  : handleTruncateToTwoDecimal(
                      (data.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                    ),
            }
          : undefined;
        const totalDiscount: number =
          (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
            (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
            (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
            (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;
        const servicePrice: number = data?.price ? handleConvertPrice(data?.price) : 0;
        const technicianDiscountPrice: number =
          type === DiscountTypeEnum.Dollar
            ? forOff
              ? handleConvertPrice(forOff)
              : 0
            : handleTruncateToTwoDecimal((forOff * servicePrice) / 100);
        const residualPrice = servicePrice - Number(totalDiscount);
        const isCheckApply = technicianDiscountPrice <= residualPrice;

        const discountTechnician = {
          idTechnician: idTechnician,
          idService: idService,
          type: type,
          discountApply:
            !isCheckApply && !!forOff
              ? serviceItem.discount?.technicianDiscount?.discountApply
              : handleConvertPrice(forOff),
          moneyDiscount:
            type === DiscountTypeEnum.Dollar
              ? !isCheckApply && !!forOff
                ? serviceItem.discount?.technicianDiscount?.discountApply
                : handleConvertPrice(forOff)
              : type === DiscountTypeEnum.Percent
              ? !isCheckApply && !!forOff
                ? handleTruncateToTwoDecimal(
                    ((serviceItem.discount?.technicianDiscount?.discountApply ?? 0) * servicePrice) / 100
                  )
                : handleTruncateToTwoDecimal((forOff * servicePrice) / 100)
              : 0,
        };

        const totalDiscountWithPromotion: number =
          (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
          (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
          (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
          (!!discountTechnician?.moneyDiscount ? +discountTechnician?.moneyDiscount : 0);

        const finalDiscount = totalDiscountWithPromotion >= servicePrice ? servicePrice : totalDiscountWithPromotion;
        const tax =
          serviceItem.isTaxable && +taxPercent > 0
            ? {
                type: DiscountTypeEnum.Percent,
                moneyDiscount: handleTruncateToTwoDecimal(((servicePrice - finalDiscount) * +taxPercent) / 100),
              }
            : undefined;

        for (let index = 0; index < appointmentBill.technician.length; index++) {
          const technician = appointmentBill.technician[index];
          const generalDiscountTicket =
            discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
              ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
              : handleTruncateToTwoDecimal(
                  (handleConvertPrice(data.price) * (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                    100
                );
          if (technician.id === idTechnician) {
            if (idTechnician === oldTechnicianId) {
              updateService.technician[index].services = technician.services?.map((service, indexService) => {
                const occasionDiscount = discountsAndRewardsApplied?.occasionDiscount?.serviceItemIds.includes(
                  service.id
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.occasionDiscount?.amount,
                      type: discountsAndRewardsApplied?.occasionDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.occasionDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.occasionDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (data.price * (discountsAndRewardsApplied?.occasionDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const weeklyDiscount = discountsAndRewardsApplied?.weeklyDiscount?.serviceItemIds.includes(service.id)
                  ? {
                      discountApply: discountsAndRewardsApplied?.weeklyDiscount?.amount,
                      type: discountsAndRewardsApplied?.weeklyDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.weeklyDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.weeklyDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (data.price * (discountsAndRewardsApplied?.weeklyDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;
                const birthdayDiscount = discountsAndRewardsApplied?.birthdayDiscount?.serviceItemIds.includes(
                  service.id
                )
                  ? {
                      discountApply: discountsAndRewardsApplied?.birthdayDiscount?.amount,
                      type: discountsAndRewardsApplied?.birthdayDiscount?.type,
                      moneyDiscount:
                        discountsAndRewardsApplied?.birthdayDiscount?.type === DiscountTypeEnum.Dollar
                          ? discountsAndRewardsApplied?.birthdayDiscount?.amount
                          : handleTruncateToTwoDecimal(
                              (data.price * (discountsAndRewardsApplied?.birthdayDiscount?.amount ?? 0)) / 100
                            ),
                    }
                  : undefined;

                const servicePrice: number = data?.price ? handleConvertPrice(data?.price) : 0;

                const technicianDiscountPrice: number =
                  type === DiscountTypeEnum.Dollar
                    ? forOff
                      ? handleConvertPrice(forOff)
                      : 0
                    : handleTruncateToTwoDecimal((forOff * servicePrice) / 100);

                const totalTempDiscount =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                    (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                    (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                    (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;
                const residualPrice = servicePrice - Number(totalTempDiscount);
                const isCheckApply = technicianDiscountPrice <= residualPrice;

                const discountTechnician = {
                  idTechnician: idTechnician,
                  idService: idService,
                  type: type,
                  discountApply:
                    !isCheckApply && !!forOff
                      ? service.discount?.technicianDiscount?.discountApply
                      : handleConvertPrice(forOff),
                  moneyDiscount:
                    type === DiscountTypeEnum.Dollar
                      ? !isCheckApply && !!forOff
                        ? service.discount?.technicianDiscount?.discountApply
                        : handleConvertPrice(forOff)
                      : type === DiscountTypeEnum.Percent
                      ? !isCheckApply && !!forOff
                        ? handleTruncateToTwoDecimal(
                            ((service.discount?.technicianDiscount?.discountApply ?? 0) * servicePrice) / 100
                          )
                        : handleTruncateToTwoDecimal((forOff * servicePrice) / 100)
                      : 0,
                };

                const totalDiscount: number =
                  (!!occasionDiscount?.moneyDiscount ? +occasionDiscount?.moneyDiscount : 0) +
                    (!!weeklyDiscount?.moneyDiscount ? +weeklyDiscount?.moneyDiscount : 0) +
                    (!!birthdayDiscount?.moneyDiscount ? +birthdayDiscount?.moneyDiscount : 0) +
                    (!!discountTechnician?.moneyDiscount ? +discountTechnician?.moneyDiscount : 0) +
                    (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;

                if (!isCheckApply) {
                  NotificationError({
                    contentNoti: intl.formatMessage({ id: 'checkout.discountAndRewards.technicianError' }),
                  });
                } else {
                  handleCloseModal();
                }
                return service.id === idService &&
                  service.idDelete === serviceItem?.idDelete &&
                  indexService === indexServiceEdit &&
                  totalDiscount < servicePrice
                  ? {
                      ...service,
                      price: data?.price ? handleConvertPrice(data?.price) : 0,
                      supply: data?.supply ? handleConvertPrice(data.supply) : 0,
                      totalDiscount: totalDiscount >= servicePrice ? servicePrice : totalDiscount,
                      discount: {
                        occasionDiscount: occasionDiscount,
                        weeklyDiscount: weeklyDiscount,
                        birthdayDiscount: birthdayDiscount,
                        technicianDiscount: isCheckApply && Number(forOff) ? discountTechnician : undefined,
                        tax: tax,
                      },
                    }
                  : service;
              });
            } else if (serviceItem) {
              if (!isCheckApply) {
                NotificationError({
                  contentNoti: intl.formatMessage({ id: 'checkout.discountAndRewards.technicianError' }),
                });
              } else {
                handleCloseModal();
              }
              updateService.technician[index].services = [
                ...(technician.services?.filter((item) => item.idDelete !== serviceItem.idDelete) || []),
                {
                  ...serviceItem,
                  price: data?.price ? handleConvertPrice(data?.price) : 0,
                  supply: data?.supply ? handleConvertPrice(data.supply) : 0,
                  totalDiscount: totalDiscount >= servicePrice ? servicePrice : totalDiscount,
                  discount: {
                    occasionDiscount: occasionDiscount,
                    weeklyDiscount: weeklyDiscount,
                    birthdayDiscount: birthdayDiscount,
                    technicianDiscount: isCheckApply && Number(forOff) ? discountTechnician : undefined,
                    tax: tax,
                  },
                },
              ];
            }
          }

          if (technician.id === oldTechnicianId && oldTechnicianId !== idTechnician) {
            updateService.technician[index].services = technician.services?.filter(
              (e, indexService) => indexService !== indexServiceEdit
            );
          }
        }

        handleGetTotal(updateService);
        if (technicianItem && technicianItem.id && serviceItem) {
          const isCheck = !updateService.technician.map((item) => item.id).includes(idTechnician);
          if (isCheck) {
            const tempTechnician = listTechnician.find((itemFilter) => itemFilter.id === idTechnician);
            setIdTechnician(technicianItem.id);
            const addNewTech = {
              ...tempTechnician,
              services: [
                {
                  ...serviceItem,
                  price: data?.price ? handleConvertPrice(data?.price) : 0,
                  supply: data?.supply ? handleConvertPrice(data.supply) : 0,
                  totalDiscount: totalDiscount >= servicePrice ? servicePrice : totalDiscount,
                  discount: {
                    occasionDiscount: occasionDiscount,
                    weeklyDiscount: weeklyDiscount,
                    birthdayDiscount: birthdayDiscount,
                    technicianDiscount: isCheckApply && Number(forOff) ? discountTechnician : undefined,
                    tax: serviceItem.discount?.tax,
                  },
                },
              ],
            } as ITechnicianItem;
            const updateTech = updateService.technician.concat(addNewTech);
            handleGetTotal({ ...updateService, technician: updateTech });
            setAppointmentBill({ ...updateService, technician: updateTech });
            handleCloseModal();
          } else {
            handleGetTotal(updateService);
            setAppointmentBill(updateService);
          }
          setOpenModalSelect(false);
          setListIdDisable({
            ...listIdDisable,
            listIdTechnician: updateService.technician.map((item) => item.id) as number[],
          });
        } else {
          setAppointmentBill(updateService);
        }
      } else if (technician) {
        handleCreateNewGeneralService(data, false, idService);
      }
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsEditServicePrice(false);
    onCancelModal();
  };

  const handleCreateNewGeneralService = (data: any, isHaveRemove: boolean, idService: string | number) => {
    appointmentBill.technician.some((item) => {
      const listIdServiceDisable: number[] = [];
      const idGeneralService = parseFloat(Math.random().toFixed(3)) * 10;

      const technicianDiscountPrice: number =
        type === DiscountTypeEnum.Dollar
          ? handleConvertPrice(forOff)
          : handleTruncateToTwoDecimal((data.price * (handleConvertPrice(forOff) ?? 0)) / 100);

      const discountTechnician = {
        idTechnician: item.id,
        idService: idGeneralService,
        type: type,
        discountApply: handleConvertPrice(forOff),
        moneyDiscount: technicianDiscountPrice,
      };
      const totalDiscount: number =
        discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
          : handleTruncateToTwoDecimal(
              (handleConvertPrice(data?.price) * (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) / 100
            ) + technicianDiscountPrice;
      const price: number = handleConvertPrice(data?.price);
      const supply: number = handleConvertPrice(data?.supply);

      const tax =
        +taxPercent > 0
          ? {
              type: DiscountTypeEnum.Percent,
              moneyDiscount: handleTruncateToTwoDecimal(((price - technicianDiscountPrice) * +taxPercent) / 100),
            }
          : undefined;
      const dataGeneralServices = [
        {
          id: idGeneralService,
          idDelete: parseFloat(Math.random().toFixed(3)) * 10,
          isMerchandise: false,
          name: intl.formatMessage({ id: 'checkout.generalService' }),
          price: price,
          supply: supply,
          description: '',
          isGeneralService: true,
          isTaxable: true,
          totalDiscount: totalDiscount,
          discount:
            !!forOff || +taxPercent > 0
              ? {
                  technicianDiscount: !!forOff ? discountTechnician : undefined,
                  tax: +taxPercent > 0 ? tax : undefined,
                }
              : undefined,
        } as IServicesItem,
      ];
      if (item.id === idTechnician) {
        const updateService = {
          ...appointmentBill,
          technician: appointmentBill.technician.map((item) => {
            return {
              ...item,
              services:
                idTechnician === item.id
                  ? item.services
                    ? item.services.concat(dataGeneralServices)
                    : dataGeneralServices
                  : item.services,
            };
          }),
        };
        updateService.technician.map((item) =>
          item.services?.map((itemServices) => {
            return listIdServiceDisable.push(itemServices.id as number);
          })
        );
        setListIdDisable({
          ...listIdDisable,
          listIdServices: listIdServiceDisable,
        });
        if (isHaveRemove) {
          updateService.technician.forEach((technician) => {
            technician.services = technician?.services?.filter((itemService) => itemService.id !== idService);
          });
        }

        setAppointmentBill(updateService);
        handleGetTotal(updateService);
        setIdTechnician(idTechnician);
        setListSkill(item.skills ?? []);
        return true;
      } else {
        const tempTechnician = listTechnician.find((itemFilter) => itemFilter.id === idTechnician);
        const addNewTech = {
          ...tempTechnician,
          services: dataGeneralServices,
        } as ITechnicianItem;
        const updateTech = appointmentBill.technician.concat(addNewTech);
        handleGetTotal({ ...appointmentBill, technician: updateTech });
        setAppointmentBill({ ...appointmentBill, technician: updateTech });
      }
      return false;
    });
    handleCloseModal();
  };

  const handleConvertPrice = (price: string) => {
    return price ? Number(price.toString().replace(/,/g, '')) : 0;
  };

  const handleChangeTechnician = (newTechnician: ITechnicianItem | Employee) => {
    setTechnician(newTechnician);
    // if (newTechnician.id !== technician?.id) form.resetFields();
  };

  useEffect(() => {
    handleFillSupply(price);
  }, [price]);

  const handleFillSupply = (price: string) => {
    if (
      (serviceItem?.isGeneralService || !serviceItem) &&
      settingCheckout?.inputingServiceSupply &&
      settingCheckout?.supplyCost &&
      !isEdit
    ) {
      // const supplyCostRules: SupplyCostRule[] = settingCheckout.supplyCost
      //   .split('|')
      //   .map((s): SupplyCostRule | null => {
      //     const match = s.match(/([><=]+)(\d+),(\d+)/);
      //     return match
      //       ? { operatorSetting: match[1], priceSetting: Number(match[2]), supplySetting: Number(match[3]) }
      //       : null;
      //   })
      //   .filter((rule): rule is SupplyCostRule => rule !== null);
      // let supplyValue: number | null = null;
      // for (const ruleSetting of supplyCostRules) {
      //   if (
      //     (ruleSetting.operatorSetting === '>' && Number(price) > ruleSetting.priceSetting) ||
      //     (ruleSetting.operatorSetting === '>=' && Number(price) >= ruleSetting.priceSetting) ||
      //     (ruleSetting.operatorSetting === '=' && Number(price) === ruleSetting.priceSetting)
      //   ) {
      //     supplyValue = ruleSetting.supplySetting;
      //     break;
      //   }
      // }

      // get expression string of supply cost
      const supplyCostString = settingCheckout.supplyCost;

      // split it into many conditions
      const splitArr = supplyCostString.split('|').map((exp) => exp.replace(/ /g, ''));

      // then get the conditions which suitable price
      const filteredArr = splitArr
        .map((exp) => {
          const match = exp.match(/([><=]+)(\d+(?:\.\d+)?),(\d+(?:\.\d+)?)/);
          const operator = match ? match[1] : undefined;
          return match &&
            operator &&
            operators.hasOwnProperty(operator) &&
            operators[operator as keyof typeof operators](Number(price), match[2])
            ? match
            : undefined;
        })
        .filter((x) => x);

      // get best price expression
      const bestExp = filteredArr.reduce((prev, cur) => {
        if (!prev || (prev && cur && Number(cur[2]) > Number(prev[2]))) return cur;
        return prev || undefined;
      }, undefined);

      // fill best supply
      form.setFieldValue(n('supply'), formatNumberThousandWithDecimal(bestExp?.[3] || 0));
    }
  };

  const renderIconForInput = () => {
    if (type === DiscountTypeEnum.Dollar) return { prefix: <SvgDollarIcon /> };
    else return { suffix: <SvgPercentIcon /> };
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-general-modal-heading">
            {isEdit
              ? intl.formatMessage({ id: 'checkout.editServicePrice' })
              : intl.formatMessage({ id: 'checkout.generalService' })}
          </div>
        ),
        className: 'salon__checkout-general-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        name="generalService"
        layout="vertical"
        className="w-100 salon__checkout-general-modal-form"
      >
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[20, 20]}
          isWrap={true}
          styleFill="between"
          rowClassName="align-items-center"
        >
          <ColWrap colProps={{ span: 16 }}>
            <span className="salon__checkout-general-modal-title">
              {isEdit && serviceItem
                ? intl.formatMessage({ id: 'checkout.enterAmountNameOfService' }, { name: serviceItem.name })
                : intl.formatMessage({ id: 'checkout.generalService.title' })}
            </span>
          </ColWrap>
          <ColWrap colProps={{ span: 8 }}>
            <div className="d-flex gap-8 align-items-center justify-content-end">
              <div className="salon__checkout-general-modal-card-main gap-4 justify-content-start">
                <img width={20} height={20} src={technician?.avatar?.source || 'assets/qr/avatar.png'} alt="" />
                <p className="salon__checkout-general-modal-card-name m-b-0">
                  {technician?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
                </p>
              </div>
              {openModalSelect ? (
                <SvgReloadTechnician />
              ) : (
                <p className="salon__checkout-general-modal-card-button m-b-0" onClick={() => setOpenModalSelect(true)}>
                  {intl.formatMessage({ id: 'checkout.generalService.card.button' })}
                </p>
              )}
            </div>
          </ColWrap>

          <ColWrap colProps={{ span: 12 }}>
            <div className="salon__checkout-input-checkout-readonly">
              <FormInputNumber
                name={n('price')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.generalService.label.price' })}
                numericFormatProps={{
                  inputMode: 'none',
                  placeholder: '0.00',
                  className: 'm-b-0',
                  max: 999_999_999,
                  onFocus: () => setFocusedInputName(InputNameEnum.price),
                  // onChange: (e) => {
                  //   const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);

                  //   form.setFieldValue([n('price')], currentValue);
                  // },
                  // onKeyDown: (event) => {
                  //   const value = event?.key;
                  //   if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  //   if (value === 'Enter') {
                  //     onSubmit();
                  //   }
                  //   if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  //   return event.preventDefault();
                  // },
                  getInputRef: priceRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            </div>
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <div className="salon__checkout-input-checkout-readonly">
              <FormInputNumber
                name={n('supply')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.generalService.label.supply' })}
                numericFormatProps={{
                  inputMode: 'none',
                  placeholder: '0.00',
                  className: 'm-b-0',
                  max: 999_999_999,
                  onFocus: () => setFocusedInputName(InputNameEnum.supply),
                  onChange: (e) => {
                    const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
                    form.setFieldValue([n('supply')], currentValue);
                  },
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      onSubmit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                  getInputRef: supplyRef,
                  disabled: settingCheckout?.inputingServiceSupply && (serviceItem?.isGeneralService || !serviceItem),
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            </div>
          </ColWrap>
        </RowWrap>
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <FormSelect
              name={n('type')}
              formItemProps={{
                className: 'salon__checkout-select-redeem m-b-0',
                initialValue: DiscountTypeEnum.Dollar,
              }}
              selectProps={{
                className: 'font-weight-500',
                options: [
                  {
                    label: intl.formatMessage({ id: 'checkout.discount.anAmount' }),
                    value: DiscountTypeEnum.Dollar,
                  },
                  {
                    label: intl.formatMessage({ id: 'checkout.discount.aPercent' }),
                    value: DiscountTypeEnum.Percent,
                  },
                ],
                allowClear: false,
                suffixIcon: <SvgArrowSelectIcon />,
              }}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 24 }}>
            {/* <FormInputNumber
              name={n('forOff')}
              label={intl.formatMessage({ id: 'checkout.discount.title' })}
              {...renderIconForInput()}
              numericFormatProps={{
                className: 'm-b-0',
                inputMode: 'none',
                max: type === '$' ? 999_999_999 : 100,
                maxLength: type === '$' ? 11 : 3,
                placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.placeholder' }),
                onFocus: () => setFocusedInputName(InputNameEnum.forOff),
                getInputRef: forOffRef,
                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
                id: 'salon__custom-redeem__input' + n('forOff'),
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
                rules: [{ required: true, message: '' }],
              }}
            /> */}

            <FormInputNumber
              name={n('forOff')}
              label={intl.formatMessage({ id: 'checkout.generalService.label.discount' })}
              {...renderIconForInput()}
              numericFormatProps={{
                className: 'm-b-0',
                inputMode: 'none',
                max: type === '$' ? 999_999_999 : 100,
                maxLength: type === '$' ? 11 : 3,
                placeholder: intl.formatMessage({ id: 'checkout.discountAndRewards.customRedeem.placeholder' }),
                onFocus: () => setFocusedInputName(InputNameEnum.forOff),
                onChange: (e) => {
                  const currentValue = Number.parseFloat(e.target.value.replace(/,/g, '')).toFixed(2);
                  form.setFieldValue([n('forOff')], currentValue);
                },

                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    onSubmit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
                getInputRef: forOffRef,
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
            />
          </ColWrap>
        </RowWrap>
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-giftcard-modal-keyboard-button-text ">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                onClick: () => {
                  onSubmit();
                },
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>

      <ModalSelectTechnician
        openModalSelect={openModalSelect}
        setOpenModalSelect={setOpenModalSelect}
        setIdTechnician={setIdTechnician}
        setListSkill={setListSkill}
        setTechnician={handleChangeTechnician}
        idTechnician={idTechnician}
        appointmentBill={appointmentBill}
        idService={idService}
        listTechnician={listTechnician}
        setAppointmentBill={setAppointmentBill}
        listIdDisable={listIdDisable}
        setListIdDisable={setListIdDisable}
        setTechnicianItem={setTechnicianItem}
      />
    </StyledModal>
  );
}

export default GeneralService;
