import { Form } from 'antd';
import { useRef, useEffect, useState } from 'react';
import FormWrap from '../../../../components/FormWrap';
import { FormInput } from '../../../../components/Form/FormInput';
import NotificationError from '../../../../components/HandleShowNotiError';
import { useIntl } from 'react-intl';
import { customerApi } from '../../../../apis';
import { useMutation } from '@tanstack/react-query';
import { Customer, SettingCheckin } from '../../../../apis/client-axios';
import { useDebounce } from './useDebounce';
import KeyboardWrapper from '../../../../components/KeyboardWrapper';
import {
  DATE_FORMAT,
  emailChecker,
  formatPhoneNumber,
  phoneChecker,
  timeZoneSalon,
  toDayjsTimeZone,
  toStartOfDaysByTimeZone,
} from '../../../../utils';
import { FormDatePicker } from '../../../../components/Form/FormDatePicker';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { useParams } from 'react-router-dom';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface ITabOneProps {
  dataDetail: Customer | undefined;
  settingCheckin: SettingCheckin | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  dataCustomer: Customer | undefined;
  setDataCustomer: (customer: Customer) => void;
  setCheckedTechnician: (checkedTechnician: boolean) => void;
  setSelectedTechnician: (selectedTechnician: number | undefined) => void;
  setListSelectedService: (services: number[]) => void;
  setReceiveMessagesByPhone: (receiveMessagesByPhone: boolean) => void;
  setReceivePromotionViaEmail: (receivePromotionViaEmail: boolean) => void;
  setDataDetail: (customer: Customer) => void;
  showKeyboard: boolean;
  isShowNextBtn: boolean;
  setListMerchandise: (merchandise: number[]) => void;
  setAppointmentId: (appoinmentId: number) => void;
  onFinish: (customerParams?: Customer) => void;
}
function StepOne<T extends object>(props: ITabOneProps) {
  const {
    dataDetail,
    settingCheckin,
    setStep,
    dataCustomer,
    setDataCustomer,
    setCheckedTechnician,
    setSelectedTechnician,
    setListSelectedService,
    setDataDetail,
    showKeyboard,
    isShowNextBtn,
    setListMerchandise,
    setAppointmentId,
    onFinish,
  } = props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const [inputName, setInputName] = useState<string | undefined>('phoneNumber');
  const { id } = useParams();
  const dataPhone = Form.useWatch('phoneNumber', form)?.replace(/[-\s]/g, '');
  const dataEmail = Form.useWatch('emailAddress', form);
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [emailExisted, setEmailExisted] = useState<string>();

  useEffect(() => {
    document.getElementById('customer_birthDay')?.setAttribute('inputmode', 'none');
    document.getElementById('phoneNumber')?.focus();
  }, []);
  const checkEmail = useMutation(() => customerApi.customerControllerGetCustomerByEmail(dataEmail), {
    onSuccess: ({ data }) => {
      if (data) {
        setEmailExisted(data.emailAddress);
        form.setFields([
          {
            name: 'customerEmail',
            errors: [intl.formatMessage({ id: 'common.emailAddressExisted' })],
          },
        ]);
      }
    },
  });
  const createCustomerCheckinMutation = useMutation(
    (dataPhone: string) => customerApi.customerControllerGetCustomerByPhoneNumber(dataPhone),
    {
      onSuccess: (data) => {
        setDataDetail(data?.data);
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
    }
  );
  useEffect(() => {
    if (dataDetail) {
      if (dataDetail?.appointments && dataDetail?.appointments.length > 0) {
        setSelectedTechnician(dataDetail.appointments[0]?.technician?.id ?? undefined);
        setListSelectedService(
          dataDetail.appointments[0]?.services?.filter((item) => item.isMerchandise === false).map((item) => item.id) ??
            []
        );
        setListMerchandise(
          dataDetail.appointments[0]?.services?.filter((item) => item.isMerchandise === true).map((item) => item.id) ??
            []
        );
        setCheckedTechnician(dataDetail.appointments[0]?.useAnyTechnician);
        setAppointmentId(dataDetail.appointments[0]?.id);
      }
      form.setFieldValue('name', dataDetail?.name);
      form.setFieldValue('emailAddress', dataDetail?.emailAddress);
      form.setFieldValue('phoneNumber', formatPhoneNumber(dataDetail?.phoneNumber));
      dataDetail?.birthDay
        ? form.setFieldValue('birthDay', dayjs(toDayjsTimeZone(dataDetail?.birthDay).format(DATE_FORMAT)))
        : form.setFieldValue('birthDay', null);
    } else {
      form.setFieldValue('name', '');
      form.setFieldValue('birthDay', null);
      form.setFieldValue('emailAddress', '');
      setSelectedTechnician(undefined);
      setListSelectedService([]);
      setListMerchandise([]);
      setCheckedTechnician(true);
    }
  }, [dataDetail]);

  useEffect(() => {
    form.setFieldsValue({
      ...dataCustomer,
      birthDay: dataCustomer?.birthDay ? dayjs(toDayjsTimeZone(dataCustomer?.birthDay).format(DATE_FORMAT)) : null,
    });
  }, [dataCustomer]);

  useEffect(() => {
    if (dataPhone && dataPhone !== dataDetail?.phoneNumber) {
      createCustomerCheckinMutation.mutate(dataPhone);
    }
  }, [dataPhone]);

  const onNext = async () => {
    await form
      .validateFields()
      .then((data) => {
        if (data?.birthDay) {
          data.birthDay = toStartOfDaysByTimeZone(data.birthDay);
        }
        if (data?.name === undefined || /^\s*$/.test(data?.name.trim())) {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
        } else if (dataDetail?.appointments && dataDetail.appointments.length > 0) {
          if (!settingCheckin?.asksOptionReceivingTextMessage) {
            onFinish(data);
          } else {
            setDataCustomer(data);
            if (!settingCheckin?.autoAssignAppointmentTechnician) {
              setStep((prev) => prev + 1);
            } else {
              setStep((prev) => prev + 2);
            }
          }
        } else {
          if (
            !settingCheckin?.allowSelectingServices &&
            !settingCheckin?.allowSelectingTechnician &&
            !settingCheckin?.asksOptionReceivingTextMessage
          ) {
            onFinish(data);
          } else {
            setDataCustomer(data);
            if (settingCheckin?.allowSelectingServices || settingCheckin?.allowSelectingTechnician) {
              setStep((prev) => prev + 1);
            } else {
              setStep((prev) => prev + 2);
            }
          }
        }
      })
      .catch(() => {
        NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
      });
  };

  return (
    <section className="salon__step-one">
      <div className="salon__online-appointment-container">
        <h2 className="salon__step-one-title">{intl.formatMessage({ id: 'bookingOnline.step1' })}</h2>
        <div className="salon__form">
          <div className="salon__form-wrap">
            <div className="salon__form-item">
              <FormWrap
                name="customer"
                layout="vertical"
                initialValues={{ remember: true }}
                autoComplete="off"
                requiredMark={false}
                form={form}
              >
                <FormInput
                  name={'phoneNumber'}
                  inputProps={{
                    id: 'phoneNumber',
                    className: 'font-weight-500 ',
                    inputMode: showKeyboard ? 'none' : 'tel',
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.phone' }),
                    onFocus: () => {
                      setInputName('phoneNumber');
                    },
                    maxLength: 16,
                    // onBlur: () => {
                    //   setInputName('output');
                    // },
                    onKeyDown: (event) => {
                      const value = event?.key;
                      if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                      if (value === 'Enter') {
                        form.submit();
                      }
                      if (/^\d+$/.test(value)) {
                        keyboardRef.current?.handleButtonClicked(value);
                      }
                      return event.preventDefault();
                    },
                  }}
                  formItemProps={{
                    required: true,
                    rules: [
                      {
                        validator: (_, value) => {
                          const v = value;
                          if (!v) {
                            return Promise.reject();
                          }
                          if (v && v.replace(/[-\s]/g, '').length < 10) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
                <FormInput
                  name={'name'}
                  inputProps={{
                    maxLength: 255,
                    inputMode: showKeyboard ? 'none' : 'text',
                    onFocus: () => setInputName('name'),
                    // onBlur: () => {
                    //   setInputName('output');
                    // },
                    onKeyDown: (event) => {
                      const value = event?.key;
                      if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                      if (value === 'Enter') {
                        form.submit();
                      }
                      if (value !== 'Backspace' && value !== 'Enter' && event?.key?.length < 2)
                        keyboardRef.current?.handleButtonClicked(value);
                      return event.preventDefault();
                    },
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.name' }),
                    disabled: !!(dataPhone ? dataPhone?.toString()?.length < 10 : true),
                  }}
                  formItemProps={{
                    required: true,
                    rules: [
                      {
                        validator: (_, value) => {
                          const v = value?.toString().trim();
                          if (!v) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
                <FormInput
                  name={'emailAddress'}
                  inputProps={{
                    maxLength: 255,
                    inputMode: showKeyboard ? 'none' : 'text',
                    onFocus: () => setInputName('emailAddress'),
                    // onBlur: () => {
                    //   setInputName('output');
                    // },
                    onBlur: (event) => {
                      const v = event.target.value;
                      if (!v) return;
                      if (v && !emailChecker(v)) {
                        return;
                      }
                      checkEmail.mutate();
                    },
                    onKeyDown: (event) => {
                      const value = event?.key;
                      if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                      if (value === 'Enter') {
                        form.submit();
                      }
                      if (value !== 'Backspace' && value !== 'Enter' && event?.key?.length < 2)
                        keyboardRef.current?.handleButtonClicked(value);
                      return event.preventDefault();
                    },
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.email' }),
                    disabled: !!(dataPhone ? dataPhone?.toString()?.length < 10 : true),
                  }}
                  formItemProps={{
                    required: true,
                    rules: [
                      {
                        validator: (_, value: string) => {
                          const v = value?.trim();
                          if (v && !emailChecker(v)) {
                            return Promise.reject('');
                          }
                          if (v && v === emailExisted) {
                            return Promise.reject(intl.formatMessage({ id: 'common.emailAddressExisted' }));
                          }
                          return Promise.resolve();
                        },
                      },
                    ],
                  }}
                />
                <FormDatePicker
                  name={'birthDay'}
                  datePickerProps={{
                    placeholder: intl.formatMessage({ id: 'bookingOnline.service.placeholder.birthday' }),
                    disabled: !!(dataPhone ? dataPhone?.toString()?.length < 10 : true),
                    format: DATE_FORMAT,
                    disabledDate: (date) => {
                      return dayjs(date).format('YYYY-MM-DD') > toDayjsTimeZone().format('YYYY-MM-DD');
                    },
                    allowClear: false,
                    showToday: false,
                    className: `salon__custom-date-booking-online ${
                      !(dataPhone ? dataPhone?.toString()?.length < 10 : true) ? 'salon__border-color' : ''
                    }`,
                  }}
                />
              </FormWrap>
            </div>
          </div>

          <div className="salon__form-keyboard" style={{ display: showKeyboard ? 'block' : 'none' }}>
            <KeyboardWrapper
              form={form}
              onFinish={() => {}}
              buttonNext={true}
              onNext={onNext}
              maxLength={inputName === 'phoneNumber' ? 10 : 255}
              inputName={inputName ?? 'phoneNumber'}
              keyboardRef={keyboardRef}
              keyboardProps={{
                inputPattern: inputName === 'phoneNumber' ? /^\d+$/ : undefined,
              }}
            />
          </div>

          {(id || isShowNextBtn) && (
            <div className="salon__custom-m-t d-flex justify-content-end m-t-200 p-t-7">
              <ButtonStyled
                content={intl.formatMessage({ id: 'common.next' })}
                buttonProps={{
                  className:
                    'width-184 height-64 border-radius-12px font-weight-700 font-size-24 line-height-29 text-transform-capitalize',
                  onClick: onNext,
                }}
                isPrimary
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default StepOne;
