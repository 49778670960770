import { Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgDollarIcon } from '../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../components/ColWrap';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IAppointmentBill, IListIdDisable, IMerchandise } from '../index';
import KeyboardWrapper, { KeyboardDirection } from '../keyboard';
import './style.scss';
import { DiscountTypeEnum, IDiscountsAndRewardsApplied } from '../checkoutConstants';
import { handleTruncateToTwoDecimal } from '../../../../utils';
import { KeyboardReactInterface } from 'react-simple-keyboard';

export interface Props {
  open: boolean;
  appointmentBill: IAppointmentBill;
  listIdDisable: IListIdDisable;
  discountsAndRewardsApplied?: IDiscountsAndRewardsApplied;
  onCancelModal: () => void;
  handleGetTotal: (updateBill: IAppointmentBill) => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  setListIdDisable: ({}: IListIdDisable) => void;
  taxPercent: number;
}

enum InputNameEnum {
  supply = 'supply',
}

interface FormData {
  price?: number;
  supply?: number;
  switch?: boolean;
}

function Merchandise<T extends object>(props: Props) {
  const {
    open,
    appointmentBill,
    listIdDisable,
    discountsAndRewardsApplied,
    taxPercent,
    onCancelModal,
    handleGetTotal,
    setAppointmentBill,
    setListIdDisable,
  } = props;

  const intl = useIntl();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum | undefined>(InputNameEnum.supply);
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();

  const supplyRef = useRef<HTMLInputElement>(null);
  const supply = Form.useWatch(n('supply'), form);

  useEffect(() => {
    if (supply && supplyRef.current) {
      const [integer] = supply.replace(/,/g, '').split('.');
      supplyRef.current.selectionStart = supplyRef.current.selectionEnd = supply.length + Math.floor(integer / 3);
    }
  }, [supply]);

  const onSubmit = () => {
    const data = form.getFieldsValue();
    if (data?.supply === undefined || data?.supply.trim() === '') {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.merchandise.validate' }) });
    } else {
      const dataMerchandisePrice = form.getFieldValue(n('supply')) ?? 0;
      const generalDiscountMerchandise =
        discountsAndRewardsApplied?.generalMerchandiseDiscount?.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount
          : handleTruncateToTwoDecimal(
              (handleConvertPrice(dataMerchandisePrice.toString()) *
                (discountsAndRewardsApplied?.generalMerchandiseDiscount?.amount ?? 0)) /
                100
            );
      const generalDiscountTicket =
        discountsAndRewardsApplied?.generalTicketDiscount?.type === DiscountTypeEnum.Dollar
          ? discountsAndRewardsApplied?.generalTicketDiscount?.amount
          : handleTruncateToTwoDecimal(
              (handleConvertPrice(dataMerchandisePrice.toString()) *
                (discountsAndRewardsApplied?.generalTicketDiscount?.amount ?? 0)) /
                100
            );
      const totalDiscount: number =
        (!!generalDiscountMerchandise ? +generalDiscountMerchandise : 0) +
          (!!generalDiscountTicket ? +generalDiscountTicket : 0) ?? 0;
      const price = handleConvertPrice(dataMerchandisePrice.toString());
      const tax =
        +taxPercent > 0
          ? {
              type: DiscountTypeEnum.Percent,
              moneyDiscount: (price * +taxPercent) / 100,
            }
          : undefined;

      const updateBill = {
        ...appointmentBill,
        ticketMerchandise: [
          {
            idDelete: parseFloat(Math.random().toFixed(3)) * 10,
            merchandiseId: parseFloat(Math.random().toFixed(3)) * 10,
            name: intl.formatMessage({ id: 'checkout.merchandise' }),
            merchandisePrice: price,
            isTaxable: true,
            totalDiscount: totalDiscount,
            discount: {
              tax: tax,
            },
          } as IMerchandise,
        ].concat(appointmentBill.ticketMerchandise),
      };
      setListIdDisable({
        ...listIdDisable,
        listIdMerchandise: updateBill.ticketMerchandise.map((item) => item.merchandiseId) as number[],
      });
      setAppointmentBill(updateBill);
      handleGetTotal(updateBill);
      form.resetFields();
      onCancelModal();
    }
  };
  const handleConvertPrice = (price: string) => {
    return price ? Number(price.toString().replace(/,/g, '')) : 0;
  };
  useEffect(() => {
    if (open) {
      supplyRef?.current?.focus();
    }
  }, [open]);
  return (
    <StyledModal
      isOpen={open}
      onCancel={() => {
        form.resetFields();
        onCancelModal();
      }}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: 'checkout.merchandise' })}
          </div>
        ),
        className: 'salon__checkout-merchandise-modal',
        width: 700,
        footer: null,
        destroyOnClose: true,
      }}
    >
      <FormWrap
        form={form}
        name="merchandiseForm"
        layout="vertical"
        className="w-100 salon__checkout-merchandise-modal-form "
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <div className="salon__checkout-input-checkout-readonly">
              <FormInputNumber
                name={n('supply')}
                label={
                  <span className="font-weight-400">{intl.formatMessage({ id: 'checkout.merchandise.title' })}</span>
                }
                prefix={<SvgDollarIcon />}
                numericFormatProps={{
                  inputMode: 'none',
                  className: 'm-b-0',
                  placeholder: intl.formatMessage({ id: 'checkout.merchandise.placeholder' }),
                  onFocus: () => setFocusedInputName(InputNameEnum.supply),
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      onSubmit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                  getInputRef: supplyRef,
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                }}
              />
            </div>
          </ColWrap>
          <ColWrap colProps={{ span: 19 }}>
            <KeyboardWrapper
              inputName={focusedInputName}
              form={form}
              keyboardRef={keyboardRef}
              maxLength={9}
              keyboardProps={{
                inputPattern: /^\d+$/,
              }}
              direction={KeyboardDirection.rtl}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 5 }}>
            <ButtonStyled
              content={
                <span className="salon__checkout-merchandise-modal-keyboard-button-text">
                  {intl.formatMessage({ id: 'checkout.ok' })}
                </span>
              }
              buttonProps={{
                className: 'width-122 height-328',
                onClick: () => {
                  onSubmit();
                },
              }}
              isPrimary={true}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
}

export default Merchandise;
