import { useMutation } from '@tanstack/react-query';
import { Form, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  IGiftCardPayment,
  IGiftCardPaymentInformation,
  IHandleSubmitCheckoutProps,
  IListIdTotalPrice,
  PaymentInformation,
} from '../../..';
import { ticketApi } from '../../../../../../apis';
import {
  CustomerGiftCard,
  SettingCheckout,
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInput } from '../../../../../../components/Form/FormInput';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { convertFormatNumberToNumber, formatNumberThousandWithDecimal } from '../../../../../../utils';
import KeyboardWrapper, { KeyboardDirection } from '../../../keyboard';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  leftBalanceToPay: number;
  isLoading: boolean;
  settingCheckout?: SettingCheckout;
}

interface FormData {
  giftCardNumber: number;
  giftCardValueUse: number;
}

enum InputNameEnum {
  GiftCardNumber = 'giftCardNumber',
  GiftCardValueUse = 'giftCardValueUse',
}

const { confirm } = Modal;

export const GiftCard: React.FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    setIsOpenPayAndComplete,
    listTotal,
    handleSubmitCheckout,
    onChangePaymentInformation,
    paymentInformation,
    leftBalanceToPay,
    isLoading,
    settingCheckout,
  } = props;
  const [form] = Form.useForm();
  const intl = useIntl();
  const keyboardRef = useRef<any>();
  const n = (key: keyof FormData) => key;
  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const [currentGiftCard, setCurrentGiftCard] = useState<CustomerGiftCard>();

  const giftCardNumber = Form.useWatch(n('giftCardNumber'), form);
  const giftCardNumberRef = useRef<HTMLInputElement>(null);

  const giftCardValueUse = Form.useWatch(n('giftCardValueUse'), form);
  const giftCardValueUseRef = useRef<HTMLInputElement>(null);

  const confirmDataNotFound = () => {
    confirm({
      width: 480,
      centered: true,
      className: 'salon__modal-styled salon__modal__confirmation',
      content: (
        <div className="text-center font-size-20 font-weight-400 line-height-28 white-space-pre-line">
          {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.incorrectCard' })}
        </div>
      ),
      onOk: () => {},
      cancelButtonProps: {
        className: 'd-none',
      },
      okButtonProps: {
        className: 'm-0',
      },
      okText: intl.formatMessage({ id: 'common.OK' }),
      zIndex: 10000,
    });
  };

  const checkGiftCardNumberMutation = useMutation(
    (giftCardNumber: number) => ticketApi.ticketControllerCheckGiftCardByNumber(giftCardNumber),
    {
      onSuccess: ({ data }) => {
        if (!data) {
          confirmDataNotFound();
        } else {
          setCurrentGiftCard(data);
        }
      },
      onError: (error) => {
        confirmDataNotFound();
      },
    }
  );

  useEffect(() => {
    if (giftCardNumber && giftCardNumberRef.current) {
      const [integer] = giftCardNumber.replace(/,/g, '').split('.');
      giftCardNumberRef.current.selectionStart = giftCardNumberRef.current.selectionEnd =
        giftCardNumber.length + Math.floor(integer / 3);
    }
  }, [giftCardNumber]);

  useEffect(() => {
    if (giftCardValueUse && giftCardValueUseRef.current) {
      const [integer] = giftCardValueUse.replace(/,/g, '').split('.');
      giftCardValueUseRef.current.selectionStart = giftCardValueUseRef.current.selectionEnd =
        giftCardValueUse.length + Math.floor(integer / 3);
    }
  }, [giftCardValueUse]);

  const onClickOK = (value: FormData) => {
    // check gift card number if havent had
    if (!currentGiftCard && value?.giftCardNumber) {
      checkGiftCardNumberMutation.mutate(value.giftCardNumber);
    }

    // if had gift card then pass above condition to this
    if (currentGiftCard) {
      const giftCardValueUse = convertFormatNumberToNumber(value.giftCardValueUse);

      const existedGiftCardPayment: IGiftCardPayment = {
        ...(paymentInformation?.giftCardPayment ?? { totalValue: 0, giftCards: [] }),
      };

      // if find the swiped gift card in payment then update its states
      if (
        existedGiftCardPayment?.giftCards &&
        existedGiftCardPayment?.giftCards?.findIndex(
          (giftCard) => giftCard.giftCardNumber === currentGiftCard.giftCardValue
        ) >= 0
      ) {
        const index = existedGiftCardPayment?.giftCards?.findIndex(
          (giftCard) => giftCard.giftCardNumber === currentGiftCard.giftCardNumber
        );
        existedGiftCardPayment.giftCards[index].giftCardValueUse +=
          giftCardValueUse > leftBalanceToPay ? leftBalanceToPay : giftCardValueUse;
      }
      // if not then clone the swiped gift card list and push new gift card
      else {
        const temp: IGiftCardPaymentInformation[] = existedGiftCardPayment?.giftCards
          ? [...existedGiftCardPayment?.giftCards]
          : [];
        temp.push({
          giftCardNumber: currentGiftCard.giftCardNumber,
          giftCardValueUse: giftCardValueUse,
        });
        existedGiftCardPayment.giftCards = [...temp];
      }

      // count again the total value of gift card
      let totalValue = 0;

      existedGiftCardPayment.giftCards.forEach((giftCard) => {
        totalValue += giftCard.giftCardValueUse;
      });

      existedGiftCardPayment.totalValue = totalValue;

      // save history of transaction
      const ticketTransaction = [...(paymentInformation?.ticketTransaction ?? [])];

      const customerHandYouTrans: TicketTransactionDTO = {
        // money: giftCardValueUse > leftBalanceToPay ? handleTruncateToTwoDecimal(leftBalanceToPay) : giftCardValueUse,
        money: giftCardValueUse,
        typePayment: TicketTransactionDTOTypePaymentEnum.GiftCard,
        status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
        tipMoney: 0,
        giftCardNumber: currentGiftCard.giftCardNumber,
      };

      ticketTransaction.push(customerHandYouTrans);
      if (giftCardValueUse < leftBalanceToPay) {
        onChangePaymentInformation((prev) => ({
          ...prev,
          giftCardPayment: existedGiftCardPayment,
          ticketTransaction: ticketTransaction,
        }));
      } else {
        onChangePaymentInformation((prev) => ({
          ...prev,
          giftCardPayment: existedGiftCardPayment,
          ticketTransaction: ticketTransaction,
        }));

        handleSubmitCheckout({
          status: TicketDTOStatusEnum.Completed,
          ticketTransaction: ticketTransaction,
          // totalTip: paymentInformation?.tip ?? 0,
          balanceToPay: 0,
        });
      }

      setOpen(false);
    }
  };

  useEffect(() => {
    if (currentGiftCard) {
      form.setFieldsValue({
        [n('giftCardValueUse')]: null,
      });
    } else {
      form.setFieldsValue({
        [n('giftCardNumber')]: null,
      });
    }
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      [n('giftCardValueUse')]: formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0),
    });
  }, []);

  useEffect(() => {
    if (open) {
      if (currentGiftCard) {
        giftCardValueUseRef.current?.focus();
      } else {
        giftCardNumberRef.current?.focus();
      }
    }
  }, [open, currentGiftCard, giftCardNumber]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: 'checkout.payAndComplete.title.giftCardPayment' })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 700,
        footer: null,
      }}
    >
      <FormWrap
        form={form}
        onFinish={onClickOK}
        className="salon__checkout__pay-n-complete-modal__cash-payment"
        layout="vertical"
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          {currentGiftCard ? (
            <>
              {settingCheckout?.managingGiftCards ? (
                <ColWrap colProps={{ span: 24 }}>
                  <p className="font-size-16 font-weight-500 m-0">
                    {intl.formatMessage({ id: 'checkout.giftCardBalance' })}&nbsp;
                    <span className="font-weight-700">
                      $
                      {formatNumberThousandWithDecimal(
                        currentGiftCard.giftCardValue -
                          (paymentInformation?.giftCardPayment?.giftCards?.find(
                            (gc) => gc.giftCardNumber === currentGiftCard.giftCardNumber
                          )?.giftCardValueUse ?? 0)
                      )}
                    </span>
                  </p>
                </ColWrap>
              ) : (
                <></>
              )}
              <ColWrap colProps={{ span: 24 }}>
                <p className="font-size-16 font-weight-500 m-0">
                  {intl.formatMessage({ id: 'checkout.balanceToPay' })}:&nbsp;
                  <span className="font-weight-700">${formatNumberThousandWithDecimal(leftBalanceToPay)}</span>
                </p>
              </ColWrap>
            </>
          ) : (
            ''
          )}
          <ColWrap colProps={{ span: 24 }}>
            {currentGiftCard ? (
              <FormInputNumber
                name={n('giftCardValueUse')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({
                  id: 'checkout.payAndComplete.giftCard.giftCardValue.label.howMuchDidCustomerHandInGiftCard',
                })}
                numericFormatProps={{
                  placeholder: '0.00',
                  className: 'm-b-0',
                  inputMode: 'none',
                  onFocus: () => setFocusedInputName(InputNameEnum.GiftCardValueUse),
                  getInputRef: giftCardValueUseRef,
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                  rules: [
                    {
                      validator: (_, value) => {
                        const v = convertFormatNumberToNumber(value);
                        if (
                          v &&
                          v >
                            currentGiftCard.giftCardValue -
                              (paymentInformation?.giftCardPayment?.giftCards?.find(
                                (gc) => gc.giftCardNumber === currentGiftCard.giftCardNumber
                              )?.giftCardValueUse ?? 0)
                        ) {
                          return Promise.reject('');
                        }
                        return Promise.resolve();
                      },
                    },
                  ],
                }}
              />
            ) : (
              <FormInput
                name={n('giftCardNumber')}
                label={
                  <span className="text-transform-first-letter-capitalize">
                    {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.please' })}
                    <span className="font-weight-600">
                      &nbsp;
                      {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.swipe' })}
                      &nbsp;
                    </span>
                    {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.or' })}
                    <span className="font-weight-600">
                      &nbsp;
                      {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.enter' })}
                      &nbsp;
                    </span>
                    {intl.formatMessage({ id: 'checkout.payAndComplete.giftCard.giftCardNumber.yourGiftCardNumber' })}
                  </span>
                }
                inputProps={{
                  ref: giftCardNumberRef,
                  placeholder: intl.formatMessage({
                    id: 'checkout.payAndComplete.giftCard.giftCardNumber.placeHolder.enterYourGiftCardNumber',
                  }),
                  className: 'm-b-0',
                  inputMode: 'none',
                  onFocus: () => setFocusedInputName(InputNameEnum.GiftCardNumber),
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                }}
              />
            )}
          </ColWrap>

          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
              {/* numeric keyboard */}
              <ColWrap colProps={{ span: 18 }}>
                <KeyboardWrapper
                  inputName={focusedInputName}
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={9}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                  direction={KeyboardDirection.rtl}
                  isNotFormat={!currentGiftCard}
                  disabledKeyboard={checkGiftCardNumberMutation.isLoading || isLoading}
                />
              </ColWrap>

              {/* ok button */}
              <ColWrap colProps={{ span: 6 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                    htmlType: 'submit',
                    loading: checkGiftCardNumberMutation.isLoading || isLoading,
                    disabled: checkGiftCardNumberMutation.isLoading || isLoading,
                  }}
                  content={intl.formatMessage({ id: 'common.OK' })}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
};
