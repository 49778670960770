import React from 'react';

export const SvgAppointmentInputDropUpIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.70663 12.5864L11.2966 9.99636C11.6866 9.60636 12.3166 9.60636 12.7066 9.99636L15.2966 12.5864C15.9266 13.2164 15.4766 14.2964 14.5866 14.2964H9.40663C8.51663 14.2964 8.07663 13.2164 8.70663 12.5864Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
