import React, { FunctionComponent, MutableRefObject, ReactNode } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import RowWrap from '../../../../components/RowWrap';
import ColWrap from '../../../../components/ColWrap';
import { SvgRemoveTechnicianIcon } from '../../../../components/@svg/SvgRemoveTechnicianIcon';
import { ButtonIcon } from '../../../../components/buttons/ButtonIcon';
import { formatNumberThousandWithDecimal } from '../../../../utils';

interface IProps<T extends object> {
  onClick?: () => void;
  name: string | ReactNode;
  price?: string | number;
  ptsReward?: string | ReactNode;
  isBoldName?: boolean;
  isWarning?: boolean;
  isHidePrice?: boolean;
}

function BillItem<T extends object>(props: IProps<T>) {
  const { onClick, name, price, ptsReward, isBoldName, isWarning, isHidePrice } = props;

  return (
    <RowWrap
      isAutoFillRow={true}
      isGutter={true}
      gutter={8}
      isWrap={false}
      styleFill="between"
      rowClassName="align-items-center w-100 m-0"
    >
      {onClick ? (
        <ColWrap>
          <ButtonIcon
            icon={<SvgRemoveTechnicianIcon />}
            className={'salon__checkout-bill-item-remove-btn m-r-10'}
            buttonProps={{
              onClick: onClick,
            }}
          />
        </ColWrap>
      ) : (
        <></>
      )}
      <ColWrap
        colProps={{
          className: 'w-100 m-0 p-0',
        }}
      >
        <RowWrap
          isAutoFillRow={true}
          isGutter={false}
          gutter={0}
          isWrap={true}
          styleFill="start"
          rowClassName="align-items-center w-100"
        >
          <ColWrap
            colProps={{
              span: 24,
              className: 'm-0 p-0',
            }}
          >
            <RowWrap
              isAutoFillRow={true}
              isGutter={false}
              gutter={0}
              isWrap={true}
              styleFill="between"
              rowClassName="align-items-center"
            >
              <ColWrap>
                <span
                  className={`${isWarning ? 'salon__checkout-bill-item-warning' : 'salon__checkout-bill-item-name'} ${
                    isBoldName ? 'font-weight-600' : ''
                  }`}
                >
                  {name}
                </span>
              </ColWrap>
              <ColWrap
                colProps={{
                  className: 'm-0',
                }}
              >
                <span
                  className={`${isWarning ? 'salon__checkout-bill-item-warning' : 'salon__checkout-bill-item-price'}`}
                >
                  {isHidePrice ? <></> : ptsReward ? ptsReward : `$${formatNumberThousandWithDecimal(price ?? 0)}`}
                </span>
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </ColWrap>
    </RowWrap>
  );
}

export default BillItem;
