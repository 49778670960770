import { Form, FormItemProps } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import React, { ReactNode } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import './formInputNumber.scss';
type Props = {
  name: string | NamePath<any>;
  label?: ReactNode | string;
  formItemProps?: FormItemProps;
  numericFormatProps?: NumericFormatProps;
  prefix?: ReactNode | string;
  suffix?: ReactNode | string;
  prefixInsideInput?: string;
  classNameFormInputNumber?: string;
  rootClassNameFormInputNumber?: string;
};

export const FormInputNumber: React.FC<Props> = ({
  numericFormatProps,
  formItemProps,
  prefix,
  prefixInsideInput,
  suffix,
  name,
  label,
  classNameFormInputNumber,
  rootClassNameFormInputNumber,
}) => {
  return (
    <div
      className={`salon__form-input-container ${
        numericFormatProps?.readOnly ? 'salon__form-input-container-read-only' : ''
      } ${rootClassNameFormInputNumber || ''}`}
    >
      {label ? (
        <label htmlFor={name} className="p-b-8 salon__form-input-container-label">
          {label}
          {formItemProps?.required ? <span className="salon__form-input-label">*</span> : ''}
        </label>
      ) : (
        <></>
      )}
      <div className={`salon__form-input-number ${classNameFormInputNumber ?? ''}`}>
        {prefix ? <div className="salon__form-input-number-prefix">{prefix}</div> : <></>}
        <Form.Item
          name={name}
          {...formItemProps}
          className={`w-100 ${formItemProps?.className ?? ''} ${
            prefix && suffix
              ? 'salon__form-input-number-with-icon'
              : `${prefix ? 'salon__form-input-number-with-prefix' : 'salon__form-input-number-with-out-prefix'} ${
                  suffix ? 'salon__form-input-number-with-suffix' : 'salon__form-input-number-with-out-suffix'
                }`
          }`}
        >
          <NumericFormat
            allowNegative={false}
            decimalScale={2}
            thousandsGroupStyle={'thousand'}
            thousandSeparator={','}
            autoComplete="off"
            {...numericFormatProps}
            min={0}
            max={999_999_999}
            prefix={prefixInsideInput}
            // disabled={disabled}
            isAllowed={({ floatValue, value }) => {
              if (!value || (!floatValue && floatValue !== 0)) return true;
              if (
                numericFormatProps?.maxLength &&
                (value.length > numericFormatProps?.maxLength || `${floatValue}`.length > numericFormatProps?.maxLength)
              ) {
                return false;
              }
              if (numericFormatProps?.max && +floatValue > +numericFormatProps.max) return false;
              if (numericFormatProps?.min && +floatValue < +numericFormatProps.min) return false;
              return true;
            }}
          />
        </Form.Item>
        {suffix ? <div className="salon__form-input-number-suffix">{suffix}</div> : <></>}
      </div>
    </div>
  );
};
