import React from 'react';

export const SvgReloadIcon = () => (
  <span>
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="16" fill="white" fill-opacity="0.05" />
      <path
        d="M16.5 7.99992C16.5 11.6799 13.14 14.6666 9 14.6666C4.86 14.6666 2.3325 10.9599 2.3325 10.9599M2.3325 10.9599H5.7225M2.3325 10.9599V14.2933M1.5 7.99992C1.5 4.31992 4.83 1.33325 9 1.33325C14.0025 1.33325 16.5 5.03992 16.5 5.03992M16.5 5.03992V1.70659M16.5 5.03992H13.17"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);
