import { ThemeConfig } from 'antd/lib';
import { Theme } from 'antd/lib/config-provider/context';

export const customTheme: ThemeConfig | Theme | undefined = {
  token: {
    fontFamily: 'Inter, Roboto, sans-serif',
    colorText: '#292F33',
    colorPrimary: '#0090FF',
  },
  components: {
    Radio: {
      fontSize: 16,
    },
  },
};
