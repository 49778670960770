import {
  AppointmentEvent,
  FilterTechnicianType,
  OpenPopoverType,
  OpenSettingPopoverType,
  SlideActionType,
} from './listAppointment';

export const OPEN_POPOVER: Record<OpenPopoverType, OpenPopoverType> = {
  CALLER_ID: 'CALLER_ID',
  ONLINE_APPOINTMENT: 'ONLINE_APPOINTMENT',
  SETTING: 'SETTING',
  CHECKIN: 'CHECKIN',
};

export const OPEN_SETTING_POPOVER: Record<OpenSettingPopoverType, OpenSettingPopoverType> = {
  SETTING_DISPLAY: 'SETTING_DISPLAY',
  SETTING_TURN: 'SETTING_TURN',
};

export const FILTER_TECHNICIAN: Record<FilterTechnicianType, FilterTechnicianType> = {
  ALL: 'ALL',
  AT_LEAST_ONE: 'AT_LEAST_ONE',
};

export const SLIDE_ACTION: Record<SlideActionType, SlideActionType> = {
  CHOOSE: 'CHOOSE',
  NO_ACTION: 'NO_ACTION',
  SWIPE: 'SWIPE',
  PICK_CALENDAR: 'PICK_CALENDAR',
};

export const RESOURCE_UNASIGNED_KEY = 'unasigned';
export const RESOURCE_UNASIGNED_NAME = 'Unassigned';

export const getTechnicianDetail = (technicianId: string | number, events: AppointmentEvent[]) => {
  const detail = { revenue: 0, tasks: 0, hours: 0 };

  events
    .filter((event) => event.resourceId === technicianId)
    .forEach((event) => {
      detail.hours += (event.resource?.estimate || 0) / 60;
      detail.tasks += 1;
      detail.revenue += parseFloat(event.resource?.totalMoney?.toString() as string) || 0;
    });

  return detail;
};

export const APPOINTMENT_SORT = 'ascend-id';

export const ID_CHECKOUT_VIRTUAL = 'appointment_checkout_virtual';
