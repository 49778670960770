import React from 'react';

export const SvgPrevCarouselIcon = () => (
  <span>
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.49781 0.352542C3.4044 0.258915 3.27757 0.206299 3.14531 0.206299C3.01305 0.206299 2.88623 0.258915 2.79281 0.352542L0.497812 2.64754C0.302813 2.84254 0.302813 3.15754 0.497812 3.35254L2.79281 5.64754C2.98781 5.84254 3.30281 5.84254 3.49781 5.64754C3.69281 5.45254 3.69281 5.13754 3.49781 4.94254L1.55781 2.99754L3.49781 1.05754C3.69281 0.862542 3.68781 0.542542 3.49781 0.352542Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
