import React from 'react';

export const SvgSearchIcon = () => (
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1867 14.4716H15.9767L20.2167 18.7316C20.6267 19.1416 20.6267 19.8116 20.2167 20.2216C19.8067 20.6316 19.1367 20.6316 18.7267 20.2216L14.4767 15.9716V15.1816L14.2067 14.9016C12.8067 16.1016 10.8967 16.7216 8.8667 16.3816C6.0867 15.9116 3.8667 13.5916 3.5267 10.7916C3.0067 6.56157 6.5667 3.00157 10.7967 3.52157C13.5967 3.86157 15.9167 6.08157 16.3867 8.86157C16.7267 10.8916 16.1067 12.8016 14.9067 14.2016L15.1867 14.4716ZM5.4767 9.97157C5.4767 12.4616 7.4867 14.4716 9.9767 14.4716C12.4667 14.4716 14.4767 12.4616 14.4767 9.97157C14.4767 7.48157 12.4667 5.47157 9.9767 5.47157C7.4867 5.47157 5.4767 7.48157 5.4767 9.97157Z"
        fill="#B4B6B8"
      />
    </svg>
  </span>
);
