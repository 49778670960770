import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import React from 'react';
import { SvgArrowDownSelect } from '../../@svg/SvgArrowDownSelect';
import './formSelect.scss';

type Props = {
  name: string;
  label?: React.ReactNode;
  formItemProps?: FormItemProps;
  selectProps?: SelectProps;
  isFilter?: boolean;
};

export const FormSelect: React.FC<Props> = ({ selectProps, formItemProps, isFilter = false, ...rest }) => {
  const filterOption = (input: string, option: BaseOptionType | undefined): boolean => {
    // if (option?.label?.props?.children.length > 0) {
    //   return option?.label?.props?.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    // }
    if (typeof option?.label === 'string') {
      return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  return (
    <div className="salon__form-select">
      <Form.Item
        name={rest.name}
        label={rest.label}
        {...formItemProps}
        className={`w-100  ${formItemProps?.className ?? ''}`}
      >
        <Select
          allowClear
          suffixIcon={<SvgArrowDownSelect />}
          {...selectProps}
          filterOption={filterOption}
          showSearch={false}
          popupClassName={`salon__form-select-popup-select ${selectProps?.popupClassName}`}
        />
      </Form.Item>
    </div>
  );
};
