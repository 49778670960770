import { Form } from 'antd';
import _ from 'lodash';
import { FC, Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { StepTwoCommonProps, n } from '../index';
import { Employee, Salon, Service, ServiceItems } from '../../../../../apis/client-axios';
import { DATE_FORMAT, formatNumberThousandWithDecimal } from '../../../../../utils';
import { FormSelect } from '../../../../../components/Form/FormSelect';
import { ServiceItem } from '../../../../../components/ServiceItem';
import PickATime, { TimeActive } from '../PickATime';
import { DateObject } from 'react-multi-date-picker';
import dayjs from 'dayjs';
import { Booking } from '../..';

interface PickServicesProps extends StepTwoCommonProps {
  services: Service[];
  statusAnyTechnician?: boolean;
  setStatusAnyTechnician: (statusAnyTechnician: boolean) => void;
  dataSalon?: Salon;
}
export interface Notification {
  availableTime?: string | Date;
  isBusy: boolean;
  technician: Employee;
  service?: ServiceItems;
}

const PickServices: FC<PickServicesProps> = (props) => {
  const {
    services,
    form,
    bookings,
    setBookings,
    setNaturalBookings,
    naturalBookings,
    statusAnyTechnician,
    setStatusAnyTechnician,
    dataSalon,
  } = props;
  const intl = useIntl();
  const categoryId = Form.useWatch(n('categoryId'), form);
  const [openPickATime, setOpenPickATime] = useState<boolean>(false);
  const [serviceItem, setServiceItem] = useState<any>();

  const filterServices = useMemo(() => {
    return services.filter(
      (service) =>
        service.serviceItems &&
        service.serviceItems.length > 0 &&
        service.serviceItems.filter((service) => !service.isMerchandise && service.isCheckinOnline).length > 0
    );
  }, [services]);

  const serviceItems = useMemo(() => {
    let cloneServices = _.cloneDeep(filterServices);

    if (categoryId) {
      cloneServices = cloneServices.filter((service) => service.id === categoryId);
    }

    const findIndexBookingFocused = bookings.findIndex((booking) => booking.isFocus);
    const booking = bookings[findIndexBookingFocused];

    return cloneServices
      .flatMap((service) => service.serviceItems)
      .filter((serviceItem) => {
        if (booking && booking.technician) {
          return (
            serviceItem.isCheckinOnline && booking.technician?.skills?.find((skill) => skill.id === serviceItem.id)
          );
        } else return serviceItem.isCheckinOnline;
      });
  }, [filterServices, categoryId, bookings]);
  const onCreateNewBooking = (cloneBooking: Booking[], serviceItem?: ServiceItems, availableTime?: string) => {
    const findMaxId = _.maxBy(cloneBooking, 'id')?.id;
    const newBookingId = findMaxId ? findMaxId + 1 : 1;

    const newBooking = {
      service: serviceItem,
      isFocus: true,
      isAnyTechnician: false,
      isAppointment: false,
      availableTime: availableTime,
      id: newBookingId,
    };

    cloneBooking.push(newBooking);

    // unfocus others booking
    cloneBooking = cloneBooking.map((booking) => {
      if (booking.id === newBookingId) return booking;
      else return { ...booking, isFocus: false };
    });

    const cloneNatureBooking = _.cloneDeep(naturalBookings);
    cloneNatureBooking.push(newBooking);

    setNaturalBookings(cloneNatureBooking);

    return cloneBooking;
  };

  const handleChooseService = async (
    serviceItem?: ServiceItems,
    timeActive?: TimeActive,
    selectedDate?: DateObject | DateObject[] | null | Date
  ) => {
    let cloneBooking = _.cloneDeep(bookings);
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);
    const formattedDate = selectedDate ? dayjs(selectedDate.toString()).format(DATE_FORMAT) : null;

    const availableTime = `${timeActive?.timeStart}${' '}${formattedDate}`;

    if (findIndexBookingFocused > -1) {
      const bookingFocused = cloneBooking[findIndexBookingFocused];
      if (statusAnyTechnician) {
        console.log('vao 1');
        if (bookingFocused.isAppointment) {
          if (
            !Array.isArray(bookingFocused.appointmentServices) ||
            (Array.isArray(bookingFocused.appointmentServices) && bookingFocused.appointmentServices.length === 0)
          ) {
            bookingFocused.appointmentServices = serviceItem ? [serviceItem] : [];
            bookingFocused.isFocus = false;
          } else {
            cloneBooking = onCreateNewBooking(cloneBooking, serviceItem, availableTime);
          }
        } else {
          if (!bookingFocused.service) {
            bookingFocused.service = serviceItem;
            bookingFocused.availableTime = availableTime;
          } else {
            cloneBooking = onCreateNewBooking(cloneBooking, serviceItem, availableTime);
          }
        }
      } else {
        bookingFocused.availableTime = availableTime;
        bookingFocused.isFocus = true;
        bookingFocused.service = serviceItem;
        setStatusAnyTechnician(true);
        console.log('vao 12');
      }
    } else {
      cloneBooking = onCreateNewBooking(cloneBooking, serviceItem, availableTime);
    }
    setBookings([...cloneBooking]);
    setOpenPickATime(false);
  };
  const handleChooseTechnician = () => {};

  const handleOpenPickATime = async (serviceItem: ServiceItems) => {
    setServiceItem(serviceItem);
    let cloneBooking = _.cloneDeep(bookings);
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);
    const bookingFocused = cloneBooking[findIndexBookingFocused];
    if (!bookingFocused?.service && (bookingFocused?.technician || bookingFocused?.isAnyTechnician)) {
      setOpenPickATime(true);
    } else {
      const newBooking = onCreateNewBooking([...bookings], serviceItem, '');
      setBookings(newBooking);
    }
  };

  return (
    <div className="salon__step-two-left p-r-0">
      <div className="salon__step-two-left-top">
        <span className="salon__step-two-title p-r-30">
          {intl.formatMessage({ id: 'bookingOnline.service' })} ({`${serviceItems.length ?? 0}`})
        </span>
        <div className="salon__step-two-left-wrap m-t-17">
          <>
            <FormSelect
              name={n('categoryId')}
              selectProps={{
                placeholder: intl.formatMessage({ id: 'bookingOnline.step2.allCategory' }),
                options: filterServices.map((item) => ({ label: item.name, value: item.id })),
              }}
              formItemProps={{
                className: 'salon__step-two-custom p-r-30',
              }}
            />
            <div className="salon__step-two-custom-service-list p-r-30">
              {(serviceItems as ServiceItems[]).map((serviceItem) => (
                <Fragment key={serviceItem.id}>
                  <ServiceItem
                    key={serviceItem.id}
                    isMerchandise={false}
                    name={serviceItem.name}
                    price={formatNumberThousandWithDecimal(serviceItem.price)}
                    time={serviceItem.time?.toString()}
                    className={`${
                      bookings
                        .filter((booking) => booking.isFocus)
                        .findIndex((booking) => {
                          if (Array.isArray(booking.appointmentServices) && booking.appointmentServices.length > 0) {
                            return (
                              booking.appointmentServices.findIndex((service) => service.id === serviceItem.id) > -1
                            );
                          }
                          return booking.service?.id === serviceItem.id;
                        }) > -1
                        ? 'active'
                        : ''
                    }`}
                    backgroundColor={serviceItem.backgroundColor}
                    onClick={() => handleOpenPickATime(serviceItem)}
                  />
                </Fragment>
              ))}
            </div>
          </>
        </div>
      </div>

      <PickATime
        open={openPickATime}
        setOpen={() => {
          setOpenPickATime(false);
        }}
        serviceItem={serviceItem}
        handleChooseService={handleChooseService}
        handleChooseTechnician={handleChooseTechnician}
        bookings={bookings}
        statusAnyTechnician={statusAnyTechnician}
        dataSalon={dataSalon}
      />
    </div>
  );
};

export default PickServices;
