export const US_States_and_Cities = [
  {
    state: 'New York',
    city: [
      { name: 'New York', timezone: 'America/New_York' },
      { name: 'Buffalo', timezone: 'America/New_York' },
      { name: 'Rochester', timezone: 'America/New_York' },
      { name: 'Yonkers', timezone: 'America/New_York' },
      { name: 'Syracuse', timezone: 'America/New_York' },
      { name: 'Albany', timezone: 'America/New_York' },
      { name: 'New Rochelle', timezone: 'America/New_York' },
      { name: 'Mount Vernon', timezone: 'America/New_York' },
      { name: 'Schenectady', timezone: 'America/New_York' },
      { name: 'Utica', timezone: 'America/New_York' },
      { name: 'White Plains', timezone: 'America/New_York' },
      { name: 'Hempstead', timezone: 'America/New_York' },
      { name: 'Troy', timezone: 'America/New_York' },
      { name: 'Niagara Falls', timezone: 'America/New_York' },
      { name: 'Binghamton', timezone: 'America/New_York' },
      { name: 'Freeport', timezone: 'America/New_York' },
      { name: 'Valley Stream', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'California',
    city: [
      { name: 'Los Angeles', timezone: 'America/Los_Angeles' },
      { name: 'San Diego', timezone: 'America/Los_Angeles' },
      { name: 'San Jose', timezone: 'America/Los_Angeles' },
      { name: 'San Francisco', timezone: 'America/Los_Angeles' },
      { name: 'Fresno', timezone: 'America/Los_Angeles' },
      { name: 'Sacramento', timezone: 'America/Los_Angeles' },
      { name: 'Long Beach', timezone: 'America/Los_Angeles' },
      { name: 'Oakland', timezone: 'America/Los_Angeles' },
      { name: 'Bakersfield', timezone: 'America/Los_Angeles' },
      { name: 'Anaheim', timezone: 'America/Los_Angeles' },
      { name: 'Santa Ana', timezone: 'America/Los_Angeles' },
      { name: 'Riverside', timezone: 'America/Los_Angeles' },
      { name: 'Stockton', timezone: 'America/Los_Angeles' },
      { name: 'Chula Vista', timezone: 'America/Los_Angeles' },
      { name: 'Irvine', timezone: 'America/Los_Angeles' },
      { name: 'Fremont', timezone: 'America/Los_Angeles' },
      { name: 'San Bernardino', timezone: 'America/Los_Angeles' },
      { name: 'Modesto', timezone: 'America/Los_Angeles' },
      { name: 'Fontana', timezone: 'America/Los_Angeles' },
      { name: 'Oxnard', timezone: 'America/Los_Angeles' },
      { name: 'Moreno Valley', timezone: 'America/Los_Angeles' },
      { name: 'Huntington Beach', timezone: 'America/Los_Angeles' },
      { name: 'Glendale', timezone: 'America/Los_Angeles' },
      { name: 'Santa Clarita', timezone: 'America/Los_Angeles' },
      { name: 'Garden Grove', timezone: 'America/Los_Angeles' },
      { name: 'Oceanside', timezone: 'America/Los_Angeles' },
      { name: 'Rancho Cucamonga', timezone: 'America/Los_Angeles' },
      { name: 'Santa Rosa', timezone: 'America/Los_Angeles' },
      { name: 'Ontario', timezone: 'America/Los_Angeles' },
      { name: 'Lancaster', timezone: 'America/Los_Angeles' },
      { name: 'Elk Grove', timezone: 'America/Los_Angeles' },
      { name: 'Corona', timezone: 'America/Los_Angeles' },
      { name: 'Palmdale', timezone: 'America/Los_Angeles' },
      { name: 'Salinas', timezone: 'America/Los_Angeles' },
      { name: 'Pomona', timezone: 'America/Los_Angeles' },
      { name: 'Hayward', timezone: 'America/Los_Angeles' },
      { name: 'Escondido', timezone: 'America/Los_Angeles' },
      { name: 'Torrance', timezone: 'America/Los_Angeles' },
      { name: 'Sunnyvale', timezone: 'America/Los_Angeles' },
      { name: 'Orange', timezone: 'America/Los_Angeles' },
      { name: 'Fullerton', timezone: 'America/Los_Angeles' },
      { name: 'Pasadena', timezone: 'America/Los_Angeles' },
      { name: 'Thousand Oaks', timezone: 'America/Los_Angeles' },
      { name: 'Visalia', timezone: 'America/Los_Angeles' },
      { name: 'Simi Valley', timezone: 'America/Los_Angeles' },
      { name: 'Concord', timezone: 'America/Los_Angeles' },
      { name: 'Roseville', timezone: 'America/Los_Angeles' },
      { name: 'Victorville', timezone: 'America/Los_Angeles' },
      { name: 'Santa Clara', timezone: 'America/Los_Angeles' },
      { name: 'Vallejo', timezone: 'America/Los_Angeles' },
      { name: 'Berkeley', timezone: 'America/Los_Angeles' },
      { name: 'El Monte', timezone: 'America/Los_Angeles' },
      { name: 'Downey', timezone: 'America/Los_Angeles' },
      { name: 'Costa Mesa', timezone: 'America/Los_Angeles' },
      { name: 'Inglewood', timezone: 'America/Los_Angeles' },
      { name: 'Carlsbad', timezone: 'America/Los_Angeles' },
      { name: 'San Buenaventura', timezone: 'America/Los_Angeles' },
      { name: 'Fairfield', timezone: 'America/Los_Angeles' },
      { name: 'West Covina', timezone: 'America/Los_Angeles' },
      { name: 'Murrieta', timezone: 'America/Los_Angeles' },
      { name: 'Richmond', timezone: 'America/Los_Angeles' },
      { name: 'Norwalk', timezone: 'America/Los_Angeles' },
      { name: 'Antioch', timezone: 'America/Los_Angeles' },
      { name: 'Temecula', timezone: 'America/Los_Angeles' },
      { name: 'Burbank', timezone: 'America/Los_Angeles' },
      { name: 'Daly name', timezone: 'America/Los_Angeles' },
      { name: 'Rialto', timezone: 'America/Los_Angeles' },
      { name: 'Santa Maria', timezone: 'America/Los_Angeles' },
      { name: 'El Cajon', timezone: 'America/Los_Angeles' },
      { name: 'San Mateo', timezone: 'America/Los_Angeles' },
      { name: 'Clovis', timezone: 'America/Los_Angeles' },
      { name: 'Compton', timezone: 'America/Los_Angeles' },
      { name: 'Jurupa Valley', timezone: 'America/Los_Angeles' },
      { name: 'Vista', timezone: 'America/Los_Angeles' },
      { name: 'South Gate', timezone: 'America/Los_Angeles' },
      { name: 'Mission Viejo', timezone: 'America/Los_Angeles' },
      { name: 'Vacaville', timezone: 'America/Los_Angeles' },
      { name: 'Carson', timezone: 'America/Los_Angeles' },
      { name: 'Hesperia', timezone: 'America/Los_Angeles' },
      { name: 'Santa Monica', timezone: 'America/Los_Angeles' },
      { name: 'Westminster', timezone: 'America/Los_Angeles' },
      { name: 'Redding', timezone: 'America/Los_Angeles' },
      { name: 'Santa Barbara', timezone: 'America/Los_Angeles' },
      { name: 'Chico', timezone: 'America/Los_Angeles' },
      { name: 'Newport Beach', timezone: 'America/Los_Angeles' },
      { name: 'San Leandro', timezone: 'America/Los_Angeles' },
      { name: 'San Marcos', timezone: 'America/Los_Angeles' },
      { name: 'Whittier', timezone: 'America/Los_Angeles' },
      { name: 'Hawthorne', timezone: 'America/Los_Angeles' },
      { name: 'Citrus Heights', timezone: 'America/Los_Angeles' },
      { name: 'Tracy', timezone: 'America/Los_Angeles' },
      { name: 'Alhambra', timezone: 'America/Los_Angeles' },
      { name: 'Livermore', timezone: 'America/Los_Angeles' },
      { name: 'Buena Park', timezone: 'America/Los_Angeles' },
      { name: 'Menifee', timezone: 'America/Los_Angeles' },
      { name: 'Hemet', timezone: 'America/Los_Angeles' },
      { name: 'Lakewood', timezone: 'America/Los_Angeles' },
      { name: 'Merced', timezone: 'America/Los_Angeles' },
      { name: 'Chino', timezone: 'America/Los_Angeles' },
      { name: 'Indio', timezone: 'America/Los_Angeles' },
      { name: 'Redwood name', timezone: 'America/Los_Angeles' },
      { name: 'Lake Forest', timezone: 'America/Los_Angeles' },
      { name: 'Napa', timezone: 'America/Los_Angeles' },
      { name: 'Tustin', timezone: 'America/Los_Angeles' },
      { name: 'Bellflower', timezone: 'America/Los_Angeles' },
      { name: 'Mountain View', timezone: 'America/Los_Angeles' },
      { name: 'Chino Hills', timezone: 'America/Los_Angeles' },
      { name: 'Baldwin Park', timezone: 'America/Los_Angeles' },
      { name: 'Alameda', timezone: 'America/Los_Angeles' },
      { name: 'Upland', timezone: 'America/Los_Angeles' },
      { name: 'San Ramon', timezone: 'America/Los_Angeles' },
      { name: 'Folsom', timezone: 'America/Los_Angeles' },
      { name: 'Pleasanton', timezone: 'America/Los_Angeles' },
      { name: 'Union name', timezone: 'America/Los_Angeles' },
      { name: 'Perris', timezone: 'America/Los_Angeles' },
      { name: 'Manteca', timezone: 'America/Los_Angeles' },
      { name: 'Lynwood', timezone: 'America/Los_Angeles' },
      { name: 'Apple Valley', timezone: 'America/Los_Angeles' },
      { name: 'Redlands', timezone: 'America/Los_Angeles' },
      { name: 'Turlock', timezone: 'America/Los_Angeles' },
      { name: 'Milpitas', timezone: 'America/Los_Angeles' },
      { name: 'Redondo Beach', timezone: 'America/Los_Angeles' },
      { name: 'Rancho Cordova', timezone: 'America/Los_Angeles' },
      { name: 'Yorba Linda', timezone: 'America/Los_Angeles' },
      { name: 'Palo Alto', timezone: 'America/Los_Angeles' },
      { name: 'Davis', timezone: 'America/Los_Angeles' },
      { name: 'Camarillo', timezone: 'America/Los_Angeles' },
      { name: 'Walnut Creek', timezone: 'America/Los_Angeles' },
      { name: 'Pittsburg', timezone: 'America/Los_Angeles' },
      { name: 'South San Francisco', timezone: 'America/Los_Angeles' },
      { name: 'Yuba name', timezone: 'America/Los_Angeles' },
      { name: 'San Clemente', timezone: 'America/Los_Angeles' },
      { name: 'Laguna Niguel', timezone: 'America/Los_Angeles' },
      { name: 'Pico Rivera', timezone: 'America/Los_Angeles' },
      { name: 'Montebello', timezone: 'America/Los_Angeles' },
      { name: 'Lodi', timezone: 'America/Los_Angeles' },
      { name: 'Madera', timezone: 'America/Los_Angeles' },
      { name: 'Santa Cruz', timezone: 'America/Los_Angeles' },
      { name: 'La Habra', timezone: 'America/Los_Angeles' },
      { name: 'Encinitas', timezone: 'America/Los_Angeles' },
      { name: 'Monterey Park', timezone: 'America/Los_Angeles' },
      { name: 'Tulare', timezone: 'America/Los_Angeles' },
      { name: 'Cupertino', timezone: 'America/Los_Angeles' },
      { name: 'Gardena', timezone: 'America/Los_Angeles' },
      { name: 'National name', timezone: 'America/Los_Angeles' },
      { name: 'Rocklin', timezone: 'America/Los_Angeles' },
      { name: 'Petaluma', timezone: 'America/Los_Angeles' },
      { name: 'Huntington Park', timezone: 'America/Los_Angeles' },
      { name: 'San Rafael', timezone: 'America/Los_Angeles' },
      { name: 'La Mesa', timezone: 'America/Los_Angeles' },
      { name: 'Arcadia', timezone: 'America/Los_Angeles' },
      { name: 'Fountain Valley', timezone: 'America/Los_Angeles' },
      { name: 'Diamond Bar', timezone: 'America/Los_Angeles' },
      { name: 'Woodland', timezone: 'America/Los_Angeles' },
      { name: 'Santee', timezone: 'America/Los_Angeles' },
      { name: 'Lake Elsinore', timezone: 'America/Los_Angeles' },
      { name: 'Porterville', timezone: 'America/Los_Angeles' },
      { name: 'Paramount', timezone: 'America/Los_Angeles' },
      { name: 'Eastvale', timezone: 'America/Los_Angeles' },
      { name: 'Rosemead', timezone: 'America/Los_Angeles' },
      { name: 'Hanford', timezone: 'America/Los_Angeles' },
      { name: 'Highland', timezone: 'America/Los_Angeles' },
      { name: 'Brentwood', timezone: 'America/Los_Angeles' },
      { name: 'Novato', timezone: 'America/Los_Angeles' },
      { name: 'Colton', timezone: 'America/Los_Angeles' },
      { name: 'Cathedral name', timezone: 'America/Los_Angeles' },
      { name: 'Delano', timezone: 'America/Los_Angeles' },
      { name: 'Yucaipa', timezone: 'America/Los_Angeles' },
      { name: 'Watsonville', timezone: 'America/Los_Angeles' },
      { name: 'Placentia', timezone: 'America/Los_Angeles' },
      { name: 'Glendora', timezone: 'America/Los_Angeles' },
      { name: 'Gilroy', timezone: 'America/Los_Angeles' },
      { name: 'Palm Desert', timezone: 'America/Los_Angeles' },
      { name: 'Cerritos', timezone: 'America/Los_Angeles' },
      { name: 'West Sacramento', timezone: 'America/Los_Angeles' },
      { name: 'Aliso Viejo', timezone: 'America/Los_Angeles' },
      { name: 'Poway', timezone: 'America/Los_Angeles' },
      { name: 'La Mirada', timezone: 'America/Los_Angeles' },
      { name: 'Rancho Santa Margarita', timezone: 'America/Los_Angeles' },
      { name: 'Cypress', timezone: 'America/Los_Angeles' },
      { name: 'Dublin', timezone: 'America/Los_Angeles' },
      { name: 'Covina', timezone: 'America/Los_Angeles' },
      { name: 'Azusa', timezone: 'America/Los_Angeles' },
      { name: 'Palm Springs', timezone: 'America/Los_Angeles' },
      { name: 'San Luis Obispo', timezone: 'America/Los_Angeles' },
      { name: 'Ceres', timezone: 'America/Los_Angeles' },
      { name: 'San Jacinto', timezone: 'America/Los_Angeles' },
      { name: 'Lincoln', timezone: 'America/Los_Angeles' },
      { name: 'Newark', timezone: 'America/Los_Angeles' },
      { name: 'Lompoc', timezone: 'America/Los_Angeles' },
      { name: 'El Centro', timezone: 'America/Los_Angeles' },
      { name: 'Danville', timezone: 'America/Los_Angeles' },
      { name: 'Bell Gardens', timezone: 'America/Los_Angeles' },
      { name: 'Coachella', timezone: 'America/Los_Angeles' },
      { name: 'Rancho Palos Verdes', timezone: 'America/Los_Angeles' },
      { name: 'San Bruno', timezone: 'America/Los_Angeles' },
      { name: 'Rohnert Park', timezone: 'America/Los_Angeles' },
      { name: 'Brea', timezone: 'America/Los_Angeles' },
      { name: 'La Puente', timezone: 'America/Los_Angeles' },
      { name: 'Campbell', timezone: 'America/Los_Angeles' },
      { name: 'San Gabriel', timezone: 'America/Los_Angeles' },
      { name: 'Beaumont', timezone: 'America/Los_Angeles' },
      { name: 'Morgan Hill', timezone: 'America/Los_Angeles' },
      { name: 'Culver name', timezone: 'America/Los_Angeles' },
      { name: 'Calexico', timezone: 'America/Los_Angeles' },
      { name: 'Stanton', timezone: 'America/Los_Angeles' },
      { name: 'La Quinta', timezone: 'America/Los_Angeles' },
      { name: 'Pacifica', timezone: 'America/Los_Angeles' },
      { name: 'Montclair', timezone: 'America/Los_Angeles' },
      { name: 'Oakley', timezone: 'America/Los_Angeles' },
      { name: 'Monrovia', timezone: 'America/Los_Angeles' },
      { name: 'Los Banos', timezone: 'America/Los_Angeles' },
      { name: 'Martinez', timezone: 'America/Los_Angeles' },
    ],
  },
  {
    state: 'Illinois',
    city: [
      { name: 'Chicago', timezone: 'America/Chicago' },
      { name: 'Aurora', timezone: 'America/Chicago' },
      { name: 'Rockford', timezone: 'America/Chicago' },
      { name: 'Joliet', timezone: 'America/Chicago' },
      { name: 'Naperville', timezone: 'America/Chicago' },
      { name: 'Springfield', timezone: 'America/Chicago' },
      { name: 'Peoria', timezone: 'America/Chicago' },
      { name: 'Elgin', timezone: 'America/Chicago' },
      { name: 'Waukegan', timezone: 'America/Chicago' },
      { name: 'Cicero', timezone: 'America/Chicago' },
      { name: 'Champaign', timezone: 'America/Chicago' },
      { name: 'Bloomington', timezone: 'America/Chicago' },
      { name: 'Arlington Heights', timezone: 'America/Chicago' },
      { name: 'Evanston', timezone: 'America/Chicago' },
      { name: 'Decatur', timezone: 'America/Chicago' },
      { name: 'Schaumburg', timezone: 'America/Chicago' },
      { name: 'Bolingbrook', timezone: 'America/Chicago' },
      { name: 'Palatine', timezone: 'America/Chicago' },
      { name: 'Skokie', timezone: 'America/Chicago' },
      { name: 'Des Plaines', timezone: 'America/Chicago' },
      { name: 'Orland Park', timezone: 'America/Chicago' },
      { name: 'Tinley Park', timezone: 'America/Chicago' },
      { name: 'Oak Lawn', timezone: 'America/Chicago' },
      { name: 'Berwyn', timezone: 'America/Chicago' },
      { name: 'Mount Prospect', timezone: 'America/Chicago' },
      { name: 'Normal', timezone: 'America/Chicago' },
      { name: 'Wheaton', timezone: 'America/Chicago' },
      { name: 'Hoffman Estates', timezone: 'America/Chicago' },
      { name: 'Oak Park', timezone: 'America/Chicago' },
      { name: 'Downers Grove', timezone: 'America/Chicago' },
      { name: 'Elmhurst', timezone: 'America/Chicago' },
      { name: 'Glenview', timezone: 'America/Chicago' },
      { name: 'DeKalb', timezone: 'America/Chicago' },
      { name: 'Lombard', timezone: 'America/Chicago' },
      { name: 'Belleville', timezone: 'America/Chicago' },
      { name: 'Moline', timezone: 'America/Chicago' },
      { name: 'Buffalo Grove', timezone: 'America/Chicago' },
      { name: 'Bartlett', timezone: 'America/Chicago' },
      { name: 'Urbana', timezone: 'America/Chicago' },
      { name: 'Quincy', timezone: 'America/Chicago' },
      { name: 'Crystal Lake', timezone: 'America/Chicago' },
      { name: 'Plainfield', timezone: 'America/Chicago' },
      { name: 'Streamwood', timezone: 'America/Chicago' },
      { name: 'Carol Stream', timezone: 'America/Chicago' },
      { name: 'Romeoville', timezone: 'America/Chicago' },
      { name: 'Rock Island', timezone: 'America/Chicago' },
      { name: 'Hanover Park', timezone: 'America/Chicago' },
      { name: 'Carpentersville', timezone: 'America/Chicago' },
      { name: 'Wheeling', timezone: 'America/Chicago' },
      { name: 'Park Ridge', timezone: 'America/Chicago' },
      { name: 'Addison', timezone: 'America/Chicago' },
      { name: 'Calumet name', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Texas',
    city: [
      { name: 'Houston', timezone: 'America/Chicago' },
      { name: 'San Antonio', timezone: 'America/Chicago' },
      { name: 'Dallas', timezone: 'America/Chicago' },
      { name: 'Austin', timezone: 'America/Chicago' },
      { name: 'Fort Worth', timezone: 'America/Chicago' },
      { name: 'El Paso', timezone: 'America/Chicago' },
      { name: 'Arlington', timezone: 'America/Chicago' },
      { name: 'Corpus Christi', timezone: 'America/Chicago' },
      { name: 'Plano', timezone: 'America/Chicago' },
      { name: 'Laredo', timezone: 'America/Chicago' },
      { name: 'Lubbock', timezone: 'America/Chicago' },
      { name: 'Garland', timezone: 'America/Chicago' },
      { name: 'Irving', timezone: 'America/Chicago' },
      { name: 'Amarillo', timezone: 'America/Chicago' },
      { name: 'Grand Prairie', timezone: 'America/Chicago' },
      { name: 'Brownsville', timezone: 'America/Chicago' },
      { name: 'Pasadena', timezone: 'America/Chicago' },
      { name: 'McKinney', timezone: 'America/Chicago' },
      { name: 'Mesquite', timezone: 'America/Chicago' },
      { name: 'McAllen', timezone: 'America/Chicago' },
      { name: 'Killeen', timezone: 'America/Chicago' },
      { name: 'Frisco', timezone: 'America/Chicago' },
      { name: 'Waco', timezone: 'America/Chicago' },
      { name: 'Carrollton', timezone: 'America/Chicago' },
      { name: 'Denton', timezone: 'America/Chicago' },
      { name: 'Midland', timezone: 'America/Chicago' },
      { name: 'Abilene', timezone: 'America/Chicago' },
      { name: 'Beaumont', timezone: 'America/Chicago' },
      { name: 'Round Rock', timezone: 'America/Chicago' },
      { name: 'Odessa', timezone: 'America/Chicago' },
      { name: 'Wichita Falls', timezone: 'America/Chicago' },
      { name: 'Richardson', timezone: 'America/Chicago' },
      { name: 'Lewisville', timezone: 'America/Chicago' },
      { name: 'Tyler', timezone: 'America/Chicago' },
      { name: 'College Station', timezone: 'America/Chicago' },
      { name: 'Pearland', timezone: 'America/Chicago' },
      { name: 'San Angelo', timezone: 'America/Chicago' },
      { name: 'Allen', timezone: 'America/Chicago' },
      { name: 'League name', timezone: 'America/Chicago' },
      { name: 'Sugar Land', timezone: 'America/Chicago' },
      { name: 'Longview', timezone: 'America/Chicago' },
      { name: 'Edinburg', timezone: 'America/Chicago' },
      { name: 'Mission', timezone: 'America/Chicago' },
      { name: 'Bryan', timezone: 'America/Chicago' },
      { name: 'Baytown', timezone: 'America/Chicago' },
      { name: 'Pharr', timezone: 'America/Chicago' },
      { name: 'Temple', timezone: 'America/Chicago' },
      { name: 'Missouri name', timezone: 'America/Chicago' },
      { name: 'Flower Mound', timezone: 'America/Chicago' },
      { name: 'Harlingen', timezone: 'America/Chicago' },
      { name: 'North Richland Hills', timezone: 'America/Chicago' },
      { name: 'Victoria', timezone: 'America/Chicago' },
      { name: 'Conroe', timezone: 'America/Chicago' },
      { name: 'New Braunfels', timezone: 'America/Chicago' },
      { name: 'Mansfield', timezone: 'America/Chicago' },
      { name: 'Cedar Park', timezone: 'America/Chicago' },
      { name: 'Rowlett', timezone: 'America/Chicago' },
      { name: 'Port Arthur', timezone: 'America/Chicago' },
      { name: 'Euless', timezone: 'America/Chicago' },
      { name: 'Georgetown', timezone: 'America/Chicago' },
      { name: 'Pflugerville', timezone: 'America/Chicago' },
      { name: 'DeSoto', timezone: 'America/Chicago' },
      { name: 'San Marcos', timezone: 'America/Chicago' },
      { name: 'Grapevine', timezone: 'America/Chicago' },
      { name: 'Bedford', timezone: 'America/Chicago' },
      { name: 'Galveston', timezone: 'America/Chicago' },
      { name: 'Cedar Hill', timezone: 'America/Chicago' },
      { name: 'Texas name', timezone: 'America/Chicago' },
      { name: 'Wylie', timezone: 'America/Chicago' },
      { name: 'Haltom name', timezone: 'America/Chicago' },
      { name: 'Keller', timezone: 'America/Chicago' },
      { name: 'Coppell', timezone: 'America/Chicago' },
      { name: 'Rockwall', timezone: 'America/Chicago' },
      { name: 'Huntsville', timezone: 'America/Chicago' },
      { name: 'Duncanville', timezone: 'America/Chicago' },
      { name: 'Sherman', timezone: 'America/Chicago' },
      { name: 'The Colony', timezone: 'America/Chicago' },
      { name: 'Burleson', timezone: 'America/Chicago' },
      { name: 'Hurst', timezone: 'America/Chicago' },
      { name: 'Lancaster', timezone: 'America/Chicago' },
      { name: 'Texarkana', timezone: 'America/Chicago' },
      { name: 'Friendswood', timezone: 'America/Chicago' },
      { name: 'Weslaco', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Pennsylvania',
    city: [
      { name: 'Philadelphia', timezone: 'America/New_York' },
      { name: 'Pittsburgh', timezone: 'America/New_York' },
      { name: 'Allentown', timezone: 'America/New_York' },
      { name: 'Erie', timezone: 'America/New_York' },
      { name: 'Reading', timezone: 'America/New_York' },
      { name: 'Scranton', timezone: 'America/New_York' },
      { name: 'Bethlehem', timezone: 'America/New_York' },
      { name: 'Lancaster', timezone: 'America/New_York' },
      { name: 'Harrisburg', timezone: 'America/New_York' },
      { name: 'Altoona', timezone: 'America/New_York' },
      { name: 'York', timezone: 'America/New_York' },
      { name: 'State College', timezone: 'America/New_York' },
      { name: 'Wilkes-Barre', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Arizona',
    city: [
      { name: 'Phoenix', timezone: 'America/Phoenix' },
      { name: 'Tucson', timezone: 'America/Phoenix' },
      { name: 'Mesa', timezone: 'America/Phoenix' },
      { name: 'Chandler', timezone: 'America/Phoenix' },
      { name: 'Glendale', timezone: 'America/Phoenix' },
      { name: 'Scottsdale', timezone: 'America/Phoenix' },
      { name: 'Gilbert', timezone: 'America/Phoenix' },
      { name: 'Tempe', timezone: 'America/Phoenix' },
      { name: 'Peoria', timezone: 'America/Phoenix' },
      { name: 'Surprise', timezone: 'America/Phoenix' },
      { name: 'Yuma', timezone: 'America/Phoenix' },
      { name: 'Avondale', timezone: 'America/Phoenix' },
      { name: 'Goodyear', timezone: 'America/Phoenix' },
      { name: 'Flagstaff', timezone: 'America/Phoenix' },
      { name: 'Buckeye', timezone: 'America/Phoenix' },
      { name: 'Lake Havasu name', timezone: 'America/Phoenix' },
      { name: 'Casa Grande', timezone: 'America/Phoenix' },
      { name: 'Sierra Vista', timezone: 'America/Phoenix' },
      { name: 'Maricopa', timezone: 'America/Phoenix' },
      { name: 'Oro Valley', timezone: 'America/Phoenix' },
      { name: 'Prescott Valley', timezone: 'America/Phoenix' },
      { name: 'Bullhead Name', timezone: 'America/Phoenix' },
      { name: 'Marana', timezone: 'America/Phoenix' },
      { name: 'Apache Junction', timezone: 'America/Phoenix' },
    ],
  },
  {
    state: 'Florida',
    city: [
      { name: 'Jacksonville', timezone: 'America/New_York' },
      { name: 'Miami', timezone: 'America/New_York' },
      { name: 'Tampa', timezone: 'America/New_York' },
      { name: 'Orlando', timezone: 'America/New_York' },
      { name: 'St. Petersburg', timezone: 'America/New_York' },
      { name: 'Hialeah', timezone: 'America/New_York' },
      { name: 'Tallahassee', timezone: 'America/New_York' },
      { name: 'Fort Lauderdale', timezone: 'America/New_York' },
      { name: 'Port St. Lucie', timezone: 'America/New_York' },
      { name: 'Cape Coral', timezone: 'America/New_York' },
      { name: 'Pembroke Pines', timezone: 'America/New_York' },
      { name: 'Hollywood', timezone: 'America/New_York' },
      { name: 'Miramar', timezone: 'America/New_York' },
      { name: 'Gainesville', timezone: 'America/New_York' },
      { name: 'Coral Springs', timezone: 'America/New_York' },
      { name: 'Miami Gardens', timezone: 'America/New_York' },
      { name: 'Clearwater', timezone: 'America/New_York' },
      { name: 'Palm Bay', timezone: 'America/New_York' },
      { name: 'Pompano Beach', timezone: 'America/New_York' },
      { name: 'West Palm Beach', timezone: 'America/New_York' },
      { name: 'Lakeland', timezone: 'America/New_York' },
      { name: 'Davie', timezone: 'America/New_York' },
      { name: 'Miami Beach', timezone: 'America/New_York' },
      { name: 'Sunrise', timezone: 'America/New_York' },
      { name: 'Plantation', timezone: 'America/New_York' },
      { name: 'Boca Raton', timezone: 'America/New_York' },
      { name: 'Deltona', timezone: 'America/New_York' },
      { name: 'Largo', timezone: 'America/New_York' },
      { name: 'Deerfield Beach', timezone: 'America/New_York' },
      { name: 'Palm Coast', timezone: 'America/New_York' },
      { name: 'Melbourne', timezone: 'America/New_York' },
      { name: 'Boynton Beach', timezone: 'America/New_York' },
      { name: 'Lauderhill', timezone: 'America/New_York' },
      { name: 'Weston', timezone: 'America/New_York' },
      { name: 'Fort Myers', timezone: 'America/New_York' },
      { name: 'Kissimmee', timezone: 'America/New_York' },
      { name: 'Homestead', timezone: 'America/New_York' },
      { name: 'Tamarac', timezone: 'America/New_York' },
      { name: 'Delray Beach', timezone: 'America/New_York' },
      { name: 'Daytona Beach', timezone: 'America/New_York' },
      { name: 'North Miami', timezone: 'America/New_York' },
      { name: 'Wellington', timezone: 'America/New_York' },
      { name: 'North Port', timezone: 'America/New_York' },
      { name: 'Jupiter', timezone: 'America/New_York' },
      { name: 'Ocala', timezone: 'America/New_York' },
      { name: 'Port Orange', timezone: 'America/New_York' },
      { name: 'Margate', timezone: 'America/New_York' },
      { name: 'Coconut Creek', timezone: 'America/New_York' },
      { name: 'Sanford', timezone: 'America/New_York' },
      { name: 'Sarasota', timezone: 'America/New_York' },
      { name: 'Pensacola', timezone: 'America/Chicago' },
      { name: 'Bradenton', timezone: 'America/New_York' },
      { name: 'Palm Beach Gardens', timezone: 'America/New_York' },
      { name: 'Pinellas Park', timezone: 'America/New_York' },
      { name: 'Coral Gables', timezone: 'America/New_York' },
      { name: 'Doral', timezone: 'America/New_York' },
      { name: 'Bonita Springs', timezone: 'America/New_York' },
      { name: 'Apopka', timezone: 'America/New_York' },
      { name: 'Titusville', timezone: 'America/New_York' },
      { name: 'North Miami Beach', timezone: 'America/New_York' },
      { name: 'Oakland Park', timezone: 'America/New_York' },
      { name: 'Fort Pierce', timezone: 'America/New_York' },
      { name: 'North Lauderdale', timezone: 'America/New_York' },
      { name: 'Cutler Bay', timezone: 'America/New_York' },
      { name: 'Altamonte Springs', timezone: 'America/New_York' },
      { name: 'St. Cloud', timezone: 'America/New_York' },
      { name: 'Greenacres', timezone: 'America/New_York' },
      { name: 'Ormond Beach', timezone: 'America/New_York' },
      { name: 'Ocoee', timezone: 'America/New_York' },
      { name: 'Hallandale Beach', timezone: 'America/New_York' },
      { name: 'Winter Garden', timezone: 'America/New_York' },
      { name: 'Aventura', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Indiana',
    city: [
      { name: 'Indianapolis', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Fort Wayne', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Evansville', timezone: 'America/Chicago' },
      { name: 'South Bend', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Carmel', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Bloomington', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Fishers', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Hammond', timezone: 'America/Chicago' },
      { name: 'Gary', timezone: 'America/Chicago' },
      { name: 'Muncie', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Lafayette', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Terre Haute', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Kokomo', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Anderson', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Noblesville', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Greenwood', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Elkhart', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Mishawaka', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Lawrence', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Jeffersonville', timezone: 'America/Kentucky/Louisville' },
      { name: 'Columbus', timezone: 'America/Indiana/Indianapolis' },
      { name: 'Portage', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Ohio',
    city: [
      { name: 'Columbus', timezone: 'America/New_York' },
      { name: 'Cleveland', timezone: 'America/New_York' },
      { name: 'Cincinnati', timezone: 'America/New_York' },
      { name: 'Toledo', timezone: 'America/New_York' },
      { name: 'Akron', timezone: 'America/New_York' },
      { name: 'Dayton', timezone: 'America/New_York' },
      { name: 'Parma', timezone: 'America/New_York' },
      { name: 'Canton', timezone: 'America/New_York' },
      { name: 'Youngstown', timezone: 'America/New_York' },
      { name: 'Lorain', timezone: 'America/New_York' },
      { name: 'Hamilton', timezone: 'America/New_York' },
      { name: 'Springfield', timezone: 'America/New_York' },
      { name: 'Kettering', timezone: 'America/New_York' },
      { name: 'Elyria', timezone: 'America/New_York' },
      { name: 'Lakewood', timezone: 'America/New_York' },
      { name: 'Cuyahoga Falls', timezone: 'America/New_York' },
      { name: 'Middletown', timezone: 'America/New_York' },
      { name: 'Euclid', timezone: 'America/New_York' },
      { name: 'Newark', timezone: 'America/New_York' },
      { name: 'Mansfield', timezone: 'America/New_York' },
      { name: 'Mentor', timezone: 'America/New_York' },
      { name: 'Beavercreek', timezone: 'America/New_York' },
      { name: 'Cleveland Heights', timezone: 'America/New_York' },
      { name: 'Strongsville', timezone: 'America/New_York' },
      { name: 'Dublin', timezone: 'America/New_York' },
      { name: 'Fairfield', timezone: 'America/New_York' },
      { name: 'Findlay', timezone: 'America/New_York' },
      { name: 'Warren', timezone: 'America/New_York' },
      { name: 'Lancaster', timezone: 'America/New_York' },
      { name: 'Lima', timezone: 'America/New_York' },
      { name: 'Huber Heights', timezone: 'America/New_York' },
      { name: 'Westerville', timezone: 'America/New_York' },
      { name: 'Marion', timezone: 'America/New_York' },
      { name: 'Grove name', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'North Carolina',
    city: [
      { name: 'Charlotte', timezone: 'America/New_York' },
      { name: 'Raleigh', timezone: 'America/New_York' },
      { name: 'Greensboro', timezone: 'America/New_York' },
      { name: 'Durham', timezone: 'America/New_York' },
      { name: 'Winston-Salem', timezone: 'America/New_York' },
      { name: 'Fayetteville', timezone: 'America/New_York' },
      { name: 'Cary', timezone: 'America/New_York' },
      { name: 'Wilmington', timezone: 'America/New_York' },
      { name: 'High Point', timezone: 'America/New_York' },
      { name: 'Greenville', timezone: 'America/New_York' },
      { name: 'Asheville', timezone: 'America/New_York' },
      { name: 'Concord', timezone: 'America/New_York' },
      { name: 'Gastonia', timezone: 'America/New_York' },
      { name: 'Jacksonville', timezone: 'America/New_York' },
      { name: 'Chapel Hill', timezone: 'America/New_York' },
      { name: 'Rocky Mount', timezone: 'America/New_York' },
      { name: 'Burlington', timezone: 'America/New_York' },
      { name: 'Wilson', timezone: 'America/New_York' },
      { name: 'Huntersville', timezone: 'America/New_York' },
      { name: 'Kannapolis', timezone: 'America/New_York' },
      { name: 'Apex', timezone: 'America/New_York' },
      { name: 'Hickory', timezone: 'America/New_York' },
      { name: 'Goldsboro', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Michigan',
    city: [
      { name: 'Detroit', timezone: 'America/Detroit' },
      { name: 'Grand Rapids', timezone: 'America/Detroit' },
      { name: 'Warren', timezone: 'America/Detroit' },
      { name: 'Sterling Heights', timezone: 'America/Detroit' },
      { name: 'Ann Arbor', timezone: 'America/Detroit' },
      { name: 'Lansing', timezone: 'America/Detroit' },
      { name: 'Flint', timezone: 'America/Detroit' },
      { name: 'Dearborn', timezone: 'America/Detroit' },
      { name: 'Livonia', timezone: 'America/Detroit' },
      { name: 'Westland', timezone: 'America/Detroit' },
      { name: 'Troy', timezone: 'America/Detroit' },
      { name: 'Farmington Hills', timezone: 'America/Detroit' },
      { name: 'Kalamazoo', timezone: 'America/Detroit' },
      { name: 'Wyoming', timezone: 'America/Detroit' },
      { name: 'Southfield', timezone: 'America/Detroit' },
      { name: 'Rochester Hills', timezone: 'America/Detroit' },
      { name: 'Taylor', timezone: 'America/Detroit' },
      { name: 'Pontiac', timezone: 'America/Detroit' },
      { name: 'St. Clair Shores', timezone: 'America/Detroit' },
      { name: 'Royal Oak', timezone: 'America/Detroit' },
      { name: 'Novi', timezone: 'America/Detroit' },
      { name: 'Dearborn Heights', timezone: 'America/Detroit' },
      { name: 'Battle Creek', timezone: 'America/Detroit' },
      { name: 'Saginaw', timezone: 'America/Detroit' },
      { name: 'Kentwood', timezone: 'America/Detroit' },
      { name: 'East Lansing', timezone: 'America/Detroit' },
      { name: 'Roseville', timezone: 'America/Detroit' },
      { name: 'Portage', timezone: 'America/Detroit' },
      { name: 'Midland', timezone: 'America/Detroit' },
      { name: 'Lincoln Park', timezone: 'America/Detroit' },
      { name: 'Muskegon', timezone: 'America/Detroit' },
    ],
  },
  {
    state: 'Tennessee',
    city: [
      { name: 'Memphis', timezone: 'America/Chicago' },
      { name: 'Nashville', timezone: 'America/Chicago' },
      { name: 'Knoxville', timezone: 'America/New_York' },
      { name: 'Chattanooga', timezone: 'America/New_York' },
      { name: 'Clarksville', timezone: 'America/Chicago' },
      { name: 'Murfreesboro', timezone: 'America/Chicago' },
      { name: 'Jackson', timezone: 'America/Chicago' },
      { name: 'Franklin', timezone: 'America/Chicago' },
      { name: 'Johnson name', timezone: 'America/New_York' },
      { name: 'Bartlett', timezone: 'America/Chicago' },
      { name: 'Hendersonville', timezone: 'America/Chicago' },
      { name: 'Kingsport', timezone: 'America/New_York' },
      { name: 'Collierville', timezone: 'America/Chicago' },
      { name: 'Cleveland', timezone: 'America/New_York' },
      { name: 'Smyrna', timezone: 'America/Chicago' },
      { name: 'Germantown', timezone: 'America/Chicago' },
      { name: 'Brentwood', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Massachusetts',
    city: [
      { name: 'Boston', timezone: 'America/New_York' },
      { name: 'Worcester', timezone: 'America/New_York' },
      { name: 'Springfield', timezone: 'America/New_York' },
      { name: 'Lowell', timezone: 'America/New_York' },
      { name: 'Cambridge', timezone: 'America/New_York' },
      { name: 'New Bedford', timezone: 'America/New_York' },
      { name: 'Brockton', timezone: 'America/New_York' },
      { name: 'Quincy', timezone: 'America/New_York' },
      { name: 'Lynn', timezone: 'America/New_York' },
      { name: 'Fall River', timezone: 'America/New_York' },
      { name: 'Newton', timezone: 'America/New_York' },
      { name: 'Lawrence', timezone: 'America/New_York' },
      { name: 'Somerville', timezone: 'America/New_York' },
      { name: 'Waltham', timezone: 'America/New_York' },
      { name: 'Haverhill', timezone: 'America/New_York' },
      { name: 'Malden', timezone: 'America/New_York' },
      { name: 'Medford', timezone: 'America/New_York' },
      { name: 'Taunton', timezone: 'America/New_York' },
      { name: 'Chicopee', timezone: 'America/New_York' },
      { name: 'Weymouth Town', timezone: 'America/New_York' },
      { name: 'Revere', timezone: 'America/New_York' },
      { name: 'Peabody', timezone: 'America/New_York' },
      { name: 'Methuen', timezone: 'America/New_York' },
      { name: 'Barnstable Town', timezone: 'America/New_York' },
      { name: 'Pittsfield', timezone: 'America/New_York' },
      { name: 'Attleboro', timezone: 'America/New_York' },
      { name: 'Everett', timezone: 'America/New_York' },
      { name: 'Salem', timezone: 'America/New_York' },
      { name: 'Westfield', timezone: 'America/New_York' },
      { name: 'Leominster', timezone: 'America/New_York' },
      { name: 'Fitchburg', timezone: 'America/New_York' },
      { name: 'Beverly', timezone: 'America/New_York' },
      { name: 'Holyoke', timezone: 'America/New_York' },
      { name: 'Marlborough', timezone: 'America/New_York' },
      { name: 'Woburn', timezone: 'America/New_York' },
      { name: 'Chelsea', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Washington',
    city: [
      { name: 'Seattle', timezone: 'America/Los_Angeles' },
      { name: 'Spokane', timezone: 'America/Los_Angeles' },
      { name: 'Tacoma', timezone: 'America/Los_Angeles' },
      { name: 'Vancouver', timezone: 'America/Los_Angeles' },
      { name: 'Bellevue', timezone: 'America/Los_Angeles' },
      { name: 'Kent', timezone: 'America/Los_Angeles' },
      { name: 'Everett', timezone: 'America/Los_Angeles' },
      { name: 'Renton', timezone: 'America/Los_Angeles' },
      { name: 'Yakima', timezone: 'America/Los_Angeles' },
      { name: 'Federal Way', timezone: 'America/Los_Angeles' },
      { name: 'Spokane Valley', timezone: 'America/Los_Angeles' },
      { name: 'Bellingham', timezone: 'America/Los_Angeles' },
      { name: 'Kennewick', timezone: 'America/Los_Angeles' },
      { name: 'Auburn', timezone: 'America/Los_Angeles' },
      { name: 'Pasco', timezone: 'America/Los_Angeles' },
      { name: 'Marysville', timezone: 'America/Los_Angeles' },
      { name: 'Lakewood', timezone: 'America/Los_Angeles' },
      { name: 'Redmond', timezone: 'America/Los_Angeles' },
      { name: 'Shoreline', timezone: 'America/Los_Angeles' },
      { name: 'Richland', timezone: 'America/Los_Angeles' },
      { name: 'Kirkland', timezone: 'America/Los_Angeles' },
      { name: 'Burien', timezone: 'America/Los_Angeles' },
      { name: 'Sammamish', timezone: 'America/Los_Angeles' },
      { name: 'Olympia', timezone: 'America/Los_Angeles' },
      { name: 'Lacey', timezone: 'America/Los_Angeles' },
      { name: 'Edmonds', timezone: 'America/Los_Angeles' },
      { name: 'Bremerton', timezone: 'America/Los_Angeles' },
      { name: 'Puyallup', timezone: 'America/Los_Angeles' },
    ],
  },
  {
    state: 'Colorado',
    city: [
      { name: 'Denver', timezone: 'America/Denver' },
      { name: 'Colorado Springs', timezone: 'America/Denver' },
      { name: 'Aurora', timezone: 'America/Denver' },
      { name: 'Fort Collins', timezone: 'America/Denver' },
      { name: 'Lakewood', timezone: 'America/Denver' },
      { name: 'Thornton', timezone: 'America/Denver' },
      { name: 'Arvada', timezone: 'America/Denver' },
      { name: 'Westminster', timezone: 'America/Denver' },
      { name: 'Pueblo', timezone: 'America/Denver' },
      { name: 'Centennial', timezone: 'America/Denver' },
      { name: 'Boulder', timezone: 'America/Denver' },
      { name: 'Greeley', timezone: 'America/Denver' },
      { name: 'Longmont', timezone: 'America/Denver' },
      { name: 'Loveland', timezone: 'America/Denver' },
      { name: 'Grand Junction', timezone: 'America/Denver' },
      { name: 'Broomfield', timezone: 'America/Denver' },
      { name: 'Castle Rock', timezone: 'America/Denver' },
      { name: 'Commerce name', timezone: 'America/Denver' },
      { name: 'Parker', timezone: 'America/Denver' },
      { name: 'Littleton', timezone: 'America/Denver' },
      { name: 'Northglenn', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'District of Columbia',
    city: [{ name: 'Washington', timezone: 'America/New_York' }],
  },
  {
    state: 'Maryland',
    city: [
      { name: 'Baltimore', timezone: 'America/New_York' },
      { name: 'Frederick', timezone: 'America/New_York' },
      { name: 'Rockville', timezone: 'America/New_York' },
      { name: 'Gaithersburg', timezone: 'America/New_York' },
      { name: 'Bowie', timezone: 'America/New_York' },
      { name: 'Hagerstown', timezone: 'America/New_York' },
      { name: 'Annapolis', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Kentucky',
    city: [
      { name: 'Louisville/Jefferson County', timezone: 'America/New York' },
      { name: 'Lexington-Fayette', timezone: 'America/New_York' },
      { name: 'Bowling Green', timezone: 'America/Chicago' },
      { name: 'Owensboro', timezone: 'America/Chicago' },
      { name: 'Covington', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Oregon',
    city: [
      { name: 'Portland', timezone: 'America/Los_Angeles' },
      { name: 'Eugene', timezone: 'America/Los_Angeles' },
      { name: 'Salem', timezone: 'America/Los_Angeles' },
      { name: 'Gresham', timezone: 'America/Los_Angeles' },
      { name: 'Hillsboro', timezone: 'America/Los_Angeles' },
      { name: 'Beaverton', timezone: 'America/Los_Angeles' },
      { name: 'Bend', timezone: 'America/Los_Angeles' },
      { name: 'Medford', timezone: 'America/Los_Angeles' },
      { name: 'Springfield', timezone: 'America/Los_Angeles' },
      { name: 'Corvallis', timezone: 'America/Los_Angeles' },
      { name: 'Albany', timezone: 'America/Los_Angeles' },
      { name: 'Tigard', timezone: 'America/Los_Angeles' },
      { name: 'Lake Oswego', timezone: 'America/Los_Angeles' },
      { name: 'Keizer', timezone: 'America/Los_Angeles' },
    ],
  },
  {
    state: 'Oklahoma',
    city: [
      { name: 'Oklahoma name', timezone: 'America/Chicago' },
      { name: 'Tulsa', timezone: 'America/Chicago' },
      { name: 'Norman', timezone: 'America/Chicago' },
      { name: 'Broken Arrow', timezone: 'America/Chicago' },
      { name: 'Lawton', timezone: 'America/Chicago' },
      { name: 'Edmond', timezone: 'America/Chicago' },
      { name: 'Moore', timezone: 'America/Chicago' },
      { name: 'Midwest name', timezone: 'America/Chicago' },
      { name: 'Enid', timezone: 'America/Chicago' },
      { name: 'Stillwater', timezone: 'America/Chicago' },
      { name: 'Muskogee', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Wisconsin',
    city: [
      { name: 'Milwaukee', timezone: 'America/Chicago' },
      { name: 'Madison', timezone: 'America/Chicago' },
      { name: 'Green Bay', timezone: 'America/Chicago' },
      { name: 'Kenosha', timezone: 'America/Chicago' },
      { name: 'Racine', timezone: 'America/Chicago' },
      { name: 'Appleton', timezone: 'America/Chicago' },
      { name: 'Waukesha', timezone: 'America/Chicago' },
      { name: 'Eau Claire', timezone: 'America/Chicago' },
      { name: 'Oshkosh', timezone: 'America/Chicago' },
      { name: 'Janesville', timezone: 'America/Chicago' },
      { name: 'West Allis', timezone: 'America/Chicago' },
      { name: 'La Crosse', timezone: 'America/Chicago' },
      { name: 'Sheboygan', timezone: 'America/Chicago' },
      { name: 'Wauwatosa', timezone: 'America/Chicago' },
      { name: 'Fond du Lac', timezone: 'America/Chicago' },
      { name: 'New Berlin', timezone: 'America/Chicago' },
      { name: 'Wausau', timezone: 'America/Chicago' },
      { name: 'Brookfield', timezone: 'America/Chicago' },
      { name: 'Greenfield', timezone: 'America/Chicago' },
      { name: 'Beloit', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Nevada',
    city: [
      { name: 'Las Vegas', timezone: 'America/Los_Angeles' },
      { name: 'Henderson', timezone: 'America/Los_Angeles' },
      { name: 'Reno', timezone: 'America/Los_Angeles' },
      { name: 'North Las Vegas', timezone: 'America/Los_Angeles' },
      { name: 'Sparks', timezone: 'America/Los_Angeles' },
      { name: 'Carson name', timezone: 'America/Los_Angeles' },
    ],
  },
  {
    state: 'New Mexico',
    city: [
      { name: 'Albuquerque', timezone: 'America/Denver' },
      { name: 'Las Cruces', timezone: 'America/Denver' },
      { name: 'Rio Rancho', timezone: 'America/Denver' },
      { name: 'Santa Fe', timezone: 'America/Denver' },
      { name: 'Roswell', timezone: 'America/Denver' },
      { name: 'Farmington', timezone: 'America/Denver' },
      { name: 'Clovis', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'Missouri',
    city: [
      { name: 'Kansas name', timezone: 'America/Chicago' },
      { name: 'St. Louis', timezone: 'America/Chicago' },
      { name: 'Springfield', timezone: 'America/Chicago' },
      { name: 'Independence', timezone: 'America/Chicago' },
      { name: 'Columbia', timezone: 'America/Chicago' },
      { name: "Lee's Summit", timezone: 'America/Chicago' },
      { name: "O'Fallon", timezone: 'America/Chicago' },
      { name: 'St. Joseph', timezone: 'America/Chicago' },
      { name: 'St. Charles', timezone: 'America/Chicago' },
      { name: 'St. Peters', timezone: 'America/Chicago' },
      { name: 'Blue Springs', timezone: 'America/Chicago' },
      { name: 'Florissant', timezone: 'America/Chicago' },
      { name: 'Joplin', timezone: 'America/Chicago' },
      { name: 'Chesterfield', timezone: 'America/Chicago' },
      { name: 'Jefferson name', timezone: 'America/Chicago' },
      { name: 'Cape Girardeau', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Virginia',
    city: [
      { name: 'Virginia Beach', timezone: 'America/New_York' },
      { name: 'Norfolk', timezone: 'America/New_York' },
      { name: 'Chesapeake', timezone: 'America/New_York' },
      { name: 'Richmond', timezone: 'America/New_York' },
      { name: 'Newport News', timezone: 'America/New_York' },
      { name: 'Alexandria', timezone: 'America/New_York' },
      { name: 'Hampton', timezone: 'America/New_York' },
      { name: 'Roanoke', timezone: 'America/New_York' },
      { name: 'Portsmouth', timezone: 'America/New_York' },
      { name: 'Suffolk', timezone: 'America/New_York' },
      { name: 'Lynchburg', timezone: 'America/New_York' },
      { name: 'Harrisonburg', timezone: 'America/New_York' },
      { name: 'Leesburg', timezone: 'America/New_York' },
      { name: 'Charlottesville', timezone: 'America/New_York' },
      { name: 'Danville', timezone: 'America/New_York' },
      { name: 'Blacksburg', timezone: 'America/New_York' },
      { name: 'Manassas', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Georgia',
    city: [
      { name: 'Atlanta', timezone: 'America/New_York' },
      { name: 'Columbus', timezone: 'America/New_York' },
      { name: 'Augusta-Richmond County', timezone: 'America/New_York' },
      { name: 'Savannah', timezone: 'America/New_York' },
      { name: 'Athens', timezone: 'America/New_York' },
      { name: 'Sandy Springs', timezone: 'America/New_York' },
      { name: 'Roswell', timezone: 'America/New_York' },
      { name: 'Macon', timezone: 'America/New_York' },
      { name: 'Johns Creek', timezone: 'America/New_York' },
      { name: 'Albany', timezone: 'America/New_York' },
      { name: 'Warner Robins', timezone: 'America/New_York' },
      { name: 'Alpharetta', timezone: 'America/New_York' },
      { name: 'Marietta', timezone: 'America/New_York' },
      { name: 'Valdosta', timezone: 'America/New_York' },
      { name: 'Smyrna', timezone: 'America/New_York' },
      { name: 'Dunwoody', timezone: 'America/New_York' },
      { name: 'Tyrone', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Nebraska',
    city: [
      { name: 'Omaha', timezone: 'America/Chicago' },
      { name: 'Lincoln', timezone: 'America/Chicago' },
      { name: 'Bellevue', timezone: 'America/Chicago' },
      { name: 'Grand Island', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Minnesota',
    city: [
      { name: 'Minneapolis', timezone: 'America/Chicago' },
      { name: 'St. Paul', timezone: 'America/Chicago' },
      { name: 'Rochester', timezone: 'America/Chicago' },
      { name: 'Duluth', timezone: 'America/Chicago' },
      { name: 'Bloomington', timezone: 'America/Chicago' },
      { name: 'Brooklyn Park', timezone: 'America/Chicago' },
      { name: 'Plymouth', timezone: 'America/Chicago' },
      { name: 'St. Cloud', timezone: 'America/Chicago' },
      { name: 'Eagan', timezone: 'America/Chicago' },
      { name: 'Woodbury', timezone: 'America/Chicago' },
      { name: 'Maple Grove', timezone: 'America/Chicago' },
      { name: 'Eden Prairie', timezone: 'America/Chicago' },
      { name: 'Coon Rapids', timezone: 'America/Chicago' },
      { name: 'Burnsville', timezone: 'America/Chicago' },
      { name: 'Blaine', timezone: 'America/Chicago' },
      { name: 'Lakeville', timezone: 'America/Chicago' },
      { name: 'Minnetonka', timezone: 'America/Chicago' },
      { name: 'Apple Valley', timezone: 'America/Chicago' },
      { name: 'Edina', timezone: 'America/Chicago' },
      { name: 'St. Louis Park', timezone: 'America/Chicago' },
      { name: 'Mankato', timezone: 'America/Chicago' },
      { name: 'Maplewood', timezone: 'America/Chicago' },
      { name: 'Moorhead', timezone: 'America/Chicago' },
      { name: 'Shakopee', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Kansas',
    city: [
      { name: 'Wichita', timezone: 'America/Chicago' },
      { name: 'Overland Park', timezone: 'America/Chicago' },
      { name: 'Kansas name', timezone: 'America/Chicago' },
      { name: 'Olathe', timezone: 'America/Chicago' },
      { name: 'Topeka', timezone: 'America/Chicago' },
      { name: 'Lawrence', timezone: 'America/Chicago' },
      { name: 'Shawnee', timezone: 'America/Chicago' },
      { name: 'Manhattan', timezone: 'America/Chicago' },
      { name: 'Lenexa', timezone: 'America/Chicago' },
      { name: 'Salina', timezone: 'America/Chicago' },
      { name: 'Hutchinson', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Louisiana',
    city: [
      { name: 'New Orleans', timezone: 'America/Chicago' },
      { name: 'Baton Rouge', timezone: 'America/Chicago' },
      { name: 'Shreveport', timezone: 'America/Chicago' },
      { name: 'Lafayette', timezone: 'America/Chicago' },
      { name: 'Lake Charles', timezone: 'America/Chicago' },
      { name: 'Kenner', timezone: 'America/Chicago' },
      { name: 'Bossier name', timezone: 'America/Chicago' },
      { name: 'Monroe', timezone: 'America/Chicago' },
      { name: 'Alexandria', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Hawaii',
    city: [{ name: 'Honolulu', timezone: 'Pacific/Honolulu' }],
  },
  {
    state: 'Alaska',
    city: [{ name: 'Anchorage', timezone: 'America/Anchorage' }],
  },
  {
    state: 'New Jersey',
    city: [
      { name: 'Newark', timezone: 'America/New_York' },
      { name: 'Jersey name', timezone: 'America/New_York' },
      { name: 'Paterson', timezone: 'America/New_York' },
      { name: 'Elizabeth', timezone: 'America/New_York' },
      { name: 'Clifton', timezone: 'America/New_York' },
      { name: 'Trenton', timezone: 'America/New_York' },
      { name: 'Camden', timezone: 'America/New_York' },
      { name: 'Passaic', timezone: 'America/New_York' },
      { name: 'Union name', timezone: 'America/New_York' },
      { name: 'Bayonne', timezone: 'America/New_York' },
      { name: 'East Orange', timezone: 'America/New_York' },
      { name: 'Vineland', timezone: 'America/New_York' },
      { name: 'New Brunswick', timezone: 'America/New_York' },
      { name: 'Hoboken', timezone: 'America/New_York' },
      { name: 'Perth Amboy', timezone: 'America/New_York' },
      { name: 'West New York', timezone: 'America/New_York' },
      { name: 'Plainfield', timezone: 'America/New_York' },
      { name: 'Hackensack', timezone: 'America/New_York' },
      { name: 'Sayreville', timezone: 'America/New_York' },
      { name: 'Kearny', timezone: 'America/New_York' },
      { name: 'Linden', timezone: 'America/New_York' },
      { name: 'Atlantic name', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Idaho',
    city: [
      { name: 'Boise', timezone: 'America/Boise' },
      { name: 'Nampa', timezone: 'America/Boise' },
      { name: 'Meridian', timezone: 'America/Boise' },
      { name: 'Idaho Falls', timezone: 'America/Boise' },
      { name: 'Pocatello', timezone: 'America/Boise' },
      { name: 'Caldwell', timezone: 'America/Boise' },
      { name: "Coeur d'Alene", timezone: 'America/Los_Angeles' },
      { name: 'Twin Falls', timezone: 'America/Boise' },
    ],
  },
  {
    state: 'Alabama',
    city: [
      { name: 'Birmingham', timezone: 'America/Chicago' },
      { name: 'Montgomery', timezone: 'America/Chicago' },
      { name: 'Mobile', timezone: 'America/Chicago' },
      { name: 'Huntsville', timezone: 'America/Chicago' },
      { name: 'Tuscaloosa', timezone: 'America/Chicago' },
      { name: 'Hoover', timezone: 'America/Chicago' },
      { name: 'Dothan', timezone: 'America/Chicago' },
      { name: 'Auburn', timezone: 'America/Chicago' },
      { name: 'Decatur', timezone: 'America/Chicago' },
      { name: 'Madison', timezone: 'America/Chicago' },
      { name: 'Florence', timezone: 'America/Chicago' },
      { name: 'Gadsden', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Iowa',
    city: [
      { name: 'Des Moines', timezone: 'America/Chicago' },
      { name: 'Cedar Rapids', timezone: 'America/Chicago' },
      { name: 'Davenport', timezone: 'America/Chicago' },
      { name: 'Sioux name', timezone: 'America/Chicago' },
      { name: 'Iowa name', timezone: 'America/Chicago' },
      { name: 'Waterloo', timezone: 'America/Chicago' },
      { name: 'Council Bluffs', timezone: 'America/Chicago' },
      { name: 'Ames', timezone: 'America/Chicago' },
      { name: 'West Des Moines', timezone: 'America/Chicago' },
      { name: 'Dubuque', timezone: 'America/Chicago' },
      { name: 'Ankeny', timezone: 'America/Chicago' },
      { name: 'Urbandale', timezone: 'America/Chicago' },
      { name: 'Cedar Falls', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Arkansas',
    city: [
      { name: 'Little Rock', timezone: 'America/Chicago' },
      { name: 'Fort Smith', timezone: 'America/Chicago' },
      { name: 'Fayetteville', timezone: 'America/Chicago' },
      { name: 'Springdale', timezone: 'America/Chicago' },
      { name: 'Jonesboro', timezone: 'America/Chicago' },
      { name: 'North Little Rock', timezone: 'America/Chicago' },
      { name: 'Conway', timezone: 'America/Chicago' },
      { name: 'Rogers', timezone: 'America/Chicago' },
      { name: 'Pine Bluff', timezone: 'America/Chicago' },
      { name: 'Bentonville', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Utah',
    city: [
      { name: 'Salt Lake name', timezone: 'America/Denver' },
      { name: 'West Valley name', timezone: 'America/Denver' },
      { name: 'Provo', timezone: 'America/Denver' },
      { name: 'West Jordan', timezone: 'America/Denver' },
      { name: 'Orem', timezone: 'America/Denver' },
      { name: 'Sandy', timezone: 'America/Denver' },
      { name: 'Ogden', timezone: 'America/Denver' },
      { name: 'St. George', timezone: 'America/Denver' },
      { name: 'Layton', timezone: 'America/Denver' },
      { name: 'Taylorsville', timezone: 'America/Denver' },
      { name: 'South Jordan', timezone: 'America/Denver' },
      { name: 'Lehi', timezone: 'America/Denver' },
      { name: 'Logan', timezone: 'America/Denver' },
      { name: 'Murray', timezone: 'America/Denver' },
      { name: 'Draper', timezone: 'America/Denver' },
      { name: 'Bountiful', timezone: 'America/Denver' },
      { name: 'Riverton', timezone: 'America/Denver' },
      { name: 'Roy', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'Rhode Island',
    city: [
      { name: 'Providence', timezone: 'America/New_York' },
      { name: 'Warwick', timezone: 'America/New_York' },
      { name: 'Cranston', timezone: 'America/New_York' },
      { name: 'Pawtucket', timezone: 'America/New_York' },
      { name: 'East Providence', timezone: 'America/New_York' },
      { name: 'Woonsocket', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Mississippi',
    city: [
      { name: 'Jackson', timezone: 'America/Chicago' },
      { name: 'Gulfport', timezone: 'America/Chicago' },
      { name: 'Southaven', timezone: 'America/Chicago' },
      { name: 'Hattiesburg', timezone: 'America/Chicago' },
      { name: 'Biloxi', timezone: 'America/Chicago' },
      { name: 'Meridian', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'South Dakota',
    city: [
      { name: 'Sioux Falls', timezone: 'America/Chicago' },
      { name: 'Rapid name', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'Connecticut',
    city: [
      { name: 'Bridgeport', timezone: 'America/New_York' },
      { name: 'New Haven', timezone: 'America/New_York' },
      { name: 'Stamford', timezone: 'America/New_York' },
      { name: 'Hartford', timezone: 'America/New_York' },
      { name: 'Waterbury', timezone: 'America/New_York' },
      { name: 'Norwalk', timezone: 'America/New_York' },
      { name: 'Danbury', timezone: 'America/New_York' },
      { name: 'New Britain', timezone: 'America/New_York' },
      { name: 'Meriden', timezone: 'America/New_York' },
      { name: 'Bristol', timezone: 'America/New_York' },
      { name: 'West Haven', timezone: 'America/New_York' },
      { name: 'Milford', timezone: 'America/New_York' },
      { name: 'Middletown', timezone: 'America/New_York' },
      { name: 'Norwich', timezone: 'America/New_York' },
      { name: 'Shelton', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'South Carolina',
    city: [
      { name: 'Columbia', timezone: 'America/New_York' },
      { name: 'Charleston', timezone: 'America/New_York' },
      { name: 'North Charleston', timezone: 'America/New_York' },
      { name: 'Mount Pleasant', timezone: 'America/New_York' },
      { name: 'Rock Hill', timezone: 'America/New_York' },
      { name: 'Greenville', timezone: 'America/New_York' },
      { name: 'Summerville', timezone: 'America/New_York' },
      { name: 'Sumter', timezone: 'America/New_York' },
      { name: 'Goose Creek', timezone: 'America/New_York' },
      { name: 'Hilton Head Island', timezone: 'America/New_York' },
      { name: 'Florence', timezone: 'America/New_York' },
      { name: 'Spartanburg', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'New Hampshire',
    city: [
      { name: 'Manchester', timezone: 'America/New_York' },
      { name: 'Nashua', timezone: 'America/New_York' },
      { name: 'Concord', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'North Dakota',
    city: [
      { name: 'Fargo', timezone: 'America/Chicago' },
      { name: 'Bismarck', timezone: 'America/Chicago' },
      { name: 'Grand Forks', timezone: 'America/Chicago' },
      { name: 'Minot', timezone: 'America/Chicago' },
    ],
  },
  {
    state: 'Montana',
    city: [
      { name: 'Billings', timezone: 'America/Denver' },
      { name: 'Missoula', timezone: 'America/Denver' },
      { name: 'Great Falls', timezone: 'America/Denver' },
      { name: 'Bozeman', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'Delaware',
    city: [
      { name: 'Wilmington', timezone: 'America/New_York' },
      { name: 'Dover', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Maine',
    city: [{ name: 'Portland', timezone: 'America/New_York' }],
  },
  {
    state: 'Wyoming',
    city: [
      { name: 'Cheyenne', timezone: 'America/Denver' },
      { name: 'Casper', timezone: 'America/Denver' },
    ],
  },
  {
    state: 'West Virginia',
    city: [
      { name: 'Charleston', timezone: 'America/New_York' },
      { name: 'Huntington', timezone: 'America/New_York' },
    ],
  },
  {
    state: 'Vermont',
    city: [{ name: 'Burlington', timezone: 'America/New_York' }],
  },
];
