import { useMutation, useQuery } from '@tanstack/react-query';
import { Checkbox, Divider, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { salonApi } from '../../../../../../apis';
import {
  AppointmentSettingDTO,
  DisplaySettingsDTO,
  LineUpTechniciansByDTOOrderEnum,
  LineUpTechniciansByDTOValueEnum,
  SuggestNextTechniciansByDTOOrderEnum,
  SuggestNextTechniciansByDTOValueEnum,
  TurnsSettingDTO,
} from '../../../../../../apis/client-axios/api';
import { SvgAppointmentTimePickerIcon } from '../../../../../../components/@svg/SvgAppointmentTimePickerIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormButtonSubmit } from '../../../../../../components/Form/FormButtonSubmit';
import { FormCheckbox } from '../../../../../../components/Form/FormCheckbox';
import { FormSelect } from '../../../../../../components/Form/FormSelect';
import FormWrap from '../../../../../../components/FormWrap';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import { useAppDispatch } from '../../../../../../store';
import { updateSalon } from '../../../../../../store/authSlice';
import { QUERY_SALONS_DETAIL_SETTING_APPOINTMENT } from '../../../../../../utils/constant';
import '../style.scss';
import { useDisplaySettingOptions } from './useDisplaySettingOptions';

interface DisplaySettingsModalProps {
  open: boolean;
  onCancel: () => void;
  onRefetch: () => void;
}

export interface IFormData {
  appointment: Array<keyof AppointmentSettingDTO>;
  appointmentGroup: boolean;
  turns: Array<keyof TurnsSettingDTO>;
  turnsGroup: boolean;
  suggestNextTechnicians: boolean;
  lineUpTechniciansByValue: LineUpTechniciansByDTOValueEnum;
  lineUpTechniciansByOrder: LineUpTechniciansByDTOOrderEnum;
  suggestNextTechnicianByValue: SuggestNextTechniciansByDTOValueEnum;
  suggestNextTechnicianByOrder: SuggestNextTechniciansByDTOOrderEnum;
}

const FormItem = Form.Item;

const n = (key: keyof IFormData | keyof AppointmentSettingDTO | keyof TurnsSettingDTO) => key;

const DisplaySettingsModal: FC<DisplaySettingsModalProps> = ({ open, onCancel, onRefetch }) => {
  const intl = useIntl();
  const [form] = Form.useForm<IFormData>();
  const dispatch = useAppDispatch();

  const {
    appointmentOptions,
    turnsOptions,
    suggestOrderOptions,
    lineUpOrderOptions,
    suggestValueOptions,
    lineUpValueOptions,
  } = useDisplaySettingOptions();

  const { data: salonDetail, refetch: onRefetchSalonDetail } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL_SETTING_APPOINTMENT],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(),
    enabled: !!localStorage.getItem('salonId'),
  });

  const [isFieldsChange, setIsFieldsChange] = useState<boolean>(false);

  useEffect(() => {
    const displaySetting = salonDetail?.data?.displaySetting;

    if (!displaySetting) {
      form.setFieldsValue({
        [n('appointmentGroup')]: true,
        [n('appointment')]: appointmentOptions.map((option) => option.value),
        [n('turnsGroup')]: true,
        [n('turns')]: turnsOptions.map((option) => option.value),
      });
    } else {
      form.setFieldsValue({
        [n('appointmentGroup')]:
          appointmentOptions.length === Object.values(displaySetting.appointment).filter((item) => item).length,
        [n('appointment')]: Object.keys(displaySetting.appointment).filter(
          (key) => displaySetting.appointment[key as keyof AppointmentSettingDTO]
        ),
        [n('turnsGroup')]: turnsOptions.length === Object.values(displaySetting.turns).filter((item) => item).length,
        [n('turns')]: Object.keys(displaySetting.turns).filter(
          (key) => displaySetting.turns[key as keyof TurnsSettingDTO]
        ),
        [n('suggestNextTechnicians')]: !!displaySetting.suggestNextTechnicians,
        [n('lineUpTechniciansByValue')]: displaySetting.lineUpTechniciansBy?.value,
        [n('lineUpTechniciansByOrder')]: displaySetting.lineUpTechniciansBy?.order,
        [n('suggestNextTechnicianByValue')]: displaySetting.suggestNextTechnicianBy?.value,
        [n('suggestNextTechnicianByOrder')]: displaySetting.suggestNextTechnicianBy?.order,
      });
    }
  }, [salonDetail]);

  const saveSalonSettingMutation = useMutation(
    (payload: DisplaySettingsDTO) => salonApi.salonControllerDisplaySetting(payload),
    {
      onSuccess: () => {
        onRefetch();
        onRefetchSalonDetail();

        if (salonDetail) {
          dispatch(updateSalon(salonDetail.data));
        }
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
      onSettled: () => {
        setIsFieldsChange(false);
        onCancel();
      },
    }
  );

  const onFinish = (formValues: IFormData) => {
    const appointment = appointmentOptions
      .map((option) => option.value)
      .reduce<AppointmentSettingDTO | Object>((prev: AppointmentSettingDTO | Object, curr) => {
        const findIndex = formValues.appointment?.findIndex((option) => option === curr);

        (prev as AppointmentSettingDTO)[curr as keyof AppointmentSettingDTO] = findIndex > -1;

        return prev;
      }, {}) as AppointmentSettingDTO;

    const turns = turnsOptions
      .map((option) => option.value)
      .reduce<TurnsSettingDTO | Object>((prev: TurnsSettingDTO | Object, curr) => {
        const findIndex = formValues.turns?.findIndex((option) => option === curr);

        (prev as TurnsSettingDTO)[curr as keyof TurnsSettingDTO] = findIndex > -1;

        return prev;
      }, {}) as TurnsSettingDTO;

    const payload = {
      appointment,
      turns,
      suggestNextTechnicians: formValues.suggestNextTechnicians,
      suggestNextTechnicianBy: {
        value: formValues.suggestNextTechnicianByValue,
        order: formValues.suggestNextTechnicianByOrder,
      },
      lineUpTechniciansBy: {
        value: formValues.lineUpTechniciansByValue,
        order: formValues.lineUpTechniciansByOrder,
      },
    };

    saveSalonSettingMutation.mutate(payload);
  };

  const handleReset = () => {
    const displaySetting = salonDetail?.data?.displaySetting;

    if (displaySetting) {
      setIsFieldsChange(false);

      form.setFieldsValue({
        [n('appointmentGroup')]:
          appointmentOptions.length === Object.values(displaySetting.appointment).filter((item) => item).length,
        [n('appointment')]: Object.keys(displaySetting.appointment).filter(
          (key) => displaySetting.appointment[key as keyof AppointmentSettingDTO]
        ),
        [n('turnsGroup')]: turnsOptions.length === Object.values(displaySetting.turns).filter((item) => item).length,
        [n('turns')]: Object.keys(displaySetting.turns).filter(
          (key) => displaySetting.turns[key as keyof TurnsSettingDTO]
        ),
        [n('suggestNextTechnicians')]: !!displaySetting.suggestNextTechnicians,
        [n('lineUpTechniciansByValue')]: displaySetting.lineUpTechniciansBy?.value,
        [n('lineUpTechniciansByOrder')]: displaySetting.lineUpTechniciansBy?.order,
        [n('suggestNextTechnicianByValue')]: displaySetting.suggestNextTechnicianBy?.value,
        [n('suggestNextTechnicianByOrder')]: displaySetting.suggestNextTechnicianBy?.order,
      });
    }
  };

  const handleChangeCheckBoxAll = (e: CheckboxChangeEvent, key: 'appointmentGroup' | 'turnsGroup') => {
    const checked = e.target.checked;

    if (key === n('appointmentGroup')) {
      form.setFieldsValue({
        [n('appointment')]: !checked ? [] : appointmentOptions.map((option) => option.value),
      });
    } else if (key === n('turnsGroup')) {
      form.setFieldsValue({
        [n('turns')]: !checked ? [] : turnsOptions.map((option) => option.value),
      });
    }
  };

  const handleChangeCheckBoxGroup = (checkedValue: CheckboxValueType[], key: 'appointment' | 'turns') => {
    if (key === n('appointment')) {
      form.setFieldsValue({
        [n('appointmentGroup')]: checkedValue.length === appointmentOptions.length,
      });
    } else if (key === n('turns')) {
      form.setFieldsValue({
        [n('turnsGroup')]: checkedValue.length === turnsOptions.length,
      });
    }
  };

  const onFieldsChange = () => {
    if (!isFieldsChange) {
      setIsFieldsChange(true);
    }
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancel}
      modalProps={{
        footer: null,
        title: (
          <span className="font-size-20 font-weight-600 salon__appointment-setting-heading">
            {intl.formatMessage({ id: 'appointment.setting.heading' }, { title: 'Display' })}
          </span>
        ),
        rootClassName: 'salon__appointment-setting',
        width: 500,
        centered: true,
      }}
    >
      <FormWrap
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        name="displaySettings"
        className="salon__appointment-setting-form"
      >
        <FormCheckbox
          name={n('appointmentGroup')}
          content={
            <span className="font-size-16 font-weight-600">{intl.formatMessage({ id: 'appointment.heading' })}</span>
          }
          formItemProps={{
            className: 'm-b-10',
          }}
          checkboxProps={{
            onChange: (e) => handleChangeCheckBoxAll(e, 'appointmentGroup'),
          }}
        />

        <FormItem className="p-l-24 m-b-10 salon__appointment-setting-form-group" name={n('appointment')}>
          <Checkbox.Group
            options={appointmentOptions}
            onChange={(checkedValue) => handleChangeCheckBoxGroup(checkedValue, 'appointment')}
          />
        </FormItem>

        <FormCheckbox
          name={n('turnsGroup')}
          content={
            <span className="font-size-16 font-weight-600">
              {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.turns' })}
            </span>
          }
          formItemProps={{
            className: 'm-b-10',
          }}
          checkboxProps={{
            onChange: (e) => handleChangeCheckBoxAll(e, 'turnsGroup'),
          }}
        />

        <FormItem className="p-l-24 m-b-10 salon__appointment-setting-form-group" name={n('turns')}>
          <Checkbox.Group
            options={turnsOptions}
            onChange={(checkedValue) => handleChangeCheckBoxGroup(checkedValue, 'turns')}
          />
        </FormItem>

        {/* <FormCheckbox
          name={n('suggestNextTechnicians')}
          content={
            <span className="font-size-16 font-weight-400">
              {intl.formatMessage({ id: 'appointment.setting.suggest.checkbox' })}
            </span>
          }
          formItemProps={{
            className: 'm-b-23',
          }}
        /> */}

        {/* <RowWrap isGutter={false} isAutoFillRow={false} isWrap>
          <ColWrap
            colProps={{
              span: 24,
              className: 'font-size-14 font-weight-400 m-b-11 color-78797A',
            }}
          >
            {intl.formatMessage({ id: 'appointment.setting.suggest.title' })}
          </ColWrap>

          <ColWrap
            colProps={{
              span: 24,
            }}
          >
            <RowWrap isGutter gutter={10} isAutoFillRow={false} isWrap>
              <ColWrap
                colProps={{
                  span: 14,
                  className: 'salon__appointment-setting-form-select',
                }}
              >
                <FormSelect
                  name={n('suggestNextTechnicianByValue')}
                  formItemProps={{
                    className: 'm-b-19',
                  }}
                  selectProps={{
                    options: suggestValueOptions,
                    suffixIcon: <SvgAppointmentTimePickerIcon />,
                    allowClear: false,
                  }}
                />
              </ColWrap>

              <ColWrap
                colProps={{
                  span: 10,
                  className: 'salon__appointment-setting-form-select salon__appointment-setting-form-select-order',
                }}
              >
                <FormSelect
                  name={n('suggestNextTechnicianByOrder')}
                  formItemProps={{
                    className: 'm-b-19',
                  }}
                  selectProps={{
                    options: suggestOrderOptions,
                    suffixIcon: <SvgAppointmentTimePickerIcon />,
                    allowClear: false,
                  }}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>

        <RowWrap isGutter={false} isAutoFillRow={false} isWrap>
          <ColWrap
            colProps={{
              span: 24,
              className: 'font-size-14 font-weight-400 m-b-11 color-78797A',
            }}
          >
            {intl.formatMessage({ id: 'appointment.setting.lineUp.title' })}
          </ColWrap>

          <ColWrap
            colProps={{
              span: 24,
            }}
          >
            <RowWrap isGutter gutter={10} isAutoFillRow={false} isWrap>
              <ColWrap
                colProps={{
                  span: 14,
                  className: 'salon__appointment-setting-form-select',
                }}
              >
                <FormSelect
                  name={n('lineUpTechniciansByValue')}
                  formItemProps={{
                    className: 'm-b-28',
                  }}
                  selectProps={{
                    options: lineUpValueOptions,
                    suffixIcon: <SvgAppointmentTimePickerIcon />,
                    allowClear: false,
                  }}
                />
              </ColWrap>

              <ColWrap
                colProps={{
                  span: 10,
                  className: 'salon__appointment-setting-form-select salon__appointment-setting-form-select-order',
                }}
              >
                <FormSelect
                  name={n('lineUpTechniciansByOrder')}
                  formItemProps={{
                    className: 'm-b-28',
                  }}
                  selectProps={{
                    options: lineUpOrderOptions,
                    suffixIcon: <SvgAppointmentTimePickerIcon />,
                    allowClear: false,
                  }}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap> */}
      </FormWrap>

      <Divider className="salon__appointment-setting-divider" />

      <RowWrap
        isGutter
        gutter={12}
        isAutoFillRow
        isWrap
        styleFill="end"
        rowClassName="salon__appointment-setting-form-button"
      >
        <ColWrap>
          <CustomButton
            type="cancel"
            content={
              <span className="font-size-16 font-weight-700">
                {intl.formatMessage({ id: 'appointment.modal.form.button.reset' })}
              </span>
            }
            buttonProps={{
              className: 'salon__appointment-setting-form-button-common',
              disabled: !isFieldsChange || saveSalonSettingMutation.isLoading,
              onClick: handleReset,
            }}
          />
        </ColWrap>

        <ColWrap>
          <FormButtonSubmit
            content={
              <span className="font-size-16 font-weight-700">
                {intl.formatMessage({ id: 'appointment.modal.form.button.submit' })}
              </span>
            }
            buttonProps={{
              onClick: () => form.submit(),
              className: 'salon__appointment-setting-form-button-common',
              loading: saveSalonSettingMutation.isLoading,
            }}
          />
        </ColWrap>
      </RowWrap>
    </StyledModal>
  );
};

export default DisplaySettingsModal;
