import React from 'react';

export const SvgBookMarkIconSmall = () => (
  <span>
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H22.382C23.1253 0 23.6088 0.782313 23.2764 1.44721L19.4472 9.10557C19.1657 9.66863 19.1657 10.3314 19.4472 10.8944L23.2764 18.5528C23.6088 19.2177 23.1253 20 22.382 20H0V0Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);
