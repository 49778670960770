import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CardNumberEnum } from '..';
import { stripeApi } from '../../../../../../../apis';
import {
  CreateChargeDto,
  SalonSetting,
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../../components/FormWrap';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../../../components/RowWrap';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import {
  convertFormatNumberToNumber,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../../../../../utils';
import { IHandleSubmitCheckoutProps, IListIdTotalPrice, ITipInformation, PaymentInformation } from '../../../../index';
import KeyboardWrapper, { KeyboardDirection } from '../../../../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface IProps {
  setCurrentScreen: React.Dispatch<React.SetStateAction<CardNumberEnum>>;
  setOpenModal: (open: boolean) => void;
  setIsOpenPayAndComplete: (open: boolean) => void;
  listTotal: IListIdTotalPrice;
  keyInCardInformation?: CreateChargeDto;
  onSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  paymentInformation?: PaymentInformation;
  onChangePaymentInformation: Dispatch<SetStateAction<PaymentInformation>>;
  tipInformation?: ITipInformation;
  saveTipAndTechnician: (totalTip: number) => void;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  settings?: SalonSetting;
}

interface FormData {
  customerCard?: number;
  customerTipLeft?: number;
}

enum InputNameEnum {
  CustomerCard = 'customerCard',
  CustomerTipLeft = 'customerTipLeft',
}

const n = (key: keyof FormData) => key;

export const CardPaymentCardNumber: React.FC<IProps> = (props) => {
  const {
    setOpenModal,
    listTotal,
    keyInCardInformation,
    onSubmitCheckout,
    paymentInformation,
    onChangePaymentInformation,
    tipInformation,
    saveTipAndTechnician,
    setListTotal,
    settings,
  } = props;

  const intl = useIntl();

  const [form] = Form.useForm();
  const customerCard = Form.useWatch(n('customerCard'), form);
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);

  const customerCardRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);

  const createChargeMutation = useMutation(
    (payload: CreateChargeDto) => stripeApi.stripeControllerCreateCharge(payload),
    {
      onSuccess: ({ data }: { data: any }) => {
        const convertCustomerCard = handleTruncateToTwoDecimal(convertFormatNumberToNumber(customerCard || 0));
        const convertCustomerTipLeft = convertFormatNumberToNumber(customerTipLeft || 0);
        const balanceToPay = handleTruncateToTwoDecimal(listTotal.totalBalanceTotalPay);

        let totalTip = Number(tipInformation?.tip ?? 0) + convertCustomerTipLeft;

        if (convertCustomerCard > balanceToPay) {
          totalTip += convertCustomerCard - balanceToPay;
        }

        const ticketTransactions = [...(paymentInformation?.ticketTransaction ?? [])];

        const ticketTransaction: TicketTransactionDTO = {
          money: convertFormatNumberToNumber(customerCard || 0),
          typePayment: TicketTransactionDTOTypePaymentEnum.CardNumber,
          status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
          tipMoney: convertCustomerTipLeft,
          paymentFee: settings?.settingCheckout?.feeChargedPerPaymentPercentage
            ? Number(settings?.settingCheckout?.feeChargedPerPaymentPercentage)
            : undefined,
          customerTransactionId: data?.customerTransaction?.id,
        };

        ticketTransactions.push(ticketTransaction);

        onChangePaymentInformation((prev) => ({
          ...prev,
          ticketTransaction: ticketTransactions,
          keyInCardPayment: {
            amount: Number(paymentInformation?.keyInCardPayment?.amount || 0) + convertCustomerCard,
            tip: Number(paymentInformation?.keyInCardPayment?.tip || 0) + convertCustomerTipLeft,
          },
        }));

        if (convertCustomerCard >= balanceToPay) {
          onSubmitCheckout({
            balanceToPay: convertFormatNumberToNumber(customerCard || 0),
            totalTip: handleTruncateToTwoDecimal(totalTip),
            ticketTransaction: ticketTransactions,
            status: TicketDTOStatusEnum.Completed,
          });
        }

        saveTipAndTechnician(handleTruncateToTwoDecimal(totalTip));
        setListTotal({
          ...listTotal,
          totalCash: convertCustomerCard + listTotal.totalCash,
        });

        setOpenModal(false);
      },
      onError: ({ response }) => {
        setIsPaymentFailed(true);

        if (response?.data?.message === "The salon hasn't had Stripe Account yet!") {
          NotificationError({ contentNoti: response?.data?.message });
        } else {
          NotificationError({ contentNoti: intl.formatMessage({ id: 'common.paymentFailed' }) });
        }
      },
    }
  );

  useEffect(() => {
    if (customerCard && customerCardRef.current) {
      const [integer] = customerCard.replace(/,/g, '').split('.');
      customerCardRef.current.selectionStart = customerCardRef.current.selectionEnd =
        customerCard.length + Math.floor(integer / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(integer / 3);
    }
  }, [customerCard, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    if (!keyInCardInformation) return;

    const convertCustomerCard = convertFormatNumberToNumber(value.customerCard || 0);
    const convertCustomerTipLeft = convertFormatNumberToNumber(value.customerTipLeft || 0);

    const feeCheckout = Number(settings?.settingCheckout?.feeChargedPerPaymentPercentage || 0);

    //customer card + fee checkout * (customer card + customer tip left) + customer tip left
    const amount =
      convertCustomerCard +
      (feeCheckout * (convertCustomerCard + convertCustomerTipLeft)) / 100 +
      convertCustomerTipLeft;

    createChargeMutation.mutate({
      ...keyInCardInformation,
      amount: Number(handleTruncateToTwoDecimal(amount)),
      cardToken: !isPaymentFailed ? keyInCardInformation.cardToken : undefined,
    });
  };

  const onClickQuickNumber = (value: number) => {
    form.setFieldValue(n('customerCard'), formatNumberThousandWithDecimal(value));
  };
  const onClickNoTip = (value: number) => {
    form.setFieldValue(n('customerTipLeft'), formatNumberThousandWithDecimal(value));
  };

  useEffect(() => {
    customerCardRef.current?.focus();
  }, [customerCard]);

  useEffect(() => {
    form.setFieldsValue({
      [n('customerCard')]: formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0),
    });
  }, []);
  return (
    <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <p className="font-size-16 font-weight-500 m-0">
            {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
            <span className="font-weight-700">
              ${formatNumberThousandWithDecimal(listTotal.totalBalanceTotalPay || 0)}
            </span>
          </p>
        </ColWrap>
        <ColWrap>
          <FormInputNumber
            name={n('customerCard')}
            prefix={<SvgDollarIcon />}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.cardPayment.customerCard.label' })}
            numericFormatProps={{
              placeholder: '0.00',
              className: 'm-b-0',
              inputMode: 'none',
              onFocus: () => setFocusedInputName(InputNameEnum.CustomerCard),
              getInputRef: customerCardRef,
              onKeyDown: (event) => {
                const value = event?.key;
                if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                if (value === 'Enter') {
                  form.submit();
                }
                if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                return event.preventDefault();
              },
            }}
            formItemProps={{
              className: 'm-b-0 width-378',
              rules: [{ required: true, message: '' }],
            }}
          />
        </ColWrap>
        <ColWrap>
          <FormInputNumber
            name={n('customerTipLeft')}
            prefix={<SvgDollarIcon />}
            label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
            numericFormatProps={{
              placeholder: '0.00',
              className: 'm-b-0',
              inputMode: 'none',
              onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
              disabled: !customerCard || customerCard === '0.00',
              getInputRef: customerTipLeftRef,
              onKeyDown: (event) => {
                const value = event?.key;
                if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                if (value === 'Enter') {
                  form.submit();
                }
                if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                return event.preventDefault();
              },
            }}
            formItemProps={{
              className: 'm-b-0 width-353',
            }}
          />
        </ColWrap>
        <ColWrap colProps={{ span: 24 }}>
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
            {/* no tip */}
            {/* <ColWrap colProps={{ span: 24 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className:
                    'height-76 w-full border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                  onClick: () => onClickQuickNumber(listTotal?.totalBalanceTotalPay || 0),
                }}
                content={`$${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}`}
              />
            </ColWrap> */}
            {/* no tip */}
            <ColWrap colProps={{ span: 4 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'h-full w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                  onClick: () => onClickNoTip(0),
                }}
                content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
              />
            </ColWrap>
            {/* numeric keyboard */}
            <ColWrap colProps={{ span: 16 }}>
              <KeyboardWrapper
                inputName={focusedInputName}
                form={form}
                keyboardRef={keyboardRef}
                maxLength={9}
                keyboardProps={{
                  inputPattern: /^\d+$/,
                }}
                direction={KeyboardDirection.rtl}
                disabledKeyboard={createChargeMutation.isLoading}
              />
            </ColWrap>

            {/* ok button */}
            <ColWrap colProps={{ span: 4 }}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                  htmlType: 'submit',
                  disabled: createChargeMutation.isLoading,
                }}
                content={intl.formatMessage({ id: 'common.OK' })}
              />
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>
    </FormWrap>
  );
};
