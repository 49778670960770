import { ReactNode } from 'react';
import './searchError.scss';
import SvgSearchError from '../@svg/SvgSearchError';

export interface ISearchErrorProps {
  content: string | ReactNode;
}

function SearchError(props: ISearchErrorProps) {
  const { content } = props;
  return (
    <div className="salon__search-error">
      <SvgSearchError />
      <span className="salon__search-error-content">{content}</span>
    </div>
  );
}
export default SearchError;
