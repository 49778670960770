import { ForwardedRef, forwardRef, useMemo } from 'react';
import Barcode from 'react-barcode';
import { useIntl } from 'react-intl';
import { CHECKOUT_FULL_TIME_FORMAT, formatDateTimeZoneByFormatString } from '../../../utils';
import { PrintCustomerCheckInProps } from '../@types/checkIn';
import HeaderBill from '../HeaderBill';
import '../bill.scss';

const DEFAULT_BARCODE_VALUE = '000000';

const PrintCustomerCheckin = (props: PrintCustomerCheckInProps, ref: ForwardedRef<HTMLDivElement | null>) => {
  const {
    id,
    orderNumber,
    customerName,
    salonName,
    customerPhoneNumber,
    salonAddress,
    salonHotline,
    wifiName,
    wifiPassword,
    isHideCustomerPhoneNumber,
    appointment,
  } = props;

  const intl = useIntl();

  const barcodeValue = useMemo(() => {
    const orderNumberLength = orderNumber.toString().length;

    return `${DEFAULT_BARCODE_VALUE.slice(0, -orderNumberLength)}${orderNumber}`;
  }, [orderNumber]);

  return (
    <div ref={ref} id={id} className="salon__print-bill-container">
      <HeaderBill
        salon={{
          name: salonName,
          address: salonAddress,
          phoneNumber: salonHotline,
        }}
        customer={{
          name: customerName,
          phoneNumber: customerPhoneNumber,
          isHideCustomerPhoneNumber,
        }}
      />

      <p className="salon__print-bill-checkin">{intl.formatMessage({ id: 'print.bill.checkin' })}</p>
      <p className="salon__print-bill-checkin-number">{orderNumber}</p>

      <div className="m-t-b-8">
        <p className="salon__print-bill-checkin-content text-center">
          {formatDateTimeZoneByFormatString(CHECKOUT_FULL_TIME_FORMAT, appointment?.timeStart)}
        </p>
      </div>

      <div className="m-t-8 m-b-16 width-full">
        <div className="d-flex justify-content-between">
          <p className="salon__print-bill-checkin-content">
            {intl.formatMessage({ id: 'print.bill.wifi' }, { name: wifiName || '' })}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="salon__print-bill-checkin-content">
            {intl.formatMessage({ id: 'print.bill.passWifi' }, { password: wifiPassword || '' })}
          </p>
        </div>
      </div>

      <div className="width-full d-flex justify-content-center align-items-center">
        <Barcode value={barcodeValue} height={140} displayValue={false} margin={0} format="CODE128" width={5} />
      </div>

      <p className="salon__print-bill-checkin-thankyou">{intl.formatMessage({ id: 'print.bill.thankyou' })}</p>
    </div>
  );
};

export default forwardRef<HTMLDivElement | null, PrintCustomerCheckInProps>(PrintCustomerCheckin);
