import { useMutation } from '@tanstack/react-query';
import { Avatar, Card } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { appointmentApi } from '../../../../../../apis';
import { CalculateAvailableTimeDto, Employee } from '../../../../../../apis/client-axios';
import avatarDefaultSrc from '../../../../../../assets/images/appointment/avatar-default.png';
import { FormCheckbox } from '../../../../../../components/Form/FormCheckbox';
import NotificationError from '../../../../../../components/HandleShowNotiError';
import { StyledPopup } from '../../../../../../components/StyledPopup';
import {
  DATE_FORMAT_FULL_DATE_3,
  TIME_FORMAT_AM_PM,
  formatDateByFormatString,
  toDayjsByTimeZoneServer,
  toDayjsTimeZone,
} from '../../../../../../utils';
import { Booking } from '../../index';
import { Notification, StepTwoCommonProps, n } from '../index';

dayjs.extend(isSameOrBefore);

interface PickTechniciansProps extends StepTwoCommonProps {
  technicians: Employee[];
}

const PickTechnicians: FC<PickTechniciansProps> = (props) => {
  const { settingCheckin, technicians, form, bookings, setBookings } = props;

  const intl = useIntl();

  const [notification, setNotification] = useState<Notification>();

  const filterTechnicians = useMemo(() => {
    const findIndexBookingFocused = bookings.findIndex((booking) => booking.isFocus);
    const booking = bookings[findIndexBookingFocused];

    return technicians.filter((technician) => {
      if (booking && booking.service)
        return technician.skills.findIndex((skill) => skill.id === booking.service?.id) > -1;
      else return technician;
    });
  }, [bookings, technicians]);

  const calculateAvailableTimeMutation = useMutation((payload: CalculateAvailableTimeDto) =>
    appointmentApi.appointmentControllerCalculateAvailableTime({ ...payload, estimate: payload.estimate ?? 15 })
  );

  useEffect(() => {
    let isAnyTechnician = true;

    if (bookings.length > 0) {
      isAnyTechnician = !!bookings.find((booking) => booking.isFocus)?.isAnyTechnician;
    }

    form.setFieldsValue({
      [n('anyTechnician')]: isAnyTechnician,
    });
  }, [bookings]);

  const handleChooseTechnician = async (technician: Employee) => {
    const cloneBooking = [...bookings];
    const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

    let technicianBooking: Booking | undefined = undefined;
    const filterBookingByTechnician = cloneBooking.filter(
      (booking) => booking.technician && booking?.technician?.id === technician?.id
    );

    if (filterBookingByTechnician.length > 0) {
      technicianBooking = _.maxBy(filterBookingByTechnician, 'availableTime');
    }

    let estimateTime = 15;

    if (technicianBooking?.service) {
      estimateTime = technicianBooking?.service?.time;
    } else if (technicianBooking?.appointmentServices) {
      estimateTime = technicianBooking?.appointmentServices.reduce(
        (total, service) => total + Number(service?.time || 0),
        0
      );
    }

    const technicianTimeBusy = toDayjsByTimeZoneServer(
      technicianBooking?.availableTime ?? new Date(),
      DATE_FORMAT_FULL_DATE_3
    ).add(estimateTime, 'minute');

    if (findIndexBookingFocused > -1) {
      const booking = cloneBooking[findIndexBookingFocused];

      if (!booking.technician || (booking.technician && booking?.technician?.id !== technician.id)) {
        try {
          const response = await calculateAvailableTimeMutation.mutateAsync({
            technicianId: technician.id,
            estimate: Number(booking.service?.time ?? 15),
            technicianTimeBusy: technicianBooking ? technicianTimeBusy.toISOString() : undefined,
          });

          const data = response.data;

          if (data && data?.isBusy) {
            setNotification({ isBusy: data.isBusy, technician, availableTime: data.availableTime });
          } else {
            booking.isAnyTechnician = false;
            booking.technician = technician;
            booking.availableTime = data.availableTime || toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3);
          }
        } catch (error: any) {
          NotificationError({ contentNoti: error?.response?.data?.message });
        }
      } else if (booking.technician && booking?.technician?.id === technician.id) {
        booking.technician = undefined;
        booking.isAnyTechnician = true;
        booking.availableTime = toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3);
      } else {
        try {
          const response = await calculateAvailableTimeMutation.mutateAsync({
            technicianId: technician.id,
            estimate: Number(booking.service?.time ?? 15),
            technicianTimeBusy: technicianBooking ? technicianTimeBusy.toISOString() : undefined,
          });

          const data = response.data;

          if (data && data?.isBusy) {
            setNotification({ isBusy: data.isBusy, technician, availableTime: data.availableTime });
          } else {
            const findMaxId = _.maxBy(cloneBooking, 'id')?.id;

            cloneBooking.push({
              isFocus: true,
              isAnyTechnician: false,
              isAppointment: false,
              technician,
              availableTime: data?.availableTime || toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3),
              id: findMaxId ? findMaxId + 1 : 1,
            });
          }
        } catch (error: any) {
          NotificationError({ contentNoti: error?.response?.data?.message });
        }
      }
    } else {
      try {
        const response = await calculateAvailableTimeMutation.mutateAsync({
          technicianId: technician.id,
          estimate: 15,
          technicianTimeBusy: technicianBooking ? technicianTimeBusy.toISOString() : undefined,
        });

        const data = response.data;

        if (data && data?.isBusy) {
          setNotification({ isBusy: data.isBusy, technician, availableTime: data.availableTime });
        } else {
          const findMaxId = _.maxBy(cloneBooking, 'id')?.id;

          cloneBooking.push({
            isFocus: true,
            isAnyTechnician: false,
            isAppointment: false,
            technician,
            availableTime: data?.availableTime || toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3),
            id: findMaxId ? findMaxId + 1 : 1,
          });
        }
      } catch (error: any) {
        NotificationError({ contentNoti: error?.response?.data?.message });
      }
    }

    setBookings(cloneBooking);
  };

  return (
    <>
      {settingCheckin?.allowSelectingTechnician && (
        <div
          className={`salon__step-two-left-bottom ${
            !settingCheckin?.allowSelectingServices && settingCheckin?.allowSelectingTechnician ? 'max-width-unset' : ''
          }`}
        >
          <span className="salon__step-two-title">
            {intl.formatMessage({ id: 'bookingOnline.technician' })} ({`${filterTechnicians?.length ?? 0}`})
          </span>
          <FormCheckbox
            name={n('anyTechnician')}
            content={
              <span className="salon__step-two-left-bottom-checkbox-text">
                {intl.formatMessage({ id: 'bookingOnline.checkbox' })}
              </span>
            }
            formItemProps={{
              className: 'salon__step-two-custom salon__step-two-custom-select',
            }}
            checkboxProps={{
              className: 'salon__step-two-custom-checkbox',
              onChange: (e: CheckboxChangeEvent) => {
                const checked = e.target.checked;
                const cloneBooking = [...bookings];
                const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

                if (findIndexBookingFocused > -1) {
                  const booking = cloneBooking[findIndexBookingFocused];
                  booking.isAnyTechnician = !!checked;
                  booking.availableTime = toDayjsTimeZone().format(DATE_FORMAT_FULL_DATE_3);

                  setBookings(cloneBooking);
                }
              },
            }}
          />
          <div className="salon__step-two-left-bottom-wrap">
            <div className="salon__step-two-left-bottom-card">
              {filterTechnicians.map((technician) => (
                <Card
                  key={technician.id}
                  className={`salon__custom-card salon__technician-item-preview ${
                    bookings.find((booking) => booking.isFocus)?.technician?.id === technician.id ? 'active' : ''
                  } ${calculateAvailableTimeMutation.isLoading ? 'disable' : ''}`}
                  onClick={() => handleChooseTechnician(technician)}
                >
                  <Avatar
                    src={
                      technician &&
                      (technician.defaultAvatar ||
                        (technician.avatar?.preview || technician.avatar?.source
                          ? `${process.env.REACT_APP_API_URL}/static/${
                              technician.avatar?.preview || technician.avatar?.source
                            }`
                          : avatarDefaultSrc))
                    }
                  />
                  <p className="salon__custom-card-des text-max-1-line">{technician.name || '--'}</p>
                </Card>
              ))}
            </div>
          </div>
        </div>
      )}

      <StyledPopup
        isOpen={!!notification?.isBusy}
        content={
          <div>
            {intl.formatMessage(
              { id: 'bookingOnline.technician.available' },
              { time: formatDateByFormatString(TIME_FORMAT_AM_PM, notification?.availableTime) }
            )}
          </div>
        }
        onCancel={() => {
          setNotification(undefined);
        }}
        onOk={() => {
          const cloneBooking = [...bookings];
          const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

          if (findIndexBookingFocused > -1) {
            const booking = cloneBooking[findIndexBookingFocused];
            booking.isAnyTechnician = false;
            booking.technician = notification?.technician;
            booking.availableTime = notification?.availableTime;
          } else {
            const findMaxId = _.maxBy(cloneBooking, 'id')?.id;

            cloneBooking.push({
              isFocus: true,
              isAnyTechnician: false,
              isAppointment: false,
              technician: notification?.technician,
              availableTime: notification?.availableTime,
              id: findMaxId ? findMaxId + 1 : 1,
            });
          }

          setBookings(cloneBooking);
          setNotification(undefined);
        }}
        buttonPropsOK={{
          content: intl.formatMessage({ id: 'common.ok' }),
        }}
        buttonPropsCancel={{
          content: intl.formatMessage({ id: 'common.cancel' }),
        }}
      />
    </>
  );
};

export default PickTechnicians;
