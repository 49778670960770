import React from 'react';

export const SvgDollarPreviewIcon = () => (
  <span>
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.18 4.82778C1.818 4.46722 1.38 4.09444 1.38 3.51389C1.38 2.84778 1.986 2.38333 3 2.38333C3.852 2.38333 4.278 2.71333 4.434 3.23889C4.506 3.48333 4.704 3.66667 4.956 3.66667H5.136C5.532 3.66667 5.814 3.26944 5.676 2.89056C5.424 2.16944 4.836 1.57056 3.9 1.33833V0.916667C3.9 0.409444 3.498 0 3 0C2.502 0 2.1 0.409444 2.1 0.916667V1.32C0.936 1.57667 0 2.34667 0 3.52611C0 4.93778 1.146 5.64056 2.82 6.05C4.32 6.41667 4.62 6.95444 4.62 7.52278C4.62 7.94444 4.326 8.61667 3 8.61667C2.01 8.61667 1.5 8.25611 1.302 7.74278C1.212 7.50444 1.008 7.33333 0.762 7.33333H0.594C0.192 7.33333 -0.09 7.74889 0.06 8.12778C0.402 8.97722 1.2 9.47833 2.1 9.67389V10.0833C2.1 10.5906 2.502 11 3 11C3.498 11 3.9 10.5906 3.9 10.0833V9.68611C5.07 9.46 6 8.76944 6 7.51667C6 5.78111 4.542 5.18833 3.18 4.82778Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
