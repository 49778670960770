import React from 'react';

export const SvgAppointmentSettingIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5037 15.9995C26.5037 16.4529 26.4637 16.8795 26.4104 17.3062L29.2237 19.5062C29.477 19.7062 29.5437 20.0662 29.3837 20.3595L26.717 24.9729C26.557 25.2662 26.2104 25.3862 25.9037 25.2662L22.5837 23.9329C21.8904 24.4529 21.1437 24.9062 20.3304 25.2395L19.8237 28.7729C19.7837 29.0929 19.5037 29.3329 19.1704 29.3329H13.837C13.5037 29.3329 13.2237 29.0929 13.1837 28.7729L12.677 25.2395C11.8637 24.9062 11.117 24.4662 10.4237 23.9329L7.10369 25.2662C6.81036 25.3729 6.45036 25.2662 6.29036 24.9729L3.62369 20.3595C3.46369 20.0662 3.53036 19.7062 3.78369 19.5062L6.59703 17.3062C6.54369 16.8795 6.50369 16.4395 6.50369 15.9995C6.50369 15.5595 6.54369 15.1195 6.59703 14.6929L3.78369 12.4929C3.53036 12.2929 3.45036 11.9329 3.62369 11.6395L6.29036 7.0262C6.45036 6.73287 6.79703 6.61287 7.10369 6.73287L10.4237 8.0662C11.117 7.5462 11.8637 7.09287 12.677 6.75953L13.1837 3.2262C13.2237 2.9062 13.5037 2.6662 13.837 2.6662H19.1704C19.5037 2.6662 19.7837 2.9062 19.8237 3.2262L20.3304 6.75953C21.1437 7.09287 21.8904 7.53287 22.5837 8.0662L25.9037 6.73287C26.197 6.6262 26.557 6.73287 26.717 7.0262L29.3837 11.6395C29.5437 11.9329 29.477 12.2929 29.2237 12.4929L26.4104 14.6929C26.4637 15.1195 26.5037 15.5462 26.5037 15.9995ZM11.837 15.9995C11.837 18.5729 13.9304 20.6662 16.5037 20.6662C19.077 20.6662 21.1704 18.5729 21.1704 15.9995C21.1704 13.4262 19.077 11.3329 16.5037 11.3329C13.9304 11.3329 11.837 13.4262 11.837 15.9995Z"
        fill="#00297A"
      />
    </svg>
  </span>
);
