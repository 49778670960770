import React from 'react';

export const SvgClockIconSmall = () => (
  <span>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99844 0.833374C2.69844 0.833374 0.835938 2.70004 0.835938 5.00004C0.835938 7.30004 2.69844 9.16671 4.99844 9.16671C7.3026 9.16671 9.16927 7.30004 9.16927 5.00004C9.16927 2.70004 7.3026 0.833374 4.99844 0.833374ZM5.0026 8.33337C3.16094 8.33337 1.66927 6.84171 1.66927 5.00004C1.66927 3.15837 3.16094 1.66671 5.0026 1.66671C6.84427 1.66671 8.33594 3.15837 8.33594 5.00004C8.33594 6.84171 6.84427 8.33337 5.0026 8.33337ZM4.88594 2.91671H4.91094C5.0776 2.91671 5.21094 3.05004 5.21094 3.21671V5.10837L6.82344 6.06671C6.96927 6.15004 7.0151 6.33754 6.9276 6.47921C6.84427 6.62087 6.66094 6.66254 6.51927 6.57921L4.7901 5.54171C4.66094 5.46671 4.58594 5.32921 4.58594 5.18337V3.21671C4.58594 3.05004 4.71927 2.91671 4.88594 2.91671Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
