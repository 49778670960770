import React from 'react';

export const SvgMinMinutesIcon = () => (
  <span>
    <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.965909 13V4.27273H3.27273V5.8125H3.375C3.55682 5.30114 3.85985 4.89773 4.28409 4.60227C4.70833 4.30682 5.21591 4.15909 5.80682 4.15909C6.4053 4.15909 6.91477 4.30871 7.33523 4.60795C7.75568 4.90341 8.03599 5.30492 8.17614 5.8125H8.26705C8.44508 5.3125 8.76705 4.91288 9.23295 4.61364C9.70265 4.31061 10.2576 4.15909 10.8977 4.15909C11.7121 4.15909 12.3731 4.41856 12.8807 4.9375C13.392 5.45265 13.6477 6.18371 13.6477 7.13068V13H11.233V7.60795C11.233 7.12311 11.1042 6.75947 10.8466 6.51705C10.589 6.27462 10.267 6.15341 9.88068 6.15341C9.44129 6.15341 9.09848 6.29356 8.85227 6.57386C8.60606 6.85038 8.48295 7.21591 8.48295 7.67045V13H6.13636V7.55682C6.13636 7.12879 6.01326 6.78788 5.76705 6.53409C5.52462 6.2803 5.20455 6.15341 4.80682 6.15341C4.53788 6.15341 4.29545 6.22159 4.07955 6.35795C3.86742 6.49053 3.69886 6.67803 3.57386 6.92045C3.44886 7.15909 3.38636 7.43939 3.38636 7.76136V13H0.965909ZM15.5597 13V4.27273H17.9801V13H15.5597ZM16.7756 3.14773C16.4157 3.14773 16.107 3.02841 15.8494 2.78977C15.5956 2.54735 15.4688 2.25758 15.4688 1.92045C15.4688 1.58712 15.5956 1.30114 15.8494 1.0625C16.107 0.820075 16.4157 0.698863 16.7756 0.698863C17.1354 0.698863 17.4422 0.820075 17.696 1.0625C17.9536 1.30114 18.0824 1.58712 18.0824 1.92045C18.0824 2.25758 17.9536 2.54735 17.696 2.78977C17.4422 3.02841 17.1354 3.14773 16.7756 3.14773ZM22.3395 7.95455V13H19.919V4.27273H22.2259V5.8125H22.3281C22.5213 5.30492 22.8452 4.90341 23.2997 4.60795C23.7543 4.30871 24.3054 4.15909 24.9531 4.15909C25.5592 4.15909 26.0876 4.29167 26.5384 4.55682C26.9891 4.82197 27.3395 5.20076 27.5895 5.69318C27.8395 6.18182 27.9645 6.76515 27.9645 7.44318V13H25.544V7.875C25.5478 7.34091 25.4115 6.92424 25.1349 6.625C24.8584 6.32197 24.4777 6.17045 23.9929 6.17045C23.6671 6.17045 23.3793 6.24053 23.1293 6.38068C22.883 6.52083 22.6899 6.72538 22.5497 6.99432C22.4134 7.25947 22.3433 7.57955 22.3395 7.95455Z"
        fill="#78797A"
      />
    </svg>
  </span>
);
