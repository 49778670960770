import antdVI from 'antd/locale/vi_VN';
import common from './common_vi.json';
import signin from './signin.json';
import sideBar from './sidebar_vi.json';
import generalInfo from './generalInfo_vi.json';
import employee from './employee_vi.json';
import setting from './setting_vi.json';
import appointment from './appointment.json';
import customer from './customer_vi.json';
import onlineAppointment from './onlineAppointment_vi.json';
import onlineAppointmentNew from './onlineAppointmentNew_vi.json';
import promotions from './promotions_vi.json';
import checkIn from './checkin_vi.json';
import timesheet from './timesheet_vi.json';
import compensation from './compensation_vi.json';
import report from './report_vi.json';
import print from './print_vi.json';
import saas from './saas_vi.json';
import checkout from '../en_US/checkout_en.json';
import bookingOnline from '../en_US/bookingOnline_en.json';

const viLang = {
  messages: {
    ...common,
    ...signin,
    ...sideBar,
    ...generalInfo,
    ...employee,
    ...setting,
    ...appointment,
    ...customer,
    ...checkout,
    ...bookingOnline,
    ...checkIn,
    ...onlineAppointment,
    ...onlineAppointmentNew,
    ...promotions,
    ...timesheet,
    ...compensation,
    ...report,
    ...print,
    ...saas,
  },
  antd: antdVI,
  locale: 'vi-VN',
};
export default viLang;
