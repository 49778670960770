import React from 'react';

export const SvgAppointmentCompletedIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50065 1.83331C4.82065 1.83331 1.83398 4.81998 1.83398 8.49998C1.83398 12.18 4.82065 15.1666 8.50065 15.1666C12.1807 15.1666 15.1673 12.18 15.1673 8.49998C15.1673 4.81998 12.1807 1.83331 8.50065 1.83331ZM8.50065 13.8333C5.56065 13.8333 3.16732 11.44 3.16732 8.49998C3.16732 5.55998 5.56065 3.16665 8.50065 3.16665C11.4407 3.16665 13.834 5.55998 13.834 8.49998C13.834 11.44 11.4407 13.8333 8.50065 13.8333ZM7.16732 9.94665L11.0873 6.02665C11.3473 5.76665 11.774 5.76665 12.034 6.02665C12.294 6.28665 12.294 6.70665 12.034 6.96665L7.64065 11.36C7.38065 11.62 6.96065 11.62 6.70065 11.36L4.97398 9.63331C4.71398 9.37331 4.71398 8.95331 4.97398 8.69331C5.09854 8.56848 5.26764 8.49832 5.44398 8.49832C5.62033 8.49832 5.78943 8.56848 5.91398 8.69331L7.16732 9.94665Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
