import React from 'react';

export const SvgPhoneNumberIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/communication/phone_24px">
        <path
          id="icon/communication/phone_24px_2"
          d="M13.6155 10.1784L11.8164 9.98505C11.3843 9.93839 10.9593 10.0784 10.6547 10.3651L9.35138 11.5917C7.34679 10.6317 5.70346 9.09172 4.68346 7.19839L5.99387 5.96505C6.29846 5.67839 6.44721 5.27839 6.39762 4.87172L6.19221 3.19172C6.10721 2.51839 5.50512 2.01172 4.78262 2.01172H3.55721C2.75679 2.01172 2.09096 2.63839 2.14054 3.39172C2.51596 9.08505 7.35388 13.6317 13.396 13.9851C14.1964 14.0317 14.8622 13.4051 14.8622 12.6517V11.4984C14.8693 10.8251 14.331 10.2584 13.6155 10.1784Z"
          fill="black"
          fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};
