import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import * as clover from 'remote-pay-cloud';
import { settingApi } from '../../../../../../../apis';
import { UpdateTerminalDeviceSettingDto } from '../../../../../../../apis/client-axios';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import { RootState, useAppDispatch } from '../../../../../../../store';
import {
  setCloverConnector,
  setDeviceActivity,
  setDeviceError,
  setIsDeviceReady,
  setIsDisconnected,
  setIsLoading,
  setIsPairingCodeSuccess,
  setPairingCode,
  setPrintResponse,
  setRefundResponse,
  setResetDeviceResponse,
  setSaleResponse,
} from '../../../../../../../store/cloverSlice';
import { DATE_FORMAT_FULL_DATE, getStorageByName } from '../../../../../../../utils';
import { QUERY_SETTING } from '../../../../../../../utils/constant';
import { cloverConnection } from '../index';

export const useClover = () => {
  const dispatch = useAppDispatch();
  const cloverConnector = useSelector((state: RootState) => state.clover.cloverConnector);
  const isLoading = useSelector((state: RootState) => state.clover.isLoading);

  const cloverDevice = cloverConnection(cloverConnector);

  const { data: salonSettingResponse, refetch: onRefetchSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    enabled: !!getStorageByName('salonId'),
  });

  const updateSettingTerminalDeviceMutation = useMutation(
    (payload: UpdateTerminalDeviceSettingDto) => settingApi.settingControllerUpdateTerminalDeviceSetting(payload),
    {
      onSuccess: () => {
        onRefetchSetting();
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
    }
  );

  const onSaleResponse = (response: clover.remotepay.SaleResponse) => {
    dispatch(setSaleResponse(response));
  };

  const onManualRefundResponse = (response: clover.remotepay.ManualRefundResponse) => {
    dispatch(setRefundResponse(response));
  };

  const onPrintJobStatusResponse = (response: clover.remotepay.PrintJobStatusResponse) => {
    dispatch(setPrintResponse(response));
  };

  const onPairingCode = (code: string) => {
    dispatch(setPairingCode(code));
  };

  const onPairingCodeSuccess = (authToken: string) => {
    dispatch(setIsPairingCodeSuccess(true));

    updateSettingTerminalDeviceMutation.mutate({
      ...salonSettingResponse?.data.settingTerminalDevice,
      cloverAuthToken: authToken,
      terminalConnection: true,
    } as UpdateTerminalDeviceSettingDto);
  };

  const onDeviceReady = (merchant: clover.remotepay.MerchantInfo) => {
    dispatch(setIsDeviceReady(true));

    const settingTerminalDevice = salonSettingResponse?.data?.settingTerminalDevice;

    if (
      merchant.getDeviceInfo().getModel() !== settingTerminalDevice?.cloverMerchantInformation?.deviceInfo?.model &&
      merchant.getMerchantID() !== settingTerminalDevice?.cloverMerchantInformation?.merchantID &&
      merchant.getMerchantMID() !== settingTerminalDevice?.cloverMerchantInformation?.merchantMID
    ) {
      updateSettingTerminalDeviceMutation.mutate({
        ...salonSettingResponse?.data.settingTerminalDevice,
        terminalConnection: true,
        cloverMerchantInformation: {
          deviceInfo: merchant.getDeviceInfo() as any,
          merchantID: merchant.getMerchantID(),
          merchantMID: merchant.getMerchantMID(),
          merchantName: merchant.getMerchantName(),
          supportsAuths: merchant.getSupportsAuths(),
          supportsManualRefunds: merchant.getSupportsManualRefunds(),
          supportsPreAuths: merchant.getSupportsPreAuths(),
          supportsSales: merchant.getSupportsSales(),
          supportsTipAdjust: merchant.getSupportsTipAdjust(),
          supportsVaultCards: merchant.getSupportsVaultCards(),
        },
      } as UpdateTerminalDeviceSettingDto);
    }
  };

  const onDeviceDisconnected = () => {
    console.log('Clover Disconnected...', dayjs().format(DATE_FORMAT_FULL_DATE));
    dispatch(setIsDisconnected(true));
  };

  const onDeviceError = (response: clover.remotepay.CloverDeviceErrorEvent) => {
    console.log('Clover Error...', dayjs().format(DATE_FORMAT_FULL_DATE), response);
    dispatch(setDeviceError(response));
  };

  const onDeviceActivityStart = (event: clover.remotepay.CloverDeviceEvent) => {
    console.log('START', event);

    dispatch(setDeviceActivity({ start: event }));
  };

  const onDeviceActivityEnd = (event: clover.remotepay.CloverDeviceEvent) => {
    console.log('END', event);

    dispatch(setDeviceActivity({ end: event }));
  };

  const onResetDeviceResponse = (response: clover.remotepay.ResetDeviceResponse) => {
    dispatch(setResetDeviceResponse(response));
  };

  const onConnect = () => {
    const settingTerminalDevice = salonSettingResponse?.data?.settingTerminalDevice;

    if (!settingTerminalDevice || (settingTerminalDevice && !settingTerminalDevice.cloverDeviceAddress)) return;

    if (!cloverConnector) {
      dispatch(setIsLoading(true));
    } else {
      if (isLoading) {
        dispatch(setIsLoading(false));
      }

      if (!settingTerminalDevice?.terminalConnection) {
        updateSettingTerminalDeviceMutation.mutate({
          ...salonSettingResponse?.data.settingTerminalDevice,
          terminalConnection: true,
        });
      }
    }

    cloverDevice.connect({
      connectionConfiguration: {
        applicationId: settingTerminalDevice.cloverApplicationID || '',
        endpoint: settingTerminalDevice.cloverDeviceAddress || '',
        posName: settingTerminalDevice.cloverPosName || '',
        serialNumber: settingTerminalDevice.cloverSerialNumber || '',
        authToken: settingTerminalDevice.cloverAuthToken || '',
      },
      onSaleResponse,
      onManualRefundResponse,
      onPrintJobStatusResponse,
      onPairingCode,
      onPairingCodeSuccess,
      onDeviceReady,
      onDeviceDisconnected,
      onDeviceError,
      onResetDeviceResponse,
      onDeviceActivityStart,
      onDeviceActivityEnd,
      setCloverConnector: (connector) => dispatch(setCloverConnector(connector)),
    });
  };

  return { cloverDevice, onConnect };
};
