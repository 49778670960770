import { useIntl } from 'react-intl';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { StyledModal } from '../../../../../components/StyledModal';
import { TimeItem } from './TimeItem';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { useEffect, useState } from 'react';

import { CalculateAvailableTimeDto, ServiceItems, Employee, BusyTime, Salon } from '../../../../../apis/client-axios';
import { useMutation } from '@tanstack/react-query';
import { appointmentApi } from '../../../../../apis';
import { Booking } from '../..';
import {
  DATE_FORMAT_2,
  DATE_FORMAT_FULL_DATE_3,
  formatTimeZoneToDate,
  TIME_FORMAT,
  TIME_FORMAT_1,
  toDayjsByTimeZoneServer,
} from '../../../../../utils';
import dayjs from 'dayjs';
import NotificationError from '../../../../../components/HandleShowNotiError';

export interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  serviceItem?: ServiceItems;
  technician?: Employee;
  handleChooseService: (
    serviceItem?: ServiceItems,
    timeActive?: TimeActive,
    selectedDate?: DateObject | DateObject[] | null | Date
  ) => void;
  handleChooseTechnician: (
    technician?: Employee,
    timeActive?: TimeActive,
    selectedDate?: DateObject | DateObject[] | null | Date
  ) => void;
  bookings: Booking[];
  statusAnyTechnician?: boolean;
  dataSalon?: Salon;
}
export interface ListTime {
  timeStart: string;
  timeEnd: string;
}

export interface TimeActive {
  timeStart?: string;
  timeEnd?: string;
}
function PickATime(props: Props) {
  const {
    open,
    setOpen,
    serviceItem,
    handleChooseService,
    bookings,
    technician,
    handleChooseTechnician,
    statusAnyTechnician,
    dataSalon,
  } = props;
  const intl = useIntl();
  const [listTime, setListTime] = useState<ListTime[]>();
  const [listTimeDisable, setListTimeDisable] = useState<BusyTime[]>();
  const [selectedDate, setSelectedDate] = useState<DateObject | DateObject[] | null | Date>(
    formatTimeZoneToDate(new Date())
  );
  const [timeActive, setTimeActive] = useState<TimeActive>();
  const calculateBusyTimeMutation = useMutation((payload: CalculateAvailableTimeDto) =>
    appointmentApi.appointmentControllerCalculateBusyTime({ ...payload, estimate: payload.estimate ?? 15 })
  );
  const resetTimeActive = () => {
    setTimeActive(undefined);
  };
  const onBack = () => {
    setOpen(false);
    setListTimeDisable([]);
    setSelectedDate(formatTimeZoneToDate(new Date()));
  };
  const handleDateChange = async (date: DateObject | DateObject[] | null | Date) => {
    if (date && dataSalon && dataSalon.workingTime) {
      const now = formatTimeZoneToDate(new Date());
      let selectedDate: Date;

      if (date instanceof Array) {
        selectedDate = date[0].toDate();
      } else if (date instanceof DateObject) {
        selectedDate = date.toDate();
      } else {
        selectedDate = date;
      }
      const selectedDayOfWeek = selectedDate.toLocaleString('en', { weekday: 'long' }).toLowerCase();
      const anyWorkingTime = dataSalon?.workingTime as any;
      const workingTime = anyWorkingTime[selectedDayOfWeek];
      if (workingTime) {
        let startTime;
        let endTime;

        startTime = workingTime?.from;
        endTime = workingTime?.to;

        const startTimeParts = startTime.split(':');
        const endTimeParts = endTime.split(':');

        const startHour = parseInt(startTimeParts[0]);
        const endHour = parseInt(endTimeParts[0]);
        const startMinute = parseInt(startTimeParts[1] ?? '0');
        const endMinute = parseInt(endTimeParts[1] ?? '0');

        const newTimeList: ListTime[] = [];

        let currentHour = startHour;
        let currentMinute = startMinute;
        const booking = bookings?.find((item) => item.isFocus);

        while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
          const timeStartNext = 15;
          const nextMinute = currentMinute + timeStartNext;
          const overflowHours = Math.floor(nextMinute / 60);
          let newHour = currentHour + overflowHours;
          let newMinute = nextMinute % 60;

          if (newMinute === 60) {
            newHour++;
            newMinute = 0;
          }
          const startPeriod = currentHour < 12 ? 'AM' : 'PM';
          const displayStartHour = currentHour;
          const displayStartMinute = currentMinute;
          const timeStart = `${displayStartHour.toString().padStart(2, '0')}:${displayStartMinute
            .toString()
            .padStart(2, '0')} ${startPeriod}`;
          let displayEndHour = displayStartHour;
          let displayEndMinute =
            displayStartMinute +
            (serviceItem?.time
              ? serviceItem?.time
              : booking
              ? technician && booking.service?.time && booking?.technician
                ? 15
                : (!technician && booking.service?.time) || (technician && booking.service?.time)
                ? booking.service?.time
                : 15
              : 15);

          displayEndHour += Math.floor(displayEndMinute / 60);
          displayEndMinute %= 60;

          const endPeriod = displayEndHour < 12 ? 'AM' : 'PM';
          const timeEnd = `${displayEndHour.toString().padStart(2, '0')}:${displayEndMinute
            .toString()
            .padStart(2, '0')} ${endPeriod}`;

          if (selectedDate.getDate() === now.getDate()) {
            if (currentHour > now.getHours() || (currentHour === now.getHours() && currentMinute >= now.getMinutes())) {
              if (timeEnd <= endTime) {
                newTimeList.push({ timeStart, timeEnd });
              }
            }
          } else {
            if (timeEnd <= endTime) {
              newTimeList.push({ timeStart, timeEnd });
            }
          }
          currentHour = newHour;
          currentMinute = newMinute;
        }

        setListTime(newTimeList);

        const cloneBooking = [...bookings];

        const findIndexBookingFocused = cloneBooking.findIndex((booking) => booking.isFocus);

        if (findIndexBookingFocused > -1) {
          const bookingFocused = cloneBooking[findIndexBookingFocused];
          if (statusAnyTechnician) {
            if (technician && !serviceItem) {
              const bookingsForTechnician = bookings?.filter((item) => item?.technician?.id === technician?.id);

              const bookingsForService = bookings?.filter((item) => item?.service?.id === bookingFocused?.service?.id);
              const response = await calculateBusyTimeMutation.mutateAsync({
                technicianId: technician?.id,
                estimate: 15,
                technicianTimeBusy: dayjs(selectedDate)?.format(DATE_FORMAT_2),
              });
              const data = response.data;
              let mergedTimeDisable: any = [];
              if (data && data.busyTime) {
                mergedTimeDisable = [...(data.busyTime || [])];
              }
              if (bookingsForTechnician?.length > 0) {
                bookingsForTechnician.forEach((booking) => {
                  if (
                    dayjs(booking?.availableTime, 'HH:mm A MM/DD/YYYY').format(DATE_FORMAT_2) ===
                    dayjs(selectedDate)?.format(DATE_FORMAT_2)
                  ) {
                    const endTimeWithExtraMinutes = dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').add(
                      Number(booking?.service?.time ?? 15),
                      'minutes'
                    );
                    mergedTimeDisable.push({
                      timeStart: dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').format(TIME_FORMAT_1),
                      timeEnd: endTimeWithExtraMinutes.format(TIME_FORMAT_1),
                    });
                  }
                });
              }
              if (
                bookingsForService?.length > 0 &&
                bookingFocused &&
                bookingFocused.service &&
                bookingFocused?.technician?.id === technician.id
              ) {
                if (!bookingFocused.technician) {
                  bookingsForService.forEach((booking) => {
                    if (
                      dayjs(booking?.availableTime, 'HH:mm A MM/DD/YYYY').format(DATE_FORMAT_2) ===
                        dayjs(selectedDate)?.format(DATE_FORMAT_2) &&
                      !booking.isAnyTechnician
                    ) {
                      const endTimeWithExtraMinutes = dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').add(
                        Number(booking?.service?.time ?? 15),
                        'minutes'
                      );

                      mergedTimeDisable.push({
                        timeStart: dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').format(TIME_FORMAT_1),
                        timeEnd: endTimeWithExtraMinutes.format(TIME_FORMAT_1),
                      });
                    }
                  });
                }
              }

              setListTimeDisable(mergedTimeDisable);
            } else if (serviceItem && !technician) {
              const bookingsForService = bookings?.filter(
                (item) =>
                  item?.service?.id === serviceItem?.id &&
                  item?.technician?.id === bookingFocused.technician?.id &&
                  !item.isAnyTechnician
              );
              const bookingsForTechnician = bookings?.filter(
                (item) => item?.technician?.id === bookingFocused.technician?.id && !item.isAnyTechnician
              );
              let mergedTimeDisable: any = [];
              if (bookingFocused.technician) {
                const response = await calculateBusyTimeMutation.mutateAsync({
                  technicianId: bookingFocused.technician.id,
                  estimate: 15,
                  technicianTimeBusy: dayjs(selectedDate)?.format(DATE_FORMAT_2),
                });

                const data = response.data;
                if (data && data.busyTime) {
                  mergedTimeDisable = [...(data.busyTime || [])];
                }
              }
              if (bookingsForTechnician?.length > 0 && bookingFocused.technician) {
                bookingsForTechnician.forEach((booking) => {
                  if (
                    dayjs(booking?.availableTime, 'HH:mm A MM/DD/YYYY').format(DATE_FORMAT_2) ===
                      dayjs(selectedDate)?.format(DATE_FORMAT_2) &&
                    !booking?.isAnyTechnician &&
                    !booking.isFocus
                  ) {
                    const endTimeWithExtraMinutes = dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').add(
                      Number(booking?.service?.time ?? 15),
                      'minutes'
                    );

                    mergedTimeDisable.push({
                      timeStart: dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').format(TIME_FORMAT_1),
                      timeEnd: endTimeWithExtraMinutes.format(TIME_FORMAT_1),
                    });
                  }
                });
              }

              if (bookingsForService?.length > 0 && bookingFocused.technician) {
                bookingsForService.forEach((booking) => {
                  if (
                    dayjs(booking?.availableTime, 'HH:mm A MM/DD/YYYY').format(DATE_FORMAT_2) ===
                      dayjs(selectedDate)?.format(DATE_FORMAT_2) &&
                    !booking?.isAnyTechnician
                  ) {
                    const endTimeWithExtraMinutes = dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').add(
                      Number(booking?.service?.time ?? 15),
                      'minutes'
                    );

                    mergedTimeDisable.push({
                      timeStart: dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').format(TIME_FORMAT_1),
                      timeEnd: endTimeWithExtraMinutes.format(TIME_FORMAT_1),
                    });
                  }
                });
              }
              if (bookingFocused.technician && bookingFocused.service) {
                setListTimeDisable([]);
              } else {
                setListTimeDisable(mergedTimeDisable);
              }
            } else {
              setListTimeDisable([]);
            }
          } else {
            setListTimeDisable([]);
          }
        } else {
          if (technician && !serviceItem) {
            const bookingsForTechnician = bookings?.filter((item) => item?.technician?.id === technician?.id);
            let mergedTimeDisable: any = [];

            const response = await calculateBusyTimeMutation.mutateAsync({
              technicianId: technician?.id,
              estimate: 15,
              technicianTimeBusy: dayjs(selectedDate)?.format(DATE_FORMAT_2),
            });

            const data = response.data;

            if (data && data.busyTime) {
              mergedTimeDisable = [...(data.busyTime || [])];
            }
            if (bookingsForTechnician?.length > 0) {
              if (bookingsForTechnician) {
                bookingsForTechnician.forEach((booking) => {
                  if (
                    dayjs(booking?.availableTime, 'HH:mm A MM/DD/YYYY').format(DATE_FORMAT_2) ===
                    dayjs(selectedDate)?.format(DATE_FORMAT_2)
                  ) {
                    const endTimeWithExtraMinutes = dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').add(
                      Number(booking?.service?.time ?? 15),
                      'minutes'
                    );
                    mergedTimeDisable.push({
                      timeStart: dayjs(booking.availableTime, 'HH:mm A MM/DD/YYYY').format(TIME_FORMAT_1),
                      timeEnd: endTimeWithExtraMinutes.format(TIME_FORMAT_1),
                    });
                  }
                });
              }
            }
            setListTimeDisable(mergedTimeDisable);
          } else if (serviceItem && !technician) {
            setListTimeDisable([]);
          }
        }
      } else {
        setListTime([]);
        setListTimeDisable([]);
      }
    }
  };
  useEffect(() => {
    handleDateChange(selectedDate);
  }, [selectedDate, serviceItem, technician, bookings]);

  const convertToAmPm = (hour: number): string => {
    return hour >= 12 ? 'PM' : 'AM';
  };
  const checkHoursCompare = (
    checkTimeStart: string,
    checkTimeEnd: string,
    compareTimeStart?: string,
    compareTimeEnd?: string
  ): boolean => {
    return (
      (dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) > dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) < dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT)) ||
      (dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) > dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) < dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT)) ||
      (dayjs(checkTimeStart, 'HH:mm A').format(TIME_FORMAT) <= dayjs(compareTimeStart, 'HH:mm A').format(TIME_FORMAT) &&
        dayjs(checkTimeEnd, 'HH:mm A').format(TIME_FORMAT) >= dayjs(compareTimeEnd, 'HH:mm A').format(TIME_FORMAT))
    );
  };

  useEffect(() => {
    if (listTime && listTime.length > 0) {
      let activeTime: ListTime | null = null;
      for (let i = 0; i < listTime.length; i++) {
        const currentItem = listTime[i];
        if (
          !listTimeDisable?.some((timeDisable) => {
            return checkHoursCompare(
              currentItem.timeStart,
              currentItem.timeEnd,
              timeDisable.timeStart,
              timeDisable.timeEnd
            );
          })
        ) {
          activeTime = currentItem;
          break;
        }
      }
      if (activeTime) {
        setTimeActive(activeTime);
      } else {
        setTimeActive(undefined);
      }
    } else {
      setTimeActive(undefined);
    }
  }, [listTime, listTimeDisable, setTimeActive, technician, serviceItem]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={onBack}
      modalProps={{
        title: (
          <div className="font-weight-600 font-size-32 text-center">
            {intl.formatMessage({ id: 'onlineAppointmentNew.pickAtime' })}
          </div>
        ),
        className: 'salon__pick-a-time-modal',
        width: 1340,
        footer: (
          <div className="d-flex justify-content-end align-items-center p-r-40 p-t-20 p-b-20">
            <div className="d-flex gap-10">
              <ButtonStyled
                content={intl.formatMessage({ id: 'setting.services.cancel' })}
                buttonProps={{
                  className: 'width-100 height-38 ',
                  onClick: onBack,
                }}
                isPrimary={false}
                isButtonCancel={true}
              />

              <ButtonStyled
                content={intl.formatMessage({ id: 'setting.services.save' })}
                buttonProps={{
                  className: 'width-100 height-38 ',
                  onClick: () => {
                    if (!timeActive) {
                      NotificationError({
                        contentNoti: intl.formatMessage({ id: 'onlineAppointmentNew.error.pickAtime' }),
                      });
                    } else {
                      if (serviceItem) {
                        handleChooseService(serviceItem, timeActive, selectedDate);
                      } else {
                        handleChooseTechnician(technician, timeActive, selectedDate);
                      }
                      setSelectedDate(formatTimeZoneToDate(new Date()));
                    }
                  },
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <div className="d-flex gap-50">
        <div className="salon__calender-custom">
          <Calendar
            highlightToday={false}
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            minDate={toDayjsByTimeZoneServer(new Date(), DATE_FORMAT_FULL_DATE_3).format(DATE_FORMAT_FULL_DATE_3)}
          />
        </div>
        <div className="salon__calender-time-custom">
          <div className="d-flex gap-20 m-r-20">
            <TimeItem
              textTime={intl.formatMessage({ id: 'onlineAppointmentNew.pickAtime.morning' })}
              listTime={
                listTime
                  ? listTime.filter((item) => {
                      const startHour = parseInt(item.timeStart.split(':')[0]);
                      return startHour < 12 && convertToAmPm(startHour) === 'AM';
                    })
                  : []
              }
              timeActive={timeActive}
              setTimeActive={setTimeActive}
              resetTimeActive={resetTimeActive}
              listTimeDisable={listTimeDisable}
            />
            <TimeItem
              textTime={intl.formatMessage({ id: 'onlineAppointmentNew.pickAtime.afternoon' })}
              listTime={
                listTime
                  ? listTime.filter((item) => {
                      const startHour = parseInt(item.timeStart.split(':')[0]);
                      return startHour >= 12 && startHour < 17;
                    })
                  : []
              }
              timeActive={timeActive}
              setTimeActive={setTimeActive}
              resetTimeActive={resetTimeActive}
              listTimeDisable={listTimeDisable}
            />
            <TimeItem
              textTime={intl.formatMessage({ id: 'onlineAppointmentNew.pickAtime.evening' })}
              listTime={
                listTime
                  ? listTime.filter((item) => {
                      const startHour = parseInt(item.timeStart.split(':')[0]);
                      return startHour >= 17 && convertToAmPm(startHour) === 'PM';
                    })
                  : []
              }
              timeActive={timeActive}
              setTimeActive={setTimeActive}
              resetTimeActive={resetTimeActive}
              listTimeDisable={listTimeDisable}
            />
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
export default PickATime;
