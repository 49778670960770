import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../../apis';
import { CashDrawerDTO, CashDrawerDTOActionEnum } from '../../../../../apis/client-axios';
import ColWrap from '../../../../../components/ColWrap';
import { FormTextArea } from '../../../../../components/Form/FormTextArea';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import '../style.scss';
import CashDrawerKeyBoard from '../CashDrawerKeyBoard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

export interface Props {
  openModal: boolean;
  onCancelModal: () => void;
  refetchCashDrawer: () => void;
  onOpenCashDrawer: (reason: string) => void;
}

interface FormData {
  cashAmount?: string;
  notes?: string;
}

const n = (key: keyof FormData) => key;

const CashOut: FC<Props> = (props) => {
  const { openModal, onCancelModal, refetchCashDrawer, onOpenCashDrawer } = props;

  const intl = useIntl();

  const [form] = Form.useForm<FormData>();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const notes = Form.useWatch(n('notes'), form) as string | undefined;

  const createCashDrawer = useMutation((data: CashDrawerDTO) => ticketApi.ticketControllerCreateCashDrawer(data), {
    onSuccess: () => {
      onOpenCashDrawer(notes || CashDrawerDTOActionEnum.Others);
      onCancelModal();
      form.resetFields();
      refetchCashDrawer();
    },
    onError: (error: { response: { data: { message: string }; status: number } }) => {
      NotificationError({ contentNoti: error.response.data.message });
    },
  });

  const onSubmit = () => {
    const { notes } = form.getFieldsValue();

    const dataSubmit: CashDrawerDTO = {
      notes,
      action: CashDrawerDTOActionEnum.Others,
    };
    createCashDrawer.mutate(dataSubmit);
  };

  return (
    <>
      <StyledModal
        isOpen={openModal}
        onCancel={onCancelModal}
        modalProps={{
          title: intl.formatMessage({ id: 'checkout.cashDrawer.others' }),
          className: 'salon__checkout-cash-drawer-modal',
          width: 'auto',
          footer: (
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex gap-12 align-items-center">
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.cashDrawer.ok' })}
                  buttonProps={{
                    className: 'width-100 height-38',
                    onClick: onSubmit,
                    loading: createCashDrawer.isLoading,
                  }}
                  isPrimary={true}
                />
              </div>
            </div>
          ),
        }}
      >
        <FormWrap form={form} name="cashIn" layout="vertical" className="w-100  p-t-20">
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[10, 20]} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <FormTextArea
                name={n('notes')}
                isFocus={true}
                inputProps={{
                  inputMode: 'none',

                  className: 'salon__checkout-cash-drawer-modal-custom-input',
                  placeholder: intl.formatMessage({ id: 'checkout.cashDrawer.others.placeholder' }),
                  maxLength: 1000,
                }}
              />
            </ColWrap>
          </RowWrap>
          <CashDrawerKeyBoard form={form} inputName={n('notes')} keyboardRef={keyboardRef} />
        </FormWrap>
      </StyledModal>
    </>
  );
};

export default CashOut;
