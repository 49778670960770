import { useMutation } from '@tanstack/react-query';
import { ColumnType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as clover from 'remote-pay-cloud';
import { ticketApi } from '../../../../../apis';
import {
  CardInformation,
  CardInformationCardBrandEnum,
  CardInformationCardTypeEnum,
  CustomerTransaction,
  CustomerTransactionDto,
  CustomerTransactionDtoPaymentMethodEnum,
  CustomerTransactionDtoPaymentStatusEnum,
  Promotion,
  SalonSetting,
  SettingCheckout,
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
  UpdateTerminalDeviceSettingDtoDeviceConnectedEnum,
} from '../../../../../apis/client-axios';
import { SvgBinIcon } from '../../../../../components/@svg/SvgBinIcon';
import ColWrap from '../../../../../components/ColWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import { RequirePasswordModal } from '../../../../../components/RequirePasswordModal';
import RowWrap from '../../../../../components/RowWrap';
import TableWrap from '../../../../../components/TableWrap';
import { ButtonIcon } from '../../../../../components/buttons/ButtonIcon';
import { CustomButton } from '../../../../../components/buttons/CustomButton';
import { RootState, useAppDispatch } from '../../../../../store';
import { setSaleResponse } from '../../../../../store/cloverSlice';
import { formatNumberThousandWithDecimal, handleTruncateToTwoDecimal } from '../../../../../utils';
import { defaultManagerCode } from '../../../../../utils/constant';
import { useClover } from '../../../setting/terminalDeviceSetting/Clover/connection/hooks';
import { ConnectPaxResponse, paxDevice } from '../../../setting/terminalDeviceSetting/Pax/connection';
import {
  IAppointmentBill,
  IHandleSubmitCheckoutProps,
  IListIdTotalPrice,
  ITipInformation,
  PaymentInformation,
} from '../../index';
import { PayAndCompleteEnum } from '../index';
import CardNumber from './CardNumber';
import { CashPayment } from './CashPayment';
import ChargeCard from './ChargeCard';
import CardPaymentModal from './ChargeCard/CardPaymentModal';
import { CheckPayment } from './CheckPayment';
import { GiftCard } from './GiftCard';
import MultipleCreditCardsModal from './MultipleCreditCards';
import { Other } from './Other';

interface IProps {
  setOpenModal: (state: boolean) => void;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  listTotal: IListIdTotalPrice;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  onChangeCurrentScreen: (screen: PayAndCompleteEnum) => void;
  listPromotion: AxiosResponse<Promotion, any> | undefined;
  saveTipAndTechnician: (totalTip: number) => void;
  tipInformation: ITipInformation | undefined;
  resetBill: () => void;
  isLoading: boolean;
  settingCheckout?: SettingCheckout;
  appointmentBill: IAppointmentBill;
  settings?: SalonSetting;
}

enum ModalTypeRequirePasswordEnum {
  ChargeCard = 'chargeCard',
  CardNumber = 'cardNumber',
  Cash = 'cash',
  Check = 'check',
  Orther = 'orther',
}

enum DataSourceTypeEnum {
  ChargeCard = 'chargeCard',
  CardNumber = 'cardNumber',
  Cash = 'cash',
  Check = 'check',
  Orther = 'orther',
  BlanceToPay = 'blanceToPay',
  GiftCard = 'giftCard',
}

interface IRequirePassword {
  open: boolean;
  modalType: ModalTypeRequirePasswordEnum | undefined;
}

export const Payment: React.FC<IProps> = (props) => {
  const {
    setOpenModal,
    setListTotal,
    handleSubmitCheckout,
    listTotal,
    paymentInformation,
    onChangePaymentInformation,
    onChangeCurrentScreen,
    saveTipAndTechnician,
    tipInformation,
    resetBill,
    isLoading,
    settingCheckout,
    appointmentBill,
    settings,
  } = props;

  const intl = useIntl();

  const [isOpenCashPayment, setIsOpenCashPayment] = useState<boolean>(false);
  const [isOpenCheckPayment, setIsOpenCheckPayment] = useState<boolean>(false);
  const [isOpenChargeCard, setIsOpenChargeCard] = useState<boolean>(false);
  const [isOpenOther, setIsOpenOther] = useState<boolean>(false);
  const [isOpenCardNumber, setIsOpenCardNumber] = useState<boolean>(false);
  const [isOpenGiftCard, setIsOpenGiftCard] = useState<boolean>(false);
  const [isOpenMultipleCards, setIsOpenMultipleCards] = useState<boolean>(false);
  const [requirePasswordState, setRequirePasswordState] = useState<IRequirePassword>({
    open: false,
    modalType: undefined,
  });
  //using this state to prevent multiple call func onSaleResponse
  const timeSaleResponseRef = useRef<{ success?: Dayjs; error?: Dayjs } | null>(null);
  const ticketTransactionPaymentRef = useRef<{
    type: TicketTransactionDTOTypePaymentEnum;
    amount: number;
  }>({
    type: TicketTransactionDTOTypePaymentEnum.MultipleChargeCard,
    amount: 0,
  });

  const { cloverDevice, onConnect } = useClover();

  const dispatch = useAppDispatch();
  const saleResponse = useSelector((state: RootState) => state.clover.saleResponse);

  useEffect(() => {
    if (saleResponse) {
      onSaleResponse(saleResponse);
    }
  }, [saleResponse]);

  const leftBalanceToPay = () => {
    return handleTruncateToTwoDecimal(Number(listTotal?.totalBalanceTotalPay?.toFixed(2) ?? 0));
  };

  const createCustomerTransactionMutation = useMutation(
    (payload: CustomerTransactionDto) => ticketApi.ticketControllerCreateCustomerTransaction(payload),
    {
      onSuccess: ({ data }) => {
        const feeCheckout = settings?.settingCheckout?.feeChargedPerPaymentPercentage || 0;

        const ticketTransactions = [...(paymentInformation?.ticketTransaction ?? [])];

        const cloverTip = Number(saleResponse?.getPayment().getTipAmount() || 0);

        //tip - 10% fee
        const tipMoney = (cloverTip - (Number(feeCheckout) * cloverTip) / 100) / 100;

        const totalTip = Number(tipInformation?.tip ?? 0) + tipMoney;

        const ticketTransaction: TicketTransactionDTO = {
          money: isOpenChargeCard ? leftBalanceToPay() : ticketTransactionPaymentRef.current.amount,
          typePayment: isOpenChargeCard
            ? TicketTransactionDTOTypePaymentEnum.ChargeCard
            : TicketTransactionDTOTypePaymentEnum.MultipleChargeCard,
          status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
          tipMoney,
          paymentInformation: saleResponse?.getPayment() as any,
          paymentFee: feeCheckout ? Number(feeCheckout) : undefined,
          customerTransactionId: data?.id,
        };

        ticketTransactions.push(ticketTransaction);

        onChangePaymentInformation((prev) => ({
          ...prev,
          ticketTransaction: ticketTransactions,
          multipleChargeCard: {
            amount:
              Number(paymentInformation?.multipleChargeCard?.amount || 0) + ticketTransactionPaymentRef.current.amount,
            tip: Number(paymentInformation?.multipleChargeCard?.tip || 0) + tipMoney,
          },
        }));

        if (
          isOpenChargeCard ||
          (isOpenMultipleCards && ticketTransactionPaymentRef.current.amount >= leftBalanceToPay())
        ) {
          handleSubmit({
            status: TicketDTOStatusEnum.Completed,
            ticketTransaction: ticketTransactions,
            totalTip,
          });
        }

        saveTipAndTechnician(totalTip);

        setListTotal({
          ...listTotal,
          totalCash: isOpenChargeCard
            ? leftBalanceToPay() + listTotal.totalCash
            : ticketTransactionPaymentRef.current.amount + listTotal.totalCash,
        });

        dispatch(setSaleResponse(undefined));
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
    }
  );

  const createCustomerTransactionPaxMutation = useMutation((payload: CustomerTransactionDto) =>
    ticketApi.ticketControllerCreateCustomerTransaction(payload)
  );

  const onDeletePaymentTransaction = (typePayment: TicketTransactionDTOTypePaymentEnum) => {
    const newTransaction = { ...paymentInformation };
    const newListTotal = { ...listTotal };
    let totalTip = tipInformation?.tip ?? 0;

    if (newTransaction) {
      switch (typePayment) {
        case TicketTransactionDTOTypePaymentEnum.Cash:
          newListTotal!.totalBalanceTotalPay += newTransaction!.cashPayment?.amount ?? 0;
          newListTotal!.totalCash -= newTransaction!.cashPayment?.amount ?? 0;
          newListTotal!.totalTip -= newTransaction!.cashPayment?.tip ?? 0;
          totalTip -= newTransaction!.cashPayment?.tip ?? 0;
          newTransaction!.cashPayment = undefined;

          break;
        case TicketTransactionDTOTypePaymentEnum.GiftCard:
          newListTotal!.totalBalanceTotalPay += newTransaction!.giftCardPayment?.totalValue ?? 0;
          newListTotal!.totalCash -= newTransaction!.giftCardPayment?.totalValue ?? 0;
          newTransaction!.giftCardPayment = undefined;

          break;
        case TicketTransactionDTOTypePaymentEnum.Check:
          newListTotal!.totalBalanceTotalPay += newTransaction!.checkPayment?.amount ?? 0;
          newListTotal!.totalCash -= newTransaction!.checkPayment?.amount ?? 0;
          newListTotal!.totalTip -= newTransaction!.checkPayment?.tip ?? 0;
          totalTip -= newTransaction!.checkPayment?.tip ?? 0;
          newTransaction!.checkPayment = undefined;

          break;
        case TicketTransactionDTOTypePaymentEnum.CardNumber:
          newListTotal!.totalBalanceTotalPay += newTransaction!.keyInCardPayment?.amount ?? 0;
          newListTotal!.totalCash -= newTransaction!.keyInCardPayment?.amount ?? 0;
          newListTotal!.totalTip -= newTransaction!.keyInCardPayment?.tip ?? 0;
          totalTip -= newTransaction!.keyInCardPayment?.tip ?? 0;
          newTransaction!.keyInCardPayment = undefined;

          break;

        case TicketTransactionDTOTypePaymentEnum.Orther:
          newListTotal!.totalBalanceTotalPay += newTransaction!.ortherPayment?.amount ?? 0;
          newListTotal!.totalCash -= newTransaction!.ortherPayment?.amount ?? 0;
          newListTotal!.totalTip -= newTransaction!.ortherPayment?.tip ?? 0;
          totalTip -= newTransaction!.ortherPayment?.tip ?? 0;
          newTransaction!.ortherPayment = undefined;

          break;
      }
      newTransaction.ticketTransaction = newTransaction.ticketTransaction?.filter((e) => e.typePayment !== typePayment);
    }

    setListTotal(newListTotal);
    saveTipAndTechnician(totalTip);
    onChangePaymentInformation(newTransaction);
  };

  const columns: ColumnType<any>[] = [
    {
      align: 'left',
      dataIndex: 'key',
      rowScope: 'row',
      key: 0,
      width: '40%',
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.payment' })}
        </span>
      ),
    },
    {
      align: 'right',
      dataIndex: 'amount',
      key: 1,
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.amount' })}
        </span>
      ),
      render: (value) => <span className="font-size-14 font-weight-600">{value && `$${value}`}</span>,
    },
    {
      align: 'right',
      dataIndex: 'tip',
      key: 1,
      title: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.tip' })}
        </span>
      ),
      render: (value) => <span className="font-size-14 font-weight-600">{value && `$${value}`}</span>,
    },
    {
      align: 'right',
      dataIndex: '',
      key: 1,
      render: (value, record, index: number) => (
        <>
          {record.type !== DataSourceTypeEnum.BlanceToPay && (
            <ButtonIcon
              icon={<SvgBinIcon />}
              buttonProps={{
                onClick: () => {
                  onDeletePaymentTransaction(record.type);
                },
              }}
              className="m-b-7"
            />
          )}
        </>
      ),
    },
  ];

  const dataSource = [
    // cash payment
    paymentInformation?.cashPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.cash' })}
            </span>
          ),
          cashDiscount: formatNumberThousandWithDecimal(0),
          tip: formatNumberThousandWithDecimal(paymentInformation?.cashPayment.tip || 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.cashPayment.amount || 0),
          type: TicketTransactionDTOTypePaymentEnum.Cash,
        }
      : undefined,
    // gift card payment
    paymentInformation?.giftCardPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.giftCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: '',
          amount: formatNumberThousandWithDecimal(paymentInformation?.giftCardPayment?.totalValue ?? 0),
          type: TicketTransactionDTOTypePaymentEnum.GiftCard,
        }
      : undefined,
    //check payment
    paymentInformation?.checkPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.check' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.checkPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.checkPayment?.amount ?? 0),
          type: TicketTransactionDTOTypePaymentEnum.Check,
        }
      : undefined,
    paymentInformation?.keyInCardPayment
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.payAndComplete.title.securityCode' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.keyInCardPayment?.amount ?? 0),
          type: TicketTransactionDTOTypePaymentEnum.CardNumber,
        }
      : undefined,
    paymentInformation?.ortherPayment
      ? {
          key: <span className="font-size-14 font-weight-600">Orther</span>,
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.ortherPayment?.amount ?? 0),
          type: TicketTransactionDTOTypePaymentEnum.Orther,
        }
      : undefined,
    paymentInformation?.multipleChargeCard
      ? {
          key: (
            <span className="font-size-14 font-weight-600">
              {intl.formatMessage({ id: 'checkout.multipleChargeCard' })}
            </span>
          ),
          cashDiscount: '',
          tip: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.tip ?? 0),
          amount: formatNumberThousandWithDecimal(paymentInformation?.multipleChargeCard?.amount ?? 0),
          type: TicketTransactionDTOTypePaymentEnum.MultipleChargeCard,
        }
      : undefined,
    {
      key: (
        <span className="font-size-14 font-weight-600">
          {intl.formatMessage({ id: 'checkout.payAndComplete.title.balanceToPay' })}
        </span>
      ),
      cashDiscount: '',
      tip: '',
      amount: formatNumberThousandWithDecimal(leftBalanceToPay()),
      type: DataSourceTypeEnum.BlanceToPay,
    },
  ];

  const onCloverPayment = () => {
    const tipSettings = settings?.settingTip;
    const terminalDeviceSettings = settings?.settingTerminalDevice;
    const checkoutSetting = settings?.settingCheckout;

    const feeCheckout = Number(settings?.settingCheckout?.feeChargedPerPaymentPercentage || 0);

    let amount = leftBalanceToPay() + (feeCheckout * leftBalanceToPay()) / 100;

    if (
      Number(ticketTransactionPaymentRef.current.amount) &&
      ticketTransactionPaymentRef.current.type === TicketTransactionDTOTypePaymentEnum.MultipleChargeCard
    ) {
      amount =
        Number(ticketTransactionPaymentRef.current.amount) +
        (feeCheckout * Number(ticketTransactionPaymentRef.current.amount)) / 100;
    }

    const ticketServices =
      appointmentBill?.technician?.flatMap((technician) => technician?.services).filter((service) => service) || [];

    const tippableAmount = ticketServices.reduce((total, service) => total + Number(service?.price), 0);

    setTimeout(() => {
      cloverDevice.performSale({
        amount: Number(handleTruncateToTwoDecimal(amount)),
        tippableAmount,
        tipMode:
          !tipSettings?.askCustomerForTipForTechnicians || ticketServices.length === 0
            ? clover.payments.TipMode.NO_TIP
            : terminalDeviceSettings?.tipLocation === 'onPaperReceipt'
            ? clover.payments.TipMode.ON_PAPER
            : clover.payments.TipMode.ON_SCREEN_BEFORE_PAYMENT,
        tipSuggestions: tipSettings?.askCustomerForTipForTechnicians
          ? [
              { enabled: true, name: 'Good', percent: 5 },
              { enabled: true, name: 'Great', percent: 10 },
              { enabled: true, name: 'Wow!', percent: 15 },
              { enabled: true, name: 'Best Service Ever!', percent: 20 },
            ]
          : undefined,
        isDisablePrint:
          !!terminalDeviceSettings?.notCustomerPrint && !!!checkoutSetting?.printTicketSummaryCustomerRequest,
        isDisableDeviceReceiptOptionsScreen: !!terminalDeviceSettings?.notCustomerobtain,
        signatureMode:
          terminalDeviceSettings?.signatureLocation === 'onScreenBeforePayment'
            ? clover.payments.DataEntryLocation.ON_SCREEN
            : terminalDeviceSettings?.signatureLocation === 'onPaperReceipt'
            ? clover.payments.DataEntryLocation.ON_PAPER
            : clover.payments.DataEntryLocation.NONE,
      });
    }, 900);
  };

  const onSaleResponse = (response: clover.remotepay.SaleResponse) => {
    if (response.getSuccess()) {
      if (
        timeSaleResponseRef.current &&
        timeSaleResponseRef.current?.success &&
        dayjs().diff(timeSaleResponseRef.current?.success, 'second') < 5
      )
        return;

      timeSaleResponseRef.current = { ...timeSaleResponseRef.current, success: dayjs() };

      const cardTransaction = response.getPayment().getCardTransaction();

      const cardInformation: CardInformation = {
        cardLast4: cardTransaction.getLast4(),
        cardHolderName: cardTransaction.getCardholderName(),
        cardType: response
          .getPayment()
          .getTender()
          .getLabel()
          .toLowerCase()
          .includes(CardInformationCardTypeEnum.Credit.toLowerCase())
          ? CardInformationCardTypeEnum.Credit
          : CardInformationCardTypeEnum.Debit,
        cardBrand: Object.values(CardInformationCardBrandEnum).find((value) =>
          value.toLowerCase().includes(cardTransaction.getCardType().toLowerCase())
        ),
      };

      createCustomerTransactionMutation.mutate({
        cardInformation,
        paymentMethod: CustomerTransactionDtoPaymentMethodEnum.Clover,
        paymentStatus: CustomerTransactionDtoPaymentStatusEnum.Success,
        customerName: appointmentBill.customer?.name || '',
        customerPhoneNumber: appointmentBill.customer?.phoneNumber || '',
      });
    } else {
      dispatch(setSaleResponse(undefined));

      if (response.getReason() === 'Device Connection Error') {
        setTimeout(() => {
          onCloverPayment();
        }, 300);
      } else {
        if (
          timeSaleResponseRef.current &&
          timeSaleResponseRef.current.error &&
          dayjs().diff(timeSaleResponseRef.current.error, 'second') < 5
        )
          return;

        timeSaleResponseRef.current = { ...timeSaleResponseRef.current, error: dayjs() };

        if (isOpenChargeCard) {
          setIsOpenChargeCard(false);
        } else if (isOpenMultipleCards) {
          setIsOpenMultipleCards(false);
        }

        cloverDevice.performResetDevice();
        NotificationError({ contentNoti: response.getMessage() });
      }
    }
  };

  const onClickCash = () => {
    if (settingCheckout?.securityCode?.secureCashCheckManual) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.Cash,
      });
    } else {
      setIsOpenCashPayment(true);
    }
  };
  const onClickCheck = () => {
    if (settingCheckout?.securityCode?.secureCashCheckManual) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.Check,
      });
    } else {
      setIsOpenCheckPayment(true);
    }
  };
  const onClickChargeCard = async () => {
    if (settingCheckout?.securityCode?.secureCardButton) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.ChargeCard,
      });
    } else {
      settings?.settingTerminalDevice?.deviceConnected === UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Pax
        ? onPaxConnect()
        : onCloverConnect();
    }
  };
  const onClickOther = () => {
    if (settingCheckout?.securityCode?.secureCashCheckManual) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.Orther,
      });
    } else {
      setIsOpenOther(true);
    }
  };

  const onCloverConnect = () => {
    setIsOpenChargeCard(true);

    if (settings?.settingTerminalDevice && settings.settingTerminalDevice.cloverAuthToken) {
      onConnect();
      onCloverPayment();
    }
  };

  const onCloverClose = () => {
    cloverDevice.performResetDevice();
  };

  const onPaxSaleResponse = async (response: any[]) => {
    // const paxResponse = [
    //   null,
    //   '0',
    //   'T01',
    //   '1.44',
    //   '000000',
    //   'OK',
    //   ['0', 'DEMO APPROVED', '000000', '88888888', '', '', ''],
    //   '01',
    //   ['7144', '0', '0', '0', '0', '0', '12048', '2022'],
    //   ['2278', '4', '1226', '', '', '', '01', 'DUY HUNG/PHAM             ', '', '', '0'],
    //   ['51', '1', '20231221213724', ''],
    //   ['', '', '', '', ''],
    //   '',
    //   '',
    //   [
    //     'EDCTYPE=CREDIT',
    //     'CARDBIN=408904',
    //     'PROGRAMTYPE=0',
    //     'SN=53570403',
    //     'GLOBALUID=53570403202312212137241828',
    //     'TC=4A1A3E9307E5FAA5',
    //     'TVR=0000008000',
    //     'AID=A0000000031010',
    //     'TSI=E800',
    //     'ATC=008F',
    //     'APPLAB=VISA CARD',
    //     'APPPN=MB VISA CARD',
    //     'IAD=06010A0360AC02',
    //     'ARC=00',
    //     'CID=40',
    //     'CVM=6',
    //   ],
    // ];
    if (response?.[5] === 'OK') {
      try {
        const cardTransaction = response?.[9] as string[];
        const [firstName, lastName] = cardTransaction[9].trim().split('/');

        let customerTransaction: AxiosResponse<CustomerTransaction> | undefined = undefined;

        if (cardTransaction) {
          const aboutCard = (response?.[14] || response?.[15]) as string[];

          const cardInformation: CardInformation = {
            cardLast4: cardTransaction[0],
            cardHolderName: `${lastName} ${firstName}`,
            cardType: aboutCard?.[0]?.toLowerCase()?.includes(CardInformationCardTypeEnum.Credit.toLowerCase())
              ? CardInformationCardTypeEnum.Credit
              : CardInformationCardTypeEnum.Debit,
            cardBrand:
              Object.values(CardInformationCardBrandEnum)?.find((value) =>
                aboutCard?.[10]?.toLowerCase()?.includes(value.toLowerCase())
              ) || undefined,
          };

          customerTransaction = (await createCustomerTransactionPaxMutation.mutateAsync({
            cardInformation,
            paymentMethod: CustomerTransactionDtoPaymentMethodEnum.Clover,
            paymentStatus: CustomerTransactionDtoPaymentStatusEnum.Success,
            customerName: appointmentBill.customer?.name || '',
            customerPhoneNumber: appointmentBill.customer?.phoneNumber || '',
          })) as AxiosResponse<CustomerTransaction>;
        }

        const ticketTransactions = [...(paymentInformation?.ticketTransaction ?? [])];

        const ticketTransaction: TicketTransactionDTO = {
          money: ticketTransactionPaymentRef.current.amount,
          typePayment: ticketTransactionPaymentRef.current.type,
          status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
          paymentFee: settings?.settingCheckout?.feeChargedPerPaymentPercentage
            ? Number(settings?.settingCheckout?.feeChargedPerPaymentPercentage)
            : undefined,
          customerTransactionId: customerTransaction?.data?.id,
        };

        ticketTransactions.push(ticketTransaction);

        handleSubmit({
          status: TicketDTOStatusEnum.Completed,
          ticketTransaction: ticketTransactions,
        });
      } catch (error: any) {
        NotificationError({ contentNoti: error.response.data.message });
      }
    } else {
      setOpenModal(false);
      const message = response?.[5];
      NotificationError({ contentNoti: message });
    }
  };

  const onPaxConnectSuccess = (response: ConnectPaxResponse) => {
    const settingTerminalDevice = settings?.settingTerminalDevice;

    if (!settingTerminalDevice || (settingTerminalDevice && !settingTerminalDevice.paxDeviceAddress)) return;

    const feeCheckout = Number(settings?.settingCheckout?.feeChargedPerPaymentPercentage || 0);

    let amount = leftBalanceToPay() + (feeCheckout * leftBalanceToPay()) / 100;

    if (ticketTransactionPaymentRef.current.amount) {
      amount =
        Number(ticketTransactionPaymentRef.current.amount) +
        (feeCheckout * Number(ticketTransactionPaymentRef.current.amount)) / 100;
    }

    const pax = paxDevice();

    if (response.responseMessage === 'OK') {
      pax.performSale({
        amount,
        endPoint: settingTerminalDevice.paxDeviceAddress,
        onSaleResponse: onPaxSaleResponse,
      });
    }
  };

  const onPaxConnect = () => {
    setIsOpenChargeCard(true);

    const pax = paxDevice();

    const settingTerminalDevice = settings?.settingTerminalDevice;

    if (!settingTerminalDevice || (settingTerminalDevice && !settingTerminalDevice.paxDeviceAddress)) return;

    pax.connect(
      {
        command: 'A00',
        version: '1.28',
      },
      onPaxConnectSuccess,
      settingTerminalDevice.paxDeviceAddress || ''
    );
  };

  const onClickCardNumber = () => {
    if (settingCheckout?.securityCode?.secureCardButton) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.CardNumber,
      });
    } else {
      setIsOpenCardNumber(true);
    }
  };
  const onClickGiftCard = () => {
    setIsOpenGiftCard(true);
  };

  const handleSubmit = (submitParams?: {
    status?: TicketDTOStatusEnum;
    ticketTransaction?: TicketTransactionDTO[];
    totalTip?: number;
  }) => {
    if (settings?.settingTip?.askEnteringTipWhenCheckout) {
      onChangeCurrentScreen(PayAndCompleteEnum.Tips);
    }
    handleSubmitCheckout({
      ...submitParams,
      totalTip: submitParams?.totalTip ?? tipInformation?.tip,
    });
    resetBill();
  };

  const onSuccessRequirePassword = () => {
    switch (requirePasswordState.modalType) {
      case ModalTypeRequirePasswordEnum.CardNumber:
        setIsOpenCardNumber(true);
        break;
      case ModalTypeRequirePasswordEnum.ChargeCard:
        settings?.settingTerminalDevice?.deviceConnected === UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Pax
          ? onPaxConnect()
          : onCloverConnect();
        break;
      case ModalTypeRequirePasswordEnum.Cash:
        setIsOpenCashPayment(true);
        break;
      case ModalTypeRequirePasswordEnum.Check:
        setIsOpenCheckPayment(true);
        break;
      case ModalTypeRequirePasswordEnum.Orther:
        setIsOpenOther(true);
        break;
    }
    setRequirePasswordState({
      open: false,
      modalType: undefined,
    });
  };

  const onCancelRequirePassword = () => setRequirePasswordState({ open: false, modalType: undefined });

  const getPasswords: () => string[] = () => {
    const arrayPasswords: string[] = [];
    if (settingCheckout?.securityCode?.managerCode) {
      arrayPasswords.push(settingCheckout.securityCode.managerCode);
    } else {
      arrayPasswords.push(settingCheckout?.securityCode?.defaultManagerCode || defaultManagerCode);
    }
    if (
      requirePasswordState.modalType &&
      [ModalTypeRequirePasswordEnum.CardNumber, ModalTypeRequirePasswordEnum.ChargeCard].includes(
        requirePasswordState.modalType
      ) &&
      settingCheckout?.securityCode?.secureCardButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureCardButton);
    }
    if (
      requirePasswordState.modalType &&
      [ModalTypeRequirePasswordEnum.Cash, ModalTypeRequirePasswordEnum.Check].includes(
        requirePasswordState.modalType
      ) &&
      settingCheckout?.securityCode?.secureCashCheckManual
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureCashCheckManual);
    }
    return arrayPasswords;
  };

  const isDisabledCardPayment: (type: TicketTransactionDTOTypePaymentEnum) => boolean = (
    type: TicketTransactionDTOTypePaymentEnum
  ) => {
    if (
      type === TicketTransactionDTOTypePaymentEnum.ChargeCard &&
      (!settingCheckout?.cardPaymentTypeButton || !settings?.settingTerminalDevice || leftBalanceToPay() <= 0)
    ) {
      return true;
    }
    if (
      !settingCheckout?.acceptingPartialCardPayment &&
      paymentInformation?.ticketTransaction?.filter(
        (tran) =>
          !(
            [
              TicketTransactionDTOTypePaymentEnum.CardNumber,
              TicketTransactionDTOTypePaymentEnum.ChargeCard,
            ] as TicketTransactionDTOTypePaymentEnum[]
          ).includes(tran.typePayment)
      ).length
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          {!paymentInformation ? (
            <div className="background-color-F6F9FB d-flex justify-content-between p-l-r-20 p-t-b-4 font-weight-600 font-size-14">
              <span>{intl.formatMessage({ id: 'checkout.balanceToPay' })}</span>
              <span className="p-l-r-80">${formatNumberThousandWithDecimal(listTotal?.totalBalanceTotalPay || 0)}</span>
            </div>
          ) : (
            <TableWrap
              isHidePagination
              setSize={() => {}}
              columns={columns}
              data={dataSource?.filter((data) => data)}
              rowKey="key"
              rootClassName="salon__checkout__pay-n-complete-modal__table"
            />
          )}
        </ColWrap>
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          <CustomButton
            type="primary"
            content={
              <>
                <p className="font-size-20 font-weight-600 color-292F33 m-b-4">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.chargeCard' })}
                </p>
                <p className="font-size-14 font-weight-400 color-78797A m-0">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.chargeCard.subTitle' })}
                </p>
              </>
            }
            buttonProps={{
              className: 'height-132 w-full background-color-0090FF1A',
              onClick: onClickChargeCard,
              disabled: isDisabledCardPayment('Charge_card'),
            }}
          ></CustomButton>
        </ColWrap>
        {/* <ColWrap
          colProps={{
            span: 12,
          }}
        >
          <CustomButton
            type="primary"
            content={
              <>
                <p className="font-size-20 font-weight-600 color-292F33 m-b-4">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.cardNumber' })}
                </p>
                <p className="font-size-14 font-weight-400 color-78797A m-0">
                  {intl.formatMessage({ id: 'checkout.payAndComplete.cardNumber.subTitle' })}
                </p>
              </>
            }
            buttonProps={{
              className: 'height-132 w-full background-color-0090FF1A',
              onClick: onClickCardNumber,
              disabled: isDisabledCardPayment('Card_number'),
            }}
          ></CustomButton>
        </ColWrap> */}
        <ColWrap className="w-full m-t-20-n text-align-right">
          <span
            className="font-size-16 font-weight-700 color-0090FF cursor-pointer"
            onClick={() => setIsOpenMultipleCards(true)}
          >
            {intl.formatMessage({ id: 'checkout.payAndComplete.multipleCreditCards.title' })}
          </span>
        </ColWrap>
        <ColWrap
          className="m-t-20-n"
          colProps={{
            span: 24,
          }}
        >
          <p className="font-size-16 font-weight-500 text-center m-0">
            {intl.formatMessage({ id: 'checkout.payAndComplete.customer' })}{' '}
            <span className="font-weight-700">{intl.formatMessage({ id: 'checkout.payAndComplete.paid' })}</span>{' '}
            {intl.formatMessage({ id: 'checkout.payAndComplete.theAboveAmountWith' })}
          </p>
        </ColWrap>
        <ColWrap
          colProps={{
            span: 24,
          }}
        >
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[12, 12]} isWrap={true} styleFill="between">
            <ColWrap
              colProps={{
                span: 24,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.cash' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickCash,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={`${intl.formatMessage({
                  id: 'checkout.payAndComplete.giftCard',
                })}`}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickGiftCard,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.check' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickCheck,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.other' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickOther,
                }}
              ></CustomButton>
            </ColWrap>
            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'checkout.payAndComplete.cardNumber' })}
                buttonProps={{
                  className: 'height-62 w-full font-weight-700 font-size-16',
                  onClick: onClickCardNumber,
                }}
              ></CustomButton>
            </ColWrap>
          </RowWrap>
        </ColWrap>
      </RowWrap>

      {/* Inner modals */}
      {/* {isOpenChargeCard && (
        <ChargeCard
          listTotal={listTotal}
          open={isOpenChargeCard}
          setOpen={setIsOpenChargeCard}
          setIsOpenPayAndComplete={setOpenModal}
        />
      )} */}
      {isOpenCardNumber && (
        <CardNumber
          listTotal={listTotal}
          open={isOpenCardNumber}
          setOpen={setIsOpenCardNumber}
          setIsOpenPayAndComplete={setOpenModal}
          settingCheckout={settingCheckout}
          appointmentBill={appointmentBill}
          onSubmitCheckout={handleSubmit}
          paymentInformation={paymentInformation}
          onChangePaymentInformation={onChangePaymentInformation}
          setListTotal={setListTotal}
          saveTipAndTechnician={saveTipAndTechnician}
          tipInformation={tipInformation}
          settings={settings}
        />
      )}
      {isOpenCashPayment && (
        <CashPayment
          open={isOpenCashPayment}
          setOpen={setIsOpenCashPayment}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          handleSubmitCheckout={handleSubmit}
          onChangePaymentInformation={onChangePaymentInformation}
          paymentInformation={paymentInformation}
          leftBalanceToPay={leftBalanceToPay()}
          setListTotal={setListTotal}
          saveTipAndTechnician={saveTipAndTechnician}
          tipInformation={tipInformation}
          isLoading={isLoading}
        />
      )}
      {isOpenCheckPayment && (
        <CheckPayment
          open={isOpenCheckPayment}
          setOpen={setIsOpenCheckPayment}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          onChangePaymentInformation={onChangePaymentInformation}
          onSubmitCheckout={handleSubmit}
          saveTipAndTechnician={saveTipAndTechnician}
          tipInformation={tipInformation}
          setListTotal={setListTotal}
          paymentInformation={paymentInformation}
        />
      )}
      {isOpenOther && (
        <Other
          open={isOpenOther}
          setOpen={setIsOpenOther}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          onChangePaymentInformation={onChangePaymentInformation}
          onSubmitCheckout={handleSubmit}
          saveTipAndTechnician={saveTipAndTechnician}
          tipInformation={tipInformation}
          setListTotal={setListTotal}
          paymentInformation={paymentInformation}
        />
      )}
      {isOpenGiftCard && (
        <GiftCard
          open={isOpenGiftCard}
          setOpen={setIsOpenGiftCard}
          setIsOpenPayAndComplete={setOpenModal}
          listTotal={listTotal}
          handleSubmitCheckout={handleSubmit}
          onChangePaymentInformation={onChangePaymentInformation}
          paymentInformation={paymentInformation}
          leftBalanceToPay={leftBalanceToPay()}
          isLoading={isLoading}
          settingCheckout={settingCheckout}
        />
      )}
      {requirePasswordState.open && (
        <RequirePasswordModal
          open={requirePasswordState.open}
          onCancel={onCancelRequirePassword}
          onClickOK={onSuccessRequirePassword}
          passwords={getPasswords()}
          inputProps={{
            inputLabel: intl.formatMessage({
              id: `checkout.payAndComplete.${
                requirePasswordState.modalType &&
                [ModalTypeRequirePasswordEnum.CardNumber, ModalTypeRequirePasswordEnum.ChargeCard].includes(
                  requirePasswordState.modalType
                )
                  ? 'cardButton'
                  : 'cashCheckManual'
              }.label`,
            }),
            inputPlaceholder: intl.formatMessage({
              id: `checkout.payAndComplete.${
                requirePasswordState.modalType &&
                [ModalTypeRequirePasswordEnum.CardNumber, ModalTypeRequirePasswordEnum.ChargeCard].includes(
                  requirePasswordState.modalType
                )
                  ? 'cardButton'
                  : 'cashCheckManual'
              }.placeholder`,
            }),
          }}
        />
      )}
      {isOpenChargeCard &&
        (!settings?.settingTerminalDevice ||
        (settings?.settingTerminalDevice &&
          !settings.settingTerminalDevice.cloverAuthToken &&
          (!settings?.settingTerminalDevice.deviceConnected ||
            settings?.settingTerminalDevice.deviceConnected ===
              UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Clover)) ? (
          <CardPaymentModal
            onCancelModal={() => setIsOpenChargeCard(false)}
            open={isOpenChargeCard}
            amount={leftBalanceToPay()}
            tip={Number(tipInformation?.tip || 0)}
            listSetting={settings}
            onCloverPayment={onCloverPayment}
          />
        ) : (
          <ChargeCard
            listTotal={listTotal}
            open={isOpenChargeCard}
            setOpen={setIsOpenChargeCard}
            setIsOpenPayAndComplete={setOpenModal}
            onCloverClose={onCloverClose}
            onCloverPayment={onCloverPayment}
          />
        ))}

      {isOpenMultipleCards && (
        <MultipleCreditCardsModal
          open={isOpenMultipleCards}
          onCancel={() => {
            setIsOpenMultipleCards(false);
            ticketTransactionPaymentRef.current = {
              type: TicketTransactionDTOTypePaymentEnum.MultipleChargeCard,
              amount: 0,
            };
          }}
          balanceToPay={leftBalanceToPay()}
          onCloverPayment={onCloverPayment}
          ticketTransactionPaymentRef={ticketTransactionPaymentRef}
          onPaxConnect={onPaxConnect}
          settings={settings}
        />
      )}
    </>
  );
};
