import { useMutation, useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import dayjs, { ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import { Dispatch, createContext, useEffect, useState } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { appointmentApi, employeeApi, settingApi } from '../../../apis';
import { Appointment as AppointmentType } from '../../../apis/client-axios';
import {
  Administrator,
  AppointmentStatusEnum,
  BlockTime,
  BlockTimeRepeatTypeEnum,
  Customer,
  CustomerControllerOldCustomerCheckin200Response,
  Employee,
  TechnicianIdsDTO,
  TurnSetting,
} from '../../../apis/client-axios/api';
import AvatarDefault from '../../../assets/images/appointment/avatar-default.png';
import { SvgAppointmentBeingServedIcon } from '../../../components/@svg/SvgAppointmentBeingServedIcon';
import { SvgAppointmentCanceledIcon } from '../../../components/@svg/SvgAppointmentCanceledIcon';
import { SvgAppointmentCheckedInIcon } from '../../../components/@svg/SvgAppointmentCheckedInIcon';
import { SvgAppointmentCompletedIcon } from '../../../components/@svg/SvgAppointmentCompletedIcon';
import { SvgAppointmentConfirmedIcon } from '../../../components/@svg/SvgAppointmentConfirmedIcon';
import NotificationError from '../../../components/HandleShowNotiError';
import { RootState } from '../../../store';
import {
  APPOINTMENT_TIME_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_2,
  DATE_FORMAT_FULL_DATE,
  formatDateByFormatString,
  formatDateTimeZoneByFormatString,
  timeZoneSalon,
} from '../../../utils';
import { QUERY_LIST_APPOINTMENTS, QUERY_LIST_TECHNICIAN } from '../../../utils/constant';
import { Permission } from '../../../utils/permission';
import { RoleCode } from '../employee/employeeConstants';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentFilter from './AppointmentFilter';
import AppointmentModified, { IinitAppointmentModal } from './AppointmentModal';
import AppointmentSidebar from './AppointmentSidebar';
import VirtualCallerID from './AppointmentSidebar/CallerID/virtual';
import {
  APPOINTMENT_SORT,
  AppointmentEvent,
  AppointmentResource,
  CustomAppointment,
  CustomCustomer,
  EventUpsert,
  EventUpsertType,
  Filter,
  RESOURCE_UNASIGNED_KEY,
  RESOURCE_UNASIGNED_NAME,
  SampleCallerID,
  getTechnicianDetail,
} from './models';
import './style.scss';

export const STATUSES = [
  {
    title: 'appointment.status.confirmed',
    icon: <SvgAppointmentConfirmedIcon />,
    key: AppointmentStatusEnum.Confirmed,
    color: '#FFD8D8',
  },
  {
    title: 'appointment.status.checked_in',
    icon: <SvgAppointmentCheckedInIcon />,
    key: AppointmentStatusEnum.CheckedIn,
    color: '#FCEAAA',
  },
  {
    title: 'appointment.status.being_served',
    icon: <SvgAppointmentBeingServedIcon />,
    key: AppointmentStatusEnum.BeingServed,
    color: '#DCFFF9',
  },
  {
    title: 'appointment.status.completed',
    icon: <SvgAppointmentCompletedIcon />,
    key: AppointmentStatusEnum.Completed,
    color: '#DDFFB5',
  },
  {
    title: 'appointment.status.canceled',
    icon: <SvgAppointmentCanceledIcon />,
    key: AppointmentStatusEnum.Canceled,
    color: '#DBDDDF',
  },
  {
    title: 'appointment.status.waiting_confirm',
    icon: <SvgAppointmentCanceledIcon />,
    key: AppointmentStatusEnum.WaitingConfirm,
    color: '#F4D3FE',
  },
];

dayjs.extend(customParseFormat);

export const AppointmentContext = createContext<{
  setting: TurnSetting | undefined;
  onRefetchTechnician: any;
  onRefetchSetting: any;
  onRefetchSortTechnician: any;
}>({
  setting: undefined,
  onRefetchTechnician: () => null,
  onRefetchSetting: () => null,
  onRefetchSortTechnician: () => null,
});

const Appointment = () => {
  const intl = useIntl();

  const authUserString = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
  const authUser: Administrator | Customer | Employee = authUserString ? JSON.parse(authUserString as string) : null;
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);

  const [resources, setResources] = useState<AppointmentResource[]>([]);
  const [events, setEvents] = useState<AppointmentEvent[]>([]);
  const [blockTimeEvents, setBlockTimeEvents] = useState<AppointmentEvent[]>([]);
  const [filter, setFilter] = useState<Filter>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editEvent, setEditEvent] = useState<AppointmentType>();
  const [draggedCaller, setDraggedCaller] = useState<SampleCallerID>();
  type EmployeeExtend = Employee & { isCheckin: boolean };
  const [technicians, setTechnicians] = useState<EmployeeExtend[]>([]);
  const [listAppointments, setListAppointments] = useState<
    AxiosResponse<CustomerControllerOldCustomerCheckin200Response, any> | undefined
  >();
  const [eventUpsert, setEventUpsert] = useState<EventUpsert>();

  const { refetch: onRefetchAppointment, isLoading: isLoadingAppointments } = useQuery({
    queryKey: [QUERY_LIST_APPOINTMENTS, filter?.date],
    queryFn: () =>
      appointmentApi.appointmentControllerGet(
        1,
        filter?.date ?? formatDateTimeZoneByFormatString(DATE_FORMAT, null),
        undefined,
        APPOINTMENT_SORT,
        undefined,
        undefined,
        true
      ),
    onSuccess: (data) => {
      data.data.content?.forEach((item) => {
        item.timeStart = dayjs(
          dayjs(item.timeStart).tz(timeZoneSalon).format(DATE_FORMAT_FULL_DATE),
          DATE_FORMAT_FULL_DATE
        ).toISOString();
      });

      setListAppointments(data);
    },
  });

  const {
    data: listTechnicians,
    refetch: onRefetchTechnician,
    isLoading: isLoadingTechnicians,
  } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(),
    enabled: true,
    staleTime: 1000,
  });

  const {
    data: setting,
    refetch: onRefetchSetting,
    isLoading: isLoadingSettings,
  } = useQuery({
    queryKey: ['settingControllerGetTurn'],
    queryFn: () => settingApi.settingControllerGetTurn(),
    select: (data) => data.data,
    staleTime: 1000,
  });

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians?.data.length > 0) {
      sortTechnician.mutate();
    }
  }, [listTechnicians?.data]);

  // in case login by technician account, dont use this for better performence
  const sortTechnician = useMutation(
    () =>
      employeeApi.employeeControllerSortTechinicians({
        technicianIds: listTechnicians?.data.map((item) => item.id) ?? [],
      }),
    {
      onSuccess: (response) => {
        setTechnicians(response.data as EmployeeExtend[]);
      },
      onError: () => {
        setTechnicians([]);
      },
    }
  );

  const setOpenModal = (state: boolean) => {
    setIsModalOpen(state);
    setTimeout(() => {
      onRefetchAppointment();
    }, 1000);
  };

  useEffect(() => {
    if (eventUpsert) {
      if (eventUpsert.type === EventUpsertType.CREATE) {
        setEvents((prev) => [...prev, ...eventUpsert.data]);
      } else if (eventUpsert.type === EventUpsertType.UPDATE) {
        setEvents((prev) => {
          const copyEvents = _.cloneDeep(prev);
          const findIndexEvent = copyEvents.findIndex((event) => event.id === eventUpsert.data[0].id);

          if (findIndexEvent > -1) {
            copyEvents[findIndexEvent] = eventUpsert.data[0];
          }

          return copyEvents;
        });
      }

      setEventUpsert(undefined);
    }
  }, [eventUpsert]);

  useEffect(() => {
    if (listAppointments?.data.content && listAppointments?.data.content?.length >= 0) {
      const events = listAppointments?.data.content.map((appointment) => {
        const end = dayjs(appointment.timeStart).add(appointment.estimate, 'minutes').toDate();
        const endOfDate = dayjs(appointment.timeStart).endOf('days').toDate();

        const event: AppointmentEvent = {
          id: appointment.id,
          resource: appointment as CustomAppointment,
          resourceId: appointment.technicianId ? appointment.technicianId : RESOURCE_UNASIGNED_KEY,
          start: dayjs(appointment.timeStart).toDate(),
          end: end > endOfDate ? endOfDate : end,
          title:
            appointment.services
              .map((service) => service.name)
              .filter((name) => name)
              .join(', ') || '',
          isBlockTime: false,
          status: appointment.status,
          customerRating: (appointment.customer as CustomCustomer)['customerRating'] || 0,
        };

        return event;
      });

      setEvents(events);
    }
  }, [listAppointments?.data.content]);

  useEffect(() => {
    const { revenue, tasks, hours } = getTechnicianDetail(RESOURCE_UNASIGNED_KEY, events);

    // let listData = Array.isArray(technicians) && technicians?.length !== 0 ? technicians : listTechnicians?.data;
    let listData = listTechnicians?.data ?? [];

    const resources: AppointmentResource[] = [];

    if (listData && authUser?.user?.roles?.[0]?.code === RoleCode.Technician) {
      listData = listData;
    } else {
      resources.push({
        resourceId: RESOURCE_UNASIGNED_KEY,
        revenue,
        tasks,
        hours,
        resourceTitle: RESOURCE_UNASIGNED_NAME,
        name: RESOURCE_UNASIGNED_NAME,
        id: -1,
        phoneNumber: '',
      });
    }

    const isTechnician = authUser?.user?.roles?.find((item) => item.code === RoleCode.Technician);
    if (listData && listData.length >= 0) {
      (technicians ?? listData)
        .filter((data) => (isTechnician ? data.id === authUser.id : true))
        .forEach((technician, index) => {
          const { revenue, tasks, hours } = getTechnicianDetail(technician.id, events);
          const resource: AppointmentResource = {
            index: setting?.assessFillingTurnEarly ? index : undefined,
            resourceId: technician.id,
            revenue,
            tasks,
            hours,
            resourceTitle: technician.name || '',
            avatar:
              technician.defaultAvatar || technician.avatar?.preview || technician.avatar?.source
                ? technician.defaultAvatar ||
                  `${process.env.REACT_APP_API_URL}/static/${technician.avatar?.preview || technician.avatar?.source}`
                : AvatarDefault,
            name: technician.name,
            id: technician.id,
            phoneNumber: technician.phoneNumber,
            resource: technicians.find((item) => item.id === technician.id) ?? undefined,
          };

          resources.push(resource);
        });
    }

    setResources(resources);
  }, [technicians, events, listTechnicians?.data, setting]);

  useEffect(() => {
    if (listTechnicians?.data && listTechnicians.data.length >= 0) {
      const filterTechnicians = listTechnicians.data.filter(
        (technician) => resources.findIndex((resource) => resource.id === technician.id) > -1
      );

      const blockTimes = filterTechnicians.flatMap((technician) =>
        technician.blockTime?.map(
          (e) =>
            ({
              ...e,
              time: dayjs(
                dayjs(e.time).tz(timeZoneSalon).format(DATE_FORMAT_FULL_DATE),
                DATE_FORMAT_FULL_DATE
              ).toISOString(),
              timeEnd: e.timeEnd
                ? dayjs(
                    dayjs(e.timeEnd).tz(timeZoneSalon).format(DATE_FORMAT_FULL_DATE),
                    DATE_FORMAT_FULL_DATE
                  ).toISOString()
                : null,
            } as BlockTime)
        )
      );

      const blockTimeEvents = blockTimes
        .map((blockTime) => {
          const from = {
            hour: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.from, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const to = {
            hour: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).hour(),
            minute: dayjs(blockTime?.to, APPOINTMENT_TIME_FORMAT).minute(),
          };

          const isBlockTimeDeleted =
            blockTime?.blockTimeDeleted &&
            blockTime?.blockTimeDeleted.findIndex(
              (item) =>
                dayjs(item.timeDelete).startOf('days').isSame(dayjs(blockTime?.time).startOf('days')) &&
                item?.blockTimeId === blockTime?.id
            ) > -1;

          if (blockTime?.repeat && blockTime.repeatType && blockTime.repeatValue) {
            let dayToAdd = blockTime.repeatValue;

            const distanceDays = dayjs(filter?.date || formatDateTimeZoneByFormatString(DATE_FORMAT_2, null))
              .startOf('days')
              .diff(
                dayjs(blockTime.time).startOf('days'),
                blockTime.repeatType.toLowerCase() as ManipulateType | undefined
              );

            if (distanceDays > 0 && distanceDays % blockTime.repeatValue === 0) {
              dayToAdd = distanceDays;
            }

            const nextRepeatDay = dayjs(blockTime.time)
              .startOf('days')
              .add(dayToAdd, blockTime.repeatType.toLowerCase() as ManipulateType | undefined);

            const blockTimeDate = dayjs(blockTime.time).date();
            const nextRepeatDate = dayjs(nextRepeatDay).date();

            const isBlockTimeRepeatDeleted =
              blockTime.blockTimeDeleted?.findIndex(
                (item) =>
                  dayjs(item.timeDelete).startOf('days').isSame(dayjs(nextRepeatDay)) &&
                  item.blockTimeId === blockTime.id
              ) === -1;

            const isBlockTimeGreaterThanNextRepeat =
              (blockTime.repeatType === BlockTimeRepeatTypeEnum.Months ||
                blockTime.repeatType === BlockTimeRepeatTypeEnum.Years) &&
              blockTimeDate > nextRepeatDate;

            const isCreateNextRepeat =
              isBlockTimeRepeatDeleted &&
              dayjs(nextRepeatDay).isSame(
                dayjs(filter?.date || formatDateTimeZoneByFormatString(DATE_FORMAT_2, null)).startOf('days')
              ) &&
              (blockTime.timeEnd
                ? dayjs(nextRepeatDay).toDate() <= dayjs(blockTime.timeEnd).startOf('days').toDate()
                : true);

            if (isBlockTimeGreaterThanNextRepeat || !isCreateNextRepeat) {
              if (isBlockTimeDeleted) return undefined;

              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
                end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }

            if (isCreateNextRepeat) {
              const event: AppointmentEvent = {
                title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
                start: dayjs(filter?.date || formatDateTimeZoneByFormatString(DATE_FORMAT_2, null))
                  .set('hour', from.hour)
                  .set('minute', from.minute)
                  .toDate(),
                end: dayjs(filter?.date || formatDateTimeZoneByFormatString(DATE_FORMAT_2, null))
                  .set('hour', to.hour)
                  .set('minute', to.minute)
                  .toDate(),
                id: `blockTime_${blockTime?.id}`,
                resourceId: blockTime?.technicianId || '',
                isBlockTime: true,
                blockTime: blockTime,
              };

              return event;
            }
          }

          if (isBlockTimeDeleted) return undefined;

          const event: AppointmentEvent = {
            title: intl.formatMessage({ id: 'appointment.event.blocktime' }),
            start: dayjs(blockTime?.time).set('hour', from.hour).set('minute', from.minute).toDate(),
            end: dayjs(blockTime?.time).set('hour', to.hour).set('minute', to.minute).toDate(),
            id: `blockTime_${blockTime?.id}`,
            resourceId: blockTime?.technicianId || '',
            isBlockTime: true,
            blockTime: blockTime,
          };

          return event;
        })
        .filter((blockTime) => blockTime);

      setBlockTimeEvents(blockTimeEvents as AppointmentEvent[]);
    }
  }, [listTechnicians?.data, filter?.date, resources, intl]);

  const handleChangeFilter = (newFilter: Filter) => {
    setFilter((prev) => ({
      ...prev,
      ...newFilter,
      date: newFilter?.date || prev?.date || formatDateTimeZoneByFormatString(DATE_FORMAT_2, null),
    }));
  };

  const handleOpenCreateModal = () => {
    if (!salonPermission?.includes(Permission.Appointment.CRUCAppointment))
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' }) });
    else setOpenModal(true);
  };

  const handleRefetch = () => {
    onRefetchAppointment();
    onRefetchTechnician();
  };

  const [initTabData, setInitTabData] = useState<IinitAppointmentModal | undefined>();

  const openCreateModal = (slotInfo?: SlotInfo) => {
    if (!slotInfo) return;

    const time =
      formatDateByFormatString(DATE_FORMAT_FULL_DATE, slotInfo.start) <
      formatDateTimeZoneByFormatString(DATE_FORMAT_FULL_DATE, null)
        ? formatDateTimeZoneByFormatString(DATE_FORMAT_FULL_DATE, null)
        : formatDateByFormatString(DATE_FORMAT_FULL_DATE, slotInfo.start);
    const findResource = resources.find((resource) => resource.resourceId === slotInfo.resourceId);
    if (findResource) {
      setInitTabData({ technicianId: findResource.id, timeStart: time });
      setOpenModal(true);
    }
  };

  const handleDnDCaller = (initTabData: IinitAppointmentModal) => {
    if (!draggedCaller) return;

    setOpenModal(true);

    setInitTabData({
      customerName: draggedCaller.name,
      customerNumber: draggedCaller.phone,
      technicianId: initTabData.technicianId,
      timeStart: initTabData.timeStart,
    });

    setDraggedCaller(undefined);
  };

  return (
    <Spin className="salon__appointment" spinning={isLoadingTechnicians || isLoadingAppointments || isLoadingSettings}>
      <AppointmentContext.Provider
        value={{ setting, onRefetchTechnician, onRefetchSetting, onRefetchSortTechnician: sortTechnician.mutate }}
      >
        <AppointmentSidebar
          openCreateModal={handleOpenCreateModal}
          onRefetch={handleRefetch}
          onChangeDraggedCaller={(caller) => setDraggedCaller(caller)}
          isOpenModalModified={isModalOpen}
          draggedCaller={draggedCaller}
        />

        <div className="salon__appointment-container">
          <AppointmentFilter
            filter={filter || { date: formatDateTimeZoneByFormatString(DATE_FORMAT_2, null) }}
            onChangeFilter={handleChangeFilter}
          />
          <AppointmentCalendar
            filter={filter || { date: formatDateTimeZoneByFormatString(DATE_FORMAT_2, null) }}
            onChangeFilter={handleChangeFilter}
            events={[...events, ...blockTimeEvents]}
            resources={resources}
            onChangeEvents={(events) => setEvents(events.filter((event) => !event.isBlockTime))}
            onRefetch={handleRefetch}
            onChangeEditEvent={(event) => {
              setEditEvent(event);
              if (event !== undefined) setOpenModal(true);
            }}
            setSlotInfor={openCreateModal}
            openModifiedModal={isModalOpen}
            onDnDCaller={handleDnDCaller}
          />
        </div>

        {isModalOpen && (
          <AppointmentModified
            open={isModalOpen}
            setOpenModal={setOpenModal}
            appointment={editEvent}
            initData={initTabData}
            setAppointment={setEditEvent}
            setInitData={setInitTabData}
            onChangeEventUpsert={(event) => setEventUpsert(event)}
          />
        )}
      </AppointmentContext.Provider>

      {draggedCaller && <VirtualCallerID caller={draggedCaller} />}
    </Spin>
  );
};

export default Appointment;
