import React from 'react';

interface IProps {
  className?: string;
}

export const SvgArrowDownSelect: React.FC<IProps> = (props) => {
  return (
    <span {...props}>
      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.254877 1.51143L2.41321 3.66976C2.73821 3.99476 3.26321 3.99476 3.58821 3.66976L5.74654 1.51143C6.27154 0.986426 5.89654 0.0864258 5.15488 0.0864258H0.83821C0.0965435 0.0864258 -0.270123 0.986426 0.254877 1.51143Z"
          fill="#292F33"
        />
      </svg>
    </span>
  );
};
