import React from 'react';

export const SvgRemoveTechnicianIcon = () => (
  <span>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5867 0.41107C11.9121 0.736507 11.9121 1.26414 11.5867 1.58958L1.58665 11.5896C1.26121 11.915 0.733577 11.915 0.40814 11.5896C0.0827033 11.2641 0.0827033 10.7365 0.40814 10.4111L10.4081 0.41107C10.7336 0.0856329 11.2612 0.0856329 11.5867 0.41107Z"
        fill="#292F33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.40814 0.41107C0.733577 0.0856329 1.26121 0.0856329 1.58665 0.41107L11.5867 10.4111C11.9121 10.7365 11.9121 11.2641 11.5867 11.5896C11.2612 11.915 10.7336 11.915 10.4081 11.5896L0.40814 1.58958C0.0827033 1.26414 0.0827033 0.736507 0.40814 0.41107Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
