import { Promotion, Ticket } from '../../../apis/client-axios';

export interface PrintBillSalon {
  name: string;
  address?: string;
  phoneNumber?: string;
}
export interface PrintBillCustomer {
  name?: string;
  phoneNumber?: string;
  isHideCustomerPhoneNumber: boolean;
}

export enum TimePayment {
  BEFORE = 'before',
  AFTER = 'after',
}

export interface PrintBillProps {
  salon: PrintBillSalon;
  customer?: PrintBillCustomer;
  ticket?: Ticket;
  isHideSupplyAmount: boolean;
  timePayment?: TimePayment;
  listPromotion?: Promotion;
}
