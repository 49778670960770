import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SalonSetting } from '../../../../../../../apis/client-axios';
import { SvgSettingIcon } from '../../../../../../../components/@svg/SvgSettingIcon';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import { StyledModal } from '../../../../../../../components/StyledModal';
import { ButtonIcon } from '../../../../../../../components/buttons/ButtonIcon';
import { ButtonStyled } from '../../../../../../../components/buttons/ButtonStyled';
import { RootState, useAppDispatch } from '../../../../../../../store';
import { setIsConnect, setIsDisconnected, setIsLoading } from '../../../../../../../store/cloverSlice';
import { formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { useClover } from '../../../../../setting/terminalDeviceSetting/Clover/connection/hooks';
import ModalTerminalDeviceSettings from '../../../../TerminalDeviceSettings';

interface CardPaymentModalProps {
  open: boolean;
  onCancelModal: () => void;
  amount: number;
  tip: number;
  listSetting?: SalonSetting;
  onCloverPayment: () => void;
}

const CardPaymentModal: FC<CardPaymentModalProps> = (props) => {
  const { open, onCancelModal, amount, listSetting, onCloverPayment } = props;

  const intl = useIntl();

  const { onConnect } = useClover();

  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const [isShowCloverError, setIsShowCloverError] = useState<boolean>(false);
  const [isChangeSettingInModal, setIsChangeSettingInModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: RootState) => state.clover.isLoading);
  const isDisconnected = useSelector((state: RootState) => state.clover.isDisconnected);
  const isDeviceReady = useSelector((state: RootState) => state.clover.isDeviceReady);
  const isConnect = useSelector((state: RootState) => state.clover.isConnect);
  const pairingCode = useSelector((state: RootState) => state.clover.pairingCode);
  const isPairingCodeSuccess = useSelector((state: RootState) => state.clover.isPairingCodeSuccess);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (isConnect && isDisconnected && isShowCloverError) {
      const time = !isChangeSettingInModal ? 1500 : 10000;

      timeout = setTimeout(() => {
        NotificationError({
          contentNoti: intl.formatMessage({ id: 'setting.services.terminalDevice.connectError' }),
        });

        setIsShowCloverError(false);
        setIsChangeSettingInModal(false);
        dispatch(setIsLoading(false));
        dispatch(setIsDisconnected(false));
      }, time);
    }

    if (pairingCode || isPairingCodeSuccess) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isDisconnected,
    dispatch,
    isConnect,
    intl,
    pairingCode,
    isPairingCodeSuccess,
    isShowCloverError,
    isChangeSettingInModal,
  ]);

  useEffect(() => {
    if (isDeviceReady && isConnect) {
      dispatch(setIsLoading(false));
      setIsChangeSettingInModal(false);
    }
  }, [isDeviceReady, dispatch, intl, isConnect]);

  const onCloverConnect = () => {
    dispatch(setIsConnect(true));
    dispatch(setIsDisconnected(false));
    setIsShowCloverError(true);

    onConnect();
    onCloverPayment();
  };

  const onCloseModal = () => {
    dispatch(setIsConnect(false));
    dispatch(setIsDisconnected(false));
    onCancelModal();
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={onCloseModal}
        modalProps={{
          title: intl.formatMessage({ id: 'appointment.modal.cardPayment.heading' }),
          className:
            'salon__appointment-modal-create salon__appointment-modal-create-deposit salon__appointment-modal-create-deposit__card-payment',
          width: 496,
          footer: (
            <div className="d-flex align-items-center justify-content-between">
              <p className="color-78797A font-weight-400 font-size-12 m-b-0">
                {intl.formatMessage(
                  { id: 'appointment.modal.cardPayment.device' },
                  { ip: listSetting?.settingTerminalDevice.cloverDeviceAddress }
                )}
              </p>
              <ButtonStyled
                content={intl.formatMessage({ id: 'appointment.modal.cardPayment.connect' })}
                buttonProps={{
                  className: 'width-100 m-0',
                  onClick: onCloverConnect,
                  disabled: isLoading,
                }}
                isPrimary={true}
              />
            </div>
          ),
          centered: true,
        }}
      >
        <>
          <ButtonIcon
            icon={<SvgSettingIcon fill="#1D2026" />}
            className="position-absolute top-19 right-49"
            buttonProps={{
              disabled: isLoading,
              onClick: () => setOpenSetting(true),
            }}
          />
          {!isLoading ? (
            <div className="text-align-left font-weight-400 font-size-16 m-b-0">
              <p className="color-EB4C3B">
                {intl.formatMessage(
                  { id: 'appointment.modal.cardPayment.pleaseConnectToPay' },
                  { amount: formatNumberThousandWithDecimal(amount) }
                )}
              </p>
              <ol className="m-b-0">
                <li>{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step1' })}</li>
                <li>
                  {intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step2.1' })}
                  <strong>{intl.formatMessage({ id: 'appointment.modal.cardPayment.pleaseConnect.step2.2' })}</strong>
                </li>
              </ol>
            </div>
          ) : (
            <div className="w-full d-flex flex-column justify-content-center align-items-center gap-20">
              <span className="font-weight-400 font-size-16 text-align-left d-block w-full">
                {intl.formatMessage({ id: 'appointment.modal.cardPayment.connectingToTheCloverDevice' })}
              </span>
              <img src="/assets/qr/loading.gif" alt="loading" width={48} height={48} />
            </div>
          )}
        </>
      </StyledModal>

      {openSetting && (
        <ModalTerminalDeviceSettings
          open={openSetting}
          onCancelModal={() => setOpenSetting(false)}
          onChangeSettingInModal={(value) => setIsChangeSettingInModal(value)}
        />
      )}
    </>
  );
};

export default CardPaymentModal;
