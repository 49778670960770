import { useEffect, useState } from 'react';
import { CheckInDTO, Customer, ServiceItems } from '../../../../apis/client-axios';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { formatDateTimeUTC } from '../../../../utils';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import StyledCheckBox from '../../../../components/StyledCheckbox';
import { StyledPopup } from '../../../../components/StyledPopup';
import { MAIN_ROUTES } from '../../routes';
import { customerApi } from '../../../../apis';
import { BOOKING_ONLINE_ROUTES } from '../bookingOnlineConstants';
import { useMutation } from '@tanstack/react-query';
import NotificationError from '../../../../components/HandleShowNotiError';

interface ITabThreeProps {
  receivePromotionViaEmail: boolean;
  receiveMessagesByPhone: boolean;
  setReceivePromotionViaEmail: (receivePromotionViaEmail: boolean) => void;
  setReceiveMessagesByPhone: (receiveMessagesByPhone: boolean) => void;
  isLoading: boolean;
  onFinish: () => void;
}
const StepThree: React.FC<ITabThreeProps> = ({
  setReceivePromotionViaEmail,
  receivePromotionViaEmail,
  setReceiveMessagesByPhone,
  receiveMessagesByPhone,
  isLoading,
  onFinish,
}) => {
  const intl = useIntl();

  return (
    <div className="salon__step-three">
      <span className="salon__step-three-title">{intl.formatMessage({ id: 'bookingOnline.step3.title' })}</span>
      <div className="salon__step-three-main">
        <div className="salon__step-three-main-top">
          <StyledCheckBox
            content={intl.formatMessage({ id: 'bookingOnline.email' })}
            checkboxProps={{
              checked: receivePromotionViaEmail,
              onClick: () => setReceivePromotionViaEmail(!receivePromotionViaEmail),
            }}
          />
          <StyledCheckBox
            content={intl.formatMessage({ id: 'bookingOnline.phone' })}
            checkboxProps={{
              checked: receiveMessagesByPhone,
              onClick: () => setReceiveMessagesByPhone(!receiveMessagesByPhone),
            }}
          />
        </div>
        <ButtonStyled
          content={<span className="salon__handle-text">{intl.formatMessage({ id: 'bookingOnline.complete' })}</span>}
          buttonProps={{
            className: 'width-648 m-t-60 salon__handle-booking-online',
            onClick: () => {
              onFinish();
            },
            loading: isLoading,
            disabled: isLoading,
          }}
          isLarge={true}
          isPrimary={true}
          isButtonCancel={false}
        />
      </div>
    </div>
  );
};

export default StepThree;
