import React from 'react';

export const SvgAddressIcon = () => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/action/room_24px">
        <path
          id="icon/action/room_24px_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.54175 5.40365C3.54175 3.14615 5.75883 1.32031 8.50008 1.32031C11.2413 1.32031 13.4584 3.14615 13.4584 5.40365C13.4584 7.83615 10.3276 11.1903 9.0455 12.4678C8.76216 12.7478 8.24508 12.7478 7.96175 12.4678C6.67258 11.1903 3.54175 7.83615 3.54175 5.40365ZM6.72925 5.40365C6.72925 6.20865 7.52258 6.86198 8.50008 6.86198C9.47758 6.86198 10.2709 6.20865 10.2709 5.40365C10.2709 4.59865 9.47758 3.94531 8.50008 3.94531C7.52258 3.94531 6.72925 4.59865 6.72925 5.40365Z"
          fill="black"
          fill-opacity="0.54"
        />
      </g>
    </svg>
  );
};
