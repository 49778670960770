import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {}

const PaymentChargeCard: React.FC<IProps> = (props) => {
  const intl = useIntl();

  return (
    <div className="height-130 w-full d-flex justify-content-center align-items-center gap-12">
      <img src="/assets/qr/loading.gif" alt="loading" width={48} height={48} />
      <span className="font-weight-400 font-size-16">
        {intl.formatMessage({ id: 'checkout.payAndComplete.pleaseWait' })}
      </span>
    </div>
  );
};

export default PaymentChargeCard;
