import { useMutation } from '@tanstack/react-query';
import { Divider, Form, FormInstance, Image, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { employeeApi } from '../../../../../apis';
import {
  CreateUpdateBlockTimeDTO,
  CreateUpdateBlockTimeDTORepeatTypeEnum,
  Salon,
} from '../../../../../apis/client-axios';
import { SvgAppointmentInputDropDownIcon } from '../../../../../components/@svg/SvgAppointmentInputDropDownIcon';
import { SvgAppointmentInputDropUpIcon } from '../../../../../components/@svg/SvgAppointmentInputDropUpIcon';
import { SvgAppointmentPlusIcon } from '../../../../../components/@svg/SvgAppointmentPlusIcon';
import { SvgAppointmentTimePickerIcon } from '../../../../../components/@svg/SvgAppointmentTimePickerIcon';
import { SvgRemoveServiceItemIcon } from '../../../../../components/@svg/SvgRemoveServiceItemIcon';
import ColWrap from '../../../../../components/ColWrap';
import { FormButtonSubmit } from '../../../../../components/Form/FormButtonSubmit';
import { FormCheckbox } from '../../../../../components/Form/FormCheckbox';
import { FormDatePicker } from '../../../../../components/Form/FormDatePicker';
import { FormInput } from '../../../../../components/Form/FormInput';
import { FormSelect } from '../../../../../components/Form/FormSelect';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../../components/RowWrap';
import { StyledModal } from '../../../../../components/StyledModal';
import StyledPopover from '../../../../../components/StyledPopover';
import { ButtonIcon } from '../../../../../components/buttons/ButtonIcon';
import { CustomButton } from '../../../../../components/buttons/CustomButton';
import { DATE_FORMAT, TIME_FORMAT, timeZoneSalon, toDayjsTimeZone } from '../../../../../utils';
import { datePickerFormat, weekDays } from '../../../../../utils/constant';
import { AppointmentBlockTime, AppointmentResource, RESOURCE_UNASIGNED_KEY } from '../../models';
import MoreTechnicians from './moreTechnicians';
import './style.scss';
import TimeBlockingPicker from './timeBlockingPicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Permission } from '../../../../../utils/permission';

interface BlockTimeModalProps {
  open: boolean;
  blockTime: AppointmentBlockTime;
  technicians: AppointmentResource[];
  onChangeBlockTime: (blockTime: AppointmentBlockTime) => void;
  salonInformation?: Salon;
  onCloseModal: () => void;
  onRefetch: () => void;
  form: FormInstance<IFormData>;
}

export interface IFormData {
  time: Date;
  timeEnd: Date;
  from: Date;
  to: Date;
  repeat: boolean;
  repeatValue?: number;
  technician: AppointmentResource;
  repeatType?: CreateUpdateBlockTimeDTORepeatTypeEnum;
  notes?: string;
  never: boolean;
  on: boolean;
}

const n = (key: keyof IFormData) => key;

const BlockTimeModal: FC<BlockTimeModalProps> = (props) => {
  const { open, blockTime, technicians, onChangeBlockTime, salonInformation, onCloseModal, onRefetch, form } = props;

  const intl = useIntl();
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);
  const repeat = Form.useWatch(n('repeat'), form);
  const repeatValue = Form.useWatch(n('repeatValue'), form);
  const time = Form.useWatch(n('time'), form) as Dayjs | undefined;
  const from = Form.useWatch(n('from'), form);
  const to = Form.useWatch(n('to'), form);
  const on = Form.useWatch(n('on'), form);

  const [openMore, setOpenMore] = useState<boolean>(false);
  const [listMoreTechnicians, setListMoreTechnicians] = useState<AppointmentResource[]>([]);

  useEffect(() => {
    form.setFieldsValue({
      [n('time')]:
        blockTime.time && dayjs(blockTime.time).format(DATE_FORMAT) >= toDayjsTimeZone(null).format(DATE_FORMAT)
          ? dayjs(blockTime.time)
          : (toDayjsTimeZone(null) as Dayjs),
    });
  }, [blockTime.time]);

  useEffect(() => {
    const { timeEnd, time } = form.getFieldsValue();
    if (repeat && (!blockTime.blockTimeEdited || (blockTime.blockTimeEdited && !blockTime.blockTimeEdited.repeat))) {
      const newTimeEnd = time || dayjs(time).isAfter(dayjs(timeEnd)) ? time : timeEnd;

      form.setFieldsValue({
        [n('timeEnd')]: newTimeEnd || toDayjsTimeZone(),
        [n('repeatValue')]: 1,
        [n('repeatType')]: CreateUpdateBlockTimeDTORepeatTypeEnum.Days,
      });
    }
  }, [repeat, blockTime.blockTimeEdited]);

  useEffect(() => {
    if (blockTime.blockTimeEdited) {
      form.setFieldsValue({
        [n('time')]: dayjs(blockTime.blockTimeEdited.time),
        [n('from')]: blockTime.blockTimeEdited.from,
        [n('to')]: blockTime.blockTimeEdited.to,
        [n('notes')]: blockTime.blockTimeEdited.notes,
        [n('repeat')]: blockTime.blockTimeEdited.repeat,
        [n('repeatValue')]: blockTime.blockTimeEdited.repeatValue,
        [n('repeatType')]: blockTime.blockTimeEdited.repeatType,
        [n('timeEnd')]: blockTime.blockTimeEdited.timeEnd ? dayjs(blockTime.blockTimeEdited.timeEnd) : undefined,
        [n('on')]: blockTime.blockTimeEdited.timeEnd ? true : false,
        [n('never')]: blockTime.blockTimeEdited.repeat && !blockTime.blockTimeEdited.timeEnd ? true : false,
      });
    }
  }, [blockTime.blockTimeEdited]);

  useEffect(() => {
    const filter = technicians.filter(
      (technician) =>
        blockTime.technicians.findIndex((selected) => selected.id === technician.id) === -1 &&
        technician.resourceId !== RESOURCE_UNASIGNED_KEY
    );

    setListMoreTechnicians(filter);
  }, [technicians, blockTime.technicians]);

  const createUpdateBlockTimeMutation = useMutation(
    (payload: CreateUpdateBlockTimeDTO) => employeeApi.employeeControllerCreateUpdateBlockTime(payload),
    {
      onSuccess: () => {
        onRefetch();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.event.blocktime.save.success' }) });
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
      onSettled: () => {
        handleCloseModal();
      },
    }
  );

  const repeatEveryOptions = useMemo(() => {
    return [
      {
        label: `${
          Number(repeatValue) > 1
            ? `${intl.formatMessage({ id: 'appointment.modal.form.title.day' })}s`
            : intl.formatMessage({ id: 'appointment.modal.form.title.day' })
        }`,
        value: CreateUpdateBlockTimeDTORepeatTypeEnum.Days,
      },
      {
        label: `${
          Number(repeatValue) > 1
            ? `${intl.formatMessage({ id: 'appointment.modal.form.title.week' })}s`
            : intl.formatMessage({ id: 'appointment.modal.form.title.week' })
        }`,
        value: CreateUpdateBlockTimeDTORepeatTypeEnum.Weeks,
      },
      {
        label: `${
          Number(repeatValue) > 1
            ? `${intl.formatMessage({ id: 'appointment.modal.form.title.month' })}s`
            : intl.formatMessage({ id: 'appointment.modal.form.title.month' })
        }`,
        value: CreateUpdateBlockTimeDTORepeatTypeEnum.Months,
      },
      {
        label: `${
          Number(repeatValue) > 1
            ? `${intl.formatMessage({ id: 'appointment.modal.form.title.year' })}s`
            : intl.formatMessage({ id: 'appointment.modal.form.title.year' })
        }`,
        value: CreateUpdateBlockTimeDTORepeatTypeEnum.Years,
      },
    ];
  }, [repeatValue, intl]);

  const handleChangeTechnicianSelected = (technician: AppointmentResource) => {
    const copyTechnicianSelected = [...blockTime.technicians];
    copyTechnicianSelected.push(technician);

    onChangeBlockTime({ technicians: copyTechnicianSelected });
    setOpenMore(false);
  };

  const handleCloseModal = () => {
    form.resetFields();
    onCloseModal();
  };

  const handleBlockAlphabetCharacter = (e: KeyboardEvent<HTMLInputElement>) => {
    const reg = /^[0-9]+$/;

    if (e.code === 'Backspace' || e.shiftKey || e.altKey || e.ctrlKey) {
      return;
    }

    if (e.code === 'KeyE' || e.code === 'Period' || e.code === 'Comma' || e.code === 'Minus' || !reg.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDisabledDate = (currentDate: Dayjs, name: 'time' | 'timeEnd') => {
    if (name === n('time')) {
      return dayjs(currentDate).format('YYYY-MM-DD') < toDayjsTimeZone(null).format('YYYY-MM-DD');
    } else if (name === n('timeEnd')) {
      return (
        dayjs(currentDate).format('YYYY-MM-DD') <=
        (toDayjsTimeZone(time)
          ? toDayjsTimeZone(time as unknown as Date).format('YYYY-MM-DD')
          : toDayjsTimeZone(null).format('YYYY-MM-DD'))
      );
    }

    return false;
  };

  const onFinish = (formValues: IFormData) => {
    createUpdateBlockTimeMutation.mutate({
      from: formValues.from.toString(),
      to: formValues.to.toString(),
      repeat: !!formValues.repeat,
      // time: dayjs(formValues.time, datePickerFormat).startOf('day').format(DATE_FORMAT_FULL_DATE),
      time: dayjs
        .tz(dayjs(formValues.time).format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon)
        .startOf('day')
        .toISOString(),
      repeatType: formValues.repeatType,
      repeatValue: formValues.repeatValue ? Number(formValues.repeatValue) : undefined,
      technicianId: blockTime.technicians.map((e) => e.id),
      notes: formValues.notes,
      timeEnd: formValues.on
        ? dayjs
            .tz(dayjs(formValues.timeEnd).format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon)
            .startOf('day')
            .toISOString()
        : undefined,
      blockTimeId: blockTime.blockTimeEdited?.id,
    });
  };

  const onFinishFailed = () => {
    NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
  };

  const handleBlurInputNumber = () => {
    const { repeatValue } = form.getFieldsValue();

    if (!repeatValue) {
      form.setFieldsValue({
        [n('repeatValue')]: 1,
      });
    }
  };

  const handleChangeTime = (date: Dayjs | null) => {
    const { timeEnd } = form.getFieldsValue();

    if (dayjs(timeEnd).isBefore(dayjs(date))) {
      form.setFieldsValue({
        [n('timeEnd')]: dayjs(date),
      });
    } else if (dayjs(timeEnd).isAfter(dayjs(date)) && !on) {
      form.setFieldsValue({
        [n('timeEnd')]: dayjs(date),
      });
    }
  };

  const handleRemoveTechnician = (technician: AppointmentResource) => {
    const copyTechnicians = [...blockTime.technicians];
    const findIndex = copyTechnicians.findIndex((tech) => tech.resourceId === technician.resourceId);

    if (findIndex > -1) {
      copyTechnicians.splice(findIndex, 1);
      onChangeBlockTime({ technicians: copyTechnicians });
    }
  };

  const onClickFootersButton = (type: 'cancel' | 'save') => {
    if (!!!salonPermission?.includes(Permission.Appointment.CRUDBlocktime)) {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' }) });
    } else if (type === 'cancel') {
      onChangeBlockTime({
        blockTimeDeleted: {
          id: blockTime.blockTimeEdited?.id,
        },
        technicians: blockTime.technicians,
      });
    } else {
      form.submit();
    }
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={handleCloseModal}
      modalProps={{
        footer: null,
        rootClassName: 'salon__appointment-calendar-blocktime',
        title: (
          <div className="m-l-r-24-n">
            <span className="font-size-20 font-weight-600 salon__appointment-calendar-blocktime-heading">
              {intl.formatMessage(
                { id: 'appointment.modal.blocktime.heading' },
                { heading: !blockTime.blockTimeEdited ? 'Create' : 'Edit' }
              )}
            </span>
          </div>
        ),
        className: 'salon__appointment-modal-create-block-time',
        centered: true,
        width: 400,
      }}
    >
      <RowWrap
        isGutter={false}
        isWrap={false}
        isAutoFillRow={false}
        rowClassName="salon__appointment-calendar-blocktime-list m-l-r-24-n m-t-22-n"
      >
        {blockTime.technicians.map((technician) => (
          <div className="salon__appointment-calendar-blocktime-item-container" key={technician.id}>
            {blockTime.technicians.length > 1 && (
              <ButtonIcon
                icon={<SvgRemoveServiceItemIcon />}
                className="salon__appointment-calendar-blocktime-item-button"
                buttonProps={{
                  onClick: () => handleRemoveTechnician(technician),
                }}
              />
            )}
            <ColWrap
              key={technician.id}
              colProps={{
                className: 'salon__appointment-calendar-blocktime-item-wrapper',
              }}
            >
              <RowWrap
                isGutter={false}
                isWrap={false}
                isAutoFillRow={false}
                rowClassName="salon__appointment-calendar-blocktime-item"
              >
                {technician.resourceId !== RESOURCE_UNASIGNED_KEY && (
                  <ColWrap>
                    <Image
                      alt={technician.resourceTitle}
                      src={technician.avatar}
                      preview={false}
                      width={32}
                      height={32}
                    />
                  </ColWrap>
                )}
                <ColWrap
                  colProps={{
                    className: 'salon__appointment-calendar-blocktime-item-title-wrapper',
                  }}
                >
                  <span className="font-size-16 font-weight-600 salon__appointment-calendar-blocktime-item-title">
                    {technician.resourceTitle}
                  </span>
                </ColWrap>
              </RowWrap>
            </ColWrap>
          </div>
        ))}

        {blockTime.technicians.length <
          technicians.filter((technician) => technician.resourceId !== RESOURCE_UNASIGNED_KEY).length &&
          !blockTime.blockTimeEdited && (
            <StyledPopover
              open={openMore}
              popoverProps={{
                placement: 'bottomRight',
                content: (
                  <MoreTechnicians
                    technicians={listMoreTechnicians}
                    onChangeTechnicianSelected={handleChangeTechnicianSelected}
                  />
                ),
                trigger: 'click',
                arrow: false,
                destroyTooltipOnHide: true,
                overlayClassName: 'salon__appointment-calendar-blocktime-popover',
              }}
            >
              <ButtonIcon
                icon={
                  <div className="salon__appointment-calendar-blocktime-popover-button">
                    <SvgAppointmentPlusIcon
                      fill="#292F33"
                      wrapperClassName="salon__appointment-calendar-blocktime-popover-button-icon"
                    />
                    <span className="font-size-16 font-weight-500 color-0090FF">
                      {intl.formatMessage({ id: 'appointment.modal.blocktime.button.more' })}
                    </span>
                  </div>
                }
                buttonProps={{
                  onClick: () => setOpenMore((prev) => !prev),
                }}
              />
            </StyledPopover>
          )}
      </RowWrap>

      <FormWrap
        name="appointmentBlockTime"
        form={form}
        layout="vertical"
        className="salon__appointment-calendar-blocktime-form m-l-24-n m-r-40-n p-r-16"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <FormDatePicker
          name={n('time')}
          datePickerProps={{
            disabledDate: (currentDate) => handleDisabledDate(currentDate, 'time'),
            format: datePickerFormat,
            allowClear: false,
            inputReadOnly: true,
            onChange: handleChangeTime,
            showToday: false,
          }}
          formItemProps={{
            label: (
              <span className="font-size-14 font-weight-500">
                {intl.formatMessage({ id: 'appointment.modal.form.title.time' })}
              </span>
            ),
          }}
        />

        <RowWrap
          isGutter
          isWrap
          isAutoFillRow={false}
          gutter={[20, 0]}
          className="salon__appointment-calendar-blocktime-form-period"
        >
          <ColWrap
            colProps={{
              span: 12,
            }}
          >
            <TimeBlockingPicker
              name="from"
              time={dayjs.tz(dayjs(time).format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon).startOf('day').toDate()}
              label={
                <span className="font-size-14 font-weight-500">
                  {intl.formatMessage({ id: 'appointment.modal.form.title.from' })}
                </span>
              }
              salonInformation={salonInformation}
              toValue={to as string | undefined}
              placeholder={TIME_FORMAT.toLowerCase()}
              selectProps={{
                className: 'salon__appointment-calendar-blocktime-form-period-common',
                popupClassName: 'salon__appointment-calendar-blocktime-form-popup',
              }}
            />
          </ColWrap>
          <ColWrap
            colProps={{
              span: 12,
            }}
          >
            <TimeBlockingPicker
              name="to"
              time={dayjs.tz(dayjs(time).format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon).startOf('day').toDate()}
              label={
                <span className="font-size-14 font-weight-500">
                  {intl.formatMessage({ id: 'appointment.modal.form.title.to' })}
                </span>
              }
              salonInformation={salonInformation}
              fromValue={from as string | undefined}
              placeholder={TIME_FORMAT.toLowerCase()}
              selectProps={{
                className: 'salon__appointment-calendar-blocktime-form-period-common',
                popupClassName: 'salon__appointment-calendar-blocktime-form-popup',
              }}
            />
          </ColWrap>
        </RowWrap>

        <FormCheckbox
          name={n('repeat')}
          content={
            <span className="font-size-14 font-weight-500">
              {intl.formatMessage({ id: 'appointment.modal.form.title.repeat' })}
            </span>
          }
          formItemProps={{
            className: 'salon__appointment-calendar-blocktime-form-repeat',
          }}
        />

        {repeat && (
          <RowWrap isGutter={false} isWrap isAutoFillRow={false}>
            <ColWrap
              colProps={{
                span: 12,
                className: 'salon__appointment-calendar-blocktime-form-every',
              }}
            >
              <span className="font-size-14 font-weight-500 ">
                {intl.formatMessage({ id: 'appointment.modal.form.title.repeat.sub' }, { sub: 'every' })}
              </span>
            </ColWrap>

            <ColWrap
              colProps={{
                span: 12,
              }}
            >
              <RowWrap isGutter isWrap isAutoFillRow={false} gutter={8}>
                <ColWrap
                  colProps={{
                    span: 10,
                  }}
                >
                  <Form.Item
                    name={n('repeatValue')}
                    className="salon__appointment-calendar-blocktime-form-repeat-every-number"
                  >
                    <InputNumber
                      onKeyDown={handleBlockAlphabetCharacter}
                      min={1}
                      controls={{
                        upIcon: <SvgAppointmentInputDropUpIcon />,
                        downIcon: <SvgAppointmentInputDropDownIcon />,
                      }}
                      onBlur={handleBlurInputNumber}
                      maxLength={9}
                    />
                  </Form.Item>
                </ColWrap>
                <ColWrap
                  colProps={{
                    span: 14,
                    className: 'salon__appointment-calendar-blocktime-form-repeat-every-unit',
                  }}
                >
                  <FormSelect
                    name={n('repeatType')}
                    selectProps={{
                      options: repeatEveryOptions,
                      suffixIcon: <SvgAppointmentTimePickerIcon />,
                      allowClear: false,
                    }}
                  />
                </ColWrap>
              </RowWrap>
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <span className="font-size-14 font-weight-500">
                {intl.formatMessage({ id: 'appointment.modal.form.title.repeat.sub' }, { sub: 'on' })}
              </span>
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <RowWrap isGutter={false} isWrap={false} isAutoFillRow styleFill="between">
                {weekDays.map((day, index) => (
                  <ColWrap key={index}>
                    <span
                      className={`font-size-14 font-weight-600 salon__appointment-calendar-blocktime-form-repeat-on ${
                        weekDays[dayjs(time).day()] === day ? 'active' : ''
                      }`}
                    >
                      {day}
                    </span>
                  </ColWrap>
                ))}
              </RowWrap>
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <span className="font-size-14 font-weight-500 m-b-12">
                {intl.formatMessage({ id: 'appointment.modal.form.title.ends' })}
              </span>
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <FormCheckbox
                name={n('never')}
                content={
                  <span className="font-size-14 font-weight-500">
                    {intl.formatMessage({ id: 'appointment.modal.form.title.never' })}
                  </span>
                }
                formItemProps={{
                  className: 'salon__appointment-calendar-blocktime-form-never',
                }}
                checkboxProps={{
                  onChange: (e: CheckboxChangeEvent) => {
                    const { on } = form.getFieldsValue();

                    if (e.target.checked && on) {
                      form.setFieldsValue({
                        [n('on')]: false,
                      });
                    }
                  },
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 4 }}>
              <FormCheckbox
                name={n('on')}
                content={
                  <span className="font-size-14 font-weight-500">
                    {intl.formatMessage({ id: 'appointment.modal.form.title.on' })}
                  </span>
                }
                checkboxProps={{
                  onChange: (e: CheckboxChangeEvent) => {
                    const { never } = form.getFieldsValue();

                    if (e.target.checked && never) {
                      form.setFieldsValue({
                        [n('never')]: false,
                      });
                    }
                  },
                }}
                formItemProps={{
                  className: 'm-t-5',
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 20 }}>
              <FormDatePicker
                name={n('timeEnd')}
                // datePickerIcon={<SvgAppointmentBlockTimeDatePickerIcon />}
                datePickerProps={{
                  disabled: !Boolean(on),
                  disabledDate: (currentDate) => handleDisabledDate(currentDate, 'timeEnd'),
                  format: datePickerFormat,
                  allowClear: false,
                  inputReadOnly: true,
                  showToday: false,
                }}
              />
            </ColWrap>
          </RowWrap>
        )}
        <FormInput
          name={n('notes')}
          label={
            <span className="font-size-14 font-weight-500">
              {intl.formatMessage({ id: 'appointment.modal.form.title.notes' })}
            </span>
          }
          formItemProps={{
            className: 'salon__appointment-calendar-blocktime-form-notes',
          }}
          inputProps={{
            placeholder: intl.formatMessage({ id: 'appointment.modal.placeholder.enterNote' }),
          }}
        />
      </FormWrap>

      <Divider className="m-b-13 m-t-24 m-l-r-40-n w-auto" />

      <RowWrap
        isGutter={false}
        isAutoFillRow={false}
        isWrap={false}
        rowClassName="salon__appointment-calendar-blocktime-form-buttons m-l-r-24-n p-b-20"
      >
        {blockTime.blockTimeEdited && (
          <ColWrap>
            <CustomButton
              type="cancel"
              content={
                <span className="font-size-16 font-weight-700">{intl.formatMessage({ id: 'common.delete' })}</span>
              }
              buttonProps={{
                onClick: () => onClickFootersButton('cancel'),
              }}
            />
          </ColWrap>
        )}
        <ColWrap>
          <FormButtonSubmit
            content={
              <span className="font-size-16 font-weight-700">
                {intl.formatMessage({ id: 'appointment.modal.form.button.submit' })}
              </span>
            }
            formItemProps={{
              className: 'salon__appointment-calendar-blocktime-form-submit',
            }}
            buttonProps={{
              onClick: () => onClickFootersButton('save'),
              loading: createUpdateBlockTimeMutation.isLoading,
            }}
          />
        </ColWrap>
      </RowWrap>
    </StyledModal>
  );
};

export default BlockTimeModal;
