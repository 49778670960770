import { Form, FormItemProps, Radio, RadioGroupProps } from 'antd';
import React from 'react';
import './formRadio.scss';
import { RadioProps } from 'antd/lib';
type Option = {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
};

type Props = {
  name: string;
  options: Option[];
  formItemProps?: FormItemProps;
  formRadioGroupProps?: RadioGroupProps;
  radioProps?: RadioProps;
};

export const FormRadio: React.FC<Props> = ({ formRadioGroupProps, options, formItemProps, radioProps, ...rest }) => {
  return (
    <Form.Item {...rest} {...formItemProps} className={`salon__form-radio w-100 ${formItemProps?.className ?? ''}`}>
      <Radio.Group {...formRadioGroupProps}>
        {options.map((item) => (
          <Radio
            value={item.value}
            key={item.value}
            disabled={formRadioGroupProps?.disabled || item.disabled}
            {...radioProps}
          >
            {item.label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
