import React from 'react';

import { useIntl } from 'react-intl';
import { SvgBookMarkIcon } from '../@svg/SvgBookMarkIcon';
import { SvgBookMarkIconLarge } from '../@svg/SvgBookMarkIconLarge';
import { SvgClockIcon } from '../@svg/SvgClockIcon';
import { SvgDollarPreviewIcon } from '../@svg/SvgDollarPreviewIcon';
import './serviceItem.scss';
import { Button } from 'antd';
import { SvgRemoveServiceItemIcon } from '../@svg/SvgRemoveServiceItemIcon';
import { formatNumberThousand } from '../../utils';
interface IServiceItem<T extends object> {
  isMerchandise?: boolean;
  isServiceCreate?: boolean;
  name?: string;
  price?: string;
  time?: string;
  backgroundColor?: string;
  className?: string;
  isCheckinOnline?: boolean;
  onClick?: () => void;
  onClickDeleteItem?: () => void;
}
export function ServiceItem<T extends object>(props: IServiceItem<T>) {
  const {
    isMerchandise,
    name,
    price,
    time,
    backgroundColor,
    isServiceCreate,
    className,
    isCheckinOnline,
    onClick,
    onClickDeleteItem,
  } = props;
  const intl = useIntl();
  return (
    <div
      className={'salon__service-item-preview position-relative' + (className ? ' ' + className : '')}
      style={{
        background: backgroundColor,
        border: isServiceCreate ? '1px solid transparent' : '1px solid #000000',
      }}
      onClick={onClick}
    >
      {onClickDeleteItem ? (
        <Button onClick={onClickDeleteItem} className="salon__service-item-remove">
          <SvgRemoveServiceItemIcon />
        </Button>
      ) : (
        ''
      )}

      <div className="salon__service-item-preview-book-mark">
        {isMerchandise ? <SvgBookMarkIconLarge /> : <SvgBookMarkIcon />}
        <span className="salon__service-item-preview-services">
          {isMerchandise
            ? intl.formatMessage({ id: 'setting.services.merchandise' })
            : intl.formatMessage({ id: 'setting.services.service' })}
        </span>
      </div>
      <div className="salon__service-item-preview-title">{name}</div>
      <div className="salon__service-item-preview-detail">
        <div
          className={`d-flex align-items-center color-292F33 ${
            isCheckinOnline ? 'justify-content-center ' : 'justify-content-between '
          }`}
        >
          {price ? (
            <p className="d-flex align-items-center gap-1 salon__service-box-value font-size-12 font-weight-500">
              <p className="m-b-0 m-t-3-n">
                <SvgDollarPreviewIcon />
              </p>
              <p className="salon__service-box-value-item">{formatNumberThousand(price)}</p>
            </p>
          ) : (
            <></>
          )}
          {isMerchandise ? (
            <></>
          ) : (
            <>
              {time ? (
                <p className="d-flex align-items-center gap-1 salon__service-box-value font-size-12 font-weight-500">
                  <p className="m-b-0 m-t-3-n">
                    <SvgClockIcon />
                  </p>
                  <p className="salon__service-box-value-item">{formatNumberThousand(time)}</p>
                  {intl.formatMessage({ id: 'setting.services.minute' })}
                </p>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
