import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Collapse, CollapseProps, Form, Row, Space, Spin, message } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { employeeApi, servicesApi } from '../../../../../apis';
import { ServiceItems } from '../../../../../apis/client-axios';
import { ServiceItem } from '../../../../../components/ServiceItem';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { QUERY_SERVICES, QUERY_TECHNICIAN_SKILLS } from '../../../../../utils/constant';
import '../../style.scss';
import { IFromState, IFromStateInput } from '../index';
import RowWrap from '../../../../../components/RowWrap';
import ColWrap from '../../../../../components/ColWrap';
import StyledCollapse from '../../../../../components/StyledCollapse';
import { getStorageByName } from '../../../../../utils';
import { FormCheckbox } from '../../../../../components/Form/FormCheckbox';
interface Props {
  open: boolean;
  setOpenModal: (state: boolean) => void;
  activeTab?: number;
  tabData?: IFromState[];
  setTabData?: (data: IFromStateInput) => void;
  listServiceProps?: ServiceItems[];
  setListServiceProps?: (services: ServiceItems[]) => void;
  allService?: boolean;
  setAllService?: (state: boolean) => void;
  technicianId?: string;
}

const ServiceModal: FC<Props> = (Props) => {
  const {
    open,
    setOpenModal,
    activeTab,
    tabData,
    setTabData,
    listServiceProps,
    setListServiceProps,
    allService,
    setAllService,
    technicianId,
  } = Props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const [technicianSkill, setTechnicianSkill] = useState<ServiceItems[] | undefined>(undefined);
  const [listSelectedService, setListSelectedService] = useState<ServiceItems[]>([]);
  const formField = 'check';

  const { data: listDataServices, isLoading: loadingService } = useQuery({
    queryKey: [QUERY_SERVICES, technicianId],
    queryFn: () =>
      servicesApi.serviceControllerGetBySalon(0, undefined, undefined, undefined, getStorageByName('salonId')),
    // staleTime: 100000,
  });

  const { data: technicianSkillResponse } = useQuery({
    queryKey: [QUERY_TECHNICIAN_SKILLS, tabData, technicianId],
    queryFn: () =>
      employeeApi.employeeControllerGetByID(tabData?.find((item) => item.tabId === activeTab)?.technicianId || 0),
    enabled: !!tabData?.find((item) => item.tabId === activeTab),
  });

  useEffect(() => {
    if (technicianSkillResponse?.data?.skills) {
      setTechnicianSkill(technicianSkillResponse.data?.skills);
    }
  }, [technicianSkillResponse]);

  const pushSelectedService = (service: ServiceItems) => {
    if (listSelectedService.find((item) => item.id === service.id)) return;
    setListSelectedService([...listSelectedService, service]);
  };

  const removeSelectedService = (service: ServiceItems) => {
    form.setFieldValue(formField, false);
    const updatedList = listSelectedService.filter((item) => item.id !== service.id);
    setListSelectedService(updatedList);
  };

  const handleSave = () => {
    form.validateFields().then((value) => {
      if (setTabData) setTabData({ listSelectedService: listSelectedService });
      if (setListServiceProps) setListServiceProps(listSelectedService);
      if (setAllService) setAllService(value?.[formField]);
      setOpenModal(false);
    });
  };

  useEffect(() => {
    const tab = tabData?.find((item) => item.tabId === activeTab);

    const listSelected = tabData ? tab?.listSelectedService : listServiceProps;
    form.setFieldValue(formField, allService || false);

    if (listSelected && !!listSelected?.length) setListSelectedService(listSelected);
    else setListSelectedService([]);

    if (listServiceProps && allService) {
      form.setFieldValue(formField, true);
      setListSelectedService(listDataServices?.data?.content?.flatMap((service) => service.serviceItems) || []);
    }
  }, [open, listDataServices]);

  const listDataServices2 = useMemo(() => {
    return listDataServices?.data?.content?.map((service) => {
      service.serviceItems = service.serviceItems.filter((item) => {
        return technicianSkill ? item.isMerchandise || technicianSkill.map((item) => item.id).includes(item.id) : true;
      });

      return service;
    });
  }, [listDataServices, technicianSkill]);

  const items: CollapseProps['items'] = useMemo(() => {
    return listDataServices2
      ?.filter((service) => service.serviceItems.length !== 0)
      ?.map((service) => {
        return {
          key: service.id,
          label: (
            <>
              <span className="font-size-16 font-weight-600 color-292F33">{service.name}</span>{' '}
              <span className="font-size-16 font-weight-400 color-56595C">
                {' (' + service.serviceItems.length + ')'}
              </span>
            </>
          ),
          children: (
            <Space size={[8, 8]} wrap>
              {service.serviceItems.map((item) => (
                <ServiceItem
                  isMerchandise={item.isMerchandise}
                  name={item.name}
                  price={item?.price?.toString()}
                  time={item?.time?.toString()}
                  key={item.id}
                  backgroundColor={item.backgroundColor}
                  isServiceCreate={false}
                  onClick={() => pushSelectedService(item)}
                  className={'cursor-pointer' + (listSelectedService.find((x) => x.id === item.id) ? ' active' : '')}
                />
              ))}
            </Space>
          ),
        };
      });
  }, [listDataServices2, listSelectedService]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => {
        setOpenModal(false);
      }}
      modalProps={{
        title: (
          <div className="salon__appointment-modal-heading">
            <span>{intl.formatMessage({ id: 'appointment.modal.service.modal.heading' }, { title: 'Create' })}</span>
          </div>
        ),
        className: 'salon__appointment-modal-create salon__appointment-modal-create-select-services',
        width: 927,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <ButtonStyled
              content={intl.formatMessage({ id: 'common.save' })}
              buttonProps={{
                className: 'width-100 height-38',
                onClick: () => {
                  handleSave();
                },
              }}
              isPrimary={true}
            />
          </div>
        ),
      }}
    >
      <RowWrap isGutter={false} isWrap isAutoFillRow={false}>
        <ColWrap
          colProps={{
            span: 18,
            className: 'height-558',
          }}
          isScrollCol={true}
        >
          {listServiceProps && (
            <Form className="salon__appointment-modal-service-check-box p-t-10" form={form}>
              <FormCheckbox
                name={formField}
                content={intl.formatMessage({ id: 'appointment.modal.service.modal.allService' })}
                checkboxProps={{
                  onChange(e) {
                    if (e.target.checked === true)
                      setListSelectedService(listDataServices2?.flatMap((item) => item.serviceItems) || []);
                    else setListSelectedService([]);
                  },
                }}
              />
            </Form>
          )}
          {loadingService ? (
            <div className="min-height-600 d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          ) : (
            <StyledCollapse
              items={items}
              collapseProps={{
                defaultActiveKey: listDataServices2
                  ?.filter((service) => service.serviceItems.length !== 0)
                  ?.map((item, index: number) => item.id),
              }}
            />
          )}
        </ColWrap>
        <ColWrap
          colProps={{
            span: 6,
            className: 'height-575 p-t-8 p-b-24 salon__appointment-modal-create-col-left',
          }}
          isScrollCol={true}
        >
          <div className="d-flex flex-column">
            <span className="font-size-16 font-weight-600 color-292F33 m-l-9 p-t-12">
              {intl.formatMessage({ id: 'appointment.modal.service.modal.selected' })}
            </span>
            <Space direction="vertical" size={8} className="m-t-15 m-l-9">
              {!!listSelectedService.length &&
                listSelectedService.map((item) => (
                  <ServiceItem
                    isMerchandise={item.isMerchandise}
                    name={item.name}
                    price={item?.price?.toString()}
                    time={item?.time?.toString()}
                    key={item.id}
                    backgroundColor={item.backgroundColor}
                    onClickDeleteItem={() => removeSelectedService(item)}
                  />
                ))}
            </Space>
          </div>
        </ColWrap>
      </RowWrap>
    </StyledModal>
  );
};

export default ServiceModal;
