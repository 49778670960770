import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from 'antd';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  IAppointmentBill,
  IHandleSubmitCheckoutProps,
  IListIdTotalPrice,
  ITipInformation,
  PaymentInformation,
} from '../../..';
import { CreateChargeDto, SalonSetting, SettingCheckout } from '../../../../../../apis/client-axios';
import { StyledModal } from '../../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../../components/buttons/ButtonStyled';
import { CardPaymentCardNumber } from './CardPaymentCardNumber';
import { CardNumberFormData, SecurityCodeCardNumber } from './SecurityCodeCardNumber';

export interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  settingCheckout?: SettingCheckout;
  appointmentBill: IAppointmentBill;
  onSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  paymentInformation?: PaymentInformation;
  onChangePaymentInformation: Dispatch<SetStateAction<PaymentInformation>>;
  tipInformation?: ITipInformation;
  saveTipAndTechnician: (totalTip: number) => void;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  settings?: SalonSetting;
}

export enum CardNumberEnum {
  SecurityCode = 'securityCode',
  CardPayment = 'cardPayment',
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

function CardNumber(props: Props) {
  const {
    open,
    setOpen,
    setIsOpenPayAndComplete,
    listTotal,
    settingCheckout,
    appointmentBill,
    onSubmitCheckout,
    paymentInformation,
    onChangePaymentInformation,
    tipInformation,
    saveTipAndTechnician,
    setListTotal,
    settings,
  } = props;

  const intl = useIntl();

  const [formKeyInCard] = Form.useForm<CardNumberFormData>();

  const [currentScreen, setCurrentScreen] = useState<CardNumberEnum>(CardNumberEnum.SecurityCode);
  const [keyInCardInformation, setKeyInCardInformation] = useState<CreateChargeDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeKeyInCard = (value?: CreateChargeDto) => {
    setKeyInCardInformation(value);
  };

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      case CardNumberEnum.SecurityCode:
        return (
          <Elements stripe={stripePromise}>
            <SecurityCodeCardNumber
              setCurrentScreen={setCurrentScreen}
              listTotal={listTotal}
              settingCheckout={settingCheckout}
              form={formKeyInCard}
              appointmentBill={appointmentBill}
              onChangeKeyInCard={handleChangeKeyInCard}
              onChangeLoading={(value: boolean) => setIsLoading(value)}
            />
          </Elements>
        );
      case CardNumberEnum.CardPayment:
        return (
          <CardPaymentCardNumber
            setCurrentScreen={setCurrentScreen}
            setOpenModal={setOpen}
            setIsOpenPayAndComplete={setIsOpenPayAndComplete}
            listTotal={listTotal}
            keyInCardInformation={keyInCardInformation}
            onSubmitCheckout={onSubmitCheckout}
            paymentInformation={paymentInformation}
            onChangePaymentInformation={onChangePaymentInformation}
            saveTipAndTechnician={saveTipAndTechnician}
            setListTotal={setListTotal}
            tipInformation={tipInformation}
            settings={settings}
          />
        );
    }
  }, [currentScreen]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.${currentScreen}` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: currentScreen === CardNumberEnum.CardPayment ? 832 : 700,
        footer:
          currentScreen === CardNumberEnum.SecurityCode ? (
            <div className="d-flex justify-content-end align-items-center p-r-40 p-b-20">
              <ButtonStyled
                content={intl.formatMessage({
                  id: 'setting.services.cancel',
                })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: () => {
                    setOpen(false);
                    formKeyInCard.resetFields();
                  },
                  disabled: isLoading,
                }}
                isPrimary={false}
                isCancelPopup
              />
              <ButtonStyled
                content={intl.formatMessage({
                  id: 'setting.services.save',
                })}
                buttonProps={{
                  className: 'width-100 height-38 m-l-10',
                  onClick: () => formKeyInCard.submit(),
                  loading: isLoading,
                  disabled: isLoading,
                }}
                isPrimary
              />
            </div>
          ) : null,
      }}
    >
      {renderScreen()}
    </StyledModal>
  );
}

export default CardNumber;
