import { Button, Pagination, PaginationProps } from 'antd';
import React from 'react';
import { PAGE, PER_PAGE } from '../../utils/constant';
import { SvgNextPageDisableIcon } from '../@svg/SvgNextPageDisableIcon';
import { SvgNextPageIcon } from '../@svg/SvgNextPageIcon';
import { SvgPrevPageDisableIcon } from '../@svg/SvgPrevPageDisableIcon';
import { SvgPrevPageIcon } from '../@svg/SvgPrevPageIcon';
import './StyledPagination.scss';
type Props = {
  page: number;
  size: number;
  totalItem: number;
  setPerPage: (perPage: number) => void;
  paginationProps: PaginationProps;
};

const ROW_DISPLAY: number[] = [10, 20, 50, 100];

export const StyledPagination: React.FC<Props> = ({ page, size, totalItem, paginationProps, setPerPage }) => {
  const itemRender = (
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactNode
  ) => {
    if (type === 'prev') {
      return current === 0 ? <SvgPrevPageDisableIcon /> : <SvgPrevPageIcon />;
    }
    if (type === 'next') {
      return current === page ? <SvgNextPageDisableIcon /> : <SvgNextPageIcon />;
    }

    return originalElement;
  };

  return (
    <div className="salon__styled-pagination">
      <div className="salon__styled-pagination-box-container">
        <span>Rows Display:</span>
        <div className="salon__styled-pagination-box-rows">
          {ROW_DISPLAY.map((item, index: number) => (
            <Button
              key={index}
              className={`salon__styled-pagination-btn ${item === size && 'active'}`}
              type="text"
              onClick={() => {
                setPerPage(item);
              }}
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      <Pagination
        total={totalItem}
        showTotal={(total, range) =>
          `${range[0] < 10 ? `0${range[0]}` : range[0]}-${range[1] < 10 ? `0${range[1]}` : range[1]} of ${
            total < 10 ? `0${total}` : total
          }`
        }
        pageSize={size}
        current={page}
        defaultPageSize={PER_PAGE}
        defaultCurrent={PAGE}
        jumpNextIcon={<></>}
        jumpPrevIcon={<></>}
        showPrevNextJumpers={false}
        showSizeChanger={false}
        hideOnSinglePage={false}
        itemRender={itemRender}
        {...paginationProps}
      />
    </div>
  );
};
