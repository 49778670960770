import { FormInstance, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { SettingTerminalDeviceIdForEnum } from '../../../../../apis/client-axios';
import { SvgToolTipIcon } from '../../../../../components/@svg/SvgToolTipIcon';
import ColWrap from '../../../../../components/ColWrap';
import { FormInput } from '../../../../../components/Form/FormInput';
import { FormSelect } from '../../../../../components/Form/FormSelect';
import { FormSwitch } from '../../../../../components/Form/FormSwitch';
import FormWrap from '../../../../../components/FormWrap';
import RowWrap from '../../../../../components/RowWrap';
import { cloverDeviceAddressChecker } from '../../../../../utils';

interface IProps {
  disableInput: boolean;
  form: FormInstance<FormDataClover>;
}

export interface FormDataClover {
  signatureLocation?: string;
  cloverApplicationID?: string;
  tipLocation?: string;
  cloverDeviceAddress?: string;
  notCustomerPrint: boolean;
  notCustomerobtain: boolean;
  IdFor?: SettingTerminalDeviceIdForEnum;
  cloverPosName?: string;
  cloverSerialNumber?: string;
}

const n = (key: keyof FormDataClover) => key;

export const CloverSetting: React.FC<IProps> = (props) => {
  const { disableInput, form } = props;

  const intl = useIntl();

  return (
    <>
      <FormWrap
        form={form}
        name="cloverSetting"
        layout="vertical"
        className="w-100 salon__checkout-tax-ticket-modal-form"
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <div className="m-b-8">
              <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverApplicationID' })}
              </span>
              <br />
              <span className="font-weight-400 font-size-12 color-B4B6B8 ">
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverApplicationID.sub' })}
              </span>
            </div>
            <FormInput
              name={n('cloverApplicationID')}
              formItemProps={{
                className: 'm-b-20',
              }}
              inputProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({
                  id: 'checkout.terminalDeviceSetting.placeholder.cloverApplicationID',
                }),
              }}
            />
            <FormSelect
              name={n('IdFor')}
              formItemProps={{
                className: 'm-b-20',
              }}
              selectProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.terminalconnection' }),
                showSearch: true,
                options: [
                  {
                    value: SettingTerminalDeviceIdForEnum.Production,
                    label: intl.formatMessage({
                      id: 'checkout.terminalDeviceSetting.cloverApplicationID.iDForProduction',
                    }),
                  },
                  {
                    value: SettingTerminalDeviceIdForEnum.Development,
                    label: intl.formatMessage({
                      id: 'checkout.terminalDeviceSetting.cloverApplicationID.iDForDeveloped',
                    }),
                  },
                  {
                    value: SettingTerminalDeviceIdForEnum.LocalTest,
                    label: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverApplicationID.iDLocalTest' }),
                  },
                ],
              }}
            />
            <div className="m-b-8">
              <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverDeviceAddress' })}
              </span>
              <br />
              <span className="font-weight-400 font-size-12 color-B4B6B8 m-b-8">
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverDeviceAddress.sub' })}
                <span className={`${!disableInput ? 'color-0090FF' : ''}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverDeviceAddress.sub-2' })}
                </span>
              </span>
            </div>
            <FormInput
              name={n('cloverDeviceAddress')}
              formItemProps={{
                className: 'm-b-20',
                rules: [
                  {
                    validator: (_, value: string) => {
                      const v = value?.trim();

                      if (v && !cloverDeviceAddressChecker(v)) {
                        return Promise.reject('');
                      }

                      return Promise.resolve();
                    },
                  },
                ],
              }}
              inputProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({
                  id: 'checkout.terminalDeviceSetting.placeholder.cloverDeviceAddress',
                }),
              }}
            />
            <div className="m-b-8">
              <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverPosName' })}
              </span>
              <br />
              <span className="font-weight-400 font-size-12 color-B4B6B8 m-b-8">
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverPosName.sub' })}
                <span className={`${!disableInput ? 'color-0090FF' : ''}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverPosName.sub-2' })}
                </span>
              </span>
            </div>
            <FormInput
              name={n('cloverPosName')}
              formItemProps={{
                className: 'm-b-20',
              }}
              inputProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({
                  id: 'checkout.terminalDeviceSetting.placeholder.cloverPosName',
                }),
              }}
            />
            <div className="m-b-8">
              <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverSerialNumber' })}
              </span>
              <br />
              <span className="font-weight-400 font-size-12 color-B4B6B8 m-b-8">
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverSerialNumber.sub' })}
                <span className={`${!disableInput ? 'color-0090FF' : ''}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverSerialNumber.sub-2' })}
                </span>
              </span>
            </div>
            <FormInput
              name={n('cloverSerialNumber')}
              formItemProps={{
                className: 'm-b-20',
              }}
              inputProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({
                  id: 'checkout.terminalDeviceSetting.placeholder.cloverSerialNumber',
                }),
              }}
            />
            <FormSelect
              name={n('tipLocation')}
              label={
                <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.tipLocation' })}
                </span>
              }
              formItemProps={{
                className: 'm-b-20',
              }}
              selectProps={{
                placeholder: intl.formatMessage({
                  id: 'checkout.terminalDeviceSetting.tipLocation.onScreenBeforePayment',
                }),
                showSearch: true,
                disabled: disableInput,
                options: [
                  {
                    value: 'onPaperReceipt',
                    label: intl.formatMessage({
                      id: 'checkout.terminalDeviceSetting.tipLocation.onPaperReceipt',
                    }),
                  },
                  {
                    value: 'onScreenBeforePayment',
                    label: intl.formatMessage({
                      id: 'checkout.terminalDeviceSetting.tipLocation.onScreenBeforePayment',
                    }),
                  },
                ],
              }}
            />
            <FormSelect
              name={n('signatureLocation')}
              label={
                <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.signatureLocation' })}
                </span>
              }
              formItemProps={{
                className: 'm-b-20',
              }}
              selectProps={{
                placeholder: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.placeholder.signatureLocation' }),
                showSearch: true,
                disabled: disableInput,
                options: [
                  {
                    value: 'onPaperReceipt',
                    label: intl.formatMessage({
                      id: 'checkout.terminalDeviceSetting.tipLocation.onPaperReceipt',
                    }),
                  },
                  {
                    value: 'onScreenBeforePayment',
                    label: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.placeholder.signatureLocation' }),
                  },
                  {
                    value: 'dontNeedSignature',
                    label: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.placeholder.dontneedsohnature' }),
                  },
                ],
              }}
            />
            <FormSwitch
              name={n('notCustomerPrint')}
              content={
                <span className={`${!disableInput ? 'color-292F33' : 'color-B4B6B8'}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.doNotLetCustomersPrint' })}
                  <span className="m-l-8">
                    <Tooltip
                      trigger="hover"
                      title={
                        <span className="font-size-16 font-weight-500">
                          {intl.formatMessage({ id: 'setting.terminalDeviceSetting.tooltip.doNotLetCustomersPrint' })}
                        </span>
                      }
                      overlayInnerStyle={{
                        padding: 12,
                      }}
                      overlayClassName="max-width-442"
                      placement="topLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              className="salon__promotions__fee-n-cash-discounts__switch"
              formItemProps={{
                className: 'm-b-0',
              }}
              switchProps={{
                className: disableInput ? 'salon__custom-switch-disabel' : '',
                disabled: disableInput,
              }}
            />

            <FormSwitch
              name={n('notCustomerobtain')}
              content={
                <span className={`${!disableInput ? 'color-292F33' : 'color-B4B6B8'}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.donotLetCustomersObtain' })}
                  <span className="m-l-8">
                    <Tooltip
                      trigger="hover"
                      title={
                        <span className="font-size-16 font-weight-500">
                          {intl.formatMessage({ id: 'settings.terminalDeviceSetting.tooltip.donotLetCustomersObtain' })}
                        </span>
                      }
                      overlayInnerStyle={{
                        padding: 12,
                      }}
                      overlayClassName="max-width-442"
                      placement="topLeft"
                    >
                      <SvgToolTipIcon />
                      &nbsp;
                    </Tooltip>
                  </span>
                </span>
              }
              className="salon__promotions__fee-n-cash-discounts__switch"
              formItemProps={{
                className: 'm-b-0',
              }}
              switchProps={{
                className: disableInput ? 'salon__custom-switch-disabel' : '',
                disabled: disableInput,
              }}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </>
  );
};
