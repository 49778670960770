import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import { CSSProperties, FC, HTMLAttributes, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Calendar,
  CalendarProps,
  EventProps,
  ResourceHeaderProps,
  SlotInfo,
  SlotPropGetter,
  Views,
  dayjsLocalizer,
} from 'react-big-calendar';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
  OnDragStartArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { employeeApi, settingApi } from '../../../../apis';
import {
  Administrator,
  Appointment,
  AppointmentStatusEnum,
  Customer,
  DeleteBlockTimeDTOTypeEnum,
  Employee,
} from '../../../../apis/client-axios';
import { SvgAppointmentDropDownIcon } from '../../../../components/@svg/SvgAppointmentDropDownIcon';
import NotificationError from '../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../components/HandleShowNotiSuccess';
import StyledPopover from '../../../../components/StyledPopover';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { RootState } from '../../../../store';
import {
  DATE_FORMAT,
  DATE_FORMAT_2,
  DATE_FORMAT_FULL_DATE,
  TIME_FORMAT,
  _fmFullDateTimeUTC,
  formatDateByFormatString,
  formatDateTimeZoneByFormatString,
  formatTimeHHMM,
  formatTimeZoneToDate,
  timeZoneSalon,
  toDayjsTimeZone,
  toStartOfDaysByTimeZone,
} from '../../../../utils';
import { RoleCode, WorkingHours } from '../../employee/employeeConstants';
import { IinitAppointmentModal } from '../AppointmentModal';
import BlockTimeModal, { IFormData } from '../AppointmentModal/BlockTime';
import DeleteBlockTimeContent from '../AppointmentModal/BlockTime/deleteContent';
import TechnicianDetailDay from '../AppointmentModal/TechnicianDetailDay';
import { STATUSES } from '../index';
import {
  AppointmentBlockTime,
  AppointmentEvent,
  AppointmentResource,
  DnDEventMobileRef,
  FILTER_TECHNICIAN,
  FilterProps,
  ID_CHECKOUT_VIRTUAL,
  OpenModalTechnician,
  RESOURCE_UNASIGNED_KEY,
} from '../models';
import Checkout from './Checkout';
import Schedule from './Schedule';
import Technician from './Technician';
import TechniciansFilter from './Technician/filter';
import { QUERY_SETTING } from '../../../../utils/constant';
import { Permission } from '../../../../utils/permission';

interface AppointmentCalendarProps extends FilterProps {
  events: AppointmentEvent[];
  resources: AppointmentResource[];
  onChangeEvents: (events: AppointmentEvent[]) => void;
  onRefetch: () => void;
  onChangeEditEvent: (event?: Appointment) => void;
  setSlotInfor: (slot: SlotInfo) => void;
  openModifiedModal: boolean;
  onDnDCaller: (initTabData: IinitAppointmentModal) => void;
}

dayjs.extend(customParseFormat);

const DnDCalendar = withDragAndDrop<AppointmentEvent, AppointmentResource>(Calendar);

const AppointmentCalendar: FC<AppointmentCalendarProps> = (props) => {
  const {
    filter,
    onChangeFilter,
    setSlotInfor,
    events,
    resources,
    onChangeEvents,
    onRefetch,
    onChangeEditEvent,
    openModifiedModal,
    onDnDCaller,
  } = props;

  const intl = useIntl();
  const filterDate = new Date(filter?.date || new Date());
  const authUserString = sessionStorage.getItem('authUser') || localStorage.getItem('authUser');
  const authUser: Administrator | Customer | Employee = authUserString ? JSON.parse(authUserString as string) : null;
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [openModalTechnician, setOpenModalTechnician] = useState<OpenModalTechnician>({
    filter: false,
  });
  const [draggedEvent, setDraggedEvent] = useState<AppointmentEvent>();
  const [blockTime, setBlockTime] = useState<AppointmentBlockTime>({
    technicians: [],
  });
  const [isCheckoutMobile, setIsCheckoutMobile] = useState<boolean>(false);
  const draggedEventMobileRef = useRef<DnDEventMobileRef>({ isDnD: false });
  const [checkoutList, setCheckoutList] = useState<AppointmentEvent[]>([]);

  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);
  const [form] = Form.useForm<IFormData>();

  const deleteBlockTimeMutation = useMutation(
    (payload: { id: number; type: DeleteBlockTimeDTOTypeEnum }) =>
      employeeApi.employeeControllerDeleteBlockTime(payload.id, {
        type: payload.type,
        timeDeleted: dayjs
          .tz(dayjs(filter?.date).format(DATE_FORMAT), DATE_FORMAT, timeZoneSalon)
          .startOf('day')
          .toISOString(),
      }),
    {
      onSuccess: () => {
        onRefetch();
        form.resetFields();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'appointment.event.blocktime.delete.success' }) });
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
      onSettled: () => {
        setBlockTime({ technicians: [] });
      },
    }
  );

  const filterResources = useMemo(() => {
    if (!filter?.technician || filter?.technician === FILTER_TECHNICIAN.ALL) return resources;
    else if (filter?.technician === FILTER_TECHNICIAN.AT_LEAST_ONE) {
      const resourceIdMap = _.uniq(events.filter((event) => !event.isBlockTime).map((event) => event.resourceId));

      return resources.filter(
        (resource) => resourceIdMap.includes(resource.resourceId) || resource.resourceId === RESOURCE_UNASIGNED_KEY
      );
    } else {
      return resources.filter(
        (resource) => resource.resourceId === filter?.technician || resource.resourceId === RESOURCE_UNASIGNED_KEY
      );
    }
  }, [resources, filter?.technician, events]);

  const filterEvents = useMemo(() => {
    if (checkoutList.length > 0) {
      return events.filter((event) => checkoutList.findIndex((checkout) => checkout.id === event.id) === -1);
    }

    return events;
  }, [checkoutList, events]);

  useEffect(() => {
    if (
      openModalTechnician.filter &&
      (blockTime.technicians.length > 0 || blockTime.blockTimeDeleted || openModifiedModal)
    ) {
      setOpenModalTechnician((prev) => ({ ...prev, filter: false }));
    }
  }, [openModalTechnician.filter, blockTime.technicians, blockTime.blockTimeDeleted, openModifiedModal]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleEnlargeScrollbar = (e: MouseEvent) => {
    const element = document.querySelector('.rbc-time-content');

    if (element) {
      const distanceX = (element as HTMLElement).offsetLeft + (element as HTMLElement).offsetWidth - e.pageX;
      distanceX < -105 && distanceX > -120
        ? element.classList.add('more-width')
        : element.classList.remove('more-width');

      const distanceY = (element as HTMLElement).offsetTop + (element as HTMLElement).offsetHeight - e.pageY;
      distanceY < 20 && distanceY > -15
        ? element.classList.add('more-height')
        : element.classList.remove('more-height');
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleEnlargeScrollbar);
    return () => {
      document.removeEventListener('mousemove', handleEnlargeScrollbar);
    };
  }, []);

  const { formats, view, localizer, timeslots, step } = useMemo<CalendarProps<AppointmentEvent, AppointmentResource>>(
    () => ({
      localizer: dayjsLocalizer(dayjs),
      formats: {
        timeGutterFormat: (date: Date, culture?: string) => dayjsLocalizer(dayjs).format(date, 'hha', culture),
        eventTimeRangeFormat: () => '',
      },
      view: Views.DAY,
      timeslots: 4,
      step: 15,
    }),
    []
  );

  const handleCheckWorkingTime = (
    dateToCheck: Date,
    resources: AppointmentResource[],
    resourceId?: string | number
  ) => {
    const day = dayjs(filter?.date).day();
    const dayIndex = day > 0 ? day - 1 : 6;

    const findTechnician = resources.find((resource) => resource.id === resourceId);

    if (findTechnician || resourceId === RESOURCE_UNASIGNED_KEY) {
      let workingTime: WorkingHours | undefined = findTechnician?.resource?.employeeWorkingHours?.isSameSalon
        ? (salonInformation?.workingTime as WorkingHours | undefined)
        : (findTechnician?.resource?.employeeWorkingHours?.workingTime as WorkingHours | undefined);

      if (resourceId === RESOURCE_UNASIGNED_KEY) {
        workingTime = salonInformation?.workingTime as WorkingHours | undefined;
      }

      if (workingTime) {
        const findWorkingTime = workingTime?.[Object.keys(workingTime)?.[dayIndex] as keyof WorkingHours];

        const dateYMD = formatDateByFormatString(DATE_FORMAT_2, filter.date ?? null);

        if (!findWorkingTime.from && !findWorkingTime.to) return true;

        const from = findWorkingTime.from;
        const to = dayjs(findWorkingTime.to, TIME_FORMAT).subtract(15, 'minutes').format(TIME_FORMAT);

        const formatDate = formatTimeHHMM(dateToCheck);

        return Boolean(
          dayjs(`${dateYMD} ${formatDate}`).isBefore(`${dateYMD} ${from}`) ||
            dayjs(`${dateYMD} ${formatDate}`).isAfter(`${dateYMD} ${to}`)
        );
      }

      return false;
    }

    return false;
  };

  const renderResourceHeader = (props: ResourceHeaderProps<AppointmentResource>) => {
    return (
      props.resource && (
        <Technician
          filter={filter}
          onChangeFilter={onChangeFilter}
          technician={props.resource}
          onOpenDetailDay={(technician) => setOpenModalTechnician({ filter: false, detailDay: technician })}
        />
      )
    );
  };

  const renderEvent = (props: EventProps<AppointmentEvent>) => <Schedule eventProps={props} />;

  const renderEventPropGetter = (event: AppointmentEvent): { className?: string; style?: CSSProperties } => {
    const diff = dayjs(event?.end).diff(event?.start, 'minutes');

    const filteredEvents = events.filter((e) => e.resourceId === event.resourceId);

    const isTimeOverlap = filteredEvents.some(
      (element, index) =>
        element.id !== event.id &&
        ((dayjs(event.start) <= dayjs(element.end) && dayjs(event.start) >= dayjs(element.start)) ||
          (dayjs(event.end) <= dayjs(element.end) && dayjs(event.end) >= dayjs(element.start)) ||
          (dayjs(event.start) <= dayjs(element.start) && dayjs(event.end) >= dayjs(element.end))) &&
        index < filteredEvents.findIndex((e) => e.id === event.id)
    );

    const borderColor = !!isTimeOverlap
      ? '#00297A'
      : !event.isBlockTime
      ? STATUSES.find((status) => status.key === event.resource?.status)?.color
      : '#A0A2A3';

    return {
      style: {
        backgroundColor: !event.isBlockTime
          ? STATUSES.find((status) => status.key === event.resource?.status)?.color
          : '#A0A2A3',
        border: `1px solid ${borderColor}`,
        padding: diff < 10 ? '0 11px' : '8px 11px',
        minHeight: diff < 10 ? 20 : 32,
        zIndex: draggedEvent?.id === event.id ? 6 : 4,
      },
    };
  };

  const renderSlotPropGetter = useCallback<SlotPropGetter>(
    (dateSlot: Date, resourceId?: string | number): HTMLAttributes<HTMLDivElement> => {
      let className = '';

      const convertDate = toDayjsTimeZone(currentDate);

      if (dateSlot.getHours() === convertDate.hour() && convertDate.minute() === 0) {
        className = `${className} rbc-time-slot-custom`;
      }

      const isNotWorkingTime = handleCheckWorkingTime(dateSlot, resources, resourceId);

      if (isNotWorkingTime) {
        className = `${className} rbc-time-slot-blocktime`;
      }

      return {
        className,
      };
    },
    [filter?.date, resources, currentDate, salonInformation?.workingTime]
  );

  const handleEditEvent = (args?: EventInteractionArgs<AppointmentEvent>) => {
    if (!args) return;

    setDraggedEvent(undefined);

    const { event, start, end } = args;

    const resourceId = (args as any).resourceId === RESOURCE_UNASIGNED_KEY ? undefined : (args as any).resourceId;

    const canDrop = STATUSES.map((status) => status.key).filter(
      (status) =>
        status === AppointmentStatusEnum.WaitingConfirm ||
        status === AppointmentStatusEnum.Confirmed ||
        status === AppointmentStatusEnum.CheckedIn
    );

    const findEventsOfTechnician = events.filter(
      (event) =>
        event.resourceId === (args as any).resourceId &&
        event.resource?.status !== AppointmentStatusEnum.Canceled &&
        !event.isBlockTime
    );

    const isTechnicianHaveSchedule = findEventsOfTechnician.some(
      (event) => new Date(event.start) <= start && new Date(event.end) >= start
    );

    if (
      event.isBlockTime ||
      !event.resource?.status ||
      isTechnicianHaveSchedule ||
      (event.resource?.status && !canDrop.includes(event.resource?.status))
    )
      return;

    if (event.resource) {
      onChangeEditEvent({
        ...event.resource,
        timeStart: start.toString(),
        estimate: dayjs(end).diff(dayjs(start), 'minutes'),
        technicianId: resourceId,
      });
    }
  };

  const handleEventDrop = (args: EventInteractionArgs<AppointmentEvent>) => {
    if (draggedEventMobileRef.current.isDnD) {
      draggedEventMobileRef.current = { ...draggedEventMobileRef.current, args };

      return;
    }

    handleEditEvent(args);
  };

  const handleDropFromOutside = (args: DragFromOutsideItemArgs) => {
    const { start } = args;

    const resourceId = (args as any).resource === RESOURCE_UNASIGNED_KEY ? undefined : (args as any).resource;

    if (openModalTechnician.filter) {
      setOpenModalTechnician((prev) => ({ ...prev, filter: false }));
    }

    onDnDCaller({
      technicianId: resourceId,
      timeStart: formatDateTimeZoneByFormatString(DATE_FORMAT_FULL_DATE, start),
    });
  };

  const handleTouchEndToDrop = (event: TouchEvent) => {
    const touches = event.changedTouches;
    const touch = touches[0];
    const simulatedEvent = document.createEvent('MouseEvent');

    simulatedEvent.initMouseEvent(
      'drop',
      true,
      true,
      window,
      1,
      touch.screenX,
      touch.screenY,
      touch.clientX,
      touch.clientY,
      false,
      false,
      false,
      false,
      0,
      null
    );

    touch.target.dispatchEvent(simulatedEvent);

    const checkoutElement = document.getElementById(ID_CHECKOUT_VIRTUAL);

    if (checkoutElement) {
      const checkoutElementPosition = checkoutElement?.getBoundingClientRect();

      if (checkoutElementPosition) {
        const { top, left, right, bottom } = checkoutElementPosition;
        const { pageX, pageY } = touch;

        if (top <= pageY && pageY <= bottom && left <= pageX && pageX <= right) {
          setIsCheckoutMobile(true);
        } else {
          handleEditEvent(draggedEventMobileRef.current.args);
        }
      }
    }

    if (isCheckoutMobile) {
      setIsCheckoutMobile(false);
    }

    const eventElement = document.getElementById(`event_${draggedEventMobileRef.current.args?.event?.id}`);
    eventElement?.removeEventListener('touchend', function (event: TouchEvent) {
      handleTouchEndToDrop(event);
    });

    draggedEventMobileRef.current = { isDnD: false };
  };

  const handleDragStart = (args: OnDragStartArgs<AppointmentEvent>) => {
    const { event } = args;
    setDraggedEvent(event);

    if (!navigator.userAgent.match(/Windows/i)) {
      draggedEventMobileRef.current = { isDnD: true };

      const eventElement = document.getElementById(`event_${event.id}`);

      if (eventElement) {
        eventElement.addEventListener(
          'touchend',
          function (event: TouchEvent) {
            handleTouchEndToDrop(event);
          },
          false
        );
      }
    }
  };

  const handleSelectEvent = (event: AppointmentEvent) => {
    if (event.isBlockTime) {
      const findTechnician = resources.find((resource) => resource.id === event.resourceId);

      if (findTechnician) {
        setBlockTime({
          technicians: [findTechnician],
          blockTimeEdited: event.blockTime,
          time: event?.blockTime?.time,
        });
      }
    } else {
      onChangeEditEvent(event.resource);

      setDraggedEvent(undefined);
      if (openModalTechnician.filter) {
        setOpenModalTechnician((prev) => ({ ...prev, filter: false }));
      }
    }
  };

  const handleOpenBlockTime = (technician: AppointmentResource) => {
    if (!!!salonPermission?.includes(Permission.Appointment.CRUDBlocktime)) {
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' }) });
    } else {
      setOpenModalTechnician({
        filter: false,
        detailDay: undefined,
      });

      setBlockTime({
        technicians: [technician],
        time: filter?.date,
      });
    }
  };

  const handleCloseBlockTimeModal = () => {
    setDraggedEvent(undefined);
    setBlockTime({ technicians: [] });
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    if (!!!salonPermission?.includes(Permission.Appointment.CRUCAppointment))
      NotificationError({ contentNoti: intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' }) });
    else {
      if (openModalTechnician.filter) {
        setOpenModalTechnician((prev) => ({ ...prev, filter: false }));
      }
      setSlotInfor(slotInfo);
    }
  };

  return (
    <div className="salon__appointment-calendar position-relative">
      <DnDCalendar
        localizer={localizer}
        defaultView={view}
        formats={formats}
        toolbar={false}
        date={new Date(toStartOfDaysByTimeZone(filter.date ?? dayjs(), DATE_FORMAT_2))}
        min={new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate(), 8)}
        scrollToTime={new Date(toStartOfDaysByTimeZone(filter.date ?? dayjs(), DATE_FORMAT_2))}
        resources={filterResources}
        resourceIdAccessor={(resource: AppointmentResource) => resource.resourceId}
        resourceTitleAccessor={(resource: AppointmentResource) => resource.resourceTitle}
        events={filterEvents}
        resizable={false}
        timeslots={timeslots}
        step={step}
        className={currentDate.getMinutes() < 10 ? 'rbc-calendar-custom-indicator' : ''}
        onEventDrop={handleEventDrop}
        onDropFromOutside={handleDropFromOutside}
        onDragStart={handleDragStart}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={renderEventPropGetter}
        slotPropGetter={renderSlotPropGetter}
        components={{
          resourceHeader: renderResourceHeader,
          event: renderEvent,
        }}
        selectable
        onSelectSlot={handleSelectSlot}
        showMultiDayTimes
        getNow={() => formatTimeZoneToDate()}
      />

      {!(authUser?.user?.roles?.[0]?.code === RoleCode.Technician) && (
        <StyledPopover
          open={openModalTechnician.filter}
          popoverProps={{
            content: (
              <TechniciansFilter
                filter={filter}
                onChangeFilter={onChangeFilter}
                events={filterEvents}
                onCloseFilter={() => setOpenModalTechnician((prev) => ({ ...prev, filter: false }))}
              />
            ),
            placement: 'bottomLeft',
            trigger: 'click',
            arrow: false,
            overlayClassName: 'salon__appointment-calendar-filter-popover',
          }}
        >
          <ButtonStyled
            isPrimary
            content={
              <p className="font-size-16 font-weight-600 salon__appointment-calendar-filter-button-text">
                {intl.formatMessage({ id: 'appointment.button.all' })}
                <SvgAppointmentDropDownIcon />
              </p>
            }
            buttonProps={{
              onClick: () => setOpenModalTechnician((prev) => ({ ...prev, filter: !prev.filter })),
              className: 'salon__appointment-calendar-filter-button top-0',
            }}
          />
        </StyledPopover>
      )}

      <Checkout
        draggedEvent={draggedEvent}
        onChangeDraggedEvent={(draggedEvent) => setDraggedEvent(draggedEvent)}
        events={filterEvents}
        onChangeEvents={(events) => onChangeEvents(events)}
        isCheckoutMobile={isCheckoutMobile}
        checkoutList={checkoutList}
        onChangeCheckoutList={(checkoutList) => setCheckoutList(checkoutList)}
      />

      {blockTime.technicians.length > 0 && (
        <BlockTimeModal
          open={Boolean(blockTime.technicians.length > 0)}
          blockTime={blockTime}
          technicians={resources}
          form={form}
          onChangeBlockTime={(newBlockTime) => {
            setBlockTime((prev) => ({ ...prev, ...newBlockTime }));
          }}
          salonInformation={salonInformation}
          onCloseModal={handleCloseBlockTimeModal}
          onRefetch={onRefetch}
        />
      )}

      {blockTime.blockTimeDeleted && (
        <StyledPopup
          isOpen={Boolean(blockTime.blockTimeDeleted)}
          content={
            <DeleteBlockTimeContent
              repeat={form.getFieldValue('repeat')}
              onChangeBlockTime={(type) =>
                setBlockTime((prev) => ({ ...prev, blockTimeDeleted: { ...prev.blockTimeDeleted, type } }))
              }
            />
          }
          onCancel={() => setBlockTime((prev) => ({ ...prev, blockTimeDeleted: undefined }))}
          onOk={() =>
            deleteBlockTimeMutation.mutate({
              id: blockTime.blockTimeDeleted?.id || 0,
              type: blockTime.blockTimeDeleted?.type || DeleteBlockTimeDTOTypeEnum.Only,
            })
          }
          buttonPropsOK={{
            loading: deleteBlockTimeMutation.isLoading,
          }}
        />
      )}

      {openModalTechnician.detailDay && (
        <TechnicianDetailDay
          open={Boolean(openModalTechnician.detailDay)}
          technician={openModalTechnician.detailDay}
          onCancel={() => setOpenModalTechnician((prev) => ({ filter: false, detailDay: undefined }))}
          onOpenBlockTime={handleOpenBlockTime}
        />
      )}
    </div>
  );
};

export default AppointmentCalendar;
