import { useIntl } from 'react-intl';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { IListIdTotalPrice } from '../index';
import './style.scss';
import { formatNumberThousandWithDecimal } from '../../../../utils';

interface Props {
  open: boolean;
  onCancelModal: () => void;
  onSubmit: () => void;
}

function ConfirmCloseOut(props: Props) {
  const { open, onCancelModal, onSubmit } = props;
  const intl = useIntl();

  return (
    <StyledPopup
      isOpen={open}
      content={
        <div className="text-align-left line-height-19 p-t-4">
          <div className="salon__checkout-confirm-close-out-title">
            <span>{intl.formatMessage({ id: 'checkout.closeout.contentModal' })}</span>
          </div>
        </div>
      }
      onCancel={() => {
        onCancelModal();
      }}
      onOk={() => {
        onCancelModal();
        onSubmit();
      }}
      isHiddenCancel={false}
      customFooter={
        <div className="d-flex gap-12 justify-content-center p-b-8">
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.no' })}
            buttonProps={{
              className: 'width-100 m-0',
              onClick: async () => {
                onCancelModal();
              },
            }}
            isButtonCancel={true}
            isCancelPopup={false}
            isPrimary={false}
          />
          <ButtonStyled
            content={intl.formatMessage({ id: 'common.OK' })}
            buttonProps={{
              className: 'width-100 m-0',
              onClick: async () => {
                onCancelModal();
                onSubmit();
              },
            }}
            isButtonCancel={false}
            isCancelPopup={false}
            isPrimary={true}
          />
        </div>
      }
    />
  );
}

export default ConfirmCloseOut;
