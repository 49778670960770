import React from 'react';

export const SvgAppointmentDeleteCheckoutIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9118 3.08736C11.1396 3.31516 11.1396 3.68451 10.9118 3.91232L3.91183 10.9123C3.68402 11.1401 3.31468 11.1401 3.08687 10.9123C2.85906 10.6845 2.85906 10.3152 3.08687 10.0874L10.0869 3.08736C10.3147 2.85955 10.684 2.85955 10.9118 3.08736Z"
        fill="#78797A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08687 3.08736C3.31468 2.85955 3.68402 2.85955 3.91183 3.08736L10.9118 10.0874C11.1396 10.3152 11.1396 10.6845 10.9118 10.9123C10.684 11.1401 10.3147 11.1401 10.0869 10.9123L3.08687 3.91232C2.85906 3.68451 2.85906 3.31516 3.08687 3.08736Z"
        fill="#78797A"
      />
    </svg>
  </span>
);
