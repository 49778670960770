import { FormInstance } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './style.scss';
import { formatPhoneNumber, formatPhoneNumber2 } from '../../utils';
import { useNavigate } from 'react-router-dom';

interface IProps<T extends object> {
  form: FormInstance;
  keyboardRef: any;
  inputName?: string | NamePath<any>;
  maxLength?: number;
  keyboardProps?: KeyboardReactInterface['options'];
  formatFunction?: (value: string | number) => string;
  disabledKeyboard?: boolean;
}

function KeyboardBookingOnline<T extends object>(props: IProps<T>) {
  const { keyboardRef, inputName, maxLength, keyboardProps, form } = props;

  const onKeyPress = (button: string, e?: MouseEvent) => {
    if (button === '{backspace}') {
      const currentValue = form.getFieldValue(inputName);
      if (currentValue) {
        form.setFieldValue(inputName, currentValue.slice(0, -1));
      }
    } else if (button === '{cancel}') {
      form.setFieldValue(inputName, '');
    } else if (button === '{back}') {
      const currentValue = form.getFieldValue(inputName);
      if (currentValue) {
        form.setFieldValue(inputName, currentValue.slice(0, -1));
      }
    } else {
      const currentValue = form.getFieldValue(inputName) || '';
      const newValue = `${currentValue}${button}`;
      form.setFieldValue(inputName, inputName === 'phoneNumber' ? formatPhoneNumber2(newValue) : newValue);
    }
  };
  const customLayout = {
    default: ['1 2 3', '4 5 6', '7 8 9', '{cancel} 0 {back}'],
  };

  const customDisplay = {
    '{cancel}': ' ',
    '{back}': ' ',
  };

  const customTheme = [
    { class: 'salon__keyboard-back', buttons: '{back}' },
    { class: 'salon__keyboard-close', buttons: '{cancel}' },
  ];

  return (
    <div className="salon__keyboard">
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        maxLength={maxLength}
        layout={customLayout}
        buttonTheme={customTheme}
        onKeyPress={onKeyPress}
        display={customDisplay}
        inputName={inputName}
        preventMouseDownDefault={true}
        {...keyboardProps}
      />
    </div>
  );
}

export default KeyboardBookingOnline;
