import React from 'react';

export const SvgDollarIcon = () => (
  <span>
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.35227 15.4545V0.90909H5.28409V15.4545H4.35227ZM6.82386 5.71023C6.77841 5.25189 6.58333 4.89583 6.23864 4.64205C5.89394 4.38826 5.42614 4.26136 4.83523 4.26136C4.43371 4.26136 4.0947 4.31818 3.81818 4.43182C3.54167 4.54167 3.32955 4.69508 3.18182 4.89205C3.03788 5.08902 2.96591 5.3125 2.96591 5.5625C2.95833 5.77083 3.00189 5.95265 3.09659 6.10795C3.19508 6.26326 3.32955 6.39773 3.5 6.51136C3.67045 6.62121 3.86742 6.7178 4.09091 6.80114C4.31439 6.88068 4.55303 6.94886 4.80682 7.00568L5.85227 7.25568C6.35985 7.36932 6.82576 7.52083 7.25 7.71023C7.67424 7.89962 8.04167 8.13258 8.35227 8.40909C8.66288 8.68561 8.90341 9.01136 9.07386 9.38636C9.24811 9.76136 9.33712 10.1913 9.34091 10.6761C9.33712 11.3883 9.1553 12.0057 8.79545 12.5284C8.43939 13.0473 7.92424 13.4508 7.25 13.7386C6.57955 14.0227 5.77083 14.1648 4.82386 14.1648C3.88447 14.1648 3.06629 14.0208 2.36932 13.733C1.67614 13.4451 1.13447 13.0189 0.744318 12.4545C0.357955 11.8864 0.155303 11.1837 0.136364 10.3466H2.51705C2.54356 10.7367 2.6553 11.0625 2.85227 11.3239C3.05303 11.5814 3.32008 11.7765 3.65341 11.9091C3.99053 12.0379 4.37121 12.1023 4.79545 12.1023C5.21212 12.1023 5.57386 12.0417 5.88068 11.9205C6.19129 11.7992 6.43182 11.6307 6.60227 11.4148C6.77273 11.1989 6.85795 10.9508 6.85795 10.6705C6.85795 10.4091 6.7803 10.1894 6.625 10.0114C6.47348 9.83333 6.25 9.68182 5.95455 9.55682C5.66288 9.43182 5.30492 9.31818 4.88068 9.21591L3.61364 8.89773C2.63258 8.65909 1.85795 8.28598 1.28977 7.77841C0.721591 7.27083 0.439394 6.58712 0.443182 5.72727C0.439394 5.02273 0.626894 4.4072 1.00568 3.88068C1.38826 3.35417 1.91288 2.94318 2.57955 2.64773C3.24621 2.35227 4.00379 2.20455 4.85227 2.20455C5.71591 2.20455 6.4697 2.35227 7.11364 2.64773C7.76136 2.94318 8.26515 3.35417 8.625 3.88068C8.98485 4.4072 9.17045 5.01705 9.18182 5.71023H6.82386Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
