import { Form, QRCode } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SvgLogoutCheckinIcon } from '../@svg/SvgLogoutCheckinIcon';
import { ButtonIcon } from '../buttons/ButtonIcon';
import { RootState, useAppDispatch } from '../../store';
import { logout } from '../../store/authSlice';
import { MAIN_ROUTES } from '../../routes/tenant/routes';
import { BOOKING_ONLINE_ROUTES } from '../../routes/tenant/bookingOnline/bookingOnlineConstants';
import './checkInQRCode.scss';
import { CreateAppointmentDTO, SettingCheckin } from '../../apis/client-axios';
import ScreenSaver from '../../routes/tenant/bookingOnline/screenSaver';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { QUERY_SETTING } from '../../utils/constant';
import { customerApi, settingApi } from '../../apis';
import KeyboardBookingOnline from '../Keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import { ButtonStyled } from '../buttons/ButtonStyled';
import { FormInputNumber } from '../Form/FormInputNumber';
import { FormInput } from '../Form/FormInput';
import FormWrap from '../FormWrap';
import NotificationError from '../HandleShowNotiError';
import { useNavigate } from 'react-router-dom';

interface IProps {
  checkIn?: boolean;
  bookingOnline?: boolean;
  setStatusShowCheckin: (status: boolean) => void;
  setDataPhoneCheckin: (phone: string) => void;
  setStep: (step: number) => void;
  dataPhoneCheckin?: string;
}

const CheckInQRCode: React.FC<IProps> = ({ ...props }) => {
  const { checkIn, bookingOnline, setStatusShowCheckin, setDataPhoneCheckin, setStep, dataPhoneCheckin } = props;
  const intl = useIntl();
  const [status, setStatus] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [form] = Form.useForm();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputName, setInputName] = useState<string | undefined>('phoneNumber');
  const salonInformation = useSelector((state: RootState) => state.auth.salonActive);
  const dispatch = useAppDispatch();

  const { data: setting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1366) {
      let myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 120000);

      const onMouseDown = () => {
        clearInterval(myInterval);
        myInterval = setInterval(() => {
          if (!status) setStatus(true);
        }, 120000);
      };

      document.addEventListener('mousedown', onMouseDown);

      return () => {
        document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, []);

  const onNext = async () => {
    await form
      .validateFields()
      .then((data) => {
        const phoneNumber = form.getFieldValue('phoneNumber');
        setStatusShowCheckin(false);
        setDataPhoneCheckin(phoneNumber);
        setStep(0);
      })
      .catch(() => {
        NotificationError({ contentNoti: intl.formatMessage({ id: 'common.errorRequiredFields' }) });
      });
  };
  useEffect(() => {
    form.setFieldValue('phoneNumber', dataPhoneCheckin?.trim());
  }, [dataPhoneCheckin]);
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  return (
    <section className="salon__qr-code">
      <div className="salon__qr-code-logo">
        <picture>
          <img src="/assets/qr/logo.png" alt="" />
        </picture>
      </div>
      {checkIn ? (
        <div className="position-absolute top-20 right-20">
          <ButtonIcon
            icon={
              <div className="d-flex gap-10">
                <SvgLogoutCheckinIcon />
                <span className="font-weight-500 text-white">{intl.formatMessage({ id: 'common.logout' })}</span>
              </div>
            }
            buttonProps={{
              onClick: () => {
                dispatch(logout());
              },
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="salon__qr-code-main">
        <div className="salon__qr-code-main-left">
          <div className="salon__qr-code-main-left-text">
            {setting?.data?.settingCheckin?.flashTitle && (
              <p className="salon__qr-title-salon">{setting?.data?.settingCheckin?.flashTitle}</p>
            )}
          </div>
          {checkIn ? (
            <div className=" m-auto">
              {setting?.data?.settingCheckin?.allowCustomersCheckinFromPhone ? (
                <div className="salon__qr-code__image">
                  <div className="salon__qr-code-container">
                    <QRCode
                      value={`${process.env.REACT_APP_URL}/${MAIN_ROUTES.BOOKING_ONLINE}${BOOKING_ONLINE_ROUTES.CHECKINBYQRCODE}/${salonInformation?.id}`}
                      color={'#000'}
                      className="salon__qr-code-qr-scan"
                    />
                  </div>
                  <p className="salon__qr-code__image-des">
                    {' '}
                    {intl.formatMessage({ id: 'bookingOnline.checkin.qr.des' })}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {bookingOnline ? (
            <div className="salon__qr-code-main-left-slice ">
              <ScreenSaver
                status={status}
                setStatus={setStatus}
                screenSaver={setting?.data.settingCheckin?.screenSaver}
                salonHome={false}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="salon__qr-code-main-right">
          {checkIn ? (
            <div>
              <span className="salon__qr-code-main-right-text">{setting?.data?.settingCheckin?.flashMessage}</span>
            </div>
          ) : (
            <></>
          )}
          {bookingOnline ? (
            <div>
              <span className="salon__qr-code-main-right-text">
                {intl.formatMessage({ id: 'common.pleaseBookingHere' })}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="m-t-50 width-400 salon__qr-code-main-right-form">
            <FormWrap
              name="customer"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              requiredMark={false}
              form={form}
            >
              <FormInput
                name={'phoneNumber'}
                inputProps={{
                  id: 'phoneNumber',
                  className: 'font-weight-700 salon__qr-code-main-right-custom-input font-size-45',
                  inputMode: 'none',
                  placeholder: '000-000-0000',
                  onFocus: () => {
                    setInputName('phoneNumber');
                  },

                  maxLength: 16,
                  // onBlur: () => {
                  //   setInputName('output');
                  // },
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) {
                      keyboardRef.current?.handleButtonClicked(value);
                    }
                    return event.preventDefault();
                  },
                  ref: inputRef,
                }}
                formItemProps={{
                  required: true,
                  rules: [
                    {
                      validator: (_, value) => {
                        const v = value;
                        if (!v) {
                          return Promise.reject();
                        }
                        if (v && v.replace(/[-\s]/g, '').length < 10) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ],
                }}
              />
              <div className="m-t-10">
                <KeyboardBookingOnline
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={16}
                  inputName={inputName}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                />
              </div>
              <ButtonStyled
                content={
                  <span className="font-weight-400 font-size-40 text-transform-capitalize">
                    {intl.formatMessage({ id: 'common.next' })}
                  </span>
                }
                heightButton={61}
                buttonProps={{
                  className: 'width-400 salon__qr-code-main-right-custom-button m-t-30 m-b-20',
                  htmlType: 'submit',
                  onClick: onNext,
                }}
                isPrimary={true}
              />
            </FormWrap>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckInQRCode;
