import React from 'react';

interface IProps {
  className?: string;
}

export const SvgPointRewardIcon = (props: IProps) => (
  <span {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1154 10.3655C11.9189 10.3655 11.7253 10.3177 11.5513 10.2263C11.3774 10.1348 11.2284 10.0023 11.117 9.84035L10.5242 8.97799C9.82579 10.2302 8.49817 11.0386 7.00003 11.0386C4.90955 11.0386 3.18492 9.44185 2.98159 7.404H3.36542C3.43901 7.40401 3.51121 7.38391 3.5742 7.34587C3.6372 7.30783 3.68861 7.2533 3.72287 7.18817C3.75713 7.12304 3.77294 7.04979 3.76859 6.97632C3.76424 6.90286 3.7399 6.83198 3.69819 6.77135L3.10948 5.91505L2.21742 4.61748C2.18031 4.56349 2.13063 4.51934 2.07265 4.48883C2.01468 4.45833 1.95015 4.44239 1.88464 4.44238C1.81913 4.44238 1.7546 4.45832 1.69662 4.48882C1.63864 4.51932 1.58895 4.56346 1.55184 4.61745L0.0710707 6.77132C0.0293776 6.83196 0.00504574 6.90284 0.000703714 6.9763C-0.00363832 7.04977 0.0121745 7.12302 0.046434 7.18815C0.0806935 7.25328 0.132097 7.30781 0.195093 7.34585C0.258088 7.38389 0.330281 7.404 0.403872 7.404H0.82059C0.916676 8.90602 1.54758 10.3049 2.62142 11.3788C3.791 12.5484 5.34601 13.1925 7.00003 13.1925C8.65405 13.1925 10.2091 12.5484 11.3786 11.3788C11.6877 11.0697 11.9635 10.729 12.2013 10.3622C12.1727 10.3643 12.144 10.3654 12.1154 10.3655Z"
        fill="#0090FF"
      />
      <path
        d="M7.30654 6.5962H6.69117C6.57452 6.5962 6.47964 6.50131 6.47964 6.38466V6.07697C6.47964 5.96034 6.57452 5.86546 6.69117 5.86546H7.30654C7.42319 5.86546 7.51807 5.96034 7.51807 6.07697C7.51807 6.30001 7.69889 6.4808 7.92191 6.4808C8.14493 6.4808 8.32575 6.29998 8.32575 6.07697C8.32575 5.54743 7.91975 5.11105 7.40265 5.06243V4.84622C7.40265 4.62318 7.22183 4.44238 6.99881 4.44238C6.77577 4.44238 6.59497 4.62321 6.59497 4.84622V5.06243C6.0779 5.11105 5.67188 5.54743 5.67188 6.07697V6.38466C5.67188 6.94669 6.12909 7.4039 6.69111 7.4039H7.30648C7.42313 7.4039 7.51802 7.49879 7.51802 7.61543V7.92313C7.51802 8.03975 7.42311 8.13466 7.30648 8.13466H6.69111C6.57446 8.13466 6.47958 8.03978 6.47958 7.92316C6.47958 7.70012 6.29876 7.51932 6.07574 7.51932C5.85273 7.51932 5.6719 7.70015 5.6719 7.92316C5.6719 8.4527 6.0779 8.88908 6.595 8.9377V9.15388C6.595 9.37692 6.77582 9.55771 6.99884 9.55771C7.22188 9.55771 7.40268 9.37689 7.40268 9.15388V8.93767C7.91972 8.88905 8.32578 8.45267 8.32578 7.92313V7.61543C8.32575 7.05341 7.86856 6.5962 7.30654 6.5962Z"
        fill="#0090FF"
      />
      <path
        d="M13.9517 6.81192C13.9174 6.7468 13.866 6.69227 13.803 6.65423C13.74 6.61618 13.6678 6.59608 13.5943 6.59607H13.1775C13.0814 5.09405 12.4505 3.69514 11.3767 2.62127C10.2072 1.45173 8.65212 0.807617 6.99812 0.807617C5.34413 0.807617 3.78906 1.45173 2.61951 2.6213C2.31042 2.93039 2.03469 3.27112 1.79688 3.63789C1.82545 3.63576 1.85408 3.63465 1.88273 3.63455C2.07926 3.63452 2.27285 3.68232 2.44678 3.77381C2.62071 3.86531 2.76977 3.99775 2.88108 4.15971L3.47395 5.02208C4.17234 3.76988 5.49996 2.96148 6.99809 2.96148C9.08858 2.96148 10.8132 4.55825 11.0165 6.5961H10.6327C10.5591 6.59609 10.4869 6.61619 10.4239 6.65422C10.3609 6.69226 10.3095 6.74679 10.2753 6.81192C10.241 6.87706 10.2252 6.95031 10.2295 7.02377C10.2339 7.09724 10.2582 7.16812 10.2999 7.22875L10.8886 8.08505L11.7807 9.38262C11.8178 9.4366 11.8675 9.48075 11.9255 9.51124C11.9835 9.54174 12.048 9.55768 12.1135 9.55768C12.179 9.55768 12.2435 9.54174 12.3015 9.51124C12.3595 9.48075 12.4092 9.4366 12.4463 9.38262L12.981 8.60488L13.9271 7.22878C13.9687 7.16813 13.9931 7.09725 13.9974 7.02378C14.0018 6.95031 13.9859 6.87706 13.9517 6.81192Z"
        fill="#0090FF"
      />
    </svg>
  </span>
);
