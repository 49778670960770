export const PROMOTIONS_ROUTES = {
  FEE_N_CASH_DISCOUNTS: 'fee-cash-discounts',
  CHECKIN_REWARD: 'checkin-reward',
  LOYALTY_REWARD: 'loyalty-reward',
  BIRTHDAY_REWARD: 'birthday-reward',
  OCCASION_REWARD: 'occasion-reward',
  REFERRAL_REWARD: 'referral-reward',
  WEEKLY_REWARD: 'weekly-reward',
  GENERAL_REWARD: 'general-reward',
  ANNOUNCEMENTS: 'annoucements',
  ANNOUNCEMENTS_CREATE: '/annoucements/create',
  ANNOUNCEMENTS_DETAIL: '/annoucements/detail/',
};
