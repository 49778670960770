import React, { useEffect } from 'react';
import { ChargeCardEnum } from '..';
import { useIntl } from 'react-intl';

interface IProps {}

export const PrintChargeCard: React.FC<IProps> = (props) => {
  const {} = props;
  const intl = useIntl();

  return (
    <div className="w-full">
      <span className="font-weight-400 font-size-16">
        {intl.formatMessage({ id: 'checkout.payAndComplete.print.label' })}
      </span>
    </div>
  );
};
