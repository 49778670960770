import { useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ticketApi } from '../../../../apis';
import { CashDrawer, CashDrawerDTOActionEnum, SalonSetting } from '../../../../apis/client-axios/api';
import ColWrap from '../../../../components/ColWrap';
import FormWrap from '../../../../components/FormWrap';
import RowWrap from '../../../../components/RowWrap';
import { StyledModal } from '../../../../components/StyledModal';
import TableWrap from '../../../../components/TableWrap';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import {
  APPOINTMENT_TIME_FORMAT,
  DATE_FORMAT,
  formatDateTimeZoneByFormatString,
  formatNumberThousandWithDecimal,
} from '../../../../utils';
import { QUERY_TICKET } from '../../../../utils/constant';
import CashIn from './CashIn';
import CashOut from './CashOut';
import Others from './Others';
import './style.scss';
import { useClover } from '../../setting/terminalDeviceSetting/Clover/connection/hooks';

export interface Props {
  open: boolean;
  onCancelModal: () => void;
  listSetting?: SalonSetting;
}

interface IOpenModalCashDrawer {
  openCashIn: boolean;
  openCashOut: boolean;
  openOthers: boolean;
}

const ModalCashDrawer: FC<Props> = (props) => {
  const { open, onCancelModal, listSetting } = props;

  const intl = useIntl();
  const [form] = Form.useForm();

  const { cloverDevice, onConnect } = useClover();

  const [openModal, setOpenModal] = useState<IOpenModalCashDrawer>({
    openCashIn: false,
    openCashOut: false,
    openOthers: false,
  });

  const { data: dataCashDrawer, refetch: refetchCashDrawer } = useQuery({
    queryKey: [QUERY_TICKET],
    enabled: true,
    staleTime: 1000,
    queryFn: () => ticketApi.ticketControllerGetCashDrawer(1, undefined, undefined, undefined, undefined, undefined),
  });

  const columnsCustomer: ColumnType<CashDrawer>[] = [
    {
      key: 0,
      title: (
        <p className="font-size-16 font-weight-500 w-100 d-flex flex-column justify-content-end m-b-0 m-t-23 m-l-16-n">
          {intl.formatMessage({ id: 'checkout.cashDrawer.table.title.1' })}
        </p>
      ),

      width: 228,
      align: 'left',
      render: (_, item: CashDrawer, index) => (
        <div className="font-size-16 font-weight-500 salon__checkout-cash-drawer-modal-table-custom-text m-l-16-n">
          <p className="width-40 m-b-0">{index + 1}</p>
          <p className="width-76 m-b-0 m-r-20">
            {formatDateTimeZoneByFormatString(APPOINTMENT_TIME_FORMAT, item?.createdOnDate) + ' '}
          </p>
          <span className="salon__checkout-cash-drawer-modal-table-custom-text-blue">
            {item.action === CashDrawerDTOActionEnum.CashIn
              ? intl.formatMessage({ id: 'checkout.cashDrawer.table.cashIn' })
              : item.action === CashDrawerDTOActionEnum.CashOut
              ? intl.formatMessage({ id: 'checkout.cashDrawer.table.cashOut' })
              : intl.formatMessage({ id: 'checkout.cashDrawer.table.other' })}
          </span>
        </div>
      ),
    },
    {
      key: 1,
      title: (
        <p className="font-size-16 font-weight-500 w-100 d-flex flex-column justify-content-end m-b-0 m-t-23">
          <span>
            {intl.formatMessage({ id: 'checkout.cashDrawer.table.title.2' })} $
            {formatNumberThousandWithDecimal(dataCashDrawer?.data?.totalBalance || 0)}
          </span>
        </p>
      ),
      width: 156,
      align: 'right',
      render: (_, item: CashDrawer) => (
        <span className="font-size-16 font-weight-500">
          {item.cashAmount ? intl.formatMessage({ id: 'checkout.cashDrawer.table.price' }) : '--'}
          {item.cashAmount}
        </span>
      ),
    },
    {
      key: 2,
      title: (
        <p className="font-size-16 font-weight-500 w-100 d-flex flex-column justify-content-end m-b-0 m-t-23">
          {intl.formatMessage({ id: 'checkout.cashDrawer.table.title.3' })}
        </p>
      ),
      width: 174,
      align: 'left',
      render: (_, item: CashDrawer) => <span className="font-size-16 font-weight-500">{item.notes ?? '--'}</span>,
    },
  ];

  const handleOpenCashDrawer = (reason: string) => {
    onConnect();

    setTimeout(() => {
      cloverDevice.performOpenCashDrawer({ reason });
    }, 900);
  };

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancelModal}
      modalProps={{
        title: (
          <div className="salon__checkout-cash-drawer-modal-heading">
            {intl.formatMessage({ id: 'checkout.cashDrawer' })}
          </div>
        ),
        className: 'salon__checkout-cash-drawer-modal',
        width: 700,
        footer: (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-12 align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'checkout.cashDrawer.ok' })}
                buttonProps={{
                  className: 'width-100 height-38',
                  onClick: () => {
                    onCancelModal();
                  },
                }}
                isPrimary={true}
              />
            </div>
          </div>
        ),
      }}
    >
      <FormWrap
        form={form}
        name="cashDrawer"
        layout="vertical"
        className="w-100 salon__checkout-cash-drawer-modal-form p-t-20"
      >
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[10, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <span className="font-size-16 font-weight-500">
              {intl.formatMessage({ id: 'checkout.cashDrawer.title' })}
            </span>
          </ColWrap>
          <ColWrap colProps={{ span: 8 }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.cashDrawer.button.cashIn' })}
              buttonProps={{
                className: 'width-200 height-62',
                onClick: () => {
                  setOpenModal({
                    ...openModal,
                    openCashIn: true,
                  });
                },
              }}
              isPrimary={false}
            />
          </ColWrap>

          <ColWrap colProps={{ span: 8 }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.cashDrawer.button.cashOut' })}
              buttonProps={{
                className: 'width-200 height-62',
                onClick: () => {
                  setOpenModal({
                    ...openModal,
                    openCashOut: true,
                  });
                },
                disabled: Number(dataCashDrawer?.data?.totalBalance) <= 0,
              }}
              isPrimary={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 8 }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.cashDrawer.button.others' })}
              buttonProps={{
                className: 'width-200 height-62',
                onClick: () => {
                  setOpenModal({
                    ...openModal,
                    openOthers: true,
                  });
                },
              }}
              isPrimary={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 24 }}>
            <span className="font-size-16 font-weight-600 ">{formatDateTimeZoneByFormatString(DATE_FORMAT)}</span>
            <div className="salon__checkout-cash-drawer-modal-table">
              <TableWrap
                data={dataCashDrawer?.data?.content ?? []}
                columns={columnsCustomer}
                total={0}
                size={0}
                page={1}
                setPage={() => {}}
                setSize={() => {}}
                showSizeChanger
                isHidePagination={true}
                isScroll={true}
                scrollValue={{ y: 216 }}
                isPointer={false}
              />
            </div>
          </ColWrap>
        </RowWrap>
      </FormWrap>

      {openModal.openCashIn && (
        <CashIn
          refetchCashDrawer={refetchCashDrawer}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openCashIn: false,
            });
          }}
          openModal={openModal.openCashIn}
          listSetting={listSetting}
          onOpenCashDrawer={handleOpenCashDrawer}
        />
      )}

      {openModal.openCashOut && (
        <CashOut
          refetchCashDrawer={refetchCashDrawer}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openCashOut: false,
            });
          }}
          openModal={openModal.openCashOut}
          totalBalance={Number(dataCashDrawer?.data?.totalBalance)}
          onOpenCashDrawer={handleOpenCashDrawer}
        />
      )}

      {openModal.openOthers && (
        <Others
          refetchCashDrawer={refetchCashDrawer}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openOthers: false,
            });
          }}
          openModal={openModal.openOthers}
          onOpenCashDrawer={handleOpenCashDrawer}
        />
      )}
    </StyledModal>
  );
};

export default ModalCashDrawer;
