import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  TicketDTOStatusEnum,
  TicketTransactionDTO,
  TicketTransactionDTOStatusEnum,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../components/FormWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import {
  convertFormatNumberToNumber,
  formatNumberThousandWithDecimal,
  handleTruncateToTwoDecimal,
} from '../../../../../../utils';
import { IHandleSubmitCheckoutProps, IListIdTotalPrice, ITipInformation, PaymentInformation } from '../../../index';
import KeyboardWrapper, { KeyboardDirection } from '../../../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  setIsOpenPayAndComplete: (state: boolean) => void;
  listTotal: IListIdTotalPrice;
  setListTotal: (listTotal: IListIdTotalPrice) => void;
  handleSubmitCheckout: (submitParams?: IHandleSubmitCheckoutProps) => void;
  onChangePaymentInformation: React.Dispatch<React.SetStateAction<PaymentInformation>>;
  paymentInformation?: PaymentInformation;
  leftBalanceToPay: number;
  saveTipAndTechnician: (totalTip: number) => void;
  tipInformation: ITipInformation | undefined;
  isLoading: boolean;
}

interface FormData {
  customerCash?: number;
  customerTipLeft?: number;
  changeAmount?: number;
}

enum InputNameEnum {
  CustomerCash = 'customerCash',
  CustomerTipLeft = 'customerTipLeft',
}

const arrayDollarMoney = [100, 50, 20, 10, 5];

const arrayCentMoney = [50, 25, 10, 5, 1];

const n = (key: keyof FormData) => key;

export const CashPayment: React.FC<IProps> = (props) => {
  const {
    open,
    setOpen,
    listTotal,
    setListTotal,
    handleSubmitCheckout,
    onChangePaymentInformation,
    paymentInformation,
    leftBalanceToPay,
    saveTipAndTechnician,
    tipInformation,
    isLoading,
  } = props;

  const intl = useIntl();

  const [form] = Form.useForm();
  const customerTipLeft = Form.useWatch(n('customerTipLeft'), form);
  const customerCash = Form.useWatch(n('customerCash'), form);

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const customerCashRef = useRef<HTMLInputElement>(null);
  const customerTipLeftRef = useRef<HTMLInputElement>(null);

  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();

  useEffect(() => {
    if (customerCash && customerCashRef.current) {
      const [integer] = customerCash.replace(/,/g, '').split('.');
      customerCashRef.current.selectionStart = customerCashRef.current.selectionEnd =
        customerCash.length + Math.floor(integer / 3);
    }

    if (customerTipLeft && customerTipLeftRef.current) {
      const [integer] = customerTipLeft.replace(/,/g, '').split('.');
      customerTipLeftRef.current.selectionStart = customerTipLeftRef.current.selectionEnd =
        customerTipLeft.length + Math.floor(integer / 3);
    }
  }, [customerCash, customerTipLeft]);

  const onClickOK = (value: FormData) => {
    const convertCustomerCash = convertFormatNumberToNumber(value.customerCash || 0);
    const convertCustomerTipLeft = convertFormatNumberToNumber(value.customerTipLeft || 0);
    const convertChangeAmount = convertFormatNumberToNumber(value.changeAmount || 0);
    const balanceToPay = handleTruncateToTwoDecimal(listTotal.totalBalanceTotalPay);

    const moneyTransaction = handleTruncateToTwoDecimal(convertCustomerCash - convertCustomerTipLeft);

    const ticketTransaction = [...(paymentInformation?.ticketTransaction ?? [])];

    const customerHandYouTrans: TicketTransactionDTO = {
      money: moneyTransaction >= balanceToPay ? balanceToPay : moneyTransaction,
      typePayment: TicketTransactionDTOTypePaymentEnum.Cash,
      status: TicketTransactionDTOStatusEnum.CustomerHandToYou,
      tipMoney: convertCustomerTipLeft,
      changeAmount: Math.abs(convertChangeAmount),
    };
    ticketTransaction.push(customerHandYouTrans);

    let totalTip = tipInformation?.tip ?? 0;
    totalTip += convertCustomerTipLeft;

    onChangePaymentInformation((prev) => ({
      ...prev,
      ticketTransaction,
      cashPayment: {
        amount: Number(paymentInformation?.cashPayment?.amount || 0) + moneyTransaction,
        tip: Number(paymentInformation?.cashPayment?.tip || 0) + convertCustomerTipLeft,
      },
    }));

    if (moneyTransaction >= balanceToPay) {
      handleSubmitCheckout({
        status: TicketDTOStatusEnum.Completed,
        ticketTransaction: ticketTransaction,
        totalTip,
        balanceToPay: 0,
      });
    }

    saveTipAndTechnician(totalTip);

    setListTotal({
      ...listTotal,
      totalCash: moneyTransaction + listTotal.totalCash,
    });

    setOpen(false);
  };

  const onClickQuickNumber = (value: number) => {
    if (focusedInputName) {
      form.setFieldValue(focusedInputName, formatNumberThousandWithDecimal(value));
    }
  };

  const onClickNoTip = () => {
    form.setFieldValue(n('customerTipLeft'), formatNumberThousandWithDecimal(0));
  };

  useEffect(() => {
    form.setFieldsValue({
      [n('customerCash')]: formatNumberThousandWithDecimal(leftBalanceToPay || 0),
      [n('customerTipLeft')]: null,
    });
  }, []);

  useEffect(() => {
    if (open) {
      customerCashRef.current?.focus();
    }
  }, [open, customerCash]);

  useEffect(() => {
    const convertCustomerCash = convertFormatNumberToNumber(customerCash);
    const convertCustomerTipLeft = convertFormatNumberToNumber(customerTipLeft);
    const convertChangeAmount = convertCustomerCash - convertCustomerTipLeft;
    // if (convertChangeAmount && convertChangeAmount > leftBalanceToPay) {
    const actualChangeAmount = convertChangeAmount - leftBalanceToPay;

    form.setFieldValue(n('changeAmount'), formatNumberThousandWithDecimal(actualChangeAmount));
    // } else {
    //   form.setFieldValue(n('changeAmount'), '');
    // }
  }, [customerCash, customerTipLeft]);

  return (
    <StyledModal
      isOpen={open}
      onCancel={() => setOpen(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-merchandise-modal-heading">
            {intl.formatMessage({ id: `checkout.payAndComplete.title.cashPayment` })}
          </div>
        ),
        className: 'salon__checkout__pay-n-complete-modal',
        width: 831,
        footer: null,
      }}
    >
      <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <p className="font-size-16 font-weight-500 m-0">
              {`${intl.formatMessage({ id: 'checkout.payAndComplete.balanceToPay' })}: `}
              <span className="font-weight-700">
                $
                {formatNumberThousandWithDecimal(
                  // Number(listTotal?.totalBalanceTotalPay || 0) - Number(paymentInformation?.amount || 0)
                  leftBalanceToPay
                )}
              </span>
            </p>
          </ColWrap>
          <ColWrap colProps={{ span: 13 }}>
            <FormInputNumber
              name={n('customerCash')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerCash.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                inputMode: 'none',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerCash),
                getInputRef: customerCashRef,
                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
              }}
              formItemProps={{
                className: 'm-b-0 width-378',
                // rules: [
                //   {
                //     required: true,
                //     message: '',
                //   },
                // ],
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 11 }}>
            <FormInputNumber
              name={n('customerTipLeft')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.customerTipLeft.label' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                inputMode: 'none',
                onFocus: () => setFocusedInputName(InputNameEnum.CustomerTipLeft),
                onKeyDown: (event) => {
                  const value = event?.key;
                  if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                  if (value === 'Enter') {
                    form.submit();
                  }
                  if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                  return event.preventDefault();
                },
                // disabled: !customerCash || customerCash === '0.00',
                getInputRef: customerTipLeftRef,
              }}
              formItemProps={{
                className: 'm-b-0 width-353',
                rules: [
                  {
                    validator: (_, value: string | number) => {
                      const v = value ? convertFormatNumberToNumber(value) : undefined;
                      const convertCustomerCash = customerCash ? convertFormatNumberToNumber(customerCash) : undefined;
                      if (v && convertCustomerCash && v > convertCustomerCash) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              }}
            />
          </ColWrap>

          {/* change amount */}
          <ColWrap colProps={{ span: 24 }}>
            <FormInputNumber
              name={n('changeAmount')}
              prefix={<SvgDollarIcon />}
              label={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.changeAmount' })}
              numericFormatProps={{
                placeholder: '0.00',
                className: 'm-b-0',
                disabled: true,
                allowNegative: true,
              }}
              formItemProps={{
                className: 'm-b-0 w-full',
              }}
            />
          </ColWrap>
        </RowWrap>
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[8, 8]}
          isWrap={false}
          styleFill="between"
          className="m-t-20"
        >
          {arrayDollarMoney.map((money, index) => (
            <ColWrap key={`DoolarMoney_${index}`}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className:
                    'height-76 width-143 border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                  onClick: () => onClickQuickNumber(money),
                }}
                content={`$${formatNumberThousandWithDecimal(money)}`}
              />
            </ColWrap>
          ))}
        </RowWrap>
        {/* <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[8, 8]}
          isWrap={false}
          styleFill="between"
          className="m-t-20"
        >
          {arrayCentMoney.map((money, index) => (
            <ColWrap key={`CentMoney_${index}`}>
              <CustomButton
                type="primary"
                buttonProps={{
                  className:
                    'height-76 width-143 border-radius-12px font-size-24 font-weight-700 background-color-CCF4FF color-292F33',
                  onClick: () => onClickQuickNumber(money * 0.01),
                }}
                content={`${formatNumberThousandWithDecimal(money)}c`}
              />
            </ColWrap>
          ))}
        </RowWrap> */}
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[20, 20]}
          isWrap={true}
          styleFill="between"
          className="m-t-20"
        >
          <ColWrap colProps={{ span: 24 }}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
              {/* no tip */}
              <ColWrap colProps={{ span: 4 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-100 w-full border-radius-12px font-size-24 font-weight-700 background-color-EE7E68',
                    onClick: onClickNoTip,
                  }}
                  content={intl.formatMessage({ id: 'checkout.payAndComplete.cashPayment.noTip' })}
                />
              </ColWrap>

              {/* numeric keyboard */}
              <ColWrap colProps={{ span: 16 }}>
                <KeyboardWrapper
                  inputName={focusedInputName}
                  form={form}
                  keyboardRef={keyboardRef}
                  maxLength={9}
                  keyboardProps={{
                    inputPattern: /^\d+$/,
                  }}
                  direction={KeyboardDirection.rtl}
                />
              </ColWrap>

              {/* ok button */}
              <ColWrap colProps={{ span: 4 }}>
                <CustomButton
                  type="primary"
                  buttonProps={{
                    className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                    htmlType: 'submit',
                    loading: isLoading,
                    disabled: isLoading,
                  }}
                  content={intl.formatMessage({ id: 'common.OK' })}
                />
              </ColWrap>
            </RowWrap>
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </StyledModal>
  );
};
