import { FC, RefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SalonSetting, SettingCheckout, Ticket } from '../../../../apis/client-axios';
import ColWrap from '../../../../components/ColWrap';
import { RequirePasswordModal } from '../../../../components/RequirePasswordModal';
import RowWrap from '../../../../components/RowWrap';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { RootState, useAppDispatch } from '../../../../store';
import { setDeviceError, setIsDisconnected } from '../../../../store/cloverSlice';
import { defaultManagerCode } from '../../../../utils/constant';
import ModalCashDrawer from '../CashDrawer';
import Closeout from '../CashDrawer/Closeout';
import Transactions from '../Transactions';
import { DesiredStatusesItem, ITechnicianItem } from '../index';

export interface IOpenModalCheckOut {
  openModalCashDrawer: boolean;
  openTransactions: boolean;
  openCloseoutBatch: boolean;
  openPrintReceipt: boolean;
}

interface IProps {
  settingCheckout?: SettingCheckout;
  listSetting?: SalonSetting;
  lastTicketInToday?: Ticket;
  printBillComponentRef: RefObject<HTMLDivElement | null>;
  onChangeTicketToPrint: (ticket?: Ticket) => void;
  onPrintTicket: () => void;
  listTechnician: ITechnicianItem[];
  isLoadingPdf: boolean;
}

enum ModalTypeRequirePasswordEnum {
  CashDrawer = 'cashDrawer',
  Transactions = 'transactions',
  CloseOut = 'closeOut',
}

interface IRequirePassword {
  open: boolean;
  modalType: ModalTypeRequirePasswordEnum | undefined;
}

export enum PrintType {
  LAST_TICKET = 'LAST_TICKET',
  TRANSACTION = 'TRANSACTION',
  TECHNICIAN_RECEIPT = 'TECHNICIAN_RECEIPT',
  CUSTOMER_RECEIPT = 'CUSTOMER_RECEIPT',
}

const BillStart: FC<IProps> = (props) => {
  const {
    settingCheckout,
    lastTicketInToday,
    onChangeTicketToPrint,
    onPrintTicket,
    listSetting,
    listTechnician,
    isLoadingPdf,
  } = props;

  const intl = useIntl();
  const [requirePasswordState, setRequirePasswordState] = useState<IRequirePassword>({
    open: false,
    modalType: undefined,
  });

  const [isLoading, setIsLoading] = useState<Record<PrintType, boolean>>({
    LAST_TICKET: false,
    TRANSACTION: false,
    TECHNICIAN_RECEIPT: false,
    CUSTOMER_RECEIPT: false,
  });

  const [isLastTicketEmpty, setIsLastTicketEmpty] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<IOpenModalCheckOut>({
    openModalCashDrawer: false,
    openTransactions: false,
    openCloseoutBatch: false,
    openPrintReceipt: false,
  });

  const dispatch = useAppDispatch();
  const isDisconnected = useSelector((state: RootState) => state.clover.isDisconnected);
  const deviceError = useSelector((state: RootState) => state.clover.deviceError);
  const printResponse = useSelector((state: RootState) => state.clover.printResponse);

  useEffect(() => {
    if (isDisconnected || deviceError || printResponse) {
      setIsLoading({
        LAST_TICKET: false,
        TRANSACTION: false,
        TECHNICIAN_RECEIPT: false,
        CUSTOMER_RECEIPT: false,
      });

      dispatch(setIsDisconnected(false));
      dispatch(setDeviceError(undefined));
    }
  }, [isDisconnected, deviceError, printResponse, dispatch]);

  useEffect(() => {
    if (!isLoadingPdf) {
      setIsLoading({
        LAST_TICKET: false,
        TRANSACTION: false,
        TECHNICIAN_RECEIPT: false,
        CUSTOMER_RECEIPT: false,
      });
    }
  }, [isLoadingPdf]);

  const handleHighlight = (keyHighlight: string) => {
    const highLightElement = document.querySelector(`#${keyHighlight}`) as HTMLElement;
    if (highLightElement) {
      highLightElement.style.border = '1px solid red';
      setTimeout(() => {
        highLightElement.style.border = 'unset';
      }, 3000);
    }
  };

  const onClickTransactions = () => {
    if (settingCheckout?.securityCode?.secureTransactionButton) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.Transactions,
      });
    } else {
      setOpenModal((prev) => ({
        ...prev,
        openTransactions: true,
      }));
    }
  };
  const onClickCloseoutBatch = () => {
    if (settingCheckout?.securityCode?.secureCloseoutButton) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.CloseOut,
      });
    } else {
      setOpenModal((prev) => ({
        ...prev,
        openCloseoutBatch: true,
      }));
    }
  };
  const onClickCashDrawer = () => {
    if (settingCheckout?.securityCode?.secureOpenCashDrawer) {
      setRequirePasswordState({
        open: true,
        modalType: ModalTypeRequirePasswordEnum.CashDrawer,
      });
    } else {
      setOpenModal((prev) => ({
        ...prev,
        openModalCashDrawer: true,
      }));
    }
  };

  const getPasswords: () => string[] = () => {
    const arrayPasswords: string[] = [];
    if (settingCheckout?.securityCode?.managerCode) {
      arrayPasswords.push(settingCheckout.securityCode.managerCode);
    } else {
      arrayPasswords.push(settingCheckout?.securityCode?.defaultManagerCode || defaultManagerCode);
    }
    if (
      requirePasswordState.modalType &&
      ModalTypeRequirePasswordEnum.CashDrawer === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureOpenCashDrawer
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureOpenCashDrawer);
    }
    if (
      requirePasswordState.modalType &&
      ModalTypeRequirePasswordEnum.Transactions === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureTransactionButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureTransactionButton);
    }
    if (
      requirePasswordState.modalType &&
      ModalTypeRequirePasswordEnum.CloseOut === requirePasswordState.modalType &&
      settingCheckout?.securityCode?.secureCloseoutButton
    ) {
      arrayPasswords.push(settingCheckout.securityCode.secureCloseoutButton);
    }
    return arrayPasswords;
  };

  const onCancelRequirePassword = () => setRequirePasswordState({ open: false, modalType: undefined });

  const onSuccessRequirePassword = () => {
    switch (requirePasswordState.modalType) {
      case ModalTypeRequirePasswordEnum.CashDrawer:
        setOpenModal((prev) => ({
          ...prev,
          openModalCashDrawer: true,
        }));
        break;
      case ModalTypeRequirePasswordEnum.Transactions:
        setOpenModal((prev) => ({
          ...prev,
          openTransactions: true,
        }));
        break;
      case ModalTypeRequirePasswordEnum.CloseOut:
        setOpenModal((prev) => ({
          ...prev,
          openCloseoutBatch: true,
        }));
        break;
      default:
        break;
    }
    setRequirePasswordState({
      open: false,
      modalType: undefined,
    });
  };

  const handlePrintTicket = (type: PrintType, transactionTicket?: Ticket, isNotPrintBill?: boolean) => {
    if (!lastTicketInToday && type === PrintType.LAST_TICKET) {
      setIsLastTicketEmpty(true);
      return;
    }

    setIsLoading((prev) => ({ ...prev, [type]: true }));

    if (!isNotPrintBill) {
      onChangeTicketToPrint(type === PrintType.LAST_TICKET ? lastTicketInToday : transactionTicket);

      onPrintTicket();
    }
  };

  return (
    <div className="salon__checkout-bill-start">
      <div className="salon__checkout-bill-start-tap-on-container">
        <span className="salon__checkout-bill-start-tap-on-title">
          {intl.formatMessage({ id: 'checkout.tapOn.title' })}{' '}
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListTechnician);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.technician' })}
          </span>
          ,
        </span>
        <br />
        <span className="salon__checkout-bill-start-tap-on-title">
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListServices);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.service' })}
          </span>{' '}
          {intl.formatMessage({ id: 'checkout.tapOn.or' })}{' '}
          <span
            className="salon__checkout-bill-start-tap-on-title-btn cursor-pointer"
            onClick={() => {
              handleHighlight(DesiredStatusesItem.ListCheckin);
            }}
          >
            {intl.formatMessage({ id: 'checkout.tapOn.checkin' })}
          </span>{' '}
          {intl.formatMessage({ id: 'checkout.tapOn.to' })}
        </span>
        <br />
        <span className="salon__checkout-bill-start-tap-on-title">
          {intl.formatMessage({ id: 'checkout.tapOn.createNew' })}
        </span>
        <br />
      </div>
      <div className="m-l-r-48">
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[12, 12]}
          isWrap={true}
          styleFill="between"
          rowClassName="m-b-52"
        >
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.transaction.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: onClickTransactions,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.printLastTicket.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: () => handlePrintTicket(PrintType.LAST_TICKET),
                disabled: isLoading.LAST_TICKET,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.cashDrawer.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: onClickCashDrawer,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12, className: 'text-center' }}>
            <ButtonStyled
              content={intl.formatMessage({ id: 'checkout.closeoutBatch.btn.title' })}
              buttonProps={{
                className: 'w-100 height-62',
                onClick: onClickCloseoutBatch,
              }}
              isPrimary={false}
              isButtonCancel={false}
              isCancelPopup={false}
            />
          </ColWrap>
        </RowWrap>
      </div>
      {openModal?.openModalCashDrawer && (
        <ModalCashDrawer
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openModalCashDrawer: false,
            });
          }}
          open={openModal.openModalCashDrawer}
        />
      )}
      {openModal?.openTransactions && (
        <Transactions
          open={openModal.openTransactions}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openTransactions: false,
            });
          }}
          settingCheckout={settingCheckout}
          onPrintTicket={handlePrintTicket}
          isLoading={isLoading}
          openModal={openModal}
          onChangeOpenModal={setOpenModal}
          settingTip={listSetting?.settingTip}
          listTechnician={listTechnician}
          setIsLoading={setIsLoading}
        />
      )}
      {openModal?.openCloseoutBatch && (
        <Closeout
          open={openModal.openCloseoutBatch}
          onCancelModal={() => {
            setOpenModal({
              ...openModal,
              openCloseoutBatch: false,
            });
          }}
        />
      )}

      {requirePasswordState.open && (
        <RequirePasswordModal
          open={requirePasswordState.open}
          onCancel={onCancelRequirePassword}
          onClickOK={onSuccessRequirePassword}
          passwords={getPasswords()}
          inputProps={{
            inputLabel: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.label`,
            }),
            inputPlaceholder: intl.formatMessage({
              id: `checkout.${requirePasswordState.modalType || 'transactions'}.requirePassword.placeholder`,
            }),
          }}
        />
      )}

      {isLastTicketEmpty && (
        <StyledPopup
          isOpen={isLastTicketEmpty}
          content={<div>{intl.formatMessage({ id: 'checkout.printLastTicket.empty' })}</div>}
          isHiddenCancel
          onCancel={() => setIsLastTicketEmpty(false)}
          onOk={() => setIsLastTicketEmpty(false)}
          buttonPropsOK={{
            content: intl.formatMessage({ id: 'common.ok' }),
          }}
        />
      )}
    </div>
  );
};

export default BillStart;
