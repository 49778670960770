import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as clover from 'remote-pay-cloud';
import { ticketApi } from '../../../../../../../apis';
import { RefundTransactionDTO, RefundTransactionDTOTypePaymentEnum } from '../../../../../../../apis/client-axios';
import { SvgDollarIcon } from '../../../../../../../components/@svg/SvgDollarIcon';
import ColWrap from '../../../../../../../components/ColWrap';
import { FormInputNumber } from '../../../../../../../components/Form/FormInputNumber';
import FormWrap from '../../../../../../../components/FormWrap';
import NotificationError from '../../../../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../../components/StyledModal';
import { StyledPopup } from '../../../../../../../components/StyledPopup';
import { CustomButton } from '../../../../../../../components/buttons/CustomButton';
import { RootState, useAppDispatch } from '../../../../../../../store';
import { setRefundResponse } from '../../../../../../../store/cloverSlice';
import { convertFormatNumberToNumber, formatNumberThousandWithDecimal } from '../../../../../../../utils';
import { useClover } from '../../../../../setting/terminalDeviceSetting/Clover/connection/hooks';
import KeyboardWrapper, { KeyboardDirection } from '../../../../keyboard';
import { KeyboardReactInterface } from 'react-simple-keyboard';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  refundType: RefundTransactionDTOTypePaymentEnum;
  ticketId: number;
  totalPayment: number;
  setOpenRefundMethod: (state: boolean) => void;
  onCancelModal: () => void;
}

interface FormData {
  refundAmount?: number;
}

enum InputNameEnum {
  RefundAmount = 'refundAmount',
}

const n = (key: keyof FormData) => key;

const Refund: FC<IProps> = (props) => {
  const { open, setOpen, ticketId, totalPayment, setOpenRefundMethod, onCancelModal, refundType } = props;

  const intl = useIntl();

  const [form] = Form.useForm();
  const refundAmount = Form.useWatch(n('refundAmount'), form);

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const refundAmountRef = useRef<HTMLInputElement>(null);

  const [focusedInputName, setFocusedInputName] = useState<InputNameEnum>();
  const [isConfirmDialog, setIsConfirmDialog] = useState<boolean>(false);
  const [isLoadingRefund, setIsLoadingRefund] = useState<boolean>(false);

  const { cloverDevice, onConnect } = useClover();

  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: RootState) => state.clover.isLoading);
  const refundResponse = useSelector((state: RootState) => state.clover.refundResponse);
  const isDisconnected = useSelector((state: RootState) => state.clover.isDisconnected);

  useEffect(() => {
    if (isDisconnected) {
      setIsLoadingRefund(false);
    }
  }, [isDisconnected]);

  useEffect(() => {
    if (refundAmount && refundAmountRef.current) {
      const [integer] = refundAmount.replace(/,/g, '').split('.');
      refundAmountRef.current.selectionStart = refundAmountRef.current.selectionEnd =
        refundAmount.length + Math.floor(integer / 3);
    }
  }, [refundAmount]);

  useEffect(() => {
    if (open) {
      refundAmountRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    if (refundResponse) {
      onManualRefundResponse(refundResponse);
    }
  }, [refundResponse]);

  const refundMutation = useMutation(
    (refundTransactionDTO: RefundTransactionDTO) => ticketApi.ticketControllerRefundTransaction(refundTransactionDTO),
    {
      onSuccess: ({ data }) => {
        NotificationSuccess({ contentNoti: 'Refund success!' });
        setIsConfirmDialog(false);
        setOpen(false);
        setOpenRefundMethod(false);
        onCancelModal();
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
      onSettled: () => {
        setIsLoadingRefund(false);
      },
    }
  );

  const onClickOK = (value: FormData) => {
    if (value?.refundAmount && Number(value?.refundAmount) > Number(totalPayment))
      return NotificationError({ contentNoti: "The refund amount can't be more than the total payment!" });
    setIsConfirmDialog(true);
  };

  const onCloverRefund = () => {
    onConnect();

    setTimeout(() => {
      cloverDevice.performRefund({ amount: convertFormatNumberToNumber(refundAmount) });
    }, 900);
  };

  const onManualRefundResponse = (response: clover.remotepay.ManualRefundResponse) => {
    setIsConfirmDialog(false);
    dispatch(setRefundResponse(undefined));
    setIsLoadingRefund(false);

    if (response.getSuccess()) {
      refundMutation.mutate({
        money: Number(refundAmount) || 0,
        ticketId: ticketId,
        typePayment: refundType,
      });
    } else {
      NotificationError({ contentNoti: response.getMessage() });
    }
  };

  const handleRefund = () => {
    if (
      refundType === RefundTransactionDTOTypePaymentEnum.Cash ||
      refundType === RefundTransactionDTOTypePaymentEnum.Check
    ) {
      refundMutation.mutate({
        money: Number(refundAmount) || 0,
        ticketId: ticketId,
        typePayment: refundType,
      });
    } else if (refundType === RefundTransactionDTOTypePaymentEnum.OriginalCard) {
      setIsLoadingRefund(true);
      onCloverRefund();
    }
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        modalProps={{
          title: (
            <div className="salon__checkout-merchandise-modal-heading">
              {intl.formatMessage({ id: 'checkout.transactions.item.refund' })}
            </div>
          ),
          className: 'salon__checkout__pay-n-complete-modal',
          width: 700,
          footer: null,
        }}
      >
        <FormWrap form={form} onFinish={onClickOK} className="salon__checkout__pay-n-complete-modal__cash-payment">
          <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24 }}>
              <FormInputNumber
                name={n('refundAmount')}
                prefix={<SvgDollarIcon />}
                label={intl.formatMessage({ id: 'checkout.transactions.refundMethod.refundAmount' })}
                numericFormatProps={{
                  placeholder: '0.00',
                  className: 'm-b-0',
                  onFocus: () => setFocusedInputName(InputNameEnum.RefundAmount),
                  getInputRef: refundAmountRef,
                  onKeyDown: (event) => {
                    const value = event?.key;
                    if (value === 'Backspace') keyboardRef.current?.handleButtonClicked('{backspace}');
                    if (value === 'Enter') {
                      form.submit();
                    }
                    if (/^\d+$/.test(value)) keyboardRef.current?.handleButtonClicked(value);

                    return event.preventDefault();
                  },
                }}
                formItemProps={{
                  className: 'm-b-0 w-full',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                }}
              />
            </ColWrap>

            <ColWrap colProps={{ span: 24 }}>
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[8, 8]} isWrap={true} styleFill="between">
                {/* numeric keyboard */}
                <ColWrap colProps={{ span: 18 }}>
                  <KeyboardWrapper
                    inputName={focusedInputName}
                    form={form}
                    keyboardRef={keyboardRef}
                    maxLength={9}
                    keyboardProps={{
                      inputPattern: /^\d+$/,
                    }}
                    direction={KeyboardDirection.rtl}
                  />
                </ColWrap>

                {/* ok button */}
                <ColWrap colProps={{ span: 6 }}>
                  <CustomButton
                    type="primary"
                    buttonProps={{
                      className: 'h-full w-full border-radius-12px font-size-24 font-weight-700',
                      htmlType: 'submit',
                    }}
                    content={intl.formatMessage({ id: 'common.OK' })}
                  />
                </ColWrap>
              </RowWrap>
            </ColWrap>
          </RowWrap>
        </FormWrap>
      </StyledModal>

      {/* confirm dialog */}
      {isConfirmDialog && (
        <StyledPopup
          isOpen={isConfirmDialog}
          content={
            <div>
              {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.areYouSureYouWantTo' })}
              &nbsp;
              <span className="font-weight-700 text-transform-lowercase">
                {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.refund' })}
              </span>
              &nbsp;
              {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.this' })}
              &nbsp;
              <span className="font-weight-700 text-transform-lowercase">
                ${formatNumberThousandWithDecimal(refundAmount)}
              </span>
              &nbsp;
              {intl.formatMessage({ id: 'checkout.transactions.refundMethod.confirm.toTheCustomer' })}?
            </div>
          }
          customFooter={
            <div className="d-flex justify-content-center gap-12">
              <CustomButton
                type="primary"
                content={intl.formatMessage({ id: 'common.no' })}
                buttonProps={{
                  className: 'font-size-16 font-weight-700 height-38 width-100',
                  onClick: () => setIsConfirmDialog(false),
                }}
              />

              <CustomButton
                type="cancel"
                content={intl.formatMessage({ id: 'common.yes' })}
                buttonProps={{
                  className: 'font-size-16 font-weight-700 height-38 width-100',
                  onClick: handleRefund,
                  loading: isLoading || refundMutation.isLoading || isLoadingRefund,
                }}
              />
            </div>
          }
          onCancel={() => {
            setIsConfirmDialog(false);
          }}
          onOk={() => {}}
        />
      )}
    </>
  );
};

export default Refund;
