import { useQuery } from '@tanstack/react-query';
import { Card } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { employeeApi } from '../../../../apis';
import { Employee } from '../../../../apis/client-axios';
import { StyledModal } from '../../../../components/StyledModal';
import { QUERY_LIST_TECHNICIAN } from '../../../../utils/constant';
import './style.scss';
import { IAppointmentBill, IListIdDisable, ITechnicianItem } from '../index';

interface Props {
  openModalSelect: boolean;
  idTechnician: string | number;
  idService: string | number;
  appointmentBill: IAppointmentBill;
  setOpenModalSelect: (state: boolean) => void;
  setTechnician: (technician: ITechnicianItem | Employee) => void;
  setIdTechnician: (idTechnician: string | number) => void;
  setListSkill: (listSkill: number[]) => void;
  listTechnician: ITechnicianItem[];
  setListIdDisable: (listIdDisable: IListIdDisable) => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
  listIdDisable: IListIdDisable;
  setTechnicianItem:(technician: ITechnicianItem) => void;
}

const ModalSelectTechnician: FC<Props> = ({ ...props }) => {
  const {
    openModalSelect,
    idTechnician,
    appointmentBill,
    listTechnician,
    setOpenModalSelect,
    setTechnician,
    setIdTechnician,
    setListSkill,
    idService,
    listIdDisable,
    setAppointmentBill,
    setListIdDisable,
    setTechnicianItem
  } = props;

  const intl = useIntl();
  const [listTechnicianBySKill, setListTechnicianBySKill] = useState<ITechnicianItem[]>([]);

  const addTechnician = (item: ITechnicianItem) => {
    setTechnician(item);
    setOpenModalSelect(false);
    setIdTechnician(item.id);
    setListSkill(item.skills ?? []);
    setTechnicianItem(item);
  };
  // const addTechnician = (item: ITechnicianItem) => {
  //   const findIndexTechnician = appointmentBill.technician.findIndex((itemFilter) => itemFilter.id === idTechnician);
  //   const tempAppointment = appointmentBill;
  //   tempAppointment.technician[findIndexTechnician].defaultAvatar = item.defaultAvatar ?? '';
  //   tempAppointment.technician[findIndexTechnician].id = item.id;
  //   tempAppointment.technician[findIndexTechnician].name = item.name;
  //   tempAppointment.technician[findIndexTechnician].phoneNumber = item.phoneNumber;
  //   tempAppointment.technician[findIndexTechnician].skills = item.skills;
  //   tempAppointment.technician[findIndexTechnician].title = item.title;
  //   const mergedTechnician = tempAppointment.technician.reduce((acc:ITechnicianItem[], cur:ITechnicianItem) => {
  //     const found = acc.find(item => item.id === cur.id);
  //     if (found && found.services && cur.services) {
  //       found.services = [...found.services, ...cur.services];
  //     } else {
  //       acc.push(cur);
  //     }
  //     return acc;
  //   }, []);
  //   setIdTechnician(item.id);
  //   setOpenModalSelect(false);
  //   setAppointmentBill({...tempAppointment, technician: mergedTechnician});
  //   setListIdDisable({
  //     ...listIdDisable,
  //     listIdTechnician: tempAppointment.technician.map((item) => item.id) as number[],
  //   });
  // };
  useEffect(() => {
    if (idTechnician && appointmentBill.technician) {
      const findTechnician = appointmentBill.technician.find((item) => item.id === idTechnician);
      if (findTechnician) {
        setTechnician(findTechnician);
      }
    }
  }, [idTechnician, appointmentBill.technician]);

  useEffect(() => {
    const listServiceIds: any[] = [];
    const findTechnician = appointmentBill.technician.find((itemFilter) => itemFilter.id === idTechnician);
    const findListService = findTechnician?.services ?? [];

    findListService.forEach((item) => {
      if (!item.isGeneralService) {
        listServiceIds.push(item.id);
      }
    });
    const filteredElements = listTechnician.filter((item) =>
      listServiceIds.every((skill) => item?.skills?.includes(skill))
    );
    setListTechnicianBySKill(filteredElements);
  }, [appointmentBill, idTechnician, listTechnician]);
  return (
    <StyledModal
      isOpen={openModalSelect}
      onCancel={() => setOpenModalSelect(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-general-modal-heading">{intl.formatMessage({ id: 'checkout.select' })}</div>
        ),
        className: 'salon__checkout-general-select-technician-modal',
        width: 700,
        footer: null,

        centered: true,
      }}
    >
      <div className="salon__checkout-general-select-technician-modal-card">
        {listTechnicianBySKill && listTechnicianBySKill.length > 1 ? (
          listTechnicianBySKill.map((item) => (
            <Card
              className="salon__checkout-general-select-technician-modal-card-main"
              onClick={() => addTechnician(item)}
            >
              <img src="/assets/qr/avatar.png" alt="" />
              <p className="salon__checkout-general-select-technician-modal-card-name">
                {item?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
              </p>
            </Card>
          ))
        ) : (
          <></>
        )}
      </div>
    </StyledModal>
  );
};

export default ModalSelectTechnician;
