import { ReactNode } from 'react';
import {
  Appointment,
  AppointmentStatusEnum,
  BlockTime,
  Customer,
  DeleteBlockTimeDTOTypeEnum,
  Employee,
} from '../../../../apis/client-axios/api';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';

export type FilterTechnicianType = 'ALL' | 'AT_LEAST_ONE';

export type FilterTechnician = FilterTechnicianType | number | string;

export interface Filter {
  date?: string;
  technician?: FilterTechnician;
}

export interface FilterProps {
  filter: Filter;
  onChangeFilter: (filter: Filter) => void;
}

export type OpenPopoverType = 'ONLINE_APPOINTMENT' | 'CALLER_ID' | 'SETTING' | 'CHECKIN';

export type OpenSettingPopoverType = 'SETTING_DISPLAY' | 'SETTING_TURN';

export interface SampleCallerID {
  phone: string;
  name?: string;
  star?: number;
  id?: number;
}

export interface CustomCustomer extends Customer {
  customerRating?: number;
}

export interface CustomAppointment extends Appointment {
  customer: CustomCustomer;
}

export interface AppointmentEvent {
  id: number | string;
  start: Date;
  end: Date;
  title: string;
  resourceId: number | string;
  resource?: CustomAppointment;
  isBlockTime?: boolean;
  blockTime?: BlockTime;
  status?: AppointmentStatusEnum;
  customerRating?: number;
}

export interface AppointmentResource extends Pick<Employee, 'id' | 'name' | 'phoneNumber'> {
  resourceId: number | string;
  revenue: number;
  tasks: number;
  hours: number;
  resourceTitle: string;
  resource?: Employee;
  avatar?: string;
  index?: number;
}

export interface SidebarRenderButtonIcon<T> {
  icon: ReactNode;
  type: T;
  onClick: () => void;
  title: ReactNode;
  content: ReactNode;
  overlayClassName?: string;
  disabled?: boolean;
}

export interface BlockTimeDeleted {
  id?: number;
  type?: DeleteBlockTimeDTOTypeEnum;
}

export interface AppointmentBlockTime {
  time?: string;
  technicians: AppointmentResource[];
  blockTimeEdited?: BlockTime;
  blockTimeDeleted?: BlockTimeDeleted;
}

export type SlideActionType = 'NO_ACTION' | 'SWIPE' | 'CHOOSE' | 'PICK_CALENDAR';

export interface OpenModalTechnician {
  filter: boolean;
  detailDay?: AppointmentResource;
}

export interface DnDEventMobileRef {
  args?: EventInteractionArgs<AppointmentEvent>;
  isDnD: boolean;
}

export enum EventUpsertType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export interface EventUpsert {
  type: EventUpsertType;
  data: AppointmentEvent[];
}
