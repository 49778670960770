import { QRCode } from 'antd';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../../../../../components/StyledModal';
import { MAIN_ROUTES } from '../../../routes';
import './style.scss';

interface CheckInQRModalProps {
  open: boolean;
  onCancel: () => void;
}

const CheckInQRModal: FC<CheckInQRModalProps> = (props) => {
  const { open, onCancel } = props;

  const intl = useIntl();

  const QRCODE_VALUE = `${process.env.REACT_APP_URL}/${MAIN_ROUTES.CHECK_IN}${
    localStorage.getItem('salonId') ? `?salonId=${localStorage.getItem('salonId')}` : ''
  }`;

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancel}
      modalProps={{
        width: 360,
        title: null,
        footer: null,
        centered: true,
        rootClassName: 'salon__appointment-checkin-modal',
      }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="font-size-16 font-weight-600 color-292F33 p-b-12 m-b-38 salon__appointment-checkin-modal-heading">
          {intl.formatMessage({ id: 'appointment.modal.form.title.qrcode' })}
        </h3>
        <div className="salon__appointment-checkin-modal-qr-image">
          <div className="salon__appointment-checkin-modal-qr-container">
            <QRCode value={QRCODE_VALUE} bordered={false} />
          </div>
        </div>
        <p className="font-size-16 font-weight-300 color-292F33 m-b-0 salon__appointment-checkin-modal-subtitle z-index-10 font-style-italic">
          {intl.formatMessage({ id: 'appointment.modal.form.subtitle.qrcode' })}
        </p>
      </div>
    </StyledModal>
  );
};

export default CheckInQRModal;
