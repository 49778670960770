import { FC, TouchEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { SvgAppointmentStarIcon } from '../../../../../components/@svg/SvgAppointmentStarIcon';
import { SvgCloseIcon } from '../../../../../components/@svg/SvgCloseIcon';
import ColWrap from '../../../../../components/ColWrap';
import RowWrap from '../../../../../components/RowWrap';
import { ButtonIcon } from '../../../../../components/buttons/ButtonIcon';
import { SampleCallerID } from '../../models';

interface CallerIdModalProps {
  onClosePopover: () => void;
  onChangeDraggedCaller: (caller: SampleCallerID) => void;
  draggedCaller?: SampleCallerID;
}

const CallerIdContent: FC<CallerIdModalProps> = (props) => {
  const { onClosePopover, onChangeDraggedCaller, draggedCaller } = props;

  const intl = useIntl();
  const [callers, setCallers] = useState<Array<SampleCallerID>>([
    {
      name: 'David Johnson',
      star: 5,
      phone: '012-345-6789',
      id: 4,
    },
    {
      phone: '012-345-6789',
      id: 6,
    },
  ]);

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>, caller: SampleCallerID) => {
    if (!draggedCaller) {
      onChangeDraggedCaller(caller);
    }

    const touchLocation = e.targetTouches[0];

    const callerElement = document.getElementById(`caller_${caller.id}`) as HTMLElement;

    if (callerElement) {
      callerElement.style.left = touchLocation.pageX + 'px';
      callerElement.style.top = touchLocation.pageY + 'px';
    }
  };

  const handleTouchEndToDrop = (event: TouchEvent<HTMLDivElement>) => {
    const touches = event.changedTouches;
    const touch = touches[0];
    const simulatedEvent = document.createEvent('MouseEvent');

    simulatedEvent.initMouseEvent(
      'drop',
      true,
      true,
      window,
      1,
      touch.screenX,
      touch.screenY,
      touch.clientX,
      touch.clientY,
      false,
      false,
      false,
      false,
      0 /*left*/,
      null
    );

    touch.target.dispatchEvent(simulatedEvent);
  };

  return (
    <>
      <ButtonIcon
        icon={<SvgCloseIcon />}
        className="salon__appointment-caller-close"
        buttonProps={{
          onClick: onClosePopover,
        }}
      />

      <p className="font-size-14 font-weight-400 salon__appointment-caller-subtitle">
        {intl.formatMessage({ id: 'appointment.modal.caller.subtitle' })}
      </p>
      <RowWrap isGutter={false} isWrap isAutoFillRow={false} rowClassName="salon__appointment-caller-list">
        {callers.length === 0 ? (
          <ColWrap
            colProps={{
              className: 'font-size-16 font-weight-500 salon__appointment-caller-empty',
              span: 24,
            }}
          >
            {intl.formatMessage({ id: 'appointment.modal.caller.empty' })}
          </ColWrap>
        ) : (
          <div className="salon__appointment-caller-list-container">
            {callers.map((caller) => (
              <ColWrap
                key={caller.id}
                colProps={{
                  draggable: 'true',
                  className: 'salon__appointment-caller-item',
                  span: 24,
                  onDragStart: () => onChangeDraggedCaller(caller),
                  onTouchEnd: handleTouchEndToDrop,
                  onTouchMove: (e) => handleTouchMove(e, caller),
                }}
              >
                <RowWrap isGutter={false} isWrap={false} isAutoFillRow={false}>
                  <ColWrap
                    colProps={{
                      className: 'font-size-16 font-weight-700 salon__appointment-caller-name',
                      span: 21,
                    }}
                  >
                    {caller.name}
                  </ColWrap>
                  {caller.star && (
                    <ColWrap
                      colProps={{
                        className: 'salon__appointment-caller-star',
                        span: 3,
                      }}
                    >
                      {caller.star} <SvgAppointmentStarIcon wrapperClassName="salon__appointment-caller-star-icon" />
                    </ColWrap>
                  )}
                </RowWrap>
                <RowWrap isGutter={false} isWrap isAutoFillRow={false} styleFill="between">
                  <ColWrap
                    colProps={{
                      className: 'font-size-16 font-weight-500 salon__appointment-caller-number',
                      span: 24,
                    }}
                  >
                    {caller.phone}
                  </ColWrap>
                </RowWrap>
              </ColWrap>
            ))}
          </div>
        )}
      </RowWrap>
    </>
  );
};

export default CallerIdContent;
