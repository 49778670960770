import { FC } from 'react';
import { AppointmentResource } from '../../models';

interface MoreTechniciansProps {
  technicians: AppointmentResource[];
  onChangeTechnicianSelected: (technician: AppointmentResource) => void;
}

const MoreTechnicians: FC<MoreTechniciansProps> = ({ technicians, onChangeTechnicianSelected }) => {
  return (
    <div className="salon__appointment-calendar-blocktime-popover-list">
      {technicians.map((technician) => (
        <div
          key={technician.id}
          className="cursor-pointer salon__appointment-calendar-blocktime-popover-item"
          onClick={() => onChangeTechnicianSelected(technician)}
        >
          <span className="font-size-12 font-weight-400 salon__appointment-calendar-blocktime-popover-item-name">
            {technician.resourceTitle}
          </span>
        </div>
      ))}
    </div>
  );
};

export default MoreTechnicians;
