import { FC, ReactNode } from 'react';
import { formatNumberThousandWithDecimal } from '../../../utils';
import '../bill.scss';

interface BillItemProps {
  title: string | number;
  value?: number | string;
  ptsReward?: ReactNode;
  isSelfFill?: boolean;
  isNotMoney?: boolean;
}

const BillItem: FC<BillItemProps> = (props) => {
  const { title, value, ptsReward, isSelfFill, isNotMoney } = props;

  const renderValue = () => {
    if (isSelfFill) return '............................';
    else if (ptsReward) return ptsReward;
    else return !isNotMoney ? `$${formatNumberThousandWithDecimal(value ?? 0)}` : value;
  };

  return (
    <div className="m-t-b-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className="salon__print-bill-detail">{title}</p>
        <p className="salon__print-bill-detail">{renderValue()}</p>
      </div>
    </div>
  );
};

export default BillItem;
