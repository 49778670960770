import { ReactNode } from 'react';
import './technicianItem.scss';
import { Image } from 'antd';
export interface ITechnicianItemProps {
  imgSrc: string | undefined;
  imgSrcTitle: string | undefined;
  technicianName: string | undefined;
  onClick: () => void;
}

function TechnicianItem(props: ITechnicianItemProps) {
  const { imgSrc, imgSrcTitle, technicianName, onClick } = props;
  return (
    <div className="salon__technician-item-container" onClick={onClick}>
      <Image alt={imgSrcTitle} src={imgSrc} preview={false} className="salon__technician-item-avatar" />
      <p className="salon__technician-item-name">{technicianName}</p>
    </div>
  );
}
export default TechnicianItem;
