import { Card } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../../../../components/StyledModal';
import { IAppointmentBill, IListIdDisable, ITechnicianItem } from '../index';
import './style.scss';

interface Props {
  openModalSelect: boolean;
  idTechnician: string | number;
  listTechnician: ITechnicianItem[];
  appointmentBill: IAppointmentBill;
  listIdDisable: IListIdDisable;
  setListIdDisable: (listIdDisable: IListIdDisable) => void;
  setOpenModalSelect: (state: boolean) => void;
  setIdTechnician: (idTechnician: number | string) => void;
  setAppointmentBill: (appointmentBill: IAppointmentBill) => void;
}

const ModalSelectTechnician: FC<Props> = ({ ...props }) => {
  const {
    openModalSelect,
    idTechnician,
    listTechnician,
    appointmentBill,
    listIdDisable,
    setListIdDisable,
    setOpenModalSelect,
    setIdTechnician,
    setAppointmentBill,
  } = props;

  const intl = useIntl();
  const [listTechnicianBySKill, setListTechnicianBySKill] = useState<ITechnicianItem[]>([]);

  useEffect(() => {
    const listServiceIds: any[] = [];
    const findTechnician = appointmentBill.technician.find((itemFilter) => itemFilter.id === idTechnician);
    const findListService = findTechnician?.services ?? [];

    findListService.forEach((item) => {
      if (!item.isGeneralService) {
        listServiceIds.push(item.id);
      }
    });
    const filteredElements = listTechnician.filter((item) =>
      listServiceIds.every((skill) => item?.skills?.includes(skill))
    );
    setListTechnicianBySKill(filteredElements);
  }, [appointmentBill, idTechnician, listTechnician]);

  const addTechnician = (item: ITechnicianItem) => {
    const tempTechnician = listTechnicianBySKill.find((itemFilter) => itemFilter.id === idTechnician);
    const findIndexTechnician = appointmentBill.technician.findIndex((itemFilter) => itemFilter.id === idTechnician);
    const tempAppointment = appointmentBill;
    tempAppointment.technician[findIndexTechnician].defaultAvatar = item.defaultAvatar ?? '';
    tempAppointment.technician[findIndexTechnician].id = item.id;
    tempAppointment.technician[findIndexTechnician].name = item.name;
    tempAppointment.technician[findIndexTechnician].phoneNumber = item.phoneNumber;
    tempAppointment.technician[findIndexTechnician].skills = item.skills;
    tempAppointment.technician[findIndexTechnician].title = item.title;
    tempAppointment.technician.push({ ...tempTechnician, services: [] } as ITechnicianItem);
    const mergedTechnician = tempAppointment.technician.reduce((acc: ITechnicianItem[], cur: ITechnicianItem) => {
      const found = acc.find((item) => item.id === cur.id);
      if (found && found.services && cur.services) {
        found.services = [...found.services, ...cur.services];
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    setIdTechnician(item.id);
    setOpenModalSelect(false);
    setAppointmentBill({ ...tempAppointment, technician: mergedTechnician });
    setListIdDisable({
      ...listIdDisable,
      listIdTechnician: tempAppointment.technician.map((item) => item.id) as number[],
    });
  };

  return (
    <StyledModal
      isOpen={openModalSelect}
      onCancel={() => setOpenModalSelect(false)}
      modalProps={{
        title: (
          <div className="salon__checkout-change-technician-modal-heading">
            {intl.formatMessage({ id: 'checkout.select' })}
          </div>
        ),
        className: 'salon__checkout-general-select-technician-modal',
        width: 700,
        footer: null,
        centered: true,
      }}
    >
      <div className="salon__checkout-general-select-technician-modal-card">
        {listTechnicianBySKill && listTechnicianBySKill.length > 1 ? (
          listTechnicianBySKill.map((item) => (
            <Card
              className="salon__checkout-general-select-technician-modal-card-main"
              onClick={() => addTechnician(item)}
            >
              <img src="/assets/qr/avatar.png" alt="" />
              <p className="salon__checkout-general-select-technician-modal-card-name">
                {item?.name ?? intl.formatMessage({ id: 'checkout.unassigned' })}
              </p>
            </Card>
          ))
        ) : (
          <></>
        )}
      </div>
    </StyledModal>
  );
};

export default ModalSelectTechnician;
