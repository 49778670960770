import React from 'react';

export const SvgAppointmentBeingServedIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 1.83331C4.6 1.83331 4 2.43331 4 3.16665V5.27998C4 5.63331 4.14 5.97331 4.39333 6.22665L6.66667 8.49998L4.38667 10.78C4.14 11.0333 4 11.3733 4 11.7266V13.8333C4 14.5666 4.6 15.1666 5.33333 15.1666H10.6667C11.4 15.1666 12 14.5666 12 13.8333V11.7266C12 11.3733 11.86 11.0333 11.6133 10.7866L9.33333 8.49998L11.6067 6.23331C11.86 5.97998 12 5.63998 12 5.28665V3.16665C12 2.43331 11.4 1.83331 10.6667 1.83331H5.33333ZM10.6667 11.5V13.1666C10.6667 13.5333 10.3667 13.8333 10 13.8333H6C5.63333 13.8333 5.33333 13.5333 5.33333 13.1666V11.5L8 8.83331L10.6667 11.5ZM5.33333 5.49998L8 8.16665L10.6667 5.49998V3.83331C10.6667 3.46665 10.3667 3.16665 10 3.16665H6C5.63333 3.16665 5.33333 3.46665 5.33333 3.83331V5.49998Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
