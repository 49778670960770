import { FormInstance } from 'antd/lib';
import React, { FunctionComponent, useState, MutableRefObject } from 'react';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { formatNumberThousandWithDecimal, formatPhoneNumber } from '../../utils';

interface IProps<T extends object> {
  form: FormInstance;
  keyboardRef: any;
  inputName?: string;
  onNext: () => void;
  onFinish: () => void;
  buttonNext?: boolean;
  maxLength: number;
  keyboardProps?: KeyboardReactInterface['options'];
  isOrtherPaymentNumber?: boolean;
}

function KeyboardWrapper<T extends object>(props: IProps<T>) {
  const {
    keyboardRef,
    inputName,
    maxLength,
    onNext,
    keyboardProps,
    form,
    buttonNext,
    onFinish,
    isOrtherPaymentNumber,
  } = props;
  const [layoutName, setLayoutName] = useState('default');

  const onKeyPress = (button: string, e?: MouseEvent) => {
    if (button === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    } else if (button === '{next}') {
      e?.preventDefault();
      onNext();
    } else if (button === '{OK}') {
      e?.preventDefault();
      onFinish();
    } else if (button === '{backspace}') {
      const currentValue = form.getFieldValue(inputName);

      if (currentValue) {
        form.setFieldValue(inputName, currentValue.slice(0, -1));
      }
    } else if (button === '{space}') {
      const currentValue = form.getFieldValue(inputName) || '';
      const newValue = `${currentValue}${' '}`;
      form.setFieldValue(inputName, inputName === 'phoneNumber' ? currentValue : newValue);
    } else {
      const currentValue = form.getFieldValue(inputName) || '';
      const newValue = `${currentValue}${button}`;
      form.setFieldValue(inputName, inputName === 'phoneNumber' ? formatPhoneNumber(newValue) : newValue);
    }
  };

  const formatValueRtlWhenDelete = (currentValue?: string, button?: string) => {
    let value = '0.00';

    if (currentValue === undefined || currentValue === null) return '';

    let [integer, decimal] = currentValue.replace(/,/g, '').split('.');
    integer = integer ? integer : '';
    decimal = decimal ? decimal : '';

    if (button === '{backspace}') {
      const newValueDecimal = `${integer.length > 0 ? integer[integer.length - 1] : 0}${
        decimal.length > 0 ? decimal.slice(0, decimal.length - 1) : 0
      }`;

      let newValueInteger = `${integer.length > 0 ? integer.slice(0, integer.length - 1) : 0}`;

      if (integer.length === 1) {
        newValueInteger = `0${newValueInteger}`;
      }

      value = `${newValueInteger}.${newValueDecimal}`;

      if (value === '0.00') value = '';

      return value;
    } else return value;
  };

  const formatValueRtl = (currentValue?: string, button?: string, maxLength?: number) => {
    let value = '0.00';
    const firstRegex = /^0\.0[0-9]$/;
    const secondRegex = /^0\.[0-9]{2}$/;

    if (currentValue === undefined || currentValue === null) return value;

    if (maxLength) {
      const [integer] = currentValue.split('.');

      if (integer.length === maxLength) return currentValue;
    }

    const replaceCurrentValue = currentValue.replace('.', '');

    if (currentValue.length === 0) {
      if (button === '0' || button === '00') {
        value = '';
      } else {
        value = `0.0${button}`;
      }
    } else {
      if (currentValue.match(firstRegex)) {
        value = `0.${currentValue[currentValue.length - 1]}${button}`;

        if (button === '00') {
          value = `${currentValue[currentValue.length - 1]}.${button}`;
        }
      } else if (currentValue.match(secondRegex)) {
        value = `${replaceCurrentValue[1]}.${replaceCurrentValue[2]}${button}`;
      } else {
        value = `${replaceCurrentValue.slice(0, replaceCurrentValue.length - 1)}.${
          replaceCurrentValue[replaceCurrentValue.length - 1]
        }${button}`;

        if (button === '00') {
          value = `${replaceCurrentValue.slice(0, replaceCurrentValue.length)}.${button}`;
        }
      }
    }

    return value;
  };

  const onOrtherPaymentNumberPress = (button: string, e?: MouseEvent) => {
    const currentValue: string = form.getFieldValue(inputName) || '';

    //divide into integer and decimal part, like [10, 5] = 10.5.split('.')
    let [integer, decimal] = currentValue.replace(/,/g, '').split('.');
    integer = integer ? integer : '';
    decimal = decimal ? decimal : '';
    if (button === '{OK}') {
      e?.preventDefault();
      onFinish();
    } else if (button === '{backspace}') {
      const newValue = formatValueRtlWhenDelete(currentValue, button);

      form.setFieldValue(inputName, newValue === '' ? newValue : formatNumberThousandWithDecimal(newValue));
    } else {
      if (!/^\d+$/.test(button)) return false;
      if (maxLength) {
        //add value to integer part

        const value = formatValueRtl(currentValue, button, maxLength);

        form.setFieldValue(inputName, value);
      } else {
        const newValue = `${integer}${button}`.concat('.', decimal);
        form.setFieldValue(inputName, formatNumberThousandWithDecimal(newValue));
      }
    }
  };

  const customLayout = {
    default: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'q w e r t y u i o p - {shift}',
      'a s d f g h j k l , . @',
      'z x c v b n m _ {space} {next}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'Q W E R T Y U I O P - {shift}',
      'A S D F G H J K L , . @',
      'Z X C V B N M _ {space} {next}',
    ],
  };
  const customLayoutNoButtonNext = {
    default: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'q w e r t y u i o p - {shift}',
      'a s d f g h j k l , . @',
      'z x c v b n m _ {space} {OK}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'Q W E R T Y U I O P - {shift}',
      'A S D F G H J K L , . @',
      'Z X C V B N M _ {space} {OK}',
    ],
  };
  const customTheme = [
    { class: 'salon__blue', buttons: '@' },
    { class: 'salon__blue salon__next', buttons: '{next}' },
    { class: 'salon__blue salon__next', buttons: '{OK}' },
  ];

  const customDisplay = {
    '{space}': 'Space',
    '{backspace}': ' ',
    '{shift}': 'Caps',
    '{next}': 'Next',
    '{OK}': 'OK',
  };

  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      maxLength={maxLength}
      layoutName={layoutName}
      onKeyPress={isOrtherPaymentNumber ? onOrtherPaymentNumberPress : onKeyPress}
      layout={buttonNext ? customLayout : customLayoutNoButtonNext}
      buttonTheme={customTheme}
      display={customDisplay}
      inputName={inputName}
      preventMouseDownDefault={true}
      {...keyboardProps}
    />
  );
}

export default KeyboardWrapper;
