import { useIntl } from 'react-intl';
import { formatPhoneNumber } from '../../../utils';
import { PrintBillProps } from '../@types/bill';
import '../bill.scss';

function HeaderBill(props: Pick<PrintBillProps, 'salon' | 'customer'>) {
  const { salon, customer } = props;

  const intl = useIntl();

  return (
    <div className="salon__print-bill-header">
      <h2 className="salon__print-bill-title">{salon.name}</h2>
      {salon?.phoneNumber && (
        <p className="salon__print-bill-header-content">
          {intl.formatMessage({ id: 'print.bill.hotline' }, { phoneNumber: formatPhoneNumber(salon.phoneNumber) })}
        </p>
      )}
      {salon?.address && (
        <p className="salon__print-bill-header-content">
          {intl.formatMessage({ id: 'print.bill.address' }, { address: salon.address })}
        </p>
      )}
      <p className="salon__print-bill-header-content m-t-8">
        {intl.formatMessage({ id: 'print.bill.customer' }, { customer: customer?.name })}
      </p>
      {customer?.phoneNumber && (
        <p className="salon__print-bill-header-content">
          {intl.formatMessage(
            { id: 'print.bill.customerPhone' },
            {
              phoneNumber: !customer.isHideCustomerPhoneNumber
                ? formatPhoneNumber(customer?.phoneNumber)
                : `***-***-${customer?.phoneNumber.slice(6, 10)}`,
            }
          )}
        </p>
      )}
    </div>
  );
}

export default HeaderBill;
