import { FC } from 'react';
import ScrollCalendar from './ScrollCalendar';
import { FilterProps } from '../models';

interface AppointmentFilterProps extends FilterProps {}

const AppointmentFilter: FC<AppointmentFilterProps> = ({ filter, onChangeFilter }) => {
  return (
    <div className="salon__appointment-filter">
      <ScrollCalendar filter={filter} onChangeFilter={onChangeFilter} />
    </div>
  );
};

export default AppointmentFilter;
