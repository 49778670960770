import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { settingApi } from '../../../../apis';
import {
  SettingTerminalDeviceIdForEnum,
  UpdateTerminalDeviceSettingDto,
  UpdateTerminalDeviceSettingDtoDeviceConnectedEnum,
} from '../../../../apis/client-axios';
import ColWrap from '../../../../components/ColWrap';
import { FormSwitch } from '../../../../components/Form/FormSwitch';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import NotificationSuccess from '../../../../components/HandleShowNotiSuccess';
import RowWrap from '../../../../components/RowWrap';
import StyledButtonTab from '../../../../components/StyledButtonTab';
import { StyledModal } from '../../../../components/StyledModal';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { useAppDispatch } from '../../../../store';
import { resetDefault } from '../../../../store/cloverSlice';
import { QUERY_SETTING } from '../../../../utils/constant';
import { CloverSetting, FormDataClover } from '../../setting/terminalDeviceSetting/Clover';
import { FormDataPax, PaxSetting } from '../../setting/terminalDeviceSetting/Pax';
import './style.scss';

interface Props {
  open: boolean;
  onCancelModal: () => void;
  onChangeSettingInModal: (value: boolean) => void;
}
interface FormData {
  terminalConnection: boolean;
}
enum TerminalDeviceSettingsTabsEnum {
  Clover = 'Clover',
  Pax = 'Pax',
}
const listTerminalDeviceSettingTabs: TerminalDeviceSettingsTabsEnum[] = [
  TerminalDeviceSettingsTabsEnum.Clover,
  TerminalDeviceSettingsTabsEnum.Pax,
];

const n = (key: keyof FormData) => key;
const nClover = (key: keyof FormDataClover) => key;
const nPax = (key: keyof FormDataPax) => key;

const ModalTerminalDeviceSettings: FC<Props> = (props) => {
  const { open, onCancelModal, onChangeSettingInModal } = props;

  const intl = useIntl();

  const [form] = Form.useForm<FormData>();
  const [formClover] = Form.useForm<FormDataClover>();
  const [formPax] = Form.useForm<FormDataPax>();

  const terminalConnection = Form.useWatch(n('terminalConnection'), form) as boolean | undefined;

  const [currentTab, setCurrentTab] = useState<TerminalDeviceSettingsTabsEnum>(TerminalDeviceSettingsTabsEnum.Clover);

  const dispatch = useAppDispatch();

  const { data: setting, refetch: onRefechSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
  });

  useEffect(() => {
    form.setFieldValue(n('terminalConnection'), setting?.data?.settingTerminalDevice?.terminalConnection);

    if (setting?.data?.settingTerminalDevice) {
      const data = setting?.data?.settingTerminalDevice;

      formClover.setFieldsValue({
        [nClover('cloverApplicationID')]: data.cloverApplicationID,
        [nClover('cloverDeviceAddress')]: data.cloverDeviceAddress,
        [nClover('IdFor')]: data.IdFor,
        [nClover('cloverPosName')]: data.cloverPosName,
        [nClover('cloverSerialNumber')]: data.cloverSerialNumber,
        [nClover('notCustomerPrint')]: data.notCustomerPrint,
        [nClover('notCustomerobtain')]: data.notCustomerobtain,
        [nClover('signatureLocation')]: data.signatureLocation,
        [nClover('tipLocation')]: data.tipLocation,
      });

      formPax.setFieldsValue({
        [nPax('paxDeviceAddress')]: data.paxDeviceAddress,
      });
    }
  }, [setting]);

  const updateSetting = useMutation(
    (payload: { updateDTO: UpdateTerminalDeviceSettingDto }) =>
      settingApi.settingControllerUpdateTerminalDeviceSetting(payload.updateDTO),
    {
      onSuccess: () => {
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'setting.services.save.success' }) });
        onRefechSetting();
      },
      onError: ({ response }) => {
        NotificationError({ contentNoti: response?.data?.message });
      },
    }
  );

  const renderTab = () => {
    switch (currentTab) {
      case TerminalDeviceSettingsTabsEnum.Clover:
        return <CloverSetting disableInput={!terminalConnection} form={formClover} />;
      case TerminalDeviceSettingsTabsEnum.Pax:
        return <PaxSetting disableInput={!terminalConnection} form={formPax} />;
      default:
        return;
    }
  };

  const onFinish = () => {
    const dataClover = formClover.getFieldsValue();
    const dataPax = formPax.getFieldsValue();

    if (
      dataClover.cloverDeviceAddress !== setting?.data?.settingTerminalDevice.cloverDeviceAddress ||
      dataClover.cloverApplicationID !== setting?.data?.settingTerminalDevice.cloverApplicationID ||
      currentTab !== TerminalDeviceSettingsTabsEnum.Clover
    ) {
      dispatch(resetDefault());
      onChangeSettingInModal(true);
    }

    const dataSubmit: UpdateTerminalDeviceSettingDto = {
      cloverApplicationID: dataClover?.cloverApplicationID,
      IdFor: dataClover?.IdFor || SettingTerminalDeviceIdForEnum.Development,
      cloverDeviceAddress: dataClover?.cloverDeviceAddress,
      tipLocation: dataClover?.tipLocation,
      paxDeviceAddress: dataPax?.paxDeviceAddress,
      poyntDeviceAddress: undefined,
      signatureLocation: dataClover?.signatureLocation,
      notCustomerPrint: dataClover?.notCustomerPrint || false,
      notCustomerobtain: dataClover?.notCustomerobtain || false,
      terminalConnection: !!terminalConnection,
      cloverPosName: dataClover.cloverPosName,
      cloverSerialNumber: dataClover.cloverSerialNumber,
      cloverAuthToken:
        dataClover.cloverDeviceAddress !== setting?.data?.settingTerminalDevice.cloverDeviceAddress ||
        dataClover.cloverApplicationID !== setting?.data?.settingTerminalDevice.cloverApplicationID
          ? null
          : setting?.data?.settingTerminalDevice?.cloverAuthToken,
      deviceConnected:
        currentTab === TerminalDeviceSettingsTabsEnum.Clover
          ? UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Clover
          : UpdateTerminalDeviceSettingDtoDeviceConnectedEnum.Pax,
    };

    updateSetting.mutate({ updateDTO: dataSubmit });
  };

  return (
    <FormWrap
      name="modalTerminalDeviceSetting"
      layout="vertical"
      autoComplete="off"
      requiredMark={false}
      form={form}
      onFinish={onFinish}
    >
      <StyledModal
        isOpen={open}
        onCancel={onCancelModal}
        modalProps={{
          title: (
            <div className="salon__checkout-tax-ticket-modal-heading">
              {intl.formatMessage({ id: 'checkout.terminalDeviceSetting' })}
            </div>
          ),
          className: 'salon__checkout-terminal-device-setting-modal',
          width: 700,
          footer: (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="font-weight-600 font-size-16">
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.certificates' })}
                </span>
              </div>
              <div className="d-flex gap-12 align-items-center">
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.option.technician.cancel' })}
                  buttonProps={{
                    className: 'width-100 height-38',
                    onClick: onCancelModal,
                  }}
                  isPrimary={false}
                  isButtonCancel={true}
                />
                <ButtonStyled
                  content={intl.formatMessage({ id: 'checkout.option.technician.save' })}
                  buttonProps={{
                    className: 'width-100 height-38 m-0',
                    htmlType: 'submit',
                    onClick: onFinish,
                  }}
                  isPrimary={true}
                />
              </div>
            </div>
          ),
        }}
      >
        <FormWrap
          form={form}
          name="terminalDeviceSettingCheckout"
          layout="vertical"
          className="w-100 salon__checkout-tax-ticket-modal-form m-t-7-n"
        >
          <RowWrap isAutoFillRow={false} isWrap isGutter gutter={20}>
            <ColWrap
              colProps={{
                span: 24,
              }}
            >
              <FormSwitch
                name={n('terminalConnection')}
                content={
                  <span className={`${!terminalConnection ? '' : 'color-B4B6B8'}`}>
                    {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.terminalconnection' })}
                  </span>
                }
                className="salon__promotions__fee-n-cash-discounts__switch"
                formItemProps={{
                  className: 'm-b-0',
                }}
              />
            </ColWrap>
            <ColWrap
              colProps={{
                span: 9,
              }}
            >
              <div>
                <span className={`${!terminalConnection ? 'font-weight-400' : 'font-weight-400 color-B4B6B8'}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.yourTerminal' })}
                </span>
              </div>
              <StyledButtonTab
                dataArr={listTerminalDeviceSettingTabs}
                setValueActive={setCurrentTab as (value: string) => void}
                valueActive={currentTab}
                className="p-t-b-12 salon__checkout-terminal-device-setting-modal__tabs-list"
              />
            </ColWrap>
            <ColWrap
              colProps={{
                span: 24,
                className: 'max-height-520 overflow-auto salon__checkout-terminal-device-setting-modal__col',
              }}
            >
              {renderTab()}
            </ColWrap>
          </RowWrap>
        </FormWrap>
      </StyledModal>
    </FormWrap>
  );
};

export default ModalTerminalDeviceSettings;
