import React from 'react';

export const SvgLogoutCheckinIcon: React.FC = () => (
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9857 0.337966C11.6766 0.34147 11.3814 0.467802 11.1649 0.689294C10.9484 0.910786 10.8281 1.20939 10.8304 1.5197V10.8986C10.8304 11.2097 10.9535 11.508 11.1727 11.728C11.3918 11.9479 11.689 12.0715 11.9989 12.0715C12.3088 12.0715 12.6061 11.9479 12.8252 11.728C13.0443 11.508 13.1674 11.2097 13.1674 10.8986V1.5197C13.1686 1.36381 13.1388 1.20924 13.0798 1.06504C13.0208 0.92084 12.9337 0.789897 12.8237 0.679869C12.7136 0.56984 12.5829 0.482933 12.439 0.424227C12.2951 0.365522 12.141 0.336197 11.9857 0.337966ZM18.7939 2.68379C18.7557 2.68191 18.7174 2.68191 18.6793 2.68379C18.4478 2.70311 18.2274 2.7912 18.0461 2.93682C17.8648 3.08243 17.7308 3.27897 17.6613 3.50134C17.5917 3.72372 17.5897 3.96186 17.6556 4.18537C17.7215 4.40888 17.8522 4.60762 18.0311 4.75623C20.055 6.48033 21.3337 9.04663 21.3337 11.926C21.3337 17.1291 17.1712 21.3225 12.0033 21.3225C10.7739 21.3202 9.55695 21.0754 8.42224 20.6021C7.28753 20.1288 6.25733 19.4363 5.39063 18.5643C4.52394 17.6923 3.83777 16.6579 3.37143 15.5203C2.90509 14.3827 2.66773 13.1643 2.67295 11.9348C2.67295 9.06868 3.93406 6.52001 5.93595 4.79151C6.06048 4.69408 6.16406 4.57233 6.24041 4.43361C6.31677 4.29488 6.36433 4.14207 6.38021 3.98438C6.3961 3.8267 6.37998 3.66742 6.33283 3.51616C6.28568 3.3649 6.20849 3.2248 6.1059 3.10432C6.00332 2.98384 5.87747 2.88548 5.73596 2.81518C5.59445 2.74488 5.44021 2.70409 5.28256 2.69528C5.12492 2.68648 4.96712 2.70983 4.81872 2.76394C4.67031 2.81805 4.53437 2.90178 4.4191 3.01009C3.13703 4.11489 2.10852 5.4834 1.40384 7.02214C0.699156 8.56087 0.334897 10.2336 0.335945 11.926C0.332408 15.0291 1.55873 18.0072 3.74637 20.2081C5.934 22.4089 8.90465 23.6532 12.0078 23.6684C15.1104 23.6544 18.0808 22.4102 20.2673 20.2088C22.4538 18.0075 23.6778 15.0287 23.6707 11.926C23.6707 8.34552 22.0701 5.12663 19.5435 2.97482C19.3358 2.79189 19.0702 2.68878 18.7939 2.68379Z"
        fill="#DBDDDF"
      />
    </svg>
  </span>
);
