import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Result, Spin } from 'antd';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { appointmentApi, customerApi } from '../../../../../apis';
import { Appointment, AppointmentStatusEnum } from '../../../../../apis/client-axios';
import { SvgAddMoreDataIconSmall } from '../../../../../components/@svg/SvgAddMoreDataIconSmall';
import CheckInCard from '../../../../../components/CheckinCard';
import { FormInputSearch } from '../../../../../components/Form/FormInputSearch';
import FormWrap from '../../../../../components/FormWrap';
import NotificationError from '../../../../../components/HandleShowNotiError';
import SearchError from '../../../../../components/SearchError';
import StyledHeader from '../../../../../components/StyledHeader';
import { QUERY_APPOINTMENTS, statusMappings } from '../../../../../utils/constant';
import AppointmentModified from '../../../appointment/AppointmentModal';
import './checkInManager.scss';
import { SvgAddMoreDataIconExSmall } from '../../../../../components/@svg/SvgAddMoreDataIconExSmall';
import {
  DATE_FORMAT,
  DATE_FORMAT_FULL_DATE,
  formatDateQuery,
  formatDateTimeZoneByFormatString,
  timeZoneSalon,
  toDayjsTimeZone,
} from '../../../../../utils';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Permission } from '../../../../../utils/permission';
import { CustomAppointment } from '../../../appointment/models';
import PageContainer from '../../../../../components/PageContainer';

interface Props {}

type ColumnsType = {
  [status: string]: Appointment[];
};

interface Destination {
  droppableId: AppointmentStatusEnum;
  index: number;
}

interface IFilter {
  page: number;
  sort: string;
  fullTextSearch: string;
  salonId: number | string;
}

interface GroupData {
  [status: string]: Appointment[];
}

const initialFilters: IFilter = {
  page: 1,
  sort: '',
  fullTextSearch: '',
  salonId: '',
};

const desiredStatuses = ['Waiting_Confirm', 'Confirmed', 'Checked_in', 'Being_served', 'Completed', 'Canceled'];

export const CompletedList: FC<Props> = () => {
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);
  const isHavingPermission = salonPermission?.includes(Permission.CheckIn.CheckIn);
  const isHavingEditAppointmentPermission = salonPermission?.includes(Permission.Appointment.CRUCAppointment);
  const [filters, setFilters] = useState(initialFilters);
  const [expandedTagId, setExpandedTagId] = useState<number | null>(null);
  const [isModalOpen, setIsCreateModalOpen] = useState(false);
  const [editEvent, setEditEvent] = useState<Appointment>();
  const [statusCounts, setStatusCounts] = useState<Record<string, number>>({});
  const [customerHistory, setCustomerHistory] = useState<Appointment[] | undefined>();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const appointmentRef = useRef<Appointment[]>([]);

  const {
    data: listAppointments,
    // isFetching: isTechnicians,
    isLoading: isLoadingListAppointments,
    refetch: handleRefetchlistAppointments,
  } = useQuery({
    queryKey: [QUERY_APPOINTMENTS, filters],
    staleTime: 1000,
    enabled: isHavingPermission,
    queryFn: () =>
      appointmentApi.appointmentControllerGet(
        1,
        formatDateTimeZoneByFormatString(DATE_FORMAT, null),
        0,
        '',
        filters.fullTextSearch
      ),
  });

  const intl = useIntl();

  useEffect(() => {
    if (listAppointments?.data?.content && listAppointments?.data?.content?.length >= 0) {
      setAppointments(listAppointments?.data?.content);
    }
  }, [listAppointments?.data?.content]);

  const onFinishSearch = ({ fullTextSearch }: { fullTextSearch: string }) => {
    setFilters({ ...filters, fullTextSearch: fullTextSearch });
  };

  const [formFilterCheckIn] = Form.useForm();
  const timeout: any = useRef(null);

  const mutationChangeStatusAppoiment = useMutation(
    (input: { dropAppoiment: Appointment; destination: Destination }) =>
      appointmentApi.appointmentControllerChangeStatusAppointment(
        String(input.dropAppoiment.id),
        { status: input.destination.droppableId },
        input.dropAppoiment.salonId,
        undefined
      ),
    {
      onSuccess: () => {
        setAppointments(appointmentRef.current);
        handleRefetchlistAppointments();
      },
      onError: (error) => {
        NotificationError({ contentNoti: (error as any)?.response?.data?.message });
      },
      onSettled: () => {
        appointmentRef.current = [];
      },
    }
  );

  const onDragEnd = (result: DropResult) => {
    if (isHavingEditAppointmentPermission) {
      const { source, destination }: { source: DraggableLocation; destination?: DraggableLocation | null } = result;

      if (!destination) return;

      const sourceDroppableId: AppointmentStatusEnum = source.droppableId as AppointmentStatusEnum;
      const destinationDroppableId: AppointmentStatusEnum = destination.droppableId as AppointmentStatusEnum;

      if (sourceDroppableId !== destinationDroppableId) {
        const dropAppoiment = appointments.filter((appointment) => appointment.status === source.droppableId)[
          source.index
        ];

        const correctDestination: Destination = {
          droppableId: destinationDroppableId,
          index: destination.index,
        };

        const copyAppointment = [...appointments];
        const findIndex = copyAppointment.findIndex((appointment) => appointment.id === dropAppoiment.id);

        if (
          destination.droppableId === 'Completed' ||
          source.droppableId === 'Completed' ||
          source.droppableId === 'Canceled' ||
          (destination.droppableId === 'Being_served' && (!dropAppoiment.services.length || !dropAppoiment.technician))
        ) {
          return;
        }

        if (findIndex > -1) {
          copyAppointment[findIndex] = { ...dropAppoiment, status: destination.droppableId as AppointmentStatusEnum };
          appointmentRef.current = copyAppointment;
          mutationChangeStatusAppoiment.mutate({ dropAppoiment, destination: correctDestination });
          const newStatusCounts = getStatusCounts();
          setStatusCounts(newStatusCounts);
        }
      }
    } else NotificationError({ contentNoti: intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' }) });
  };

  useEffect(() => {
    const newStatusCounts = getStatusCounts();
    setStatusCounts(newStatusCounts);
  }, [appointments]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isModalOpen) {
  //       handleRefetchlistAppointments();
  //     }
  //   }, 500);
  // }, [isModalOpen]);

  const getStatusCounts = () => {
    const statusCounts: Record<string, number> = {};

    if (appointments) {
      appointments.forEach((appointment: Appointment) => {
        const { status } = appointment;
        if (statusCounts[status]) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      });
    }

    return statusCounts;
  };

  // const statusCounts = getStatusCounts();

  function convertStatus(status: string) {
    return statusMappings[status] || status;
  }

  const toggleTagExpansion = async (tag: Appointment) => {
    try {
      const response = await customerApi.customerControllerOldCustomerCheckin(String(tag.customer.id), tag.salonId);
      const historyData = response.data.content;
      if (historyData?.length) {
        setCustomerHistory(historyData);
        if (expandedTagId === tag.id) {
          setExpandedTagId(null);
        } else {
          setExpandedTagId(tag.id);
        }
      }
    } catch (error) {}
  };
  const groupByTime = (historyData: Appointment[] | undefined) => {
    const groupedMap = new Map<string, Appointment[]>();

    if (historyData) {
      historyData.forEach((history) => {
        const time = history.timeStart.substring(0, 16);
        if (!groupedMap.has(time)) {
          groupedMap.set(time, []);
        }
        groupedMap.get(time)?.push(history);
      });
    }
    return groupedMap;
  };

  const debounceSearch = useCallback(
    (value: string) => {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => {
        setFilters((prev) => ({ ...prev, fullTextSearch: value ?? '' }));
      }, 500);
    },
    [timeout]
  );

  const handleChangeSearch = useCallback(
    (value: string) => {
      debounceSearch(value);
    },
    [filters?.fullTextSearch]
  );

  return (
    <Spin
      spinning={mutationChangeStatusAppoiment.isLoading || (isLoadingListAppointments && isHavingPermission)}
      size="large"
    >
      {isModalOpen && (
        <AppointmentModified
          open={isModalOpen}
          setOpenModal={setIsCreateModalOpen}
          appointment={editEvent}
          setAppointment={setEditEvent}
        />
      )}

      <StyledHeader
        content={intl.formatMessage({ id: 'checkIn.checkin' })}
        extraButton={
          isHavingPermission && (
            <FormWrap
              name="formFilterCheckIn"
              form={formFilterCheckIn}
              initialValues={{ remember: true }}
              onFinish={onFinishSearch}
              autoComplete="off"
              className="salon__search-check-in-form-search"
            >
              <FormInputSearch
                name={'fullTextSearch'}
                inputProps={{
                  placeholder: intl.formatMessage({ id: 'checkIn.placeholder.search' }),
                  className: 'width-600 ',
                  onChange: (e) => {
                    handleChangeSearch(e.target.value);
                  },
                }}
                formItemProps={{
                  rules: [
                    {
                      transform: (value) => {
                        return value?.trim();
                      },
                    },
                  ],
                  className: 'm-0',
                }}
              />
            </FormWrap>
          )
        }
      />
      {isHavingPermission ? (
        filters.fullTextSearch && listAppointments && listAppointments.data.content?.length === 0 ? (
          <SearchError content={intl.formatMessage({ id: 'common.searchError' })} />
        ) : (
          <div className="salon__check-in">
            <div className="salon__check-in-check-in">
              <DragDropContext onDragEnd={onDragEnd}>
                {desiredStatuses.map((status, index) => (
                  <div className="salon__check-in-check-in-container" key={status}>
                    <div title={convertStatus(status)} className="salon__check-in-check-in-container-card">
                      <div className="salon__check-in-check-in-container-card-titles">
                        <div className="salon__check-in-check-in-container-card-titles-border">
                          <p className="salon__check-in-check-in-container-card-titles-border-title">
                            {convertStatus(status)} ({statusCounts[status] || 0})
                          </p>
                        </div>
                      </div>
                      <Droppable droppableId={`${status}`} key={status}>
                        {(providedDrop: DroppableProvided) => (
                          <div
                            {...providedDrop.droppableProps}
                            ref={providedDrop.innerRef}
                            className="salon__check-in-check-in-container-card-main"
                          >
                            {appointments.filter((appointment) => appointment.status === status).length > 0 ? (
                              appointments
                                .filter((appointment) => appointment.status === status)
                                .map((data: CustomAppointment, index: number) => (
                                  <Draggable draggableId={data.id.toString()} index={index} key={data.id}>
                                    {(providedDrag: DraggableProvided) => (
                                      <div className="m-l-8 m-b-10">
                                        <CheckInCard
                                          providedDrag={providedDrag}
                                          customerName={data.customer.name}
                                          estimate={data.estimate}
                                          rate={data.customer?.customerRating || 0}
                                          serviceName={data.services.map((item) => item.name).join(', ')}
                                          status={data.status}
                                          timeStart={toDayjsTimeZone(data.timeStart).toISOString()}
                                          technicianName={data.technician?.name}
                                          expandedTagId={expandedTagId}
                                          dataHistory={groupByTime(customerHistory)}
                                          idService={data.id}
                                          onClickHistory={() => {
                                            toggleTagExpansion(data);
                                          }}
                                          onClickDetail={() => {
                                            setEditEvent({
                                              ...data,
                                              timeStart: dayjs(
                                                dayjs(data.timeStart).tz(timeZoneSalon).format(DATE_FORMAT_FULL_DATE),
                                                DATE_FORMAT_FULL_DATE
                                              ).toISOString(),
                                            });
                                            setIsCreateModalOpen(true);
                                          }}
                                          data={data}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                            ) : (
                              <div className="salon__check-in-check-in-container-card-main-icon-more">
                                <SvgAddMoreDataIconExSmall />
                              </div>
                            )}
                            {providedDrop.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                ))}
              </DragDropContext>
            </div>
          </div>
        )
      ) : (
        <PageContainer className="p-t-20 p-b-20" marginTop={39}>
          <Result
            status="error"
            title={intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' })}
          ></Result>
        </PageContainer>
      )}
    </Spin>
  );
};
