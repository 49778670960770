import React from 'react';

export const SvgPrevSlice = () => (
  <span>
    <svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/navigation/chevron_left_24px">
        <path
          id="icon/navigation/chevron_left_24px_2"
          d="M23.125 10.8952C22.837 10.5909 22.4459 10.4199 22.0381 10.4199C21.6303 10.4199 21.2393 10.5909 20.9513 10.8952L13.875 18.354C13.2738 18.9877 13.2738 20.0115 13.875 20.6452L20.9513 28.104C21.5525 28.7377 22.5238 28.7377 23.125 28.104C23.7263 27.4702 23.7263 26.4465 23.125 25.8127L17.1433 19.4915L23.125 13.1865C23.7263 12.5527 23.7108 11.5127 23.125 10.8952Z"
          fill="#F2F2F2"
        />
      </g>
    </svg>
  </span>
);

export default SvgPrevSlice;
