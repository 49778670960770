import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Information from './information';
import CheckInConfirm from './checkInConfirm';
import { BOOKING_ONLINE_ROUTES } from './bookingOnlineConstants';

const BookingOnlineRoutes = () => (
  <Routes>
    <Route path={BOOKING_ONLINE_ROUTES.HOME} element={<Information />}></Route>
    <Route path={BOOKING_ONLINE_ROUTES.INFORMATION} element={<Information />}></Route>
    <Route path={BOOKING_ONLINE_ROUTES.CONFIRM} element={<CheckInConfirm />}></Route>
  </Routes>
);

export default BookingOnlineRoutes;
