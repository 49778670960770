import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import '../../bookingOnline/checkInConfirm/checkInConfirm.scss';
import { useParams } from 'react-router-dom';

export const CheckInConfirm = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(false);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!status) setStatus(true);
    }, 10000);

    const onMouseDown = () => {
      clearInterval(myInterval);
      myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 10000);
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [status]);

  return (
    <section className="salon_confirm_qr">
      <div className="salon_confirm_qr-img top">
        <picture>
          <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-top-sp.png" />
          <img src="/assets/qr/img-qr-top.png" alt="" />
        </picture>
      </div>
      <div className="salon_confirm_qr-main">
        <div className="salon_confirm_qr-main__image">
          <img src="/assets/qr/thankyou.svg" alt="" />
        </div>
        <p className="salon_confirm_qr-main__image-des">
          {intl.formatMessage({ id: 'bookingOnline.checkin.confirm.des' })}
        </p>
        <p className="salon_confirm_qr-main-title">{'We will contact you to confirm your appointment soon!'}</p>
      </div>
      <div className="salon_confirm_qr-img bottom">
        <picture>
          <source media="(max-width: 767px)" srcSet="/assets/qr/img-qr-bottom-sp.png" />
          <img src="/assets/qr/qr-bottom-confirm.png" alt="" />
        </picture>
      </div>
    </section>
  );
};
