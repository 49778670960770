import React from 'react';

interface SvgAppointmentPlusIconProps {
  fill?: string;
  wrapperClassName?: string;
}

export const SvgAppointmentPlusIcon = ({ fill = 'white', wrapperClassName = '' }: SvgAppointmentPlusIconProps) => (
  <span className={wrapperClassName}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M8.2125 5.9124H0.9125C0.413667 5.9124 0 5.49874 0 4.9999C0 4.50107 0.413667 4.0874 0.9125 4.0874H8.2125C8.71133 4.0874 9.125 4.50107 9.125 4.9999C9.125 5.49874 8.71133 5.9124 8.2125 5.9124Z"
        fill={fill}
      />
      <path
        d="M4.56289 9.5625C4.06406 9.5625 3.65039 9.14883 3.65039 8.65V1.35C3.65039 0.851167 4.06406 0.4375 4.56289 0.4375C5.06172 0.4375 5.47539 0.851167 5.47539 1.35V8.65C5.47539 9.14883 5.06172 9.5625 4.56289 9.5625Z"
        fill={fill}
      />
    </svg>
  </span>
);
