import { Form, FormItemProps } from 'antd';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import React, { ReactNode } from 'react';
import './FormInputNumberFormat.scss';
type Props = {
  name: string;
  label?: ReactNode | string;
  isRequired?: boolean;
  formItemProps?: FormItemProps;
  patternFormat?: PatternFormatProps;
  formatType?: string;
  prefix?: ReactNode | string;
  suffix?: ReactNode | string;
};

export const FormInputNumberFormat: React.FC<Props> = ({
  formItemProps,
  patternFormat,
  name,
  label,
  prefix,
  suffix,
  ...rest
}) => {
  return (
    <div className="salon__form-input-number-container">
      {/* {label ? (
        <label htmlFor={name} className="p-b-8 salon__form-input-number-container-label">
          {label}
          {formItemProps?.required ? <span className="salon__form-input-number-label">*</span> : ''}
        </label>
      ) : (
        <></>
      )} */}
      <div
        className={`salon__form-input-number-format ${
          patternFormat?.readOnly ? 'salon__form-input-number-format-read-only' : ''
        } ${patternFormat?.disabled ? 'salon__form-input-number-format-disabled' : ''}`}
      >
        <Form.Item
          label={label}
          name={name}
          {...formItemProps}
          className={
            (formItemProps?.className ?? '') + (prefix || suffix ? ' salon__form-input-number-format-with-icon' : '')
          }
        >
          {prefix ? <div className="salon__form-input-number-format-with-icon__prefix">{prefix}</div> : <></>}
          <PatternFormat format="" /* format value here is required but DO NOT defined*/ {...patternFormat} />
          {suffix ? <div className="salon__form-input-number-format-with-icon__suffix">{suffix}</div> : <></>}
        </Form.Item>
      </div>
    </div>
  );
};
