import React from 'react';

export const SvgAppointmentPullUpIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.89 12.2962L12 8.40624L8.11 12.2962C7.72 12.6862 7.09 12.6862 6.7 12.2962C6.31 11.9062 6.31 11.2762 6.7 10.8862L11.3 6.29624C11.4868 6.10899 11.7405 6.00375 12.005 6.00375C12.2695 6.00375 12.5232 6.10899 12.71 6.29624L17.3 10.8862C17.69 11.2762 17.69 11.9062 17.3 12.2962C16.91 12.6862 16.28 12.6862 15.89 12.2962ZM18 16.9962C18 17.5462 17.55 17.9962 17 17.9962H7C6.45 17.9962 6 17.5462 6 16.9962C6 16.4462 6.45 15.9962 7 15.9962H17C17.55 15.9962 18 16.4462 18 16.9962Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  </span>
);
