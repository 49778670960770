import { Spin, Steps } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { CheckInConfirm } from './CheckInConfirm';
import { useIntl } from 'react-intl';
import PickAServiceAndTechnician from './PickAServiceAndTechnician';
import YourInformation from './YourInformation';
import Deposit from './Deposit';
import './onlineAppointmentNew.scss';
import { SvgAddressIcon } from '../../../components/@svg/SvgAddressIcon';
import { SvgPhoneNumberIcon } from '../../../components/@svg/SvgPhoneNumberIcon';
import { QUERY_LIST_TECHNICIAN, QUERY_SALONS_DETAIL, QUERY_SERVICES, QUERY_SETTING } from '../../../utils/constant';
import { useMutation, useQuery } from '@tanstack/react-query';
import { customerApi, employeeApi, salonApi, servicesApi, settingApi } from '../../../apis';
import { CheckInAppointment, CheckInBulkDTO, Employee, ServiceItems } from '../../../apis/client-axios';
import NotificationError from '../../../components/HandleShowNotiError';
import { DATE_FORMAT_FULL_DATE, formatDateTimeUTC, timeZoneSalon, toDayjsByTimeZoneServer } from '../../../utils';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
export interface Booking {
  id: number;
  availableTime?: string;
  service?: ServiceItems;
  technician?: Employee;
  isFocus: boolean;
  isAnyTechnician: boolean;
  isAppointment: boolean;
  appointmentServices?: ServiceItems[];
}
export interface DataCustomer {
  customerName: string;
  customerPhone: string;
  birthDay: string;
  customerEmail: string;
  receiveMessagesByPhone: boolean;
  receivePromotionViaEmail: boolean;
  customerNote: string;
  acceptAutomatedTextMessage: boolean;
}
const StepTitle = {
  pickAServiceAndTechnician: 'pickAServiceAndTechnician',
  yourInformation: 'yourInformation',
  deposit: 'deposit',
  submit: 'submit',
  depositAndSubmit: 'depositAndSubmit',
};

const ListStep = [StepTitle.pickAServiceAndTechnician, StepTitle.yourInformation, StepTitle.deposit];

const OnlineAppointmentNew = () => {
  const intl = useIntl();
  const [step, setStep] = useState<number>(0);
  const refPage = useRef<HTMLElement | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [dataCustomer, setDataCustomer] = useState<DataCustomer>({
    customerName: '',
    customerPhone: '',
    birthDay: '',
    customerEmail: '',
    receiveMessagesByPhone: false,
    receivePromotionViaEmail: false,
    customerNote: '',
    acceptAutomatedTextMessage: false,
  });
  const { id } = useParams();

  const { data: dataTechnicians, isLoading: isLoadingTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(undefined, undefined, undefined, undefined, Number(id)),
    staleTime: 1000,
  });

  const { data: dataServices, isLoading: isLoadingService } = useQuery({
    queryKey: [QUERY_SERVICES],
    queryFn: () => servicesApi.serviceControllerGetBySalon(0, undefined, undefined, undefined, Number(id)),
    staleTime: 1000,
  });

  const { data: setting, isLoading: isLoadingSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(Number(id)),
    staleTime: 1000,
  });

  const { data: dataSalon, isLoading: isLoadingSalon } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(id),
    enabled: !!id,
  });

  const createCustomerCheckinBulkMutation = useMutation(
    (checkIn: CheckInBulkDTO) => customerApi.customerControllerCheckinBulk(checkIn),
    {
      onSuccess: ({ data }) => {
        setStep((prev) => prev + 1);
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
      onSettled: () => {
        setBookings([]);
      },
    }
  );
  const onFinish = () => {
    const appointments: CheckInAppointment[] = bookings.map((booking) => {
      const services = booking.isAppointment ? booking.appointmentServices : [booking.service];

      const estimate = services?.reduce((total, service) => total + Number(service?.time || 0), 0) || 15;
      const totalMoney = services?.reduce((total, service) => total + Number(service?.price || 0), 0) || 0;

      const appointmentDeposits = [];
      appointmentDeposits.push({
        action: 'Card',
        money: booking.service?.price
          ? (Number(booking.service.price) * Number(dataSalon?.data?.deposit ?? 0)) / 100
          : Number(dataSalon?.data?.depositNotService),
      });
      return {
        id: booking.isAppointment ? booking.id : undefined,
        technicianId: booking.technician?.id,
        useAnyTechnician: booking.isAnyTechnician,
        serviceIds: services?.map((service) => Number(service?.id))?.filter((service) => service) || [],
        estimate,
        totalMoney,
        checkInTime: booking?.availableTime
          ? toDayjsByTimeZoneServer(booking?.availableTime, 'HH:mm MM/DD/YYYY').toISOString()
          : dayjs().toISOString(),
        status: true,
        onlineAppoinment: true,
        note: dataCustomer?.customerNote,
        appointmentDeposits: appointmentDeposits,
      };
    });
    const customer = {
      customerName: dataCustomer?.customerName ?? '',
      customerPhone: dataCustomer?.customerPhone.replace(/[-\s]/g, '') ?? '',
      customerEmail: dataCustomer?.customerEmail ?? '',
      birthDay: formatDateTimeUTC(dataCustomer?.birthDay),
      receivePromotionViaEmail: dataCustomer.acceptAutomatedTextMessage,
      receiveMessagesByPhone: dataCustomer.acceptAutomatedTextMessage,
      notes: dataCustomer?.customerNote ?? '',
    };

    createCustomerCheckinBulkMutation.mutate({
      appointments: !!appointments?.length
        ? appointments
        : [
            {
              checkInTime: dayjs
                .tz(dayjs().format(DATE_FORMAT_FULL_DATE), DATE_FORMAT_FULL_DATE, timeZoneSalon)
                .toISOString(),
              estimate: 15,
              serviceIds: [],
              technicianId: undefined,
              totalMoney: 0,
              useAnyTechnician: true,
              status: true,
            } as CheckInAppointment,
          ],
      customer: customer,
    });
  };
  const renderStep = useCallback(() => {
    switch (step) {
      case 0:
        return (
          <PickAServiceAndTechnician
            services={dataServices?.data?.content || []}
            technicians={dataTechnicians?.data || []}
            bookings={bookings}
            setBookings={setBookings}
            setStep={setStep}
            dataSalon={dataSalon?.data}
          />
        );
      case 1:
        return (
          <YourInformation
            setStep={setStep}
            dataCustomer={dataCustomer}
            setDataCustomer={setDataCustomer}
            dataSalon={dataSalon?.data}
          />
        );
      case 2:
        return (
          <Deposit
            checkShowDeposit={!!setting?.data.settingBusiness?.showDepositProcess}
            setStep={setStep}
            bookings={bookings}
            dataCustomer={dataCustomer}
            onFinish={onFinish}
            dataSalon={dataSalon?.data}
          />
        );
      default:
        return <></>;
    }
  }, [step, bookings, dataServices, dataServices, dataSalon, setting]);

  if (step <= 2)
    return (
      <Spin
        className="salon__online-appointment-new__ant-spinning"
        spinning={isLoadingSalon || isLoadingService || isLoadingSetting || isLoadingTechnicians}
      >
        <section className="salon__online-appointment-new" ref={refPage}>
          <div className="salon__online-appointment-new-container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="salon__online-appointment-new-text d-flex gap-30 align-items-center">
                <span className="font-weight-900 font-size-45">{dataSalon?.data?.name ?? ''}</span>
                <div>
                  <div className="d-flex  align-items-center">
                    <SvgAddressIcon />
                    <span className="m-l-10 font-weight-500 font-size-16">{dataSalon?.data?.address ?? ''}</span>
                  </div>
                  <div className="d-flex  align-items-center">
                    <SvgPhoneNumberIcon />
                    <span className="m-l-10 font-weight-500 font-size-16">
                      {setting?.data?.settingBusiness?.phoneNumber ?? ''}
                    </span>
                  </div>
                </div>
              </div>

              <div className="salon__online-appointment-new-step salon__online-appointment-new__step">
                <Steps
                  current={step}
                  labelPlacement="vertical"
                  responsive={false}
                  items={ListStep.map((item, index: number) => ({
                    title: (
                      <span className="text-transform-first-letter-capitalize">
                        {step !== 2
                          ? intl.formatMessage({ id: `onlineAppointmentNew.step.${item}` })
                          : intl.formatMessage({
                              id: `onlineAppointmentNew.step.${
                                !!setting?.data.settingBusiness?.showDepositProcess
                                  ? StepTitle.depositAndSubmit
                                  : StepTitle.submit
                              }`,
                            })}
                      </span>
                    ),
                    icon: <div className="salon__online-appointment-new__step-item">{index + 1}</div>,
                  }))}
                />
              </div>
            </div>
          </div>

          <div className="salon__online-appointment-new-main">{renderStep()}</div>
        </section>
      </Spin>
    );
  return <CheckInConfirm />;
};
export default OnlineAppointmentNew;
