import { DatePicker, DatePickerProps, Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import { toDayjsTimeZone } from '../../../utils';
import { SvgDatePickerIcon } from '../../@svg/SvgDatePickerIcon';
import './formDatePicker.scss';

type Props = {
  name: string;
  label?: ReactNode | string;
  formItemProps?: FormItemProps;
  datePickerProps?: DatePickerProps;
  datePickerIcon?: ReactNode;
  isPickWeek?: boolean;
  isRemoveTimeZone?: boolean;
};

export const FormDatePicker: React.FC<Props> = ({
  datePickerProps,
  formItemProps,
  datePickerIcon,
  name,
  label,
  isPickWeek,
  isRemoveTimeZone,
}) => {
  const activeTodayByTimezone = () => {
    const datePickerELement = document.getElementsByClassName(`date-picker-custom-today-${name}`)?.[0];

    if (datePickerELement && !isRemoveTimeZone) {
      const antPickerCell = datePickerELement.getElementsByClassName('ant-picker-cell');
      if (!!antPickerCell?.length) {
        const today = toDayjsTimeZone().format('YYYY-MM-DD');

        for (let index = 0; index < antPickerCell.length; index++) {
          const element = antPickerCell[index];
          const title = element.getAttribute('title')?.toString();

          if (title && title === today && !element.classList.contains('ant-picker-cell-today')) {
            element.classList.add('ant-picker-cell-today');
          }

          if (title && title !== today && element.classList.contains('ant-picker-cell-today')) {
            element.classList.remove('ant-picker-cell-today');
          }
        }
      }
    }
  };
  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      className={`salon__form-date-picker w-100 ${formItemProps?.className ?? ''}`}
    >
      <DatePicker
        {...datePickerProps}
        onOpenChange={() => {
          setTimeout(() => {
            activeTodayByTimezone();
          }, 10);
        }}
        onPanelChange={() => {
          setTimeout(() => {
            activeTodayByTimezone();
          }, 10);
        }}
        popupClassName={`salon__form-date-picker-container date-picker-custom-today-${name} ${
          isPickWeek ? 'salon__form-date-picker-container-pick-week' : ''
        }`}
        suffixIcon={datePickerIcon ?? <SvgDatePickerIcon />}
      />
    </Form.Item>
  );
};
