import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { axiosInstance } from '../apis';
import { Administrator, Customer, Employee, LoginResponseDto, Salon } from '../apis/client-axios';
import { setStorageByName } from '../utils';

export interface AuthState {
  loading: boolean;
  error: string;
  userType?: 'administrator' | 'customer' | 'employees';
  authUser?: Administrator | Customer | Employee;
  salonActive?: Salon;
  moduleTenant: string[];
  salonPermission: string[];
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: (): AuthState => {
    let initState: AuthState = {
      loading: false,
      error: '',
      moduleTenant: [],
      salonPermission: [],
    };

    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      initState.authUser = JSON.parse(authUser);
    }

    return initState;
  },
  reducers: {
    login: (state, action: PayloadAction<LoginResponseDto>) => {
      axiosInstance.defaults.headers.Authorization = action.payload.token ? `Bearer ${action.payload.token}` : '';
      if ((action.payload?.user as any)?.salons?.[0]?.timeZone) {
        setStorageByName('timeZone', (action.payload?.user as any).salons?.[0]?.timeZone ?? 'America/New_York');
      }
    },
    updateMe: (state, action: PayloadAction<Customer | Administrator | Employee>) => {
      state.authUser = action.payload;
    },
    updateSalon: (state, action: PayloadAction<Salon>) => {
      state.salonActive = action.payload;
      state.moduleTenant = action.payload.tenant?.modules?.modules ?? [];
    },
    updatePermission: (state, action: PayloadAction<string[]>) => {
      state.salonPermission = action.payload;
    },
    logout: (state) => {
      window.location.href = '/signin';
      state.authUser = undefined;
      localStorage.clear();
      sessionStorage.clear();
    },
  },
});

export const { logout, login, updateMe, updateSalon, updatePermission } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
