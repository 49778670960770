import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  RefundTransactionDTOTypePaymentEnum,
  Ticket,
  TicketTransactionDTOTypePaymentEnum,
} from '../../../../../../apis/client-axios';
import ColWrap from '../../../../../../components/ColWrap';
import RowWrap from '../../../../../../components/RowWrap';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CustomButton } from '../../../../../../components/buttons/CustomButton';
import Refund from './Refund';

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  ticketId: number;
  totalPayment: number;
  onCancelModal: () => void;
  ticket: Ticket;
}

const RefundMethod: FC<IProps> = (props) => {
  const { open, setOpen, ticketId, totalPayment, onCancelModal, ticket } = props;

  const intl = useIntl();

  const [isOpenRefundForm, setIsOpenRefundForm] = useState<boolean>(false);
  const [refundType, setRefundType] = useState<RefundTransactionDTOTypePaymentEnum>(
    RefundTransactionDTOTypePaymentEnum.Cash
  );

  const onClickOK = (value: RefundTransactionDTOTypePaymentEnum) => {
    if (value) {
      setIsOpenRefundForm(true);
      setRefundType(value);
    }
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        modalProps={{
          title: (
            <div className="salon__checkout-merchandise-modal-heading">
              {intl.formatMessage({ id: 'checkout.transactions.refundMethod' })}
            </div>
          ),
          className: 'salon__checkout__pay-n-complete-modal',
          width: 700,
          footer: null,
        }}
      >
        <RowWrap
          isAutoFillRow={true}
          isGutter={true}
          gutter={[12, 20]}
          isWrap={true}
          styleFill="between"
          className="m-b-20"
        >
          <ColWrap colProps={{ span: 24 }}>
            <CustomButton
              type="primary"
              content={intl.formatMessage({ id: 'checkout.transactions.refundMethod.back' })}
              buttonProps={{
                className: 'font-size-20 font-weight-600 height-108 w-full',
                onClick: () => onClickOK(RefundTransactionDTOTypePaymentEnum.OriginalCard),
                disabled: !ticket.ticketTransaction.some(
                  (ticketTransaction) =>
                    ticketTransaction.typePayment === TicketTransactionDTOTypePaymentEnum.ChargeCard
                ),
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <CustomButton
              type="default"
              content={intl.formatMessage({ id: 'checkout.payment.check' })}
              buttonProps={{
                className: 'font-size-20 font-weight-600 height-62 w-full salon__checkout__primary-outlined-btn',
                onClick: () => onClickOK(RefundTransactionDTOTypePaymentEnum.Check),
              }}
            />
          </ColWrap>
          <ColWrap colProps={{ span: 12 }}>
            <CustomButton
              type="primary"
              content={intl.formatMessage({ id: 'checkout.payment.cash' })}
              buttonProps={{
                className: 'font-size-20 font-weight-600 height-62 w-full color-FFFFFF',
                onClick: () => onClickOK(RefundTransactionDTOTypePaymentEnum.Cash),
              }}
            />
          </ColWrap>
        </RowWrap>
      </StyledModal>
      {isOpenRefundForm && (
        <Refund
          ticketId={ticketId}
          totalPayment={totalPayment}
          refundType={refundType}
          open={isOpenRefundForm}
          setOpen={setIsOpenRefundForm}
          setOpenRefundMethod={setOpen}
          onCancelModal={onCancelModal}
        />
      )}
    </>
  );
};

export default RefundMethod;
