import { Col, Row } from 'antd';
import { CSSProperties, FC } from 'react';
import { SvgAppointmentStarIcon } from '../../../../../components/@svg/SvgAppointmentStarIcon';
import { SampleCallerID } from '../../models';

interface VirtualCallerIDProps {
  caller: SampleCallerID;
}

const VirtualCallerID: FC<VirtualCallerIDProps> = ({ caller }) => {
  const virtualStyled: CSSProperties = {
    position: 'absolute',
    zIndex: 10,
    opacity: 0.7,
  };

  return (
    <div id={`caller_${caller.id}`} className="salon__appointment-caller-virtual">
      <Col className="salon__appointment-caller-item" span={24}>
        <Row justify="space-between" align="middle">
          <Col className="font-size-16 font-weight-700 salon__appointment-caller-name">{caller.name}</Col>
          {caller.star && (
            <Col className="salon__appointment-caller-star">
              {caller.star} <SvgAppointmentStarIcon />
            </Col>
          )}
        </Row>
        <Row justify="space-between" align="middle">
          <Col className="font-size-16 font-weight-500 salon__appointment-caller-number" span={24}>
            {caller.phone}
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default VirtualCallerID;
