import React from 'react';

export const SvgBinIcon: React.FC<{ onClick?: (e: React.MouseEvent<HTMLElement>) => void }> = ({ onClick }) => (
  <span onClick={onClick} className="cursor-pointer">
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91699 1.33333H11.0003C11.4587 1.33333 11.8337 1.70833 11.8337 2.16667C11.8337 2.625 11.4587 3 11.0003 3H1.00033C0.541992 3 0.166992 2.625 0.166992 2.16667C0.166992 1.70833 0.541992 1.33333 1.00033 1.33333H3.08366L3.67533 0.741667C3.82533 0.591667 4.04199 0.5 4.25866 0.5H7.74199C7.95866 0.5 8.17533 0.591667 8.32533 0.741667L8.91699 1.33333ZM2.66699 15.5C1.75033 15.5 1.00033 14.75 1.00033 13.8333V5.5C1.00033 4.58333 1.75033 3.83333 2.66699 3.83333H9.33366C10.2503 3.83333 11.0003 4.58333 11.0003 5.5V13.8333C11.0003 14.75 10.2503 15.5 9.33366 15.5H2.66699Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
