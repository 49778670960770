import React from 'react';

export const SvgAppointmentArrowForwardIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.99767 21.0149C7.48767 21.5049 8.27767 21.5049 8.76767 21.0149L17.0777 12.7049C17.4677 12.3149 17.4677 11.6849 17.0777 11.2949L8.76767 2.98493C8.27767 2.49493 7.48767 2.49493 6.99767 2.98493C6.50767 3.47493 6.50767 4.26493 6.99767 4.75493L14.2377 12.0049L6.98767 19.2549C6.50767 19.7349 6.50767 20.5349 6.99767 21.0149Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  </span>
);
