import { FormInstance } from 'antd/lib';
import KeyboardWrapper from '../KeyboardWrapper';
import './styles.scss';
export interface Props {
  form: FormInstance;
  inputName: string;
  keyboardRef: any;
}

function KeyboardRequirePassword(props: Props) {
  const { form, inputName, keyboardRef } = props;
  const customLayoutKeyboard = {
    default: ['1 2 3', '4 5 6', '7 8 9', '00 0 {backspace}'],
  };
  return (
    <div className="salon__numeric-keyboard-require">
      <KeyboardWrapper
        onNext={() => {}}
        onFinish={() => {
          form.submit();
        }}
        form={form}
        maxLength={255}
        inputName={inputName}
        keyboardRef={keyboardRef}
        keyboardProps={{
          layout: customLayoutKeyboard,
        }}
      />
    </div>
  );
}

export default KeyboardRequirePassword;
