/* tslint:disable */
/* eslint-disable */
/**
 * 1STMERCHANT
 * Api for development - v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddDeviceTokenDto
 */
export interface AddDeviceTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AddDeviceTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AdjustDto
 */
export interface AdjustDto {
    /**
     * 
     * @type {number}
     * @memberof AdjustDto
     */
    'adjustWage': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustDto
     */
    'adjustTip': number;
    /**
     * 
     * @type {string}
     * @memberof AdjustDto
     */
    'adjustNotes'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdjustDto
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof AdjustDto
     */
    'adjustDate': string;
}
/**
 * 
 * @export
 * @interface Administrator
 */
export interface Administrator {
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'emailAddress': string;
    /**
     * 
     * @type {User}
     * @memberof Administrator
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'announcementFor': AnnouncementAnnouncementForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'isSms': boolean;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'smsContent': string;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'isEmail': boolean;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'emailContent': object;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'customerVisit'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'moreFilter': boolean;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'accountCreated'?: object;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'ticketAmount'?: object;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'moneySpent'?: object;
    /**
     * 
     * @type {Array<number>}
     * @memberof Announcement
     */
    'technicianIds': Array<number>;
    /**
     * 
     * @type {object}
     * @memberof Announcement
     */
    'timeSend': object;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'expireDate': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'saveDate': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'stopDate': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'announceDate': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'option': AnnouncementOptionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'sent': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Announcement
     */
    'customerIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'sentSuccess': number;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof Announcement
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'deletedAt': string;
}

export const AnnouncementAnnouncementForEnum = {
    BirthdayPromotion: 'Birthday Promotion',
    CheckinPromotion: 'Checkin Promotion',
    LoyaltyPromotion: 'Loyalty Promotion',
    OccationPromotion: 'Occation Promotion',
    ReferralPromotion: 'Referral Promotion',
    WeeklyPromotion: 'Weekly Promotion'
} as const;

export type AnnouncementAnnouncementForEnum = typeof AnnouncementAnnouncementForEnum[keyof typeof AnnouncementAnnouncementForEnum];
export const AnnouncementOptionEnum = {
    SendNow: 'sendNow',
    SendLater: 'sendLater',
    SendUntilEnd: 'sendUntilEnd'
} as const;

export type AnnouncementOptionEnum = typeof AnnouncementOptionEnum[keyof typeof AnnouncementOptionEnum];

/**
 * 
 * @export
 * @interface AnouncementFilter
 */
export interface AnouncementFilter {
    /**
     * 
     * @type {object}
     * @memberof AnouncementFilter
     */
    'customerVisit'?: object;
    /**
     * 
     * @type {object}
     * @memberof AnouncementFilter
     */
    'accountCreated'?: object;
    /**
     * 
     * @type {object}
     * @memberof AnouncementFilter
     */
    'ticketAmount'?: object;
    /**
     * 
     * @type {object}
     * @memberof AnouncementFilter
     */
    'moneySpent'?: object;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnouncementFilter
     */
    'technicianIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'timeStart': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'checkinTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'completedTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'canceledTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'confirmedTime': string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'estimate': number;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'useAnyTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'useMessageReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'status': AppointmentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'totalMoney': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'color': string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'orderNumberCheckinToday': number;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    'isOnlineAppointment': boolean;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'salonId': number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'checkinReward'?: number;
    /**
     * 
     * @type {Employee}
     * @memberof Appointment
     */
    'technician'?: Employee;
    /**
     * 
     * @type {Customer}
     * @memberof Appointment
     */
    'customer': Customer;
    /**
     * 
     * @type {Salon}
     * @memberof Appointment
     */
    'salon': Salon;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof Appointment
     */
    'services': Array<ServiceItems>;
    /**
     * 
     * @type {Array<AppointmentDeposit>}
     * @memberof Appointment
     */
    'appointmentDeposits': Array<AppointmentDeposit>;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof Appointment
     */
    'ticket'?: Array<Ticket>;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'deletedAt': string;
}

export const AppointmentStatusEnum = {
    WaitingConfirm: 'Waiting_Confirm',
    Confirmed: 'Confirmed',
    CheckedIn: 'Checked_in',
    BeingServed: 'Being_served',
    Completed: 'Completed',
    Canceled: 'Canceled'
} as const;

export type AppointmentStatusEnum = typeof AppointmentStatusEnum[keyof typeof AppointmentStatusEnum];

/**
 * 
 * @export
 * @interface AppointmentDeposit
 */
export interface AppointmentDeposit {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeposit
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeposit
     */
    'action': AppointmentDepositActionEnum;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDeposit
     */
    'money': number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDeposit
     */
    'appointmentId': number;
    /**
     * 
     * @type {Appointment}
     * @memberof AppointmentDeposit
     */
    'appointment': Appointment;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDeposit
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeposit
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDeposit
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeposit
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDeposit
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDeposit
     */
    'deletedAt': string;
}

export const AppointmentDepositActionEnum = {
    Cash: 'Cash',
    Check: 'Check',
    Card: 'Card',
    Others: 'Others'
} as const;

export type AppointmentDepositActionEnum = typeof AppointmentDepositActionEnum[keyof typeof AppointmentDepositActionEnum];

/**
 * 
 * @export
 * @interface AppointmentSettingDTO
 */
export interface AppointmentSettingDTO {
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'pastAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'waitingForConfirmationAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'confirmedAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'checkedInAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'beingServedAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'completedAppointment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSettingDTO
     */
    'canceledAppointment': boolean;
}
/**
 * 
 * @export
 * @interface AppointmentTodayDTO
 */
export interface AppointmentTodayDTO {
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'confirmed'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'checkedIn'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'canceled'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'waitingConfirm'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTodayDTO
     */
    'beingServed'?: number;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type': AssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'preview': string;
    /**
     * 
     * @type {AssetFocalPoint}
     * @memberof Asset
     */
    'focalPoint': AssetFocalPoint;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'deletedAt': string;
}

export const AssetTypeEnum = {
    Image: 'IMAGE',
    Video: 'VIDEO',
    Binary: 'BINARY'
} as const;

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];

/**
 * 
 * @export
 * @interface AssetFocalPoint
 */
export interface AssetFocalPoint {
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface Attributes
 */
export interface Attributes {
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'source_type': string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'is_quick_chip': string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'tip_source': string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'payment_app_version': string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'create_auth': string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    'source_ip': string;
}
/**
 * 
 * @export
 * @interface AuthenticationMethod
 */
export interface AuthenticationMethod {
    /**
     * 
     * @type {User}
     * @memberof AuthenticationMethod
     */
    'user': User;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationMethod
     */
    'loginWithPhoneNumber': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationMethod
     */
    'loginWithEmailAddress': boolean;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface AuthorizationManagementDto
 */
export interface AuthorizationManagementDto {
    /**
     * 
     * @type {number}
     * @memberof AuthorizationManagementDto
     */
    'roleId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorizationManagementDto
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface BillingInformationAddress
 */
export interface BillingInformationAddress {
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationAddress
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface BillingInformationCompany
 */
export interface BillingInformationCompany {
    /**
     * 
     * @type {BillingInformationAddress}
     * @memberof BillingInformationCompany
     */
    'address'?: BillingInformationAddress;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationCompany
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationCompany
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface BillingInformationFutureRequirements
 */
export interface BillingInformationFutureRequirements {
    /**
     * 
     * @type {Array<BillingInformationFutureRequirementsAlternative>}
     * @memberof BillingInformationFutureRequirements
     */
    'alternatives': Array<BillingInformationFutureRequirementsAlternative> | null;
    /**
     * 
     * @type {number}
     * @memberof BillingInformationFutureRequirements
     */
    'current_deadline': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirements
     */
    'currently_due': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationFutureRequirements
     */
    'disabled_reason': string | null;
    /**
     * 
     * @type {Array<BillingInformationFutureRequirementsError>}
     * @memberof BillingInformationFutureRequirements
     */
    'errors': Array<BillingInformationFutureRequirementsError> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirements
     */
    'eventually_due': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirements
     */
    'past_due': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirements
     */
    'pending_verification': Array<string> | null;
}
/**
 * 
 * @export
 * @interface BillingInformationFutureRequirementsAlternative
 */
export interface BillingInformationFutureRequirementsAlternative {
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirementsAlternative
     */
    'alternative_fields_due': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingInformationFutureRequirementsAlternative
     */
    'original_fields_due': Array<string>;
}
/**
 * 
 * @export
 * @interface BillingInformationFutureRequirementsError
 */
export interface BillingInformationFutureRequirementsError {
    /**
     * 
     * @type {object}
     * @memberof BillingInformationFutureRequirementsError
     */
    'code': object;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationFutureRequirementsError
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationFutureRequirementsError
     */
    'requirement': string;
}
/**
 * 
 * @export
 * @interface BillingInformationResponse
 */
export interface BillingInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof BillingInformationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingInformationResponse
     */
    'email'?: string;
    /**
     * 
     * @type {BillingInformationCompany}
     * @memberof BillingInformationResponse
     */
    'company'?: BillingInformationCompany;
    /**
     * 
     * @type {object}
     * @memberof BillingInformationResponse
     */
    'metadata'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof BillingInformationResponse
     */
    'details_submitted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingInformationResponse
     */
    'charges_enabled': boolean;
    /**
     * 
     * @type {BillingInformationFutureRequirements}
     * @memberof BillingInformationResponse
     */
    'future_requirements': BillingInformationFutureRequirements;
}
/**
 * 
 * @export
 * @interface BirthdayReward
 */
export interface BirthdayReward {
    /**
     * 
     * @type {boolean}
     * @memberof BirthdayReward
     */
    'birthdayReward': boolean;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'discountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'discountPrecent': number;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'eligiblePeriod': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof BirthdayReward
     */
    'discountServices': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof BirthdayReward
     */
    'allService': boolean;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof BirthdayReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BirthdayReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BirthdayReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BirthdayReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BirthdayReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface BlockTime
 */
export interface BlockTime {
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'timeEnd': string;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'to': string;
    /**
     * 
     * @type {object}
     * @memberof BlockTime
     */
    'repeat': object;
    /**
     * 
     * @type {number}
     * @memberof BlockTime
     */
    'repeatValue': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'repeatType': BlockTimeRepeatTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTime
     */
    'technicianId': number;
    /**
     * 
     * @type {Employee}
     * @memberof BlockTime
     */
    'technician': Employee;
    /**
     * 
     * @type {Array<BlockTimeDeleted>}
     * @memberof BlockTime
     */
    'blockTimeDeleted': Array<BlockTimeDeleted>;
    /**
     * 
     * @type {number}
     * @memberof BlockTime
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTime
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTime
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTime
     */
    'deletedAt': string;
}

export const BlockTimeRepeatTypeEnum = {
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Years: 'Years'
} as const;

export type BlockTimeRepeatTypeEnum = typeof BlockTimeRepeatTypeEnum[keyof typeof BlockTimeRepeatTypeEnum];

/**
 * 
 * @export
 * @interface BlockTimeDeleted
 */
export interface BlockTimeDeleted {
    /**
     * 
     * @type {string}
     * @memberof BlockTimeDeleted
     */
    'timeDelete': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTimeDeleted
     */
    'blockTimeId': number;
    /**
     * 
     * @type {BlockTime}
     * @memberof BlockTimeDeleted
     */
    'blockTime': BlockTime;
    /**
     * 
     * @type {number}
     * @memberof BlockTimeDeleted
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTimeDeleted
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTimeDeleted
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTimeDeleted
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BlockTimeDeleted
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BlockTimeDeleted
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface BusinessSetting
 */
export interface BusinessSetting {
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'businessName': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'website': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'phoneNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessSetting
     */
    'hideCustomerPhoneNumber': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessSetting
     */
    'hideCustomerIncheckinTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessSetting
     */
    'showDepositProcess': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessSetting
     */
    'hideCustomerIncheckinAndCheckOut': boolean;
    /**
     * 
     * @type {number}
     * @memberof BusinessSetting
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof BusinessSetting
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof BusinessSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BusinessSetting
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof BusinessSetting
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof BusinessSetting
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface BusyTime
 */
export interface BusyTime {
    /**
     * 
     * @type {string}
     * @memberof BusyTime
     */
    'timeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusyTime
     */
    'timeEnd'?: string;
}
/**
 * 
 * @export
 * @interface CalculateAvailableTimeDto
 */
export interface CalculateAvailableTimeDto {
    /**
     * 
     * @type {number}
     * @memberof CalculateAvailableTimeDto
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof CalculateAvailableTimeDto
     */
    'estimate': number;
    /**
     * 
     * @type {string}
     * @memberof CalculateAvailableTimeDto
     */
    'technicianTimeBusy'?: string;
}
/**
 * 
 * @export
 * @interface CalculateAvailableTimeResponse
 */
export interface CalculateAvailableTimeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CalculateAvailableTimeResponse
     */
    'isBusy': boolean;
    /**
     * 
     * @type {string}
     * @memberof CalculateAvailableTimeResponse
     */
    'availableTime': string;
    /**
     * 
     * @type {Array<BusyTime>}
     * @memberof CalculateAvailableTimeResponse
     */
    'busyTime'?: Array<BusyTime>;
}
/**
 * 
 * @export
 * @interface CardInformation
 */
export interface CardInformation {
    /**
     * 
     * @type {string}
     * @memberof CardInformation
     */
    'cardLast4'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardInformation
     */
    'cardHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardInformation
     */
    'cardBrand'?: CardInformationCardBrandEnum;
    /**
     * 
     * @type {string}
     * @memberof CardInformation
     */
    'cardExpiredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardInformation
     */
    'cardType'?: CardInformationCardTypeEnum;
}

export const CardInformationCardBrandEnum = {
    Visa: 'Visa',
    Mastercard: 'Mastercard',
    AmericanExpress: 'American Express',
    DiscoverDinersClub: 'Discover & Diners Club',
    ChinaUnionPay: 'China UnionPay',
    JapanCreditBureauJcb: 'Japan Credit Bureau (JCB)',
    CartesBancaires: 'Cartes Bancaires',
    Eftpos: 'eftpos'
} as const;

export type CardInformationCardBrandEnum = typeof CardInformationCardBrandEnum[keyof typeof CardInformationCardBrandEnum];
export const CardInformationCardTypeEnum = {
    Credit: 'CREDIT',
    Debit: 'DEBIT'
} as const;

export type CardInformationCardTypeEnum = typeof CardInformationCardTypeEnum[keyof typeof CardInformationCardTypeEnum];

/**
 * 
 * @export
 * @interface CardTransaction
 */
export interface CardTransaction {
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'cardType': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'entryType': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'first6': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'last4': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'authCode': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'referenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'transactionNo': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'state': string;
    /**
     * 
     * @type {Extra}
     * @memberof CardTransaction
     */
    'extra': Extra;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'cardholderName': string;
    /**
     * 
     * @type {string}
     * @memberof CardTransaction
     */
    'token': string;
    /**
     * 
     * @type {object}
     * @memberof CardTransaction
     */
    'vaultedCard': object;
}
/**
 * 
 * @export
 * @interface CashDiscount
 */
export interface CashDiscount {
    /**
     * 
     * @type {number}
     * @memberof CashDiscount
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {boolean}
     * @memberof CashDiscount
     */
    'autoDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof CashDiscount
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof CashDiscount
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof CashDiscount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CashDiscount
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CashDiscount
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CashDiscount
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CashDiscount
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CashDiscount
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CashDrawer
 */
export interface CashDrawer {
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    'action': CashDrawerActionEnum;
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    'cashAmount': number;
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    'salonId': number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CashDrawer
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawer
     */
    'deletedAt': string;
}

export const CashDrawerActionEnum = {
    CashIn: 'Cash_In',
    CashOut: 'Cash_Out',
    Others: 'Others'
} as const;

export type CashDrawerActionEnum = typeof CashDrawerActionEnum[keyof typeof CashDrawerActionEnum];

/**
 * 
 * @export
 * @interface CashDrawerDTO
 */
export interface CashDrawerDTO {
    /**
     * 
     * @type {string}
     * @memberof CashDrawerDTO
     */
    'action': CashDrawerDTOActionEnum;
    /**
     * 
     * @type {number}
     * @memberof CashDrawerDTO
     */
    'cashAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CashDrawerDTO
     */
    'notes'?: string;
}

export const CashDrawerDTOActionEnum = {
    CashIn: 'Cash_In',
    CashOut: 'Cash_Out',
    Others: 'Others'
} as const;

export type CashDrawerDTOActionEnum = typeof CashDrawerDTOActionEnum[keyof typeof CashDrawerDTOActionEnum];

/**
 * 
 * @export
 * @interface CashDrawerResponse
 */
export interface CashDrawerResponse {
    /**
     * 
     * @type {number}
     * @memberof CashDrawerResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CashDrawerResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CashDrawerResponse
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof CashDrawerResponse
     */
    'totalBalance': number;
    /**
     * 
     * @type {Array<CashDrawer>}
     * @memberof CashDrawerResponse
     */
    'content': Array<CashDrawer>;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ChangeStatusAppointment
 */
export interface ChangeStatusAppointment {
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusAppointment
     */
    'status': ChangeStatusAppointmentStatusEnum;
}

export const ChangeStatusAppointmentStatusEnum = {
    WaitingConfirm: 'Waiting_Confirm',
    Confirmed: 'Confirmed',
    CheckedIn: 'Checked_in',
    BeingServed: 'Being_served',
    Completed: 'Completed',
    Canceled: 'Canceled'
} as const;

export type ChangeStatusAppointmentStatusEnum = typeof ChangeStatusAppointmentStatusEnum[keyof typeof ChangeStatusAppointmentStatusEnum];

/**
 * 
 * @export
 * @interface ChangeStatusAppointments
 */
export interface ChangeStatusAppointments {
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusAppointments
     */
    'status': ChangeStatusAppointmentsStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeStatusAppointments
     */
    'appointmentIds': Array<number>;
}

export const ChangeStatusAppointmentsStatusEnum = {
    WaitingConfirm: 'Waiting_Confirm',
    Confirmed: 'Confirmed',
    CheckedIn: 'Checked_in',
    BeingServed: 'Being_served',
    Completed: 'Completed',
    Canceled: 'Canceled'
} as const;

export type ChangeStatusAppointmentsStatusEnum = typeof ChangeStatusAppointmentsStatusEnum[keyof typeof ChangeStatusAppointmentsStatusEnum];

/**
 * 
 * @export
 * @interface CheckBusyDTO
 */
export interface CheckBusyDTO {
    /**
     * 
     * @type {number}
     * @memberof CheckBusyDTO
     */
    'estimate': number;
    /**
     * 
     * @type {number}
     * @memberof CheckBusyDTO
     */
    'technicianId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckBusyDTO
     */
    'appointmentId'?: number;
}
/**
 * 
 * @export
 * @interface CheckInAppointment
 */
export interface CheckInAppointment {
    /**
     * 
     * @type {number}
     * @memberof CheckInAppointment
     */
    'estimate': number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckInAppointment
     */
    'useAnyTechnician': boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckInAppointment
     */
    'totalMoney': number;
    /**
     * 
     * @type {number}
     * @memberof CheckInAppointment
     */
    'technicianId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInAppointment
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CheckInAppointment
     */
    'checkInTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckInAppointment
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckInAppointment
     */
    'onlineAppoinment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckInAppointment
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface CheckInBulkDTO
 */
export interface CheckInBulkDTO {
    /**
     * 
     * @type {Array<CheckInAppointment>}
     * @memberof CheckInBulkDTO
     */
    'appointments': Array<CheckInAppointment>;
    /**
     * 
     * @type {CustomerCheckInDto}
     * @memberof CheckInBulkDTO
     */
    'customer': CustomerCheckInDto;
}
/**
 * 
 * @export
 * @interface CheckInCountResponse
 */
export interface CheckInCountResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckInCountResponse
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof CheckInCountResponse
     */
    'news': number;
    /**
     * 
     * @type {number}
     * @memberof CheckInCountResponse
     */
    'returns': number;
}
/**
 * 
 * @export
 * @interface CheckInDTO
 */
export interface CheckInDTO {
    /**
     * 
     * @type {number}
     * @memberof CheckInDTO
     */
    'estimate': number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckInDTO
     */
    'useAnyTechnician': boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckInDTO
     */
    'totalMoney': number;
    /**
     * 
     * @type {number}
     * @memberof CheckInDTO
     */
    'technicianId'?: number;
    /**
     * 
     * @type {CustomerCheckInDto}
     * @memberof CheckInDTO
     */
    'customer': CustomerCheckInDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CheckInDTO
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CheckInDTO
     */
    'appointmentId'?: number;
}
/**
 * 
 * @export
 * @interface CheckLoginIdExistedDTO
 */
export interface CheckLoginIdExistedDTO {
    /**
     * 
     * @type {string}
     * @memberof CheckLoginIdExistedDTO
     */
    'loginId': string;
    /**
     * 
     * @type {number}
     * @memberof CheckLoginIdExistedDTO
     */
    'employeeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckLoginIdExistedDTO
     */
    'type': CheckLoginIdExistedDTOTypeEnum;
}

export const CheckLoginIdExistedDTOTypeEnum = {
    Email: 'Email',
    PhoneNumber: 'PhoneNumber',
    LoginId: 'LoginId'
} as const;

export type CheckLoginIdExistedDTOTypeEnum = typeof CheckLoginIdExistedDTOTypeEnum[keyof typeof CheckLoginIdExistedDTOTypeEnum];

/**
 * 
 * @export
 * @interface CheckSalonDTO
 */
export interface CheckSalonDTO {
    /**
     * 
     * @type {number}
     * @memberof CheckSalonDTO
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CheckTenantLoginIdExistedDTO
 */
export interface CheckTenantLoginIdExistedDTO {
    /**
     * 
     * @type {string}
     * @memberof CheckTenantLoginIdExistedDTO
     */
    'loginId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckTenantLoginIdExistedDTO
     */
    'type': CheckTenantLoginIdExistedDTOTypeEnum;
}

export const CheckTenantLoginIdExistedDTOTypeEnum = {
    Email: 'Email',
    PhoneNumber: 'PhoneNumber',
    LoginId: 'LoginId'
} as const;

export type CheckTenantLoginIdExistedDTOTypeEnum = typeof CheckTenantLoginIdExistedDTOTypeEnum[keyof typeof CheckTenantLoginIdExistedDTOTypeEnum];

/**
 * 
 * @export
 * @interface CheckinReward
 */
export interface CheckinReward {
    /**
     * 
     * @type {boolean}
     * @memberof CheckinReward
     */
    'checkinReward': boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'reward': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CheckinReward
     */
    'redeemOptions': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'expiration': number;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'checkinPerDay': number;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'checkinPerWeek': number;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'checkinPerMonth': number;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'checkinWholeLife': number;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof CheckinReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CheckinReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CheckinReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CheckinReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CheckinReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CloseOut
 */
export interface CloseOut {
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'salonId': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'credit': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'cash': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'check': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'giftCard': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'deposit': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'membership': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'rewardRedeem': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'others': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'totalPayments': number;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CloseOut
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CloseOut
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CloseOut
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CloseOut
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CloseoutResponse
 */
export interface CloseoutResponse {
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'credit': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'cash': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'check': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'giftCard': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'deposit': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'membership': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'rewardRedeem': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'others': number;
    /**
     * 
     * @type {number}
     * @memberof CloseoutResponse
     */
    'totalPayments': number;
}
/**
 * 
 * @export
 * @interface CloverMerchantInformation
 */
export interface CloverMerchantInformation {
    /**
     * 
     * @type {string}
     * @memberof CloverMerchantInformation
     */
    'merchantID': string;
    /**
     * 
     * @type {string}
     * @memberof CloverMerchantInformation
     */
    'merchantMID': string;
    /**
     * 
     * @type {string}
     * @memberof CloverMerchantInformation
     */
    'merchantName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsAuths': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsPreAuths': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsSales': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsVaultCards': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsManualRefunds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CloverMerchantInformation
     */
    'supportsTipAdjust': boolean;
    /**
     * 
     * @type {DeviceInfo}
     * @memberof CloverMerchantInformation
     */
    'deviceInfo': DeviceInfo;
}
/**
 * 
 * @export
 * @interface Compensation
 */
export interface Compensation {
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'labor': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'supply': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'techPromo': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'wage': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'tip': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'hourlyWage': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'minCommission': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'commission': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'totalWorkingHours': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'ownerIncome': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'dailyBonus': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'income': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'dailyDeduction': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'adjustWage': number;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'adjustTip': number;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    'adjustNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    'compensationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'technicianId': number;
    /**
     * 
     * @type {Employee}
     * @memberof Compensation
     */
    'technician': Employee;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Compensation
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Compensation
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CompensationOverview
 */
export interface CompensationOverview {
    /**
     * 
     * @type {string}
     * @memberof CompensationOverview
     */
    'dateCompensation': string;
    /**
     * 
     * @type {number}
     * @memberof CompensationOverview
     */
    'totalPayment': number;
}
/**
 * 
 * @export
 * @interface CompensationPay
 */
export interface CompensationPay {
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'datePayment': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompensationPay
     */
    'datePayments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'typePayment': CompensationPayTypePaymentEnum;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'earningCheckPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'earningOrtherPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'earning': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'tip': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'tipCheckPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'tipOrtherPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'totalCheck': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'totalOrther': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'notes': string;
    /**
     * 
     * @type {Employee}
     * @memberof CompensationPay
     */
    'technician': Employee;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CompensationPay
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationPay
     */
    'deletedAt': string;
}

export const CompensationPayTypePaymentEnum = {
    Custom: 'custom',
    OneDay: 'oneDay',
    Week: 'week',
    SemiMonth: 'semiMonth',
    Month: 'month'
} as const;

export type CompensationPayTypePaymentEnum = typeof CompensationPayTypePaymentEnum[keyof typeof CompensationPayTypePaymentEnum];

/**
 * 
 * @export
 * @interface CompensationPayResponse
 */
export interface CompensationPayResponse {
    /**
     * 
     * @type {number}
     * @memberof CompensationPayResponse
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPayResponse
     */
    'earning': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationPayResponse
     */
    'tip': number;
}
/**
 * 
 * @export
 * @interface CompensationPaymentDTO
 */
export interface CompensationPaymentDTO {
    /**
     * 
     * @type {Array<TechnicianItemPayDTO>}
     * @memberof CompensationPaymentDTO
     */
    'dataPayments': Array<TechnicianItemPayDTO>;
}
/**
 * 
 * @export
 * @interface CompensationResponese
 */
export interface CompensationResponese {
    /**
     * 
     * @type {string}
     * @memberof CompensationResponese
     */
    'dateCompensation': string;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'labor': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'supply': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'techPromo': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'commission': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'tip': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'hourlyWage': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'minCommission': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'dailyBonus': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'dailyDeduction': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'adjustWage': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'income': number;
    /**
     * 
     * @type {number}
     * @memberof CompensationResponese
     */
    'adjustTip': number;
    /**
     * 
     * @type {string}
     * @memberof CompensationResponese
     */
    'adjustNotes': string | null;
}
/**
 * 
 * @export
 * @interface CompletedResponse
 */
export interface CompletedResponse {
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'closeoutId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'salonId': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedResponse
     */
    'timeCompleted': string;
    /**
     * 
     * @type {ServiceTaxDTO}
     * @memberof CompletedResponse
     */
    'serviceTax': ServiceTaxDTO;
    /**
     * 
     * @type {ServiceChargeDTO}
     * @memberof CompletedResponse
     */
    'serviceCharge': ServiceChargeDTO;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'totalDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'totalPayment': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'totalTip': number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof CompletedResponse
     */
    'discount': TicketDisCountDTO;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'deposit': number;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'balanceToPay': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CompletedResponse
     */
    'technicianResponsibility': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof CompletedResponse
     */
    'status': CompletedResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'rewarding': number;
    /**
     * 
     * @type {Customer}
     * @memberof CompletedResponse
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<TransactionRefund>}
     * @memberof CompletedResponse
     */
    'transactionRefunds'?: Array<TransactionRefund>;
    /**
     * 
     * @type {CloseOut}
     * @memberof CompletedResponse
     */
    'closeout'?: CloseOut;
    /**
     * 
     * @type {Array<TechnicianTurn>}
     * @memberof CompletedResponse
     */
    'technicianTurns': Array<TechnicianTurn>;
    /**
     * 
     * @type {Array<TicketTransaction>}
     * @memberof CompletedResponse
     */
    'ticketTransaction': Array<TicketTransaction>;
    /**
     * 
     * @type {Array<TicketMerchandise>}
     * @memberof CompletedResponse
     */
    'ticketMerchandise': Array<TicketMerchandise>;
    /**
     * 
     * @type {Array<TicketGiftCard>}
     * @memberof CompletedResponse
     */
    'ticketGiftCard': Array<TicketGiftCard>;
    /**
     * 
     * @type {Array<GiftCardHistory>}
     * @memberof CompletedResponse
     */
    'giftCardHistory': Array<GiftCardHistory>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CompletedResponse
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedResponse
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedResponse
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CompletedResponse
     */
    'deletedAt': string;
    /**
     * 
     * @type {number}
     * @memberof CompletedResponse
     */
    'transactionOrder': number;
}

export const CompletedResponseStatusEnum = {
    Completed: 'Completed',
    Hold: 'Hold'
} as const;

export type CompletedResponseStatusEnum = typeof CompletedResponseStatusEnum[keyof typeof CompletedResponseStatusEnum];

/**
 * 
 * @export
 * @interface ConnectAccountResponse
 */
export interface ConnectAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof ConnectAccountResponse
     */
    'object': string;
    /**
     * 
     * @type {number}
     * @memberof ConnectAccountResponse
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof ConnectAccountResponse
     */
    'expires_at': number;
    /**
     * 
     * @type {string}
     * @memberof ConnectAccountResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface CreateAnnouncementDto
 */
export interface CreateAnnouncementDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'announcementFor': CreateAnnouncementDtoAnnouncementForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAnnouncementDto
     */
    'isSms': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'smsContent'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAnnouncementDto
     */
    'isEmail': boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'emailContent'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'customerVisit'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAnnouncementDto
     */
    'moreFilter'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'accountCreated'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'ticketAmount'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'moneySpent'?: object;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAnnouncementDto
     */
    'technicianIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'expireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'saveDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'stopDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'announceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnnouncementDto
     */
    'option': CreateAnnouncementDtoOptionEnum;
    /**
     * 
     * @type {object}
     * @memberof CreateAnnouncementDto
     */
    'timeSend'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAnnouncementDto
     */
    'sent'?: boolean;
}

export const CreateAnnouncementDtoAnnouncementForEnum = {
    BirthdayPromotion: 'Birthday Promotion',
    CheckinPromotion: 'Checkin Promotion',
    LoyaltyPromotion: 'Loyalty Promotion',
    OccationPromotion: 'Occation Promotion',
    ReferralPromotion: 'Referral Promotion',
    WeeklyPromotion: 'Weekly Promotion'
} as const;

export type CreateAnnouncementDtoAnnouncementForEnum = typeof CreateAnnouncementDtoAnnouncementForEnum[keyof typeof CreateAnnouncementDtoAnnouncementForEnum];
export const CreateAnnouncementDtoOptionEnum = {
    SendNow: 'sendNow',
    SendLater: 'sendLater',
    SendUntilEnd: 'sendUntilEnd'
} as const;

export type CreateAnnouncementDtoOptionEnum = typeof CreateAnnouncementDtoOptionEnum[keyof typeof CreateAnnouncementDtoOptionEnum];

/**
 * 
 * @export
 * @interface CreateAppointmentDTO
 */
export interface CreateAppointmentDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDTO
     */
    'timeStart': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDTO
     */
    'estimate': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDTO
     */
    'useAnyTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDTO
     */
    'useMessageReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDTO
     */
    'status'?: CreateAppointmentDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDTO
     */
    'totalMoney': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDTO
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentDTO
     */
    'color': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentDTO
     */
    'technicianId'?: number;
    /**
     * 
     * @type {CustomerDto}
     * @memberof CreateAppointmentDTO
     */
    'customer': CustomerDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAppointmentDTO
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {Array<DepositTotalDTO>}
     * @memberof CreateAppointmentDTO
     */
    'depositTotal'?: Array<DepositTotalDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentDTO
     */
    'isOnlineAppointment'?: boolean;
}

export const CreateAppointmentDTOStatusEnum = {
    WaitingConfirm: 'Waiting_Confirm',
    Confirmed: 'Confirmed',
    CheckedIn: 'Checked_in',
    BeingServed: 'Being_served',
    Completed: 'Completed',
    Canceled: 'Canceled'
} as const;

export type CreateAppointmentDTOStatusEnum = typeof CreateAppointmentDTOStatusEnum[keyof typeof CreateAppointmentDTOStatusEnum];

/**
 * 
 * @export
 * @interface CreateAppointmentsDTO
 */
export interface CreateAppointmentsDTO {
    /**
     * 
     * @type {Array<CreateAppointmentDTO>}
     * @memberof CreateAppointmentsDTO
     */
    'appointments': Array<CreateAppointmentDTO>;
}
/**
 * 
 * @export
 * @interface CreateBusinessDailyDeductionDTO
 */
export interface CreateBusinessDailyDeductionDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'monday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'tuesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'wednesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'thursday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'friday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'saturday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessDailyDeductionDTO
     */
    'sunday'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateBusinessWorkingHours
 */
export interface CreateBusinessWorkingHours {
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessWorkingHours
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessWorkingHours
     */
    'salonId'?: number;
    /**
     * 
     * @type {CreateWorkingTimeDTO}
     * @memberof CreateBusinessWorkingHours
     */
    'workingTime'?: CreateWorkingTimeDTO;
    /**
     * 
     * @type {CreateSettingBusinessDTO}
     * @memberof CreateBusinessWorkingHours
     */
    'settingBusiness'?: CreateSettingBusinessDTO;
}
/**
 * 
 * @export
 * @interface CreateChargeDto
 */
export interface CreateChargeDto {
    /**
     * 
     * @type {number}
     * @memberof CreateChargeDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'cardToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'customerPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChargeDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateChargeForTenantDto
 */
export interface CreateChargeForTenantDto {
    /**
     * 
     * @type {number}
     * @memberof CreateChargeForTenantDto
     */
    'tenantPaymentRequestId': number;
}
/**
 * 
 * @export
 * @interface CreateDailyDeductionDTO
 */
export interface CreateDailyDeductionDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'monday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'tuesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'webnesday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'thursday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'friday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'saturday'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDailyDeductionDTO
     */
    'sunday'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDTO
 */
export interface CreateEmployeeDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'loginName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDTO
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'store'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDTO
     */
    'loginWithPhoneNumber': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDTO
     */
    'loginWithEmailAddress': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeDTO
     */
    'roles': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeDTO
     */
    'salons': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeDTO
     */
    'skills'?: Array<number>;
    /**
     * 
     * @type {CreateEmployeeProfileSetting}
     * @memberof CreateEmployeeDTO
     */
    'employeeProfileSetting'?: CreateEmployeeProfileSetting;
    /**
     * 
     * @type {CreateEmployeeWorkingHours}
     * @memberof CreateEmployeeDTO
     */
    'employeeWorkingHours'?: CreateEmployeeWorkingHours;
    /**
     * 
     * @type {Asset}
     * @memberof CreateEmployeeDTO
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'defaultAvatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'legalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDTO
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeProfileSetting
 */
export interface CreateEmployeeProfileSetting {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'commissionRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'tipRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'hourRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'dailyBonus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'minimumTurn'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'minimunCommission'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeProfileSetting
     */
    'guaranteePeriods'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'guaranteePeriodsValue'?: number | null;
    /**
     * 
     * @type {Array<CreatePeriodDate>}
     * @memberof CreateEmployeeProfileSetting
     */
    'periodDates'?: Array<CreatePeriodDate>;
    /**
     * 
     * @type {CreateDailyDeductionDTO}
     * @memberof CreateEmployeeProfileSetting
     */
    'dailyDeduction'?: CreateDailyDeductionDTO;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'timeBlockers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'onlineAppointments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'reminderMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'selfCreatingAppointments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'receivingNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeProfileSetting
     */
    'isSameSalonDailyDeduction'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'checkRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeProfileSetting
     */
    'castAndOtherRate'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateEmployeeWorkingHours
 */
export interface CreateEmployeeWorkingHours {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeWorkingHours
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeWorkingHours
     */
    'isSameSalon'?: boolean;
    /**
     * 
     * @type {CreateWorkingHoursDTO}
     * @memberof CreateEmployeeWorkingHours
     */
    'workingTime'?: CreateWorkingHoursDTO;
}
/**
 * 
 * @export
 * @interface CreatePaymentMethodDto
 */
export interface CreatePaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CreatePeriodDate
 */
export interface CreatePeriodDate {
    /**
     * 
     * @type {number}
     * @memberof CreatePeriodDate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePeriodDate
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSalonDTO
 */
export interface CreateSalonDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateSalonDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalonDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalonDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateServiceDTO
 */
export interface CreateServiceDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateServiceItemDTO
 */
export interface CreateServiceItemDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'serviceId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceItemDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceItemDTO
     */
    'subName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceItemDTO
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceItemDTO
     */
    'isMerchandise': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceItemDTO
     */
    'isTaxable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceItemDTO
     */
    'isCheckinStands': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceItemDTO
     */
    'isCheckinOnline': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'supply'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'time'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'turns'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'uncountedTurns'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'technicianCut'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceItemDTO
     */
    'technicianRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceItemDTO
     */
    'textColor': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceItemDTO
     */
    'backgroundColor': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateServiceItemDTO
     */
    'salonIds': Array<number>;
}
/**
 * 
 * @export
 * @interface CreateSettingBusinessDTO
 */
export interface CreateSettingBusinessDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSettingBusinessDTO
     */
    'businessName'?: string;
}
/**
 * 
 * @export
 * @interface CreateTenantDto
 */
export interface CreateTenantDto {
    /**
     * 
     * @type {number}
     * @memberof CreateTenantDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'loginName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDto
     */
    'status': CreateTenantDtoStatusEnum;
    /**
     * 
     * @type {CreateTenantDtoPackage}
     * @memberof CreateTenantDto
     */
    'package': CreateTenantDtoPackage;
    /**
     * 
     * @type {CreateTenantDtoModule}
     * @memberof CreateTenantDto
     */
    'module': CreateTenantDtoModule;
    /**
     * 
     * @type {CreateTenantDtoPayment}
     * @memberof CreateTenantDto
     */
    'payment': CreateTenantDtoPayment;
    /**
     * 
     * @type {Array<CreateSalonDTO>}
     * @memberof CreateTenantDto
     */
    'salons': Array<CreateSalonDTO>;
}

export const CreateTenantDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive'
} as const;

export type CreateTenantDtoStatusEnum = typeof CreateTenantDtoStatusEnum[keyof typeof CreateTenantDtoStatusEnum];

/**
 * 
 * @export
 * @interface CreateTenantDtoModule
 */
export interface CreateTenantDtoModule {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDtoModule
     */
    'name'?: CreateTenantDtoModuleNameEnum;
}

export const CreateTenantDtoModuleNameEnum = {
    Silver: 'Silver',
    Gold: 'Gold',
    Diamond: 'Diamond'
} as const;

export type CreateTenantDtoModuleNameEnum = typeof CreateTenantDtoModuleNameEnum[keyof typeof CreateTenantDtoModuleNameEnum];

/**
 * 
 * @export
 * @interface CreateTenantDtoPackage
 */
export interface CreateTenantDtoPackage {
    /**
     * 
     * @type {number}
     * @memberof CreateTenantDtoPackage
     */
    'customerLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTenantDtoPackage
     */
    'transactionLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTenantDtoPackage
     */
    'SMSAndEmailLimit'?: number;
}
/**
 * 
 * @export
 * @interface CreateTenantDtoPayment
 */
export interface CreateTenantDtoPayment {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDtoPayment
     */
    'paymentType'?: CreateTenantDtoPaymentPaymentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateTenantDtoPayment
     */
    'totalPayment'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantDtoPayment
     */
    'paymentDate'?: string;
}

export const CreateTenantDtoPaymentPaymentTypeEnum = {
    Monthly: 'Monthly',
    Annually: 'Annually'
} as const;

export type CreateTenantDtoPaymentPaymentTypeEnum = typeof CreateTenantDtoPaymentPaymentTypeEnum[keyof typeof CreateTenantDtoPaymentPaymentTypeEnum];

/**
 * 
 * @export
 * @interface CreateUpdateBlockTimeDTO
 */
export interface CreateUpdateBlockTimeDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'technicianId': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'blockTimeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'timeEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'to': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'repeat': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'repeatValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'repeatType'?: CreateUpdateBlockTimeDTORepeatTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateBlockTimeDTO
     */
    'notes'?: string;
}

export const CreateUpdateBlockTimeDTORepeatTypeEnum = {
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    Years: 'Years'
} as const;

export type CreateUpdateBlockTimeDTORepeatTypeEnum = typeof CreateUpdateBlockTimeDTORepeatTypeEnum[keyof typeof CreateUpdateBlockTimeDTORepeatTypeEnum];

/**
 * 
 * @export
 * @interface CreateWorkingHoursDTO
 */
export interface CreateWorkingHoursDTO {
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'monday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'tuesday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'webnesday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'thursday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'friday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'saturday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingHoursDTO
     */
    'sunday'?: FromToDTO;
}
/**
 * 
 * @export
 * @interface CreateWorkingTimeDTO
 */
export interface CreateWorkingTimeDTO {
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'monday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'tuesday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'wednesday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'thursday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'friday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'saturday'?: FromToDTO;
    /**
     * 
     * @type {FromToDTO}
     * @memberof CreateWorkingTimeDTO
     */
    'sunday'?: FromToDTO;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'birthDay': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastCheckin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'referralCodeUse': string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'receivePromotionViaEmail': boolean;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    'user'?: User;
    /**
     * 
     * @type {Asset}
     * @memberof Customer
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Customer
     */
    'appointments': Array<Appointment>;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof Customer
     */
    'tickets': Array<Ticket>;
    /**
     * 
     * @type {Array<CustomerReward>}
     * @memberof Customer
     */
    'customerReward': Array<CustomerReward>;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof Customer
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof Customer
     */
    'sentMessages'?: Array<SentMessage>;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerCheckInDto
 */
export interface CustomerCheckInDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckInDto
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckInDto
     */
    'customerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckInDto
     */
    'customerPhone': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckInDto
     */
    'birthDay': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCheckInDto
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCheckInDto
     */
    'receivePromotionViaEmail': boolean;
}
/**
 * 
 * @export
 * @interface CustomerCheckout
 */
export interface CustomerCheckout {
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'birthDay': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'lastCheckin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'referralCodeUse': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCheckout
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCheckout
     */
    'receivePromotionViaEmail': boolean;
    /**
     * 
     * @type {User}
     * @memberof CustomerCheckout
     */
    'user'?: User;
    /**
     * 
     * @type {Asset}
     * @memberof CustomerCheckout
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CustomerCheckout
     */
    'appointments': Array<Appointment>;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof CustomerCheckout
     */
    'tickets': Array<Ticket>;
    /**
     * 
     * @type {Array<CustomerReward>}
     * @memberof CustomerCheckout
     */
    'customerReward': Array<CustomerReward>;
    /**
     * 
     * @type {number}
     * @memberof CustomerCheckout
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof CustomerCheckout
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof CustomerCheckout
     */
    'sentMessages'?: Array<SentMessage>;
    /**
     * 
     * @type {number}
     * @memberof CustomerCheckout
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCheckout
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCheckout
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCheckout
     */
    'deletedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCheckout
     */
    'isReferralCodeUse': boolean;
}
/**
 * 
 * @export
 * @interface CustomerControllerGet200Response
 */
export interface CustomerControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200Response
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGet200ResponseAllOf
 */
export interface CustomerControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200ResponseAllOf
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetCheckout200Response
 */
export interface CustomerControllerGetCheckout200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetCheckout200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetCheckout200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetCheckout200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<CustomerCheckout>}
     * @memberof CustomerControllerGetCheckout200Response
     */
    'content'?: Array<CustomerCheckout>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetCheckout200ResponseAllOf
 */
export interface CustomerControllerGetCheckout200ResponseAllOf {
    /**
     * 
     * @type {Array<CustomerCheckout>}
     * @memberof CustomerControllerGetCheckout200ResponseAllOf
     */
    'content'?: Array<CustomerCheckout>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetRecentCheckins200Response
 */
export interface CustomerControllerGetRecentCheckins200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRecentCheckins200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRecentCheckins200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRecentCheckins200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<CustomerRecentCheckin>}
     * @memberof CustomerControllerGetRecentCheckins200Response
     */
    'content'?: Array<CustomerRecentCheckin>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetRecentCheckins200ResponseAllOf
 */
export interface CustomerControllerGetRecentCheckins200ResponseAllOf {
    /**
     * 
     * @type {Array<CustomerRecentCheckin>}
     * @memberof CustomerControllerGetRecentCheckins200ResponseAllOf
     */
    'content'?: Array<CustomerRecentCheckin>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetRewardInformation200Response
 */
export interface CustomerControllerGetRewardInformation200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRewardInformation200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRewardInformation200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGetRewardInformation200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<RewardInformation>}
     * @memberof CustomerControllerGetRewardInformation200Response
     */
    'content'?: Array<RewardInformation>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGetRewardInformation200ResponseAllOf
 */
export interface CustomerControllerGetRewardInformation200ResponseAllOf {
    /**
     * 
     * @type {Array<RewardInformation>}
     * @memberof CustomerControllerGetRewardInformation200ResponseAllOf
     */
    'content'?: Array<RewardInformation>;
}
/**
 * 
 * @export
 * @interface CustomerControllerOldCustomerCheckin200Response
 */
export interface CustomerControllerOldCustomerCheckin200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerOldCustomerCheckin200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerOldCustomerCheckin200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerOldCustomerCheckin200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CustomerControllerOldCustomerCheckin200Response
     */
    'content'?: Array<Appointment>;
}
/**
 * 
 * @export
 * @interface CustomerControllerOldCustomerCheckin200ResponseAllOf
 */
export interface CustomerControllerOldCustomerCheckin200ResponseAllOf {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CustomerControllerOldCustomerCheckin200ResponseAllOf
     */
    'content'?: Array<Appointment>;
}
/**
 * 
 * @export
 * @interface CustomerCountResponse
 */
export interface CustomerCountResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerCountResponse
     */
    'title': CustomerCountResponseTitleEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerCountResponse
     */
    'value': number;
}

export const CustomerCountResponseTitleEnum = {
    ActiveCustomers: 'activeCustomers',
    AllCustomers: 'allCustomers',
    CurrentCustomers: 'currentCustomers',
    OldCustomers: 'oldCustomers',
    OptedInCustomers: 'optedInCustomers'
} as const;

export type CustomerCountResponseTitleEnum = typeof CustomerCountResponseTitleEnum[keyof typeof CustomerCountResponseTitleEnum];

/**
 * 
 * @export
 * @interface CustomerCountToday
 */
export interface CustomerCountToday {
    /**
     * 
     * @type {number}
     * @memberof CustomerCountToday
     */
    'news'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCountToday
     */
    'returns'?: number;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'customerPhone': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'customerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'customerBirthday'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    'receiveMessagesByPhone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    'receivePromotionViaEmail'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerGiftCard
 */
export interface CustomerGiftCard {
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'giftCardNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'giftCardValue': number;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerGiftCard
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGiftCard
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGiftCard
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerGiftCard
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGiftCard
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerRecentCheckin
 */
export interface CustomerRecentCheckin {
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'birthDay': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'lastCheckin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'referralCodeUse': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRecentCheckin
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRecentCheckin
     */
    'receivePromotionViaEmail': boolean;
    /**
     * 
     * @type {User}
     * @memberof CustomerRecentCheckin
     */
    'user'?: User;
    /**
     * 
     * @type {Asset}
     * @memberof CustomerRecentCheckin
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CustomerRecentCheckin
     */
    'appointments': Array<Appointment>;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof CustomerRecentCheckin
     */
    'tickets': Array<Ticket>;
    /**
     * 
     * @type {Array<CustomerReward>}
     * @memberof CustomerRecentCheckin
     */
    'customerReward': Array<CustomerReward>;
    /**
     * 
     * @type {number}
     * @memberof CustomerRecentCheckin
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof CustomerRecentCheckin
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof CustomerRecentCheckin
     */
    'sentMessages'?: Array<SentMessage>;
    /**
     * 
     * @type {number}
     * @memberof CustomerRecentCheckin
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRecentCheckin
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRecentCheckin
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerRecentCheckin
     */
    'deletedAt': string;
    /**
     * 
     * @type {CardInformation}
     * @memberof CustomerRecentCheckin
     */
    'cardInformation'?: CardInformation;
}
/**
 * 
 * @export
 * @interface CustomerReward
 */
export interface CustomerReward {
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'salonId': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'reward': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReward
     */
    'lastReward'?: string;
    /**
     * 
     * @type {Salon}
     * @memberof CustomerReward
     */
    'salon': Salon;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerReward
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerRewardBalance
 */
export interface CustomerRewardBalance {
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardBalance
     */
    'rewardBalance': number;
}
/**
 * 
 * @export
 * @interface CustomerRewardInformation
 */
export interface CustomerRewardInformation {
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'paymentCard'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'referralCode': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'numberOfFriendsReferred': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'customerRating': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'accountStartDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'totalTicket': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'firstTicket'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'lastTicket'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'totalSpent': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'totalCheckIns': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRewardInformation
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerRewardInformation
     */
    'receivePromotionViaEmail': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerRewardInformation
     */
    'notes'?: string;
    /**
     * 
     * @type {CardInformation}
     * @memberof CustomerRewardInformation
     */
    'cardInformation'?: CardInformation;
    /**
     * 
     * @type {number}
     * @memberof CustomerRewardInformation
     */
    'tenantId': number;
}
/**
 * 
 * @export
 * @interface CustomerStripeAccount
 */
export interface CustomerStripeAccount {
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'stripeAccountCustomerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'customerPhoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerStripeAccount
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof CustomerStripeAccount
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof CustomerStripeAccount
     */
    'tenantId': number;
    /**
     * 
     * @type {Tenant}
     * @memberof CustomerStripeAccount
     */
    'tenant': Tenant;
    /**
     * 
     * @type {number}
     * @memberof CustomerStripeAccount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerStripeAccount
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerStripeAccount
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerStripeAccount
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerTransaction
 */
export interface CustomerTransaction {
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'customerPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'paymentMethod': CustomerTransactionPaymentMethodEnum;
    /**
     * 
     * @type {CardInformation}
     * @memberof CustomerTransaction
     */
    'cardInformation': CardInformation;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'paymentStatus': CustomerTransactionPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'stripeAccountCustomerId': string;
    /**
     * 
     * @type {TicketTransaction}
     * @memberof CustomerTransaction
     */
    'ticketTransaction': TicketTransaction;
    /**
     * 
     * @type {number}
     * @memberof CustomerTransaction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerTransaction
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerTransaction
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransaction
     */
    'deletedAt': string;
}

export const CustomerTransactionPaymentMethodEnum = {
    Pax: 'PAX',
    Clover: 'CLOVER',
    Stripe: 'STRIPE'
} as const;

export type CustomerTransactionPaymentMethodEnum = typeof CustomerTransactionPaymentMethodEnum[keyof typeof CustomerTransactionPaymentMethodEnum];
export const CustomerTransactionPaymentStatusEnum = {
    Success: 'SUCCESS',
    Failed: 'FAILED',
    Pending: 'PENDING'
} as const;

export type CustomerTransactionPaymentStatusEnum = typeof CustomerTransactionPaymentStatusEnum[keyof typeof CustomerTransactionPaymentStatusEnum];

/**
 * 
 * @export
 * @interface CustomerTransactionDto
 */
export interface CustomerTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTransactionDto
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransactionDto
     */
    'customerPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransactionDto
     */
    'paymentMethod'?: CustomerTransactionDtoPaymentMethodEnum;
    /**
     * 
     * @type {CardInformation}
     * @memberof CustomerTransactionDto
     */
    'cardInformation'?: CardInformation | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerTransactionDto
     */
    'paymentStatus'?: CustomerTransactionDtoPaymentStatusEnum;
}

export const CustomerTransactionDtoPaymentMethodEnum = {
    Pax: 'PAX',
    Clover: 'CLOVER',
    Stripe: 'STRIPE'
} as const;

export type CustomerTransactionDtoPaymentMethodEnum = typeof CustomerTransactionDtoPaymentMethodEnum[keyof typeof CustomerTransactionDtoPaymentMethodEnum];
export const CustomerTransactionDtoPaymentStatusEnum = {
    Success: 'SUCCESS',
    Failed: 'FAILED',
    Pending: 'PENDING'
} as const;

export type CustomerTransactionDtoPaymentStatusEnum = typeof CustomerTransactionDtoPaymentStatusEnum[keyof typeof CustomerTransactionDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface DashboardCompensation
 */
export interface DashboardCompensation {
    /**
     * 
     * @type {string}
     * @memberof DashboardCompensation
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DashboardCompensation
     */
    'compensation': number;
}
/**
 * 
 * @export
 * @interface DashboardResponse
 */
export interface DashboardResponse {
    /**
     * 
     * @type {DashboardResponseMyAppointment}
     * @memberof DashboardResponse
     */
    'myAppointment': DashboardResponseMyAppointment;
    /**
     * 
     * @type {DashboardResponseMyCalendar}
     * @memberof DashboardResponse
     */
    'myCalendar': DashboardResponseMyCalendar;
    /**
     * 
     * @type {DashboardResponseMyCompensation}
     * @memberof DashboardResponse
     */
    'myCompensation': DashboardResponseMyCompensation;
}
/**
 * 
 * @export
 * @interface DashboardResponseMyAppointment
 */
export interface DashboardResponseMyAppointment {
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyAppointment
     */
    'today'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyAppointment
     */
    'tomorrow'?: number;
}
/**
 * 
 * @export
 * @interface DashboardResponseMyCalendar
 */
export interface DashboardResponseMyCalendar {
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'waiting'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'confirmed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'checkedIn'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'beingServed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCalendar
     */
    'canceled'?: number;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof DashboardResponseMyCalendar
     */
    'appointments'?: Array<Appointment>;
}
/**
 * 
 * @export
 * @interface DashboardResponseMyCompensation
 */
export interface DashboardResponseMyCompensation {
    /**
     * 
     * @type {DashboardResponseMyCompensationToday}
     * @memberof DashboardResponseMyCompensation
     */
    'today'?: DashboardResponseMyCompensationToday;
    /**
     * 
     * @type {DashboardResponseMyCompensationToday}
     * @memberof DashboardResponseMyCompensation
     */
    'thisMonth'?: DashboardResponseMyCompensationToday;
    /**
     * 
     * @type {Array<DashboardCompensation>}
     * @memberof DashboardResponseMyCompensation
     */
    'compensations'?: Array<DashboardCompensation>;
}
/**
 * 
 * @export
 * @interface DashboardResponseMyCompensationToday
 */
export interface DashboardResponseMyCompensationToday {
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCompensationToday
     */
    'wage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResponseMyCompensationToday
     */
    'tip'?: number;
}
/**
 * 
 * @export
 * @interface DataExportSalesByCategoryDTO
 */
export interface DataExportSalesByCategoryDTO {
    /**
     * 
     * @type {ReportSalesByCategory}
     * @memberof DataExportSalesByCategoryDTO
     */
    'content'?: ReportSalesByCategory;
    /**
     * 
     * @type {string}
     * @memberof DataExportSalesByCategoryDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataExportSalesByCategoryDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface DeleteBlockTimeDTO
 */
export interface DeleteBlockTimeDTO {
    /**
     * 
     * @type {string}
     * @memberof DeleteBlockTimeDTO
     */
    'type': DeleteBlockTimeDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteBlockTimeDTO
     */
    'timeDeleted'?: string;
}

export const DeleteBlockTimeDTOTypeEnum = {
    All: 'ALL',
    Only: 'ONLY'
} as const;

export type DeleteBlockTimeDTOTypeEnum = typeof DeleteBlockTimeDTOTypeEnum[keyof typeof DeleteBlockTimeDTOTypeEnum];

/**
 * 
 * @export
 * @interface DeleteCheckinDTO
 */
export interface DeleteCheckinDTO {
    /**
     * 
     * @type {string}
     * @memberof DeleteCheckinDTO
     */
    'dateCheckin': string;
}
/**
 * 
 * @export
 * @interface DepositTotalDTO
 */
export interface DepositTotalDTO {
    /**
     * 
     * @type {string}
     * @memberof DepositTotalDTO
     */
    'action': DepositTotalDTOActionEnum;
    /**
     * 
     * @type {number}
     * @memberof DepositTotalDTO
     */
    'money': number;
    /**
     * 
     * @type {string}
     * @memberof DepositTotalDTO
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof DepositTotalDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepositTotalDTO
     */
    'appointmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepositTotalDTO
     */
    'cardToken'?: string;
}

export const DepositTotalDTOActionEnum = {
    Cash: 'Cash',
    Check: 'Check',
    Card: 'Card',
    Others: 'Others'
} as const;

export type DepositTotalDTOActionEnum = typeof DepositTotalDTOActionEnum[keyof typeof DepositTotalDTOActionEnum];

/**
 * 
 * @export
 * @interface DetailCompensationResponse
 */
export interface DetailCompensationResponse {
    /**
     * 
     * @type {string}
     * @memberof DetailCompensationResponse
     */
    'monthCompensation': string;
    /**
     * 
     * @type {number}
     * @memberof DetailCompensationResponse
     */
    'totalPayment': number;
}
/**
 * 
 * @export
 * @interface DetailResponse
 */
export interface DetailResponse {
    /**
     * 
     * @type {number}
     * @memberof DetailResponse
     */
    'totalPayment': number;
    /**
     * 
     * @type {Array<DetailCompensationResponse>}
     * @memberof DetailResponse
     */
    'compensations': Array<DetailCompensationResponse>;
}
/**
 * 
 * @export
 * @interface DeviceInfo
 */
export interface DeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'serial': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceInfo
     */
    'supportsAcks': boolean;
}
/**
 * 
 * @export
 * @interface DeviceToken
 */
export interface DeviceToken {
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    'token': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceToken
     */
    'userId': number | null;
    /**
     * 
     * @type {User}
     * @memberof DeviceToken
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof DeviceToken
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceToken
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceToken
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface DiscountDTO
 */
export interface DiscountDTO {
    /**
     * 
     * @type {string}
     * @memberof DiscountDTO
     */
    'type'?: DiscountDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountDTO
     */
    'discountApply'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDTO
     */
    'moneyDiscount'?: number;
}

export const DiscountDTOTypeEnum = {
    Percent: '%',
    Dollar: '$'
} as const;

export type DiscountDTOTypeEnum = typeof DiscountDTOTypeEnum[keyof typeof DiscountDTOTypeEnum];

/**
 * 
 * @export
 * @interface DisplaySettingsDTO
 */
export interface DisplaySettingsDTO {
    /**
     * 
     * @type {AppointmentSettingDTO}
     * @memberof DisplaySettingsDTO
     */
    'appointment': AppointmentSettingDTO;
    /**
     * 
     * @type {TurnsSettingDTO}
     * @memberof DisplaySettingsDTO
     */
    'turns': TurnsSettingDTO;
    /**
     * 
     * @type {boolean}
     * @memberof DisplaySettingsDTO
     */
    'suggestNextTechnicians': boolean;
    /**
     * 
     * @type {SuggestNextTechniciansByDTO}
     * @memberof DisplaySettingsDTO
     */
    'suggestNextTechnicianBy': SuggestNextTechniciansByDTO;
    /**
     * 
     * @type {LineUpTechniciansByDTO}
     * @memberof DisplaySettingsDTO
     */
    'lineUpTechniciansBy': LineUpTechniciansByDTO;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'terminatedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'loginName': string;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'store': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'defaultAvatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'legalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'address'?: string;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Employee
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {Array<TechnicianCheckin>}
     * @memberof Employee
     */
    'technicianCheckins'?: Array<TechnicianCheckin>;
    /**
     * 
     * @type {Array<BlockTime>}
     * @memberof Employee
     */
    'blockTime'?: Array<BlockTime>;
    /**
     * 
     * @type {User}
     * @memberof Employee
     */
    'user'?: User;
    /**
     * 
     * @type {Asset}
     * @memberof Employee
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {EmployeeProfileSetting}
     * @memberof Employee
     */
    'employeeProfileSetting': EmployeeProfileSetting;
    /**
     * 
     * @type {EmployeeWorkingHours}
     * @memberof Employee
     */
    'employeeWorkingHours': EmployeeWorkingHours;
    /**
     * 
     * @type {Array<Salon>}
     * @memberof Employee
     */
    'salons': Array<Salon>;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof Employee
     */
    'skills': Array<ServiceItems>;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface EmployeeControllerGet200Response
 */
export interface EmployeeControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof EmployeeControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof EmployeeControllerGet200Response
     */
    'content'?: Array<Employee>;
}
/**
 * 
 * @export
 * @interface EmployeeControllerGet200ResponseAllOf
 */
export interface EmployeeControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Employee>}
     * @memberof EmployeeControllerGet200ResponseAllOf
     */
    'content'?: Array<Employee>;
}
/**
 * 
 * @export
 * @interface EmployeeProfileSetting
 */
export interface EmployeeProfileSetting {
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'commissionRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'hourRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'tipRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'dailyBonus'?: number;
    /**
     * 
     * @type {object}
     * @memberof EmployeeProfileSetting
     */
    'dailyDeduction': object;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'isSameSalonDailyDeduction'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'minimunCommission'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeProfileSetting
     */
    'guaranteePeriods'?: EmployeeProfileSettingGuaranteePeriodsEnum;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'minimumTurn'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'guaranteePeriodsValue'?: number;
    /**
     * 
     * @type {Array<PeriodDate>}
     * @memberof EmployeeProfileSetting
     */
    'periodDates': Array<PeriodDate>;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'reminderMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'receivingNotification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'onlineAppointments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'selfCreatingAppointments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeProfileSetting
     */
    'timeBlockers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'checkRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'castAndOtherRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeProfileSetting
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeProfileSetting
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeProfileSetting
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeProfileSetting
     */
    'deletedAt': string;
}

export const EmployeeProfileSettingGuaranteePeriodsEnum = {
    Daily: 'Daily',
    EveryDay: 'EveryDay',
    Monthly: 'Monthly',
    EveryMonths: 'EveryMonths',
    SpecificDays: 'SpecificDays'
} as const;

export type EmployeeProfileSettingGuaranteePeriodsEnum = typeof EmployeeProfileSettingGuaranteePeriodsEnum[keyof typeof EmployeeProfileSettingGuaranteePeriodsEnum];

/**
 * 
 * @export
 * @interface EmployeeWorkingHours
 */
export interface EmployeeWorkingHours {
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeWorkingHours
     */
    'isSameSalon': boolean;
    /**
     * 
     * @type {object}
     * @memberof EmployeeWorkingHours
     */
    'workingTime'?: object;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWorkingHours
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWorkingHours
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWorkingHours
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWorkingHours
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWorkingHours
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeWorkingHours
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Expense
 */
export interface Expense {
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'category': ExpenseCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'note': string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Expense
     */
    'file'?: Array<Asset>;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof Expense
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    'deletedAt': string;
}

export const ExpenseCategoryEnum = {
    RentMortgagePayments: 'Rent/mortgage payments',
    UtilityBillsElectricityWaterInternet: 'Utility bills (electricity, water, internet)',
    InventorySupply: 'Inventory & supply',
    EquipmentMaintenanceAndRepair: 'Equipment maintenance and repair',
    MarketingAdvertisingAndPromotions: 'Marketing, advertising, and promotions',
    Insurance: 'Insurance',
    TaxesLicenses: 'Taxes & licenses',
    FireProtectionPrevention: 'Fire protection & prevention',
    Others: 'Others'
} as const;

export type ExpenseCategoryEnum = typeof ExpenseCategoryEnum[keyof typeof ExpenseCategoryEnum];

/**
 * 
 * @export
 * @interface Extra
 */
export interface Extra {
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    'authorizingNetworkName': string;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    'cvmResult': string;
}
/**
 * 
 * @export
 * @interface FeeDiscount
 */
export interface FeeDiscount {
    /**
     * 
     * @type {string}
     * @memberof FeeDiscount
     */
    'feeName': string;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'autoOption': number;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'manualOption': number;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof FeeDiscount
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FeeDiscount
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof FeeDiscount
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof FeeDiscount
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof FeeDiscount
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface FromToDTO
 */
export interface FromToDTO {
    /**
     * 
     * @type {string}
     * @memberof FromToDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof FromToDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface GeneralReward
 */
export interface GeneralReward {
    /**
     * 
     * @type {number}
     * @memberof GeneralReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof GeneralReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof GeneralReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface GetCompensationTechnicianDTO
 */
export interface GetCompensationTechnicianDTO {
    /**
     * 
     * @type {string}
     * @memberof GetCompensationTechnicianDTO
     */
    'dateCompensation': string;
}
/**
 * 
 * @export
 * @interface GiftCardDTO
 */
export interface GiftCardDTO {
    /**
     * 
     * @type {number}
     * @memberof GiftCardDTO
     */
    'giftCardValue': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardDTO
     */
    'giftCardCode': number;
}
/**
 * 
 * @export
 * @interface GiftCardHistory
 */
export interface GiftCardHistory {
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'giftCardSelling': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'giftCardPayment': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'giftCardBalance': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'giftCardRaw': number;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'giftCardCode': number;
    /**
     * 
     * @type {Ticket}
     * @memberof GiftCardHistory
     */
    'ticket': Ticket;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardHistory
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardHistory
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof GiftCardHistory
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof GiftCardHistory
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LineUpTechniciansByDTO
 */
export interface LineUpTechniciansByDTO {
    /**
     * 
     * @type {string}
     * @memberof LineUpTechniciansByDTO
     */
    'value': LineUpTechniciansByDTOValueEnum;
    /**
     * 
     * @type {string}
     * @memberof LineUpTechniciansByDTO
     */
    'order': LineUpTechniciansByDTOOrderEnum;
}

export const LineUpTechniciansByDTOValueEnum = {
    ClockInTime: 'CLOCK_IN_TIME'
} as const;

export type LineUpTechniciansByDTOValueEnum = typeof LineUpTechniciansByDTOValueEnum[keyof typeof LineUpTechniciansByDTOValueEnum];
export const LineUpTechniciansByDTOOrderEnum = {
    RecentFirst: 'RECENT_FIRST',
    EarlyFirst: 'EARLY_FIRST'
} as const;

export type LineUpTechniciansByDTOOrderEnum = typeof LineUpTechniciansByDTOOrderEnum[keyof typeof LineUpTechniciansByDTOOrderEnum];

/**
 * 
 * @export
 * @interface ListSalon
 */
export interface ListSalon {
    /**
     * 
     * @type {number}
     * @memberof ListSalon
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'zipCode'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListSalon
     */
    'openingTime'?: object;
    /**
     * 
     * @type {object}
     * @memberof ListSalon
     */
    'closingTime'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'salonBankAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSalon
     */
    'beneficiaryBank'?: string;
    /**
     * 
     * @type {CreateBusinessDailyDeductionDTO}
     * @memberof ListSalon
     */
    'dailyDeduction'?: CreateBusinessDailyDeductionDTO;
    /**
     * 
     * @type {CreateBusinessWorkingHours}
     * @memberof ListSalon
     */
    'salonSetting'?: CreateBusinessWorkingHours;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ListSalon
     */
    'employees'?: Array<Employee>;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof ListSalon
     */
    'serviceItems'?: Array<ServiceItems>;
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof ListSalon
     */
    'announcement'?: Array<Announcement>;
    /**
     * 
     * @type {Promotion}
     * @memberof ListSalon
     */
    'promotion'?: Promotion;
    /**
     * 
     * @type {number}
     * @memberof ListSalon
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListSalon
     */
    'depositNotService'?: number;
}
/**
 * 
 * @export
 * @interface LoginCodeDto
 */
export interface LoginCodeDto {
    /**
     * 
     * @type {string}
     * @memberof LoginCodeDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {object}
     * @memberof LoginResponseDto
     */
    'user'?: object;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface LoyaltyReward
 */
export interface LoyaltyReward {
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyReward
     */
    'loyaltyReward': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyReward
     */
    'allowCustomAmount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyReward
     */
    'autoRewardPoint': boolean;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'reward': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoyaltyReward
     */
    'redeemOptions': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'expiration': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'saleTicket': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof LoyaltyReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ManuallyPurgeDateDto
 */
export interface ManuallyPurgeDateDto {
    /**
     * 
     * @type {boolean}
     * @memberof ManuallyPurgeDateDto
     */
    'saleTickets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManuallyPurgeDateDto
     */
    'employePayment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManuallyPurgeDateDto
     */
    'closeout'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManuallyPurgeDateDto
     */
    'cutOffDate'?: string;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'body': string;
    /**
     * 
     * @type {object}
     * @memberof Notification
     */
    'data': object;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'seen': boolean;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'userId': number | null;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface NotificationControllerGetAll200Response
 */
export interface NotificationControllerGetAll200Response {
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGetAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGetAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGetAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationControllerGetAll200Response
     */
    'content'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface NotificationControllerGetAll200ResponseAllOf
 */
export interface NotificationControllerGetAll200ResponseAllOf {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationControllerGetAll200ResponseAllOf
     */
    'content'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface OccasionReward
 */
export interface OccasionReward {
    /**
     * 
     * @type {string}
     * @memberof OccasionReward
     */
    'occasionPromotion': string;
    /**
     * 
     * @type {boolean}
     * @memberof OccasionReward
     */
    'occasionReward': boolean;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'discountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'discountPercent': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof OccasionReward
     */
    'discountServices': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof OccasionReward
     */
    'allService': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OccasionReward
     */
    'isDiscountAll': boolean;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof OccasionReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OccasionReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof OccasionReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof OccasionReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof OccasionReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OverviewResponse
 */
export interface OverviewResponse {
    /**
     * 
     * @type {number}
     * @memberof OverviewResponse
     */
    'totalPayment': number;
    /**
     * 
     * @type {number}
     * @memberof OverviewResponse
     */
    'wage': number;
    /**
     * 
     * @type {number}
     * @memberof OverviewResponse
     */
    'tip': number;
    /**
     * 
     * @type {Array<WageAndTipReponse>}
     * @memberof OverviewResponse
     */
    'wageAndTip': Array<WageAndTipReponse>;
    /**
     * 
     * @type {Array<CompensationOverview>}
     * @memberof OverviewResponse
     */
    'compensations': Array<CompensationOverview>;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginationResponseExpense
 */
export interface PaginationResponseExpense {
    /**
     * 
     * @type {Array<Expense>}
     * @memberof PaginationResponseExpense
     */
    'content': Array<Expense>;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseExpense
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseExpense
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseExpense
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseExpense
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface PayTenantPaymentDto
 */
export interface PayTenantPaymentDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof PayTenantPaymentDto
     */
    'requestId': Array<number>;
}
/**
 * 
 * @export
 * @interface PaymentFilterDTO
 */
export interface PaymentFilterDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof PaymentFilterDTO
     */
    'technicianIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PaymentFilterDTO
     */
    'datePayment': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentFilterDTO
     */
    'typePayment': PaymentFilterDTOTypePaymentEnum;
}

export const PaymentFilterDTOTypePaymentEnum = {
    Custom: 'custom',
    OneDay: 'oneDay',
    Week: 'week',
    SemiMonth: 'semiMonth',
    Month: 'month'
} as const;

export type PaymentFilterDTOTypePaymentEnum = typeof PaymentFilterDTOTypePaymentEnum[keyof typeof PaymentFilterDTOTypePaymentEnum];

/**
 * 
 * @export
 * @interface PaymentInformation
 */
export interface PaymentInformation {
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'id': string;
    /**
     * 
     * @type {Order}
     * @memberof PaymentInformation
     */
    'order': Order;
    /**
     * 
     * @type {Tender}
     * @memberof PaymentInformation
     */
    'tender': Tender;
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'tipAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'taxAmount': number;
    /**
     * 
     * @type {object}
     * @memberof PaymentInformation
     */
    'cashbackAmount': object;
    /**
     * 
     * @type {object}
     * @memberof PaymentInformation
     */
    'cashTendered': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'externalPaymentId': string;
    /**
     * 
     * @type {PaymentInformationEmployee}
     * @memberof PaymentInformation
     */
    'employee': PaymentInformationEmployee;
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'createdTime': number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentInformation
     */
    'offline': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'result': string;
    /**
     * 
     * @type {CardTransaction}
     * @memberof PaymentInformation
     */
    'cardTransaction': CardTransaction;
    /**
     * 
     * @type {Attributes}
     * @memberof PaymentInformation
     */
    'attributes': Attributes;
    /**
     * 
     * @type {Array<object>}
     * @memberof PaymentInformation
     */
    'taxRates': Array<object>;
    /**
     * 
     * @type {object}
     * @memberof PaymentInformation
     */
    'lineItemPayments': object;
    /**
     * 
     * @type {TransactionSettings}
     * @memberof PaymentInformation
     */
    'transactionSettings': TransactionSettings;
    /**
     * 
     * @type {TransactionInfo}
     * @memberof PaymentInformation
     */
    'transactionInfo': TransactionInfo;
    /**
     * 
     * @type {object}
     * @memberof PaymentInformation
     */
    'externalReferenceId': object;
}
/**
 * 
 * @export
 * @interface PaymentInformationEmployee
 */
export interface PaymentInformationEmployee {
    /**
     * 
     * @type {string}
     * @memberof PaymentInformationEmployee
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PaymentMethodResponse
 */
export interface PaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'last4': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'brand': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'lastUsed': string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'status': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodResponse
     */
    'default_for_currency': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    'exp_month': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    'exp_year': number;
}
/**
 * 
 * @export
 * @interface PaymentResponese
 */
export interface PaymentResponese {
    /**
     * 
     * @type {string}
     * @memberof PaymentResponese
     */
    'datePayment': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponese
     */
    'checkPayment': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponese
     */
    'otherPayments': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentResponese
     */
    'totalPayment': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentResponese
     */
    'datePayments': Array<string>;
}
/**
 * 
 * @export
 * @interface PeriodDate
 */
export interface PeriodDate {
    /**
     * 
     * @type {string}
     * @memberof PeriodDate
     */
    'date': string;
    /**
     * 
     * @type {EmployeeProfileSetting}
     * @memberof PeriodDate
     */
    'employeeProfileSetting': EmployeeProfileSetting;
    /**
     * 
     * @type {number}
     * @memberof PeriodDate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodDate
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof PeriodDate
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodDate
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof PeriodDate
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof PeriodDate
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PermissionDefinitionConfig
 */
export interface PermissionDefinitionConfig {
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'assignable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'internal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PermissionDefinitionConfig
     */
    'levelModules'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'isTenantPermission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'isDefaultForCheckinStand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'isDefaultForTechnician'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'isDefaultForManager'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'isDefaultForBusinessOwner'?: boolean;
}
/**
 * 
 * @export
 * @interface PermissionGroupDto
 */
export interface PermissionGroupDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'description': string;
    /**
     * 
     * @type {Array<PermissionDefinitionConfig>}
     * @memberof PermissionGroupDto
     */
    'permissions': Array<PermissionDefinitionConfig>;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'salonId': number;
    /**
     * 
     * @type {FeeDiscount}
     * @memberof Promotion
     */
    'feeDiscount': FeeDiscount;
    /**
     * 
     * @type {CashDiscount}
     * @memberof Promotion
     */
    'cashDiscount': CashDiscount;
    /**
     * 
     * @type {CheckinReward}
     * @memberof Promotion
     */
    'checkinReward': CheckinReward;
    /**
     * 
     * @type {LoyaltyReward}
     * @memberof Promotion
     */
    'loyaltyReward': LoyaltyReward;
    /**
     * 
     * @type {OccasionReward}
     * @memberof Promotion
     */
    'occasionReward': OccasionReward;
    /**
     * 
     * @type {BirthdayReward}
     * @memberof Promotion
     */
    'birthdayReward': BirthdayReward;
    /**
     * 
     * @type {ReferralReward}
     * @memberof Promotion
     */
    'referralReward': ReferralReward;
    /**
     * 
     * @type {WeeklyReward}
     * @memberof Promotion
     */
    'weeklyReward': WeeklyReward;
    /**
     * 
     * @type {GeneralReward}
     * @memberof Promotion
     */
    'generalReward': GeneralReward;
    /**
     * 
     * @type {Salon}
     * @memberof Promotion
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PromotionControllerGetAllAnnouncements200Response
 */
export interface PromotionControllerGetAllAnnouncements200Response {
    /**
     * 
     * @type {number}
     * @memberof PromotionControllerGetAllAnnouncements200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionControllerGetAllAnnouncements200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionControllerGetAllAnnouncements200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof PromotionControllerGetAllAnnouncements200Response
     */
    'content'?: Array<Announcement>;
}
/**
 * 
 * @export
 * @interface PromotionControllerGetAllAnnouncements200ResponseAllOf
 */
export interface PromotionControllerGetAllAnnouncements200ResponseAllOf {
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof PromotionControllerGetAllAnnouncements200ResponseAllOf
     */
    'content'?: Array<Announcement>;
}
/**
 * 
 * @export
 * @interface RedeemOptionDTO
 */
export interface RedeemOptionDTO {
    /**
     * 
     * @type {number}
     * @memberof RedeemOptionDTO
     */
    'redeem': number;
    /**
     * 
     * @type {object}
     * @memberof RedeemOptionDTO
     */
    'for': object;
}
/**
 * 
 * @export
 * @interface ReferralCodeCheckDTO
 */
export interface ReferralCodeCheckDTO {
    /**
     * 
     * @type {number}
     * @memberof ReferralCodeCheckDTO
     */
    'customerId': number;
}
/**
 * 
 * @export
 * @interface ReferralDiscountDTO
 */
export interface ReferralDiscountDTO {
    /**
     * 
     * @type {string}
     * @memberof ReferralDiscountDTO
     */
    'referralCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralDiscountDTO
     */
    'type'?: ReferralDiscountDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReferralDiscountDTO
     */
    'discountApply'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralDiscountDTO
     */
    'moneyDiscount'?: number;
}

export const ReferralDiscountDTOTypeEnum = {
    Percent: '%',
    Dollar: '$'
} as const;

export type ReferralDiscountDTOTypeEnum = typeof ReferralDiscountDTOTypeEnum[keyof typeof ReferralDiscountDTOTypeEnum];

/**
 * 
 * @export
 * @interface ReferralReward
 */
export interface ReferralReward {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralReward
     */
    'referralReward': boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'rewardAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof ReferralReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ReferralReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralReward
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface RefundTransactionDTO
 */
export interface RefundTransactionDTO {
    /**
     * 
     * @type {number}
     * @memberof RefundTransactionDTO
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof RefundTransactionDTO
     */
    'money': number;
    /**
     * 
     * @type {string}
     * @memberof RefundTransactionDTO
     */
    'typePayment': RefundTransactionDTOTypePaymentEnum;
}

export const RefundTransactionDTOTypePaymentEnum = {
    Check: 'Check',
    Cash: 'Cash',
    OriginalCard: 'OriginalCard'
} as const;

export type RefundTransactionDTOTypePaymentEnum = typeof RefundTransactionDTOTypePaymentEnum[keyof typeof RefundTransactionDTOTypePaymentEnum];

/**
 * 
 * @export
 * @interface RegisterCustomerDto
 */
export interface RegisterCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface ReportControllerGetAllValuedEmployee200Response
 */
export interface ReportControllerGetAllValuedEmployee200Response {
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetAllValuedEmployee200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetAllValuedEmployee200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetAllValuedEmployee200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ReportValuedEmployee>}
     * @memberof ReportControllerGetAllValuedEmployee200Response
     */
    'content'?: Array<ReportValuedEmployee>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetAllValuedEmployee200ResponseAllOf
 */
export interface ReportControllerGetAllValuedEmployee200ResponseAllOf {
    /**
     * 
     * @type {Array<ReportValuedEmployee>}
     * @memberof ReportControllerGetAllValuedEmployee200ResponseAllOf
     */
    'content'?: Array<ReportValuedEmployee>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetGiftCard200Response
 */
export interface ReportControllerGetGiftCard200Response {
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetGiftCard200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetGiftCard200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetGiftCard200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<GiftCardHistory>}
     * @memberof ReportControllerGetGiftCard200Response
     */
    'content'?: Array<GiftCardHistory>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetGiftCard200ResponseAllOf
 */
export interface ReportControllerGetGiftCard200ResponseAllOf {
    /**
     * 
     * @type {Array<GiftCardHistory>}
     * @memberof ReportControllerGetGiftCard200ResponseAllOf
     */
    'content'?: Array<GiftCardHistory>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetRevenueReport200Response
 */
export interface ReportControllerGetRevenueReport200Response {
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReport200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReport200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReport200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<RevenueReport>}
     * @memberof ReportControllerGetRevenueReport200Response
     */
    'content'?: Array<RevenueReport>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetRevenueReport200ResponseAllOf
 */
export interface ReportControllerGetRevenueReport200ResponseAllOf {
    /**
     * 
     * @type {Array<RevenueReport>}
     * @memberof ReportControllerGetRevenueReport200ResponseAllOf
     */
    'content'?: Array<RevenueReport>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetRevenueReportGroupTechnician200Response
 */
export interface ReportControllerGetRevenueReportGroupTechnician200Response {
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReportGroupTechnician200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReportGroupTechnician200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetRevenueReportGroupTechnician200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<RevenueReportGrp>}
     * @memberof ReportControllerGetRevenueReportGroupTechnician200Response
     */
    'content'?: Array<RevenueReportGrp>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetRevenueReportGroupTechnician200ResponseAllOf
 */
export interface ReportControllerGetRevenueReportGroupTechnician200ResponseAllOf {
    /**
     * 
     * @type {Array<RevenueReportGrp>}
     * @memberof ReportControllerGetRevenueReportGroupTechnician200ResponseAllOf
     */
    'content'?: Array<RevenueReportGrp>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetValuedCustomer200Response
 */
export interface ReportControllerGetValuedCustomer200Response {
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetValuedCustomer200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetValuedCustomer200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ReportControllerGetValuedCustomer200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ReportValuedCustomer>}
     * @memberof ReportControllerGetValuedCustomer200Response
     */
    'content'?: Array<ReportValuedCustomer>;
}
/**
 * 
 * @export
 * @interface ReportControllerGetValuedCustomer200ResponseAllOf
 */
export interface ReportControllerGetValuedCustomer200ResponseAllOf {
    /**
     * 
     * @type {Array<ReportValuedCustomer>}
     * @memberof ReportControllerGetValuedCustomer200ResponseAllOf
     */
    'content'?: Array<ReportValuedCustomer>;
}
/**
 * 
 * @export
 * @interface ReportDashboard
 */
export interface ReportDashboard {
    /**
     * 
     * @type {CustomerCountToday}
     * @memberof ReportDashboard
     */
    'customerCountToday'?: CustomerCountToday;
    /**
     * 
     * @type {AppointmentTodayDTO}
     * @memberof ReportDashboard
     */
    'appointmentToday'?: AppointmentTodayDTO;
    /**
     * 
     * @type {RevenueDTO}
     * @memberof ReportDashboard
     */
    'revenue'?: RevenueDTO;
    /**
     * 
     * @type {Array<Revenue7DaysDTO>}
     * @memberof ReportDashboard
     */
    'reduce7Days'?: Array<Revenue7DaysDTO>;
}
/**
 * 
 * @export
 * @interface ReportSalesByCategory
 */
export interface ReportSalesByCategory {
    /**
     * 
     * @type {Array<SalesByCategoryDTO>}
     * @memberof ReportSalesByCategory
     */
    'dataGiftCard': Array<SalesByCategoryDTO>;
    /**
     * 
     * @type {Array<SalesByCategoryDTO>}
     * @memberof ReportSalesByCategory
     */
    'dataMerchandise': Array<SalesByCategoryDTO>;
    /**
     * 
     * @type {Array<SalesByCategoryDTO>}
     * @memberof ReportSalesByCategory
     */
    'dataService': Array<SalesByCategoryDTO>;
}
/**
 * 
 * @export
 * @interface ReportValuedCustomer
 */
export interface ReportValuedCustomer {
    /**
     * 
     * @type {number}
     * @memberof ReportValuedCustomer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReportValuedCustomer
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportValuedCustomer
     */
    'phoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedCustomer
     */
    'moneySpent': number;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedCustomer
     */
    'numberOfTickets': number;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedCustomer
     */
    'numberOfServices': number;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedCustomer
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface ReportValuedEmployee
 */
export interface ReportValuedEmployee {
    /**
     * 
     * @type {number}
     * @memberof ReportValuedEmployee
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportValuedEmployee
     */
    'employeeName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportValuedEmployee
     */
    'employeePhone': string;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedEmployee
     */
    'revenue': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportValuedEmployee
     */
    'customerIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedEmployee
     */
    'customerOfNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ReportValuedEmployee
     */
    'customerOfTicket': number;
}
/**
 * 
 * @export
 * @interface RequestPasswordResetDto
 */
export interface RequestPasswordResetDto {
    /**
     * 
     * @type {string}
     * @memberof RequestPasswordResetDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface Revenue7DaysDTO
 */
export interface Revenue7DaysDTO {
    /**
     * 
     * @type {string}
     * @memberof Revenue7DaysDTO
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Revenue7DaysDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface RevenueDTO
 */
export interface RevenueDTO {
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'toDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'yesterday'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'thisWeek'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'lastWeek'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'thisMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'lastMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'thisYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueDTO
     */
    'lastYear'?: number;
}
/**
 * 
 * @export
 * @interface RevenueReport
 */
export interface RevenueReport {
    /**
     * 
     * @type {number}
     * @memberof RevenueReport
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RevenueReport
     */
    'createdOnDate'?: string;
    /**
     * 
     * @type {RevenueReportTechnician}
     * @memberof RevenueReport
     */
    'technician': RevenueReportTechnician;
    /**
     * 
     * @type {number}
     * @memberof RevenueReport
     */
    'sale': number;
    /**
     * 
     * @type {RevenueReportInvolce}
     * @memberof RevenueReport
     */
    'involce': RevenueReportInvolce;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReport
     */
    'rewardCost': RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {RevenueReportCustomerPayment}
     * @memberof RevenueReport
     */
    'customerPayment': RevenueReportCustomerPayment;
    /**
     * 
     * @type {number}
     * @memberof RevenueReport
     */
    'grossRevenue': number;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReport
     */
    'income': RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {Ticket}
     * @memberof RevenueReport
     */
    'billTicket'?: Ticket;
}
/**
 * 
 * @export
 * @interface RevenueReportCustomerPayment
 */
export interface RevenueReportCustomerPayment {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'rewardRedeem'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'giftCard'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'check'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'orther'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPayment
     */
    'cash'?: number;
    /**
     * 
     * @type {RevenueReportCustomerPaymentCreditCard}
     * @memberof RevenueReportCustomerPayment
     */
    'creditCard'?: RevenueReportCustomerPaymentCreditCard;
}
/**
 * 
 * @export
 * @interface RevenueReportCustomerPaymentCreditCard
 */
export interface RevenueReportCustomerPaymentCreditCard {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPaymentCreditCard
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPaymentCreditCard
     */
    'bill'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportCustomerPaymentCreditCard
     */
    'tip'?: number;
}
/**
 * 
 * @export
 * @interface RevenueReportGrp
 */
export interface RevenueReportGrp {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportGrp
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RevenueReportGrp
     */
    'createdOnDate'?: string;
    /**
     * 
     * @type {RevenueReportTechnician}
     * @memberof RevenueReportGrp
     */
    'technician': RevenueReportTechnician;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportGrp
     */
    'sale': number;
    /**
     * 
     * @type {RevenueReportInvolce}
     * @memberof RevenueReportGrp
     */
    'involce': RevenueReportInvolce;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReportGrp
     */
    'rewardCost': RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {RevenueReportCustomerPayment}
     * @memberof RevenueReportGrp
     */
    'customerPayment': RevenueReportCustomerPayment;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportGrp
     */
    'grossRevenue': number;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReportGrp
     */
    'income': RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {Ticket}
     * @memberof RevenueReportGrp
     */
    'billTicket'?: Ticket;
    /**
     * 
     * @type {Array<RevenueReport>}
     * @memberof RevenueReportGrp
     */
    'listDetail'?: Array<RevenueReport>;
}
/**
 * 
 * @export
 * @interface RevenueReportInvolce
 */
export interface RevenueReportInvolce {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolce
     */
    'value'?: number;
    /**
     * 
     * @type {RevenueReportInvolceService}
     * @memberof RevenueReportInvolce
     */
    'service'?: RevenueReportInvolceService;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolce
     */
    'merchandiseAndGCSale'?: number;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReportInvolce
     */
    'discountCost'?: RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {RevenueReportInvolceDiscountCost}
     * @memberof RevenueReportInvolce
     */
    'tip'?: RevenueReportInvolceDiscountCost;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolce
     */
    'tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolce
     */
    'fee'?: number;
}
/**
 * 
 * @export
 * @interface RevenueReportInvolceDiscountCost
 */
export interface RevenueReportInvolceDiscountCost {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceDiscountCost
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceDiscountCost
     */
    'owner'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceDiscountCost
     */
    'technician'?: number;
}
/**
 * 
 * @export
 * @interface RevenueReportInvolceService
 */
export interface RevenueReportInvolceService {
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceService
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceService
     */
    'labor'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueReportInvolceService
     */
    'supply'?: number;
}
/**
 * 
 * @export
 * @interface RevenueReportTechnician
 */
export interface RevenueReportTechnician {
    /**
     * 
     * @type {Array<string>}
     * @memberof RevenueReportTechnician
     */
    'name'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RevenueReportTechnician
     */
    'ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RewardBalanceDiscountDTO
 */
export interface RewardBalanceDiscountDTO {
    /**
     * 
     * @type {string}
     * @memberof RewardBalanceDiscountDTO
     */
    'type'?: RewardBalanceDiscountDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RewardBalanceDiscountDTO
     */
    'discountApply'?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardBalanceDiscountDTO
     */
    'moneyDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardBalanceDiscountDTO
     */
    'point'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RewardBalanceDiscountDTO
     */
    'isCustomAmount'?: boolean;
}

export const RewardBalanceDiscountDTOTypeEnum = {
    Percent: '%',
    Dollar: '$'
} as const;

export type RewardBalanceDiscountDTOTypeEnum = typeof RewardBalanceDiscountDTOTypeEnum[keyof typeof RewardBalanceDiscountDTOTypeEnum];

/**
 * 
 * @export
 * @interface RewardInformation
 */
export interface RewardInformation {
    /**
     * 
     * @type {string}
     * @memberof RewardInformation
     */
    'transaction': string;
    /**
     * 
     * @type {number}
     * @memberof RewardInformation
     */
    'reward': number;
    /**
     * 
     * @type {number}
     * @memberof RewardInformation
     */
    'redeem': number;
    /**
     * 
     * @type {number}
     * @memberof RewardInformation
     */
    'remained': number;
    /**
     * 
     * @type {string}
     * @memberof RewardInformation
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof RewardInformation
     */
    'ticketId': number;
    /**
     * 
     * @type {Ticket}
     * @memberof RewardInformation
     */
    'ticket': Ticket | null;
    /**
     * 
     * @type {number}
     * @memberof RewardInformation
     */
    'rewardTotal': number;
    /**
     * 
     * @type {string}
     * @memberof RewardInformation
     */
    'type': RewardInformationTypeEnum;
}

export const RewardInformationTypeEnum = {
    CheckinReward: 'Checkin_Reward',
    LoyaltyReward: 'Loyalty_Reward',
    UseForCheckout: 'Use_For_Checkout'
} as const;

export type RewardInformationTypeEnum = typeof RewardInformationTypeEnum[keyof typeof RewardInformationTypeEnum];

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'order'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    'isSystemRole': boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof Role
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<SettingAuthorization>}
     * @memberof Role
     */
    'settingAuthorizations': Array<SettingAuthorization>;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200Response
 */
export interface RoleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200Response
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200ResponseAllOf
 */
export interface RoleControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200ResponseAllOf
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface SalesByCategoryDTO
 */
export interface SalesByCategoryDTO {
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SalesByCategoryDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesByCategoryDTO
     */
    'category': string;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'supply'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'grosssales'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'discount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesByCategoryDTO
     */
    'netsales'?: number;
}
/**
 * 
 * @export
 * @interface Salon
 */
export interface Salon {
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof Salon
     */
    'workingTime'?: object;
    /**
     * 
     * @type {DisplaySettingsDTO}
     * @memberof Salon
     */
    'displaySetting'?: DisplaySettingsDTO;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof Salon
     */
    'serviceItems'?: Array<ServiceItems>;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof Salon
     */
    'employees'?: Array<Employee>;
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof Salon
     */
    'announcement'?: Array<Announcement>;
    /**
     * 
     * @type {SalonSetting}
     * @memberof Salon
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {Promotion}
     * @memberof Salon
     */
    'promotion': Promotion;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'zipCode'?: string;
    /**
     * 
     * @type {object}
     * @memberof Salon
     */
    'openingTime': object;
    /**
     * 
     * @type {object}
     * @memberof Salon
     */
    'closingTime': object;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'salonBankAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'beneficiaryBank'?: string;
    /**
     * 
     * @type {object}
     * @memberof Salon
     */
    'dailyDeduction'?: object;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'stripeAccountId'?: string;
    /**
     * 
     * @type {CustomerStripeAccount}
     * @memberof Salon
     */
    'customerStripeAccount': CustomerStripeAccount;
    /**
     * 
     * @type {Array<Expense>}
     * @memberof Salon
     */
    'expense'?: Array<Expense>;
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof Salon
     */
    'sentMessages'?: Array<SentMessage>;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof Salon
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'depositNotService'?: number;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Salon
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Salon
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SalonControllerGet200Response
 */
export interface SalonControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof SalonControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SalonControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SalonControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Salon>}
     * @memberof SalonControllerGet200Response
     */
    'content'?: Array<Salon>;
}
/**
 * 
 * @export
 * @interface SalonControllerGet200ResponseAllOf
 */
export interface SalonControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Salon>}
     * @memberof SalonControllerGet200ResponseAllOf
     */
    'content'?: Array<Salon>;
}
/**
 * 
 * @export
 * @interface SalonSetting
 */
export interface SalonSetting {
    /**
     * 
     * @type {number}
     * @memberof SalonSetting
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof SalonSetting
     */
    'salon': Salon;
    /**
     * 
     * @type {SettingTip}
     * @memberof SalonSetting
     */
    'settingTip': SettingTip;
    /**
     * 
     * @type {SettingCustomerRate}
     * @memberof SalonSetting
     */
    'settingCustomerRate': SettingCustomerRate;
    /**
     * 
     * @type {BusinessSetting}
     * @memberof SalonSetting
     */
    'settingBusiness': BusinessSetting;
    /**
     * 
     * @type {Array<SettingPrinter>}
     * @memberof SalonSetting
     */
    'settingPrinter': Array<SettingPrinter>;
    /**
     * 
     * @type {SettingTextMessage}
     * @memberof SalonSetting
     */
    'settingTextMessage': SettingTextMessage;
    /**
     * 
     * @type {SettingCheckout}
     * @memberof SalonSetting
     */
    'settingCheckout': SettingCheckout;
    /**
     * 
     * @type {SettingCheckin}
     * @memberof SalonSetting
     */
    'settingCheckin': SettingCheckin;
    /**
     * 
     * @type {SettingData}
     * @memberof SalonSetting
     */
    'settingData': SettingData;
    /**
     * 
     * @type {SettingTerminalDevice}
     * @memberof SalonSetting
     */
    'settingTerminalDevice': SettingTerminalDevice;
    /**
     * 
     * @type {SettingBillingAndPayments}
     * @memberof SalonSetting
     */
    'settingBillingAndPayments': SettingBillingAndPayments;
    /**
     * 
     * @type {Array<SettingAuthorization>}
     * @memberof SalonSetting
     */
    'settingAuthorization': Array<SettingAuthorization>;
    /**
     * 
     * @type {number}
     * @memberof SalonSetting
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof SalonSetting
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof SalonSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SalonSetting
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SalonSetting
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SalonSetting
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SalonSetting
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SalonSetting
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Sample
 */
export interface Sample {
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof Sample
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof Sample
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof Sample
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Sample
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Sample
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Sample
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SearchFilterExpense
 */
export interface SearchFilterExpense {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterExpense
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterExpense
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterExpense
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterExpense
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterExpense
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterExpense
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface SearchFilterGiftCardDTO
 */
export interface SearchFilterGiftCardDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterGiftCardDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterGiftCardDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterGiftCardDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterGiftCardDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterGiftCardDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterGiftCardDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface SearchFilterTransactionDTO
 */
export interface SearchFilterTransactionDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterTransactionDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterTransactionDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterTransactionDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterTransactionDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterTransactionDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterTransactionDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface SearchFilterValuedCustomerDTO
 */
export interface SearchFilterValuedCustomerDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedCustomerDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface SearchFilterValuedEmployeeDTO
 */
export interface SearchFilterValuedEmployeeDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterValuedEmployeeDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface SearchRevenueDTO
 */
export interface SearchRevenueDTO {
    /**
     * 
     * @type {number}
     * @memberof SearchRevenueDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchRevenueDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchRevenueDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRevenueDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRevenueDTO
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof SearchRevenueDTO
     */
    'to': string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchRevenueDTO
     */
    'isGrpTechnician': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchRevenueDTO
     */
    'filterType': string;
}
/**
 * 
 * @export
 * @interface SearchTransactionDTO
 */
export interface SearchTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionDTO
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionDTO
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionDTO
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionDTO
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchTransactionDTO
     */
    'technicianIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SecurityCode
 */
export interface SecurityCode {
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureCardButton': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureCashCheckManual': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureOpenCashDrawer': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureCloseoutButton': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureGiftCardSaleButton': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureDiscountButton': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureManualRewarding': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'secureTransactionButton': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'managerCode': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityCode
     */
    'defaultManagerCode': string;
}
/**
 * 
 * @export
 * @interface SendMailAndExportDTO
 */
export interface SendMailAndExportDTO {
    /**
     * 
     * @type {string}
     * @memberof SendMailAndExportDTO
     */
    'dateCheckin': string;
    /**
     * 
     * @type {number}
     * @memberof SendMailAndExportDTO
     */
    'technicianId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SendMailAndExportDTO
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SendMailCompensationDTO
 */
export interface SendMailCompensationDTO {
    /**
     * 
     * @type {string}
     * @memberof SendMailCompensationDTO
     */
    'dateCompensation': string;
    /**
     * 
     * @type {string}
     * @memberof SendMailCompensationDTO
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SentMessage
 */
export interface SentMessage {
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'messageContent': string;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'category'?: SentMessageCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'type': SentMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'sendFromPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'sendToPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'sendFromEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'sendToEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof SentMessage
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof SentMessage
     */
    'salon': Salon;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof SentMessage
     */
    'customers'?: Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof SentMessage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SentMessage
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SentMessage
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SentMessage
     */
    'deletedAt': string;
}

export const SentMessageCategoryEnum = {
    CustomerConfirmationTemplate: 'customerConfirmationTemplate',
    CustomerReminderTemplate: 'customerReminderTemplate',
    CustomerCancelTemplate: 'customerCancelTemplate',
    TechnicianNotificationTemplate: 'technicianNotificationTemplate',
    TechnicianReminderTemplate: 'technicianReminderTemplate',
    CustomerRewardBalance: 'customerRewardBalance'
} as const;

export type SentMessageCategoryEnum = typeof SentMessageCategoryEnum[keyof typeof SentMessageCategoryEnum];
export const SentMessageTypeEnum = {
    Sms: 'SMS',
    Email: 'Email'
} as const;

export type SentMessageTypeEnum = typeof SentMessageTypeEnum[keyof typeof SentMessageTypeEnum];

/**
 * 
 * @export
 * @interface SentMessagesControllerFindAll200Response
 */
export interface SentMessagesControllerFindAll200Response {
    /**
     * 
     * @type {number}
     * @memberof SentMessagesControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SentMessagesControllerFindAll200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SentMessagesControllerFindAll200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof SentMessagesControllerFindAll200Response
     */
    'content'?: Array<SentMessage>;
}
/**
 * 
 * @export
 * @interface SentMessagesControllerFindAll200ResponseAllOf
 */
export interface SentMessagesControllerFindAll200ResponseAllOf {
    /**
     * 
     * @type {Array<SentMessage>}
     * @memberof SentMessagesControllerFindAll200ResponseAllOf
     */
    'content'?: Array<SentMessage>;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof Service
     */
    'serviceItems': Array<ServiceItems>;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Tenant}
     * @memberof Service
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ServiceChargeDTO
 */
export interface ServiceChargeDTO {
    /**
     * 
     * @type {string}
     * @memberof ServiceChargeDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceChargeDTO
     */
    'percentDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceChargeDTO
     */
    'moneyDiscount': number;
}
/**
 * 
 * @export
 * @interface ServiceControllerGet200Response
 */
export interface ServiceControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Service>}
     * @memberof ServiceControllerGet200Response
     */
    'content'?: Array<Service>;
}
/**
 * 
 * @export
 * @interface ServiceControllerGet200ResponseAllOf
 */
export interface ServiceControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Service>}
     * @memberof ServiceControllerGet200ResponseAllOf
     */
    'content'?: Array<Service>;
}
/**
 * 
 * @export
 * @interface ServiceControllerGetServiceItems200Response
 */
export interface ServiceControllerGetServiceItems200Response {
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGetServiceItems200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGetServiceItems200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceControllerGetServiceItems200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof ServiceControllerGetServiceItems200Response
     */
    'content'?: Array<ServiceItems>;
}
/**
 * 
 * @export
 * @interface ServiceControllerGetServiceItems200ResponseAllOf
 */
export interface ServiceControllerGetServiceItems200ResponseAllOf {
    /**
     * 
     * @type {Array<ServiceItems>}
     * @memberof ServiceControllerGetServiceItems200ResponseAllOf
     */
    'content'?: Array<ServiceItems>;
}
/**
 * 
 * @export
 * @interface ServiceItems
 */
export interface ServiceItems {
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'subName': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceItems
     */
    'isMerchandise': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceItems
     */
    'isTaxable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceItems
     */
    'isCheckinStands': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceItems
     */
    'isCheckinOnline': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'supply'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'time': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'turns'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'uncountedTurns'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'technicianCut'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'technicianRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'textColor': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'backgroundColor': string;
    /**
     * 
     * @type {Service}
     * @memberof ServiceItems
     */
    'service': Service;
    /**
     * 
     * @type {Array<Salon>}
     * @memberof ServiceItems
     */
    'salons': Array<Salon>;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ServiceItems
     */
    'employees'?: Array<Employee>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ServiceItems
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceItems
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceItems
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ServiceTaxDTO
 */
export interface ServiceTaxDTO {
    /**
     * 
     * @type {number}
     * @memberof ServiceTaxDTO
     */
    'percentDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTaxDTO
     */
    'moneyDiscount': number;
}
/**
 * 
 * @export
 * @interface ServiceTurn
 */
export interface ServiceTurn {
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'technicianTurnId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTurn
     */
    'isGeneralService': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'serviceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'totalDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'supply': number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof ServiceTurn
     */
    'discount': TicketDisCountDTO;
    /**
     * 
     * @type {ServiceItems}
     * @memberof ServiceTurn
     */
    'service': ServiceItems;
    /**
     * 
     * @type {TechnicianTurn}
     * @memberof ServiceTurn
     */
    'technicianTurn': TechnicianTurn;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceTurn
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceTurn
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurn
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceTurn
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ServiceTurnDTO
 */
export interface ServiceTurnDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTurnDTO
     */
    'isGeneralService': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurnDTO
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurnDTO
     */
    'supply': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurnDTO
     */
    'serviceId'?: number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof ServiceTurnDTO
     */
    'discount'?: TicketDisCountDTO;
    /**
     * 
     * @type {number}
     * @memberof ServiceTurnDTO
     */
    'totalDiscount'?: number;
}
/**
 * 
 * @export
 * @interface SettingAuthorization
 */
export interface SettingAuthorization {
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingAuthorization
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SettingAuthorization
     */
    'roleId': number;
    /**
     * 
     * @type {Role}
     * @memberof SettingAuthorization
     */
    'role': Role;
    /**
     * 
     * @type {number}
     * @memberof SettingAuthorization
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingAuthorization
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingAuthorization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingAuthorization
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingAuthorization
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingAuthorization
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingAuthorization
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingAuthorization
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingBillingAndPayments
 */
export interface SettingBillingAndPayments {
    /**
     * 
     * @type {string}
     * @memberof SettingBillingAndPayments
     */
    'statementDescriptor'?: string;
    /**
     * 
     * @type {number}
     * @memberof SettingBillingAndPayments
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingBillingAndPayments
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingBillingAndPayments
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingBillingAndPayments
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingBillingAndPayments
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingBillingAndPayments
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingBillingAndPayments
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingBillingAndPayments
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingCheckin
 */
export interface SettingCheckin {
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'allowCustomersCheckinFromPhone': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'checkinPrompt': string;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'flashTitle': string;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'flashMessage': string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof SettingCheckin
     */
    'screenSaver': Array<Asset>;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'askForConsent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'allowSelectingServices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'showServicePriceInCheckin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'allowSelectingTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'autoAssignAppointmentTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'asksOptionReceivingTextMessage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'autoPrintCheckinTicketsExternal': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'consentTitle': string;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'consentContent': string;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'consentPrompt': string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckin
     */
    'requireCustomers': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'freeWifi': string;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'freeWifiPassword': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckin
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingCheckin
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckin
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckin
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckin
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckin
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingCheckout
 */
export interface SettingCheckout {
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'inputingServiceSupply': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckout
     */
    'supplyCost': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'saleTax': number;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'cardPaymentTypeButton': boolean;
    /**
     * 
     * @type {SecurityCode}
     * @memberof SettingCheckout
     */
    'securityCode': SecurityCode;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'trackingServingTime': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'managingGiftCards': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'acceptingPartialCardPayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'hideNoneCardItemsInTransactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'autoPrintTicketSummaryCheckoutCompletion': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'printTicketSummaryCustomerRequest': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'printSupplyAmountTicketSummary': boolean;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'feeChargedPerPaymentPercentage': number;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'askForZipcode': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'askForBillingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingCheckout
     */
    'askForEmailAddress': boolean;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingCheckout
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckout
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckout
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCheckout
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCheckout
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingCustomerRate
 */
export interface SettingCustomerRate {
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'oneStar': number;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'twoStar': number;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'threeStar': number;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'fourStar': number;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'fiveStar': number;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingCustomerRate
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCustomerRate
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCustomerRate
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingCustomerRate
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingCustomerRate
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingData
 */
export interface SettingData {
    /**
     * 
     * @type {number}
     * @memberof SettingData
     */
    'retentionPeriod': number;
    /**
     * 
     * @type {number}
     * @memberof SettingData
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingData
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingData
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingData
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingData
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingData
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingData
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingData
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingPrinter
 */
export interface SettingPrinter {
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'cloverPrinter': SettingPrinterCloverPrinterEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingPrinter
     */
    'receiptFor': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'printerIPAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'printerDeviceIP': string;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'charactersPerLine': string;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'lineSpacing': string;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'paperWidth': number;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'printerName': string;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'settingId': number;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'salonSettingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingPrinter
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingPrinter
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingPrinter
     */
    'deletedAt': string;
}

export const SettingPrinterCloverPrinterEnum = {
    CloverMiniOrFlex: 'Clover Mini or Flex',
    PrinterConnectedViaUsb: 'Printer connected via USB'
} as const;

export type SettingPrinterCloverPrinterEnum = typeof SettingPrinterCloverPrinterEnum[keyof typeof SettingPrinterCloverPrinterEnum];

/**
 * 
 * @export
 * @interface SettingTerminalDevice
 */
export interface SettingTerminalDevice {
    /**
     * 
     * @type {boolean}
     * @memberof SettingTerminalDevice
     */
    'terminalConnection': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'cloverApplicationID': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'IdFor': SettingTerminalDeviceIdForEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'cloverDeviceAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'cloverPosName': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'cloverSerialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'cloverAuthToken': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'tipLocation': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'signatureLocation': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'paxDeviceAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'poyntDeviceAddress': string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTerminalDevice
     */
    'notCustomerPrint': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTerminalDevice
     */
    'notCustomerobtain': boolean;
    /**
     * 
     * @type {number}
     * @memberof SettingTerminalDevice
     */
    'settingId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'deviceConnected': SettingTerminalDeviceDeviceConnectedEnum;
    /**
     * 
     * @type {CloverMerchantInformation}
     * @memberof SettingTerminalDevice
     */
    'cloverMerchantInformation': CloverMerchantInformation;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingTerminalDevice
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingTerminalDevice
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTerminalDevice
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTerminalDevice
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTerminalDevice
     */
    'deletedAt': string;
}

export const SettingTerminalDeviceIdForEnum = {
    LocalTest: 'LocalTest',
    Production: 'Production',
    Development: 'Development'
} as const;

export type SettingTerminalDeviceIdForEnum = typeof SettingTerminalDeviceIdForEnum[keyof typeof SettingTerminalDeviceIdForEnum];
export const SettingTerminalDeviceDeviceConnectedEnum = {
    Pax: 'PAX',
    Clover: 'CLOVER'
} as const;

export type SettingTerminalDeviceDeviceConnectedEnum = typeof SettingTerminalDeviceDeviceConnectedEnum[keyof typeof SettingTerminalDeviceDeviceConnectedEnum];

/**
 * 
 * @export
 * @interface SettingTextMessage
 */
export interface SettingTextMessage {
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'customerConfirmationTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'customerReminderTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'customerCancelTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'technicianNotificationTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'technicianReminderTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'customerRewardBalance': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTextMessage
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingTextMessage
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingTextMessage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTextMessage
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTextMessage
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTextMessage
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SettingTip
 */
export interface SettingTip {
    /**
     * 
     * @type {boolean}
     * @memberof SettingTip
     */
    'askEnteringTipWhenCheckout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTip
     */
    'askCustomerForTipForTechnicians': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTip
     */
    'askForTipAgainstFinalPayingAmount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTip
     */
    'allowManuallySplittingTips': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingTip
     */
    'splitTipByWholeDollars': boolean;
    /**
     * 
     * @type {string}
     * @memberof SettingTip
     */
    'splitTipBy': SettingTipSplitTipByEnum;
    /**
     * 
     * @type {number}
     * @memberof SettingTip
     */
    'settingId': number;
    /**
     * 
     * @type {SalonSetting}
     * @memberof SettingTip
     */
    'salonSetting': SalonSetting;
    /**
     * 
     * @type {number}
     * @memberof SettingTip
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTip
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTip
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTip
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof SettingTip
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof SettingTip
     */
    'deletedAt': string;
}

export const SettingTipSplitTipByEnum = {
    Equal: 'Equal',
    AmountOfMoney: 'AmountOfMoney'
} as const;

export type SettingTipSplitTipByEnum = typeof SettingTipSplitTipByEnum[keyof typeof SettingTipSplitTipByEnum];

/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SuggestNextTechniciansByDTO
 */
export interface SuggestNextTechniciansByDTO {
    /**
     * 
     * @type {string}
     * @memberof SuggestNextTechniciansByDTO
     */
    'value': SuggestNextTechniciansByDTOValueEnum;
    /**
     * 
     * @type {string}
     * @memberof SuggestNextTechniciansByDTO
     */
    'order': SuggestNextTechniciansByDTOOrderEnum;
}

export const SuggestNextTechniciansByDTOValueEnum = {
    TurnCount: 'TURN_COUNT'
} as const;

export type SuggestNextTechniciansByDTOValueEnum = typeof SuggestNextTechniciansByDTOValueEnum[keyof typeof SuggestNextTechniciansByDTOValueEnum];
export const SuggestNextTechniciansByDTOOrderEnum = {
    MostFirst: 'MOST_FIRST',
    LeastFirst: 'LEAST_FIRST'
} as const;

export type SuggestNextTechniciansByDTOOrderEnum = typeof SuggestNextTechniciansByDTOOrderEnum[keyof typeof SuggestNextTechniciansByDTOOrderEnum];

/**
 * 
 * @export
 * @interface TechnicianCheckin
 */
export interface TechnicianCheckin {
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckin
     */
    'checkinTime': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckin
     */
    'note'?: string;
    /**
     * 
     * @type {Asset}
     * @memberof TechnicianCheckin
     */
    'checkinImage'?: Asset;
}
/**
 * 
 * @export
 * @interface TechnicianCheckinResponese
 */
export interface TechnicianCheckinResponese {
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'dayCheckin': string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianCheckinResponese
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'end': string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'noteCheckin': string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'noteCheckout': string | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicianCheckinResponese
     */
    'compensation': number;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianCheckinResponese
     */
    'isCheckinLate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianCheckinResponese
     */
    'isCheckoutEarly': boolean;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCheckinResponese
     */
    'totalTimeWork': string;
}
/**
 * 
 * @export
 * @interface TechnicianCompensationResponese
 */
export interface TechnicianCompensationResponese {
    /**
     * 
     * @type {number}
     * @memberof TechnicianCompensationResponese
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCompensationResponese
     */
    'technicianName': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianCompensationResponese
     */
    'avatar': string | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicianCompensationResponese
     */
    'income': number;
    /**
     * 
     * @type {CompensationResponese}
     * @memberof TechnicianCompensationResponese
     */
    'firstHalf': CompensationResponese;
    /**
     * 
     * @type {CompensationResponese}
     * @memberof TechnicianCompensationResponese
     */
    'secondHalf': CompensationResponese;
    /**
     * 
     * @type {CompensationResponese}
     * @memberof TechnicianCompensationResponese
     */
    'balance': CompensationResponese;
    /**
     * 
     * @type {CompensationResponese}
     * @memberof TechnicianCompensationResponese
     */
    'total': CompensationResponese;
    /**
     * 
     * @type {Array<CompensationResponese>}
     * @memberof TechnicianCompensationResponese
     */
    'compensations': Array<CompensationResponese>;
    /**
     * 
     * @type {Array<PaymentResponese>}
     * @memberof TechnicianCompensationResponese
     */
    'pays': Array<PaymentResponese>;
    /**
     * 
     * @type {PaymentResponese}
     * @memberof TechnicianCompensationResponese
     */
    'totalPay': PaymentResponese;
    /**
     * 
     * @type {number}
     * @memberof TechnicianCompensationResponese
     */
    'checkRate': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianCompensationResponese
     */
    'cashAndOtherRate': number;
}
/**
 * 
 * @export
 * @interface TechnicianIdsDTO
 */
export interface TechnicianIdsDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof TechnicianIdsDTO
     */
    'technicianIds': Array<number>;
}
/**
 * 
 * @export
 * @interface TechnicianItemPayDTO
 */
export interface TechnicianItemPayDTO {
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianItemPayDTO
     */
    'datePayment': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianItemPayDTO
     */
    'typePayment': TechnicianItemPayDTOTypePaymentEnum;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'earningCheckPercent': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'earningOrtherPercent': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'earning': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'tip': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'tipCheckPercent': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'tipOrtherPercent': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'totalOrther': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianItemPayDTO
     */
    'totalCheck': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianItemPayDTO
     */
    'notes': string;
}

export const TechnicianItemPayDTOTypePaymentEnum = {
    Custom: 'custom',
    OneDay: 'oneDay',
    Week: 'week',
    SemiMonth: 'semiMonth',
    Month: 'month'
} as const;

export type TechnicianItemPayDTOTypePaymentEnum = typeof TechnicianItemPayDTOTypePaymentEnum[keyof typeof TechnicianItemPayDTOTypePaymentEnum];

/**
 * 
 * @export
 * @interface TechnicianReceiptResponse
 */
export interface TechnicianReceiptResponse {
    /**
     * 
     * @type {Employee}
     * @memberof TechnicianReceiptResponse
     */
    'technician': Employee;
    /**
     * 
     * @type {Array<ServiceTurn>}
     * @memberof TechnicianReceiptResponse
     */
    'services': Array<ServiceTurn>;
    /**
     * 
     * @type {number}
     * @memberof TechnicianReceiptResponse
     */
    'subTotal': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianReceiptResponse
     */
    'commission': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianReceiptResponse
     */
    'tip': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianReceiptResponse
     */
    'payment': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianReceiptResponse
     */
    'techEarning': number;
}
/**
 * 
 * @export
 * @interface TechnicianSummary
 */
export interface TechnicianSummary {
    /**
     * 
     * @type {Employee}
     * @memberof TechnicianSummary
     */
    'technician': Employee;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'totalServices': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'totalSupply': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'totalTechPromo': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'totalCommission': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'totalTip': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSummary
     */
    'grossEarning': number;
    /**
     * 
     * @type {Array<TicketCompensation>}
     * @memberof TechnicianSummary
     */
    'ticketCompensation': Array<TicketCompensation>;
}
/**
 * 
 * @export
 * @interface TechnicianTurn
 */
export interface TechnicianTurn {
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'tips': number;
    /**
     * 
     * @type {Employee}
     * @memberof TechnicianTurn
     */
    'technician': Employee;
    /**
     * 
     * @type {Ticket}
     * @memberof TechnicianTurn
     */
    'ticket': Ticket;
    /**
     * 
     * @type {Array<ServiceTurn>}
     * @memberof TechnicianTurn
     */
    'serviceTurns': Array<ServiceTurn>;
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianTurn
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianTurn
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurn
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianTurn
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TechnicianTurnDTO
 */
export interface TechnicianTurnDTO {
    /**
     * 
     * @type {number}
     * @memberof TechnicianTurnDTO
     */
    'technicianId': number;
    /**
     * 
     * @type {Array<ServiceTurnDTO>}
     * @memberof TechnicianTurnDTO
     */
    'serviceTurns': Array<ServiceTurnDTO>;
}
/**
 * 
 * @export
 * @interface TechniciansCheckinResponese
 */
export interface TechniciansCheckinResponese {
    /**
     * 
     * @type {number}
     * @memberof TechniciansCheckinResponese
     */
    'technicianId': number;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'technicianName': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'avatar': string | null;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'firstHalf': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'firstHalfAtwork': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'secondHalfAtwork': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'secondHalf': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'totalTime': string;
    /**
     * 
     * @type {string}
     * @memberof TechniciansCheckinResponese
     */
    'totalTimeAtwork': string;
    /**
     * 
     * @type {Array<TechnicianCheckinResponese>}
     * @memberof TechniciansCheckinResponese
     */
    'checkinHistory': Array<TechnicianCheckinResponese>;
    /**
     * 
     * @type {number}
     * @memberof TechniciansCheckinResponese
     */
    'compensationTotalTime': number;
    /**
     * 
     * @type {number}
     * @memberof TechniciansCheckinResponese
     */
    'compensationFirstHalf': number;
    /**
     * 
     * @type {number}
     * @memberof TechniciansCheckinResponese
     */
    'compensationSecondHalf': number;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'loginName': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'status': TenantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'stripeAccountId'?: string;
    /**
     * 
     * @type {User}
     * @memberof Tenant
     */
    'user'?: User;
    /**
     * 
     * @type {TenantPackage}
     * @memberof Tenant
     */
    'package': TenantPackage;
    /**
     * 
     * @type {TenantModule}
     * @memberof Tenant
     */
    'modules': TenantModule;
    /**
     * 
     * @type {TenantPayment}
     * @memberof Tenant
     */
    'payment': TenantPayment;
    /**
     * 
     * @type {Array<Service>}
     * @memberof Tenant
     */
    'services': Array<Service>;
    /**
     * 
     * @type {Array<Salon>}
     * @memberof Tenant
     */
    'salons': Array<Salon>;
    /**
     * 
     * @type {Array<TenantRequestPayment>}
     * @memberof Tenant
     */
    'requestPayment': Array<TenantRequestPayment>;
    /**
     * 
     * @type {CustomerStripeAccount}
     * @memberof Tenant
     */
    'customerStripeAccount': CustomerStripeAccount;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'deletedAt': string;
}

export const TenantStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive'
} as const;

export type TenantStatusEnum = typeof TenantStatusEnum[keyof typeof TenantStatusEnum];

/**
 * 
 * @export
 * @interface TenantControllerGetPaymentListForAdmin200Response
 */
export interface TenantControllerGetPaymentListForAdmin200Response {
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetPaymentListForAdmin200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetPaymentListForAdmin200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetPaymentListForAdmin200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<TenantRequestPayment>}
     * @memberof TenantControllerGetPaymentListForAdmin200Response
     */
    'content'?: Array<TenantRequestPayment>;
}
/**
 * 
 * @export
 * @interface TenantControllerGetPaymentListForAdmin200ResponseAllOf
 */
export interface TenantControllerGetPaymentListForAdmin200ResponseAllOf {
    /**
     * 
     * @type {Array<TenantRequestPayment>}
     * @memberof TenantControllerGetPaymentListForAdmin200ResponseAllOf
     */
    'content'?: Array<TenantRequestPayment>;
}
/**
 * 
 * @export
 * @interface TenantControllerGetTenantList200Response
 */
export interface TenantControllerGetTenantList200Response {
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetTenantList200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetTenantList200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof TenantControllerGetTenantList200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof TenantControllerGetTenantList200Response
     */
    'content'?: Array<Tenant>;
}
/**
 * 
 * @export
 * @interface TenantControllerGetTenantList200ResponseAllOf
 */
export interface TenantControllerGetTenantList200ResponseAllOf {
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof TenantControllerGetTenantList200ResponseAllOf
     */
    'content'?: Array<Tenant>;
}
/**
 * 
 * @export
 * @interface TenantDetail
 */
export interface TenantDetail {
    /**
     * 
     * @type {Tenant}
     * @memberof TenantDetail
     */
    'general': Tenant;
    /**
     * 
     * @type {TenantDetailPayment}
     * @memberof TenantDetail
     */
    'payment': TenantDetailPayment;
}
/**
 * 
 * @export
 * @interface TenantDetailPayment
 */
export interface TenantDetailPayment {
    /**
     * 
     * @type {string}
     * @memberof TenantDetailPayment
     */
    'paymentFreqency'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'totalPayment'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailPayment
     */
    'nextPayment'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDetailPayment
     */
    'module'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'customerCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'transactionCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'SMSAndEmailCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'customerLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'transactionLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDetailPayment
     */
    'SMSAndEmailLimit'?: number;
}
/**
 * 
 * @export
 * @interface TenantModule
 */
export interface TenantModule {
    /**
     * 
     * @type {number}
     * @memberof TenantModule
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantModule
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantModule
     */
    'modules': Array<string>;
    /**
     * 
     * @type {Tenant}
     * @memberof TenantModule
     */
    'tenant': Tenant;
    /**
     * 
     * @type {number}
     * @memberof TenantModule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantModule
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantModule
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantModule
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantModule
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantModule
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TenantModuleDTO
 */
export interface TenantModuleDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantModuleDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<Module>}
     * @memberof TenantModuleDTO
     */
    'modules': Array<Module>;
}
/**
 * 
 * @export
 * @interface TenantPackage
 */
export interface TenantPackage {
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'customerLimit': number;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'transactionLimit': number;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'SMSAndEmailLimit': number;
    /**
     * 
     * @type {Tenant}
     * @memberof TenantPackage
     */
    'tenant': Tenant;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPackage
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPackage
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantPackage
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPackage
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TenantPayment
 */
export interface TenantPayment {
    /**
     * 
     * @type {number}
     * @memberof TenantPayment
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPayment
     */
    'paymentType': TenantPaymentPaymentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TenantPayment
     */
    'totalPayment': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPayment
     */
    'paymentDate': string;
    /**
     * 
     * @type {Tenant}
     * @memberof TenantPayment
     */
    'tenant': Tenant;
    /**
     * 
     * @type {number}
     * @memberof TenantPayment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPayment
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantPayment
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPayment
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantPayment
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantPayment
     */
    'deletedAt': string;
}

export const TenantPaymentPaymentTypeEnum = {
    Monthly: 'Monthly',
    Annually: 'Annually'
} as const;

export type TenantPaymentPaymentTypeEnum = typeof TenantPaymentPaymentTypeEnum[keyof typeof TenantPaymentPaymentTypeEnum];

/**
 * 
 * @export
 * @interface TenantRequestPayment
 */
export interface TenantRequestPayment {
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'tenantName': string;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'customerCount': number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'customerLimit': number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'transactionCount': number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'transactionLimit': number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'SMSAndEmailCount': number;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'SMSAndEmailLimit': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'moduleName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'status': TenantRequestPaymentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'totalPayment': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'nextPayment': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'startServiceCycle': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'endServiceCycle': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'paymentFrequency': TenantRequestPaymentPaymentFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'paidAt': string;
    /**
     * 
     * @type {Tenant}
     * @memberof TenantRequestPayment
     */
    'tenant': Tenant;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TenantRequestPayment
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestPayment
     */
    'deletedAt': string;
}

export const TenantRequestPaymentStatusEnum = {
    Unpaid: 'unpaid',
    Paid: 'paid'
} as const;

export type TenantRequestPaymentStatusEnum = typeof TenantRequestPaymentStatusEnum[keyof typeof TenantRequestPaymentStatusEnum];
export const TenantRequestPaymentPaymentFrequencyEnum = {
    Monthly: 'Monthly',
    Annually: 'Annually'
} as const;

export type TenantRequestPaymentPaymentFrequencyEnum = typeof TenantRequestPaymentPaymentFrequencyEnum[keyof typeof TenantRequestPaymentPaymentFrequencyEnum];

/**
 * 
 * @export
 * @interface Tender
 */
export interface Tender {
    /**
     * 
     * @type {string}
     * @memberof Tender
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Tender
     */
    'editable': boolean;
    /**
     * 
     * @type {string}
     * @memberof Tender
     */
    'labelKey': string;
    /**
     * 
     * @type {string}
     * @memberof Tender
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof Tender
     */
    'opensCashDrawer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tender
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tender
     */
    'visible': boolean;
    /**
     * 
     * @type {object}
     * @memberof Tender
     */
    'instructions': object;
}
/**
 * 
 * @export
 * @interface TestDto
 */
export interface TestDto {
    /**
     * 
     * @type {string}
     * @memberof TestDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'closeoutId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'salonId': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'timeCompleted': string;
    /**
     * 
     * @type {ServiceTaxDTO}
     * @memberof Ticket
     */
    'serviceTax': ServiceTaxDTO;
    /**
     * 
     * @type {ServiceChargeDTO}
     * @memberof Ticket
     */
    'serviceCharge': ServiceChargeDTO;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'totalDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'totalPayment': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'totalTip': number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof Ticket
     */
    'discount': TicketDisCountDTO;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'deposit': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'balanceToPay': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Ticket
     */
    'technicianResponsibility': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'rewarding': number;
    /**
     * 
     * @type {Customer}
     * @memberof Ticket
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<TransactionRefund>}
     * @memberof Ticket
     */
    'transactionRefunds'?: Array<TransactionRefund>;
    /**
     * 
     * @type {CloseOut}
     * @memberof Ticket
     */
    'closeout'?: CloseOut;
    /**
     * 
     * @type {Array<TechnicianTurn>}
     * @memberof Ticket
     */
    'technicianTurns': Array<TechnicianTurn>;
    /**
     * 
     * @type {Array<TicketTransaction>}
     * @memberof Ticket
     */
    'ticketTransaction': Array<TicketTransaction>;
    /**
     * 
     * @type {Array<TicketMerchandise>}
     * @memberof Ticket
     */
    'ticketMerchandise': Array<TicketMerchandise>;
    /**
     * 
     * @type {Array<TicketGiftCard>}
     * @memberof Ticket
     */
    'ticketGiftCard': Array<TicketGiftCard>;
    /**
     * 
     * @type {Array<GiftCardHistory>}
     * @memberof Ticket
     */
    'giftCardHistory': Array<GiftCardHistory>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof Ticket
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'deletedAt': string;
}

export const TicketStatusEnum = {
    Completed: 'Completed',
    Hold: 'Hold'
} as const;

export type TicketStatusEnum = typeof TicketStatusEnum[keyof typeof TicketStatusEnum];

/**
 * 
 * @export
 * @interface TicketCompensation
 */
export interface TicketCompensation {
    /**
     * 
     * @type {number}
     * @memberof TicketCompensation
     */
    'transactionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof TicketCompensation
     */
    'transactionTime': string;
    /**
     * 
     * @type {number}
     * @memberof TicketCompensation
     */
    'techPromo': number;
    /**
     * 
     * @type {number}
     * @memberof TicketCompensation
     */
    'commission': number;
    /**
     * 
     * @type {number}
     * @memberof TicketCompensation
     */
    'tip': number;
    /**
     * 
     * @type {Array<ServiceTurn>}
     * @memberof TicketCompensation
     */
    'serviceTurns': Array<ServiceTurn>;
}
/**
 * 
 * @export
 * @interface TicketControllerGetTicketPending200Response
 */
export interface TicketControllerGetTicketPending200Response {
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTicketPending200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTicketPending200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTicketPending200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof TicketControllerGetTicketPending200Response
     */
    'content'?: Array<Ticket>;
}
/**
 * 
 * @export
 * @interface TicketControllerGetTicketPending200ResponseAllOf
 */
export interface TicketControllerGetTicketPending200ResponseAllOf {
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof TicketControllerGetTicketPending200ResponseAllOf
     */
    'content'?: Array<Ticket>;
}
/**
 * 
 * @export
 * @interface TicketControllerGetTransaction200Response
 */
export interface TicketControllerGetTransaction200Response {
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTransaction200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTransaction200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof TicketControllerGetTransaction200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<TicketTransaction>}
     * @memberof TicketControllerGetTransaction200Response
     */
    'content'?: Array<TicketTransaction>;
}
/**
 * 
 * @export
 * @interface TicketControllerGetTransaction200ResponseAllOf
 */
export interface TicketControllerGetTransaction200ResponseAllOf {
    /**
     * 
     * @type {Array<TicketTransaction>}
     * @memberof TicketControllerGetTransaction200ResponseAllOf
     */
    'content'?: Array<TicketTransaction>;
}
/**
 * 
 * @export
 * @interface TicketDTO
 */
export interface TicketDTO {
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TicketDTO
     */
    'combineId'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TicketDTO
     */
    'appointmentId'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'customerId'?: number;
    /**
     * 
     * @type {Array<GiftCardDTO>}
     * @memberof TicketDTO
     */
    'giftCards'?: Array<GiftCardDTO>;
    /**
     * 
     * @type {ServiceTaxDTO}
     * @memberof TicketDTO
     */
    'serviceTax'?: ServiceTaxDTO;
    /**
     * 
     * @type {ServiceChargeDTO}
     * @memberof TicketDTO
     */
    'serviceCharge'?: ServiceChargeDTO;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'totalDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'totalPayment': number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof TicketDTO
     */
    'discount': TicketDisCountDTO;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'deposit': number;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'balanceToPay': number;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    'status': TicketDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'totalTip'?: number;
    /**
     * 
     * @type {Array<TechnicianTurnDTO>}
     * @memberof TicketDTO
     */
    'technicianTurns': Array<TechnicianTurnDTO>;
    /**
     * 
     * @type {Array<TicketTransactionDTO>}
     * @memberof TicketDTO
     */
    'ticketTransaction'?: Array<TicketTransactionDTO>;
    /**
     * 
     * @type {Array<TicketMerchandiseDTO>}
     * @memberof TicketDTO
     */
    'ticketMerchandise'?: Array<TicketMerchandiseDTO>;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    'rewarding'?: number;
}

export const TicketDTOStatusEnum = {
    Completed: 'Completed',
    Hold: 'Hold'
} as const;

export type TicketDTOStatusEnum = typeof TicketDTOStatusEnum[keyof typeof TicketDTOStatusEnum];

/**
 * 
 * @export
 * @interface TicketDisCountDTO
 */
export interface TicketDisCountDTO {
    /**
     * 
     * @type {ReferralDiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'referralDiscount'?: ReferralDiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'birthdayDiscount'?: DiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'weeklyDiscount'?: DiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'occasionDiscount'?: DiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'generalTicketDiscount'?: DiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'generalItemDiscount'?: DiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'loyaltyReward'?: DiscountDTO;
    /**
     * 
     * @type {RewardBalanceDiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'rewardBalance'?: RewardBalanceDiscountDTO;
    /**
     * 
     * @type {DiscountDTO}
     * @memberof TicketDisCountDTO
     */
    'technicianDiscount'?: DiscountDTO;
}
/**
 * 
 * @export
 * @interface TicketGiftCard
 */
export interface TicketGiftCard {
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'giftCardValue': number;
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'giftCardCode': number;
    /**
     * 
     * @type {Ticket}
     * @memberof TicketGiftCard
     */
    'ticket': Ticket;
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TicketGiftCard
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketGiftCard
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketGiftCard
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketGiftCard
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TicketMerchandise
 */
export interface TicketMerchandise {
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'merchandiseId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'merchandisePrice': number;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'totalDiscount': number;
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof TicketMerchandise
     */
    'discount': TicketDisCountDTO;
    /**
     * 
     * @type {ServiceItems}
     * @memberof TicketMerchandise
     */
    'merchandise': ServiceItems;
    /**
     * 
     * @type {Ticket}
     * @memberof TicketMerchandise
     */
    'ticket': Ticket;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TicketMerchandise
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketMerchandise
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandise
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketMerchandise
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TicketMerchandiseDTO
 */
export interface TicketMerchandiseDTO {
    /**
     * 
     * @type {TicketDisCountDTO}
     * @memberof TicketMerchandiseDTO
     */
    'discount'?: TicketDisCountDTO;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandiseDTO
     */
    'totalDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandiseDTO
     */
    'merchandisePrice': number;
    /**
     * 
     * @type {number}
     * @memberof TicketMerchandiseDTO
     */
    'merchandiseId'?: number;
}
/**
 * 
 * @export
 * @interface TicketTransaction
 */
export interface TicketTransaction {
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'salonId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'giftCardNumber': number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'status': TicketTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'typePayment': TicketTransactionTypePaymentEnum;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'money': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'tipMoney'?: number;
    /**
     * 
     * @type {Customer}
     * @memberof TicketTransaction
     */
    'technician': Customer;
    /**
     * 
     * @type {PaymentInformation}
     * @memberof TicketTransaction
     */
    'paymentInformation': PaymentInformation;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'paymentFee': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'customerTransactionId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'changeAmount'?: number;
    /**
     * 
     * @type {CustomerTransaction}
     * @memberof TicketTransaction
     */
    'customerTransaction': CustomerTransaction;
    /**
     * 
     * @type {Ticket}
     * @memberof TicketTransaction
     */
    'ticket': Ticket;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TicketTransaction
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransaction
     */
    'deletedAt': string;
}

export const TicketTransactionStatusEnum = {
    CustomerHandToYou: 'Customer_hand_to_you',
    YouHandToCustomer: 'You_hand_to_customer'
} as const;

export type TicketTransactionStatusEnum = typeof TicketTransactionStatusEnum[keyof typeof TicketTransactionStatusEnum];
export const TicketTransactionTypePaymentEnum = {
    ChargeCard: 'Charge_card',
    MultipleChargeCard: 'Multiple_charge_card',
    CardNumber: 'Card_number',
    GiftCard: 'Gift_card',
    Check: 'Check',
    Cash: 'Cash',
    TipCash: 'Tip_cash',
    Tip: 'Tip',
    Orther: 'Orther'
} as const;

export type TicketTransactionTypePaymentEnum = typeof TicketTransactionTypePaymentEnum[keyof typeof TicketTransactionTypePaymentEnum];

/**
 * 
 * @export
 * @interface TicketTransactionDTO
 */
export interface TicketTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof TicketTransactionDTO
     */
    'typePayment': TicketTransactionDTOTypePaymentEnum;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'money': number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransactionDTO
     */
    'status': TicketTransactionDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'tipMoney'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'giftCardNumber'?: number;
    /**
     * 
     * @type {PaymentInformation}
     * @memberof TicketTransactionDTO
     */
    'paymentInformation'?: PaymentInformation;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'customerTransactionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'paymentFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketTransactionDTO
     */
    'changeAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketTransactionDTO
     */
    'notes'?: string;
}

export const TicketTransactionDTOTypePaymentEnum = {
    ChargeCard: 'Charge_card',
    MultipleChargeCard: 'Multiple_charge_card',
    CardNumber: 'Card_number',
    GiftCard: 'Gift_card',
    Check: 'Check',
    Cash: 'Cash',
    TipCash: 'Tip_cash',
    Tip: 'Tip',
    Orther: 'Orther'
} as const;

export type TicketTransactionDTOTypePaymentEnum = typeof TicketTransactionDTOTypePaymentEnum[keyof typeof TicketTransactionDTOTypePaymentEnum];
export const TicketTransactionDTOStatusEnum = {
    CustomerHandToYou: 'Customer_hand_to_you',
    YouHandToCustomer: 'You_hand_to_customer'
} as const;

export type TicketTransactionDTOStatusEnum = typeof TicketTransactionDTOStatusEnum[keyof typeof TicketTransactionDTOStatusEnum];

/**
 * 
 * @export
 * @interface TipForTechnicianDTO
 */
export interface TipForTechnicianDTO {
    /**
     * 
     * @type {number}
     * @memberof TipForTechnicianDTO
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof TipForTechnicianDTO
     */
    'tips': number;
    /**
     * 
     * @type {number}
     * @memberof TipForTechnicianDTO
     */
    'ticketId': number;
}
/**
 * 
 * @export
 * @interface TipForTechniciansDTO
 */
export interface TipForTechniciansDTO {
    /**
     * 
     * @type {Array<TipForTechnicianDTO>}
     * @memberof TipForTechniciansDTO
     */
    'technicianTurns': Array<TipForTechnicianDTO>;
}
/**
 * 
 * @export
 * @interface TransactionInfo
 */
export interface TransactionInfo {
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'accountSelection'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'applicationPanSequenceNumber'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'batchNumber'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'cardSymbol'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'cardTypeLabel'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'clientCardType'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'eCommTransactionInfo'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionInfo
     */
    'emergencyFlag': boolean;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'entryType'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'fiscalInvoiceNumber'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'identityDocument'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'installmentsPlanCode'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'installmentsPlanDesc'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'installmentsPlanId'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'installmentsQuantity'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionInfo
     */
    'isTokenBasedTx': boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionInfo
     */
    'languageIndicator': string;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'maskedTrack2'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'merchantIdentifier'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'merchantNameLocation'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'origTransactionSequenceCounter'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'panMask'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'promotionalMessage'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'receiptExtraData'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'receiptNumber'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'reversalMac'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'reversalMacKsn'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'reversalReason'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'reversalStan'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'reversalStanRefNum'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'selectedService'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'stan'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'terminalIdentification'?: object;
    /**
     * 
     * @type {string}
     * @memberof TransactionInfo
     */
    'transactionLocale': string;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'transactionResult'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'transactionSequenceCounter'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'transactionSequenceCounterUpdate'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'transactionTags'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionInfo
     */
    'txFormat'?: object;
}
/**
 * 
 * @export
 * @interface TransactionRefund
 */
export interface TransactionRefund {
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'salonId': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionRefund
     */
    'typePayment': TransactionRefundTypePaymentEnum;
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'money': number;
    /**
     * 
     * @type {Ticket}
     * @memberof TransactionRefund
     */
    'ticket': Ticket;
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionRefund
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionRefund
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionRefund
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionRefund
     */
    'deletedAt': string;
}

export const TransactionRefundTypePaymentEnum = {
    Check: 'Check',
    Cash: 'Cash',
    OriginalCard: 'OriginalCard'
} as const;

export type TransactionRefundTypePaymentEnum = typeof TransactionRefundTypePaymentEnum[keyof typeof TransactionRefundTypePaymentEnum];

/**
 * 
 * @export
 * @interface TransactionSettings
 */
export interface TransactionSettings {
    /**
     * 
     * @type {number}
     * @memberof TransactionSettings
     */
    'cardEntryMethods': number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'disableCashBack': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'cloverShouldHandleReceipts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'forcePinEntryOnSwipe': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'disableRestartTransactionOnFailure': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'allowOfflinePayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'approveOfflinePaymentWithoutPrompt': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'forceOfflinePayment': boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionSettings
     */
    'signatureThreshold': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSettings
     */
    'signatureEntryLocation': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSettings
     */
    'tipMode': string;
    /**
     * 
     * @type {object}
     * @memberof TransactionSettings
     */
    'tippableAmount': object;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'disableReceiptSelection': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'disableDuplicateCheck': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'autoAcceptPaymentConfirmations': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'autoAcceptSignature': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSettings
     */
    'returnResultOnTransactionComplete': boolean;
}
/**
 * 
 * @export
 * @interface TurnSetting
 */
export interface TurnSetting {
    /**
     * 
     * @type {string}
     * @memberof TurnSetting
     */
    'suggesttingMode': TurnSettingSuggesttingModeEnum;
    /**
     * 
     * @type {number}
     * @memberof TurnSetting
     */
    'turnSize': number;
    /**
     * 
     * @type {boolean}
     * @memberof TurnSetting
     */
    'assessFillingTurnEarly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnSetting
     */
    'suggestOnlyAvailiableTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnSetting
     */
    'enableTurnManagement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnSetting
     */
    'showTurnInfoCounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnSetting
     */
    'showMoneyAmounts': boolean;
    /**
     * 
     * @type {number}
     * @memberof TurnSetting
     */
    'salonId': number;
    /**
     * 
     * @type {Salon}
     * @memberof TurnSetting
     */
    'salon': Salon;
    /**
     * 
     * @type {number}
     * @memberof TurnSetting
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TurnSetting
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TurnSetting
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TurnSetting
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof TurnSetting
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof TurnSetting
     */
    'deletedAt': string;
}

export const TurnSettingSuggesttingModeEnum = {
    SimpleTurn: 'simpleTurn',
    TraditionalTurn: 'traditionalTurn',
    EnhancedTurn: 'enhancedTurn',
    AverageTurn: 'averageTurn',
    ServiceTurn: 'serviceTurn',
    TimeTurn: 'timeTurn'
} as const;

export type TurnSettingSuggesttingModeEnum = typeof TurnSettingSuggesttingModeEnum[keyof typeof TurnSettingSuggesttingModeEnum];

/**
 * 
 * @export
 * @interface TurnsSettingDTO
 */
export interface TurnsSettingDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TurnsSettingDTO
     */
    'servedTurns': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnsSettingDTO
     */
    'stackTurns': boolean;
}
/**
 * 
 * @export
 * @interface UpdateAnnouncementDto
 */
export interface UpdateAnnouncementDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'announcementFor'?: UpdateAnnouncementDtoAnnouncementForEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnouncementDto
     */
    'isSms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'smsContent'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnouncementDto
     */
    'isEmail'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'emailContent'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'customerVisit'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnouncementDto
     */
    'moreFilter'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'accountCreated'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'ticketAmount'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'moneySpent'?: object;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAnnouncementDto
     */
    'technicianIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'expireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'saveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'stopDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'announceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnouncementDto
     */
    'option'?: UpdateAnnouncementDtoOptionEnum;
    /**
     * 
     * @type {object}
     * @memberof UpdateAnnouncementDto
     */
    'timeSend'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnouncementDto
     */
    'sent'?: boolean;
}

export const UpdateAnnouncementDtoAnnouncementForEnum = {
    BirthdayPromotion: 'Birthday Promotion',
    CheckinPromotion: 'Checkin Promotion',
    LoyaltyPromotion: 'Loyalty Promotion',
    OccationPromotion: 'Occation Promotion',
    ReferralPromotion: 'Referral Promotion',
    WeeklyPromotion: 'Weekly Promotion'
} as const;

export type UpdateAnnouncementDtoAnnouncementForEnum = typeof UpdateAnnouncementDtoAnnouncementForEnum[keyof typeof UpdateAnnouncementDtoAnnouncementForEnum];
export const UpdateAnnouncementDtoOptionEnum = {
    SendNow: 'sendNow',
    SendLater: 'sendLater',
    SendUntilEnd: 'sendUntilEnd'
} as const;

export type UpdateAnnouncementDtoOptionEnum = typeof UpdateAnnouncementDtoOptionEnum[keyof typeof UpdateAnnouncementDtoOptionEnum];

/**
 * 
 * @export
 * @interface UpdateAppointmentDTO
 */
export interface UpdateAppointmentDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDTO
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDTO
     */
    'timeStart'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDTO
     */
    'estimate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDTO
     */
    'useAnyTechnician'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDTO
     */
    'useMessageReminder'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDTO
     */
    'status'?: UpdateAppointmentDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDTO
     */
    'totalMoney'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDTO
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDTO
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAppointmentDTO
     */
    'technicianId'?: number;
    /**
     * 
     * @type {CustomerDto}
     * @memberof UpdateAppointmentDTO
     */
    'customer'?: CustomerDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAppointmentDTO
     */
    'serviceIds'?: Array<number>;
    /**
     * 
     * @type {Array<DepositTotalDTO>}
     * @memberof UpdateAppointmentDTO
     */
    'depositTotal'?: Array<DepositTotalDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointmentDTO
     */
    'isOnlineAppointment'?: boolean;
}

export const UpdateAppointmentDTOStatusEnum = {
    WaitingConfirm: 'Waiting_Confirm',
    Confirmed: 'Confirmed',
    CheckedIn: 'Checked_in',
    BeingServed: 'Being_served',
    Completed: 'Completed',
    Canceled: 'Canceled'
} as const;

export type UpdateAppointmentDTOStatusEnum = typeof UpdateAppointmentDTOStatusEnum[keyof typeof UpdateAppointmentDTOStatusEnum];

/**
 * 
 * @export
 * @interface UpdateAuthorizationManagementDto
 */
export interface UpdateAuthorizationManagementDto {
    /**
     * 
     * @type {Array<AuthorizationManagementDto>}
     * @memberof UpdateAuthorizationManagementDto
     */
    'settingAuthorization': Array<AuthorizationManagementDto>;
}
/**
 * 
 * @export
 * @interface UpdateBillingAndPaymentSettingDto
 */
export interface UpdateBillingAndPaymentSettingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingAndPaymentSettingDto
     */
    'statementDescriptor'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateBillingInformationDto
 */
export interface UpdateBillingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingInformationDto
     */
    'email'?: string;
    /**
     * 
     * @type {BillingInformationCompany}
     * @memberof UpdateBillingInformationDto
     */
    'company'?: BillingInformationCompany;
}
/**
 * 
 * @export
 * @interface UpdateBirthdayRewardDto
 */
export interface UpdateBirthdayRewardDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBirthdayRewardDto
     */
    'birthdayReward'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateBirthdayRewardDto
     */
    'technicianResponsibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBirthdayRewardDto
     */
    'discountAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBirthdayRewardDto
     */
    'discountPrecent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBirthdayRewardDto
     */
    'eligiblePeriod'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateBirthdayRewardDto
     */
    'discountServices'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBirthdayRewardDto
     */
    'allService'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateBusinessSettingDto
 */
export interface UpdateBusinessSettingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateBusinessSettingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessSettingDto
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessSettingDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessSettingDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessSettingDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessSettingDto
     */
    'hideCustomerPhoneNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessSettingDto
     */
    'hideCustomerIncheckinAndCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessSettingDto
     */
    'hideCustomerIncheckinTechnician'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessSettingDto
     */
    'showDepositProcess'?: boolean;
    /**
     * 
     * @type {Array<ListSalon>}
     * @memberof UpdateBusinessSettingDto
     */
    'listSalon'?: Array<ListSalon>;
}
/**
 * 
 * @export
 * @interface UpdateCheckinDTO
 */
export interface UpdateCheckinDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinDTO
     */
    'checkinTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinDTO
     */
    'checkoutTime': string;
}
/**
 * 
 * @export
 * @interface UpdateCheckinRewardDto
 */
export interface UpdateCheckinRewardDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinRewardDto
     */
    'checkinReward'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'reward'?: number | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateCheckinRewardDto
     */
    'redeemOptions'?: Array<object> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'expiration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'checkinPerDay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'checkinPerWeek'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'checkinPerMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckinRewardDto
     */
    'checkinWholeLife'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateCheckinSettingDto
 */
export interface UpdateCheckinSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'allowCustomersCheckinFromPhone': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'checkinPrompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'flashTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'flashMessage'?: string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof UpdateCheckinSettingDto
     */
    'screenSaver'?: Array<Asset>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'askForConsent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'allowSelectingServices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'showServicePriceInCheckin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'allowSelectingTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'autoAssignAppointmentTechnician': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'asksOptionReceivingTextMessage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'autoPrintCheckinTicketsExternal': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'freeWifi'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'freeWifiPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'consentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'consentContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckinSettingDto
     */
    'consentPrompt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckinSettingDto
     */
    'requireCustomers': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCheckoutSettingDto
 */
export interface UpdateCheckoutSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'inputingServiceSupply': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutSettingDto
     */
    'supplyCost'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckoutSettingDto
     */
    'saleTax': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'cardPaymentTypeButton': boolean;
    /**
     * 
     * @type {UpdateSecurityCode}
     * @memberof UpdateCheckoutSettingDto
     */
    'securityCode': UpdateSecurityCode;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'trackingServingTime': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'managingGiftCards': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'acceptingPartialCardPayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'hideNoneCardItemsInTransactions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'autoPrintTicketSummaryCheckoutCompletion': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'printTicketSummaryCustomerRequest': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'printSupplyAmountTicketSummary': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckoutSettingDto
     */
    'feeChargedPerPaymentPercentage': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'askForZipcode': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'askForBillingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCheckoutSettingDto
     */
    'askForEmailAddress': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'birthDay'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerDto
     */
    'receiveMessagesByPhone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerDto
     */
    'receivePromotionViaEmail': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerRateSettingDto
 */
export interface UpdateCustomerRateSettingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRateSettingDto
     */
    'oneStar': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRateSettingDto
     */
    'twoStar': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRateSettingDto
     */
    'threeStar': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRateSettingDto
     */
    'fourStar': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerRateSettingDto
     */
    'fiveStar': number | null;
}
/**
 * 
 * @export
 * @interface UpdateDataSettingDto
 */
export interface UpdateDataSettingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDataSettingDto
     */
    'retentionPeriod'?: number;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeDTO
 */
export interface UpdateEmployeeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'loginName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeDTO
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'store'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeDTO
     */
    'loginWithPhoneNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeDTO
     */
    'loginWithEmailAddress'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateEmployeeDTO
     */
    'roles'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateEmployeeDTO
     */
    'salons'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateEmployeeDTO
     */
    'skills'?: Array<number>;
    /**
     * 
     * @type {CreateEmployeeProfileSetting}
     * @memberof UpdateEmployeeDTO
     */
    'employeeProfileSetting'?: CreateEmployeeProfileSetting;
    /**
     * 
     * @type {CreateEmployeeWorkingHours}
     * @memberof UpdateEmployeeDTO
     */
    'employeeWorkingHours'?: CreateEmployeeWorkingHours;
    /**
     * 
     * @type {Asset}
     * @memberof UpdateEmployeeDTO
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'defaultAvatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'legalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDTO
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeProfileDTO
 */
export interface UpdateEmployeeProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {Asset}
     * @memberof UpdateEmployeeProfileDTO
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeProfileDTO
     */
    'defaultAvatar'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExpenseDto
 */
export interface UpdateExpenseDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateExpenseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpenseDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateExpenseDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpenseDto
     */
    'category': UpdateExpenseDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpenseDto
     */
    'note'?: string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof UpdateExpenseDto
     */
    'file'?: Array<Asset>;
}

export const UpdateExpenseDtoCategoryEnum = {
    RentMortgagePayments: 'Rent/mortgage payments',
    UtilityBillsElectricityWaterInternet: 'Utility bills (electricity, water, internet)',
    InventorySupply: 'Inventory & supply',
    EquipmentMaintenanceAndRepair: 'Equipment maintenance and repair',
    MarketingAdvertisingAndPromotions: 'Marketing, advertising, and promotions',
    Insurance: 'Insurance',
    TaxesLicenses: 'Taxes & licenses',
    FireProtectionPrevention: 'Fire protection & prevention',
    Others: 'Others'
} as const;

export type UpdateExpenseDtoCategoryEnum = typeof UpdateExpenseDtoCategoryEnum[keyof typeof UpdateExpenseDtoCategoryEnum];

/**
 * 
 * @export
 * @interface UpdateFeeAndCashDiscountDto
 */
export interface UpdateFeeAndCashDiscountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeeAndCashDiscountDto
     */
    'feeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeeAndCashDiscountDto
     */
    'autoOption'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeeAndCashDiscountDto
     */
    'manualOption'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeeAndCashDiscountDto
     */
    'technicianResponsibility'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeeAndCashDiscountDto
     */
    'autoDiscount'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateGeneralRewardDto
 */
export interface UpdateGeneralRewardDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGeneralRewardDto
     */
    'technicianResponsibility'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyRewardDto
 */
export interface UpdateLoyaltyRewardDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoyaltyRewardDto
     */
    'loyaltyReward'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoyaltyRewardDto
     */
    'allowCustomAmount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoyaltyRewardDto
     */
    'autoRewardPoint'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRewardDto
     */
    'reward'?: number | null;
    /**
     * 
     * @type {Array<RedeemOptionDTO>}
     * @memberof UpdateLoyaltyRewardDto
     */
    'redeemOptions'?: Array<RedeemOptionDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRewardDto
     */
    'expiration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRewardDto
     */
    'saleTicket'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRewardDto
     */
    'technicianResponsibility'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateOccasionRewardDto
 */
export interface UpdateOccasionRewardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOccasionRewardDto
     */
    'occasionPromotion'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOccasionRewardDto
     */
    'occasionReward'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateOccasionRewardDto
     */
    'technicianResponsibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOccasionRewardDto
     */
    'discountAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOccasionRewardDto
     */
    'eligiblePeriod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOccasionRewardDto
     */
    'discountPercent'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateOccasionRewardDto
     */
    'discountServices'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOccasionRewardDto
     */
    'isDiscountAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOccasionRewardDto
     */
    'allService'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatePrinterSettingDto
 */
export interface UpdatePrinterSettingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'cloverPrinter': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePrinterSettingDto
     */
    'receiptFor': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdatePrinterSettingDto
     */
    'paperWidth': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'printerName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'printerIPAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'printerDeviceIP': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'charactersPerLine': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrinterSettingDto
     */
    'lineSpacing': string;
}
/**
 * 
 * @export
 * @interface UpdatePrinterSettingsDto
 */
export interface UpdatePrinterSettingsDto {
    /**
     * 
     * @type {Array<UpdatePrinterSettingDto>}
     * @memberof UpdatePrinterSettingsDto
     */
    'settings': Array<UpdatePrinterSettingDto>;
}
/**
 * 
 * @export
 * @interface UpdateReferralRewardDto
 */
export interface UpdateReferralRewardDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReferralRewardDto
     */
    'referralReward'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralRewardDto
     */
    'rewardAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateReferralRewardDto
     */
    'technicianResponsibility'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleDto
     */
    'permissions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSampleDto
 */
export interface UpdateSampleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSampleDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSampleDto
     */
    'type'?: UpdateSampleDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateSampleDto
     */
    'id'?: number;
}

export const UpdateSampleDtoTypeEnum = {
    SampleEmail: 'sample-email',
    SampleSms: 'sample-sms',
    Link: 'link'
} as const;

export type UpdateSampleDtoTypeEnum = typeof UpdateSampleDtoTypeEnum[keyof typeof UpdateSampleDtoTypeEnum];

/**
 * 
 * @export
 * @interface UpdateSecurityCode
 */
export interface UpdateSecurityCode {
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureCardButton': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureCashCheckManual': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureOpenCashDrawer': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureCloseoutButton': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureGiftCardSaleButton': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureDiscountButton': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureManualRewarding': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'secureTransactionButton': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'managerCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecurityCode
     */
    'defaultManagerCode': string;
}
/**
 * 
 * @export
 * @interface UpdateServiceDTO
 */
export interface UpdateServiceDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateServiceItemDTO
 */
export interface UpdateServiceItemDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceItemDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceItemDTO
     */
    'subName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceItemDTO
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceItemDTO
     */
    'isMerchandise'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceItemDTO
     */
    'isTaxable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceItemDTO
     */
    'isCheckinStands'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceItemDTO
     */
    'isCheckinOnline'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'supply'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'time'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'turns'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'uncountedTurns'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'technicianCut'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceItemDTO
     */
    'technicianRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceItemDTO
     */
    'textColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceItemDTO
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateServiceItemDTO
     */
    'salonIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateTechniciansDTO
 */
export interface UpdateTechniciansDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateTechniciansDTO
     */
    'technicianIdOld': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTechniciansDTO
     */
    'technicianIdNew': number;
}
/**
 * 
 * @export
 * @interface UpdateTerminalDeviceSettingDto
 */
export interface UpdateTerminalDeviceSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'terminalConnection': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverApplicationID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverDeviceAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'tipLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'paxDeviceAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'poyntDeviceAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverPosName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverSerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'signatureLocation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'notCustomerPrint': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'notCustomerobtain': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'IdFor': UpdateTerminalDeviceSettingDtoIdForEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverAuthToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'deviceConnected'?: UpdateTerminalDeviceSettingDtoDeviceConnectedEnum;
    /**
     * 
     * @type {CloverMerchantInformation}
     * @memberof UpdateTerminalDeviceSettingDto
     */
    'cloverMerchantInformation'?: CloverMerchantInformation | null;
}

export const UpdateTerminalDeviceSettingDtoIdForEnum = {
    LocalTest: 'LocalTest',
    Production: 'Production',
    Development: 'Development'
} as const;

export type UpdateTerminalDeviceSettingDtoIdForEnum = typeof UpdateTerminalDeviceSettingDtoIdForEnum[keyof typeof UpdateTerminalDeviceSettingDtoIdForEnum];
export const UpdateTerminalDeviceSettingDtoDeviceConnectedEnum = {
    Pax: 'PAX',
    Clover: 'CLOVER'
} as const;

export type UpdateTerminalDeviceSettingDtoDeviceConnectedEnum = typeof UpdateTerminalDeviceSettingDtoDeviceConnectedEnum[keyof typeof UpdateTerminalDeviceSettingDtoDeviceConnectedEnum];

/**
 * 
 * @export
 * @interface UpdateTextMessageDto
 */
export interface UpdateTextMessageDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'customerConfirmationTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'customerReminderTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'customerCancelTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'technicianNotificationTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'technicianReminderTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTextMessageDto
     */
    'customerRewardBalance': string;
}
/**
 * 
 * @export
 * @interface UpdateTipForTechnicianDTO
 */
export interface UpdateTipForTechnicianDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateTipForTechnicianDTO
     */
    'technicianId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTipForTechnicianDTO
     */
    'tips': number;
}
/**
 * 
 * @export
 * @interface UpdateTipForTechniciansDTO
 */
export interface UpdateTipForTechniciansDTO {
    /**
     * 
     * @type {Array<UpdateTipForTechnicianDTO>}
     * @memberof UpdateTipForTechniciansDTO
     */
    'technicianTurns': Array<UpdateTipForTechnicianDTO>;
}
/**
 * 
 * @export
 * @interface UpdateTipSettingDto
 */
export interface UpdateTipSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTipSettingDto
     */
    'askEnteringTipWhenCheckout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTipSettingDto
     */
    'askCustomerForTipForTechnicians': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTipSettingDto
     */
    'askForTipAgainstFinalPayingAmount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTipSettingDto
     */
    'allowManuallySplittingTips': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTipSettingDto
     */
    'splitTipByWholeDollars': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTipSettingDto
     */
    'splitTipBy': UpdateTipSettingDtoSplitTipByEnum;
}

export const UpdateTipSettingDtoSplitTipByEnum = {
    Equal: 'Equal',
    AmountOfMoney: 'AmountOfMoney'
} as const;

export type UpdateTipSettingDtoSplitTipByEnum = typeof UpdateTipSettingDtoSplitTipByEnum[keyof typeof UpdateTipSettingDtoSplitTipByEnum];

/**
 * 
 * @export
 * @interface UpdateTurnSettingDto
 */
export interface UpdateTurnSettingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTurnSettingDto
     */
    'suggesttingMode'?: UpdateTurnSettingDtoSuggesttingModeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateTurnSettingDto
     */
    'turnSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurnSettingDto
     */
    'assessFillingTurnEarly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurnSettingDto
     */
    'suggestOnlyAvailiableTechnician'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurnSettingDto
     */
    'enableTurnManagement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurnSettingDto
     */
    'showTurnInfoCounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTurnSettingDto
     */
    'showMoneyAmounts': boolean;
}

export const UpdateTurnSettingDtoSuggesttingModeEnum = {
    SimpleTurn: 'simpleTurn',
    TraditionalTurn: 'traditionalTurn',
    EnhancedTurn: 'enhancedTurn',
    AverageTurn: 'averageTurn',
    ServiceTurn: 'serviceTurn',
    TimeTurn: 'timeTurn'
} as const;

export type UpdateTurnSettingDtoSuggesttingModeEnum = typeof UpdateTurnSettingDtoSuggesttingModeEnum[keyof typeof UpdateTurnSettingDtoSuggesttingModeEnum];

/**
 * 
 * @export
 * @interface UpdateWeeklyRewardDto
 */
export interface UpdateWeeklyRewardDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWeeklyRewardDto
     */
    'weeklyReward'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWeeklyRewardDto
     */
    'discountAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWeeklyRewardDto
     */
    'technicianResponsibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWeeklyRewardDto
     */
    'discountServiceAmount'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWeeklyRewardDto
     */
    'discountServices'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWeeklyRewardDto
     */
    'discountPercent'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWeeklyRewardDto
     */
    'dayOfWeek'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWeeklyRewardDto
     */
    'allService'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'identifier': string;
    /**
     * 
     * @type {Array<AuthenticationMethod>}
     * @memberof User
     */
    'authenticationMethods': Array<AuthenticationMethod>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface VerifyTokenDto
 */
export interface VerifyTokenDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface WageAndTipReponse
 */
export interface WageAndTipReponse {
    /**
     * 
     * @type {string}
     * @memberof WageAndTipReponse
     */
    'monthWageAndTip': string;
    /**
     * 
     * @type {number}
     * @memberof WageAndTipReponse
     */
    'wage': number;
    /**
     * 
     * @type {number}
     * @memberof WageAndTipReponse
     */
    'tip': number;
}
/**
 * 
 * @export
 * @interface WeeklyReward
 */
export interface WeeklyReward {
    /**
     * 
     * @type {boolean}
     * @memberof WeeklyReward
     */
    'weeklyReward': boolean;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'discountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'technicianResponsibility': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'discountServiceAmount': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WeeklyReward
     */
    'discountServices': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof WeeklyReward
     */
    'allService': boolean;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'discountPercent': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WeeklyReward
     */
    'dayOfWeek': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'promotionId': number;
    /**
     * 
     * @type {Promotion}
     * @memberof WeeklyReward
     */
    'promotion': Promotion;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReward
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReward
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReward
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReward
     */
    'deletedAt': string;
}

/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCalculateAvailableTime: async (calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculateAvailableTimeDto' is not null or undefined
            assertParamExists('appointmentControllerCalculateAvailableTime', 'calculateAvailableTimeDto', calculateAvailableTimeDto)
            const localVarPath = `/appointment/calculate-available-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateAvailableTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCalculateBusyTime: async (calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculateAvailableTimeDto' is not null or undefined
            assertParamExists('appointmentControllerCalculateBusyTime', 'calculateAvailableTimeDto', calculateAvailableTimeDto)
            const localVarPath = `/appointment/calculate-busy-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateAvailableTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {ChangeStatusAppointment} changeStatusAppointment 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerChangeStatusAppointment: async (appointmentId: string, changeStatusAppointment: ChangeStatusAppointment, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('appointmentControllerChangeStatusAppointment', 'appointmentId', appointmentId)
            // verify required parameter 'changeStatusAppointment' is not null or undefined
            assertParamExists('appointmentControllerChangeStatusAppointment', 'changeStatusAppointment', changeStatusAppointment)
            const localVarPath = `/appointment/status/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeStatusAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeStatusAppointments} changeStatusAppointments 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerChangeStatusAppointments: async (changeStatusAppointments: ChangeStatusAppointments, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeStatusAppointments' is not null or undefined
            assertParamExists('appointmentControllerChangeStatusAppointments', 'changeStatusAppointments', changeStatusAppointments)
            const localVarPath = `/appointment/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeStatusAppointments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreate: async (createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentDTO' is not null or undefined
            assertParamExists('appointmentControllerCreate', 'createAppointmentDTO', createAppointmentDTO)
            const localVarPath = `/appointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppointmentsDTO} createAppointmentsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreateGroup: async (createAppointmentsDTO: CreateAppointmentsDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentsDTO' is not null or undefined
            assertParamExists('appointmentControllerCreateGroup', 'createAppointmentsDTO', createAppointmentsDTO)
            const localVarPath = `/appointment/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreateOnline: async (createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentDTO' is not null or undefined
            assertParamExists('appointmentControllerCreateOnline', 'createAppointmentDTO', createAppointmentDTO)
            const localVarPath = `/appointment/online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerDelete: async (appointmentId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('appointmentControllerDelete', 'appointmentId', appointmentId)
            const localVarPath = `/appointment/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {string} dateAppointment 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [technician] 
         * @param {boolean} [filterByAppointmentSetting] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerGet: async (page: number, dateAppointment: string, size?: number, sort?: string, fullTextSearch?: string, technician?: number, filterByAppointmentSetting?: boolean, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('appointmentControllerGet', 'page', page)
            // verify required parameter 'dateAppointment' is not null or undefined
            assertParamExists('appointmentControllerGet', 'dateAppointment', dateAppointment)
            const localVarPath = `/appointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (dateAppointment !== undefined) {
                localVarQueryParameter['dateAppointment'] = (dateAppointment as any instanceof Date) ?
                    (dateAppointment as any).toISOString() :
                    dateAppointment;
            }

            if (technician !== undefined) {
                localVarQueryParameter['technician'] = technician;
            }

            if (filterByAppointmentSetting !== undefined) {
                localVarQueryParameter['filterByAppointmentSetting'] = filterByAppointmentSetting;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerGetById: async (appointmentId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('appointmentControllerGetById', 'appointmentId', appointmentId)
            const localVarPath = `/appointment/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {UpdateAppointmentDTO} updateAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerUpdate: async (appointmentId: string, updateAppointmentDTO: UpdateAppointmentDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('appointmentControllerUpdate', 'appointmentId', appointmentId)
            // verify required parameter 'updateAppointmentDTO' is not null or undefined
            assertParamExists('appointmentControllerUpdate', 'updateAppointmentDTO', updateAppointmentDTO)
            const localVarPath = `/appointment/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateAvailableTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCalculateBusyTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateAvailableTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCalculateBusyTime(calculateAvailableTimeDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {ChangeStatusAppointment} changeStatusAppointment 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerChangeStatusAppointment(appointmentId: string, changeStatusAppointment: ChangeStatusAppointment, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerChangeStatusAppointment(appointmentId, changeStatusAppointment, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangeStatusAppointments} changeStatusAppointments 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerChangeStatusAppointments(changeStatusAppointments: ChangeStatusAppointments, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerChangeStatusAppointments(changeStatusAppointments, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCreate(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCreate(createAppointmentDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppointmentsDTO} createAppointmentsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCreateGroup(createAppointmentsDTO: CreateAppointmentsDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCreateGroup(createAppointmentsDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCreateOnline(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCreateOnline(createAppointmentDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerDelete(appointmentId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerDelete(appointmentId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {string} dateAppointment 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [technician] 
         * @param {boolean} [filterByAppointmentSetting] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerGet(page: number, dateAppointment: string, size?: number, sort?: string, fullTextSearch?: string, technician?: number, filterByAppointmentSetting?: boolean, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerOldCustomerCheckin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerGet(page, dateAppointment, size, sort, fullTextSearch, technician, filterByAppointmentSetting, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerGetById(appointmentId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerGetById(appointmentId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {UpdateAppointmentDTO} updateAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerUpdate(appointmentId: string, updateAppointmentDTO: UpdateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerUpdate(appointmentId, updateAppointmentDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: any): AxiosPromise<CalculateAvailableTimeResponse> {
            return localVarFp.appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCalculateBusyTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: any): AxiosPromise<CalculateAvailableTimeResponse> {
            return localVarFp.appointmentControllerCalculateBusyTime(calculateAvailableTimeDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {ChangeStatusAppointment} changeStatusAppointment 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerChangeStatusAppointment(appointmentId: string, changeStatusAppointment: ChangeStatusAppointment, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentControllerChangeStatusAppointment(appointmentId, changeStatusAppointment, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeStatusAppointments} changeStatusAppointments 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerChangeStatusAppointments(changeStatusAppointments: ChangeStatusAppointments, salonId?: any, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentControllerChangeStatusAppointments(changeStatusAppointments, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreate(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentControllerCreate(createAppointmentDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppointmentsDTO} createAppointmentsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreateGroup(createAppointmentsDTO: CreateAppointmentsDTO, salonId?: any, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.appointmentControllerCreateGroup(createAppointmentsDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAppointmentDTO} createAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreateOnline(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentControllerCreateOnline(createAppointmentDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerDelete(appointmentId: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentControllerDelete(appointmentId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {string} dateAppointment 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [technician] 
         * @param {boolean} [filterByAppointmentSetting] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerGet(page: number, dateAppointment: string, size?: number, sort?: string, fullTextSearch?: string, technician?: number, filterByAppointmentSetting?: boolean, salonId?: any, options?: any): AxiosPromise<CustomerControllerOldCustomerCheckin200Response> {
            return localVarFp.appointmentControllerGet(page, dateAppointment, size, sort, fullTextSearch, technician, filterByAppointmentSetting, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerGetById(appointmentId: string, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentControllerGetById(appointmentId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appointmentId 
         * @param {UpdateAppointmentDTO} updateAppointmentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerUpdate(appointmentId: string, updateAppointmentDTO: UpdateAppointmentDTO, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.appointmentControllerUpdate(appointmentId, updateAppointmentDTO, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCalculateAvailableTime(calculateAvailableTimeDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculateAvailableTimeDto} calculateAvailableTimeDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCalculateBusyTime(calculateAvailableTimeDto: CalculateAvailableTimeDto, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCalculateBusyTime(calculateAvailableTimeDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {ChangeStatusAppointment} changeStatusAppointment 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerChangeStatusAppointment(appointmentId: string, changeStatusAppointment: ChangeStatusAppointment, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerChangeStatusAppointment(appointmentId, changeStatusAppointment, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeStatusAppointments} changeStatusAppointments 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerChangeStatusAppointments(changeStatusAppointments: ChangeStatusAppointments, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerChangeStatusAppointments(changeStatusAppointments, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppointmentDTO} createAppointmentDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCreate(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCreate(createAppointmentDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppointmentsDTO} createAppointmentsDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCreateGroup(createAppointmentsDTO: CreateAppointmentsDTO, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCreateGroup(createAppointmentsDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAppointmentDTO} createAppointmentDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCreateOnline(createAppointmentDTO: CreateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCreateOnline(createAppointmentDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerDelete(appointmentId: string, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerDelete(appointmentId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {string} dateAppointment 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {number} [technician] 
     * @param {boolean} [filterByAppointmentSetting] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerGet(page: number, dateAppointment: string, size?: number, sort?: string, fullTextSearch?: string, technician?: number, filterByAppointmentSetting?: boolean, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerGet(page, dateAppointment, size, sort, fullTextSearch, technician, filterByAppointmentSetting, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerGetById(appointmentId: string, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerGetById(appointmentId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appointmentId 
     * @param {UpdateAppointmentDTO} updateAppointmentDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerUpdate(appointmentId: string, updateAppointmentDTO: UpdateAppointmentDTO, salonId?: any, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerUpdate(appointmentId, updateAppointmentDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadAllFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assetControllerUploadAllFile', 'file', file)
            const localVarPath = `/assets/upload/all-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assetControllerUploadFile', 'file', file)
            const localVarPath = `/assets/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerUploadAllFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerUploadAllFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadAllFile(file: File, salonId?: any, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetControllerUploadAllFile(file, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerUploadAllFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerUploadAllFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin: async (loginDto: LoginDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerAdminLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/admin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword: async (changePasswordDto: ChangePasswordDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('authControllerChangePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin: async (loginDto: LoginDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerCustomerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/customer/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/customer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeLogin: async (loginDto: LoginDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerEmployeeLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/employee/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeLoginCode: async (loginCodeDto: LoginCodeDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCodeDto' is not null or undefined
            assertParamExists('authControllerEmployeeLoginCode', 'loginCodeDto', loginCodeDto)
            const localVarPath = `/auth/employee/login-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Employee infomation
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeMe: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/employee/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount: async (registerCustomerDto: RegisterCustomerDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerCustomerDto' is not null or undefined
            assertParamExists('authControllerRegisterCustomerAccount', 'registerCustomerDto', registerCustomerDto)
            const localVarPath = `/auth/customer/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestLoginCode: async (requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetDto' is not null or undefined
            assertParamExists('authControllerRequestLoginCode', 'requestPasswordResetDto', requestPasswordResetDto)
            const localVarPath = `/auth/request-login-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset: async (requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetDto' is not null or undefined
            assertParamExists('authControllerRequestPasswordReset', 'requestPasswordResetDto', requestPasswordResetDto)
            const localVarPath = `/auth/request-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (resetPasswordDto: ResetPasswordDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('authControllerResetPassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendNewPasswordToPhone: async (requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetDto' is not null or undefined
            assertParamExists('authControllerSendNewPasswordToPhone', 'requestPasswordResetDto', requestPasswordResetDto)
            const localVarPath = `/auth/send-new-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTechnicianLogin: async (loginDto: LoginDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerTechnicianLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/technician/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTechnicianLoginCode: async (loginCodeDto: LoginCodeDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCodeDto' is not null or undefined
            assertParamExists('authControllerTechnicianLoginCode', 'loginCodeDto', loginCodeDto)
            const localVarPath = `/auth/technician/login-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTest: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/testQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken: async (verifyTokenDto: VerifyTokenDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTokenDto' is not null or undefined
            assertParamExists('authControllerVerifyToken', 'verifyTokenDto', verifyTokenDto)
            const localVarPath = `/auth/verify-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminLogin(loginDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminMe(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminMe(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerChangePassword(changePasswordDto: ChangePasswordDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(changePasswordDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerLogin(loginDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerMe(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerMe(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmployeeLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerEmployeeLogin(loginDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmployeeLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerEmployeeLoginCode(loginCodeDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Employee infomation
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmployeeMe(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerEmployeeMe(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterCustomerAccount(registerCustomerDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRequestLoginCode(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRequestLoginCode(requestPasswordResetDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRequestPasswordReset(requestPasswordResetDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(resetPasswordDto: ResetPasswordDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(resetPasswordDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSendNewPasswordToPhone(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSendNewPasswordToPhone(requestPasswordResetDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTechnicianLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTechnicianLogin(loginDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTechnicianLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTechnicianLoginCode(loginCodeDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTest(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTest(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyToken(verifyTokenDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin(loginDto: LoginDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerAdminLogin(loginDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe(salonId?: any, options?: any): AxiosPromise<Administrator> {
            return localVarFp.authControllerAdminMe(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(changePasswordDto: ChangePasswordDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerChangePassword(changePasswordDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin(loginDto: LoginDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerCustomerLogin(loginDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe(salonId?: any, options?: any): AxiosPromise<Customer> {
            return localVarFp.authControllerCustomerMe(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeLogin(loginDto: LoginDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerEmployeeLogin(loginDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerEmployeeLoginCode(loginCodeDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Employee infomation
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmployeeMe(salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerEmployeeMe(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRegisterCustomerAccount(registerCustomerDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestLoginCode(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRequestLoginCode(requestPasswordResetDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerRequestPasswordReset(requestPasswordResetDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(resetPasswordDto: ResetPasswordDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerResetPassword(resetPasswordDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestPasswordResetDto} requestPasswordResetDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendNewPasswordToPhone(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerSendNewPasswordToPhone(requestPasswordResetDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTechnicianLogin(loginDto: LoginDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerTechnicianLogin(loginDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginCodeDto} loginCodeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTechnicianLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerTechnicianLoginCode(loginCodeDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTest(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerTest(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, salonId?: any, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.authControllerVerifyToken(verifyTokenDto, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminLogin(loginDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminMe(salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminMe(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(changePasswordDto: ChangePasswordDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerChangePassword(changePasswordDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerLogin(loginDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerMe(salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerMe(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEmployeeLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerEmployeeLogin(loginDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginCodeDto} loginCodeDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEmployeeLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerEmployeeLoginCode(loginCodeDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Employee infomation
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEmployeeMe(salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerEmployeeMe(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterCustomerDto} registerCustomerDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegisterCustomerAccount(registerCustomerDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestPasswordResetDto} requestPasswordResetDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRequestLoginCode(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRequestLoginCode(requestPasswordResetDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestPasswordResetDto} requestPasswordResetDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRequestPasswordReset(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRequestPasswordReset(requestPasswordResetDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPassword(resetPasswordDto: ResetPasswordDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPassword(resetPasswordDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestPasswordResetDto} requestPasswordResetDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSendNewPasswordToPhone(requestPasswordResetDto: RequestPasswordResetDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSendNewPasswordToPhone(requestPasswordResetDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTechnicianLogin(loginDto: LoginDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerTechnicianLogin(loginDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginCodeDto} loginCodeDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTechnicianLoginCode(loginCodeDto: LoginCodeDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerTechnicianLoginCode(loginCodeDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTest(salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerTest(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTokenDto} verifyTokenDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, salonId?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyToken(verifyTokenDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompensationApi - axios parameter creator
 * @export
 */
export const CompensationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdjustDto} adjustDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerAdjust: async (adjustDto: AdjustDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adjustDto' is not null or undefined
            assertParamExists('compensationControllerAdjust', 'adjustDto', adjustDto)
            const localVarPath = `/compensation/adjust`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adjustDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerDetails: async (technicianId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('compensationControllerDetails', 'technicianId', technicianId)
            const localVarPath = `/compensation/details/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCompensationTechnicianDTO} getCompensationTechnicianDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerExportService: async (getCompensationTechnicianDTO: GetCompensationTechnicianDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCompensationTechnicianDTO' is not null or undefined
            assertParamExists('compensationControllerExportService', 'getCompensationTechnicianDTO', getCompensationTechnicianDTO)
            const localVarPath = `/compensation/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCompensationTechnicianDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} adjustDate 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerGet: async (technicianId: number, adjustDate: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('compensationControllerGet', 'technicianId', technicianId)
            // verify required parameter 'adjustDate' is not null or undefined
            assertParamExists('compensationControllerGet', 'adjustDate', adjustDate)
            const localVarPath = `/compensation/adjust`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (adjustDate !== undefined) {
                localVarQueryParameter['adjustDate'] = (adjustDate as any instanceof Date) ?
                    (adjustDate as any).toISOString() :
                    adjustDate;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentFilterDTO} paymentFilterDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerGetCompensation: async (paymentFilterDTO: PaymentFilterDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentFilterDTO' is not null or undefined
            assertParamExists('compensationControllerGetCompensation', 'paymentFilterDTO', paymentFilterDTO)
            const localVarPath = `/compensation/compensation-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentFilterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {'Month' | 'Day'} typeOverview 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerOverview: async (technicianId: number, typeOverview: 'Month' | 'Day', salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('compensationControllerOverview', 'technicianId', technicianId)
            // verify required parameter 'typeOverview' is not null or undefined
            assertParamExists('compensationControllerOverview', 'typeOverview', typeOverview)
            const localVarPath = `/compensation/overview/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (typeOverview !== undefined) {
                localVarQueryParameter['typeOverview'] = typeOverview;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompensationPaymentDTO} compensationPaymentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerPayment: async (compensationPaymentDTO: CompensationPaymentDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compensationPaymentDTO' is not null or undefined
            assertParamExists('compensationControllerPayment', 'compensationPaymentDTO', compensationPaymentDTO)
            const localVarPath = `/compensation/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compensationPaymentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerPaymentDate: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/compensation/payment-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendMailCompensationDTO} sendMailCompensationDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerSendMailTimeSheet: async (sendMailCompensationDTO: SendMailCompensationDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMailCompensationDTO' is not null or undefined
            assertParamExists('compensationControllerSendMailTimeSheet', 'sendMailCompensationDTO', sendMailCompensationDTO)
            const localVarPath = `/compensation/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMailCompensationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompensationApi - functional programming interface
 * @export
 */
export const CompensationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompensationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdjustDto} adjustDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerAdjust(adjustDto: AdjustDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Compensation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerAdjust(adjustDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerDetails(technicianId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerDetails(technicianId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCompensationTechnicianDTO} getCompensationTechnicianDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerExportService(getCompensationTechnicianDTO: GetCompensationTechnicianDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerExportService(getCompensationTechnicianDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} adjustDate 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerGet(technicianId: number, adjustDate: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Compensation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerGet(technicianId, adjustDate, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentFilterDTO} paymentFilterDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerGetCompensation(paymentFilterDTO: PaymentFilterDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompensationPayResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerGetCompensation(paymentFilterDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {'Month' | 'Day'} typeOverview 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerOverview(technicianId: number, typeOverview: 'Month' | 'Day', salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerOverview(technicianId, typeOverview, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompensationPaymentDTO} compensationPaymentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerPayment(compensationPaymentDTO: CompensationPaymentDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompensationPay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerPayment(compensationPaymentDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerPaymentDate(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerPaymentDate(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendMailCompensationDTO} sendMailCompensationDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compensationControllerSendMailTimeSheet(sendMailCompensationDTO: SendMailCompensationDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compensationControllerSendMailTimeSheet(sendMailCompensationDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompensationApi - factory interface
 * @export
 */
export const CompensationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompensationApiFp(configuration)
    return {
        /**
         * 
         * @param {AdjustDto} adjustDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerAdjust(adjustDto: AdjustDto, salonId?: any, options?: any): AxiosPromise<Compensation> {
            return localVarFp.compensationControllerAdjust(adjustDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerDetails(technicianId: number, salonId?: any, options?: any): AxiosPromise<DetailResponse> {
            return localVarFp.compensationControllerDetails(technicianId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCompensationTechnicianDTO} getCompensationTechnicianDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerExportService(getCompensationTechnicianDTO: GetCompensationTechnicianDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.compensationControllerExportService(getCompensationTechnicianDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} adjustDate 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerGet(technicianId: number, adjustDate: string, salonId?: any, options?: any): AxiosPromise<Compensation> {
            return localVarFp.compensationControllerGet(technicianId, adjustDate, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentFilterDTO} paymentFilterDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerGetCompensation(paymentFilterDTO: PaymentFilterDTO, salonId?: any, options?: any): AxiosPromise<Array<CompensationPayResponse>> {
            return localVarFp.compensationControllerGetCompensation(paymentFilterDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {'Month' | 'Day'} typeOverview 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerOverview(technicianId: number, typeOverview: 'Month' | 'Day', salonId?: any, options?: any): AxiosPromise<OverviewResponse> {
            return localVarFp.compensationControllerOverview(technicianId, typeOverview, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompensationPaymentDTO} compensationPaymentDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerPayment(compensationPaymentDTO: CompensationPaymentDTO, salonId?: any, options?: any): AxiosPromise<Array<CompensationPay>> {
            return localVarFp.compensationControllerPayment(compensationPaymentDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerPaymentDate(salonId?: any, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.compensationControllerPaymentDate(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendMailCompensationDTO} sendMailCompensationDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compensationControllerSendMailTimeSheet(sendMailCompensationDTO: SendMailCompensationDTO, salonId?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.compensationControllerSendMailTimeSheet(sendMailCompensationDTO, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompensationApi - object-oriented interface
 * @export
 * @class CompensationApi
 * @extends {BaseAPI}
 */
export class CompensationApi extends BaseAPI {
    /**
     * 
     * @param {AdjustDto} adjustDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerAdjust(adjustDto: AdjustDto, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerAdjust(adjustDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerDetails(technicianId: number, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerDetails(technicianId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCompensationTechnicianDTO} getCompensationTechnicianDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerExportService(getCompensationTechnicianDTO: GetCompensationTechnicianDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerExportService(getCompensationTechnicianDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {string} adjustDate 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerGet(technicianId: number, adjustDate: string, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerGet(technicianId, adjustDate, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentFilterDTO} paymentFilterDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerGetCompensation(paymentFilterDTO: PaymentFilterDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerGetCompensation(paymentFilterDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {'Month' | 'Day'} typeOverview 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerOverview(technicianId: number, typeOverview: 'Month' | 'Day', salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerOverview(technicianId, typeOverview, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompensationPaymentDTO} compensationPaymentDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerPayment(compensationPaymentDTO: CompensationPaymentDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerPayment(compensationPaymentDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerPaymentDate(salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerPaymentDate(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendMailCompensationDTO} sendMailCompensationDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompensationApi
     */
    public compensationControllerSendMailTimeSheet(sendMailCompensationDTO: SendMailCompensationDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CompensationApiFp(this.configuration).compensationControllerSendMailTimeSheet(sendMailCompensationDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckBusyDTO} checkBusyDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckBusy: async (checkBusyDTO: CheckBusyDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkBusyDTO' is not null or undefined
            assertParamExists('customerControllerCheckBusy', 'checkBusyDTO', checkBusyDTO)
            const localVarPath = `/customers/check-busy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkBusyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckInDTO} checkInDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckin: async (checkInDTO: CheckInDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkInDTO' is not null or undefined
            assertParamExists('customerControllerCheckin', 'checkInDTO', checkInDTO)
            const localVarPath = `/customers/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckInBulkDTO} checkInBulkDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckinBulk: async (checkInBulkDTO: CheckInBulkDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkInBulkDTO' is not null or undefined
            assertParamExists('customerControllerCheckinBulk', 'checkInBulkDTO', checkInBulkDTO)
            const localVarPath = `/customers/checkin-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkInBulkDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDowloadTemplate: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/dowload-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerExportService: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGet', 'page', page)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetAppointment: async (customerId: string, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerGetAppointment', 'customerId', customerId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGetAppointment', 'page', page)
            const localVarPath = `/customers/appointment/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById: async (customerId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerGetById', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'Days' | 'Weeks' | 'Months' | 'Years'} timeType 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCheckInCount: async (timeType: 'Days' | 'Weeks' | 'Months' | 'Years', fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeType' is not null or undefined
            assertParamExists('customerControllerGetCheckInCount', 'timeType', timeType)
            const localVarPath = `/customers/checkin-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeType !== undefined) {
                localVarQueryParameter['timeType'] = timeType;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCheckout: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGetCheckout', 'page', page)
            const localVarPath = `/customers/check-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCount: async (fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomList: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGetCustomList', 'page', page)
            const localVarPath = `/customers/custom-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerByEmail: async (emailAddress: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('customerControllerGetCustomerByEmail', 'emailAddress', emailAddress)
            const localVarPath = `/customers/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerByPhoneNumber: async (phoneNumber: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('customerControllerGetCustomerByPhoneNumber', 'phoneNumber', phoneNumber)
            const localVarPath = `/customers/phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetGeneralInformation: async (customerId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerGetGeneralInformation', 'customerId', customerId)
            const localVarPath = `/customers/general-information/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRecentCheckins: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGetRecentCheckins', 'page', page)
            const localVarPath = `/customers/recent-checkins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRewardBalance: async (customerId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerGetRewardBalance', 'customerId', customerId)
            const localVarPath = `/customers/reward-balance/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {string} customerId 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRewardInformation: async (page: number, customerId: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGetRewardInformation', 'page', page)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerGetRewardInformation', 'customerId', customerId)
            const localVarPath = `/customers/reward-information/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerImportCustomer: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('customerControllerImportCustomer', 'file', file)
            const localVarPath = `/customers/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerOldCustomerCheckin: async (customerId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerOldCustomerCheckin', 'customerId', customerId)
            const localVarPath = `/customers/old-customer-checkins/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPut: async (customerId: string, updateCustomerDto: UpdateCustomerDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerPut', 'customerId', customerId)
            // verify required parameter 'updateCustomerDto' is not null or undefined
            assertParamExists('customerControllerPut', 'updateCustomerDto', updateCustomerDto)
            const localVarPath = `/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referralCode 
         * @param {ReferralCodeCheckDTO} referralCodeCheckDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerReferralCodeCheck: async (referralCode: string, referralCodeCheckDTO: ReferralCodeCheckDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralCode' is not null or undefined
            assertParamExists('customerControllerReferralCodeCheck', 'referralCode', referralCode)
            // verify required parameter 'referralCodeCheckDTO' is not null or undefined
            assertParamExists('customerControllerReferralCodeCheck', 'referralCodeCheckDTO', referralCodeCheckDTO)
            const localVarPath = `/customers/referral-code-check/{referralCode}`
                .replace(`{${"referralCode"}}`, encodeURIComponent(String(referralCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralCodeCheckDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerTextThisCustomer: async (customerId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerControllerTextThisCustomer', 'customerId', customerId)
            const localVarPath = `/customers/text-this/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('customerControllerUploadFile', 'file', file)
            const localVarPath = `/customers/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckBusyDTO} checkBusyDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCheckBusy(checkBusyDTO: CheckBusyDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCheckBusy(checkBusyDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckInDTO} checkInDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCheckin(checkInDTO: CheckInDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCheckin(checkInDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckInBulkDTO} checkInBulkDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCheckinBulk(checkInBulkDTO: CheckInBulkDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Appointment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCheckinBulk(checkInBulkDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerDowloadTemplate(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerDowloadTemplate(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerExportService(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerExportService(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGet(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetAppointment(customerId: string, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerOldCustomerCheckin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetAppointment(customerId, page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetById(customerId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetById(customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'Days' | 'Weeks' | 'Months' | 'Years'} timeType 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCheckInCount(timeType: 'Days' | 'Weeks' | 'Months' | 'Years', fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckInCountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCheckInCount(timeType, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCheckout(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGetCheckout200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCheckout(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCount(fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCount(fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCustomList(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerByEmail(emailAddress: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCustomerByEmail(emailAddress, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerByPhoneNumber(phoneNumber: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetCustomerByPhoneNumber(phoneNumber, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetGeneralInformation(customerId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRewardInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetGeneralInformation(customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetRecentCheckins(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGetRecentCheckins200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetRecentCheckins(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetRewardBalance(customerId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRewardBalance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetRewardBalance(customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {string} customerId 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetRewardInformation(page: number, customerId: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGetRewardInformation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetRewardInformation(page, customerId, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerImportCustomer(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerImportCustomer(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerOldCustomerCheckin(customerId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerOldCustomerCheckin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerOldCustomerCheckin(customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPut(customerId: string, updateCustomerDto: UpdateCustomerDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerPut(customerId, updateCustomerDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referralCode 
         * @param {ReferralCodeCheckDTO} referralCodeCheckDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerReferralCodeCheck(referralCode: string, referralCodeCheckDTO: ReferralCodeCheckDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerReferralCodeCheck(referralCode, referralCodeCheckDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerTextThisCustomer(customerId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerTextThisCustomer(customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckBusyDTO} checkBusyDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckBusy(checkBusyDTO: CheckBusyDTO, salonId?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.customerControllerCheckBusy(checkBusyDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckInDTO} checkInDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckin(checkInDTO: CheckInDTO, salonId?: any, options?: any): AxiosPromise<Appointment> {
            return localVarFp.customerControllerCheckin(checkInDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckInBulkDTO} checkInBulkDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCheckinBulk(checkInBulkDTO: CheckInBulkDTO, salonId?: any, options?: any): AxiosPromise<Array<Appointment>> {
            return localVarFp.customerControllerCheckinBulk(checkInBulkDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDowloadTemplate(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerDowloadTemplate(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerExportService(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerExportService(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerGet200Response> {
            return localVarFp.customerControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetAppointment(customerId: string, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerOldCustomerCheckin200Response> {
            return localVarFp.customerControllerGetAppointment(customerId, page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById(customerId: string, salonId?: any, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerGetById(customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'Days' | 'Weeks' | 'Months' | 'Years'} timeType 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCheckInCount(timeType: 'Days' | 'Weeks' | 'Months' | 'Years', fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<Array<CheckInCountResponse>> {
            return localVarFp.customerControllerGetCheckInCount(timeType, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCheckout(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerGetCheckout200Response> {
            return localVarFp.customerControllerGetCheckout(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCount(fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<Array<CustomerCountResponse>> {
            return localVarFp.customerControllerGetCount(fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerGetCustomList(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerByEmail(emailAddress: string, salonId?: any, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerGetCustomerByEmail(emailAddress, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerByPhoneNumber(phoneNumber: string, salonId?: any, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerGetCustomerByPhoneNumber(phoneNumber, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetGeneralInformation(customerId: string, salonId?: any, options?: any): AxiosPromise<CustomerRewardInformation> {
            return localVarFp.customerControllerGetGeneralInformation(customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRecentCheckins(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerGetRecentCheckins200Response> {
            return localVarFp.customerControllerGetRecentCheckins(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRewardBalance(customerId: string, salonId?: any, options?: any): AxiosPromise<CustomerRewardBalance> {
            return localVarFp.customerControllerGetRewardBalance(customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {string} customerId 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetRewardInformation(page: number, customerId: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerGetRewardInformation200Response> {
            return localVarFp.customerControllerGetRewardInformation(page, customerId, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerImportCustomer(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerImportCustomer(file, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerOldCustomerCheckin(customerId: string, salonId?: any, options?: any): AxiosPromise<CustomerControllerOldCustomerCheckin200Response> {
            return localVarFp.customerControllerOldCustomerCheckin(customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPut(customerId: string, updateCustomerDto: UpdateCustomerDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.customerControllerPut(customerId, updateCustomerDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referralCode 
         * @param {ReferralCodeCheckDTO} referralCodeCheckDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerReferralCodeCheck(referralCode: string, referralCodeCheckDTO: ReferralCodeCheckDTO, salonId?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.customerControllerReferralCodeCheck(referralCode, referralCodeCheckDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerTextThisCustomer(customerId: number, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerTextThisCustomer(customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CheckBusyDTO} checkBusyDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerCheckBusy(checkBusyDTO: CheckBusyDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerCheckBusy(checkBusyDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckInDTO} checkInDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerCheckin(checkInDTO: CheckInDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerCheckin(checkInDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckInBulkDTO} checkInBulkDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerCheckinBulk(checkInBulkDTO: CheckInBulkDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerCheckinBulk(checkInBulkDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerDowloadTemplate(salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerDowloadTemplate(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerExportService(salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerExportService(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetAppointment(customerId: string, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetAppointment(customerId, page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetById(customerId: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetById(customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'Days' | 'Weeks' | 'Months' | 'Years'} timeType 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCheckInCount(timeType: 'Days' | 'Weeks' | 'Months' | 'Years', fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCheckInCount(timeType, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCheckout(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCheckout(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCount(fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCount(fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCustomList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCustomList(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCustomerByEmail(emailAddress: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCustomerByEmail(emailAddress, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} phoneNumber 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetCustomerByPhoneNumber(phoneNumber: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetCustomerByPhoneNumber(phoneNumber, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetGeneralInformation(customerId: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetGeneralInformation(customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetRecentCheckins(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetRecentCheckins(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetRewardBalance(customerId: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetRewardBalance(customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {string} customerId 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetRewardInformation(page: number, customerId: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetRewardInformation(page, customerId, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerImportCustomer(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerImportCustomer(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerOldCustomerCheckin(customerId: string, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerOldCustomerCheckin(customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {UpdateCustomerDto} updateCustomerDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerPut(customerId: string, updateCustomerDto: UpdateCustomerDto, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerPut(customerId, updateCustomerDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referralCode 
     * @param {ReferralCodeCheckDTO} referralCodeCheckDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerReferralCodeCheck(referralCode: string, referralCodeCheckDTO: ReferralCodeCheckDTO, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerReferralCodeCheck(referralCode, referralCodeCheckDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerTextThisCustomer(customerId: number, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerTextThisCustomer(customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckLoginIdExistedDTO} checkLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckLoginIdExisted: async (checkLoginIdExistedDTO: CheckLoginIdExistedDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkLoginIdExistedDTO' is not null or undefined
            assertParamExists('employeeControllerCheckLoginIdExisted', 'checkLoginIdExistedDTO', checkLoginIdExistedDTO)
            const localVarPath = `/employees/check-loginId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkLoginIdExistedDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckin: async (technicianCheckin: TechnicianCheckin, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianCheckin' is not null or undefined
            assertParamExists('employeeControllerCheckin', 'technicianCheckin', technicianCheckin)
            const localVarPath = `/employees/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicianCheckin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckinAppointment: async (technicianId: number, technicianCheckin: TechnicianCheckin, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerCheckinAppointment', 'technicianId', technicianId)
            // verify required parameter 'technicianCheckin' is not null or undefined
            assertParamExists('employeeControllerCheckinAppointment', 'technicianCheckin', technicianCheckin)
            const localVarPath = `/employees/checkin-appointment/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicianCheckin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUpdateBlockTimeDTO} createUpdateBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCreateUpdateBlockTime: async (createUpdateBlockTimeDTO: CreateUpdateBlockTimeDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUpdateBlockTimeDTO' is not null or undefined
            assertParamExists('employeeControllerCreateUpdateBlockTime', 'createUpdateBlockTimeDTO', createUpdateBlockTimeDTO)
            const localVarPath = `/employees/save-block-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpdateBlockTimeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'DAYS' | 'MONTHS'} typeCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDashboard: async (typeCompensation: 'DAYS' | 'MONTHS', salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeCompensation' is not null or undefined
            assertParamExists('employeeControllerDashboard', 'typeCompensation', typeCompensation)
            const localVarPath = `/employees/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (typeCompensation !== undefined) {
                localVarQueryParameter['typeCompensation'] = typeCompensation;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DeleteBlockTimeDTO} deleteBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteBlockTime: async (id: number, deleteBlockTimeDTO: DeleteBlockTimeDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerDeleteBlockTime', 'id', id)
            // verify required parameter 'deleteBlockTimeDTO' is not null or undefined
            assertParamExists('employeeControllerDeleteBlockTime', 'deleteBlockTimeDTO', deleteBlockTimeDTO)
            const localVarPath = `/employees/block-time/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBlockTimeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {DeleteCheckinDTO} deleteCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteCheckout: async (technicianId: number, deleteCheckinDTO: DeleteCheckinDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerDeleteCheckout', 'technicianId', technicianId)
            // verify required parameter 'deleteCheckinDTO' is not null or undefined
            assertParamExists('employeeControllerDeleteCheckout', 'deleteCheckinDTO', deleteCheckinDTO)
            const localVarPath = `/employees/checkin/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCheckinDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteEmployee: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerDeleteEmployee', 'id', id)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportEmployees: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('employeeControllerExportEmployees', 'page', page)
            const localVarPath = `/employees/data/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportService: async (sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMailAndExportDTO' is not null or undefined
            assertParamExists('employeeControllerExportService', 'sendMailAndExportDTO', sendMailAndExportDTO)
            const localVarPath = `/employees/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMailAndExportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportTemplate: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGet: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('employeeControllerGet', 'page', page)
            const localVarPath = `/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salons) {
                localVarQueryParameter['salons'] = salons;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetBlockTimeByTechnicianId: async (technicianId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerGetBlockTimeByTechnicianId', 'technicianId', technicianId)
            const localVarPath = `/employees/block-time/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetByID: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerGetByID', 'id', id)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckin: async (dateCheckin: string, technicianId?: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCheckin' is not null or undefined
            assertParamExists('employeeControllerGetCheckin', 'dateCheckin', dateCheckin)
            const localVarPath = `/employees/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateCheckin !== undefined) {
                localVarQueryParameter['dateCheckin'] = (dateCheckin as any instanceof Date) ?
                    (dateCheckin as any).toISOString() :
                    dateCheckin;
            }

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckinAllTechnicianInMonth: async (dateCheckin: string, technicianId?: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCheckin' is not null or undefined
            assertParamExists('employeeControllerGetCheckinAllTechnicianInMonth', 'dateCheckin', dateCheckin)
            const localVarPath = `/employees/technicians-checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateCheckin !== undefined) {
                localVarQueryParameter['dateCheckin'] = (dateCheckin as any instanceof Date) ?
                    (dateCheckin as any).toISOString() :
                    dateCheckin;
            }

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} dateCheckin 
         * @param {number} [technicianId2] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckinTechnicianInMonth: async (technicianId: number, dateCheckin: string, technicianId2?: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerGetCheckinTechnicianInMonth', 'technicianId', technicianId)
            // verify required parameter 'dateCheckin' is not null or undefined
            assertParamExists('employeeControllerGetCheckinTechnicianInMonth', 'dateCheckin', dateCheckin)
            const localVarPath = `/employees/technicians-checkin/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateCheckin !== undefined) {
                localVarQueryParameter['dateCheckin'] = (dateCheckin as any instanceof Date) ?
                    (dateCheckin as any).toISOString() :
                    dateCheckin;
            }

            if (technicianId2 !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId2;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetTechnicianCompensation: async (dateCompensation: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCompensation' is not null or undefined
            assertParamExists('employeeControllerGetTechnicianCompensation', 'dateCompensation', dateCompensation)
            const localVarPath = `/employees/technicians-compensation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateCompensation !== undefined) {
                localVarQueryParameter['dateCompensation'] = (dateCompensation as any instanceof Date) ?
                    (dateCompensation as any).toISOString() :
                    dateCompensation;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetTechnicianCompensationById: async (dateCompensation: string, technicianId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCompensation' is not null or undefined
            assertParamExists('employeeControllerGetTechnicianCompensationById', 'dateCompensation', dateCompensation)
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerGetTechnicianCompensationById', 'technicianId', technicianId)
            const localVarPath = `/employees/technicians-compensation/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateCompensation !== undefined) {
                localVarQueryParameter['dateCompensation'] = (dateCompensation as any instanceof Date) ?
                    (dateCompensation as any).toISOString() :
                    dateCompensation;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPost: async (createEmployeeDTO: CreateEmployeeDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDTO' is not null or undefined
            assertParamExists('employeeControllerPost', 'createEmployeeDTO', createEmployeeDTO)
            const localVarPath = `/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPostRehire: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerPostRehire', 'id', id)
            const localVarPath = `/employees/rehire/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPostTerminated: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerPostTerminated', 'id', id)
            const localVarPath = `/employees/terminated/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPut: async (id: number, updateEmployeeDTO: UpdateEmployeeDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerPut', 'id', id)
            // verify required parameter 'updateEmployeeDTO' is not null or undefined
            assertParamExists('employeeControllerPut', 'updateEmployeeDTO', updateEmployeeDTO)
            const localVarPath = `/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeProfileDTO} updateEmployeeProfileDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPutAvatar: async (id: number, updateEmployeeProfileDTO: UpdateEmployeeProfileDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('employeeControllerPutAvatar', 'id', id)
            // verify required parameter 'updateEmployeeProfileDTO' is not null or undefined
            assertParamExists('employeeControllerPutAvatar', 'updateEmployeeProfileDTO', updateEmployeeProfileDTO)
            const localVarPath = `/employees/{id}/profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeProfileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerSendMailTimeSheet: async (sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMailAndExportDTO' is not null or undefined
            assertParamExists('employeeControllerSendMailTimeSheet', 'sendMailAndExportDTO', sendMailAndExportDTO)
            const localVarPath = `/employees/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMailAndExportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TechnicianIdsDTO} technicianIdsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerSortTechinicians: async (technicianIdsDTO: TechnicianIdsDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianIdsDTO' is not null or undefined
            assertParamExists('employeeControllerSortTechinicians', 'technicianIdsDTO', technicianIdsDTO)
            const localVarPath = `/employees/priority`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicianIdsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [serviceItemIds] 
         * @param {'WORK_TODAY' | 'CLOCKED_IN'} [filterBy] 
         * @param {'NAME' | 'CLOCK_TIME'} [orderBy] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerTechnician: async (fullTextSearch?: string, serviceItemIds?: Array<number>, filterBy?: 'WORK_TODAY' | 'CLOCKED_IN', orderBy?: 'NAME' | 'CLOCK_TIME', salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/employees/technician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (serviceItemIds) {
                localVarQueryParameter['serviceItemIds'] = serviceItemIds;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerTerminated: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('employeeControllerTerminated', 'page', page)
            const localVarPath = `/employees/terminated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salons) {
                localVarQueryParameter['salons'] = salons;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {UpdateCheckinDTO} updateCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerUpdateCheckin: async (technicianId: number, updateCheckinDTO: UpdateCheckinDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'technicianId' is not null or undefined
            assertParamExists('employeeControllerUpdateCheckin', 'technicianId', technicianId)
            // verify required parameter 'updateCheckinDTO' is not null or undefined
            assertParamExists('employeeControllerUpdateCheckin', 'updateCheckinDTO', updateCheckinDTO)
            const localVarPath = `/employees/checkin/{technicianId}`
                .replace(`{${"technicianId"}}`, encodeURIComponent(String(technicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckinDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('employeeControllerUploadFile', 'file', file)
            const localVarPath = `/employees/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckLoginIdExistedDTO} checkLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO: CheckLoginIdExistedDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Employee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerCheckin(technicianCheckin: TechnicianCheckin, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerCheckin(technicianCheckin, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerCheckinAppointment(technicianId: number, technicianCheckin: TechnicianCheckin, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerCheckinAppointment(technicianId, technicianCheckin, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUpdateBlockTimeDTO} createUpdateBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO: CreateUpdateBlockTimeDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'DAYS' | 'MONTHS'} typeCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerDashboard(typeCompensation: 'DAYS' | 'MONTHS', salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerDashboard(typeCompensation, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DeleteBlockTimeDTO} deleteBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerDeleteBlockTime(id: number, deleteBlockTimeDTO: DeleteBlockTimeDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerDeleteBlockTime(id, deleteBlockTimeDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {DeleteCheckinDTO} deleteCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerDeleteCheckout(technicianId: number, deleteCheckinDTO: DeleteCheckinDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerDeleteCheckout(technicianId, deleteCheckinDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerDeleteEmployee(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerDeleteEmployee(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerExportEmployees(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerExportEmployees(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerExportService(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerExportService(sendMailAndExportDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerExportTemplate(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGet(page, size, sort, fullTextSearch, salons, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetBlockTimeByTechnicianId(technicianId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockTime>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetBlockTimeByTechnicianId(technicianId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetByID(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Employee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetByID(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetCheckin(dateCheckin: string, technicianId?: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnicianCheckinResponese>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetCheckin(dateCheckin, technicianId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin: string, technicianId?: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechniciansCheckinResponese>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin, technicianId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} dateCheckin 
         * @param {number} [technicianId2] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetCheckinTechnicianInMonth(technicianId: number, dateCheckin: string, technicianId2?: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechniciansCheckinResponese>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetCheckinTechnicianInMonth(technicianId, dateCheckin, technicianId2, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetTechnicianCompensation(dateCompensation: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnicianCompensationResponese>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetTechnicianCompensation(dateCompensation, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerGetTechnicianCompensationById(dateCompensation: string, technicianId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicianCompensationResponese>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerGetTechnicianCompensationById(dateCompensation, technicianId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerPost(createEmployeeDTO: CreateEmployeeDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Employee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerPost(createEmployeeDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerPostRehire(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerPostRehire(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerPostTerminated(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerPostTerminated(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerPut(id: number, updateEmployeeDTO: UpdateEmployeeDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Employee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerPut(id, updateEmployeeDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeProfileDTO} updateEmployeeProfileDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerPutAvatar(id: number, updateEmployeeProfileDTO: UpdateEmployeeProfileDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Employee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerPutAvatar(id, updateEmployeeProfileDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerSendMailTimeSheet(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerSendMailTimeSheet(sendMailAndExportDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TechnicianIdsDTO} technicianIdsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerSortTechinicians(technicianIdsDTO: TechnicianIdsDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerSortTechinicians(technicianIdsDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [serviceItemIds] 
         * @param {'WORK_TODAY' | 'CLOCKED_IN'} [filterBy] 
         * @param {'NAME' | 'CLOCK_TIME'} [orderBy] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerTechnician(fullTextSearch?: string, serviceItemIds?: Array<number>, filterBy?: 'WORK_TODAY' | 'CLOCKED_IN', orderBy?: 'NAME' | 'CLOCK_TIME', salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Employee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerTechnician(fullTextSearch, serviceItemIds, filterBy, orderBy, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerTerminated(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerTerminated(page, size, sort, fullTextSearch, salons, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {UpdateCheckinDTO} updateCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerUpdateCheckin(technicianId: number, updateCheckinDTO: UpdateCheckinDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerUpdateCheckin(technicianId, updateCheckinDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeesApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckLoginIdExistedDTO} checkLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO: CheckLoginIdExistedDTO, salonId?: any, options?: any): AxiosPromise<Employee> {
            return localVarFp.employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckin(technicianCheckin: TechnicianCheckin, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.employeeControllerCheckin(technicianCheckin, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {TechnicianCheckin} technicianCheckin 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCheckinAppointment(technicianId: number, technicianCheckin: TechnicianCheckin, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.employeeControllerCheckinAppointment(technicianId, technicianCheckin, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUpdateBlockTimeDTO} createUpdateBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO: CreateUpdateBlockTimeDTO, salonId?: any, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'DAYS' | 'MONTHS'} typeCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDashboard(typeCompensation: 'DAYS' | 'MONTHS', salonId?: any, options?: any): AxiosPromise<DashboardResponse> {
            return localVarFp.employeeControllerDashboard(typeCompensation, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DeleteBlockTimeDTO} deleteBlockTimeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteBlockTime(id: number, deleteBlockTimeDTO: DeleteBlockTimeDTO, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.employeeControllerDeleteBlockTime(id, deleteBlockTimeDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {DeleteCheckinDTO} deleteCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteCheckout(technicianId: number, deleteCheckinDTO: DeleteCheckinDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerDeleteCheckout(technicianId, deleteCheckinDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerDeleteEmployee(id: number, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerDeleteEmployee(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportEmployees(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerExportEmployees(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportService(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerExportService(sendMailAndExportDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerExportTemplate(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerExportTemplate(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: any): AxiosPromise<EmployeeControllerGet200Response> {
            return localVarFp.employeeControllerGet(page, size, sort, fullTextSearch, salons, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetBlockTimeByTechnicianId(technicianId: number, salonId?: any, options?: any): AxiosPromise<Array<BlockTime>> {
            return localVarFp.employeeControllerGetBlockTimeByTechnicianId(technicianId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetByID(id: number, salonId?: any, options?: any): AxiosPromise<Employee> {
            return localVarFp.employeeControllerGetByID(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckin(dateCheckin: string, technicianId?: number, salonId?: any, options?: any): AxiosPromise<Array<TechnicianCheckinResponese>> {
            return localVarFp.employeeControllerGetCheckin(dateCheckin, technicianId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateCheckin 
         * @param {number} [technicianId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin: string, technicianId?: number, salonId?: any, options?: any): AxiosPromise<Array<TechniciansCheckinResponese>> {
            return localVarFp.employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin, technicianId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {string} dateCheckin 
         * @param {number} [technicianId2] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetCheckinTechnicianInMonth(technicianId: number, dateCheckin: string, technicianId2?: number, salonId?: any, options?: any): AxiosPromise<TechniciansCheckinResponese> {
            return localVarFp.employeeControllerGetCheckinTechnicianInMonth(technicianId, dateCheckin, technicianId2, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetTechnicianCompensation(dateCompensation: string, salonId?: any, options?: any): AxiosPromise<Array<TechnicianCompensationResponese>> {
            return localVarFp.employeeControllerGetTechnicianCompensation(dateCompensation, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateCompensation 
         * @param {number} technicianId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerGetTechnicianCompensationById(dateCompensation: string, technicianId: number, salonId?: any, options?: any): AxiosPromise<TechnicianCompensationResponese> {
            return localVarFp.employeeControllerGetTechnicianCompensationById(dateCompensation, technicianId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmployeeDTO} createEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPost(createEmployeeDTO: CreateEmployeeDTO, salonId?: any, options?: any): AxiosPromise<Employee> {
            return localVarFp.employeeControllerPost(createEmployeeDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPostRehire(id: number, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.employeeControllerPostRehire(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPostTerminated(id: number, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.employeeControllerPostTerminated(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeDTO} updateEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPut(id: number, updateEmployeeDTO: UpdateEmployeeDTO, salonId?: any, options?: any): AxiosPromise<Employee> {
            return localVarFp.employeeControllerPut(id, updateEmployeeDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEmployeeProfileDTO} updateEmployeeProfileDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerPutAvatar(id: number, updateEmployeeProfileDTO: UpdateEmployeeProfileDTO, salonId?: any, options?: any): AxiosPromise<Employee> {
            return localVarFp.employeeControllerPutAvatar(id, updateEmployeeProfileDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendMailAndExportDTO} sendMailAndExportDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerSendMailTimeSheet(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.employeeControllerSendMailTimeSheet(sendMailAndExportDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TechnicianIdsDTO} technicianIdsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerSortTechinicians(technicianIdsDTO: TechnicianIdsDTO, salonId?: any, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.employeeControllerSortTechinicians(technicianIdsDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [serviceItemIds] 
         * @param {'WORK_TODAY' | 'CLOCKED_IN'} [filterBy] 
         * @param {'NAME' | 'CLOCK_TIME'} [orderBy] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerTechnician(fullTextSearch?: string, serviceItemIds?: Array<number>, filterBy?: 'WORK_TODAY' | 'CLOCKED_IN', orderBy?: 'NAME' | 'CLOCK_TIME', salonId?: any, options?: any): AxiosPromise<Array<Employee>> {
            return localVarFp.employeeControllerTechnician(fullTextSearch, serviceItemIds, filterBy, orderBy, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [salons] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerTerminated(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: any): AxiosPromise<EmployeeControllerGet200Response> {
            return localVarFp.employeeControllerTerminated(page, size, sort, fullTextSearch, salons, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} technicianId 
         * @param {UpdateCheckinDTO} updateCheckinDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerUpdateCheckin(technicianId: number, updateCheckinDTO: UpdateCheckinDTO, salonId?: any, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.employeeControllerUpdateCheckin(technicianId, updateCheckinDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.employeeControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
    /**
     * 
     * @param {CheckLoginIdExistedDTO} checkLoginIdExistedDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO: CheckLoginIdExistedDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerCheckLoginIdExisted(checkLoginIdExistedDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianCheckin} technicianCheckin 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerCheckin(technicianCheckin: TechnicianCheckin, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerCheckin(technicianCheckin, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {TechnicianCheckin} technicianCheckin 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerCheckinAppointment(technicianId: number, technicianCheckin: TechnicianCheckin, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerCheckinAppointment(technicianId, technicianCheckin, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUpdateBlockTimeDTO} createUpdateBlockTimeDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO: CreateUpdateBlockTimeDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerCreateUpdateBlockTime(createUpdateBlockTimeDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'DAYS' | 'MONTHS'} typeCompensation 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerDashboard(typeCompensation: 'DAYS' | 'MONTHS', salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerDashboard(typeCompensation, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DeleteBlockTimeDTO} deleteBlockTimeDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerDeleteBlockTime(id: number, deleteBlockTimeDTO: DeleteBlockTimeDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerDeleteBlockTime(id, deleteBlockTimeDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {DeleteCheckinDTO} deleteCheckinDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerDeleteCheckout(technicianId: number, deleteCheckinDTO: DeleteCheckinDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerDeleteCheckout(technicianId, deleteCheckinDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerDeleteEmployee(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerDeleteEmployee(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerExportEmployees(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerExportEmployees(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendMailAndExportDTO} sendMailAndExportDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerExportService(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerExportService(sendMailAndExportDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerExportTemplate(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {Array<number>} [salons] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGet(page, size, sort, fullTextSearch, salons, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetBlockTimeByTechnicianId(technicianId: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetBlockTimeByTechnicianId(technicianId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetByID(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetByID(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateCheckin 
     * @param {number} [technicianId] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetCheckin(dateCheckin: string, technicianId?: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetCheckin(dateCheckin, technicianId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateCheckin 
     * @param {number} [technicianId] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin: string, technicianId?: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetCheckinAllTechnicianInMonth(dateCheckin, technicianId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {string} dateCheckin 
     * @param {number} [technicianId2] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetCheckinTechnicianInMonth(technicianId: number, dateCheckin: string, technicianId2?: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetCheckinTechnicianInMonth(technicianId, dateCheckin, technicianId2, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateCompensation 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetTechnicianCompensation(dateCompensation: string, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetTechnicianCompensation(dateCompensation, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateCompensation 
     * @param {number} technicianId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerGetTechnicianCompensationById(dateCompensation: string, technicianId: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerGetTechnicianCompensationById(dateCompensation, technicianId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmployeeDTO} createEmployeeDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerPost(createEmployeeDTO: CreateEmployeeDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerPost(createEmployeeDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerPostRehire(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerPostRehire(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerPostTerminated(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerPostTerminated(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateEmployeeDTO} updateEmployeeDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerPut(id: number, updateEmployeeDTO: UpdateEmployeeDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerPut(id, updateEmployeeDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateEmployeeProfileDTO} updateEmployeeProfileDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerPutAvatar(id: number, updateEmployeeProfileDTO: UpdateEmployeeProfileDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerPutAvatar(id, updateEmployeeProfileDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendMailAndExportDTO} sendMailAndExportDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerSendMailTimeSheet(sendMailAndExportDTO: SendMailAndExportDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerSendMailTimeSheet(sendMailAndExportDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianIdsDTO} technicianIdsDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerSortTechinicians(technicianIdsDTO: TechnicianIdsDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerSortTechinicians(technicianIdsDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fullTextSearch] 
     * @param {Array<number>} [serviceItemIds] 
     * @param {'WORK_TODAY' | 'CLOCKED_IN'} [filterBy] 
     * @param {'NAME' | 'CLOCK_TIME'} [orderBy] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerTechnician(fullTextSearch?: string, serviceItemIds?: Array<number>, filterBy?: 'WORK_TODAY' | 'CLOCKED_IN', orderBy?: 'NAME' | 'CLOCK_TIME', salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerTechnician(fullTextSearch, serviceItemIds, filterBy, orderBy, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {Array<number>} [salons] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerTerminated(page: number, size?: number, sort?: string, fullTextSearch?: string, salons?: Array<number>, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerTerminated(page, size, sort, fullTextSearch, salons, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} technicianId 
     * @param {UpdateCheckinDTO} updateCheckinDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerUpdateCheckin(technicianId: number, updateCheckinDTO: UpdateCheckinDTO, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerUpdateCheckin(technicianId, updateCheckinDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public employeeControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).employeeControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExpenseApi - axios parameter creator
 * @export
 */
export const ExpenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerDeleteExpense: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expenseControllerDeleteExpense', 'id', id)
            const localVarPath = `/expense/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilterExpense} searchFilterExpense 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerExportRecentSales: async (searchFilterExpense: SearchFilterExpense, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterExpense' is not null or undefined
            assertParamExists('expenseControllerExportRecentSales', 'searchFilterExpense', searchFilterExpense)
            const localVarPath = `/expense/expense-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterExpense, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerGetExpense: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('expenseControllerGetExpense', 'page', page)
            const localVarPath = `/expense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateExpenseDto} updateExpenseDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerUpdateExpense: async (updateExpenseDto: UpdateExpenseDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateExpenseDto' is not null or undefined
            assertParamExists('expenseControllerUpdateExpense', 'updateExpenseDto', updateExpenseDto)
            const localVarPath = `/expense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpenseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpenseApi - functional programming interface
 * @export
 */
export const ExpenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseControllerDeleteExpense(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseControllerDeleteExpense(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilterExpense} searchFilterExpense 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseControllerExportRecentSales(searchFilterExpense: SearchFilterExpense, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseControllerExportRecentSales(searchFilterExpense, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseControllerGetExpense(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationResponseExpense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseControllerGetExpense(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateExpenseDto} updateExpenseDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseControllerUpdateExpense(updateExpenseDto: UpdateExpenseDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Expense>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseControllerUpdateExpense(updateExpenseDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExpenseApi - factory interface
 * @export
 */
export const ExpenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpenseApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerDeleteExpense(id: number, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.expenseControllerDeleteExpense(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilterExpense} searchFilterExpense 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerExportRecentSales(searchFilterExpense: SearchFilterExpense, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.expenseControllerExportRecentSales(searchFilterExpense, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerGetExpense(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<PaginationResponseExpense> {
            return localVarFp.expenseControllerGetExpense(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateExpenseDto} updateExpenseDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseControllerUpdateExpense(updateExpenseDto: UpdateExpenseDto, salonId?: any, options?: any): AxiosPromise<Expense> {
            return localVarFp.expenseControllerUpdateExpense(updateExpenseDto, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpenseApi - object-oriented interface
 * @export
 * @class ExpenseApi
 * @extends {BaseAPI}
 */
export class ExpenseApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseControllerDeleteExpense(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseControllerDeleteExpense(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilterExpense} searchFilterExpense 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseControllerExportRecentSales(searchFilterExpense: SearchFilterExpense, salonId?: any, options?: AxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseControllerExportRecentSales(searchFilterExpense, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseControllerGetExpense(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseControllerGetExpense(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateExpenseDto} updateExpenseDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseControllerUpdateExpense(updateExpenseDto: UpdateExpenseDto, salonId?: any, options?: AxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseControllerUpdateExpense(updateExpenseDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailsApi - axios parameter creator
 * @export
 */
export const MailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mails/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailsApi - functional programming interface
 * @export
 */
export const MailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailControllerTest(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailControllerTest(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailsApi - factory interface
 * @export
 */
export const MailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailsApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.mailControllerTest(salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailsApi - object-oriented interface
 * @export
 * @class MailsApi
 * @extends {BaseAPI}
 */
export class MailsApi extends BaseAPI {
    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailsApi
     */
    public mailControllerTest(salonId?: any, options?: AxiosRequestConfig) {
        return MailsApiFp(this.configuration).mailControllerTest(salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDeviceTokenDto} addDeviceTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerAddToken: async (addDeviceTokenDto: AddDeviceTokenDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addDeviceTokenDto' is not null or undefined
            assertParamExists('notificationControllerAddToken', 'addDeviceTokenDto', addDeviceTokenDto)
            const localVarPath = `/notifications/add-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeviceTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetAll: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('notificationControllerGetAll', 'page', page)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetOne: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationControllerGetOne', 'id', id)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMaskAsRead: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationControllerMaskAsRead', 'id', id)
            const localVarPath = `/notifications/mask-as-read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerTest: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerUnseenAmount: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/unseen-amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddDeviceTokenDto} addDeviceTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerAddToken(addDeviceTokenDto: AddDeviceTokenDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerAddToken(addDeviceTokenDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetAll(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationControllerGetAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetAll(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetOne(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetOne(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMaskAsRead(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMaskAsRead(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerTest(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerTest(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerUnseenAmount(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerUnseenAmount(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddDeviceTokenDto} addDeviceTokenDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerAddToken(addDeviceTokenDto: AddDeviceTokenDto, salonId?: any, options?: any): AxiosPromise<DeviceToken> {
            return localVarFp.notificationControllerAddToken(addDeviceTokenDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetAll(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<NotificationControllerGetAll200Response> {
            return localVarFp.notificationControllerGetAll(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetOne(id: number, salonId?: any, options?: any): AxiosPromise<Notification> {
            return localVarFp.notificationControllerGetOne(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMaskAsRead(id: number, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.notificationControllerMaskAsRead(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerTest(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerTest(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerUnseenAmount(salonId?: any, options?: any): AxiosPromise<number> {
            return localVarFp.notificationControllerUnseenAmount(salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {AddDeviceTokenDto} addDeviceTokenDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerAddToken(addDeviceTokenDto: AddDeviceTokenDto, salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerAddToken(addDeviceTokenDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerGetAll(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerGetAll(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerGetOne(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerGetOne(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerMaskAsRead(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerMaskAsRead(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerTest(salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerTest(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerUnseenAmount(salonId?: any, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerUnseenAmount(salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerGet(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerGet(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet(salonId?: any, options?: any): AxiosPromise<Array<PermissionGroupDto>> {
            return localVarFp.permissionControllerGet(salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerGet(salonId?: any, options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerGet(salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreateAnnouncement: async (createAnnouncementDto: CreateAnnouncementDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnnouncementDto' is not null or undefined
            assertParamExists('promotionControllerCreateAnnouncement', 'createAnnouncementDto', createAnnouncementDto)
            const localVarPath = `/promotions/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnnouncementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSampleDto} updateSampleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreateOrUpdateSample: async (updateSampleDto: UpdateSampleDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSampleDto' is not null or undefined
            assertParamExists('promotionControllerCreateOrUpdateSample', 'updateSampleDto', updateSampleDto)
            const localVarPath = `/promotions/announcement-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSampleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnouncementFilter} anouncementFilter 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerEstimateMatchCustomer: async (anouncementFilter: AnouncementFilter, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'anouncementFilter' is not null or undefined
            assertParamExists('promotionControllerEstimateMatchCustomer', 'anouncementFilter', anouncementFilter)
            const localVarPath = `/promotions/announcement/estimate-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(anouncementFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGet: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetAllAnnouncements: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('promotionControllerGetAllAnnouncements', 'page', page)
            const localVarPath = `/promotions/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetAnnouncement: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerGetAnnouncement', 'id', id)
            const localVarPath = `/promotions/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetListSample: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/announcement-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerDto} customerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerSendOne: async (id: number, customerDto: CustomerDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerSendOne', 'id', id)
            // verify required parameter 'customerDto' is not null or undefined
            assertParamExists('promotionControllerSendOne', 'customerDto', customerDto)
            const localVarPath = `/promotions/announcement/{id}/send-one`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerTest: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/testQuery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateAnnouncement: async (id: number, updateAnnouncementDto: UpdateAnnouncementDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionControllerUpdateAnnouncement', 'id', id)
            // verify required parameter 'updateAnnouncementDto' is not null or undefined
            assertParamExists('promotionControllerUpdateAnnouncement', 'updateAnnouncementDto', updateAnnouncementDto)
            const localVarPath = `/promotions/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnouncementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBirthdayRewardDto} updateBirthdayRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateBirthdayReward: async (updateBirthdayRewardDto: UpdateBirthdayRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBirthdayRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateBirthdayReward', 'updateBirthdayRewardDto', updateBirthdayRewardDto)
            const localVarPath = `/promotions/birthday-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBirthdayRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCheckinRewardDto} updateCheckinRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateCheckinReward: async (updateCheckinRewardDto: UpdateCheckinRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCheckinRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateCheckinReward', 'updateCheckinRewardDto', updateCheckinRewardDto)
            const localVarPath = `/promotions/checkin-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckinRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFeeAndCashDiscountDto} updateFeeAndCashDiscountDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateFeeAndCashDiscount: async (updateFeeAndCashDiscountDto: UpdateFeeAndCashDiscountDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFeeAndCashDiscountDto' is not null or undefined
            assertParamExists('promotionControllerUpdateFeeAndCashDiscount', 'updateFeeAndCashDiscountDto', updateFeeAndCashDiscountDto)
            const localVarPath = `/promotions/fee-cash-discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeeAndCashDiscountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGeneralRewardDto} updateGeneralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateGeneralReward: async (updateGeneralRewardDto: UpdateGeneralRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGeneralRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateGeneralReward', 'updateGeneralRewardDto', updateGeneralRewardDto)
            const localVarPath = `/promotions/general-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGeneralRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLoyaltyRewardDto} updateLoyaltyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateLoyaltyReward: async (updateLoyaltyRewardDto: UpdateLoyaltyRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLoyaltyRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateLoyaltyReward', 'updateLoyaltyRewardDto', updateLoyaltyRewardDto)
            const localVarPath = `/promotions/loyalty-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOccasionRewardDto} updateOccasionRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateOccasionReward: async (updateOccasionRewardDto: UpdateOccasionRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOccasionRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateOccasionReward', 'updateOccasionRewardDto', updateOccasionRewardDto)
            const localVarPath = `/promotions/occasion-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOccasionRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateReferralRewardDto} updateReferralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateReferralReward: async (updateReferralRewardDto: UpdateReferralRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateReferralRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateReferralReward', 'updateReferralRewardDto', updateReferralRewardDto)
            const localVarPath = `/promotions/referral-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReferralRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWeeklyRewardDto} updateWeeklyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateWeeklyReward: async (updateWeeklyRewardDto: UpdateWeeklyRewardDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWeeklyRewardDto' is not null or undefined
            assertParamExists('promotionControllerUpdateWeeklyReward', 'updateWeeklyRewardDto', updateWeeklyRewardDto)
            const localVarPath = `/promotions/weekly-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWeeklyRewardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerCreateAnnouncement(createAnnouncementDto: CreateAnnouncementDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerCreateAnnouncement(createAnnouncementDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSampleDto} updateSampleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerCreateOrUpdateSample(updateSampleDto: UpdateSampleDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerCreateOrUpdateSample(updateSampleDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnouncementFilter} anouncementFilter 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerEstimateMatchCustomer(anouncementFilter: AnouncementFilter, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Customer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerEstimateMatchCustomer(anouncementFilter, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerGet(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerGet(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerGetAllAnnouncements(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionControllerGetAllAnnouncements200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerGetAllAnnouncements(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerGetAnnouncement(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerGetAnnouncement(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerGetListSample(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sample>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerGetListSample(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerDto} customerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerSendOne(id: number, customerDto: CustomerDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerSendOne(id, customerDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerTest(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerTest(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateAnnouncement(id: number, updateAnnouncementDto: UpdateAnnouncementDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateAnnouncement(id, updateAnnouncementDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBirthdayRewardDto} updateBirthdayRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto: UpdateBirthdayRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCheckinRewardDto} updateCheckinRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateCheckinReward(updateCheckinRewardDto: UpdateCheckinRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateCheckinReward(updateCheckinRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateFeeAndCashDiscountDto} updateFeeAndCashDiscountDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto: UpdateFeeAndCashDiscountDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateGeneralRewardDto} updateGeneralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateGeneralReward(updateGeneralRewardDto: UpdateGeneralRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateGeneralReward(updateGeneralRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLoyaltyRewardDto} updateLoyaltyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto: UpdateLoyaltyRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOccasionRewardDto} updateOccasionRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateOccasionReward(updateOccasionRewardDto: UpdateOccasionRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateOccasionReward(updateOccasionRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateReferralRewardDto} updateReferralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateReferralReward(updateReferralRewardDto: UpdateReferralRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateReferralReward(updateReferralRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWeeklyRewardDto} updateWeeklyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto: UpdateWeeklyRewardDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAnnouncementDto} createAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreateAnnouncement(createAnnouncementDto: CreateAnnouncementDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.promotionControllerCreateAnnouncement(createAnnouncementDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSampleDto} updateSampleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerCreateOrUpdateSample(updateSampleDto: UpdateSampleDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.promotionControllerCreateOrUpdateSample(updateSampleDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnouncementFilter} anouncementFilter 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerEstimateMatchCustomer(anouncementFilter: AnouncementFilter, salonId?: any, options?: any): AxiosPromise<Array<Customer>> {
            return localVarFp.promotionControllerEstimateMatchCustomer(anouncementFilter, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGet(salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerGet(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetAllAnnouncements(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<PromotionControllerGetAllAnnouncements200Response> {
            return localVarFp.promotionControllerGetAllAnnouncements(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetAnnouncement(id: number, salonId?: any, options?: any): AxiosPromise<Announcement> {
            return localVarFp.promotionControllerGetAnnouncement(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerGetListSample(salonId?: any, options?: any): AxiosPromise<Array<Sample>> {
            return localVarFp.promotionControllerGetListSample(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CustomerDto} customerDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerSendOne(id: number, customerDto: CustomerDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.promotionControllerSendOne(id, customerDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerTest(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.promotionControllerTest(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateAnnouncementDto} updateAnnouncementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateAnnouncement(id: number, updateAnnouncementDto: UpdateAnnouncementDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.promotionControllerUpdateAnnouncement(id, updateAnnouncementDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBirthdayRewardDto} updateBirthdayRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto: UpdateBirthdayRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCheckinRewardDto} updateCheckinRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateCheckinReward(updateCheckinRewardDto: UpdateCheckinRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateCheckinReward(updateCheckinRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFeeAndCashDiscountDto} updateFeeAndCashDiscountDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto: UpdateFeeAndCashDiscountDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGeneralRewardDto} updateGeneralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateGeneralReward(updateGeneralRewardDto: UpdateGeneralRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateGeneralReward(updateGeneralRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLoyaltyRewardDto} updateLoyaltyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto: UpdateLoyaltyRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOccasionRewardDto} updateOccasionRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateOccasionReward(updateOccasionRewardDto: UpdateOccasionRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateOccasionReward(updateOccasionRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateReferralRewardDto} updateReferralRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateReferralReward(updateReferralRewardDto: UpdateReferralRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateReferralReward(updateReferralRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWeeklyRewardDto} updateWeeklyRewardDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto: UpdateWeeklyRewardDto, salonId?: any, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAnnouncementDto} createAnnouncementDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerCreateAnnouncement(createAnnouncementDto: CreateAnnouncementDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerCreateAnnouncement(createAnnouncementDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSampleDto} updateSampleDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerCreateOrUpdateSample(updateSampleDto: UpdateSampleDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerCreateOrUpdateSample(updateSampleDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnouncementFilter} anouncementFilter 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerEstimateMatchCustomer(anouncementFilter: AnouncementFilter, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerEstimateMatchCustomer(anouncementFilter, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerGet(salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerGet(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerGetAllAnnouncements(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerGetAllAnnouncements(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerGetAnnouncement(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerGetAnnouncement(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerGetListSample(salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerGetListSample(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CustomerDto} customerDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerSendOne(id: number, customerDto: CustomerDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerSendOne(id, customerDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerTest(salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerTest(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateAnnouncementDto} updateAnnouncementDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateAnnouncement(id: number, updateAnnouncementDto: UpdateAnnouncementDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateAnnouncement(id, updateAnnouncementDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBirthdayRewardDto} updateBirthdayRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto: UpdateBirthdayRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateBirthdayReward(updateBirthdayRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCheckinRewardDto} updateCheckinRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateCheckinReward(updateCheckinRewardDto: UpdateCheckinRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateCheckinReward(updateCheckinRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFeeAndCashDiscountDto} updateFeeAndCashDiscountDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto: UpdateFeeAndCashDiscountDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateFeeAndCashDiscount(updateFeeAndCashDiscountDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGeneralRewardDto} updateGeneralRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateGeneralReward(updateGeneralRewardDto: UpdateGeneralRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateGeneralReward(updateGeneralRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLoyaltyRewardDto} updateLoyaltyRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto: UpdateLoyaltyRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateLoyaltyReward(updateLoyaltyRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOccasionRewardDto} updateOccasionRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateOccasionReward(updateOccasionRewardDto: UpdateOccasionRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateOccasionReward(updateOccasionRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateReferralRewardDto} updateReferralRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateReferralReward(updateReferralRewardDto: UpdateReferralRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateReferralReward(updateReferralRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWeeklyRewardDto} updateWeeklyRewardDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto: UpdateWeeklyRewardDto, salonId?: any, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionControllerUpdateWeeklyReward(updateWeeklyRewardDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SearchFilterGiftCardDTO} searchFilterGiftCardDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportGiftCard: async (searchFilterGiftCardDTO: SearchFilterGiftCardDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterGiftCardDTO' is not null or undefined
            assertParamExists('reportControllerExportGiftCard', 'searchFilterGiftCardDTO', searchFilterGiftCardDTO)
            const localVarPath = `/report/gift-card-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterGiftCardDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportRecentSales: async (searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterTransactionDTO' is not null or undefined
            assertParamExists('reportControllerExportRecentSales', 'searchFilterTransactionDTO', searchFilterTransactionDTO)
            const localVarPath = `/report/recent-sales-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRevenueDTO} searchRevenueDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportRevenue: async (searchRevenueDTO: SearchRevenueDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRevenueDTO' is not null or undefined
            assertParamExists('reportControllerExportRevenue', 'searchRevenueDTO', searchRevenueDTO)
            const localVarPath = `/report/revenue-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRevenueDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataExportSalesByCategoryDTO} dataExportSalesByCategoryDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportSalesByCategory: async (dataExportSalesByCategoryDTO: DataExportSalesByCategoryDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataExportSalesByCategoryDTO' is not null or undefined
            assertParamExists('reportControllerExportSalesByCategory', 'dataExportSalesByCategoryDTO', dataExportSalesByCategoryDTO)
            const localVarPath = `/report/sales-by-category-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataExportSalesByCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilterValuedCustomerDTO} searchFilterValuedCustomerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportService: async (searchFilterValuedCustomerDTO: SearchFilterValuedCustomerDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterValuedCustomerDTO' is not null or undefined
            assertParamExists('reportControllerExportService', 'searchFilterValuedCustomerDTO', searchFilterValuedCustomerDTO)
            const localVarPath = `/report/valued-customer-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterValuedCustomerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportTransaction: async (searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterTransactionDTO' is not null or undefined
            assertParamExists('reportControllerExportTransaction', 'searchFilterTransactionDTO', searchFilterTransactionDTO)
            const localVarPath = `/report/transaction-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilterValuedEmployeeDTO} searchFilterValuedEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportValuedEmployee: async (searchFilterValuedEmployeeDTO: SearchFilterValuedEmployeeDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterValuedEmployeeDTO' is not null or undefined
            assertParamExists('reportControllerExportValuedEmployee', 'searchFilterValuedEmployeeDTO', searchFilterValuedEmployeeDTO)
            const localVarPath = `/report/valued-employee-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterValuedEmployeeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetAllSalesByCategory: async (from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/sales-by-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetAllValuedEmployee: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetAllValuedEmployee', 'page', page)
            const localVarPath = `/report/valued-employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetDashboard: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetGiftCard: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetGiftCard', 'page', page)
            const localVarPath = `/report/gift-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRecentSales: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetRecentSales', 'page', page)
            const localVarPath = `/report/recent-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRevenueReport: async (page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetRevenueReport', 'page', page)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('reportControllerGetRevenueReport', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('reportControllerGetRevenueReport', 'to', to)
            // verify required parameter 'isGrpTechnician' is not null or undefined
            assertParamExists('reportControllerGetRevenueReport', 'isGrpTechnician', isGrpTechnician)
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('reportControllerGetRevenueReport', 'filterType', filterType)
            const localVarPath = `/report/revenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (isGrpTechnician !== undefined) {
                localVarQueryParameter['isGrpTechnician'] = isGrpTechnician;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['filterType'] = filterType;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRevenueReportGroupTechnician: async (page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetRevenueReportGroupTechnician', 'page', page)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('reportControllerGetRevenueReportGroupTechnician', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('reportControllerGetRevenueReportGroupTechnician', 'to', to)
            // verify required parameter 'isGrpTechnician' is not null or undefined
            assertParamExists('reportControllerGetRevenueReportGroupTechnician', 'isGrpTechnician', isGrpTechnician)
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('reportControllerGetRevenueReportGroupTechnician', 'filterType', filterType)
            const localVarPath = `/report/revenue-group-technician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (isGrpTechnician !== undefined) {
                localVarQueryParameter['isGrpTechnician'] = isGrpTechnician;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['filterType'] = filterType;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetTransaction: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetTransaction', 'page', page)
            const localVarPath = `/report/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetValuedCustomer: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('reportControllerGetValuedCustomer', 'page', page)
            const localVarPath = `/report/valued-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SearchFilterGiftCardDTO} searchFilterGiftCardDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportGiftCard(searchFilterGiftCardDTO: SearchFilterGiftCardDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportGiftCard(searchFilterGiftCardDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportRecentSales(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportRecentSales(searchFilterTransactionDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchRevenueDTO} searchRevenueDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportRevenue(searchRevenueDTO: SearchRevenueDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportRevenue(searchRevenueDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataExportSalesByCategoryDTO} dataExportSalesByCategoryDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO: DataExportSalesByCategoryDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilterValuedCustomerDTO} searchFilterValuedCustomerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportService(searchFilterValuedCustomerDTO: SearchFilterValuedCustomerDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportService(searchFilterValuedCustomerDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportTransaction(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportTransaction(searchFilterTransactionDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilterValuedEmployeeDTO} searchFilterValuedEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO: SearchFilterValuedEmployeeDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetAllSalesByCategory(from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSalesByCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetAllSalesByCategory(from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetAllValuedEmployee(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerGetAllValuedEmployee200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetAllValuedEmployee(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetDashboard(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetDashboard(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetGiftCard(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerGetGiftCard200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetGiftCard(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetRecentSales(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControllerGetTicketPending200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetRecentSales(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetRevenueReport(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerGetRevenueReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetRevenueReport(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetRevenueReportGroupTechnician(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerGetRevenueReportGroupTechnician200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetRevenueReportGroupTechnician(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControllerGetTicketPending200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetTransaction(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportControllerGetValuedCustomer(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportControllerGetValuedCustomer200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportControllerGetValuedCustomer(page, size, sort, fullTextSearch, from, to, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {SearchFilterGiftCardDTO} searchFilterGiftCardDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportGiftCard(searchFilterGiftCardDTO: SearchFilterGiftCardDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportGiftCard(searchFilterGiftCardDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportRecentSales(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportRecentSales(searchFilterTransactionDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRevenueDTO} searchRevenueDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportRevenue(searchRevenueDTO: SearchRevenueDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportRevenue(searchRevenueDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataExportSalesByCategoryDTO} dataExportSalesByCategoryDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO: DataExportSalesByCategoryDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilterValuedCustomerDTO} searchFilterValuedCustomerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportService(searchFilterValuedCustomerDTO: SearchFilterValuedCustomerDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportService(searchFilterValuedCustomerDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportTransaction(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportTransaction(searchFilterTransactionDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilterValuedEmployeeDTO} searchFilterValuedEmployeeDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO: SearchFilterValuedEmployeeDTO, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetAllSalesByCategory(from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<ReportSalesByCategory> {
            return localVarFp.reportControllerGetAllSalesByCategory(from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetAllValuedEmployee(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<ReportControllerGetAllValuedEmployee200Response> {
            return localVarFp.reportControllerGetAllValuedEmployee(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetDashboard(salonId?: any, options?: any): AxiosPromise<ReportDashboard> {
            return localVarFp.reportControllerGetDashboard(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetGiftCard(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<ReportControllerGetGiftCard200Response> {
            return localVarFp.reportControllerGetGiftCard(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRecentSales(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<TicketControllerGetTicketPending200Response> {
            return localVarFp.reportControllerGetRecentSales(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRevenueReport(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<ReportControllerGetRevenueReport200Response> {
            return localVarFp.reportControllerGetRevenueReport(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {string} from 
         * @param {string} to 
         * @param {boolean} isGrpTechnician 
         * @param {string} filterType 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetRevenueReportGroupTechnician(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<ReportControllerGetRevenueReportGroupTechnician200Response> {
            return localVarFp.reportControllerGetRevenueReportGroupTechnician(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<TicketControllerGetTicketPending200Response> {
            return localVarFp.reportControllerGetTransaction(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportControllerGetValuedCustomer(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: any): AxiosPromise<ReportControllerGetValuedCustomer200Response> {
            return localVarFp.reportControllerGetValuedCustomer(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {SearchFilterGiftCardDTO} searchFilterGiftCardDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportGiftCard(searchFilterGiftCardDTO: SearchFilterGiftCardDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportGiftCard(searchFilterGiftCardDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportRecentSales(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportRecentSales(searchFilterTransactionDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchRevenueDTO} searchRevenueDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportRevenue(searchRevenueDTO: SearchRevenueDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportRevenue(searchRevenueDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataExportSalesByCategoryDTO} dataExportSalesByCategoryDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO: DataExportSalesByCategoryDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportSalesByCategory(dataExportSalesByCategoryDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilterValuedCustomerDTO} searchFilterValuedCustomerDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportService(searchFilterValuedCustomerDTO: SearchFilterValuedCustomerDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportService(searchFilterValuedCustomerDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilterTransactionDTO} searchFilterTransactionDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportTransaction(searchFilterTransactionDTO: SearchFilterTransactionDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportTransaction(searchFilterTransactionDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilterValuedEmployeeDTO} searchFilterValuedEmployeeDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO: SearchFilterValuedEmployeeDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerExportValuedEmployee(searchFilterValuedEmployeeDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetAllSalesByCategory(from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetAllSalesByCategory(from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetAllValuedEmployee(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetAllValuedEmployee(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetDashboard(salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetDashboard(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetGiftCard(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetGiftCard(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetRecentSales(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetRecentSales(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {string} from 
     * @param {string} to 
     * @param {boolean} isGrpTechnician 
     * @param {string} filterType 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetRevenueReport(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetRevenueReport(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {string} from 
     * @param {string} to 
     * @param {boolean} isGrpTechnician 
     * @param {string} filterType 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetRevenueReportGroupTechnician(page: number, from: string, to: string, isGrpTechnician: boolean, filterType: string, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetRevenueReportGroupTechnician(page, from, to, isGrpTechnician, filterType, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetTransaction(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportControllerGetValuedCustomer(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportControllerGetValuedCustomer(page, size, sort, fullTextSearch, from, to, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate: async (createRoleDto: CreateRoleDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerCreate', 'createRoleDto', createRoleDto)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('roleControllerGet', 'page', page)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById: async (id: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerGetById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoleByAuthorization: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate: async (id: string, updateRoleDto: UpdateRoleDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerUpdate', 'id', id)
            // verify required parameter 'updateRoleDto' is not null or undefined
            assertParamExists('roleControllerUpdate', 'updateRoleDto', updateRoleDto)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreate(createRoleDto: CreateRoleDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerCreate(createRoleDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGet(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetById(id: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetById(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetRoleByAuthorization(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetRoleByAuthorization(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerUpdate(id, updateRoleDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate(createRoleDto: CreateRoleDto, salonId?: any, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerCreate(createRoleDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<RoleControllerGet200Response> {
            return localVarFp.roleControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById(id: string, salonId?: any, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerGetById(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoleByAuthorization(salonId?: any, options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.roleControllerGetRoleByAuthorization(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, salonId?: any, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerUpdate(id, updateRoleDto, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} createRoleDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerCreate(createRoleDto: CreateRoleDto, salonId?: any, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerCreate(createRoleDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGetById(id: string, salonId?: any, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGetById(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGetRoleByAuthorization(salonId?: any, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGetRoleByAuthorization(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRoleDto} updateRoleDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, salonId?: any, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerUpdate(id, updateRoleDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SalonsApi - axios parameter creator
 * @export
 */
export const SalonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckSalonDTO} checkSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerCheckSalon: async (checkSalonDTO: CheckSalonDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkSalonDTO' is not null or undefined
            assertParamExists('salonControllerCheckSalon', 'checkSalonDTO', checkSalonDTO)
            const localVarPath = `/salons/check-salon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkSalonDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSalonDTO} createSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerCreate: async (createSalonDTO: CreateSalonDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSalonDTO' is not null or undefined
            assertParamExists('salonControllerCreate', 'createSalonDTO', createSalonDTO)
            const localVarPath = `/salons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSalonDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DisplaySettingsDTO} displaySettingsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerDisplaySetting: async (displaySettingsDTO: DisplaySettingsDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'displaySettingsDTO' is not null or undefined
            assertParamExists('salonControllerDisplaySetting', 'displaySettingsDTO', displaySettingsDTO)
            const localVarPath = `/salons/dislay-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(displaySettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGet: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('salonControllerGet', 'page', page)
            const localVarPath = `/salons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetAllSalonByBusinessSetting: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('salonControllerGetAllSalonByBusinessSetting', 'page', page)
            const localVarPath = `/salons/by-business-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetCurrentSalon: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salons/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetSalonById: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonControllerGetSalonById', 'id', id)
            const localVarPath = `/salons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalonsApi - functional programming interface
 * @export
 */
export const SalonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckSalonDTO} checkSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerCheckSalon(checkSalonDTO: CheckSalonDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerCheckSalon(checkSalonDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSalonDTO} createSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerCreate(createSalonDTO: CreateSalonDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Salon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerCreate(createSalonDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DisplaySettingsDTO} displaySettingsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerDisplaySetting(displaySettingsDTO: DisplaySettingsDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerDisplaySetting(displaySettingsDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerGet(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerGetAllSalonByBusinessSetting(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerGetAllSalonByBusinessSetting(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerGetCurrentSalon(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Salon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerGetCurrentSalon(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerGetSalonById(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Salon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerGetSalonById(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalonsApi - factory interface
 * @export
 */
export const SalonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalonsApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckSalonDTO} checkSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerCheckSalon(checkSalonDTO: CheckSalonDTO, salonId?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.salonControllerCheckSalon(checkSalonDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSalonDTO} createSalonDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerCreate(createSalonDTO: CreateSalonDTO, salonId?: any, options?: any): AxiosPromise<Salon> {
            return localVarFp.salonControllerCreate(createSalonDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DisplaySettingsDTO} displaySettingsDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerDisplaySetting(displaySettingsDTO: DisplaySettingsDTO, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.salonControllerDisplaySetting(displaySettingsDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<SalonControllerGet200Response> {
            return localVarFp.salonControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetAllSalonByBusinessSetting(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<SalonControllerGet200Response> {
            return localVarFp.salonControllerGetAllSalonByBusinessSetting(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetCurrentSalon(salonId?: any, options?: any): AxiosPromise<Salon> {
            return localVarFp.salonControllerGetCurrentSalon(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerGetSalonById(id: number, salonId?: any, options?: any): AxiosPromise<Salon> {
            return localVarFp.salonControllerGetSalonById(id, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalonsApi - object-oriented interface
 * @export
 * @class SalonsApi
 * @extends {BaseAPI}
 */
export class SalonsApi extends BaseAPI {
    /**
     * 
     * @param {CheckSalonDTO} checkSalonDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerCheckSalon(checkSalonDTO: CheckSalonDTO, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerCheckSalon(checkSalonDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSalonDTO} createSalonDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerCreate(createSalonDTO: CreateSalonDTO, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerCreate(createSalonDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DisplaySettingsDTO} displaySettingsDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerDisplaySetting(displaySettingsDTO: DisplaySettingsDTO, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerDisplaySetting(displaySettingsDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerGetAllSalonByBusinessSetting(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerGetAllSalonByBusinessSetting(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerGetCurrentSalon(salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerGetCurrentSalon(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerGetSalonById(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerGetSalonById(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SentMessagesApi - axios parameter creator
 * @export
 */
export const SentMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sentMessagesControllerExportTransaction: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('sentMessagesControllerExportTransaction', 'page', page)
            const localVarPath = `/sent-messages/sent-message-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sentMessagesControllerFindAll: async (page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('sentMessagesControllerFindAll', 'page', page)
            const localVarPath = `/sent-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SentMessagesApi - functional programming interface
 * @export
 */
export const SentMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SentMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sentMessagesControllerExportTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sentMessagesControllerExportTransaction(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sentMessagesControllerFindAll(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentMessagesControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sentMessagesControllerFindAll(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SentMessagesApi - factory interface
 * @export
 */
export const SentMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SentMessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sentMessagesControllerExportTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.sentMessagesControllerExportTransaction(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {'SMS' | 'Email'} [type] 
         * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
         * @param {number} [customerId] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sentMessagesControllerFindAll(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: any): AxiosPromise<SentMessagesControllerFindAll200Response> {
            return localVarFp.sentMessagesControllerFindAll(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SentMessagesApi - object-oriented interface
 * @export
 * @class SentMessagesApi
 * @extends {BaseAPI}
 */
export class SentMessagesApi extends BaseAPI {
    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {'SMS' | 'Email'} [type] 
     * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
     * @param {number} [customerId] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SentMessagesApi
     */
    public sentMessagesControllerExportTransaction(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: AxiosRequestConfig) {
        return SentMessagesApiFp(this.configuration).sentMessagesControllerExportTransaction(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {'SMS' | 'Email'} [type] 
     * @param {'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance'} [category] 
     * @param {number} [customerId] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SentMessagesApi
     */
    public sentMessagesControllerFindAll(page: number, size?: number, sort?: string, fullTextSearch?: string, from?: string, to?: string, type?: 'SMS' | 'Email', category?: 'customerConfirmationTemplate' | 'customerReminderTemplate' | 'customerCancelTemplate' | 'technicianNotificationTemplate' | 'technicianReminderTemplate' | 'customerRewardBalance', customerId?: number, salonId?: any, options?: AxiosRequestConfig) {
        return SentMessagesApiFp(this.configuration).sentMessagesControllerFindAll(page, size, sort, fullTextSearch, from, to, type, category, customerId, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerCreateService: async (createServiceDTO: CreateServiceDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceDTO' is not null or undefined
            assertParamExists('serviceControllerCreateService', 'createServiceDTO', createServiceDTO)
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateServiceItemDTO} createServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerCreateServiceItem: async (createServiceItemDTO: CreateServiceItemDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceItemDTO' is not null or undefined
            assertParamExists('serviceControllerCreateServiceItem', 'createServiceItemDTO', createServiceItemDTO)
            const localVarPath = `/services/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceItemDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerDeleteService: async (serviceId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('serviceControllerDeleteService', 'serviceId', serviceId)
            const localVarPath = `/services/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerDeleteServiceItem: async (serviceItemId: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceItemId' is not null or undefined
            assertParamExists('serviceControllerDeleteServiceItem', 'serviceItemId', serviceItemId)
            const localVarPath = `/services/item/{serviceItemId}`
                .replace(`{${"serviceItemId"}}`, encodeURIComponent(String(serviceItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerEditService: async (serviceId: string, updateServiceDTO: UpdateServiceDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('serviceControllerEditService', 'serviceId', serviceId)
            // verify required parameter 'updateServiceDTO' is not null or undefined
            assertParamExists('serviceControllerEditService', 'updateServiceDTO', updateServiceDTO)
            const localVarPath = `/services/{serviceId}`
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {UpdateServiceItemDTO} updateServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerEditServiceItem: async (serviceItemId: string, updateServiceItemDTO: UpdateServiceItemDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceItemId' is not null or undefined
            assertParamExists('serviceControllerEditServiceItem', 'serviceItemId', serviceItemId)
            // verify required parameter 'updateServiceItemDTO' is not null or undefined
            assertParamExists('serviceControllerEditServiceItem', 'updateServiceItemDTO', updateServiceItemDTO)
            const localVarPath = `/services/item/{serviceItemId}`
                .replace(`{${"serviceItemId"}}`, encodeURIComponent(String(serviceItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceItemDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerExportService: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerExportTemplate: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGet: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('serviceControllerGet', 'page', page)
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetBySalon: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('serviceControllerGetBySalon', 'page', page)
            const localVarPath = `/services/by-salon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [isService] 
         * @param {number} [isMerchandise] 
         * @param {number} [salonId] 
         * @param {boolean} [isCheckinOnline] 
         * @param {any} [salonId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetServiceItems: async (page: number, size?: number, sort?: string, fullTextSearch?: string, isService?: number, isMerchandise?: number, salonId?: number, isCheckinOnline?: boolean, salonId2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('serviceControllerGetServiceItems', 'page', page)
            const localVarPath = `/services/service-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (isService !== undefined) {
                localVarQueryParameter['isService'] = isService;
            }

            if (isMerchandise !== undefined) {
                localVarQueryParameter['isMerchandise'] = isMerchandise;
            }

            if (salonId !== undefined) {
                localVarQueryParameter['salonId'] = salonId;
            }

            if (isCheckinOnline !== undefined) {
                localVarQueryParameter['isCheckinOnline'] = isCheckinOnline;
            }

            if (salonId2 != null) {
                localVarHeaderParameter['salonId'] = typeof salonId2 === 'string' 
                    ? salonId2 
                    : JSON.stringify(salonId2);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('serviceControllerUploadFile', 'file', file)
            const localVarPath = `/services/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerCreateService(createServiceDTO: CreateServiceDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerCreateService(createServiceDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateServiceItemDTO} createServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerCreateServiceItem(createServiceItemDTO: CreateServiceItemDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerCreateServiceItem(createServiceItemDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerDeleteService(serviceId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerDeleteService(serviceId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerDeleteServiceItem(serviceItemId: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerDeleteServiceItem(serviceItemId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerEditService(serviceId: string, updateServiceDTO: UpdateServiceDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerEditService(serviceId, updateServiceDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {UpdateServiceItemDTO} updateServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerEditServiceItem(serviceItemId: string, updateServiceItemDTO: UpdateServiceItemDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerEditServiceItem(serviceItemId, updateServiceItemDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerExportService(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerExportService(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerExportTemplate(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerGet(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerGetBySalon(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerGetBySalon(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [isService] 
         * @param {number} [isMerchandise] 
         * @param {number} [salonId] 
         * @param {boolean} [isCheckinOnline] 
         * @param {any} [salonId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerGetServiceItems(page: number, size?: number, sort?: string, fullTextSearch?: string, isService?: number, isMerchandise?: number, salonId?: number, isCheckinOnline?: boolean, salonId2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceControllerGetServiceItems200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerGetServiceItems(page, size, sort, fullTextSearch, isService, isMerchandise, salonId, isCheckinOnline, salonId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerCreateService(createServiceDTO: CreateServiceDTO, salonId?: any, options?: any): AxiosPromise<Service> {
            return localVarFp.serviceControllerCreateService(createServiceDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateServiceItemDTO} createServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerCreateServiceItem(createServiceItemDTO: CreateServiceItemDTO, salonId?: any, options?: any): AxiosPromise<ServiceItems> {
            return localVarFp.serviceControllerCreateServiceItem(createServiceItemDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerDeleteService(serviceId: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerDeleteService(serviceId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerDeleteServiceItem(serviceItemId: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerDeleteServiceItem(serviceItemId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceId 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerEditService(serviceId: string, updateServiceDTO: UpdateServiceDTO, salonId?: any, options?: any): AxiosPromise<Service> {
            return localVarFp.serviceControllerEditService(serviceId, updateServiceDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceItemId 
         * @param {UpdateServiceItemDTO} updateServiceItemDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerEditServiceItem(serviceItemId: string, updateServiceItemDTO: UpdateServiceItemDTO, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.serviceControllerEditServiceItem(serviceItemId, updateServiceItemDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerExportService(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerExportService(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerExportTemplate(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerExportTemplate(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<ServiceControllerGet200Response> {
            return localVarFp.serviceControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetBySalon(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<ServiceControllerGet200Response> {
            return localVarFp.serviceControllerGetBySalon(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [isService] 
         * @param {number} [isMerchandise] 
         * @param {number} [salonId] 
         * @param {boolean} [isCheckinOnline] 
         * @param {any} [salonId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerGetServiceItems(page: number, size?: number, sort?: string, fullTextSearch?: string, isService?: number, isMerchandise?: number, salonId?: number, isCheckinOnline?: boolean, salonId2?: any, options?: any): AxiosPromise<ServiceControllerGetServiceItems200Response> {
            return localVarFp.serviceControllerGetServiceItems(page, size, sort, fullTextSearch, isService, isMerchandise, salonId, isCheckinOnline, salonId2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.serviceControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * 
     * @param {CreateServiceDTO} createServiceDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerCreateService(createServiceDTO: CreateServiceDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerCreateService(createServiceDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateServiceItemDTO} createServiceItemDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerCreateServiceItem(createServiceItemDTO: CreateServiceItemDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerCreateServiceItem(createServiceItemDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerDeleteService(serviceId: string, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerDeleteService(serviceId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceItemId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerDeleteServiceItem(serviceItemId: string, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerDeleteServiceItem(serviceItemId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceId 
     * @param {UpdateServiceDTO} updateServiceDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerEditService(serviceId: string, updateServiceDTO: UpdateServiceDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerEditService(serviceId, updateServiceDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceItemId 
     * @param {UpdateServiceItemDTO} updateServiceItemDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerEditServiceItem(serviceItemId: string, updateServiceItemDTO: UpdateServiceItemDTO, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerEditServiceItem(serviceItemId, updateServiceItemDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerExportService(salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerExportService(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerExportTemplate(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerGet(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerGet(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerGetBySalon(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerGetBySalon(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {number} [isService] 
     * @param {number} [isMerchandise] 
     * @param {number} [salonId] 
     * @param {boolean} [isCheckinOnline] 
     * @param {any} [salonId2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerGetServiceItems(page: number, size?: number, sort?: string, fullTextSearch?: string, isService?: number, isMerchandise?: number, salonId?: number, isCheckinOnline?: boolean, salonId2?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerGetServiceItems(page, size, sort, fullTextSearch, isService, isMerchandise, salonId, isCheckinOnline, salonId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public serviceControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).serviceControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateAuthorizationManagementDto} updateAuthorizationManagementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerAuthorization: async (updateAuthorizationManagementDto: UpdateAuthorizationManagementDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAuthorizationManagementDto' is not null or undefined
            assertParamExists('settingControllerAuthorization', 'updateAuthorizationManagementDto', updateAuthorizationManagementDto)
            const localVarPath = `/settings/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuthorizationManagementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGet: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGetTurn: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/turn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManuallyPurgeDateDto} manuallyPurgeDateDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerPurgeData: async (manuallyPurgeDateDto: ManuallyPurgeDateDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manuallyPurgeDateDto' is not null or undefined
            assertParamExists('settingControllerPurgeData', 'manuallyPurgeDateDto', manuallyPurgeDateDto)
            const localVarPath = `/settings/data/purge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manuallyPurgeDateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBillingAndPaymentSettingDto} updateBillingAndPaymentSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateBillingAndPaymentSetting: async (updateBillingAndPaymentSettingDto: UpdateBillingAndPaymentSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBillingAndPaymentSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateBillingAndPaymentSetting', 'updateBillingAndPaymentSettingDto', updateBillingAndPaymentSettingDto)
            const localVarPath = `/settings/billing-and-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBillingAndPaymentSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBusinessSettingDto} updateBusinessSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateBusiness: async (updateBusinessSettingDto: UpdateBusinessSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBusinessSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateBusiness', 'updateBusinessSettingDto', updateBusinessSettingDto)
            const localVarPath = `/settings/business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBusinessSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCheckinSettingDto} updateCheckinSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCheckin: async (updateCheckinSettingDto: UpdateCheckinSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCheckinSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateCheckin', 'updateCheckinSettingDto', updateCheckinSettingDto)
            const localVarPath = `/settings/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckinSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCheckoutSettingDto} updateCheckoutSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCheckout: async (updateCheckoutSettingDto: UpdateCheckoutSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCheckoutSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateCheckout', 'updateCheckoutSettingDto', updateCheckoutSettingDto)
            const localVarPath = `/settings/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCheckoutSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCustomerRateSettingDto} updateCustomerRateSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCustomerRate: async (updateCustomerRateSettingDto: UpdateCustomerRateSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomerRateSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateCustomerRate', 'updateCustomerRateSettingDto', updateCustomerRateSettingDto)
            const localVarPath = `/settings/customer-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerRateSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDataSettingDto} updateDataSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateDataSetting: async (updateDataSettingDto: UpdateDataSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDataSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateDataSetting', 'updateDataSettingDto', updateDataSettingDto)
            const localVarPath = `/settings/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePrinterSettingsDto} updatePrinterSettingsDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdatePrinter: async (updatePrinterSettingsDto: UpdatePrinterSettingsDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePrinterSettingsDto' is not null or undefined
            assertParamExists('settingControllerUpdatePrinter', 'updatePrinterSettingsDto', updatePrinterSettingsDto)
            const localVarPath = `/settings/printer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrinterSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTerminalDeviceSettingDto} updateTerminalDeviceSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTerminalDeviceSetting: async (updateTerminalDeviceSettingDto: UpdateTerminalDeviceSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTerminalDeviceSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateTerminalDeviceSetting', 'updateTerminalDeviceSettingDto', updateTerminalDeviceSettingDto)
            const localVarPath = `/settings/terminal-device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTerminalDeviceSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTextMessageDto} updateTextMessageDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTextMessage: async (updateTextMessageDto: UpdateTextMessageDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTextMessageDto' is not null or undefined
            assertParamExists('settingControllerUpdateTextMessage', 'updateTextMessageDto', updateTextMessageDto)
            const localVarPath = `/settings/text-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTextMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTipSettingDto} updateTipSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTip: async (updateTipSettingDto: UpdateTipSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTipSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateTip', 'updateTipSettingDto', updateTipSettingDto)
            const localVarPath = `/settings/tip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTipSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTurnSettingDto} updateTurnSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTurn: async (updateTurnSettingDto: UpdateTurnSettingDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTurnSettingDto' is not null or undefined
            assertParamExists('settingControllerUpdateTurn', 'updateTurnSettingDto', updateTurnSettingDto)
            const localVarPath = `/settings/turn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTurnSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateAuthorizationManagementDto} updateAuthorizationManagementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerAuthorization(updateAuthorizationManagementDto: UpdateAuthorizationManagementDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingAuthorization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerAuthorization(updateAuthorizationManagementDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerGet(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerGet(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerGetTurn(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerGetTurn(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManuallyPurgeDateDto} manuallyPurgeDateDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerPurgeData(manuallyPurgeDateDto: ManuallyPurgeDateDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerPurgeData(manuallyPurgeDateDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBillingAndPaymentSettingDto} updateBillingAndPaymentSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto: UpdateBillingAndPaymentSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBusinessSettingDto} updateBusinessSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateBusiness(updateBusinessSettingDto: UpdateBusinessSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateBusiness(updateBusinessSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCheckinSettingDto} updateCheckinSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateCheckin(updateCheckinSettingDto: UpdateCheckinSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateCheckin(updateCheckinSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCheckoutSettingDto} updateCheckoutSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateCheckout(updateCheckoutSettingDto: UpdateCheckoutSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateCheckout(updateCheckoutSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCustomerRateSettingDto} updateCustomerRateSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateCustomerRate(updateCustomerRateSettingDto: UpdateCustomerRateSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateCustomerRate(updateCustomerRateSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDataSettingDto} updateDataSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateDataSetting(updateDataSettingDto: UpdateDataSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateDataSetting(updateDataSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePrinterSettingsDto} updatePrinterSettingsDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdatePrinter(updatePrinterSettingsDto: UpdatePrinterSettingsDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdatePrinter(updatePrinterSettingsDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTerminalDeviceSettingDto} updateTerminalDeviceSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto: UpdateTerminalDeviceSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTextMessageDto} updateTextMessageDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateTextMessage(updateTextMessageDto: UpdateTextMessageDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateTextMessage(updateTextMessageDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTipSettingDto} updateTipSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateTip(updateTipSettingDto: UpdateTipSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateTip(updateTipSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTurnSettingDto} updateTurnSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingControllerUpdateTurn(updateTurnSettingDto: UpdateTurnSettingDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerUpdateTurn(updateTurnSettingDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateAuthorizationManagementDto} updateAuthorizationManagementDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerAuthorization(updateAuthorizationManagementDto: UpdateAuthorizationManagementDto, salonId?: any, options?: any): AxiosPromise<Array<SettingAuthorization>> {
            return localVarFp.settingControllerAuthorization(updateAuthorizationManagementDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGet(salonId?: any, options?: any): AxiosPromise<SalonSetting> {
            return localVarFp.settingControllerGet(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerGetTurn(salonId?: any, options?: any): AxiosPromise<TurnSetting> {
            return localVarFp.settingControllerGetTurn(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManuallyPurgeDateDto} manuallyPurgeDateDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerPurgeData(manuallyPurgeDateDto: ManuallyPurgeDateDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.settingControllerPurgeData(manuallyPurgeDateDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBillingAndPaymentSettingDto} updateBillingAndPaymentSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto: UpdateBillingAndPaymentSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBusinessSettingDto} updateBusinessSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateBusiness(updateBusinessSettingDto: UpdateBusinessSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateBusiness(updateBusinessSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCheckinSettingDto} updateCheckinSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCheckin(updateCheckinSettingDto: UpdateCheckinSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateCheckin(updateCheckinSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCheckoutSettingDto} updateCheckoutSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCheckout(updateCheckoutSettingDto: UpdateCheckoutSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateCheckout(updateCheckoutSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCustomerRateSettingDto} updateCustomerRateSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateCustomerRate(updateCustomerRateSettingDto: UpdateCustomerRateSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateCustomerRate(updateCustomerRateSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDataSettingDto} updateDataSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateDataSetting(updateDataSettingDto: UpdateDataSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateDataSetting(updateDataSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePrinterSettingsDto} updatePrinterSettingsDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdatePrinter(updatePrinterSettingsDto: UpdatePrinterSettingsDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdatePrinter(updatePrinterSettingsDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTerminalDeviceSettingDto} updateTerminalDeviceSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto: UpdateTerminalDeviceSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTextMessageDto} updateTextMessageDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTextMessage(updateTextMessageDto: UpdateTextMessageDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateTextMessage(updateTextMessageDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTipSettingDto} updateTipSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTip(updateTipSettingDto: UpdateTipSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateTip(updateTipSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTurnSettingDto} updateTurnSettingDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingControllerUpdateTurn(updateTurnSettingDto: UpdateTurnSettingDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.settingControllerUpdateTurn(updateTurnSettingDto, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {UpdateAuthorizationManagementDto} updateAuthorizationManagementDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerAuthorization(updateAuthorizationManagementDto: UpdateAuthorizationManagementDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerAuthorization(updateAuthorizationManagementDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerGet(salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerGet(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerGetTurn(salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerGetTurn(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManuallyPurgeDateDto} manuallyPurgeDateDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerPurgeData(manuallyPurgeDateDto: ManuallyPurgeDateDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerPurgeData(manuallyPurgeDateDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBillingAndPaymentSettingDto} updateBillingAndPaymentSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto: UpdateBillingAndPaymentSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateBillingAndPaymentSetting(updateBillingAndPaymentSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBusinessSettingDto} updateBusinessSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateBusiness(updateBusinessSettingDto: UpdateBusinessSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateBusiness(updateBusinessSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCheckinSettingDto} updateCheckinSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateCheckin(updateCheckinSettingDto: UpdateCheckinSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateCheckin(updateCheckinSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCheckoutSettingDto} updateCheckoutSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateCheckout(updateCheckoutSettingDto: UpdateCheckoutSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateCheckout(updateCheckoutSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCustomerRateSettingDto} updateCustomerRateSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateCustomerRate(updateCustomerRateSettingDto: UpdateCustomerRateSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateCustomerRate(updateCustomerRateSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDataSettingDto} updateDataSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateDataSetting(updateDataSettingDto: UpdateDataSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateDataSetting(updateDataSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePrinterSettingsDto} updatePrinterSettingsDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdatePrinter(updatePrinterSettingsDto: UpdatePrinterSettingsDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdatePrinter(updatePrinterSettingsDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTerminalDeviceSettingDto} updateTerminalDeviceSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto: UpdateTerminalDeviceSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateTerminalDeviceSetting(updateTerminalDeviceSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTextMessageDto} updateTextMessageDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateTextMessage(updateTextMessageDto: UpdateTextMessageDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateTextMessage(updateTextMessageDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTipSettingDto} updateTipSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateTip(updateTipSettingDto: UpdateTipSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateTip(updateTipSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTurnSettingDto} updateTurnSettingDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public settingControllerUpdateTurn(updateTurnSettingDto: UpdateTurnSettingDto, salonId?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).settingControllerUpdateTurn(updateTurnSettingDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateChargeDto} createChargeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCharge: async (createChargeDto: CreateChargeDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChargeDto' is not null or undefined
            assertParamExists('stripeControllerCreateCharge', 'createChargeDto', createChargeDto)
            const localVarPath = `/stripe/charge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChargeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateChargeForTenantDto} createChargeForTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateChargeForTenant: async (createChargeForTenantDto: CreateChargeForTenantDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChargeForTenantDto' is not null or undefined
            assertParamExists('stripeControllerCreateChargeForTenant', 'createChargeForTenantDto', createChargeForTenantDto)
            const localVarPath = `/stripe/charge/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChargeForTenantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePaymentMethod: async (createPaymentMethodDto: CreatePaymentMethodDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentMethodDto' is not null or undefined
            assertParamExists('stripeControllerCreatePaymentMethod', 'createPaymentMethodDto', createPaymentMethodDto)
            const localVarPath = `/stripe/payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerDeletePaymentMethod: async (id: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stripeControllerDeletePaymentMethod', 'id', id)
            const localVarPath = `/stripe/payment-method/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetBillingInformation: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/billing-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetPaymentMethod: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerRefreshStripeAccount: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/account-refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSetupStripeConnectAccount: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/account-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBillingInformationDto} updateBillingInformationDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerUpdateBillingInformation: async (updateBillingInformationDto: UpdateBillingInformationDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBillingInformationDto' is not null or undefined
            assertParamExists('stripeControllerUpdateBillingInformation', 'updateBillingInformationDto', updateBillingInformationDto)
            const localVarPath = `/stripe/billing-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBillingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerUpdateDefaultPaymentMethod: async (id: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stripeControllerUpdateDefaultPaymentMethod', 'id', id)
            const localVarPath = `/stripe/payment-method/default/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateChargeDto} createChargeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreateCharge(createChargeDto: CreateChargeDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreateCharge(createChargeDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateChargeForTenantDto} createChargeForTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreateChargeForTenant(createChargeForTenantDto: CreateChargeForTenantDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreateChargeForTenant(createChargeForTenantDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreatePaymentMethod(createPaymentMethodDto: CreatePaymentMethodDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreatePaymentMethod(createPaymentMethodDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerDeletePaymentMethod(id: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerDeletePaymentMethod(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerGetBillingInformation(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerGetBillingInformation(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerGetPaymentMethod(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethodResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerGetPaymentMethod(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerRefreshStripeAccount(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerRefreshStripeAccount(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerSetupStripeConnectAccount(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerSetupStripeConnectAccount(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBillingInformationDto} updateBillingInformationDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerUpdateBillingInformation(updateBillingInformationDto: UpdateBillingInformationDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerUpdateBillingInformation(updateBillingInformationDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerUpdateDefaultPaymentMethod(id: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerUpdateDefaultPaymentMethod(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateChargeDto} createChargeDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCharge(createChargeDto: CreateChargeDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.stripeControllerCreateCharge(createChargeDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateChargeForTenantDto} createChargeForTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateChargeForTenant(createChargeForTenantDto: CreateChargeForTenantDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerCreateChargeForTenant(createChargeForTenantDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePaymentMethod(createPaymentMethodDto: CreatePaymentMethodDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerCreatePaymentMethod(createPaymentMethodDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerDeletePaymentMethod(id: string, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerDeletePaymentMethod(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetBillingInformation(salonId?: any, options?: any): AxiosPromise<BillingInformationResponse> {
            return localVarFp.stripeControllerGetBillingInformation(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetPaymentMethod(salonId?: any, options?: any): AxiosPromise<Array<PaymentMethodResponse>> {
            return localVarFp.stripeControllerGetPaymentMethod(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerRefreshStripeAccount(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.stripeControllerRefreshStripeAccount(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSetupStripeConnectAccount(salonId?: any, options?: any): AxiosPromise<ConnectAccountResponse> {
            return localVarFp.stripeControllerSetupStripeConnectAccount(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBillingInformationDto} updateBillingInformationDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerUpdateBillingInformation(updateBillingInformationDto: UpdateBillingInformationDto, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerUpdateBillingInformation(updateBillingInformationDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerUpdateDefaultPaymentMethod(id: string, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerUpdateDefaultPaymentMethod(id, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @param {CreateChargeDto} createChargeDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreateCharge(createChargeDto: CreateChargeDto, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreateCharge(createChargeDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateChargeForTenantDto} createChargeForTenantDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreateChargeForTenant(createChargeForTenantDto: CreateChargeForTenantDto, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreateChargeForTenant(createChargeForTenantDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePaymentMethodDto} createPaymentMethodDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreatePaymentMethod(createPaymentMethodDto: CreatePaymentMethodDto, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreatePaymentMethod(createPaymentMethodDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerDeletePaymentMethod(id: string, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerDeletePaymentMethod(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerGetBillingInformation(salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerGetBillingInformation(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerGetPaymentMethod(salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerGetPaymentMethod(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerRefreshStripeAccount(salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerRefreshStripeAccount(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerSetupStripeConnectAccount(salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerSetupStripeConnectAccount(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBillingInformationDto} updateBillingInformationDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerUpdateBillingInformation(updateBillingInformationDto: UpdateBillingInformationDto, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerUpdateBillingInformation(updateBillingInformationDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerUpdateDefaultPaymentMethod(id: string, salonId?: any, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerUpdateDefaultPaymentMethod(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckTenantLoginIdExistedDTO} checkTenantLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerCheckLoginIdExisted: async (checkTenantLoginIdExistedDTO: CheckTenantLoginIdExistedDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkTenantLoginIdExistedDTO' is not null or undefined
            assertParamExists('tenantControllerCheckLoginIdExisted', 'checkTenantLoginIdExistedDTO', checkTenantLoginIdExistedDTO)
            const localVarPath = `/tenants/check-loginId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkTenantLoginIdExistedDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTenantDto} createTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerCreateTenant: async (createTenantDto: CreateTenantDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTenantDto' is not null or undefined
            assertParamExists('tenantControllerCreateTenant', 'createTenantDto', createTenantDto)
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerDeleteTenant: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerDeleteTenant', 'id', id)
            const localVarPath = `/tenants/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerExportTemplate: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerExportTenant: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('tenantControllerExportTenant', 'page', page)
            const localVarPath = `/tenants/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetCurrentTenant: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/business/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetModuleForAdmin: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/admin/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymenById: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerGetPaymenById', 'id', id)
            const localVarPath = `/tenants/business/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListByTenant: async (id: number, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerGetPaymentListByTenant', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('tenantControllerGetPaymentListByTenant', 'page', page)
            const localVarPath = `/tenants/admin/{id}/payment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListForAdmin: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('tenantControllerGetPaymentListForAdmin', 'page', page)
            const localVarPath = `/tenants/admin/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [datePayment] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListForBusiness: async (page: number, size?: number, sort?: string, fullTextSearch?: string, datePayment?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('tenantControllerGetPaymentListForBusiness', 'page', page)
            const localVarPath = `/tenants/business/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (datePayment !== undefined) {
                localVarQueryParameter['datePayment'] = (datePayment as any instanceof Date) ?
                    (datePayment as any).toISOString() :
                    datePayment;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetTenantById: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerGetTenantById', 'id', id)
            const localVarPath = `/tenants/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetTenantList: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('tenantControllerGetTenantList', 'page', page)
            const localVarPath = `/tenants/admin/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetUnPaidListByTenant: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerGetUnPaidListByTenant', 'id', id)
            const localVarPath = `/tenants/admin/{id}/unpaid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PayTenantPaymentDto} payTenantPaymentDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerPayForRequestPayment: async (id: number, payTenantPaymentDto: PayTenantPaymentDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerPayForRequestPayment', 'id', id)
            // verify required parameter 'payTenantPaymentDto' is not null or undefined
            assertParamExists('tenantControllerPayForRequestPayment', 'payTenantPaymentDto', payTenantPaymentDto)
            const localVarPath = `/tenants/admin/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payTenantPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerRequest: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerRequest', 'id', id)
            const localVarPath = `/tenants/request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerRequestdsdd: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantControllerRequestdsdd', 'id', id)
            const localVarPath = `/tenants/requestalala`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('tenantControllerUploadFile', 'file', file)
            const localVarPath = `/tenants/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckTenantLoginIdExistedDTO} checkTenantLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO: CheckTenantLoginIdExistedDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTenantDto} createTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerCreateTenant(createTenantDto: CreateTenantDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerCreateTenant(createTenantDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerDeleteTenant(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerDeleteTenant(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerExportTemplate(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerExportTenant(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerExportTenant(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetCurrentTenant(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantControllerGetTenantList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetCurrentTenant(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetModuleForAdmin(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantModuleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetModuleForAdmin(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetPaymenById(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetPaymenById(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetPaymentListByTenant(id: number, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantControllerGetPaymentListForAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetPaymentListByTenant(id, page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetPaymentListForAdmin(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantControllerGetPaymentListForAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetPaymentListForAdmin(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [datePayment] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetPaymentListForBusiness(page: number, size?: number, sort?: string, fullTextSearch?: string, datePayment?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantControllerGetPaymentListForAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetPaymentListForBusiness(page, size, sort, fullTextSearch, datePayment, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetTenantById(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetTenantById(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetTenantList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantControllerGetTenantList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetTenantList(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerGetUnPaidListByTenant(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantRequestPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerGetUnPaidListByTenant(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PayTenantPaymentDto} payTenantPaymentDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerPayForRequestPayment(id: number, payTenantPaymentDto: PayTenantPaymentDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerPayForRequestPayment(id, payTenantPaymentDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerRequest(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRequestPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerRequest(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerRequestdsdd(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerRequestdsdd(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckTenantLoginIdExistedDTO} checkTenantLoginIdExistedDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO: CheckTenantLoginIdExistedDTO, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTenantDto} createTenantDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerCreateTenant(createTenantDto: CreateTenantDto, salonId?: any, options?: any): AxiosPromise<Tenant> {
            return localVarFp.tenantControllerCreateTenant(createTenantDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerDeleteTenant(id: number, salonId?: any, options?: any): AxiosPromise<Tenant> {
            return localVarFp.tenantControllerDeleteTenant(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerExportTemplate(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.tenantControllerExportTemplate(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerExportTenant(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.tenantControllerExportTenant(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetCurrentTenant(salonId?: any, options?: any): AxiosPromise<TenantControllerGetTenantList200Response> {
            return localVarFp.tenantControllerGetCurrentTenant(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetModuleForAdmin(salonId?: any, options?: any): AxiosPromise<Array<TenantModuleDTO>> {
            return localVarFp.tenantControllerGetModuleForAdmin(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymenById(id: number, salonId?: any, options?: any): AxiosPromise<TenantRequestPayment> {
            return localVarFp.tenantControllerGetPaymenById(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListByTenant(id: number, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<TenantControllerGetPaymentListForAdmin200Response> {
            return localVarFp.tenantControllerGetPaymentListByTenant(id, page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListForAdmin(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<TenantControllerGetPaymentListForAdmin200Response> {
            return localVarFp.tenantControllerGetPaymentListForAdmin(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {string} [datePayment] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetPaymentListForBusiness(page: number, size?: number, sort?: string, fullTextSearch?: string, datePayment?: string, salonId?: any, options?: any): AxiosPromise<TenantControllerGetPaymentListForAdmin200Response> {
            return localVarFp.tenantControllerGetPaymentListForBusiness(page, size, sort, fullTextSearch, datePayment, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetTenantById(id: number, salonId?: any, options?: any): AxiosPromise<TenantDetail> {
            return localVarFp.tenantControllerGetTenantById(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetTenantList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<TenantControllerGetTenantList200Response> {
            return localVarFp.tenantControllerGetTenantList(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerGetUnPaidListByTenant(id: number, salonId?: any, options?: any): AxiosPromise<Array<TenantRequestPayment>> {
            return localVarFp.tenantControllerGetUnPaidListByTenant(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PayTenantPaymentDto} payTenantPaymentDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerPayForRequestPayment(id: number, payTenantPaymentDto: PayTenantPaymentDto, salonId?: any, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.tenantControllerPayForRequestPayment(id, payTenantPaymentDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerRequest(id: number, salonId?: any, options?: any): AxiosPromise<TenantRequestPayment> {
            return localVarFp.tenantControllerRequest(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerRequestdsdd(id: number, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.tenantControllerRequestdsdd(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.tenantControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @param {CheckTenantLoginIdExistedDTO} checkTenantLoginIdExistedDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO: CheckTenantLoginIdExistedDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerCheckLoginIdExisted(checkTenantLoginIdExistedDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTenantDto} createTenantDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerCreateTenant(createTenantDto: CreateTenantDto, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerCreateTenant(createTenantDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerDeleteTenant(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerDeleteTenant(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerExportTemplate(salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerExportTemplate(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerExportTenant(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerExportTenant(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetCurrentTenant(salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetCurrentTenant(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetModuleForAdmin(salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetModuleForAdmin(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetPaymenById(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetPaymenById(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetPaymentListByTenant(id: number, page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetPaymentListByTenant(id, page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetPaymentListForAdmin(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetPaymentListForAdmin(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {string} [datePayment] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetPaymentListForBusiness(page: number, size?: number, sort?: string, fullTextSearch?: string, datePayment?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetPaymentListForBusiness(page, size, sort, fullTextSearch, datePayment, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetTenantById(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetTenantById(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetTenantList(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetTenantList(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerGetUnPaidListByTenant(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerGetUnPaidListByTenant(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PayTenantPaymentDto} payTenantPaymentDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerPayForRequestPayment(id: number, payTenantPaymentDto: PayTenantPaymentDto, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerPayForRequestPayment(id, payTenantPaymentDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerRequest(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerRequest(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerRequestdsdd(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerRequestdsdd(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public tenantControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return TenantsApiFp(this.configuration).tenantControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketApi - axios parameter creator
 * @export
 */
export const TicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TestDto} testDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCaculateForDay: async (testDto: TestDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testDto' is not null or undefined
            assertParamExists('ticketControllerCaculateForDay', 'testDto', testDto)
            const localVarPath = `/ticket/caculateForDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCheckGiftCardByNumber: async (giftCardNumber: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftCardNumber' is not null or undefined
            assertParamExists('ticketControllerCheckGiftCardByNumber', 'giftCardNumber', giftCardNumber)
            const localVarPath = `/ticket/gift-card/check-exist/{giftCardNumber}`
                .replace(`{${"giftCardNumber"}}`, encodeURIComponent(String(giftCardNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCloseout: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/closeout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TicketDTO} ticketDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreate: async (ticketDTO: TicketDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketDTO' is not null or undefined
            assertParamExists('ticketControllerCreate', 'ticketDTO', ticketDTO)
            const localVarPath = `/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashDrawerDTO} cashDrawerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateCashDrawer: async (cashDrawerDTO: CashDrawerDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashDrawerDTO' is not null or undefined
            assertParamExists('ticketControllerCreateCashDrawer', 'cashDrawerDTO', cashDrawerDTO)
            const localVarPath = `/ticket/cash-drawer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashDrawerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerTransactionDto} customerTransactionDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateCustomerTransaction: async (customerTransactionDto: CustomerTransactionDto, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerTransactionDto' is not null or undefined
            assertParamExists('ticketControllerCreateCustomerTransaction', 'customerTransactionDto', customerTransactionDto)
            const localVarPath = `/ticket/customer-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerDeleteTicketPending: async (id: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketControllerDeleteTicketPending', 'id', id)
            const localVarPath = `/ticket/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetCashDrawer: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('ticketControllerGetCashDrawer', 'page', page)
            const localVarPath = `/ticket/cash-drawer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetCloseout: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/closeout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {number} cusomterId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetGiftCardByCustomer: async (giftCardNumber: number, cusomterId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftCardNumber' is not null or undefined
            assertParamExists('ticketControllerGetGiftCardByCustomer', 'giftCardNumber', giftCardNumber)
            // verify required parameter 'cusomterId' is not null or undefined
            assertParamExists('ticketControllerGetGiftCardByCustomer', 'cusomterId', cusomterId)
            const localVarPath = `/ticket/gift-card/{cusomterId}`
                .replace(`{${"cusomterId"}}`, encodeURIComponent(String(cusomterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (giftCardNumber !== undefined) {
                localVarQueryParameter['giftCardNumber'] = giftCardNumber;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetLastTicketInToday: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/last-ticket-today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTechDailySummary: async (date?: string, technicianIds?: Array<number>, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/technician-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (technicianIds) {
                localVarQueryParameter['technicianIds'] = technicianIds;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTechnicianReceipt: async (ticketId: number, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('ticketControllerGetTechnicianReceipt', 'ticketId', ticketId)
            const localVarPath = `/ticket/technician-receipt/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchTransactionDTO} searchTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTicketCompleted: async (searchTransactionDTO: SearchTransactionDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTransactionDTO' is not null or undefined
            assertParamExists('ticketControllerGetTicketCompleted', 'searchTransactionDTO', searchTransactionDTO)
            const localVarPath = `/ticket/completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTicketPending: async (page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('ticketControllerGetTicketPending', 'page', page)
            const localVarPath = `/ticket/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTransaction: async (date?: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, technicianIds?: Array<number>, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (technicianIds) {
                localVarQueryParameter['technicianIds'] = technicianIds;
            }

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefundTransactionDTO} refundTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerRefundTransaction: async (refundTransactionDTO: RefundTransactionDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refundTransactionDTO' is not null or undefined
            assertParamExists('ticketControllerRefundTransaction', 'refundTransactionDTO', refundTransactionDTO)
            const localVarPath = `/ticket/transaction/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refundTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerTest: async (salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ticket/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TipForTechniciansDTO} tipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerTipForTechnician: async (tipForTechniciansDTO: TipForTechniciansDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipForTechniciansDTO' is not null or undefined
            assertParamExists('ticketControllerTipForTechnician', 'tipForTechniciansDTO', tipForTechniciansDTO)
            const localVarPath = `/ticket/technician/tip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tipForTechniciansDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTechniciansDTO} updateTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUpdateTechnician: async (id: number, updateTechniciansDTO: UpdateTechniciansDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketControllerUpdateTechnician', 'id', id)
            // verify required parameter 'updateTechniciansDTO' is not null or undefined
            assertParamExists('ticketControllerUpdateTechnician', 'updateTechniciansDTO', updateTechniciansDTO)
            const localVarPath = `/ticket/transaction/technician/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTechniciansDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTipForTechniciansDTO} updateTipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUpdateTipForTechnician: async (id: number, updateTipForTechniciansDTO: UpdateTipForTechniciansDTO, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ticketControllerUpdateTipForTechnician', 'id', id)
            // verify required parameter 'updateTipForTechniciansDTO' is not null or undefined
            assertParamExists('ticketControllerUpdateTipForTechnician', 'updateTipForTechniciansDTO', updateTipForTechniciansDTO)
            const localVarPath = `/ticket/transaction/tip/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTipForTechniciansDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUploadFile: async (file: File, salonId?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('ticketControllerUploadFile', 'file', file)
            const localVarPath = `/ticket/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (salonId != null) {
                localVarHeaderParameter['salonId'] = typeof salonId === 'string' 
                    ? salonId 
                    : JSON.stringify(salonId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketApi - functional programming interface
 * @export
 */
export const TicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TestDto} testDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCaculateForDay(testDto: TestDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCaculateForDay(testDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCheckGiftCardByNumber(giftCardNumber: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGiftCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCheckGiftCardByNumber(giftCardNumber, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCloseout(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCloseout(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TicketDTO} ticketDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCreate(ticketDTO: TicketDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCreate(ticketDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CashDrawerDTO} cashDrawerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCreateCashDrawer(cashDrawerDTO: CashDrawerDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCreateCashDrawer(cashDrawerDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerTransactionDto} customerTransactionDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCreateCustomerTransaction(customerTransactionDto: CustomerTransactionDto, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCreateCustomerTransaction(customerTransactionDto, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerDeleteTicketPending(id: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerDeleteTicketPending(id, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetCashDrawer(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashDrawerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetCashDrawer(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetCloseout(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloseoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetCloseout(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {number} cusomterId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetGiftCardByCustomer(giftCardNumber: number, cusomterId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetGiftCardByCustomer(giftCardNumber, cusomterId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetLastTicketInToday(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ticket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetLastTicketInToday(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetTechDailySummary(date?: string, technicianIds?: Array<number>, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnicianSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetTechDailySummary(date, technicianIds, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetTechnicianReceipt(ticketId: number, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnicianReceiptResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetTechnicianReceipt(ticketId, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchTransactionDTO} searchTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetTicketCompleted(searchTransactionDTO: SearchTransactionDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompletedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetTicketCompleted(searchTransactionDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetTicketPending(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControllerGetTicketPending200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetTicketPending(page, size, sort, fullTextSearch, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGetTransaction(date?: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, technicianIds?: Array<number>, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketControllerGetTransaction200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGetTransaction(date, page, size, sort, fullTextSearch, technicianIds, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefundTransactionDTO} refundTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerRefundTransaction(refundTransactionDTO: RefundTransactionDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRefund>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerRefundTransaction(refundTransactionDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerTest(salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerTest(salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TipForTechniciansDTO} tipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerTipForTechnician(tipForTechniciansDTO: TipForTechniciansDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerTipForTechnician(tipForTechniciansDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTechniciansDTO} updateTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerUpdateTechnician(id: number, updateTechniciansDTO: UpdateTechniciansDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Compensation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerUpdateTechnician(id, updateTechniciansDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTipForTechniciansDTO} updateTipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerUpdateTipForTechnician(id: number, updateTipForTechniciansDTO: UpdateTipForTechniciansDTO, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerUpdateTipForTechnician(id, updateTipForTechniciansDTO, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerUploadFile(file, salonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketApi - factory interface
 * @export
 */
export const TicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketApiFp(configuration)
    return {
        /**
         * 
         * @param {TestDto} testDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCaculateForDay(testDto: TestDto, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.ticketControllerCaculateForDay(testDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCheckGiftCardByNumber(giftCardNumber: number, salonId?: any, options?: any): AxiosPromise<CustomerGiftCard> {
            return localVarFp.ticketControllerCheckGiftCardByNumber(giftCardNumber, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCloseout(salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.ticketControllerCloseout(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketDTO} ticketDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreate(ticketDTO: TicketDTO, salonId?: any, options?: any): AxiosPromise<Ticket> {
            return localVarFp.ticketControllerCreate(ticketDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashDrawerDTO} cashDrawerDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateCashDrawer(cashDrawerDTO: CashDrawerDTO, salonId?: any, options?: any): AxiosPromise<object> {
            return localVarFp.ticketControllerCreateCashDrawer(cashDrawerDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerTransactionDto} customerTransactionDto 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateCustomerTransaction(customerTransactionDto: CustomerTransactionDto, salonId?: any, options?: any): AxiosPromise<CustomerTransaction> {
            return localVarFp.ticketControllerCreateCustomerTransaction(customerTransactionDto, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerDeleteTicketPending(id: number, salonId?: any, options?: any): AxiosPromise<string> {
            return localVarFp.ticketControllerDeleteTicketPending(id, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetCashDrawer(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<CashDrawerResponse> {
            return localVarFp.ticketControllerGetCashDrawer(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetCloseout(salonId?: any, options?: any): AxiosPromise<CloseoutResponse> {
            return localVarFp.ticketControllerGetCloseout(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} giftCardNumber 
         * @param {number} cusomterId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetGiftCardByCustomer(giftCardNumber: number, cusomterId: number, salonId?: any, options?: any): AxiosPromise<number> {
            return localVarFp.ticketControllerGetGiftCardByCustomer(giftCardNumber, cusomterId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetLastTicketInToday(salonId?: any, options?: any): AxiosPromise<Ticket> {
            return localVarFp.ticketControllerGetLastTicketInToday(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTechDailySummary(date?: string, technicianIds?: Array<number>, salonId?: any, options?: any): AxiosPromise<Array<TechnicianSummary>> {
            return localVarFp.ticketControllerGetTechDailySummary(date, technicianIds, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTechnicianReceipt(ticketId: number, salonId?: any, options?: any): AxiosPromise<Array<TechnicianReceiptResponse>> {
            return localVarFp.ticketControllerGetTechnicianReceipt(ticketId, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchTransactionDTO} searchTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTicketCompleted(searchTransactionDTO: SearchTransactionDTO, salonId?: any, options?: any): AxiosPromise<Array<CompletedResponse>> {
            return localVarFp.ticketControllerGetTicketCompleted(searchTransactionDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTicketPending(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: any): AxiosPromise<TicketControllerGetTicketPending200Response> {
            return localVarFp.ticketControllerGetTicketPending(page, size, sort, fullTextSearch, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {Array<number>} [technicianIds] 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGetTransaction(date?: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, technicianIds?: Array<number>, salonId?: any, options?: any): AxiosPromise<TicketControllerGetTransaction200Response> {
            return localVarFp.ticketControllerGetTransaction(date, page, size, sort, fullTextSearch, technicianIds, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefundTransactionDTO} refundTransactionDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerRefundTransaction(refundTransactionDTO: RefundTransactionDTO, salonId?: any, options?: any): AxiosPromise<TransactionRefund> {
            return localVarFp.ticketControllerRefundTransaction(refundTransactionDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerTest(salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.ticketControllerTest(salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TipForTechniciansDTO} tipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerTipForTechnician(tipForTechniciansDTO: TipForTechniciansDTO, salonId?: any, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.ticketControllerTipForTechnician(tipForTechniciansDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTechniciansDTO} updateTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUpdateTechnician(id: number, updateTechniciansDTO: UpdateTechniciansDTO, salonId?: any, options?: any): AxiosPromise<Array<Compensation>> {
            return localVarFp.ticketControllerUpdateTechnician(id, updateTechniciansDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTipForTechniciansDTO} updateTipForTechniciansDTO 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUpdateTipForTechnician(id: number, updateTipForTechniciansDTO: UpdateTipForTechniciansDTO, salonId?: any, options?: any): AxiosPromise<string> {
            return localVarFp.ticketControllerUpdateTipForTechnician(id, updateTipForTechniciansDTO, salonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {any} [salonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerUploadFile(file: File, salonId?: any, options?: any): AxiosPromise<void> {
            return localVarFp.ticketControllerUploadFile(file, salonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
export class TicketApi extends BaseAPI {
    /**
     * 
     * @param {TestDto} testDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCaculateForDay(testDto: TestDto, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCaculateForDay(testDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} giftCardNumber 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCheckGiftCardByNumber(giftCardNumber: number, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCheckGiftCardByNumber(giftCardNumber, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCloseout(salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCloseout(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketDTO} ticketDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCreate(ticketDTO: TicketDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCreate(ticketDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashDrawerDTO} cashDrawerDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCreateCashDrawer(cashDrawerDTO: CashDrawerDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCreateCashDrawer(cashDrawerDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerTransactionDto} customerTransactionDto 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerCreateCustomerTransaction(customerTransactionDto: CustomerTransactionDto, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerCreateCustomerTransaction(customerTransactionDto, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerDeleteTicketPending(id: number, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerDeleteTicketPending(id, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetCashDrawer(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetCashDrawer(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetCloseout(salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetCloseout(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} giftCardNumber 
     * @param {number} cusomterId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetGiftCardByCustomer(giftCardNumber: number, cusomterId: number, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetGiftCardByCustomer(giftCardNumber, cusomterId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetLastTicketInToday(salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetLastTicketInToday(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {Array<number>} [technicianIds] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetTechDailySummary(date?: string, technicianIds?: Array<number>, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetTechDailySummary(date, technicianIds, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetTechnicianReceipt(ticketId: number, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetTechnicianReceipt(ticketId, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchTransactionDTO} searchTransactionDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetTicketCompleted(searchTransactionDTO: SearchTransactionDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetTicketCompleted(searchTransactionDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetTicketPending(page: number, size?: number, sort?: string, fullTextSearch?: string, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetTicketPending(page, size, sort, fullTextSearch, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {Array<number>} [technicianIds] 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerGetTransaction(date?: string, page?: number, size?: number, sort?: string, fullTextSearch?: string, technicianIds?: Array<number>, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerGetTransaction(date, page, size, sort, fullTextSearch, technicianIds, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefundTransactionDTO} refundTransactionDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerRefundTransaction(refundTransactionDTO: RefundTransactionDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerRefundTransaction(refundTransactionDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerTest(salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerTest(salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TipForTechniciansDTO} tipForTechniciansDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerTipForTechnician(tipForTechniciansDTO: TipForTechniciansDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerTipForTechnician(tipForTechniciansDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTechniciansDTO} updateTechniciansDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerUpdateTechnician(id: number, updateTechniciansDTO: UpdateTechniciansDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerUpdateTechnician(id, updateTechniciansDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTipForTechniciansDTO} updateTipForTechniciansDTO 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerUpdateTipForTechnician(id: number, updateTipForTechniciansDTO: UpdateTipForTechniciansDTO, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerUpdateTipForTechnician(id, updateTipForTechniciansDTO, salonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {any} [salonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public ticketControllerUploadFile(file: File, salonId?: any, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).ticketControllerUploadFile(file, salonId, options).then((request) => request(this.axios, this.basePath));
    }
}


