import dayjs from 'dayjs';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { Appointment } from '../../apis/client-axios';
import { STATUSES } from '../../routes/tenant/appointment';
import {
  APPOINTMENT_TIME_FORMAT,
  DATE_TIME_WITH_MERIDIEM_FORMAT,
  formatDateTimeZoneByFormatString,
  formatDateWithMer,
  formatTimeHHMMa,
  toDayjsTimeZone,
} from '../../utils';
import { colorBackground, colorContainerHistory, colorInMappings, statusMappings } from '../../utils/constant';
import { SvgAppointmentStarIcon } from '../@svg/SvgAppointmentStarIcon';
import { SvgDropIcon } from '../@svg/SvgDropIcon';
import { SvgPickUpIcon } from '../@svg/SvgPickupIcon';
import ColWrap from '../ColWrap';
import CountdownInMinutes from '../CountdownInMinutes';
import RowWrap from '../RowWrap';
import './checkInCard.scss';

export interface ICheckInCardProps<T extends object> {
  status: string | undefined;
  timeStart: string | undefined;
  estimate: number;
  rate: number;
  customerName: string;
  serviceName: string;
  technicianName: string | undefined;
  expandedTagId?: number | null;
  idService?: number | null;
  listServiceName?: string;
  dataHistory?: Map<string, Appointment[]>;
  onClickDetail?: () => void;
  onClickHistory?: () => void;
  providedDrag?: DraggableProvided | undefined;
  containerClassName?: string;
  isCountDownMinutes?: boolean;
  data: Appointment;
}

function CheckInCard<T extends object>(props: ICheckInCardProps<T>) {
  const {
    status,
    timeStart,
    estimate,
    rate,
    customerName,
    serviceName,
    technicianName,
    expandedTagId,
    idService,
    dataHistory,
    onClickDetail,
    onClickHistory,
    providedDrag,
    containerClassName = '',
    isCountDownMinutes,
    data,
  } = props;
  const intl = useIntl();

  const convertColorBackground = (status: string) => {
    return colorBackground[status] || status;
  };
  const convertColorIn = (status: string) => {
    return colorInMappings[status] || status;
  };
  const convertContainerHistory = (status: string) => {
    return colorContainerHistory[status] || status;
  };
  const convertStatus = (status: string) => {
    return statusMappings[status] || status;
  };

  return (
    <div
      ref={providedDrag?.innerRef}
      {...providedDrag?.draggableProps}
      {...providedDrag?.dragHandleProps}
      style={{
        ...providedDrag?.draggableProps.style,
        backgroundColor: status ? convertColorBackground(status) : 'red',
      }}
      className={`salon__checkin-card-container ${containerClassName}`}
    >
      <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 5]} isWrap={true} styleFill="between">
        <ColWrap colProps={{ span: 24 }}>
          <div onClick={onClickDetail}>
            <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 5]} isWrap={true} styleFill="between">
              <ColWrap colProps={{ span: 24 }}>
                <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 2]} isWrap={true} styleFill="between">
                  <ColWrap colProps={{ span: 24, className: 'd-flex align-items-center' }}>
                    {/* {isCountDownMinutes ? (
                      <CountdownInMinutes endTime={dayjs(timeStart).add(estimate ?? 0, 'minute')} startTime={dayjs()} />
                    ) : timeStart ? (
                      <span className="font-size-12 font-weight-700 color-292F33">
                        {formatTimeHHMMa(timeStart)}
                        {' - '}
                        {formatTimeHHMMa(dayjs(timeStart).add(estimate ?? 0, 'minute'))}
                      </span>
                    ) : (
                      '--'
                    )} */}
                    {isCountDownMinutes ? (
                      <CountdownInMinutes
                        endTime={toDayjsTimeZone(timeStart).add(estimate ?? 0, 'minute')}
                        startTime={toDayjsTimeZone()}
                        estimate={estimate}
                        lastModifiedOnDate={data.lastModifiedOnDate}
                      />
                    ) : timeStart ? (
                      <div className="font-size-12 font-weight-700 color-292F33">
                        {formatDateTimeZoneByFormatString(APPOINTMENT_TIME_FORMAT, timeStart)}
                        {' - '}
                        {formatDateTimeZoneByFormatString(
                          APPOINTMENT_TIME_FORMAT,
                          dayjs(timeStart).add(estimate ?? 0, 'minute')
                        )}
                      </div>
                    ) : (
                      '--'
                    )}
                    <div className={'salon__checkin-card-rate' + (!!!rate ? ' zero-star' : '')}>
                      <div className="salon__checkin-card-rate-title">{rate ?? 0}</div>
                      <SvgAppointmentStarIcon wrapperClassName="salon__checkin-card-rate-icon" />
                    </div>
                  </ColWrap>
                  {/* <ColWrap>
                    <div className="salon__checkin-card-rate">
                      <span className="salon__checkin-card-rate-title">{rate ?? 0}</span>
                      <SvgAppointmentStarIcon />
                    </div>
                  </ColWrap> */}
                  <ColWrap colProps={{ span: 24 }}>
                    <RowWrap isAutoFillRow={true} isGutter={true} gutter={[0, 2]} isWrap={true} styleFill="between">
                      <ColWrap colProps={{ span: 24 }}>
                        <p className="salon__checkin-card-field-value">{customerName ?? '--'}</p>
                      </ColWrap>
                      <ColWrap colProps={{ span: 24 }}>
                        <p className="salon__checkin-card-field-value">{serviceName !== '' ? serviceName : '--'}</p>
                      </ColWrap>
                    </RowWrap>
                  </ColWrap>
                </RowWrap>
              </ColWrap>
              <ColWrap colProps={{ span: 24 }}>
                <RowWrap
                  isAutoFillRow={true}
                  isGutter={false}
                  isWrap={true}
                  styleFill="between"
                  rowClassName="salon__checkin-card-technician-container"
                  rowProps={{
                    style: {
                      background: status ? convertColorIn(status) : '#ffffff',
                    },
                  }}
                >
                  <ColWrap>
                    <p className="salon__checkin-card-technician-title">
                      {intl.formatMessage({ id: 'common.technician' })}
                    </p>
                  </ColWrap>
                  <ColWrap>
                    <p className="salon__checkin-card-technician-name">{technicianName ?? '--'}</p>
                  </ColWrap>
                </RowWrap>
              </ColWrap>
            </RowWrap>
          </div>
        </ColWrap>

        {onClickHistory ? (
          <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between">
            <ColWrap colProps={{ span: 24, className: 'm-b-4' }}>
              <RowWrap isAutoFillRow={true} isGutter={true} gutter={[2, 0]} isWrap={true} styleFill="start">
                <ColWrap>{STATUSES.find((statusItem) => statusItem.key === status)?.icon}</ColWrap>
                <ColWrap>
                  <p className="salon__checkin-card-status">{status ? convertStatus(status) : '--'}</p>
                </ColWrap>
              </RowWrap>
            </ColWrap>
            {expandedTagId === idService && (
              <div
                className="salon__checkin-card-history-container"
                style={{ backgroundColor: status ? convertContainerHistory(status) : '#ffffff' }}
              >
                {dataHistory &&
                  Array.from(dataHistory).map(([time, grpItem], grpIndex) => (
                    <div key={grpIndex} className="salon__checkin-card-history-container-detail">
                      <RowWrap
                        isAutoFillRow={true}
                        isGutter={false}
                        isWrap={true}
                        styleFill="center"
                        rowClassName="salon__checkin-card-history-detail"
                        rowProps={{
                          style: { backgroundColor: status ? convertColorIn(status) : '#ffffff' },
                        }}
                      >
                        <ColWrap colProps={{ span: 24, className: 'salon__checkin-card-history-header' }}>
                          <p className="salon__checkin-card-field-value salon__checkin-card-field-value-history-header">
                            {/* {formatDateWithMer(grpItem[0].completedTime)} */}
                            {!!grpItem[0].completedTime &&
                              formatDateTimeZoneByFormatString(
                                DATE_TIME_WITH_MERIDIEM_FORMAT,
                                grpItem[0].completedTime
                              )}
                          </p>
                        </ColWrap>
                        {grpItem.map((history: Appointment, index: number) => (
                          <ColWrap colProps={{ span: 24 }}>
                            <RowWrap isAutoFillRow={true} isGutter={false} isWrap={true} styleFill="between">
                              <ColWrap>
                                <p className="salon__checkin-card-field-value salon__checkin-card-field-value-history-detail font-weight-500">
                                  {history.technician?.name ?? '--'}
                                </p>
                              </ColWrap>
                              <ColWrap>
                                <p className="salon__checkin-card-field-value salon__checkin-card-field-value-history-detail font-weight-600">
                                  {history.services ? history.services.map((service) => service.name).join(', ') : '--'}
                                </p>
                              </ColWrap>
                            </RowWrap>
                          </ColWrap>
                        ))}
                      </RowWrap>
                    </div>
                  ))}
              </div>
            )}
            <ColWrap
              colProps={{
                span: 24,
                className: expandedTagId === idService ? 'salon__checkin-card-expand-button' : '',
                style: {
                  backgroundColor:
                    expandedTagId !== idService ? '' : status ? convertContainerHistory(status) : '#ffffff',
                },
              }}
            >
              <div className="cursor-pointer" onClick={onClickHistory}>
                <RowWrap isAutoFillRow={true} isGutter={true} gutter={[2, 0]} isWrap={true} styleFill="center">
                  <ColWrap>
                    <p className="salon__checkin-card-history">
                      {intl.formatMessage({ id: 'checkIn.checkin-history' })}
                    </p>
                  </ColWrap>
                  <ColWrap>
                    {expandedTagId === idService ? (
                      <p className="m-b-0 m-t-2-n">
                        <SvgPickUpIcon />
                      </p>
                    ) : (
                      <p className="m-b-0 m-t-2-n">
                        <SvgDropIcon />
                      </p>
                    )}
                  </ColWrap>
                </RowWrap>
              </div>
            </ColWrap>
          </RowWrap>
        ) : null}
      </RowWrap>
    </div>
  );
}
export default CheckInCard;
