import React from 'react';

export const SvgNextSlice = () => (
  <span>
    <svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/navigation/chevron_left_24px">
        <path
          id="icon/navigation/chevron_left_24px_2"
          d="M13.875 10.8952C14.163 10.5909 14.5541 10.4199 14.9619 10.4199C15.3697 10.4199 15.7607 10.5909 16.0487 10.8952L23.125 18.354C23.7262 18.9877 23.7262 20.0115 23.125 20.6452L16.0487 28.104C15.4475 28.7377 14.4762 28.7377 13.875 28.104C13.2737 27.4702 13.2737 26.4465 13.875 25.8127L19.8567 19.4915L13.875 13.1865C13.2737 12.5527 13.2892 11.5127 13.875 10.8952Z"
          fill="#F2F2F2"
        />
      </g>
    </svg>
  </span>
);

export default SvgNextSlice;
