import React from 'react';

export const SvgDoubleCheckIcon = () => (
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3357 0.996421L7.74901 7.58309L5.42901 5.26975C5.30446 5.14492 5.13536 5.07476 4.95901 5.07476C4.78267 5.07476 4.61356 5.14492 4.48901 5.26975C4.22901 5.52975 4.22901 5.94975 4.48901 6.20975L7.27568 8.99642C7.53568 9.25642 7.95568 9.25642 8.21568 8.99642L15.2757 1.94309C15.5357 1.68309 15.5357 1.26309 15.2757 1.00309H15.269C15.0157 0.736421 14.5957 0.736421 14.3357 0.996421ZM11.509 1.00309C11.3845 0.878252 11.2154 0.808097 11.039 0.808097C10.8627 0.808097 10.6936 0.878252 10.569 1.00309L6.80901 4.76309L7.74901 5.70309L11.509 1.93642C11.7623 1.68309 11.7623 1.25642 11.509 1.00309ZM3.50901 9.00309L0.722344 6.21642C0.462344 5.95642 0.462344 5.52975 0.722344 5.26975C0.846898 5.14492 1.016 5.07476 1.19234 5.07476C1.36869 5.07476 1.53779 5.14492 1.66234 5.26975L4.91568 8.53642L4.44901 9.00309C4.18901 9.26309 3.76901 9.26309 3.50901 9.00309Z"
        fill="white"
      />
    </svg>
  </span>
);
