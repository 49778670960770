export const weekDays: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const PER_PAGE: number = 10;
export const PAGE: number = 1;
export const QUERY_SERVICES: string = 'services';
export const QUERY_SERVICES_CHECKOUT: string = 'servicesCheckout';
export const QUERY_SALONS: string = 'salons';
export const QUERY_SALON_BY_BUSINESS_SETTING: string = 'salon_by_business_setting';
export const QUERY_SALONS_ALL: string = 'salons_all';
export const QUERY_EMPLOYEE_ME: string = 'employeeMe';
export const QUERY_EMPLOYEES: string = 'employees';
export const QUERY_CUSTOMER: string = 'customer';
export const QUERY_CUSTOMER_CHECK_IN: string = 'customerCheckIn';
export const QUERY_SALONS_DETAIL: string = 'salonsDetail';
export const QUERY_SALONS_DETAIL_SETTING_APPOINTMENT: string = 'salonsDetailSettingAppointment';
export const QUERY_CUSTOMER_COUNT: string = 'customerCount';
export const QUERY_CUSTOMER_DETAIL: string = 'customerDetail';
export const QUERY_CUSTOMER_HISTORY_APPOINTMENT: string = 'customerDetailHistoryAppointment';
export const QUERY_CUSTOMER_REWARD_INFORMATION: string = 'customerDetailRewardInformation';
export const QUERY_APPOINTMENTS: string = 'appointments';
export const QUERY_APPOINTMENTS_CHECKOUT: string = 'appointmentsCheckOut';
export const QUERY_APPOINTMENTS_CHECKOUT_POPUP: string = 'appointmentsCheckoutPopup';
export const QUERY_LIST_APPOINTMENTS: string = 'listAppointments';
export const QUERY_LIST_TECHNICIAN: string = 'technicians';
export const QUERY_LIST_TECHNICIAN_CHECKOUT: string = 'techniciansCheckout';
export const QUERY_LIST_TECHNICIAN_FILTER: string = 'techniciansFilter';
export const QUERY_SERVICE_ITEMS: string = 'serviceItems';
export const QUERY_PROMOTION: string = 'promotion';
export const QUERY_SETTING: string = 'setting';
export const QUERY_ANNOUNCEMENT: string = 'list-announcements';
export const QUERY_CARD_PAYMENT_SETTING: string = 'cardPaymentSetting';
export const QUERY_PROMOTION_CHECKOUT: string = 'promotionCheckout';
export const QUERY_LAST_TICKET_TODAY_CHECKOUT: string = 'lastTicketTodayCheckout';
export const QUERY_TICKET: string = 'ticket';
export const QUERY_TRANSACTIONS_CHECKOUT: string = 'transactionCheckout';
export const QUERY_GIFT_CARD_CHECKOUT: string = 'giftCardCheckout';
export const QUERY_TICKET_PENDING_CHECKOUT: string = 'ticketPendingCheckout';
export const QUERY_LIST_TIMESHEET: string = 'listTimeSheet';
export const QUERY_DETAIL_TIMESHEET: string = 'detailTimeSheet';
export const QUERY_DETAIL_COMPENSATION: string = 'detailCompensation';
export const QUERY_TECHNICIAN_SUMMARY: string = 'technicianSummary';
export const QUERY_PAYMENT_DATE: string = 'paymentDate';
export const QUERY_BILLING_INFORMATION: string = 'settingBillingInformation';
export const QUERY_PAYMENT_CARDS: string = 'settingPaymentCards';
export const QUERY_LIST_TECHNICIAN_COMPENSATION: string = 'techniciansCompensation';
export const ADJUST_DETAIL: string = 'adjustDetail';
export const QUERY_BILLS: string = 'settingBills';
export const QUERY_EXPENSE: string = 'expense';
export const QUERY_PERMISSION: string = 'permission';
export const QUERY_ROLE: string = 'role';
export const QUERY_BILLS_NOT_FILTER: string = 'settingBillsNotFilter';
export const QUERY_REPORT_VALUED_CUSTOMER: string = 'reportValuedCustomer';
export const QUERY_REPORT_VALUED_EMPLOYEE: string = 'reportValuedEmployee';
export const QUERY_REPORT_TRANSACTION: string = 'reportTransaction';
export const QUERY_REPORT_REVENUE: string = 'reportRevenue';
export const QUERY_REPORT_REVENUE_GRP: string = 'reportRevenueGrp';
export const QUERY_REPORT_RECENT_SALES: string = 'reportRecentSales';
export const QUERY_REPORT_DASHBOARD: string = 'reportDashboard';
export const QUERY_REPORT_SALES_BY_CATEGORY: string = 'reportSaleByCategory';
export const QUERY_REPORT_GIFT_CARD: string = 'reportGiftCard';
export const QUERY_SENT_MESSAGES: string = 'sentMessages';
export const QUERY_TENANT: string = 'tenant';
export const QUERY_TENANT_PAYMENT: string = 'tenantPayment';
export const QUERY_TENANT_DETAIL_PAYMENT: string = 'tenantDetailPayment';
export const QUERY_TENANTS_BUSINESS_PAYMENT: string = 'tenantsBusinessPayment';
export const QUERY_TENANTS_BUSINESS_PAYMENT_NOT_FILTER: string = 'tenantsBusinessPaymentNotFilter';
export const QUERY_SALON_BY_TENANTS: string = 'salon_by_tenants';
export const QUERY_TECHNICIAN_RECEIPT: string = 'technicianReceipt';
export const QUERY_TECHNICIAN_SKILLS: string = 'technicianSkills';
export const QUERY_CUSTOMER_REWARD_BALANCE: string = 'customerRewardBalance';

export const monthNamesId: string[] = [
  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
];

export const statusMappings: Record<string, string> = {
  Being_served: 'Being Serviced',
  Checked_in: 'Checked In',
  Completed: 'Completed',
  Confirmed: 'Confirmed',
  Canceled: 'Canceled',
  Waiting_Confirm: 'Waiting for confirm',
};

export const colorInMappings: Record<string, string> = {
  Waiting_Confirm: '#CF7CFC',
  Confirmed: '#EE7E68',
  Checked_in: '#FAF694',
  Being_served: '#99E3FF',
  Completed: '#B8F8B1',
  Canceled: '#C8CACC',
};
export const colorContainerHistory: Record<string, string> = {
  Waiting_Confirm: '#F8EBFF',
  Confirmed: '#FFCCC2',
  Checked_in: '#FEFDD6',
  Being_served: '#CCF4FF',
  Completed: '#DEFBD7',
  Canceled: '#F6F9FB',
};

export const colorBackground: Record<string, string> = {
  Waiting_Confirm: '#F4D3FE',
  Confirmed: '#F6C6AF',
  Checked_in: '#FCEAAA',
  Being_served: '#DCFFF9',
  Completed: '#EEFFDA',
  Canceled: '#DBDDDF',
};

export const API_STATUS = {
  SUCCESS: 'success',
  LOADING: 'loading',
  ERROR: 'error',
};

export const datePickerFormat = 'MMM DD, YYYY';

export const defaultManagerCode = '1379';
