import React, { useEffect } from 'react';
import { ConfigProvider, theme } from 'antd';
import { useSelector } from 'react-redux';
import AppLocale from './lngProvider';
import { IntlProvider } from 'react-intl';
import { RootState } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RoutesApp from './routes';
import { ToastContainer } from 'react-toastify';
import { customTheme } from './utils/ant-theme';

const App: React.FC = () => {
  const { locale, isDarkMode } = useSelector((state: RootState) => state.setting);
  const currentAppLocale = (AppLocale as any)[locale.locale];
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      // dispatch(updateWindowWidth(window.innerWidth));
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          ...customTheme,
        }}
        locale={currentAppLocale.antd}
      >
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <RoutesApp />
        </IntlProvider>
      </ConfigProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};

export default App;

// import React from 'react';
// import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, BlobProvider, pdf } from '@react-pdf/renderer';
// import { saveAs } from 'file-saver';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     backgroundColor: '#fff',
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Hello, this is a test PDF!</Text>
//       </View>
//     </Page>
//   </Document>
// );

// const App = () => {
//   const handleGenerateAndSavePDF = async () => {
//     const doc = MyDocument();
//     const blobPdf = await pdf(doc);
//     blobPdf.updateContainer(doc);
//     const result = await blobPdf.toBlob();

//     if (result) {
//       saveAs(result, 'example.pdf');
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleGenerateAndSavePDF}>Generate and Save PDF</button>
//     </div>
//   );
// };

// export default App;
