import React from 'react';

export const SvgAppointmentConfirmedIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M6.36596 11.1033L4.03263 8.77C3.90939 8.64524 3.74133 8.57502 3.56596 8.57502C3.3906 8.57502 3.22253 8.64524 3.0993 8.77C2.8393 9.03 2.8393 9.44334 3.0993 9.70334L5.89263 12.4967C6.15263 12.7567 6.57263 12.7567 6.83263 12.4967L13.8993 5.43667C14.1593 5.17667 14.1593 4.76334 13.8993 4.50334C13.7761 4.37857 13.608 4.30835 13.4326 4.30835C13.2573 4.30835 13.0892 4.37857 12.966 4.50334L6.36596 11.1033Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
