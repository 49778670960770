import { FormInstance } from 'antd/lib';
import KeyboardWrapper from '../../../../../components/KeyboardWrapper';
import '../style.scss';

export interface Props {
  form: FormInstance;
  inputName: string;
  keyboardRef: any;
}

function CashDrawerKeyBoard(props: Props) {
  const { form, inputName, keyboardRef } = props;
  const customLayoutKeyboard = {
    default: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'q w e r t y u i o p - {shift}',
      'a s d f g h j k l , . @',
      'z x c v b n m _ {space}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 + {backspace}',
      'Q W E R T Y U I O P - {shift}',
      'A S D F G H J K L , . @',
      'Z X C V B N M _ {space}',
    ],
  };
  return (
    <>
      <div className="salon__information min-height-50 p-t-0 p-b-0 bg-white">
        <div className="salon__step-one m-t-0">
          <div className="salon__form-keyboard m-t-10">
            <KeyboardWrapper
              onNext={() => {}}
              onFinish={() => {
                form.submit();
              }}
              form={form}
              maxLength={255}
              inputName={inputName}
              keyboardRef={keyboardRef}
              keyboardProps={{
                layout: customLayoutKeyboard,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CashDrawerKeyBoard;
