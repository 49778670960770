import { Checkbox, Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import './formCheckbox.scss';
import { CheckboxGroupProps } from 'antd/es/checkbox';

type Props = {
  name: string;
  label?: ReactNode;
  formItemProps?: FormItemProps;
  checkboxGroupProps?: CheckboxGroupProps;
};

export const FormCheckboxGroup: React.FC<Props> = ({ formItemProps, name, label, checkboxGroupProps }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      className={`salon__form-checkbox ${formItemProps?.className ?? ''}`}
    >
      <Checkbox.Group
        {...checkboxGroupProps}
        className={
          'salon__form-checkbox-group' + (checkboxGroupProps?.className ? ' ' + checkboxGroupProps?.className : '')
        }
      />
    </Form.Item>
  );
};
