import { useMutation, useQuery } from '@tanstack/react-query';
import { toPng } from 'html-to-image';
import { FC, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fetchApiPrint, settingApi, ticketApi } from '../../../../../apis';
import { SettingPrinter, SettingPrinterCloverPrinterEnum, Ticket } from '../../../../../apis/client-axios';
import { SvgPrintIcon } from '../../../../../components/@svg/SvgPrintIcon';
import NotificationError from '../../../../../components/HandleShowNotiError';
import TechnicianReceipt from '../../../../../components/Print/TechnicianReceipt';
import { StyledModal } from '../../../../../components/StyledModal';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { RootState } from '../../../../../store';
import { QUERY_SETTING, QUERY_TECHNICIAN_RECEIPT } from '../../../../../utils/constant';
import { useClover } from '../../../setting/terminalDeviceSetting/Clover/connection/hooks';
import { PrintType } from '../index';
import './style.scss';
import { SettingPrinterReceiptForEnum } from '../../../setting/printerSetting';
import jsPDF from 'jspdf';

interface PrintReceiptProps {
  open: boolean;
  onCancel: () => void;
  isLoading: Record<PrintType, boolean>;
  onPrintTicket: (type: PrintType, transactionTicket?: Ticket, isNotPrintBill?: boolean) => void;
  ticket?: Ticket;
  setIsLoading: (value: Record<PrintType, boolean>) => void;
}

const PrintReceipt: FC<PrintReceiptProps> = (props) => {
  const { open, onCancel, isLoading, onPrintTicket, ticket, setIsLoading } = props;

  const intl = useIntl();

  const { cloverDevice, onConnect } = useClover();

  const printTechnicianReceiptRef = useRef<HTMLDivElement>(null);

  const printResponse = useSelector((state: RootState) => state.clover.printResponse);

  const [settingPrinter, setSettingPrinter] = useState<SettingPrinter>();

  const { data: technicianReceiptResponse } = useQuery({
    queryKey: [QUERY_TECHNICIAN_RECEIPT],
    queryFn: () => ticketApi.ticketControllerGetTechnicianReceipt(ticket?.id || 0),
    enabled: !!ticket,
  });

  const { data: listSetting } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });

  const printJobMutation = useMutation((payload: FormData) => fetchApiPrint(payload), {
    onSuccess: () => {
      setIsLoading({
        LAST_TICKET: false,
        TRANSACTION: false,
        TECHNICIAN_RECEIPT: false,
        CUSTOMER_RECEIPT: false,
      });
    },
    onError: ({ response }) => {
      setIsLoading({
        LAST_TICKET: false,
        TRANSACTION: false,
        TECHNICIAN_RECEIPT: false,
        CUSTOMER_RECEIPT: false,
      });
      NotificationError({ contentNoti: 'Please connect printer!!' });
    },
  });

  useEffect(() => {
    if (printResponse && printResponse.getReason() === 'DONE') {
      if (!printResponse.getSuccess()) {
        NotificationError({ contentNoti: intl.formatMessage({ id: 'checkout.printLastTicket.failed' }) });
      } else {
        onCancel();
      }
    }
  }, [printResponse, intl]);

  useEffect(() => {
    if (!!listSetting?.data?.settingPrinter?.length) {
      const printerCompensation = listSetting?.data.settingPrinter.find((e) =>
        e.receiptFor.includes(SettingPrinterReceiptForEnum.CHECK_OUT)
      );

      setSettingPrinter(printerCompensation);
    }
  }, [listSetting]);

  const onPrintPdf = async () => {
    const cloneRef = printTechnicianReceiptRef.current;
    if (!cloneRef) return false;
    cloneRef.style.marginLeft = '10px';
    cloneRef.style.marginRight = '10px';
    const componentWidth = cloneRef.offsetWidth + 20;
    const componentHeight = cloneRef.clientHeight;

    const orientation = componentWidth >= componentHeight ? 'l' : 'p';

    // const convertComponentToPng = await toPng(techDailySummaryRef.current);
    const scale = 153 / componentWidth;

    const pdf = new jsPDF({
      orientation,
      unit: 'px',
      format: [153, 153 * 3.7],
    });

    // pdf.internal.pageSize.width = 153;

    // pdf.internal.pageSize.height = componentHeight * scale;

    await pdf.html(cloneRef, { html2canvas: { scale: scale }, x: 0, y: 0, autoPaging: 'text' });

    const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

    let newUploadFile = new FormData();
    newUploadFile.append('file', blob);

    printJobMutation.mutate(newUploadFile);
  };

  const handlePrintReceipt = (type: PrintType) => {
    if (type === PrintType.CUSTOMER_RECEIPT) {
      onPrintTicket(type, ticket);
    } else if (type === PrintType.TECHNICIAN_RECEIPT) {
      onPrintTicket(type, undefined, true);

      if (!settingPrinter) return false;

      if (settingPrinter?.cloverPrinter === SettingPrinterCloverPrinterEnum.PrinterConnectedViaUsb) {
        setTimeout(() => {
          onPrintPdf();
        }, 900);
        return true;
      }

      onConnect();

      setTimeout(async () => {
        try {
          if (printTechnicianReceiptRef.current) {
            const convertComponentToPng = await toPng(printTechnicianReceiptRef.current);

            if (convertComponentToPng) {
              cloverDevice.performPrint({
                base64Image: convertComponentToPng,
              });

              setTimeout(() => {
                cloverDevice.performPrint({
                  base64Image: convertComponentToPng,
                });
              }, 300);
            }
          }
        } catch (error) {
          throw error;
        }
      }, 900);
    }
  };

  const printButtons = [
    {
      title: intl.formatMessage({ id: 'checkout.printLastTicket.btn.modal.technicianReceipt' }),
      icon: <SvgPrintIcon />,
      onClick: () => handlePrintReceipt(PrintType.TECHNICIAN_RECEIPT),
      disabled: isLoading.TECHNICIAN_RECEIPT,
    },
    {
      title: intl.formatMessage({ id: 'checkout.printLastTicket.btn.modal.customerReceipt' }),
      icon: <SvgPrintIcon />,
      onClick: () => handlePrintReceipt(PrintType.CUSTOMER_RECEIPT),
      disabled: isLoading.CUSTOMER_RECEIPT,
    },
  ];

  return (
    <>
      <StyledModal
        isOpen={open}
        onCancel={onCancel}
        modalProps={{
          title: (
            <span className="font-size-20 font-weight-700 d-inline-block">
              {intl.formatMessage({ id: 'checkout.printLastTicket.btn.modal.title' })}
            </span>
          ),
          footer: null,
          width: 700,
          className: 'salon__checkout-print-receipt-modal',
          zIndex: 100000,
        }}
      >
        <div className="d-flex align-items-center justify-content-center w-full gap-31 p-b-54">
          {printButtons.map((button, index) => (
            <ButtonStyled
              key={index}
              isPrimary
              buttonProps={{
                className: 'width-266 height-42',
                onClick: button.onClick,
                disabled: button.disabled,
              }}
              content={
                <div className="d-flex justify-content-center align-items-center gap-9">
                  {button.icon}
                  <span className="font-size-16 font-weight-700">{button.title}</span>
                </div>
              }
            />
          ))}
        </div>
      </StyledModal>

      {technicianReceiptResponse?.data &&
        createPortal(
          <div className="width-500 d-flex justify-content-center align-items-center position-absolute top-0 left-500-n">
            <TechnicianReceipt
              receipts={technicianReceiptResponse.data}
              ticket={ticket}
              ref={printTechnicianReceiptRef}
            />
          </div>,
          document.body
        )}
    </>
  );
};

export default PrintReceipt;
