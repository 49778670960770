import { FormInstance } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import ColWrap from '../../../../../components/ColWrap';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrap from '../../../../../components/FormWrap';
import RowWrap from '../../../../../components/RowWrap';

interface IProps {
  disableInput: boolean;
  form: FormInstance<FormDataPax>;
}

export interface FormDataPax {
  paxDeviceAddress?: string;
}

const n = (key: keyof FormDataPax) => key;

export const PaxSetting: React.FC<IProps> = (props) => {
  const { disableInput, form } = props;

  const intl = useIntl();

  return (
    <>
      <FormWrap form={form} name="paxSetting" layout="vertical">
        <RowWrap isAutoFillRow={true} isGutter={true} gutter={[20, 20]} isWrap={true} styleFill="between">
          <ColWrap colProps={{ span: 24 }}>
            <div className="m-b-8 ">
              <span className={`${!disableInput ? '' : 'color-B4B6B8'} font-weight-400 font-size-16`}>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.paxDeviceAddress' })}
              </span>
              <br />
              <span className="font-weight-400 font-size-12 color-B4B6B8">
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverDeviceAddress.sub' })}
                <span className={`${!disableInput ? 'color-0090FF' : ''}`}>
                  {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.cloverDeviceAddress.sub-2' })}
                </span>
              </span>
            </div>
            <FormInput
              name={n('paxDeviceAddress')}
              inputProps={{
                disabled: disableInput,
                placeholder: intl.formatMessage({ id: 'checkout.terminalDeviceSetting.placeholder.pax.poynt' }),
              }}
              formItemProps={{
                className: 'm-b-0',
              }}
            />
          </ColWrap>
        </RowWrap>
      </FormWrap>
    </>
  );
};
