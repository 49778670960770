import { FC } from 'react';
import { EventProps } from 'react-big-calendar';
import { useIntl } from 'react-intl';
import { SvgAppointmentStarIcon } from '../../../../../components/@svg/SvgAppointmentStarIcon';
import { formatTimeHHMMa } from '../../../../../utils';
import { STATUSES } from '../../index';
import { AppointmentEvent } from '../../models';

interface ScheduleProps {
  eventProps?: EventProps<AppointmentEvent>;
}

const Schedule: FC<ScheduleProps> = ({ eventProps }) => {
  const intl = useIntl();

  return (
    <div
      id={`event_${eventProps?.event.id}`}
      className={`salon__appointment-calendar-schedule ${
        eventProps?.event.isBlockTime ? 'salon__appointment-calendar-schedule-reverse' : ''
      }`}
    >
      <div className="salon__appointment-calendar-schedule-wrapper">
        <p className="salon__appointment-calendar-schedule-time">
          <span className={`${eventProps?.event.isBlockTime ? 'font-weight-500' : 'font-weight-700'}`}>
            {formatTimeHHMMa(eventProps?.event?.start)}
          </span>
          <span className={`${eventProps?.event.isBlockTime ? 'font-weight-500' : 'font-weight-700'}`}>-</span>
          <span className={`${eventProps?.event.isBlockTime ? 'font-weight-500' : 'font-weight-700'}`}>
            {formatTimeHHMMa(eventProps?.event?.end)}
          </span>
        </p>
        {!eventProps?.event.isBlockTime && (
          <div
            className={
              'font-weight-700 salon__appointment-calendar-schedule-star' +
              (!!!eventProps?.event?.customerRating ? ' zero-star' : '')
            }
          >
            {/* <span>{eventProps?.event.resource?.star}</span> */}
            <span>{eventProps?.event?.customerRating || 0}</span>
            <SvgAppointmentStarIcon />
          </div>
        )}
      </div>
      {eventProps?.event.resource?.customer.name && (
        <p className="font-weight-500 truncate salon__appointment-calendar-schedule-name">
          {eventProps?.event.resource?.customer.name}
        </p>
      )}
      {eventProps?.event.title && (
        <p
          className={`${
            !eventProps?.event.isBlockTime ? 'font-weight-500' : 'font-weight-700'
          } truncate salon__appointment-calendar-schedule-title`}
        >
          {eventProps?.event.title}
        </p>
      )}
      {eventProps?.event?.status && (
        <div className="salon__appointment-calendar-schedule-status-wrapper">
          <p className="font-weight-700 salon__appointment-calendar-schedule-status">
            {intl.formatMessage({
              id: STATUSES.find((status) => status.key === eventProps?.event?.status)?.title,
            })}
          </p>
          {STATUSES.find((status) => status.key === eventProps?.event.resource?.status)?.icon}
        </div>
      )}
    </div>
  );
};

export default Schedule;
