import React from 'react';

export const SvgClockIcon = () => (
  <span>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90125 1.5C4.14125 1.5 1.90625 3.74 1.90625 6.5C1.90625 9.26 4.14125 11.5 6.90125 11.5C9.66625 11.5 11.9062 9.26 11.9062 6.5C11.9062 3.74 9.66625 1.5 6.90125 1.5ZM6.90772 10.5C4.69772 10.5 2.90772 8.70997 2.90772 6.49997C2.90772 4.28997 4.69772 2.49997 6.90772 2.49997C9.11772 2.49997 10.9077 4.28997 10.9077 6.49997C10.9077 8.70997 9.11772 10.5 6.90772 10.5ZM6.76601 4.00003H6.79601C6.99601 4.00003 7.15601 4.16003 7.15601 4.36003V6.63003L9.09101 7.78003C9.26601 7.88003 9.32101 8.10503 9.21601 8.27503C9.11601 8.44503 8.89601 8.49503 8.72601 8.39503L6.65101 7.15003C6.49601 7.06003 6.40601 6.89503 6.40601 6.72003V4.36003C6.40601 4.16003 6.56601 4.00003 6.76601 4.00003Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
