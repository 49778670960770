import React from 'react';

export const SvgPrevIcon = () => (
  <span>
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.49828 1.06254L1.55828 3.00254L3.49828 4.94254C3.69328 5.13754 3.69328 5.45254 3.49828 5.64754C3.30328 5.84254 2.98828 5.84254 2.79328 5.64754L0.498281 3.35254C0.303281 3.15754 0.303281 2.84254 0.498281 2.64754L2.79328 0.352542C2.8867 0.258915 3.01352 0.206299 3.14578 0.206299C3.27804 0.206299 3.40487 0.258915 3.49828 0.352542C3.68828 0.547542 3.69328 0.867542 3.49828 1.06254Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
