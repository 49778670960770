import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Result, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ErrorResponse, settingApi } from '../../../../apis';
import { SettingPrinterCloverPrinterEnum, UpdatePrinterSettingsDto } from '../../../../apis/client-axios';
import { SvgPlusIconSmall } from '../../../../components/@svg/SvgPlusIconSmall';
import { SvgToolTipIcon } from '../../../../components/@svg/SvgToolTipIcon';
import ColWrap from '../../../../components/ColWrap';
import { FormInput } from '../../../../components/Form/FormInput';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import { FormInputNumberFormat } from '../../../../components/Form/FormInputNumberFormat';
import { FormSelect } from '../../../../components/Form/FormSelect';
import FormWrap from '../../../../components/FormWrap';
import NotificationSuccess from '../../../../components/HandleShowNotiSuccess';
import PageContainer from '../../../../components/PageContainer';
import RowWrap from '../../../../components/RowWrap';
import StyledHeader from '../../../../components/StyledHeader';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { ButtonCirclePrimary } from '../../../../components/buttons/buttonCircle/ButtonCirclePrimary';
import { RootState } from '../../../../store';
import { getStorageByName, regexCheckIpV4 } from '../../../../utils';
import { QUERY_SETTING } from '../../../../utils/constant';
import { Permission } from '../../../../utils/permission';
import './printerSetting.scss';
import { PrinterChild } from './printerChild';

interface FormData {
  cloverPrinter: string;
  printerIPAddress: string;
  printerDeviceIP: string;
  charactersPerLine: string;
  lineSpacing: string;
  printerName: string;
  paperWidth: number;
  receiptFor: string[];
}

export enum SettingPrinterReceiptForEnum {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  COMPENSATION = 'COMPENSATION',
}

const PrinterSetting = () => {
  const intl = useIntl();
  const salonPermission = useSelector((state: RootState) => state.auth.salonPermission);
  const isHavingPermission = salonPermission?.includes(Permission.Settings.Printer);
  const n = (key: keyof FormData) => key;
  const [form] = Form.useForm();

  const [printerAmount, setPrinterAmount] = useState<number>(0);
  const [printerActive, setPrinterActive] = useState<number>(0);
  const salonId = getStorageByName('salonId') as string;

  const { data: setting, refetch: onRefetch } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    enabled: isHavingPermission,
  });
  const updateSetting = useMutation(
    (payload: { salonId: string; updateDTO: UpdatePrinterSettingsDto }) =>
      settingApi.settingControllerUpdatePrinter(payload.updateDTO, payload.salonId),
    {
      onSuccess: ({ data }) => {
        onRefetch();
        NotificationSuccess({ contentNoti: intl.formatMessage({ id: 'setting.services.save.success' }) });
      },
      onError: (error: ErrorResponse) => {},
    }
  );

  useEffect(() => onInit(), [setting]);

  const onInit = () => {
    setPrinterAmount(setting?.data?.settingPrinter?.length ?? 0);
    setting?.data?.settingPrinter.forEach((settingTip, index) => {
      form.setFieldsValue({
        [n('cloverPrinter') + index]: settingTip?.cloverPrinter
          ? settingTip.cloverPrinter
          : SettingPrinterCloverPrinterEnum.CloverMiniOrFlex,
        [n('printerIPAddress') + index]: settingTip?.printerIPAddress,
        [n('printerDeviceIP') + index]: settingTip?.printerDeviceIP,
        [n('charactersPerLine') + index]: settingTip?.charactersPerLine,
        [n('lineSpacing') + index]: settingTip?.lineSpacing,
        [n('paperWidth') + index]: settingTip?.paperWidth,
        [n('printerName') + index]: settingTip?.printerName,
        [n('receiptFor') + index]: settingTip?.receiptFor,
      });
    });
  };

  const onSubmit = async () => {
    let data: UpdatePrinterSettingsDto = { settings: [] };
    for (let index = 0; index < printerAmount; index++) {
      data.settings.push({
        cloverPrinter: form.getFieldValue(n('cloverPrinter') + index),
        charactersPerLine: form.getFieldValue(n('charactersPerLine') + index),
        lineSpacing: form.getFieldValue(n('lineSpacing') + index),
        printerDeviceIP: form.getFieldValue(n('printerDeviceIP') + index),
        printerIPAddress: form.getFieldValue(n('printerIPAddress') + index),
        paperWidth: form.getFieldValue(n('paperWidth') + index),
        printerName: form.getFieldValue(n('printerName') + index),
        receiptFor: form.getFieldValue(n('receiptFor') + index),
      });
    }

    updateSetting.mutate({ salonId: salonId, updateDTO: data });
  };

  const onClear = () => {
    form.setFieldValue('cloverPrinter' + printerActive, SettingPrinterCloverPrinterEnum.CloverMiniOrFlex);
    form.setFieldValue('printerIPAddress' + printerActive, undefined);
    form.setFieldValue('printerDeviceIP' + printerActive, undefined);
    form.setFieldValue('charactersPerLine' + printerActive, undefined);
    form.setFieldValue('lineSpacing' + printerActive, undefined);
    form.setFieldValue('paperWidth' + printerActive, undefined);
    form.setFieldValue('printerName' + printerActive, undefined);
  };

  const addNewPrinter = () => {
    form.setFieldValue('cloverPrinter' + printerAmount, SettingPrinterCloverPrinterEnum.CloverMiniOrFlex);
    form.setFieldValue('printerIPAddress' + printerAmount, undefined);
    form.setFieldValue('printerDeviceIP' + printerAmount, undefined);
    form.setFieldValue('charactersPerLine' + printerAmount, undefined);
    form.setFieldValue('lineSpacing' + printerAmount, undefined);
    form.setFieldValue('paperWidth' + printerAmount, 2.83);
    form.setFieldValue('printerName' + printerAmount, undefined);
    setPrinterAmount(printerAmount + 1);
    setPrinterActive(printerAmount);
  };

  return (
    <>
      <StyledHeader
        content={intl.formatMessage({ id: `setting.services.printerSettings` })}
        extraButton={
          isHavingPermission && (
            <div className="d-flex gap-12 justify-content-between align-items-center">
              <ButtonStyled
                content={intl.formatMessage({ id: 'setting.services.printTest' })}
                buttonProps={{
                  className: 'width-124',
                  onClick: () => {},
                }}
                isPrimary={false}
              />
              <ButtonStyled
                content={intl.formatMessage({ id: 'setting.services.clear' })}
                buttonProps={{
                  className: 'width-90',
                  onClick: () => {
                    onClear();
                  },
                }}
                isPrimary={false}
              />
              <ButtonStyled
                content={intl.formatMessage({ id: 'setting.services.save' })}
                buttonProps={{
                  className: 'width-87',
                  onClick: () => {
                    form.submit();
                  },
                }}
                isPrimary={true}
              />
            </div>
          )
        }
      />
      {isHavingPermission ? (
        <>
          <div className="m-t-40">
            <span className="font-weight-600 font-size-14 font-style-italic ">
              {intl.formatMessage({ id: 'setting.services.printerSettings.notes' })}
            </span>{' '}
            <span className="font-weight-400 font-size-14 font-style-italic ">
              {intl.formatMessage({ id: 'setting.services.printerSettings.notes.content' })}
            </span>
            <a href="/assets/Document.zip" target="_blank">
              . Click here to download the instruction manual.
            </a>
          </div>
          <FormWrap
            name="businessSetting"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={false}
            form={form}
            className="salon__printer-settings"
            onFinish={onSubmit}
          >
            {Array.from({ length: printerAmount }, (_, i) => i).map((index) => (
              <PrinterChild
                form={form}
                index={index}
                setPrinterActive={setPrinterActive}
                printerActive={printerActive}
              />
            ))}

            <ButtonCirclePrimary
              content={<SvgPlusIconSmall />}
              buttonProps={{
                className: 'width-36 height-36 mt-3',
                onClick: addNewPrinter,
              }}
            />
          </FormWrap>
        </>
      ) : (
        <PageContainer className="p-t-20 p-b-20" marginTop={39}>
          <Result
            status="error"
            title={intl.formatMessage({ id: 'common.youDoNotHavePermissionToAccessThis' })}
          ></Result>
        </PageContainer>
      )}
    </>
  );
};
export default PrinterSetting;
