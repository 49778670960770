export enum DiscountTypeEnum {
  Percent = '%',
  Dollar = '$',
}

interface IReferralDiscount {
  referralCode: string;
  type: DiscountTypeEnum.Dollar;
  amount: number;
}

interface IBirthdayDiscount {
  type: DiscountTypeEnum;
  amount: number;
  serviceItemIds: number[];
  allService?: boolean;
}

interface IWeeklyDiscount {
  type: DiscountTypeEnum;
  amount: number;
  serviceItemIds: number[];
  day: string[];
  allService?: boolean;
}

interface IOccasionDiscount {
  name: string;
  type: DiscountTypeEnum;
  amount: number;
  serviceItemIds: number[];
  allService?: boolean;
}

interface IGeneralTicketDiscount {
  type: DiscountTypeEnum;
  amount: number;
  isCustomAmount: boolean;
}

interface IGeneralMerchandiseDiscount {
  type: DiscountTypeEnum;
  amount: number;
  isCustomAmount: boolean;
}

interface IRewardBalance {
  points: number;
  amount: number;
  type: DiscountTypeEnum;
  isCustomAmount: boolean;
}

export interface IDiscountsAndRewardsApplied {
  // discount
  referralDiscount?: IReferralDiscount;
  birthdayDiscount?: IBirthdayDiscount;
  weeklyDiscount?: IWeeklyDiscount;
  occasionDiscount?: IOccasionDiscount;
  generalTicketDiscount?: IGeneralTicketDiscount;
  generalMerchandiseDiscount?: IGeneralMerchandiseDiscount;

  // reward
  rewardBalance?: IRewardBalance;
}

export interface IFormDataPassword {
  password: string;
}

export const nPasswordForm = (key: keyof IFormDataPassword) => key;
