import React from 'react';

export const SvgPickUpIcon = () => (
  <span>
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.91705 5.00001L4.50371 2.41334L7.09038 5.00001C7.35038 5.26001 7.77038 5.26001 8.03038 5.00001C8.29038 4.74001 8.29038 4.32001 8.03038 4.06001L4.97038 1.00001C4.71038 0.740007 4.29038 0.740007 4.03038 1.00001L0.970381 4.06001C0.845546 4.18456 0.775391 4.35366 0.775391 4.53001C0.775391 4.70635 0.845546 4.87545 0.970381 5.00001C1.23038 5.25334 1.65705 5.26001 1.91705 5.00001Z"
        fill="#292F33"
      />
    </svg>
  </span>
);
