import { Form, FormItemProps, Input, InputProps } from 'antd';
import { PasswordProps } from 'antd/es/input';
import React from 'react';
import { SvgSearchIcon } from '../../@svg/SvgSearchIcon';
import './formInputSearch.scss';
type Props = {
  name: string;
  formItemProps?: FormItemProps;
  inputProps?: InputProps | PasswordProps;
};

export const FormInputSearch: React.FC<Props> = ({ name, inputProps, formItemProps }) => {
  return (
    <Form.Item name={name} {...formItemProps} className={`salon__form-search-input`}>
      <Input autoComplete="off" {...inputProps} maxLength={255} prefix={<SvgSearchIcon />} />
    </Form.Item>
  );
};
