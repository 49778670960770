import { DatePicker, DatePickerProps, Form, FormItemProps } from 'antd';
import React, { ReactNode } from 'react';
import './styledDatePickerStyle.scss';
import { SvgDatePickerIcon } from '../@svg/SvgDatePickerIcon';
import { toDayjsTimeZone } from '../../utils';
type Props = {
  name: string;
  label?: ReactNode | string;
  datePickerProps?: DatePickerProps;
  datePickerIcon?: ReactNode;
  className?: string;
  readonly?: boolean;
};

export const StyledDatePicker: React.FC<Props> = ({
  datePickerProps,
  datePickerIcon,
  label,
  className,
  readonly,
  name,
}) => {
  const activeTodayByTimezone = () => {
    const datePickerELement = document.getElementsByClassName(`date-picker-custom-today-${name}`)?.[0];

    if (datePickerELement) {
      const antPickerCell = datePickerELement.getElementsByClassName('ant-picker-cell');
      if (!!antPickerCell?.length) {
        const today = toDayjsTimeZone().format('YYYY-MM-DD');

        for (let index = 0; index < antPickerCell.length; index++) {
          const element = antPickerCell[index];
          const title = element.getAttribute('title')?.toString();

          if (title && title === today && !element.classList.contains('ant-picker-cell-today')) {
            element.classList.add('ant-picker-cell-today');
          }

          if (title && title !== today && element.classList.contains('ant-picker-cell-today')) {
            element.classList.remove('ant-picker-cell-today');
          }
        }
      }
    }
  };
  return (
    <div className={`salon__form-date-picker salon__styled-date-picker w-100 ${className ?? ''}`}>
      {label && <label className="font-size-16 font-weight-500 m-b-8">{label}</label>}
      <DatePicker
        open={readonly ? false : undefined}
        {...datePickerProps}
        onOpenChange={() => {
          setTimeout(() => {
            activeTodayByTimezone();
          }, 10);
        }}
        onPanelChange={() => {
          setTimeout(() => {
            activeTodayByTimezone();
          }, 10);
        }}
        popupClassName={`salon__form-date-picker-container date-picker-custom-today-${name}`}
        // nextIcon={<SvgNextDayIcon />}
        // prevIcon={<SvgPrevDayIcon />}
        suffixIcon={datePickerIcon ?? <SvgDatePickerIcon />}
        allowClear={false}
      />
    </div>
  );
};
