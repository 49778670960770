import React from 'react';

export const SvgAppointmentPullDownIcon = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11 11.7037L12 15.5937L15.89 11.7037C16.28 11.3137 16.91 11.3137 17.3 11.7037C17.69 12.0937 17.69 12.7237 17.3 13.1137L12.7 17.7037C12.5132 17.891 12.2595 17.9962 11.995 17.9962C11.7305 17.9962 11.4768 17.891 11.29 17.7037L6.7 13.1137C6.31 12.7237 6.31 12.0937 6.7 11.7037C7.09 11.3137 7.72 11.3137 8.11 11.7037ZM6 7.00373C6 6.45373 6.45 6.00373 7 6.00373L17 6.00373C17.55 6.00373 18 6.45373 18 7.00373C18 7.55373 17.55 8.00373 17 8.00373L7 8.00373C6.45 8.00373 6 7.55373 6 7.00373Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  </span>
);
